import { ErrorPageVariant } from "../error-page";

export const getButtonLabel = (
  buttonLabel: string | undefined,
  variants: typeof ErrorPageVariant[keyof typeof ErrorPageVariant]
): string => {
  let label = buttonLabel ? buttonLabel : "На главную";

  if (!buttonLabel) {
    if (variants === ErrorPageVariant.UNKNOWN_ERROR) {
      label = "Обновить";
    }
  }

  return label;
};
