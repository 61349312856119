import React from "react";

import { useInView } from "react-intersection-observer";

import { BlockContent } from "components/block-content";
import {
  GAE_ACTION_GROUP,
  GAE_EVENT,
  GAE_EVENT_ACTION,
  GAE_EVENT_CATEGORY,
  GAE_EVENT_LABEL,
  useGoogleAnalyticsEvents,
} from "hooks/google-analytics";

import { InfoNumbers } from "./components/info-numbers";
import { ListCalculation } from "./components/list-calculation";
import { Table } from "./components/table";
import classes from "./effects.module.css";

export const Effects = React.memo((): JSX.Element => {
  const { sendGAE } = useGoogleAnalyticsEvents();

  const { ref: agroBlockEffectsRef, inView: inViewAgroBlockEffects } =
    useInView({
      initialInView: false,
      threshold: 0.5,
      triggerOnce: true,
    });

  React.useEffect(() => {
    if (inViewAgroBlockEffects) {
      sendGAE({
        id: 68,
        event: GAE_EVENT.vntZeml,
        eventCategory: GAE_EVENT_CATEGORY.tochnoe_zemledelie,
        eventAction: GAE_EVENT_ACTION.block_show,
        eventLabel: GAE_EVENT_LABEL.effekt_ot_vnedreniya,
        eventValue: null,
        eventContent: null,
        buttonLocation: null,
        filterName: null,
        actionGroup: GAE_ACTION_GROUP.non_interactions,
        productName: null,
      });
    }
  }, [inViewAgroBlockEffects, sendGAE]);

  return (
    <div className={classes.blockEffects} ref={agroBlockEffectsRef}>
      <BlockContent>
        <h2 className={classes.title}>Эффект от внедрения</h2>

        <p className={classes.description}>
          Эффект зависит от используемой техники и культур.
          <br />
          Инвестиции окупаются в 1-й год использования решения.
        </p>

        <div className={classes.info}>
          <div className={classes.infoData}>
            <Table />

            <InfoNumbers />
          </div>

          <ListCalculation />
        </div>
      </BlockContent>
    </div>
  );
});
