import { ITabNames } from "constants/tabs";

import { useContextMain } from "hooks/use-context-main";

interface IUseTabNamesReturn {
  tabNames: ITabNames;
}

export const useTabNames = (): IUseTabNamesReturn => {
  const { tabNames } = useContextMain();

  return { tabNames };
};
