import { SEARCH_PARAM_PAYMENT_IN_SUBS } from "constants/routes";

import { useLocation } from "react-router";

import { SubscriptionsInfo } from "components/pages/subscriptions-info";
import { SubscriptionsInfoByPayment } from "components/pages/subscriptions-info-by-payment";
import { ContextSubscriptionsProvider } from "store/context-subscriptions";

export const TabSubscriptions = () => {
  const location = useLocation();
  const locationSearchPararms = new URLSearchParams(location.search);
  const paymentId: string | null = locationSearchPararms.get(
    SEARCH_PARAM_PAYMENT_IN_SUBS
  );

  return (
    <ContextSubscriptionsProvider>
      {paymentId ? (
        <SubscriptionsInfoByPayment paymentId={paymentId} />
      ) : (
        <SubscriptionsInfo />
      )}
    </ContextSubscriptionsProvider>
  );
};
