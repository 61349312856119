import React from "react";

import { MTSIcon } from "components/mts-icon";
import { MTSLink } from "components/mts-link";
import {
  GAE_ACTION_GROUP,
  GAE_BUTTON_LOCATION,
  GAE_EVENT,
  GAE_EVENT_ACTION,
  GAE_EVENT_CATEGORY,
  GAE_EVENT_LABEL,
  useGoogleAnalyticsEvents,
} from "hooks/google-analytics";

import classes from "./logo.module.css";

interface ILogoProps {
  onClick?: () => void;
}

export const Logo = (props: ILogoProps): JSX.Element => {
  const { onClick } = props;
  const logoLinkRef = React.useRef<HTMLAnchorElement>(null);
  const { sendGAE } = useGoogleAnalyticsEvents();

  const onClickByLogoLink = React.useCallback(() => {
    sendGAE({
      id: 39,
      event: GAE_EVENT.vntCross,
      eventCategory: GAE_EVENT_CATEGORY.kross_elementy,
      eventAction: GAE_EVENT_ACTION.element_click,
      eventLabel: GAE_EVENT_LABEL.logo,
      eventValue: null,
      eventContent: null,
      buttonLocation: GAE_BUTTON_LOCATION.screen,
      filterName: null,
      actionGroup: GAE_ACTION_GROUP.interactions,
      productName: null,
    });

    onClick && onClick();
  }, [onClick, sendGAE]);

  React.useEffect(() => {
    const logoLinkRefObject = logoLinkRef?.current;

    if (logoLinkRefObject) {
      logoLinkRefObject.addEventListener("click", onClickByLogoLink);
    }

    return () => {
      logoLinkRefObject?.removeEventListener("click", onClickByLogoLink);
    };
  }, [onClickByLogoLink]);

  return (
    <MTSLink innerRef={logoLinkRef} to="/">
      <div className={classes.logo}>
        <MTSIcon.CompanyLogo
          sx={{ fontSize: "40px", color: "var(--color-brand)" }}
        />

        <h4 className={classes.logoTitle} data-testid="logo">
          LOCATIONPRO
        </h4>
      </div>
    </MTSLink>
  );
};
