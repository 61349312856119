import { SUBSCRIPTION_STATUSES } from "constants/keys";

export class SubscriptionStatusCheckerByCode {
  static isPaid(status: string | undefined): boolean {
    return status === SUBSCRIPTION_STATUSES.PAID;
  }
  static isActivated(status: string | undefined): boolean {
    return status === SUBSCRIPTION_STATUSES.ACTIVATED;
  }
  static isExpired(status: string | undefined): boolean {
    return status === SUBSCRIPTION_STATUSES.EXPIRED;
  }
}
