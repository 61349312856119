import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const Connection = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="96"
      height="96"
      viewBox="0 0 96 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M54.2339 64.744C54.2339 68.2013 51.4387 70.9998 47.9958 70.9998C44.553 70.9998 41.7578 68.2013 41.7578 64.744C41.7578 61.2868 44.553 58.4883 47.9958 58.4883C51.4387 58.4883 54.2339 61.2868 54.2339 64.744Z"
        stroke="black"
        strokeWidth="2"
      />
      <path
        d="M18.6719 61.6274C20.1204 48.9654 30.262 35.1626 48.0004 35.1626C65.7388 35.1626 75.8804 48.9655 77.3289 61.6274"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M8 57.7881C11.0434 41.1316 24.8072 24 48 24C71.1929 24 84.9567 41.1317 88 57.7881"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M29.6289 64.7442C29.6289 56.4036 36.0423 46.3257 48.0024 46.3257C59.9624 46.3257 66.3758 56.4036 66.3758 64.744"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
};

export default Connection;
