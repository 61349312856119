import { HTTPStatusCode, QueryKeys } from "constants/keys";

import { useQuery } from "react-query";

import { AxiosError } from "axios";
import { useNotifications } from "hooks/use-notifications";
import { getProfileSubscriptions } from "services/api";

export const useProfileSubscriptions = () => {
  const { addErrorMessage } = useNotifications();

  return useQuery(
    QueryKeys.PROFILE_SUBSCRIPTIONS,
    () => getProfileSubscriptions({}),
    {
      refetchOnWindowFocus: false,
      retry: 0,
      onError: (error: AxiosError) => {
        const status = error.response?.status;

        if (status !== HTTPStatusCode.UNAUTHORIZED_401) {
          addErrorMessage({
            message: "Не удалось получить данные по подпискам",
          });
        }
      },
    }
  );
};
