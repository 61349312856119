import { FIELD_NAMES, REQUIRED_FIELD_MESSAGE } from "constants/keys";
import { PATH_ADD_RINEX_REQUEST, PATH_RINEX_REQUESTS } from "constants/routes";
import { SearchParamsKeys } from "constants/search-params";

import React from "react";

import { useLocation } from "react-router-dom";

import { Controller, useForm, useWatch } from "react-hook-form";
import { useHistory } from "react-router";

import { yupResolver } from "@hookform/resolvers/yup";
import { InputMessage } from "components/input-message";
import { Loader } from "components/loader";
import { MTSAutocomplete } from "components/mts-autocomplete";
import { IMTSAutocompleteValue } from "components/mts-autocomplete/interfaces";
import { MTSButton } from "components/mts-button";
import { PageTitle } from "components/page-title";
import { getRinexStartDays } from "components/pages/rinex-request-add-page/utils/get-rinex-start-days-array";
import { useAccessStatusDownloadRinex } from "hooks/use-access-status-download-rinex";
import { useBaseStations } from "hooks/use-base-stations";
import { useBaseStationsAsDictionary } from "hooks/use-base-stations-as-dictionary";
import { useMutationCreateRinexRequest } from "hooks/use-mutation-create-rinex-request";
import { useRinexDurationHours } from "hooks/use-rinex-duration-hours";
import { useRinexFrequencies } from "hooks/use-rinex-frequencies";
import { useRinexStartHours } from "hooks/use-rinex-start-hours";
import { useRinexVersions } from "hooks/use-rinex-versions";
import { useTimezones } from "hooks/use-timezones";
import {
  IBaseStationEchoDTO,
  IDictionaryDTO,
  IFileRequestDTO,
} from "interfaces";
import { DateTime } from "luxon";
import { getStationCoordinates } from "utils/get-station-coordinates";
import * as yup from "yup";

import classes from "./rinex-request-create-form.module.css";

export interface IRinexRequestFormStruct {
  [FIELD_NAMES.RINEX_STATION]: IMTSAutocompleteValue;
  [FIELD_NAMES.RINEX_MEASUREMENT_START_DAY]: IMTSAutocompleteValue;
  [FIELD_NAMES.RINEX_MEASUREMENT_START_HOUR]: IMTSAutocompleteValue;
  [FIELD_NAMES.RINEX_DURATION]: IMTSAutocompleteValue;
  [FIELD_NAMES.RINEX_TIMEZONE]: IMTSAutocompleteValue;
  [FIELD_NAMES.RINEX_FREQUENCY]: IMTSAutocompleteValue;
  [FIELD_NAMES.RINEX_VERSION]: IMTSAutocompleteValue;
}

const schema = yup.object({
  [FIELD_NAMES.RINEX_STATION]: yup
    .object()
    .shape({
      id: yup.number(),
      name: yup.string(),
      code: yup.string(),
      description: yup.string(),
    })
    .nullable()
    .required(REQUIRED_FIELD_MESSAGE),
  [FIELD_NAMES.RINEX_TIMEZONE]: yup
    .object()
    .shape({
      id: yup.number(),
      name: yup.string(),
      code: yup.string(),
      description: yup.string(),
    })
    .nullable()
    .required(REQUIRED_FIELD_MESSAGE),
  [FIELD_NAMES.RINEX_MEASUREMENT_START_DAY]: yup
    .object()
    .shape({
      id: yup.number(),
      name: yup.string(),
      code: yup.string(),
      description: yup.string(),
    })
    .nullable()
    .required(REQUIRED_FIELD_MESSAGE),
  [FIELD_NAMES.RINEX_MEASUREMENT_START_HOUR]: yup
    .object()
    .shape({
      id: yup.number(),
      name: yup.string(),
      code: yup.string(),
      description: yup.string(),
    })
    .nullable()
    .required(REQUIRED_FIELD_MESSAGE),
  [FIELD_NAMES.RINEX_DURATION]: yup
    .object()
    .shape({
      id: yup.number(),
      name: yup.string(),
      code: yup.string(),
      description: yup.string(),
    })
    .nullable()
    .required(REQUIRED_FIELD_MESSAGE),
  [FIELD_NAMES.RINEX_FREQUENCY]: yup
    .object()
    .shape({
      id: yup.number(),
      name: yup.string(),
      code: yup.string(),
      description: yup.string(),
    })
    .nullable()
    .required(REQUIRED_FIELD_MESSAGE),
  [FIELD_NAMES.RINEX_VERSION]: yup
    .object()
    .shape({
      id: yup.number(),
      name: yup.string(),
      code: yup.string(),
      description: yup.string(),
    })
    .nullable()
    .required(REQUIRED_FIELD_MESSAGE),
});

interface IRinexRequestCreateFormProps {
  pageTitle: string;
}

export const RinexRequestCreateForm = (props: IRinexRequestCreateFormProps) => {
  const { pageTitle } = props;
  const history = useHistory();
  const location = useLocation();
  const rinexStartHours = useRinexStartHours();
  const rinexDurationHours = useRinexDurationHours();
  const { data: timezones, isLoading: isLoadingTimezones } = useTimezones();
  const rinexVersions = useRinexVersions();
  const { data: rinexFrequencies } = useRinexFrequencies();
  const { data: userAccessDownloadRinex } = useAccessStatusDownloadRinex();
  const isUserHasAccessDownloadRinex: boolean =
    userAccessDownloadRinex?.allowed || false;
  const { data: baseStationsData, isLoading: isLoadingBaseStationsData } =
    useBaseStations({ enabled: true });
  const { data: baseStationsAsDictionary } = useBaseStationsAsDictionary();

  const mutationCreateRinexRequest = useMutationCreateRinexRequest();

  const rinexInitialDaysArray = getRinexStartDays("UTC+3:00");
  const [rinexDaysArray, setRinexDaysArray] = React.useState<IDictionaryDTO[]>(
    rinexInitialDaysArray
  );
  const [rinexHoursCodesDisabled, setRinexHoursCodesDisabled] = React.useState<
    string[]
  >([""]);
  const [selectedBaseStationData, setSelectedBaseStationData] =
    React.useState<IBaseStationEchoDTO | null>(null);

  const baseStationAdress: string =
    selectedBaseStationData?.address.formatted_address ?? "Станция не выбрана";
  const baseStationCoordinates: string = getStationCoordinates({
    lat: selectedBaseStationData?.coordinateLatitude,
    lng: selectedBaseStationData?.coordinateLongitude,
  });
  const baseStationMountpoint: string =
    selectedBaseStationData?.mountPoint ?? "—";
  const baseStationReceiverType: string =
    selectedBaseStationData?.receiveType ?? "—";
  const baseStationAntenna: string = selectedBaseStationData?.antenna ?? "—";

  const isStationParamsInitializedRef = React.useRef<boolean>(false);
  const paramKeyStation = SearchParamsKeys.rinexFileRequests.stationId;

  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    setError,
    formState: { isValid },
  } = useForm<IRinexRequestFormStruct>({
    mode: "onChange",
    defaultValues: {
      [FIELD_NAMES.RINEX_STATION]: null,
      [FIELD_NAMES.RINEX_TIMEZONE]: null,
      [FIELD_NAMES.RINEX_MEASUREMENT_START_DAY]: rinexDaysArray[0],
      [FIELD_NAMES.RINEX_MEASUREMENT_START_HOUR]: null,
      [FIELD_NAMES.RINEX_DURATION]: null,
      [FIELD_NAMES.RINEX_FREQUENCY]: rinexFrequencies[0],
      [FIELD_NAMES.RINEX_VERSION]: rinexVersions[0],
    },
    shouldFocusError: false,
    resolver: yupResolver(schema),
  });

  const isTodayDaySelected =
    getValues(FIELD_NAMES.RINEX_MEASUREMENT_START_DAY)?.code ===
    rinexDaysArray[0].code;

  const selectedStation = useWatch({
    control,
    name: FIELD_NAMES.RINEX_STATION,
  });
  const selectedTimezone = useWatch({
    control,
    name: FIELD_NAMES.RINEX_TIMEZONE,
  });
  const selectedStartDay = useWatch({
    control,
    name: FIELD_NAMES.RINEX_MEASUREMENT_START_DAY,
  });

  const handleCancelRinexRequest = (): void => {
    if (history.length > 1) {
      if (history.action === "POP") {
        history.push(PATH_RINEX_REQUESTS);
      } else {
        history.goBack();
      }
    } else {
      history.push(PATH_RINEX_REQUESTS);
    }
  };

  const handleCreateRinexRequest = (data: IRinexRequestFormStruct): void => {
    const {
      rinexStation,
      rinexMeasurementStartDay,
      rinexMeasurementStartHour,
      rinexDuration,
      rinexTimezone,
      rinexFrequency,
      rinexVersion,
    } = data;

    const [day, month, year] = rinexMeasurementStartDay?.code.split(".") || "";
    const [hour, minute] = rinexMeasurementStartHour?.code.split(":") || "";
    const timezoneUTC = rinexTimezone?.name || "";

    const beginDateISO = DateTime.fromObject(
      {
        year: Number(year),
        month: Number(month),
        day: Number(day),
        hour: Number(hour),
        minute: Number(minute),
      },
      { zone: timezoneUTC }
    ).toISO();

    const payload: IFileRequestDTO = {
      beginDate: beginDateISO,
      hourNumber: Number(rinexDuration?.code),
      rinexVersion: rinexVersion?.code || "",
      rinexFreq: rinexFrequency?.code || "",
      stationName: rinexStation?.code || "",
    };

    mutationCreateRinexRequest.mutate(payload, {
      onSuccess: () => {
        history.push(PATH_RINEX_REQUESTS);
      },
    });
  };

  React.useEffect(() => {
    if (
      isStationParamsInitializedRef.current === false &&
      !isLoadingBaseStationsData
    ) {
      const searchParams = location.search;
      const params = new URLSearchParams(searchParams);
      const initParamsString = params.toString();
      const stationIdParams = params.get(paramKeyStation);

      const findedBaseStation =
        baseStationsAsDictionary.find(
          (station) => station.id.toString() === stationIdParams
        ) || null;

      setValue(FIELD_NAMES.RINEX_STATION, findedBaseStation);

      if (!findedBaseStation) {
        params.delete(paramKeyStation);
      }

      const newParamsString = params.toString();

      if (initParamsString !== newParamsString) {
        history.push(PATH_ADD_RINEX_REQUEST);
      }

      setTimeout(() => {
        isStationParamsInitializedRef.current = true;
      }, 0);
    }
  }, [
    isLoadingBaseStationsData,
    baseStationsAsDictionary,
    location.search,
    setValue,
    paramKeyStation,
    history,
  ]);

  React.useEffect(() => {
    if (
      isStationParamsInitializedRef.current === true &&
      !isLoadingBaseStationsData
    ) {
      if (selectedStation) {
        const searchParams = location.search;
        const params = new URLSearchParams(searchParams);
        params.set(paramKeyStation, selectedStation.id.toString());

        history.push(`${PATH_ADD_RINEX_REQUEST}?${params.toString()}`);
      } else {
        history.push(PATH_ADD_RINEX_REQUEST);
      }
    }
  }, [
    isLoadingBaseStationsData,
    history,
    selectedStation,
    location.search,
    paramKeyStation,
  ]);

  React.useEffect(() => {
    if (baseStationsData && !isLoadingBaseStationsData) {
      const baseStationData =
        baseStationsData.find((baseStation) => {
          return baseStation.id === selectedStation?.id;
        }) || null;

      setSelectedBaseStationData(baseStationData);
    }
  }, [baseStationsData, isLoadingBaseStationsData, selectedStation]);

  React.useEffect(() => {
    if (!isLoadingTimezones && timezones) {
      const initialTimezone =
        timezones.find((timezone) => timezone.id === 16) || null;

      setValue(FIELD_NAMES.RINEX_TIMEZONE, initialTimezone);
    }
  }, [isLoadingTimezones, timezones, setValue]);

  // Изменение массива с датами начала изменений при изменении часового пояса UTC
  React.useEffect(() => {
    if (selectedTimezone) {
      const currentDateTimeFormatted = DateTime.now()
        .setZone(selectedTimezone.name)
        .toFormat("dd.MM.yyyy");
      const newDaysArray = getRinexStartDays(selectedTimezone.name);
      if (
        selectedStartDay &&
        currentDateTimeFormatted !== rinexDaysArray[0].code
      ) {
        const rinexDaysArrayIncludeSelectedDay = newDaysArray.some(
          (rinexDay) => rinexDay.code === selectedStartDay.code
        );
        const currentDayIndexInArray = newDaysArray.findIndex((day) => {
          return day.code === selectedStartDay.code;
        });
        setRinexDaysArray(newDaysArray);
        if (!rinexDaysArrayIncludeSelectedDay) {
          setValue(FIELD_NAMES.RINEX_MEASUREMENT_START_DAY, null);
          setError(FIELD_NAMES.RINEX_MEASUREMENT_START_DAY, {
            type: "manual",
            message: "Выберите дату начала измерений",
          });
        } else {
          setValue(
            FIELD_NAMES.RINEX_MEASUREMENT_START_DAY,
            newDaysArray[currentDayIndexInArray]
          );
        }
      }
    }
  }, [selectedTimezone, setValue, rinexDaysArray, setError, selectedStartDay]);

  // Определение массива не доступных для выбора времени начала измерений
  React.useEffect(() => {
    if (selectedTimezone && isTodayDaySelected) {
      const currentHourFormatted = DateTime.now()
        .setZone(selectedTimezone.name)
        .toFormat("HH:00");

      const currentHourIndexInArray = rinexStartHours.findIndex((hour) => {
        return hour.code === currentHourFormatted;
      });

      if (currentHourIndexInArray < 1) {
        setRinexHoursCodesDisabled(
          rinexStartHours.map((disabledHour) => disabledHour.code)
        );
      } else {
        setRinexHoursCodesDisabled(
          rinexStartHours
            .slice(currentHourIndexInArray - 1)
            .map((disabledHour) => disabledHour.code)
        );
      }
    } else {
      setRinexHoursCodesDisabled([""]);
    }
  }, [rinexStartHours, selectedTimezone, isTodayDaySelected]);

  // Сброс времени начала измерений, если текущее выбранное время становится не доступным для выбора
  React.useEffect(() => {
    const isSelectedHourDesabled = rinexHoursCodesDisabled?.some(
      (hourCodeDisabled) => {
        return (
          hourCodeDisabled ===
          getValues(FIELD_NAMES.RINEX_MEASUREMENT_START_HOUR)?.code
        );
      }
    );

    if (isTodayDaySelected && isSelectedHourDesabled) {
      setValue(FIELD_NAMES.RINEX_MEASUREMENT_START_HOUR, null);
      setError(FIELD_NAMES.RINEX_MEASUREMENT_START_HOUR, {
        type: "manual",
        message: "Выберите время начала",
      });
    }
  }, [
    getValues,
    isTodayDaySelected,
    rinexHoursCodesDisabled,
    setValue,
    setError,
  ]);

  if (isLoadingTimezones || isLoadingBaseStationsData) {
    return <Loader />;
  }

  return (
    <>
      <div className={classes.container}>
        <PageTitle text={pageTitle} />

        <div className={classes.block}>
          <div className={classes.blockStation}>
            <div className={classes.inputGroup}>
              <Controller
                control={control}
                name={FIELD_NAMES.RINEX_STATION}
                render={({ field: { onChange, value }, fieldState }) => {
                  const errorMessage: string = fieldState?.error?.message || "";

                  return (
                    <MTSAutocomplete
                      required
                      size="m"
                      label="Станция"
                      placeholder="Выберите станцию"
                      value={value}
                      onChangeValue={onChange}
                      options={baseStationsAsDictionary}
                      loading={isLoadingBaseStationsData}
                      errorMessage={errorMessage}
                    />
                  );
                }}
              />
            </div>

            <div className={classes.blockStationInfo}>
              <p className={classes.blockStationInfoAddres}>
                {baseStationAdress}
              </p>
              <div className={classes.stationData}>
                <div className={classes.stationDataColumn}>
                  <p className={classes.stationDataColumnName}>Координаты:</p>
                  &nbsp;
                  <p className={classes.stationDataColumnInfo}>
                    {baseStationCoordinates || "—"}
                  </p>
                </div>
                <div className={classes.stationDataColumn}>
                  <p className={classes.stationDataColumnName}>ID станции:</p>
                  &nbsp;
                  <p className={classes.stationDataColumnInfo}>
                    {baseStationMountpoint}
                  </p>
                </div>
                <div className={classes.stationDataColumn}>
                  <p className={classes.stationDataColumnName}>
                    Тип приёмника:
                  </p>
                  &nbsp;
                  <p className={classes.stationDataColumnInfo}>
                    {baseStationReceiverType}
                  </p>
                </div>
                <div className={classes.stationDataColumn}>
                  <p className={classes.stationDataColumnName}>Антенна:</p>
                  &nbsp;
                  <p className={classes.stationDataColumnInfo}>
                    {baseStationAntenna}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className={classes.blockForm}>
            <form className={classes.form}>
              <h4 className={classes.formTitle}>Параметры запроса</h4>

              <div className={classes.formBlockInputs}>
                <div className={classes.inputGroup}>
                  <Controller
                    control={control}
                    name={FIELD_NAMES.RINEX_TIMEZONE}
                    render={({ field: { onChange, value }, fieldState }) => {
                      const errorMessage: string =
                        fieldState?.error?.message || "";

                      return (
                        <MTSAutocomplete
                          required
                          size="m"
                          label="Часовой пояс"
                          placeholder="Выберите"
                          value={value}
                          onChangeValue={onChange}
                          options={timezones}
                          loading={isLoadingTimezones}
                          errorMessage={errorMessage}
                        />
                      );
                    }}
                  />
                </div>

                <div className={classes.inputGroup}>
                  <Controller
                    name={FIELD_NAMES.RINEX_MEASUREMENT_START_DAY}
                    control={control}
                    render={({ field: { onChange, value }, fieldState }) => {
                      const errorMessage: string =
                        fieldState?.error?.message || "";

                      return (
                        <>
                          <MTSAutocomplete
                            required
                            size="m"
                            placeholder="Выберите"
                            label="Дата начала измерений"
                            value={value as IMTSAutocompleteValue}
                            onChangeValue={onChange}
                            options={rinexDaysArray}
                            errorMessage={errorMessage}
                          />

                          <InputMessage
                            mode={errorMessage ? "error" : "description"}
                            text={errorMessage ? "" : "7 дней до текущей даты"}
                          />
                        </>
                      );
                    }}
                  />
                </div>

                <div className={classes.inputGroup}>
                  <Controller
                    name={FIELD_NAMES.RINEX_MEASUREMENT_START_HOUR}
                    control={control}
                    render={({ field: { onChange, value }, fieldState }) => {
                      const errorMessage: string =
                        fieldState?.error?.message || "";

                      return (
                        <MTSAutocomplete
                          required
                          size="m"
                          placeholder="Выберите время"
                          label="Время начала"
                          value={value as IMTSAutocompleteValue}
                          onChangeValue={onChange}
                          options={rinexStartHours}
                          errorMessage={errorMessage}
                          disabledOptionCodes={
                            rinexStartHours.length
                              ? rinexHoursCodesDisabled
                              : []
                          }
                        />
                      );
                    }}
                  />
                </div>

                <div className={classes.inputGroup}>
                  <Controller
                    name={FIELD_NAMES.RINEX_DURATION}
                    control={control}
                    render={({ field: { onChange, value }, fieldState }) => {
                      const errorMessage: string =
                        fieldState?.error?.message || "";

                      return (
                        <MTSAutocomplete
                          required
                          size="m"
                          placeholder="Выберите период"
                          label="Период измерения"
                          value={value as IMTSAutocompleteValue}
                          onChangeValue={onChange}
                          options={rinexDurationHours}
                          errorMessage={errorMessage}
                        />
                      );
                    }}
                  />
                </div>

                <div className={classes.input}>
                  <Controller
                    control={control}
                    name={FIELD_NAMES.RINEX_FREQUENCY}
                    render={({ field: { onChange, value } }) => (
                      <MTSAutocomplete
                        required
                        size="m"
                        label="Частота"
                        placeholder="Выберите"
                        options={rinexFrequencies}
                        value={value}
                        onChangeValue={onChange}
                        disabled
                      />
                    )}
                  />
                </div>

                <div className={classes.input}>
                  <Controller
                    control={control}
                    name={FIELD_NAMES.RINEX_VERSION}
                    render={({ field: { onChange, value } }) => (
                      <MTSAutocomplete
                        required
                        size="m"
                        label="Версия RINEX"
                        placeholder="Выберите"
                        options={rinexVersions}
                        value={value}
                        onChangeValue={onChange}
                        disabled
                      />
                    )}
                  />
                </div>
              </div>
            </form>
            <div className={classes.button_bar}>
              <MTSButton
                variant="secondary"
                size="M"
                sx={{ width: 192 }}
                onClick={() => handleCancelRinexRequest()}
              >
                Отмена
              </MTSButton>

              <MTSButton
                variant="primary"
                size="M"
                sx={{ width: 192 }}
                onClick={handleSubmit(handleCreateRinexRequest)}
                loading={mutationCreateRinexRequest.isLoading}
                disabled={!isUserHasAccessDownloadRinex || !isValid}
              >
                Сформировать
              </MTSButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
