import React from "react";

import { useInView } from "react-intersection-observer";

import { AppHelmet } from "components/app-helmet";
import { ContainerContent } from "components/container-content";
import { FooterOfPersonalArea } from "components/footer-of-personal-area";
import { Loader } from "components/loader";
import { MTSSpinner } from "components/mts-spinner";
import { PageTitle } from "components/page-title";
import { PaymentCard } from "components/payment-card";
import { TemplateServiceNotAvailable } from "components/template-service-not-available";
import { TransitionCollapseFade } from "components/transition-сollapse-fade";
import {
  GAP_EVENT_TYPE,
  GA_SCREEN_NAME,
  useGoogleAnalyticsPages,
} from "hooks/google-analytics";
import { usePayments } from "hooks/use-payments";
import { IPaymentDTO } from "interfaces";

import { NoPayments } from "./components/no-payments";
import classes from "./payments-page.module.css";

const PAGE_TITLE = "Платежи";

export const PaymentsPage = (): JSX.Element => {
  const { ref: refToFetchPayments, inView: inViewToFetchPayments } = useInView({
    initialInView: false,
  });

  useGoogleAnalyticsPages({
    screenName: GA_SCREEN_NAME.payments,
    event: GAP_EVENT_TYPE.SCRN,
  });

  const {
    data: dataPayments,
    isLoading: isLoadingPayments,
    isError: isErrorPayments,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = usePayments();

  const isEmptyData = dataPayments?.pages[0]?.totalElements === 0;
  const payments = dataPayments?.pages[0]?.content || [];

  React.useEffect(() => {
    if (inViewToFetchPayments && hasNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, inViewToFetchPayments]);

  if (isLoadingPayments) {
    return <Loader />;
  }

  return (
    <>
      <AppHelmet title={`${PAGE_TITLE} - Личный кабинет`} />

      {isErrorPayments ? (
        <ContainerContent>
          <PageTitle text={PAGE_TITLE} />

          <TemplateServiceNotAvailable />
        </ContainerContent>
      ) : (
        <ContainerContent>
          <PageTitle text={PAGE_TITLE} />

          {isEmptyData ? (
            <div className={classes.paymentsEmptyData}>
              <NoPayments />
            </div>
          ) : (
            <div className={classes.paymentsCards}>
              {payments.map((paymentData: IPaymentDTO) => (
                <PaymentCard key={paymentData.id} data={paymentData} />
              ))}
            </div>
          )}

          <div ref={refToFetchPayments} />

          <TransitionCollapseFade isShow={isFetchingNextPage}>
            <div className={classes.loader}>
              <MTSSpinner size="M" />
            </div>
          </TransitionCollapseFade>
        </ContainerContent>
      )}
      <FooterOfPersonalArea />
    </>
  );
};
