import { Size } from "../../constants";

export const getBtnIconSize = (size: string): any => {
  switch (size) {
    case Size.S: {
      return "16px";
    }
    case Size.M: {
      return "24px";
    }
    case Size.L: {
      return "24px";
    }
    case Size.XL: {
      return "24px";
    }
    default:
      return "16px";
  }
};
