import { QueryKeys, HTTPStatusCode } from "constants/keys";

import { useQuery } from "react-query";

import { AxiosError } from "axios";
import { useNotifications } from "hooks/use-notifications";
import { ISubscriptionResponseCommonDTO } from "interfaces";
import { getSubscriptionInfoCommon } from "services/api";

export const useSubscriptionInfoCommon = () => {
  const { addErrorMessage } = useNotifications();

  return useQuery<ISubscriptionResponseCommonDTO, AxiosError>(
    QueryKeys.SUBSCRIPTION_INFO_COMMON,
    () => getSubscriptionInfoCommon(),
    {
      retry: 0,
      refetchOnWindowFocus: true,
      onError: (error: AxiosError) => {
        const statusCode: number | null = error.response?.status ?? null;

        if (
          statusCode !== null &&
          statusCode !== HTTPStatusCode.UNAUTHORIZED_401
        ) {
          addErrorMessage({ message: "Не удалось получить данные о подписке" });
        }
      },
    }
  );
};
