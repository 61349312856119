import React from "react";

import { MTSIcon } from "components/mts-icon";

import classes from "./render-filter.module.css";

interface IRenderFilterProps {
  isSorted: boolean;
  isSortedDesc: boolean;
  dataTestId?: string | null;
}

export const RenderFilter = React.memo(
  ({ isSorted, isSortedDesc, dataTestId = null }: IRenderFilterProps) => {
    return (
      <div className={classes.sortIcon} data-testid={dataTestId || ""}>
        {isSorted ? (
          isSortedDesc ? (
            <MTSIcon.ArrowDownMin
              sx={{
                fontSize: 16,
                color: "var(--color-icon-primary)",
              }}
            />
          ) : (
            <MTSIcon.ArrowUpMin
              sx={{
                fontSize: 16,
                color: "var(--color-icon-primary)",
              }}
            />
          )
        ) : (
          <MTSIcon.ArrowDownMin
            sx={{
              fontSize: 16,
              color: "var(--color-icon-secondary)",
            }}
          />
        )}
      </div>
    );
  }
);
