import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const Supporting = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="96"
      height="96"
      viewBox="0 0 96 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M48 35V48L57 57"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <circle cx="48" cy="48" r="31" stroke="black" strokeWidth="2" />
      <path
        d="M48 17V23.0268"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M26.5 26.5L30.5 30.5"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M26 69L30 65"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M69.5 26.5L65.5 30.5"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M70 69L66 65"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M23.0273 48L17.0005 48"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M79.0273 48L73.0005 48"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M48 73V75.0134V79.0268"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
};

export default Supporting;
