import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const Accuracy = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="96"
      height="96"
      viewBox="0 0 96 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M48.1367 8V16.0268"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M48.1367 79.9736V88.0005"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M16.082 47.8662L8.00105 47.8662"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M88 47.8662L79.919 47.8662"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M79.4604 47.8667C79.4604 65.0405 65.4425 78.9741 48.1364 78.9741C30.8304 78.9741 16.8125 65.0405 16.8125 47.8667C16.8125 30.6929 30.8304 16.7593 48.1364 16.7593C65.4425 16.7593 79.4604 30.6929 79.4604 47.8667Z"
        stroke="black"
        strokeWidth="2"
      />
      <path
        d="M59.5239 47.8669C59.5239 54.1057 54.4304 59.1747 48.133 59.1747C41.8357 59.1747 36.7422 54.1057 36.7422 47.8669C36.7422 41.6281 41.8357 36.5591 48.133 36.5591C54.4304 36.5591 59.5239 41.6281 59.5239 47.8669Z"
        stroke="black"
        strokeWidth="2"
      />
    </SvgIcon>
  );
};

export default Accuracy;
