import { useMediaQuery } from "@mui/material";
import { useAppTheme } from "hooks/use-app-theme";
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import AgroSturman from "../../icons/agro-shturman";
import Geomir from "../../icons/geomir";
import GSNN from "../../icons/gnss";
import Metrica from "../../icons/metrica";
import Orient from "../../icons/orient";
import UGT from "../../icons/ugt";

import classes from "./slider-partners.module.css";

export const SliderPartners = (): JSX.Element => {
  const theme = useAppTheme();

  const isMinWidthDesktop_960 = useMediaQuery(
    theme.breakpoints.up("desktopS_960")
  );
  const isWidthTablet768 = useMediaQuery(
    theme.breakpoints.between("tablet_768", "desktopS_960")
  );

  const iconSize = isMinWidthDesktop_960
    ? { width: 145, height: 68 }
    : isWidthTablet768
    ? { width: 119, height: 56 }
    : { width: 68, height: 32 };

  const partnersIcons = [
    <Orient sx={iconSize} />,
    <GSNN sx={iconSize} />,
    <UGT sx={iconSize} />,
    <Metrica sx={iconSize} />,
    <Geomir sx={iconSize} />,
    <AgroSturman sx={iconSize} />,
  ];

  return (
    <Swiper
      modules={[Autoplay]}
      autoplay={{
        delay: 1000,
        disableOnInteraction: false,
      }}
      slidesPerView={isMinWidthDesktop_960 ? 5.25 : isWidthTablet768 ? 5 : 3.65}
      speed={3000}
      className={classes.swiper}
    >
      {partnersIcons.map((partnerIcon: JSX.Element, index: number) => (
        <SwiperSlide key={index}>{partnerIcon}</SwiperSlide>
      ))}
    </Swiper>
  );
};
