import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export const ErrorIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_74439_432158)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.68597 19.314C3.3731 18.0012 3.27575 16.6932 3.08105 14.0772C3.03058 13.399 3 12.6995 3 12C3 11.3005 3.03058 10.601 3.08105 9.92282C3.27575 7.30684 3.3731 5.99884 4.68597 4.68597C5.99884 3.37309 7.30684 3.27575 9.92282 3.08105C10.601 3.03058 11.3005 3 12 3C12.6995 3 13.399 3.03058 14.0772 3.08105C16.6932 3.27575 18.0012 3.37309 19.314 4.68597C20.6269 5.99884 20.7243 7.30684 20.9189 9.92282C20.9694 10.601 21 11.3005 21 12C21 12.6995 20.9694 13.399 20.9189 14.0772C20.7243 16.6932 20.6269 18.0012 19.314 19.314C18.0012 20.6269 16.6932 20.7243 14.0772 20.9189C13.399 20.9694 12.6995 21 12 21C11.3005 21 10.601 20.9694 9.92282 20.9189C7.30684 20.7243 5.99884 20.6269 4.68597 19.314ZM12 13C12.5523 13 13 12.5523 13 12L13 8.00244C13 7.45015 12.5523 7.00244 12 7.00244C11.4477 7.00244 11 7.45016 11 8.00244L11 12C11 12.5523 11.4477 13 12 13ZM13.2477 16.0011C13.2477 15.3114 12.6886 14.7523 11.9989 14.7523C11.3091 14.7523 10.75 15.3114 10.75 16.0011C10.75 16.6909 11.3091 17.25 11.9989 17.25C12.6886 17.25 13.2477 16.6909 13.2477 16.0011Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_74439_432158">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
