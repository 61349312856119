import { TABLE_PAGE_SIZE } from "constants/table";

import React from "react";

import { ITablePagination } from "interfaces";

type IUseTablePaginationProps = {
  data: ITablePagination | null;
};

export const useTablePagination = (props: IUseTablePaginationProps) => {
  const { data } = props;
  const prevPaginationDataRef = React.useRef<ITablePagination>({
    page: 1,
    totalPages: 1,
    elements: 1,
    totalElements: 1,
    pageIsFirst: true,
    pageIsLast: true,
    size: TABLE_PAGE_SIZE,
  });

  const pagination: ITablePagination = data || prevPaginationDataRef.current;
  if (data) {
    prevPaginationDataRef.current = data;
  }

  const isCanPreviousPage: boolean =
    !pagination.pageIsFirst && pagination.page <= pagination.totalPages;
  const isCanNextPage: boolean =
    pagination.totalPages > 0 &&
    pagination.page !== pagination.totalPages &&
    pagination.page < pagination.totalPages &&
    !pagination.pageIsLast;

  return { pagination, isCanPreviousPage, isCanNextPage };
};
