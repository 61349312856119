import React from "react";

import { ContextProfileWidget } from "./context-profile-widget";

export const ContextProfileWidgetProvider: React.FC = ({ children }) => {
  const [profileWidget, setProfileWidget] = React.useState<any>(null);

  const reloadProfileWidget = (): void => {
    if (profileWidget) {
      profileWidget.$destroy();
      setProfileWidget(null);
    }
  };

  return (
    <ContextProfileWidget.Provider
      value={{ profileWidget, setProfileWidget, reloadProfileWidget }}
    >
      {children}
    </ContextProfileWidget.Provider>
  );
};

ContextProfileWidgetProvider.displayName = "ContextProfileWidgetProvider";
