import { SUBSCRIPTION_STATUSES } from "constants/keys";

import { MTSIcon } from "components/mts-icon";
import { getDateFormatted, IDateFormatted } from "utils/get-date-formatted";

import classes from "./subscription-status.module.css";

interface ISubscriptionStatusProps {
  subscriptionStatus: string | undefined;
  createdDate: string | undefined;
  expiredDate: string | undefined;
}

export const SubscriptionStatus = (props: ISubscriptionStatusProps) => {
  const { subscriptionStatus = "", createdDate = "", expiredDate = "" } = props;
  const createdDateString: IDateFormatted = getDateFormatted(createdDate);
  const expiredDateString: IDateFormatted = getDateFormatted(expiredDate);

  /* NOTE: STATUS PAID */
  if (subscriptionStatus === SUBSCRIPTION_STATUSES.PAID) {
    return (
      <div className={classes.status}>
        <MTSIcon.Success
          sx={{
            fontSize: 20,
            color: "var(--color-icon-tertiary)",
          }}
        />
        <span className={classes.statusLabel}>
          Оплачена&nbsp;
          {createdDateString.date &&
            createdDateString.time &&
            `${createdDateString.date} ${createdDateString.time}`}
        </span>
      </div>
    );
  }

  /* NOTE: STATUS ACTIVATED */
  if (subscriptionStatus === SUBSCRIPTION_STATUSES.ACTIVATED) {
    return (
      <div className={classes.status}>
        <MTSIcon.Success
          sx={{
            fontSize: 20,
            color: "var(--color-accent-positive)",
          }}
        />
        <span className={classes.statusLabel}>
          Активна до&nbsp;
          {expiredDateString.date &&
            expiredDateString.time &&
            `${expiredDateString.date} ${expiredDateString.time}`}
        </span>
      </div>
    );
  }

  /* NOTE: STATUS EXPIRED */
  if (subscriptionStatus === SUBSCRIPTION_STATUSES.EXPIRED) {
    return (
      <div className={classes.status}>
        <MTSIcon.Error
          sx={{
            fontSize: 20,
            color: "var(--color-icon-tertiary)",
          }}
        />
        <span className={classes.statusLabel}>Истёк срок действия</span>
      </div>
    );
  }

  return null;
};
