import { DISCOUNT_TYPE } from "constants/keys";

export const getDiscountDescription = (
  discountType: string | undefined
): string => {
  let description: string = "";

  if (discountType === DISCOUNT_TYPE.REGION_DISCOUNT) {
    description = "Скидка на этапе строительства";
  } else if (discountType === DISCOUNT_TYPE.SUBSCRIPTION_COUNT_DISCOUNT) {
    description = "Скидка за количество подписок";
  }

  return description;
};
