import React from "react";

import { IDictionaryDTO, IQuantityDiscountStruct } from "interfaces";
import { mockData } from "services/mock-data";

export const useQuantityDiscounts = (): IDictionaryDTO[] => {
  const [dictionary] = React.useState<IDictionaryDTO[]>(
    mockData.quantityDiscounts.map(
      (data: IQuantityDiscountStruct, index: number) => {
        return {
          id: index,
          name: data.description,
          code: data.counts,
          description: String(data.minCounts),
        };
      }
    )
  );

  return dictionary;
};
