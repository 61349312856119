import { SUBSCRIPTION_DURATIONS } from "constants/keys";

import { isSubscriptionReadyToRenewByDate } from "utils/is-subscription-ready-to-renew-by-date";
import { SubscriptionStatusCheckerByCode } from "utils/subscription-status-checker-by-code";

interface IIsSubscriptionReadyToRenewProps {
  statusCode: string | undefined;
  expiredDate: string | undefined;
  durationCode: string | undefined;
  inProlongation: boolean;
}

export const isSubscriptionReadyToRenew = ({
  statusCode,
  expiredDate,
  durationCode,
  inProlongation,
}: IIsSubscriptionReadyToRenewProps): boolean => {
  let result = false;

  const isTestSubscription: boolean =
    durationCode === SUBSCRIPTION_DURATIONS.TWO_WEEKS;
  const isSubscriptionWithDurationDay: boolean =
    durationCode === SUBSCRIPTION_DURATIONS.DAY;
  const isRenewalSubscription: boolean = inProlongation;

  if (
    !isTestSubscription &&
    !isSubscriptionWithDurationDay &&
    !isRenewalSubscription
  ) {
    const isSubscriptionActiveOrExpired: boolean =
      SubscriptionStatusCheckerByCode.isActivated(statusCode) ||
      SubscriptionStatusCheckerByCode.isExpired(statusCode);
    const isSubReadyToRenewByDate: boolean =
      isSubscriptionReadyToRenewByDate(expiredDate);

    if (isSubscriptionActiveOrExpired && isSubReadyToRenewByDate) {
      result = true;
    }
  }

  return result;
};
