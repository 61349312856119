import classes from "./plug.module.css";

interface IPlugProps {
  label?: string | JSX.Element;
  icon?: JSX.Element;
  children?: React.ReactNode;
}

export const Plug = (props: IPlugProps) => {
  const { label, children, icon } = props;

  return (
    <div className={classes.blockPlug}>
      <div className={classes.plugContent}>
        {!!icon && (
          <div className={classes.plugIcon} data-testid="plug_icon">
            {icon}
          </div>
        )}
        {label && (
          <span className={classes.plugContentText} data-testid="plug_text">
            {label}
          </span>
        )}
        {children ? children : <></>}
      </div>
    </div>
  );
};
