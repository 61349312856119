import { MTSSpinnerColor } from "../constants";
import { IMTSSpinnerColor } from "../interfaces";

export const useSpinnerColor = ({
  spinnerColor,
}: {
  spinnerColor: IMTSSpinnerColor;
}) => {
  let color = "";

  switch (spinnerColor) {
    case MTSSpinnerColor.black:
      color = "var(--color-icon-primary)";
      break;
    case MTSSpinnerColor.orange:
      color = "var(--color-accent-negative)";
      break;
    case MTSSpinnerColor.white:
      color = "var(--color-constant-greyscale-0)";
      break;
    case MTSSpinnerColor.red:
      color = "var(--color-brand)";
      break;
    default:
      color = "var(--color-brand)";
      break;
  }

  return color;
};
