import { QueryKeys } from "constants/keys";

import { useMutation, useQueryClient } from "react-query";

import { useNotifications } from "hooks/use-notifications";
import { deletePayment } from "services/api";

export const useMutationDeletePayment = () => {
  const { addErrorMessage, addSuccessMessage } = useNotifications();
  const queryClient = useQueryClient();

  return useMutation(
    (id: string) => {
      return deletePayment(id);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(QueryKeys.PAYMENTS);
        addSuccessMessage({ message: "Платёж успешно удалëн" });
      },
      onError: (error) => {
        addErrorMessage({ message: "Не удалось удалить платёж" });
      },
    }
  );
};
