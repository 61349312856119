import { PATH_PAYMENTS } from "constants/routes";

import { MTSLink } from "components/mts-link";

interface IMessageUnpaidPaymentIfRenewProps {
  isSubscriptionInfoPage?: boolean;
}

export const MessageUnpaidPaymentIfRenew = (
  props: IMessageUnpaidPaymentIfRenewProps
): JSX.Element => {
  const { isSubscriptionInfoPage = false } = props;

  return (
    <>
      У вас есть&nbsp;
      <MTSLink to={PATH_PAYMENTS}>неоплаченный счёт.</MTSLink>&nbsp;Оплатите или
      удалите его, чтобы продлить{" "}
      {isSubscriptionInfoPage ? "подписку" : "подписки"}
    </>
  );
};
