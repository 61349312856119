import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const Satellite = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="128"
      height="128"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M62.703 21.191C73.7527 10.1399 91.6751 10.1399 102.725 21.191C113.761 32.226 113.761 50.1617 102.725 61.1981C91.6751 72.2478 73.7527 72.2478 62.703 61.1981C51.6667 50.1617 51.6667 32.226 62.703 21.191Z"
        fill="url(#paint0_radial_5948_407922)"
      />
      <path
        d="M87.4724 25.3527L49.0387 62.1463L38.4414 73.1279L50.3332 63.5609L90.395 28.5435C93.1441 26.0733 95.7063 22.5382 95.3847 21.0689L95.3727 21.0556C93.7446 20.7353 90.1815 22.8451 87.4724 25.3527Z"
        fill="#DADADA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M62.9726 21.1905C74.0223 10.1395 91.9447 10.1395 102.994 21.1905C114.031 32.2255 114.031 50.1612 102.994 61.1976C91.9447 72.2473 74.0223 72.2473 62.9726 61.1976C51.9362 50.1612 51.9362 32.2255 62.9726 21.1905Z"
        fill="url(#paint1_radial_5948_407922)"
      />
      <path
        d="M79.903 58.655C98.1982 71.4159 116.384 76.9505 120.523 71.017C124.177 65.7783 115.659 53.3518 101.024 41.7311"
        stroke="#1D2023"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M66.7604 53.0246C49.1928 39.2794 38.7534 23.2774 43.4435 17.283C47.5843 11.9906 62.1339 16.2685 77.575 26.8024M77.575 26.8024L70.8504 27.4257M77.575 26.8024L75.5512 20.5319"
        stroke="#1D2023"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M62.437 32.2322C61.7905 26.0713 61.3843 22.201 58.4998 24.9996C50.2161 35.2445 50.8715 50.6232 62.0885 62.4128C73.319 74.2167 91.2522 75.2176 101.999 64.8787C95.3043 66.5602 81.6626 68.4711 70.4456 56.6829C64.3283 50.2525 63.2106 39.603 62.437 32.2322Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M60.9981 23.0001C63.5 27.5001 74.4985 46.9994 77.4985 49.9994C80.4985 52.9994 113.036 50.9635 102 61.9998C90.9504 73.0495 74.5496 72.5491 63.4999 61.4994C52.4635 50.4631 50.4968 36.0001 60.9981 23.0001Z"
        fill="url(#paint2_radial_5948_407922)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M59.5847 35.815C57.3548 36.4021 55.0341 36.8292 52.8989 38.4306L13.9046 65.3756L1 73.5427L12.7703 63.8275L55.1408 31.638C58.1168 29.4374 62.0936 27.6478 63.4668 28.2483L63.4815 28.275C64.7092 30.2501 62.6007 33.6677 59.5847 35.815Z"
        fill="#F0F0F0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M56.4084 32.5203L13.5175 64.3749L5.45703 70.1666L13.1171 64.0533L55.2741 31.8917C57.795 30.1702 59.8915 29.2107 60.5987 29.2627C60.9991 29.2894 59.8635 30.1849 59.5579 30.3971C58.6637 30.9842 57.8363 31.5047 56.4084 32.5203Z"
        fill="#E1E1E1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M62.4922 28.1685L62.4788 28.1551C62.8792 28.1151 63.2128 28.1418 63.465 28.2485L63.4797 28.2752C64.7074 30.2503 62.5989 33.668 59.583 35.8152C59.3548 35.8832 59.1279 35.9353 58.9023 35.9886C61.5714 33.72 63.6132 29.97 62.4922 28.1685Z"
        fill="#DBD9D9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M92.1339 63.1471L67.6191 110.362L60.0391 123.625L65.8976 109.521L87.343 64.0412C87.9969 62.4798 88.1304 60.8504 88.823 59.237C90.4257 55.9007 92.5342 54.4595 94.0289 54.3527L94.1223 54.4181C95.3888 55.9795 93.7887 59.8362 92.1339 63.1471Z"
        fill="#E1E1E1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M91.0515 60.8786C90.479 62.0116 89.7317 63.2927 89.0378 64.5351L67.0305 109.628L62.5078 118.542L66.5367 109.535L87.9034 64.2682C88.544 62.9591 88.584 62.2131 89.1846 60.8505C90.6258 58.1562 91.253 56.8751 93.1347 56.033C93.4817 55.8742 92.761 57.4622 92.6409 57.7959C92.2259 58.8234 91.8536 59.3159 91.0515 60.8786Z"
        fill="#F4F4F4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M88.3198 58.8841C89.9226 55.5478 92.0311 54.1065 93.5257 53.9998L93.6192 54.0652L93.7392 54.2253C92.2313 54.3334 90.2295 56.1203 88.6281 59.4566C88.0943 60.6843 87.774 62.0735 87 63.2599C87.5205 61.832 87.6939 60.3507 88.3198 58.8841Z"
        fill="#F4F4F4"
      />
      <defs>
        <radialGradient
          id="paint0_radial_5948_407922"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(82.7139 41.194) rotate(-89.9727) scale(34.6937 34.6898)"
        >
          <stop offset="0.257841" stopColor="white" />
          <stop offset="1" stopColor="#EAEAEA" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_5948_407922"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(82.9835 41.1935) rotate(-89.9727) scale(34.6937 34.6898)"
        >
          <stop offset="0.257841" stopColor="white" />
          <stop offset="1" stopColor="#EAEAEA" />
        </radialGradient>
        <radialGradient
          id="paint2_radial_5948_407922"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(79.4637 46.3425) rotate(-89.9707) scale(28.6251 30.7711)"
        >
          <stop offset="0.257841" stopColor="white" />
          <stop offset="1" stopColor="#EAEAEA" />
        </radialGradient>
      </defs>
    </SvgIcon>
  );
};

export default Satellite;
