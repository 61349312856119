import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  btn: {
    "&.MuiButton-root": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  btnRound: {
    "&.MuiButton-root": {
      borderRadius: "50% !important",
    },
  },
  btnSizeXS: {
    "&.MuiButton-root": {
      width: "24px",
      minWidth: "24px",
      maxWidth: "24px",
      height: "24px",
      minHeight: "24px",
      maxHeight: "24px",
      borderRadius: "4px",
    },
  },
  btnSizeS: {
    "&.MuiButton-root": {
      width: "32px",
      minWidth: "32px",
      maxWidth: "32px",
      height: "32px",
      minHeight: "32px",
      maxHeight: "32px",
      borderRadius: "6px",
    },
  },
  btnSizeM: {
    "&.MuiButton-root": {
      width: "44px",
      minWidth: "44px",
      maxWidth: "44px",
      height: "44px",
      minHeight: "44px",
      maxHeight: "44px",
      borderRadius: "8px",
    },
  },
  btnSizeL: {
    "&.MuiButton-root": {
      width: "52px",
      minWidth: "52px",
      maxWidth: "52px",
      height: "52px",
      minHeight: "52px",
      maxHeight: "52px",
      borderRadius: "8px",
    },
  },

  //
  // PRIMARY
  //
  btnPrimary: {
    "&.MuiButton-root": {
      backgroundColor: "var(--color-brand)",
      "&:hover": {
        backgroundColor: "rgba(212, 6, 16, 1)",
      },
      "&:active": {
        backgroundColor: "rgba(212, 6, 16, 1)",
      },
      "&:focus": {
        backgroundColor: "rgba(212, 6, 16, 1)",
      },
    },
  },

  //
  // SECONDARY
  //
  btnSecondary: {
    "&.MuiButton-root": {
      backgroundColor: "var(--color-control-tertiary-active)",
      "&:hover": {
        backgroundColor: "rgba(226, 229, 236, 1)",
      },
      "&:active": {
        backgroundColor: "rgba(226, 229, 236, 1)",
      },
      "&:focus": {
        backgroundColor: "rgba(226, 229, 236, 1)",
      },
    },
  },

  //
  // SECONDARY INVERTED
  //
  btnSecondaryInverted: {
    "&.MuiButton-root": {
      backgroundColor: "var(--color-background-primary)",
      "&:hover": {
        backgroundColor: "#EEF0F3",
      },
      "&:active": {
        backgroundColor: "#EEF0F3",
      },
      "&:focus": {
        backgroundColor: "#EEF0F3",
      },
    },
  },

  //
  // GHOST
  //
  btnGhost: {
    "&.MuiButton-root": {
      backgroundColor: "transparent",
      "&:hover": {
        backgroundColor: "#EEF0F3",
      },
      "&:active": {
        backgroundColor: "#EEF0F3",
      },
      "&:focus": {
        backgroundColor: "#EEF0F3",
      },
    },
  },

  //
  // OUTLINE
  //
  btnOutline: {
    "&.MuiButton-root": {
      backgroundColor: "var(--color-background-primary)",
      border: "2px solid",
      borderColor: "var(--color-control-inactive)",
      "&:hover": {
        backgroundColor: "var(--color-background-hover)",
        borderColor: "transparent",
      },
      "&:active": {
        backgroundColor: "var(--color-background-hover)",
        borderColor: "transparent",
      },
      "&:focus": {
        backgroundColor: "var(--color-background-hover)",
        borderColor: "transparent",
      },
    },
  },

  //
  // DISABLED
  //
  btnDisabled: {
    "&.MuiButton-root": {
      backgroundColor: "var(--color-control-inactive)",
      "&:hover": {
        backgroundColor: "var(--color-control-inactive)",
      },
      "&:active": {
        backgroundColor: "var(--color-control-inactive)",
      },
      "&:focus": {
        backgroundColor: "var(--color-control-inactive)",
      },
    },
  },

  spinnerBlock: {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  icon: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  iconVisibility: {
    visibility: "hidden",
  },
}));
