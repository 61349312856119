import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const RINEX = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="32" height="32" rx="8" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 9.33333H17.3334L21.3334 13.3333V21.3333C21.3334 22.0667 20.7334 22.6667 20 22.6667H11.9934C11.26 22.6667 10.6667 22.0667 10.6667 21.3333V10.6667C10.6667 9.93333 11.2667 9.33333 12 9.33333ZM12 21.3333H20V14H16.6667V10.6667H12V21.3333Z"
        fill="white"
      />
    </SvgIcon>
  );
};

export default RINEX;
