import { PROFILE_ROLE } from "constants/keys";
import {
  PATH_AGRONOMY,
  PATH_GEODESY_CADASTRE,
  PATH_INDUSTRY,
  PATH_MAP,
  PATH_PAYMENTS,
  PATH_RINEX_REQUESTS,
  PATH_SETTINGS,
  PATH_SUBSCRIPTIONS,
  PATH_TARIFFS,
} from "constants/routes";
import { _tabNames } from "constants/tabs";
import { PRODUCT_SUPPORT_URL } from "constants/urls";

import React from "react";

import { IMTSTab } from "components/mts-tabs";
import { useProfileData } from "hooks/use-profile-data";
import { generateId } from "utils/generate-id";

import { useTabNames } from "./use-tab-names";
import { useTabSelected } from "./use-tab-selected";

const tabMap: IMTSTab = {
  id: generateId(),
  name: _tabNames.map,
  type: "link",
  href: PATH_MAP,
  dataTestId: "lnk_nav_support",
  access: ["all"],
};

const tabIndustry: IMTSTab = {
  id: generateId(),
  name: _tabNames.industry,
  type: "folder",
  href: PATH_INDUSTRY,
  access: ["all"],
  exactId: false,
  children: [
    {
      id: generateId(),
      name: _tabNames.agro,
      type: "link-child",
      href: PATH_AGRONOMY,
      access: ["all"],
      description:
        "Оборудование и RTK-поправки, автоматизация сельхозтехники и дронов для обработки полей и аэрофотосъемки",
    },
    {
      id: generateId(),
      name: _tabNames.geodesyCadastrе,
      type: "link-child",
      href: PATH_GEODESY_CADASTRE,
      access: ["all"],
      description:
        "Корректирующие данные для проведения исследований и изысканий в строительстве и картографии",
    },
  ],
};

const tabSupport: IMTSTab = {
  id: generateId(),
  name: _tabNames.support,
  type: "link",
  href: PRODUCT_SUPPORT_URL,
  dataTestId: "lnk_nav_support",
  isRedirectTab: true,
  access: ["all"],
};

const _tabsNotAuthorized: IMTSTab[] = [tabMap, tabIndustry, tabSupport];

const _tabsAuthorized: IMTSTab[] = [
  {
    id: generateId(),
    name: _tabNames.request,
    type: "link",
    href: PATH_RINEX_REQUESTS,
    dataTestId: "lnk_nav_requests",
    access: [
      PROFILE_ROLE.POTENTIAL,
      PROFILE_ROLE.CUSTOMER_ADMIN,
      PROFILE_ROLE.CUSTOMER_BASE,
      PROFILE_ROLE.DEALER,
    ],
  },
  {
    id: generateId(),
    name: _tabNames.tariff,
    type: "link",
    href: PATH_TARIFFS,
    dataTestId: "lnk_nav_tariffs",
    access: [
      PROFILE_ROLE.POTENTIAL,
      PROFILE_ROLE.CUSTOMER_ADMIN,
      PROFILE_ROLE.DEALER,
    ],
  },
  {
    id: generateId(),
    name: _tabNames.subscriptions,
    type: "link",
    href: PATH_SUBSCRIPTIONS,
    dataTestId: "lnk_nav_subs",
    access: [
      PROFILE_ROLE.POTENTIAL,
      PROFILE_ROLE.CUSTOMER_ADMIN,
      PROFILE_ROLE.DEALER,
    ],
  },
  {
    id: generateId(),
    name: _tabNames.payments,
    type: "link",
    href: PATH_PAYMENTS,
    dataTestId: "lnk_nav_payments",
    access: [
      PROFILE_ROLE.POTENTIAL,
      PROFILE_ROLE.CUSTOMER_ADMIN,
      PROFILE_ROLE.DEALER,
    ],
  },
  {
    id: generateId(),
    name: _tabNames.settings,
    type: "link",
    href: PATH_SETTINGS,
    dataTestId: "lnk_nav_settings",
    access: [
      PROFILE_ROLE.POTENTIAL,
      PROFILE_ROLE.CUSTOMER_ADMIN,
      PROFILE_ROLE.CUSTOMER_BASE,
      PROFILE_ROLE.DEALER,
    ],
  },
];

export const useTabsInHeader = () => {
  const { tabNames } = useTabNames();
  const { isLoadingAuthData, profile, isAuthorized } = useProfileData();
  const [tabsNotAuthorized] = React.useState<IMTSTab[]>(_tabsNotAuthorized);
  const [tabs, setTabs] = React.useState<IMTSTab[]>([]);
  const { selectedTabId } = useTabSelected([
    ..._tabsNotAuthorized,
    ..._tabsAuthorized,
  ]);

  React.useEffect(() => {
    if (!isLoadingAuthData) {
      const profileRole: string = profile?.role || "";
      let newTabs: IMTSTab[] = [];

      if (isAuthorized && profileRole) {
        newTabs = _tabsAuthorized
          .filter((tabData: IMTSTab) => {
            return tabData.access.includes(profileRole);
          })
          .map((tabData: IMTSTab) => {
            if (tabData.href === PATH_SETTINGS) {
              return {
                ...tabData,
                name: tabNames.settings,
              };
            }

            return tabData;
          });
      }

      setTabs(newTabs);
    } else {
      setTabs([]);
    }
  }, [isLoadingAuthData, isAuthorized, profile?.role, tabNames.settings]);

  return { tabs, tabsNotAuthorized, selectedTabId };
};
