import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const MTSCashback = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="44" height="44" />
      <path
        d="M17.9387 10.1538H26.0618L16.9233 22L26.0618 33.8461H17.9387L11.139 24.9243C10.4983 24.084 10.1514 23.0566 10.1514 22C10.1514 20.9433 10.4983 19.9159 11.139 19.0756L17.9387 10.1538Z"
        fill="white"
      />
      <path
        d="M22.0002 18.6153H32.8309V25.3846H22.0002V18.6153Z"
        fill="white"
      />
    </SvgIcon>
  );
};

export default MTSCashback;
