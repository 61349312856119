import { IS_DEVELOPMENT_MODE } from "constants/env";

import React from "react";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import { styled } from "@mui/material/styles";
import { TransitionProps } from "@mui/material/transitions";
import { useDisplayStatus } from "hooks/use-display-status";

import classes from "./dialog-screen-restriction.module.css";
import screenImg from "./img/screen-img.png";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CustomDialog = styled(Dialog)(() => ({
  "&.MuiDialog-root": {
    backdropFilter: "blur(20px)",
  },
  "& .MuiDialog-paper": {
    overflowY: "unset",
    width: "359px",
    borderRadius: "16px",
    background: "var(--color-background-primary)",
    padding: "20px",
  },
  "& .MuiDialogContent-root": {
    padding: "0px",
  },
  "& .MuiDialogActions-root": {
    display: "flex",
    padding: "20px 0 0 0",
  },
}));

export const DialogScreenRestriction = (): JSX.Element => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const { isMobileVersion } = useDisplayStatus();

  React.useEffect(() => {
    if (isMobileVersion) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [isMobileVersion]);

  return (
    <CustomDialog
      open={IS_DEVELOPMENT_MODE ? false : isOpen}
      onClose={() => {}}
      TransitionComponent={Transition}
      disableEscapeKeyDown={true}
      sx={{ zIndex: 1001 }}
    >
      <DialogContent>
        <div className={classes.dialogImage}>
          <img src={screenImg} alt="screenImg" />
        </div>
        <div className={classes.dialogContent}>
          <p className={classes.dialogContentText}>
            Нужен экран большего размера
          </p>
          <p className={classes.dialogContentDesc}>
            Для правильного отображения сайта перейдите на устройство с большим
            экраном: планшет или компьютер
          </p>
        </div>
      </DialogContent>
    </CustomDialog>
  );
};
