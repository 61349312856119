import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const Tele2 = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="32" height="32" rx="8" />
      <path
        d="M21.6945 13.0532C20.5201 13.3021 19.9434 14.1616 20.01 15.5642L21.2621 15.4536C21.205 14.6612 21.4487 14.4733 21.6951 14.3699L21.6945 13.0532ZM19.9574 17.7683L19.9578 19H24L19.9574 17.7683ZM24 19V17.6472L19.9574 17.7684L24 19ZM23.4548 13.4358C23.0794 13.0829 22.5289 12.9382 21.9269 13.0242V14.3171C22.1707 14.3036 22.3682 14.3936 22.4562 14.5749C22.5952 14.8606 22.4519 15.2894 22.1136 15.6866C21.5915 16.2995 20.2031 17.5541 20.2031 17.5541L22.1962 17.4889C23.414 16.3053 23.8513 15.5868 23.9298 14.6901C23.971 14.2157 23.798 13.7586 23.4548 13.4358ZM14.6204 17.9297V19H16.1186V17.8847L14.6204 17.9297ZM13.3956 14.3585V19H14.4277V14.2141L13.3956 14.3585ZM17.8428 17.8326V19H19.5738V17.7806L17.8428 17.8326ZM17.8428 15.7775V16.9648L18.9774 16.9032V15.6809L17.8428 15.7775ZM17.8428 13.7352V14.9009L19.5738 14.7115V13.4931L17.8428 13.7352ZM16.4331 13.9323V19H17.621V13.7662L16.4331 13.9323ZM11.7409 18.0161V19H13.0493V17.9769L11.7409 18.0161ZM11.7409 16.2991V17.2969L12.5998 17.2503V16.2256L11.7409 16.2991ZM11.7409 14.5898V15.5698L13.0493 15.4266V14.4069L11.7409 14.5898ZM10.666 14.7402V19H11.5721V14.6134L10.666 14.7402ZM8.74955 16.0318V19H9.60942V15.9444L8.74955 16.0318ZM8 15.1129V15.9791L10.3488 15.7221V14.7846L8 15.1129Z"
        fill="white"
      />
    </SvgIcon>
  );
};

export default Tele2;
