import { SvgIcon, SvgIconProps } from "@mui/material";

const DeleteFill = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.0001 3.49951C5.00013 3.20064 5.02729 2.91182 5.06697 2.64928C5.14415 2.13872 5.18273 1.88344 5.48499 1.57547C5.78724 1.26749 6.06257 1.22057 6.61325 1.12674C7.029 1.05589 7.51175 1 8.0001 1C8.48844 1 8.9712 1.05589 9.38695 1.12674C9.93762 1.22057 10.213 1.26749 10.5152 1.57547C10.8175 1.88344 10.8561 2.13872 10.9332 2.64928C10.9729 2.91182 11.0001 3.20064 11.0001 3.49951H13.7774C14.1916 3.49951 14.5001 3.8353 14.5001 4.24951C14.5001 4.63643 14.2344 4.95491 13.8582 4.99521C13.942 5.83764 14.0001 6.84002 14.0001 8.00051C14.0001 9.26591 13.931 10.3433 13.8349 11.2303C13.6982 12.4911 13.6298 13.1215 12.8525 13.8987C12.0751 14.676 11.4427 14.7444 10.178 14.8813C9.52158 14.9524 8.78659 15.0005 8.00005 15.0005C7.21353 15.0005 6.47855 14.9524 5.82216 14.8813C4.55737 14.7444 3.92497 14.676 3.14761 13.8987C2.37025 13.1215 2.3019 12.4911 2.16521 11.2302C2.06906 10.3432 2.00005 9.26586 2.00005 8.00051C2.00005 6.84002 2.0581 5.83764 2.14189 4.99521C1.76568 4.95491 1.49999 4.63643 1.49999 4.24951C1.49999 3.8353 1.8085 3.49951 2.22271 3.49951H5.0001ZM8.0001 2.5C7.62026 2.5 7.22539 2.54405 6.86522 2.60542C6.74002 2.62676 6.6544 2.64149 6.58418 2.65532C6.57487 2.71045 6.56447 2.77857 6.55012 2.87347C6.51936 3.077 6.50013 3.28962 6.5001 3.49951H9.5001C9.50007 3.28962 9.48084 3.077 9.45008 2.87348C9.43573 2.77857 9.42532 2.71045 9.41602 2.65532C9.3458 2.64149 9.26017 2.62676 9.13498 2.60542C8.77481 2.54405 8.37994 2.5 8.0001 2.5ZM6.6668 7.25C7.08101 7.25 7.4168 7.58579 7.4168 8V10.6667C7.4168 11.0809 7.08101 11.4167 6.6668 11.4167C6.25259 11.4167 5.9168 11.0809 5.9168 10.6667V8C5.9168 7.58579 6.25259 7.25 6.6668 7.25ZM9.33347 7.25C9.74769 7.25 10.0835 7.58579 10.0835 8V10.6667C10.0835 11.0809 9.74769 11.4167 9.33347 11.4167C8.91926 11.4167 8.58347 11.0809 8.58347 10.6667V8C8.58347 7.58579 8.91926 7.25 9.33347 7.25Z"
      />
    </SvgIcon>
  );
};

export default DeleteFill;
