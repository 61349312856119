import React from "react";

import Button, { ButtonProps } from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import cn from "classnames";
import { MTSSpinner } from "components/mts-spinner";

import { Variant, Size } from "./constants";
import { useButtonTextColor } from "./hooks/use-button-text-color";
import { useStyles } from "./styles";
import { getBtnIconSize } from "./utils/get-btn-icon-size";

type ISize = keyof typeof Size;
type IVariant = keyof typeof Variant;

interface MTSButtonProps
  extends Omit<ButtonProps, "size" | "variant" | "endIcon" | "startIcon"> {
  size?: ISize;
  variant?: IVariant;
  disabled?: boolean;
  children?: React.ReactNode | string;
  loading?: boolean;
  noWrap?: boolean;
  IconEnd?: any;
  IconStart?: any;
}

const CustomButton = styled(Button)(({ theme }) => ({
  boxShadow: "none",
  textTransform: "none",
  color: "var(--color-background-primary)",
  backgroundColor: "var(--color-brand)",
  borderColor: "none",
  border: "none",
  "&:hover": {
    backgroundColor: "rgba(212, 6, 16, 1)",
    boxShadow: "none",
  },
  "&:active": {
    backgroundColor: "rgba(212, 6, 16, 1)",
    boxShadow: "none",
  },
  "&:focus": {
    backgroundColor: "rgba(212, 6, 16, 1)",
    boxShadow: "none",
  },
}));

export const MTSButton = (props: MTSButtonProps) => {
  const {
    size = Size.S,
    variant = Variant.primary,
    children = "Button",
    loading = false,
    noWrap = false,
    disabled = false,
    IconEnd = null,
    IconStart = null,
    ...rest
  } = props;
  const classes = useStyles();
  const textColor = useButtonTextColor({ variant, disabled });

  const btnClasses = cn({
    [classes.btn]: true,
    [classes.btnSizeS]: size === Size.S,
    [classes.btnSizeM]: size === Size.M,
    [classes.btnSizeL]: size === Size.L,
    [classes.btnSizeXL]: size === Size.XL,
    [classes.btnDisabled]: disabled,
    [classes.btnPrimary]: !disabled && variant === Variant.primary,
    [classes.btnPrimaryAlternate]:
      !disabled && variant === Variant.primary_alternate,
    [classes.btnAlwaysWhite]: !disabled && variant === Variant.always_white,
    [classes.btnSecondary]: !disabled && variant === Variant.secondary,

    [classes.btnGhost]: !disabled && variant === Variant.ghost,
    [classes.btnNegative]: !disabled && variant === Variant.negative,
  });

  const textStyles = cn({
    [classes.text]: true,
    [classes.textHidden]: !disabled && loading,
    [classes.textNoWrap]: noWrap,
    [classes.textSizeS]: size === Size.S,
    [classes.textSizeM]: size === Size.M,
    [classes.textSizeL]: size === Size.L,
    [classes.textSizeXL]: size === Size.XL,
  });

  const renderSpinner = (btnSize: string, btnVariant: string): JSX.Element => {
    if (
      btnVariant === Variant.primary ||
      btnVariant === Variant.primary_alternate
    ) {
      if (btnSize === Size.S || btnSize === Size.M) {
        return <MTSSpinner size="S" color="white" />;
      }

      if (btnSize === Size.L || btnSize === Size.XL) {
        return <MTSSpinner color="white" />;
      }
    }

    if (
      btnVariant === Variant.always_white ||
      btnVariant === Variant.secondary ||
      btnVariant === Variant.ghost
    ) {
      if (btnSize === Size.S || btnSize === Size.M) {
        return <MTSSpinner size="S" color="black" />;
      }

      if (btnSize === Size.L || btnSize === Size.XL) {
        return <MTSSpinner color="black" />;
      }
    }

    if (btnVariant === Variant.negative) {
      if (btnSize === Size.S || btnSize === Size.M) {
        return <MTSSpinner size="S" color="orange" />;
      }

      if (btnSize === Size.L || btnSize === Size.XL) {
        return <MTSSpinner color="orange" />;
      }
    }

    return <MTSSpinner size="S" color="white" />;
  };

  return (
    <CustomButton
      classes={{ root: btnClasses }}
      disableRipple
      disabled={disabled}
      {...rest}
    >
      {IconStart ? (
        <IconStart
          sx={{
            fontSize: getBtnIconSize(size),
            color: textColor,
            visibility: loading ? "hidden" : "visible",
          }}
        />
      ) : null}
      <span className={textStyles} style={{ color: textColor }}>
        {children}
      </span>
      {IconEnd ? (
        <IconEnd
          sx={{
            fontSize: getBtnIconSize(size),
            color: textColor,
            visibility: loading ? "hidden" : "visible",
          }}
        />
      ) : null}
      {!disabled && loading && (
        <div className={classes.spinnerBlock}>
          {renderSpinner(size, variant)}
        </div>
      )}
    </CustomButton>
  );
};
