import React from "react";

import classes from "./tariff-card-options.module.css";

export type ITariffCardOption = JSX.Element | string;

interface ITariffCardOptions {
  options: ITariffCardOption[];
}

export const TariffCardOptions = React.memo(
  (props: ITariffCardOptions): JSX.Element => {
    const { options } = props;

    return (
      <ul className={classes.blockTariffCardOptions}>
        {options.map((option, index) => (
          <li key={index} className={classes.cardOption}>
            <span className={classes.cardOptionText}>{option}</span>
          </li>
        ))}
      </ul>
    );
  }
);
