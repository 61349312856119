import { IS_DEVELOPMENT_MODE } from "./env";

export const USE_MOCK_DATA_IN_DEVELOPMENT_MODE = false;
export const IS_SERVER_MODE_MOCKED = IS_DEVELOPMENT_MODE
  ? USE_MOCK_DATA_IN_DEVELOPMENT_MODE
    ? true
    : false
  : false;

export const DEBOUNCE_REQUEST_TIME = 500;
export const DICTIONARY_SLATE_TIME = Infinity;
export const REFETCH_INTERVAL_TO_CHECK_PROFILE_DATA = 300000;
