import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export const AgroIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M26.4451 17.3093L41.1118 11.1556C41.5192 10.9505 42.5785 10.7864 43.5562 11.771C44.1674 12.3862 45.5118 14.2319 46.0007 16.6934L42.334 17.3093L42.0056 15.9864C41.8573 15.3889 41.2095 15.066 40.6432 15.3073L25.834 21.6163"
        stroke="#626C77"
        strokeLinecap="round"
      />
      <rect
        x="2.5"
        y="28.2694"
        width="35.6667"
        height="5.15371"
        stroke="#626C77"
      />
      <path
        d="M11.0547 25.0778C11.0547 24.8016 11.2785 24.5778 11.5547 24.5778H29.1103C29.3864 24.5778 29.6103 24.8016 29.6103 25.0778V28.5007H11.0547V25.0778Z"
        stroke="#626C77"
      />
      <path
        d="M17.166 14.0011C17.166 13.725 17.3899 13.5011 17.666 13.5011H25.4438C25.72 13.5011 25.9438 13.725 25.9438 14.0011V24.8085H17.166V14.0011Z"
        stroke="#626C77"
      />
      <path
        d="M19.1113 19.7696L22.1669 16.6927"
        stroke="#626C77"
        strokeLinecap="round"
      />
      <path
        d="M20.9453 21.0002L22.7786 19.1541"
        stroke="#626C77"
        strokeLinecap="round"
      />
      <path
        d="M2.61133 37.0001V33.9232"
        stroke="#626C77"
        strokeLinecap="round"
      />
      <path
        d="M8.11133 37.0001V33.9232"
        stroke="#626C77"
        strokeLinecap="round"
      />
      <path
        d="M14.2227 37.0001V33.9232"
        stroke="#626C77"
        strokeLinecap="round"
      />
      <path
        d="M20.334 37.0001V33.9232"
        stroke="#626C77"
        strokeLinecap="round"
      />
      <path
        d="M26.4453 37.0001V33.9232"
        stroke="#626C77"
        strokeLinecap="round"
      />
      <path
        d="M32.5566 37.0001V33.9232"
        stroke="#626C77"
        strokeLinecap="round"
      />
      <path
        d="M38.0547 37.0001V33.9232"
        stroke="#626C77"
        strokeLinecap="round"
      />
      <path
        d="M6.88867 27.7695V21.7697C6.88867 20.6651 7.7841 19.7697 8.88867 19.7697H17.2776"
        stroke="#626C77"
        strokeLinecap="round"
      />
      <path
        d="M8.11133 19.7711V17.4635C8.11133 16.3589 9.00676 15.4635 10.1113 15.4635H16.6669"
        stroke="#626C77"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
};
