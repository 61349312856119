import { QueryKeys } from "constants/keys";

import { useQuery } from "react-query";

import { AxiosError } from "axios";
import { useNotifications } from "hooks/use-notifications";
import { getAllBaseStationsCommon } from "services/api";

interface IUseBaseCommonStationsProps {
  enabled: boolean;
}

export const useBaseCommonStations = (props: IUseBaseCommonStationsProps) => {
  const { enabled } = props;
  const { addErrorMessage } = useNotifications();

  return useQuery(
    [QueryKeys.BASE_STATIONS_COMMON],
    () => getAllBaseStationsCommon(),
    {
      enabled,
      retry: 0,
      refetchOnWindowFocus: false,
      onError: (error: AxiosError) => {
        addErrorMessage({
          message: "Не удалось получить данные по базовым станциям",
        });
      },
    }
  );
};
