import { HTTPStatusCode, QueryKeys } from "constants/keys";

import { useQuery } from "react-query";

import { AxiosError } from "axios";
import { useNotifications } from "hooks/use-notifications";
import { IFileRequestPageableDTO } from "interfaces";
import { getRinexFileRequests } from "services/api/rinex-service";

export type IUseRinexFileRequestsProps = {
  payload: IFileRequestPageableDTO | null;
};

export const useRinexFileRequests = (props: IUseRinexFileRequestsProps) => {
  const { payload } = props;
  const { addErrorMessage } = useNotifications();

  return useQuery(
    [QueryKeys.RINEX_FILE_REQUESTS, payload],
    () => getRinexFileRequests(payload),
    {
      refetchOnWindowFocus: true,
      retry: 0,
      refetchInterval: 60 * 1 * 1000,
      enabled: !!payload,
      onError: (error: AxiosError) => {
        const status = error.response?.status;

        if (status !== HTTPStatusCode.UNAUTHORIZED_401) {
          addErrorMessage({ message: "Что-то пошло не так" });
        }
      },
    }
  );
};
