import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const Minus = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x="3" y="7" width="10" height="2" rx="1" />
    </SvgIcon>
  );
};

export default Minus;
