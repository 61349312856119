import React from "react";

export const useBodyOverflowHidden = (isMobileVersion: boolean): void => {
  React.useEffect(() => {
    if (isMobileVersion) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    return () => {
      document.body.style.overflow = "";
    };
  }, [isMobileVersion]);
};
