import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export const EyeIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_114893_11939)">
        <path d="M10.5006 12C10.5006 11.1719 11.1719 10.5006 11.9999 10.5006C12.828 10.5006 13.4992 11.1719 13.4992 12C13.4992 12.828 12.828 13.4993 11.9999 13.4993C11.1719 13.4993 10.5006 12.828 10.5006 12Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.4846 5.47032C8.37575 5.83148 7.58926 6.41268 6.01627 7.57507C5.11108 8.24398 4.28286 8.96978 3.58399 9.63636C2.5306 10.6411 2.00391 11.1434 2.00391 11.9997C2.00391 12.856 2.5306 13.3583 3.58399 14.363C4.28286 15.0296 5.11108 15.7554 6.01627 16.4243C7.58926 17.5867 8.37575 18.1679 10.4846 18.5291C11.1431 18.6419 12.8646 18.6419 13.5231 18.5291C15.6319 18.1679 16.4184 17.5867 17.9914 16.4243C18.8966 15.7554 19.7248 15.0296 20.4237 14.363C21.477 13.3583 22.0037 12.856 22.0037 11.9997C22.0037 11.1434 21.477 10.6411 20.4237 9.63636C19.7248 8.96978 18.8966 8.24398 17.9914 7.57507C16.4184 6.41268 15.6319 5.83148 13.5231 5.47032C12.8646 5.35755 11.1431 5.35755 10.4846 5.47032ZM11.9999 8.50049C10.0672 8.50049 8.50046 10.0673 8.50046 12C8.50046 13.9327 10.0672 15.4994 11.9999 15.4994C13.9326 15.4994 15.4994 13.9327 15.4994 12C15.4994 10.0673 13.9326 8.50049 11.9999 8.50049Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_114893_11939">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
