import { FIELD_NAMES, SUBSCRIPTION_REGIONS } from "constants/keys";

import { IPayGroupFormStruct } from "components/payment-configurator";
import { IDictionaryDTO, IPayGroupDTO } from "interfaces";
import { generateId } from "utils/generate-id";

import { getDurationsWithOutTest } from "../get-durations-with-out-test";

export const getParsedFormDataPayGroups = (props: {
  payGroups: IPayGroupDTO[] | undefined;
  serviceKinds: IDictionaryDTO[] | undefined;
  durations: IDictionaryDTO[] | undefined;
  regions: IDictionaryDTO[] | undefined;
}): IPayGroupFormStruct[] => {
  const { payGroups, serviceKinds, durations, regions } = props;
  const paymentData: IPayGroupFormStruct[] = [];
  const filteredDurations = getDurationsWithOutTest(durations);

  if (payGroups?.length && serviceKinds && filteredDurations) {
    payGroups.forEach((payGroupItem) => {
      const duration: IDictionaryDTO | undefined = filteredDurations?.find(
        (item) => item.code === payGroupItem.duration
      );
      const serviceKind: IDictionaryDTO | undefined = serviceKinds?.find(
        (item) => item.code === payGroupItem.serviceKind
      );
      const region: IDictionaryDTO | null =
        regions?.find(
          (regionItem: IDictionaryDTO) =>
            regionItem?.code === SUBSCRIPTION_REGIONS.ALL
        ) ?? null;
      const payGroupId: string = payGroupItem?.payGroupId
        ? payGroupItem.payGroupId
        : generateId();

      const paymentConfiguration: IPayGroupFormStruct = {
        [FIELD_NAMES.PAYMENT_CONFIGURATOR_COUNT]:
          payGroupItem.subscriptionsCount,
        [FIELD_NAMES.PAYMENT_CONFIGURATOR_SERVICE_KIND]: serviceKind || null,
        [FIELD_NAMES.PAYMENT_CONFIGURATOR_DURATION]: duration || null,
        [FIELD_NAMES.PAYMENT_CONFIGURATOR_REGION]: region,
        [FIELD_NAMES.PAYMENT_CONFIGURATOR_PAY_GROUP_ID]: payGroupId,
      };

      paymentData.push(paymentConfiguration);
    });
  }

  return paymentData;
};
