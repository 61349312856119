import { IconWrapper } from "components/icon-wrapper";
import { MTSIcon } from "components/mts-icon";
import { Plug } from "components/plug";

import classes from "./template-service-not-available.module.css";

interface ITemplateServiceNotAvailableProps {
  description?: string;
}

export const TemplateServiceNotAvailable = (
  props: ITemplateServiceNotAvailableProps
): JSX.Element => {
  const { description = "" } = props;

  return (
    <Plug
      icon={
        <IconWrapper
          size={64}
          icon={
            <MTSIcon.WarningCircle
              sx={{
                fontSize: "24px",
                color: "var(--color-constant-blackberry-light)",
              }}
            />
          }
        />
      }
      label={
        <div className={classes.info}>
          <h3 className={classes.infoTitle}>Сервис временно недоступен</h3>
          {description && (
            <span className={classes.infoDesc}>({description})</span>
          )}
        </div>
      }
    />
  );
};
