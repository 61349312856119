import { IMTSAutocompleteSize } from "../../interfaces";

export const getDropdownItemHeight = (size: IMTSAutocompleteSize): number => {
  switch (size) {
    case "s":
      return 32;
    case "m":
      return 44;
    case "l":
    case "xl":
      return 52;
    default:
      return 44;
  }
};
