import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const DoneRectangleSmooth = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_91959_431683)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.68585 4.68597C3.37297 5.99884 3.27563 7.30684 3.08093 9.92282C3.03046 10.601 2.99988 11.3005 2.99988 12C2.99988 12.6995 3.03046 13.399 3.08093 14.0772C3.27563 16.6932 3.37297 18.0012 4.68585 19.314C5.99872 20.6269 7.30671 20.7243 9.9227 20.9189C10.6008 20.9694 11.3004 21 11.9999 21C12.6994 21 13.3989 20.9694 14.0771 20.9189C16.693 20.7243 18.001 20.6269 19.3139 19.314C20.6268 18.0012 20.7241 16.6932 20.9188 14.0772C20.9693 13.399 20.9999 12.6995 20.9999 12C20.9999 11.3005 20.9693 10.601 20.9188 9.92282C20.7241 7.30684 20.6268 5.99884 19.3139 4.68597C18.001 3.3731 16.693 3.27575 14.0771 3.08105C13.3989 3.03058 12.6994 3 11.9999 3C11.3004 3 10.6008 3.03058 9.9227 3.08105C7.30671 3.27575 5.99872 3.3731 4.68585 4.68597ZM16.2069 10.2071C16.5974 9.81658 16.5974 9.18342 16.2069 8.79289C15.8164 8.40237 15.1832 8.40237 14.7927 8.79289L10.9998 12.5858L9.70688 11.2929C9.31635 10.9024 8.68319 10.9024 8.29266 11.2929C7.90214 11.6834 7.90214 12.3166 8.29266 12.7071L10.2927 14.7071C10.6832 15.0976 11.3164 15.0976 11.7069 14.7071L16.2069 10.2071Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_91959_431683">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default DoneRectangleSmooth;
