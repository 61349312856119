import React from "react";

import { Link, LinkProps } from "react-router-dom";

import classes from "./mts-link.module.css";

interface IMTSLinkProps extends LinkProps {
  children: React.ReactNode;
}

export const MTSLink = (props: IMTSLinkProps): JSX.Element => {
  const { children, ...rest } = props;

  return (
    <Link className={classes.link} {...rest}>
      {children}
    </Link>
  );
};
