import { SUBSCRIPTION_SERVICE_KINDS } from "constants/keys";

import { AppHelmet } from "components/app-helmet";
import { ContainerContent } from "components/container-content";
import { FooterOfPersonalArea } from "components/footer-of-personal-area";
import { Loader } from "components/loader";
import { PageTitle } from "components/page-title";
import { TariffCard, TariffCardTest } from "components/tariff-card";
import {
  GAP_EVENT_TYPE,
  GA_SCREEN_NAME,
  useGoogleAnalyticsPages,
} from "hooks/google-analytics";
import { useStatusTestSubscription } from "hooks/use-test-subscription-status";

import classes from "./tab-tariffs.module.css";

const PAGE_TITLE = "Тарифы";

export const TabTariffs = (): JSX.Element => {
  useGoogleAnalyticsPages({
    screenName: GA_SCREEN_NAME.tariffs,
    event: GAP_EVENT_TYPE.SCRN,
  });

  const {
    data: testSubscriptionStatusData,
    isLoading: isLoadingTestSubscriptionStatus,
    isTestSubscriptionUsed,
    isTestSubscriptionUsing,
    isTestSubscriptionNotUsed,
  } = useStatusTestSubscription();

  return (
    <>
      <AppHelmet title={`${PAGE_TITLE} - Личный кабинет`} />
      <ContainerContent>
        <PageTitle text={PAGE_TITLE} />

        {isLoadingTestSubscriptionStatus ? (
          <Loader />
        ) : (
          <>
            <div className={classes.info}>
              {isTestSubscriptionNotUsed && (
                <span className={classes.infoText}>
                  Выберите подходящий тариф или попробуйте услугу бесплатно,
                  подключив тестовый доступ
                </span>
              )}
              {(isTestSubscriptionUsing || isTestSubscriptionUsed) && (
                <span className={classes.infoText}>
                  Выберите подходящий тариф
                </span>
              )}
            </div>
            <div className={classes.block}>
              <div className={classes.blockCards}>
                <TariffCardTest
                  isPersonalAreaPage
                  isTestSubscriptionUsed={isTestSubscriptionUsed}
                  isTestSubscriptionUsing={isTestSubscriptionUsing}
                  isTestSubscriptionNotUsed={isTestSubscriptionNotUsed}
                  expiredDate={
                    testSubscriptionStatusData?.testModeExpiredOn ?? ""
                  }
                />
                <TariffCard
                  isPersonalAreaPage
                  serviceKindType={SUBSCRIPTION_SERVICE_KINDS.RTK_RINEX}
                />
                <TariffCard
                  isPersonalAreaPage
                  serviceKindType={SUBSCRIPTION_SERVICE_KINDS.RINEX}
                />
              </div>
            </div>
          </>
        )}
      </ContainerContent>
      <FooterOfPersonalArea />
    </>
  );
};
