import { PRODUCT_SUPPORT_URL } from "constants/urls";

import React from "react";

import classNames from "classnames";
import { Logo } from "components/logo";
import { MTSButtonIcon } from "components/mts-button-icon";
import { MTSIcon } from "components/mts-icon";
import { IMTSTab } from "components/mts-tabs";
import { UserInfo } from "components/user-info";
import { useContextMain } from "hooks/use-context-main";
import { getUserLabel } from "utils/get-user-role-label";

import { ButtonAllServices } from "../button-all-services";
import { MobileMenuNav } from "../mobile-menu-nav";
import { MobileMenuSupport } from "../mobile-menu-support";

import classes from "./dialog-menu.module.css";

interface IDialogMenuProps {
  onToggle: () => void;
  isOpen: boolean;
  isMounted: boolean;
  tabsAuthorized: IMTSTab[];
  tabsNotAuthorized: IMTSTab[];
}

export const DialogMenu = (props: IDialogMenuProps): JSX.Element => {
  const { profile } = useContextMain();
  const { onToggle, isOpen, isMounted, tabsNotAuthorized, tabsAuthorized } =
    props;

  const bgStyles = classNames({
    [classes.bg]: true,
    [classes.bgOpen]: isOpen,
    [classes.bgClose]: !isOpen,
  });

  const dialogBlockStyles = classNames({
    [classes.dialogBlock]: true,
    [classes.dialogBlockOpen]: isOpen,
    [classes.dialogBlockClose]: !isOpen,
  });

  return (
    <>
      {isMounted && (
        <div className={bgStyles}>
          <div className={dialogBlockStyles}>
            <div className={classes.header}>
              <Logo onClick={() => onToggle()} />

              <MTSButtonIcon
                onClick={() => onToggle()}
                mode="standard"
                Icon={MTSIcon.Close}
                iconSize={24}
                iconColor={"var(--color-icon-primary)"}
                variant="secondary"
                size="S"
              />
            </div>

            <nav className={classes.nav}>
              <MobileMenuNav
                onClickLink={() => onToggle()}
                data={tabsNotAuthorized.filter(
                  (tab) => tab.href !== PRODUCT_SUPPORT_URL
                )}
              />
              {!!tabsAuthorized.length && (
                <div className={classes.navTabsAuth}>
                  <div className={classes.navTabsAuthDevider} />

                  <MobileMenuNav
                    onClickLink={() => onToggle()}
                    data={tabsAuthorized}
                  />
                </div>
              )}
            </nav>

            <div className={classes.services}>
              <ButtonAllServices onClick={() => onToggle()} />
            </div>

            <div className={classes.footer}>
              <MobileMenuSupport />
              <UserInfo userRole={getUserLabel(profile?.role || "")} size="M" />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
