import classes from "./mts-card-custom.module.css";

interface IMTSCardCustomProps {
  children: JSX.Element;
  sx?: React.CSSProperties;
}

export const MTSCardCustom = (
  props: IMTSCardCustomProps
): JSX.Element | null => {
  const { children, sx } = props;

  return (
    <div className={classes.card} style={sx}>
      {children}
    </div>
  );
};
