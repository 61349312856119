import { REQUIRED_FIELD_MESSAGE } from "constants/keys";

import * as yup from "yup";

export const fieldDevicePasswordRequired = yup
  .string()
  .required(REQUIRED_FIELD_MESSAGE)
  .matches(/^[^A-Z]+$/, "Используйте только строчные буквы")
  .matches(/^[a-z0-9]*$/, "Используйте только буквы a-z и цифры")
  .min(5, "Не менее 5 символов")
  .max(5, "Не более 5 символов");
