import { REFETCH_INTERVAL_TO_CHECK_PROFILE_DATA } from "constants/server";
import { ITabNames, _tabNames } from "constants/tabs";

import React from "react";

import { IDialogOkProps } from "components/dialog-ok";
import { IProfileStruct, INotificationStruct } from "interfaces";

import { ContextMain } from "./context-main";

export const ContextMainProvider: React.FC = ({ children }) => {
  const [isAuthorized, setIsAuthorized] = React.useState<boolean>(false);
  const [isLoadingAuthData, setIsLoadingAuthData] =
    React.useState<boolean>(true);
  const [profile, setProfile] = React.useState<IProfileStruct | null>(null);
  const [notifications, setNotifications] = React.useState<
    INotificationStruct[]
  >([]);
  const [testSubscriptionStatus, setTestSubscriptionStatus] =
    React.useState<string>("");
  const [dialogOk, setDialogOk] = React.useState<IDialogOkProps | null>(null);
  const [tabNames, setTabNames] = React.useState<ITabNames>(_tabNames);

  const [timeIntervalCheckProfile, setTimeIntervalCheckProfile] =
    React.useState<number>(REFETCH_INTERVAL_TO_CHECK_PROFILE_DATA);

  const clearAuthData = () => {
    setProfile(null);
    setIsAuthorized(false);
  };

  return (
    <ContextMain.Provider
      value={{
        isAuthorized,
        setIsAuthorized,
        isLoadingAuthData,
        setIsLoadingAuthData,
        profile,
        setProfile,
        notifications,
        setNotifications,
        timeIntervalCheckProfile,
        setTimeIntervalCheckProfile,
        testSubscriptionStatus,
        setTestSubscriptionStatus,
        dialogOk,
        setDialogOk,
        tabNames,
        setTabNames,
        clearAuthData,
      }}
    >
      {children}
    </ContextMain.Provider>
  );
};

ContextMainProvider.displayName = "ContextMainProvider";
