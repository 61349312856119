import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export const CropsIcon = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon
      width="96"
      height="96"
      viewBox="0 0 96 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="64" cy="64" r="3" stroke="black" strokeWidth="2" />
      <circle cx="76" cy="64" r="3" stroke="black" strokeWidth="2" />
      <circle cx="76" cy="76" r="3" stroke="black" strokeWidth="2" />
      <circle cx="64" cy="76" r="3" stroke="black" strokeWidth="2" />
      <path
        d="M63.0603 45.3535L63.3464 41.8191C63.3829 41.3679 63.7155 40.9973 64.158 40.9146L67.6384 40.2641C68.4317 40.1159 68.7305 39.131 68.1546 38.5626L46.0623 16.7596C45.6696 16.3721 45.039 16.3775 44.653 16.7718L16.7294 45.2987C16.3427 45.6938 16.3482 46.3295 16.7416 46.7178L38.8561 68.5428C39.4272 69.1064 40.3926 68.7982 40.5357 68.0066L41.2053 64.3024C41.2852 63.8608 41.6473 63.5263 42.0917 63.4836L45.6102 63.1461C46.0913 63.0999 46.4702 62.7136 46.5094 62.2293L46.7896 58.7675C46.8288 58.2832 47.2077 57.8969 47.6887 57.8507L51.1272 57.5209C51.6083 57.4747 51.9872 57.0884 52.0264 56.604L52.3065 53.1423C52.3457 52.658 52.7246 52.2716 53.2057 52.2255L56.6442 51.8956C57.1252 51.8495 57.5041 51.4631 57.5433 50.9788L57.8235 47.517C57.8627 47.0327 58.2416 46.6464 58.7227 46.6002L62.1612 46.2704C62.6422 46.2242 63.0211 45.8379 63.0603 45.3535Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
