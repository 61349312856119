import { DateTime } from "luxon";

export const isSubscriptionReadyToRenewByDate = (
  expiredDate: string | undefined
): boolean => {
  let isReadyToRenew: boolean = false;
  let daysForRenew: number = 7;
  let dateForRenewInMiliseconds: number = daysForRenew * 86400000;

  if (expiredDate) {
    const _expiredDate = DateTime.fromISO(expiredDate);

    if (_expiredDate.isValid) {
      const currentDate = DateTime.now();
      const diffDates = _expiredDate.toMillis() - currentDate.toMillis();

      if (diffDates < dateForRenewInMiliseconds) {
        isReadyToRenew = true;
      }
    }
  }

  return isReadyToRenew;
};
