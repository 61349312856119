import { Size } from "../constants";

export const getSpinnerSize = (size: string): number => {
  switch (size) {
    case Size.S:
      return 16;
    case Size.M:
      return 24;
    case Size.L:
      return 40;
    default:
      return 24;
  }
};
