import { HTTPStatusCode, QueryKeys } from "constants/keys";

import { useQuery } from "react-query";

import { AxiosError } from "axios";
import { useNotifications } from "hooks/use-notifications";
import { getPaymentData } from "services/api";

interface IUsePaymentDataSettingsProps {
  useRefetchInterval?: boolean;
}

export const usePaymentData = (
  id: string,
  settings?: IUsePaymentDataSettingsProps
) => {
  const isUseRefetchInterval = settings?.useRefetchInterval;
  const { addErrorMessage } = useNotifications();

  return useQuery([QueryKeys.PAYMENT_DATA, id], () => getPaymentData(id), {
    refetchInterval: isUseRefetchInterval ? 10000 : 0,
    refetchOnWindowFocus: false,
    retry: 0,
    onError: (error: AxiosError) => {
      const status = error.response?.status;

      if (status !== HTTPStatusCode.UNAUTHORIZED_401) {
        addErrorMessage({ message: "Не удалось получить данные по платежу" });
      }
    },
  });
};
