import { IDictionaryDTO } from "interfaces";

export const getDictionaryItemCodeByName = (
  itemName: string | undefined,
  dictionary: IDictionaryDTO[] | undefined
): string => {
  let itemCode: string = "";

  if (itemName && dictionary?.length) {
    const dictionaryItem: IDictionaryDTO | undefined = dictionary.find(
      (item) => item.name === itemName
    );

    if (dictionaryItem) {
      itemCode = dictionaryItem.code;
    }
  }

  return itemCode;
};
