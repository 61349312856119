import { SUBSCRIPTION_STATUS_FILLED } from "constants/keys";

import React from "react";

import Box from "@mui/material/Box";
import Step from "@mui/material/Step";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { StepIconProps } from "@mui/material/StepIcon";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import { styled } from "@mui/material/styles";
import { MTSIcon } from "components/mts-icon";
import { SubscriptionStatusCheckerByCode } from "utils/subscription-status-checker-by-code";

import classes from "./subscription-info-stepper.module.css";

const STEP_ICON_WIDTH = 48;

const MTSStepIconRoot = styled("div")<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  zIndex: 1,
  width: STEP_ICON_WIDTH,
  height: STEP_ICON_WIDTH,

  position: "relative",
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",

  backgroundColor: "var(--color-background-stroke)",
  color: "var(--color-text-primary)",
  border: "1px solid",
  borderColor: "var(--color-background-stroke)",

  ...(ownerState.active && {
    backgroundColor: "var(--color-brand)",
    borderColor: "var(--color-brand)",
    color: "var(--color-text-inverted)",
  }),
  ...(ownerState.completed && {
    backgroundColor: "var(--color-background-primary)",
  }),
}));

const MTSStepIcon = (props: StepIconProps) => {
  const { active, completed, className, icon } = props;

  return (
    <MTSStepIconRoot ownerState={{ completed, active }} className={className}>
      <span className={classes.iconNumber}>{icon}</span>

      {completed && (
        <MTSIcon.Check
          sx={{
            position: "absolute",
            bottom: 0,
            right: `calc(-${STEP_ICON_WIDTH}px / 2 + 24px / 2)`,
            backgroundColor: "var(--color-accent-positive)",
            borderRadius: "50%",
            fontSize: "24px",
            color: "var(--color-background-primary)",
          }}
        />
      )}
    </MTSStepIconRoot>
  );
};

const MTSStepConnector = styled(StepConnector)(({ theme }) => ({
  marginLeft: `calc(${STEP_ICON_WIDTH}px / 2)`,
  [`&.${stepConnectorClasses.alternativeLabel}`]: {},
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "var(--color-background-stroke)",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "var(--color-background-stroke)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: "var(--color-background-stroke)",
    borderWidth: "1px",
  },
}));

interface IStepStruct {
  label: string;
  labelComplete: string;
  description: string;
}

const steps: IStepStruct[] = [
  {
    label: "Добавьте подписку",
    labelComplete: "Подписка добавлена",
    description: "",
  },
  {
    label: "Заполните поля",
    labelComplete: "Поля заполнены",
    description: "и сохраните изменения",
  },
  {
    label: "Активируйте подписку",
    labelComplete: "Подписка активирована",
    description: "чтобы начать её использовать",
  },
];

interface ISubscriptionInfoStepperProps {
  subcriptionStatus: string;
  subscriptionEditStatus: string;
}

export const SubscriptionInfoStepper = React.memo(
  (props: ISubscriptionInfoStepperProps) => {
    const { subcriptionStatus, subscriptionEditStatus } = props;

    const [activeStep, setActiveStep] = React.useState<number>(0);

    React.useEffect(() => {
      if (subcriptionStatus) {
        if (SubscriptionStatusCheckerByCode.isPaid(subcriptionStatus)) {
          if (subscriptionEditStatus === SUBSCRIPTION_STATUS_FILLED.FILLED) {
            setActiveStep(2);
          }
          if (subscriptionEditStatus === SUBSCRIPTION_STATUS_FILLED.EDITABLE) {
            setActiveStep(1);
          }
        }

        if (SubscriptionStatusCheckerByCode.isActivated(subcriptionStatus)) {
          setActiveStep(3);
        }
      }

      return () => {
        setActiveStep(0);
      };
    }, [subcriptionStatus, subscriptionEditStatus]);

    return (
      <div className={classes.stepper}>
        <Box sx={{ maxWidth: 400 }}>
          <Stepper
            connector={<MTSStepConnector />}
            activeStep={activeStep}
            orientation="vertical"
          >
            {steps.map((step: IStepStruct, index: number) => (
              <Step key={index}>
                <StepLabel
                  StepIconComponent={MTSStepIcon}
                  sx={{
                    "& .MuiStepLabel-iconContainer": {
                      paddingRight: "29px",
                    },
                    "&.MuiStepLabel-root": {
                      alignItems:
                        activeStep === index ? "flex-start" : "align-center",
                    },
                  }}
                >
                  <span className={classes.stepLabel}>
                    {activeStep - 1 < index ? step.label : step.labelComplete}
                  </span>
                  {activeStep === index && (
                    <div className={classes.stepDescription}>
                      {step.description}
                    </div>
                  )}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
      </div>
    );
  }
);
