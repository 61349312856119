import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const More = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M3.5 6.5C3.10218 6.5 2.72064 6.65804 2.43934 6.93934C2.15804 7.22064 2 7.60218 2 8C2 8.39782 2.15804 8.77936 2.43934 9.06066C2.72064 9.34196 3.10218 9.5 3.5 9.5C3.89782 9.5 4.27936 9.34196 4.56066 9.06066C4.84196 8.77936 5 8.39782 5 8C5 7.60218 4.84196 7.22064 4.56066 6.93934C4.27936 6.65804 3.89782 6.5 3.5 6.5ZM8 6.5C7.60218 6.5 7.22064 6.65804 6.93934 6.93934C6.65804 7.22064 6.5 7.60218 6.5 8C6.5 8.39782 6.65804 8.77936 6.93934 9.06066C7.22064 9.34196 7.60218 9.5 8 9.5C8.39782 9.5 8.77936 9.34196 9.06066 9.06066C9.34196 8.77936 9.5 8.39782 9.5 8C9.5 7.60218 9.34196 7.22064 9.06066 6.93934C8.77936 6.65804 8.39782 6.5 8 6.5ZM12.5 6.5C12.1022 6.5 11.7206 6.65804 11.4393 6.93934C11.158 7.22064 11 7.60218 11 8C11 8.39782 11.158 8.77936 11.4393 9.06066C11.7206 9.34196 12.1022 9.5 12.5 9.5C12.8978 9.5 13.2794 9.34196 13.5607 9.06066C13.842 8.77936 14 8.39782 14 8C14 7.60218 13.842 7.22064 13.5607 6.93934C13.2794 6.65804 12.8978 6.5 12.5 6.5Z" />
    </SvgIcon>
  );
};

export default More;
