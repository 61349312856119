import { FRONTEND_URL, MTS_LOGOUT_URL, STAND_NAME } from "constants/env";
import { LS_GAE_SUCCESS_AUTH } from "constants/keys";
import { IDENTITY_SERVICE_URL } from "constants/urls";

import axios, { AxiosResponse } from "axios";
import { IProfileStruct } from "interfaces";
import { $api } from "services/http";

export class AuthService {
  static login(): void {
    const url = `${IDENTITY_SERVICE_URL}/login?redirect_url=${window.location.href}`;

    window.location.href = url;
  }

  static async refresh(): Promise<AxiosResponse> {
    const url = `${IDENTITY_SERVICE_URL}/refresh`;

    return axios.get(url, {
      withCredentials: true,
    });
  }

  static async getProfile(
    useInstanceOfAxios?: boolean
  ): Promise<AxiosResponse<IProfileStruct>> {
    const url = `${IDENTITY_SERVICE_URL}/profile`;

    return useInstanceOfAxios
      ? $api.get(url)
      : axios.get(url, { withCredentials: true });
  }

  static async logout(): Promise<AxiosResponse> {
    const url = `${IDENTITY_SERVICE_URL}/logout`;

    localStorage.removeItem(LS_GAE_SUCCESS_AUTH);

    return axios
      .get(url, {
        withCredentials: true,
      })
      .finally(() => {
        const redirectURL: string =
          STAND_NAME === "DEV" ? document.location.origin : FRONTEND_URL;

        const logoutQuery: string = `?goto=${redirectURL}`;
        window.location.href = MTS_LOGOUT_URL + logoutQuery;
      });
  }
}
