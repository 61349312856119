import { QueryKeys } from "constants/keys";

import { useQuery } from "react-query";

import { getStatusUserAccessToDownloadRinex } from "services/api";

export const useAccessStatusDownloadRinex = () => {
  return useQuery(
    QueryKeys.USER_ACCESS_DOWNLOAD_RINEX,
    () => getStatusUserAccessToDownloadRinex(),
    {
      retry: 0,
      refetchOnWindowFocus: false,
    }
  );
};
