import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const ArrowUp = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M5.51246 15C4.61226 15 4.16144 13.9234 4.79798 13.2938C4.79798 13.2938 4.79797 13.2938 4.79798 13.2938L7.9273 10.1984C10.0829 8.06612 11.1607 7 12.5 7C13.8393 7 14.9171 8.06612 17.0727 10.1984L20.202 13.2938C20.8386 13.9234 20.3877 15 19.4875 15C19.2196 15 18.9625 14.8947 18.7731 14.7073L15.6437 11.6118C14.5255 10.5058 13.8276 9.82141 13.2544 9.38882C12.7371 8.99838 12.5565 8.99883 12.5028 8.99897L12.5 8.99897L12.4973 8.99897C12.4435 8.99883 12.2629 8.99838 11.7456 9.38882C11.1724 9.8214 10.4745 10.5058 9.35627 11.6118L6.22695 14.7073C6.22695 14.7073 6.22695 14.7073 6.22695 14.7073C6.03745 14.8947 5.78044 15 5.51246 15Z" />
    </SvgIcon>
  );
};

export default ArrowUp;
