import Accuracy from "./accuracy";
import AddFile from "./add-file";
import ArrowDown from "./arrow-down";
import ArrowDownMin from "./arrow-down-min";
import ArrowLeft from "./arrow-left";
import ArrowRight from "./arrow-right";
import ArrowUp from "./arrow-up";
import ArrowUpMin from "./arrow-up-min";
import ArrowsMin from "./arrows-min";
import Avatar from "./avatar";
import BackspaceFill from "./backspace-fill";
import Beeline from "./beeline";
import BeelineDisabled from "./beeline-disabled";
import Beta from "./beta";
import Business from "./business";
import Check from "./check";
import ChequeDone from "./cheque-done";
import ChevronDown from "./chevron-down";
import ChevronLeft from "./chevron-left";
import ChevronRight from "./chevron-right";
import ChevronUp from "./chevron-up";
import Clear from "./clear";
import Close from "./close";
import Company from "./company";
import CompanyLogo from "./company-logo";
import Connection from "./connection";
import Customization from "./customization";
import Date from "./date";
import Definition from "./definition";
import DeleteFill from "./delete-fill";
import DeleteOutline from "./delete-outline";
import Discount from "./discount";
import DoneRectangleSmooth from "./done-rectangle-smooth";
import DotsMenu from "./dots-menu";
import Download from "./download";
import Edit from "./edit";
import Error from "./error";
import ErrorRectangleSmooth from "./error-rectangle-smooth";
import Facebook from "./facebook";
import FileOnCloud from "./file-on-cloud";
import Hide from "./hide";
import Home from "./home";
import InfinitySymbol from "./infinity-symbol";
import InfoCircleFill from "./info-circle-fill";
import Information from "./information";
import Lock from "./lock";
import Mail from "./mail";
import MediaLive from "./media-live";
import Megafon from "./megafon";
import Minus from "./minus";
import More from "./more";
import MTS from "./mts";
import MTSBusiness from "./mts-business";
import MTSCashback from "./mts-cashback";
import Network from "./network";
import NextWeek from "./next-week";
import Notification from "./notification";
import Odnoklassniki from "./odnoklassniki";
import OkRound from "./ok-round";
import Payment from "./payment";
import Plug from "./plug";
import Plus from "./plus";
import Power from "./power";
import Profile from "./profile";
import PromisedPayment from "./promised-payment";
import Question from "./question";
import QuestionCircle from "./question-circle";
import Realiability from "./realiability";
import Ref from "./ref";
import Renewal from "./renewal";
import RestartCustom from "./restart-custom";
import Right from "./right";
import RINEX from "./RINEX";
import RTK from "./RTK";
import RubleCircle from "./ruble-circle";
import Satellite from "./satellite";
import Search from "./search";
import Shield from "./shield";
import Show from "./show";
import Smile from "./smile";
import Spinner from "./spinner";
import Success from "./success";
import Support from "./support";
import Supporting from "./supporting";
import Target from "./target";
import Tele2 from "./tele2";
import Time from "./time";
import Traffic from "./traffic";
import Twitter from "./twitter";
import UserList from "./user-list";
import VK from "./vk";
import VRS from "./VRS";
import VRSIcon from "./vrs-icon";
import Waiting from "./waiting";
import Warning from "./warning";
import WarningCircle from "./warning-circle";
import WarningCircleFill from "./warning-circle-fill";
import WarningRectangle from "./warning-rectangle";
import WarningRectangleSmooth from "./warning-rectangle-smooth";
import WifiTethering from "./wifi-tethering";

export const MTSIcon = {
  // NOTE: Checked icons [START]
  AddFile,
  ChevronDown,
  ChevronUp,
  ChevronLeft,
  ChevronRight,
  BackspaceFill,
  WarningCircleFill,
  InfoCircleFill,
  Search,
  Customization,
  Download,
  Plus,
  RestartCustom,
  DeleteFill,
  DeleteOutline,
  // NOTE: Checked icons [END]
  Twitter,
  CompanyLogo,
  Odnoklassniki,
  VK,
  Facebook,
  MTSBusiness,
  MTSCashback,
  Avatar,
  Company,
  Check,
  ArrowDown,
  ArrowRight,
  ArrowLeft,
  Close,
  Date,
  Edit,
  ArrowsMin,
  ArrowDownMin,
  ArrowUpMin,
  Home,
  WifiTethering,
  NextWeek,
  Spinner,
  Error,
  Renewal,
  Notification,
  DotsMenu,
  Warning,
  Minus,
  Question,
  InfinitySymbol,
  Success,
  More,
  Shield,
  Support,
  Business,
  FileOnCloud,
  Target,
  RubleCircle,
  Profile,
  Smile,
  Discount,
  Power,
  Payment,
  Beta,
  WarningRectangle,
  UserList,
  MediaLive,
  Plug,
  Show,
  Hide,
  MTS,
  Beeline,
  BeelineDisabled,
  Megafon,
  Tele2,
  RTK,
  RINEX,
  VRS,
  Ref,
  Time,
  Lock,
  Mail,
  ChequeDone,
  PromisedPayment,
  ArrowUp,
  Waiting,
  Information,
  WarningCircle,
  Satellite,
  Definition,
  Network,
  Accuracy,
  Connection,
  Realiability,
  Supporting,
  Traffic,
  VRSIcon,
  DoneRectangleSmooth,
  ErrorRectangleSmooth,
  WarningRectangleSmooth,
  QuestionCircle,
  Right,
  Clear,
  OkRound,
};
