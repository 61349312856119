import classes from "./icon-wrapper.module.css";

interface IIconWrapperProps {
  icon: JSX.Element;
  size?: number;
  backgroundColor?: string;
}

export const IconWrapper = (props: IIconWrapperProps): JSX.Element => {
  const { icon, size = 64, backgroundColor } = props;

  return (
    <div
      className={classes.blockIconWrapper}
      style={{
        width: `${size}px`,
        height: `${size}px`,
        backgroundColor: backgroundColor || "var(--color-background-secondary)",
      }}
    >
      {icon}
    </div>
  );
};
