import React from "react";

import { useLocation } from "react-router-dom";

import { IMTSTab } from "components/mts-tabs";

export const useTabSelected = (tabs: IMTSTab[]) => {
  const location = useLocation();
  const [selectedTabId, setSelectedTabId] = React.useState<string>("");

  React.useEffect(() => {
    const pathname: string = location.pathname;

    const selectedTabIndex: number | undefined = tabs.findIndex(
      (tabData: IMTSTab) => {
        if (tabData.href) {
          if (tabData.exactId) {
            return pathname === tabData.href;
          } else {
            return pathname.includes(tabData.href);
          }
        }

        return undefined;
      }
    );

    if (typeof selectedTabIndex === "number" && selectedTabIndex > -1) {
      setSelectedTabId(tabs[selectedTabIndex].id);
    } else {
      setSelectedTabId("");
    }
  }, [location.pathname, tabs, selectedTabId]);

  return {
    selectedTabId,
  };
};
