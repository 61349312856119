import { IDictionaryDTO } from "interfaces";

export const getDictionaryItemNameById = (
  id: number,
  dictionary: IDictionaryDTO[] | undefined
): string => {
  let itemName: string = "";

  if (Number.isInteger(id) && dictionary?.length) {
    const dictionaryItem: IDictionaryDTO | undefined = dictionary.find(
      (item) => item.id === id
    );

    if (dictionaryItem) {
      itemName = dictionaryItem.name;
    }
  }

  return itemName;
};
