import { QueryKeys } from "constants/keys";

import React from "react";

import { useQuery } from "react-query";

import { IDictionaryDTO, ITimeZoneDTO } from "interfaces";
import { getTimezones } from "services/api";

import { useNotifications } from "./use-notifications";

export const useTimezones = () => {
  const { addErrorMessage } = useNotifications();

  const query = useQuery({
    queryKey: [QueryKeys.TIMEZONES],
    queryFn: () => getTimezones(),
    cacheTime: Infinity,
    staleTime: Infinity,
    onError: () => {
      addErrorMessage({
        message: "Не удалось получить справочник с таймзонами",
      });
    },
  });

  const data: IDictionaryDTO[] = React.useMemo(() => {
    if (query.data) {
      return query.data.map((timeZoneData: ITimeZoneDTO) => ({
        id: timeZoneData.id,
        code: timeZoneData.code,
        name: timeZoneData.name,
        description: String(timeZoneData.timeValue),
      }));
    }

    return [];
  }, [query.data]);

  return {
    ...query,
    data,
  };
};
