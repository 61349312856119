import React from "react";

import { useMediaQuery } from "@mui/material";
import cn from "classnames";
import { useSwiper } from "swiper/react";
import { theme } from "theme";

import { ArrowNextIcon } from "./components/arrow-next-icon";
import { ArrowPrevIcon } from "./components/arrow-prev-icon";
import { ProgressBarCircle } from "./components/progress-bar-circle";
import {
  BUTTON_SLIDE_SIZE,
  BUTTON_SLIDE_SIZE_MEDIUM,
  BUTTON_SLIDE_SIZE_SMALL,
} from "./constants";
import classes from "./slider-nav.module.css";

interface ISlideNavProps {
  sliderProgress: number;
  onClickNext?: (next: string) => void;
  onClickPrev?: (prev: string) => void;
}

export const SliderNav = (props: ISlideNavProps): JSX.Element => {
  const { sliderProgress, onClickNext, onClickPrev } = props;
  const swiper = useSwiper();
  const progress =
    typeof sliderProgress === "number" &&
    sliderProgress <= 1 &&
    sliderProgress >= 0
      ? 100 - sliderProgress * 100
      : 0;

  const btnSlideNextStyles = cn({
    [classes.btn]: true,
    [classes.btnNext]: true,
  });

  const btnSlidePrevStyles = cn({
    [classes.btn]: true,
    [classes.btnPrev]: true,
  });

  const isMinWidthDesktop_960 = useMediaQuery(
    theme.breakpoints.up("desktopS_960")
  );
  const isMinWidthTablet_768 = useMediaQuery(
    theme.breakpoints.up("tablet_768")
  );

  return (
    <>
      <button
        className={btnSlideNextStyles}
        onClick={() => {
          swiper.slideNext();
          if (onClickNext) {
            onClickNext("next");
          }
        }}
      >
        <span className={classes.btnContent}>
          <ArrowNextIcon
            sx={{
              width: BUTTON_SLIDE_SIZE,
              height: BUTTON_SLIDE_SIZE,
              color: "transparent",
              [theme.breakpoints.down("desktopS_960")]: {
                width: BUTTON_SLIDE_SIZE_MEDIUM,
                height: BUTTON_SLIDE_SIZE_MEDIUM,
              },
              [theme.breakpoints.down("tablet_768")]: {
                width: BUTTON_SLIDE_SIZE_SMALL,
                height: BUTTON_SLIDE_SIZE_SMALL,
              },
            }}
          />
          <span className={classes.btnContentCircleProgress}>
            <ProgressBarCircle
              size={
                isMinWidthDesktop_960
                  ? BUTTON_SLIDE_SIZE
                  : isMinWidthTablet_768
                  ? BUTTON_SLIDE_SIZE_MEDIUM
                  : BUTTON_SLIDE_SIZE_SMALL
              }
              progress={progress}
              indicatorWidth={6}
              indicatorShiftDegrees={3}
            />
          </span>
        </span>
      </button>
      <button
        className={btnSlidePrevStyles}
        onClick={() => {
          swiper.slidePrev();
          if (onClickPrev) {
            onClickPrev("prev");
          }
        }}
      >
        <span className={classes.btn__content}>
          <ArrowPrevIcon
            sx={{
              width: BUTTON_SLIDE_SIZE,
              height: BUTTON_SLIDE_SIZE,
              color: "transparent",
              [theme.breakpoints.down("desktopS_960")]: {
                width: BUTTON_SLIDE_SIZE_MEDIUM,
                height: BUTTON_SLIDE_SIZE_MEDIUM,
              },
              [theme.breakpoints.down("tablet_768")]: {
                width: BUTTON_SLIDE_SIZE_SMALL,
                height: BUTTON_SLIDE_SIZE_SMALL,
              },
            }}
          />
        </span>
      </button>
    </>
  );
};
