import { FileRequestStatuses } from "constants/keys";
import { PATH_MAP } from "constants/routes";
import { TABLE_PAGE_SIZE } from "constants/table";

import React from "react";

import { useHistory } from "react-router";
import {
  useTable,
  usePagination,
  useSortBy,
  Hooks,
  useBlockLayout,
  useRowSelect,
  Column,
} from "react-table";
import { useSticky } from "react-table-sticky";

import Skeleton from "@mui/material/Skeleton";
import { MTSButton } from "components/mts-button";
import { renderFilterIcon } from "components/tables/utils/render-filter-icon";
import { useRinexFrequencies } from "hooks/use-rinex-frequencies";
import { IFileResponseDTO, ITableSort } from "interfaces";
import { getDateFormattedWithTimeZone } from "utils/get-date-formatted-with-time-zone";

import { RenderFilter } from "../render-filter";
import { TableCellWithTooltip } from "../table-cell-with-tooltip";
import { TableEmpty } from "../table-empty";

import { TableButtonDownloadRinex } from "./components/table-button-download-rinex";
import { TableCellFileRequestStatus } from "./components/table-cell-file-request-status";
import classes from "./table-rinex-requests.module.css";

enum TableAccessorKeys {
  id = "id",
  requestDate = "requestDate",
  beginDate = "beginDate",
  hourNumber = "hourNumber",
  stationName = "stationName",
  rinexVersion = "rinexVersion",
  rinexFreq = "rinexFreq",
  status = "status",
}

enum TableColumnIds {
  sticky = "sticky",
  checkbox = "checkbox",
  blank = "blank",
}

type ITableRinexRequestsProps = {
  data: IFileResponseDTO[];
  isLoading: boolean;
  pageSize: number;
  pageIndex: number;
  sorting: ITableSort;
  setSorting: React.Dispatch<React.SetStateAction<ITableSort>>;
  isShowTableEmpty: boolean;
};

export const TableRinexRequests = (props: ITableRinexRequestsProps) => {
  const { data, isLoading, pageSize, sorting, setSorting, isShowTableEmpty } =
    props;
  const history = useHistory();
  const { data: rinexFrequencies } = useRinexFrequencies();

  const tableColumns = React.useMemo<Column<IFileResponseDTO>[]>(
    () => [
      {
        Header: "Id",
        accessor: TableAccessorKeys.id,
        disableGlobalFilter: true,
      },
      {
        Header: "Дата запроса",
        accessor: TableAccessorKeys.requestDate,
        width: 199,
      },
      {
        Header: "Дата начала измерений",
        accessor: TableAccessorKeys.beginDate,
        width: 199,
      },
      {
        Header: "Кол-во часов",
        accessor: TableAccessorKeys.hourNumber,
        width: 127,
      },
      {
        Header: "ID станции",
        accessor: TableAccessorKeys.stationName,
        width: 140,
      },
      {
        Header: "Версия RINEX",
        accessor: TableAccessorKeys.rinexVersion,
        width: 123,
      },
      {
        Header: "Частота",
        accessor: TableAccessorKeys.rinexFreq,
        width: 93,
      },
      {
        Header: "Статус запроса",
        accessor: TableAccessorKeys.status,
        width: 143,
      },
    ],
    []
  );

  const hiddenColumns = React.useMemo(() => [TableAccessorKeys.id], []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    state: { sortBy },
  } = useTable(
    {
      columns: tableColumns,
      data: data,
      manualPagination: true,
      initialState: {
        pageSize: pageSize,
        sortBy: sorting,
        hiddenColumns,
      },
    },
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks: Hooks<any>) => {
      hooks.visibleColumns.push((columns) => {
        return [
          ...columns,
          {
            id: TableColumnIds.blank,
            Header: () => {
              return null;
            },
            Cell: () => {
              return null;
            },
            width: 0,
          },
          {
            id: TableColumnIds.sticky,
            Header: ({ selectedFlatRows }) => {
              return null;
            },
            Cell: ({ row }) => {
              const { original } = row;
              const { id, status } = original as IFileResponseDTO;
              const isReadyToDownload = status === FileRequestStatuses.complete;

              return (
                <div
                  key={id}
                  className={classes.buttons}
                  style={{
                    visibility: isReadyToDownload ? "visible" : "hidden",
                  }}
                >
                  <TableButtonDownloadRinex
                    rinexFileId={id}
                    disabled={!isReadyToDownload}
                  />
                </div>
              );
            },
            width: 56,
            sticky: "right",
          },
        ];
      });
    },
    useBlockLayout,
    useSticky
  );

  React.useEffect(() => {
    setSorting(sortBy as ITableSort);
  }, [setSorting, sortBy]);

  if (isLoading) {
    return (
      <Skeleton
        variant="rectangular"
        style={{
          height: `calc(${TABLE_PAGE_SIZE + 1} * var(--table-row-height))`,
        }}
      />
    );
  }

  if (!isLoading && isShowTableEmpty) {
    return (
      <TableEmpty
        pageSize={pageSize}
        icon="rinex-file-requests"
        text={
          <>
            Запросов пока нет.
            <br />
            Выберите станцию и создайте запрос
          </>
        }
      >
        <MTSButton
          variant="secondary"
          onClick={() => {
            history.push(PATH_MAP);
          }}
        >
          Выбрать станцию
        </MTSButton>
      </TableEmpty>
    );
  }

  return (
    <div className={classes.table} {...getTableProps()}>
      <div className={classes.thead}>
        {headerGroups.map((headerGroup: any) => {
          return (
            <div
              {...headerGroup.getHeaderGroupProps()}
              className={classes.theadTR}
            >
              {headerGroup.headers.map((column: any) => {
                const columnId: string = column.id;
                const isCanSort: boolean = column.canSort;

                if (columnId === TableColumnIds.blank) {
                  return <div className={classes.blank}></div>;
                }

                return (
                  <div
                    className={classes.th}
                    {...column.getHeaderProps({
                      style: {
                        width: column.width,
                      },
                      ...column.getSortByToggleProps({
                        title: null,
                      }),
                    })}
                  >
                    <p
                      className={classes.theadTypography}
                      data-testid={"txt_column_subs_" + columnId}
                    >
                      {column.render("Header")}
                    </p>
                    {isCanSort && renderFilterIcon(columnId) && (
                      <RenderFilter
                        isSorted={column.isSorted}
                        isSortedDesc={column.isSortedDesc}
                        dataTestId={"btn_sort_subs_" + columnId}
                      />
                    )}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>

      <div className={classes.tbody} {...getTableBodyProps()}>
        {page.map((pageRow: any, i: number) => {
          prepareRow(pageRow);

          return (
            <div {...pageRow.getRowProps()} className={classes.tbodyTR}>
              {pageRow.cells.map((cell: any) => {
                const { column, value } = cell;
                const columnId: string = column.id;

                if (columnId === TableAccessorKeys.beginDate) {
                  const beginDateISO = value;

                  const { date, time, zoneName } =
                    getDateFormattedWithTimeZone(beginDateISO);

                  return (
                    <div className={classes.td} {...cell.getCellProps()}>
                      <TableCellWithTooltip
                        text={`${date} ${time} ${zoneName}`}
                        cellWidth={column.width}
                        dataTestId={"txt_rinex_req_" + columnId}
                      />
                    </div>
                  );
                }

                if (columnId === TableAccessorKeys.requestDate) {
                  const requestDateISO = value;
                  const { date, time, zoneName } =
                    getDateFormattedWithTimeZone(requestDateISO);

                  return (
                    <div className={classes.td} {...cell.getCellProps()}>
                      <TableCellWithTooltip
                        text={`${date} ${time} ${zoneName}`}
                        cellWidth={column.width}
                        dataTestId={"txt_rinex_req_" + columnId}
                      />
                    </div>
                  );
                }

                if (columnId === TableAccessorKeys.status) {
                  const statusCode = value || "";

                  return (
                    <div className={classes.td} {...cell.getCellProps()}>
                      <TableCellFileRequestStatus statusCode={statusCode} />
                    </div>
                  );
                }

                if (columnId === TableAccessorKeys.stationName) {
                  const stationName = !!value ? value.split("_")[1] : "";

                  return (
                    <div className={classes.td} {...cell.getCellProps()}>
                      <TableCellWithTooltip
                        text={stationName}
                        cellWidth={column.width}
                        dataTestId={"txt_rinex_req_" + columnId}
                      />
                    </div>
                  );
                }

                if (columnId === TableAccessorKeys.rinexFreq) {
                  const rinexFreqCode = value;
                  const rinexFreqName =
                    rinexFrequencies.find(
                      (rinexFreqData) => rinexFreqData.code === rinexFreqCode
                    )?.name || "";

                  return (
                    <div className={classes.td} {...cell.getCellProps()}>
                      <TableCellWithTooltip
                        text={rinexFreqName}
                        cellWidth={column.width}
                        dataTestId={"txt_rinex_req_" + columnId}
                      />
                    </div>
                  );
                }

                if (columnId === TableColumnIds.blank) {
                  return <div className={classes.blank}></div>;
                }

                if (columnId === TableColumnIds.sticky) {
                  return (
                    <div
                      className={classes.td}
                      {...cell.getCellProps({
                        style: {
                          paddingLeft: 0,
                        },
                      })}
                    >
                      {cell.render("Cell")}
                    </div>
                  );
                }

                return (
                  <div className={classes.td} {...cell.getCellProps()}>
                    <TableCellWithTooltip
                      text={value}
                      cellWidth={column.width}
                      dataTestId={"txt_rinex_req_" + columnId}
                    />
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};
