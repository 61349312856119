import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const PromisedPayment = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M19.0758 7.97213C19.5144 7.82594 19.7994 7.39668 19.7412 6.93807C19.5645 5.54476 19.2447 4.61659 18.314 3.68597C17.0012 2.3731 15.6932 2.27575 13.0772 2.08105C12.399 2.03058 11.6995 2 11 2C10.3005 2 9.60095 2.03058 8.92282 2.08105C6.30684 2.27575 4.99884 2.3731 3.68597 3.68597C2.37309 4.99884 2.27575 6.30684 2.08105 8.92282C2.03058 9.60095 2 10.3005 2 11C2 11.6995 2.03058 12.399 2.08105 13.0772C2.27575 15.6932 2.37309 17.0012 3.68597 18.314C4.99884 19.6269 6.30684 19.7243 8.92282 19.9189C9.0082 19.9253 9.09392 19.9313 9.17993 19.937C9.7403 19.9706 10.2395 19.5016 10.24 18.9398V18.938C10.2395 18.3989 9.8462 17.9781 9.30841 17.9412L9.2718 17.9387C9.20483 17.9342 9.13798 17.9294 9.07126 17.9245C6.3209 17.7198 5.80638 17.606 5.10018 16.8998C4.39398 16.1936 4.28023 15.6791 4.07554 12.9287C4.02812 12.2916 4 11.6422 4 11C4 10.3578 4.02812 9.70842 4.07554 9.07126C4.28023 6.3209 4.39398 5.80638 5.10018 5.10018C5.80638 4.39398 6.3209 4.28023 9.07126 4.07554C9.70842 4.02812 10.3578 4 11 4C11.6422 4 12.2916 4.02812 12.9287 4.07554C15.6791 4.28023 16.1936 4.39398 16.8998 5.10018C17.3905 5.59086 17.5952 5.989 17.743 7.10888C17.8277 7.75006 18.4623 8.17665 19.0758 7.97213Z" />
      <path d="M10.9986 6C10.447 6.00075 10 6.44818 10 7V10.5877L8.29485 12.2929C7.90433 12.6834 7.90433 13.3166 8.29485 13.7071C8.68537 14.0976 9.31854 14.0976 9.70906 13.7071L11.7081 11.7081C11.9146 11.5015 12.0119 11.2271 12 10.9567V7C12 6.44818 11.553 6.00075 11.0014 6H10.9986Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.3091 10.0269C16.8047 10.0268 16.3314 10.0268 15.9434 10.0789C15.5117 10.137 15.0403 10.2755 14.6508 10.665C14.2614 11.0544 14.1228 11.5259 14.0648 11.9575C14.0126 12.3455 14.0127 12.8189 14.0127 13.3233L14.0127 15.0435H13.25C12.6977 15.0435 12.25 15.4912 12.25 16.0435C12.25 16.5958 12.6977 17.0435 13.25 17.0435H14.0127V17.9365H13.25C12.6977 17.9365 12.25 18.3842 12.25 18.9365C12.25 19.4888 12.6977 19.9365 13.25 19.9365H14.0127V20.8957C14.0127 21.4479 14.4604 21.8957 15.0127 21.8957C15.565 21.8957 16.0127 21.4479 16.0127 20.8957V19.9365H19.0102C19.5625 19.9365 20.0102 19.4888 20.0102 18.9365C20.0102 18.3842 19.5625 17.9365 19.0102 17.9365H16.0127V17.0435H18.3753C18.6648 17.0435 18.9422 17.0363 19.2039 17.0243C20.9642 16.9436 22.7725 15.7683 22.7725 13.6422C22.7725 11.4348 20.8981 10.1026 18.9882 10.0374C18.7913 10.0307 18.5865 10.0269 18.3753 10.0269L17.3091 10.0269ZM16.0127 15.0435H17.7501L17.7543 15.0435H18.3753C18.6319 15.0435 18.8787 15.0371 19.1123 15.0264C20.1786 14.9775 20.7725 14.3613 20.7725 13.6422C20.7725 12.7914 20.0545 12.0749 18.92 12.0362C18.7445 12.0302 18.5625 12.0269 18.3753 12.0269H17.3698C16.7856 12.0269 16.4486 12.029 16.2099 12.0611C16.1427 12.0701 16.0994 12.0797 16.073 12.0871C16.0656 12.1135 16.056 12.1569 16.0469 12.2241C16.0148 12.4628 16.0127 12.7997 16.0127 13.3839V15.0435Z"
      />
    </SvgIcon>
  );
};

export default PromisedPayment;
