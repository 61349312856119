import React from "react";

import classes from "./container-content.module.css";

interface IContainerContentProps {
  children: React.ReactNode;
  sx?: React.CSSProperties;
}

export const ContainerContent = (
  props: IContainerContentProps
): JSX.Element => {
  const { children, sx } = props;

  return (
    <div className={classes.blockContainerContent} style={sx}>
      {children}
    </div>
  );
};
