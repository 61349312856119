import React from "react";

import { useHistory } from "react-router-dom";

import { MTSTabs } from "components/mts-tabs";
import {
  GAE_ACTION_GROUP,
  GAE_BUTTON_LOCATION,
  GAE_EVENT,
  GAE_EVENT_ACTION,
  GAE_EVENT_CATEGORY,
  GAE_EVENT_LABEL,
  useGoogleAnalyticsEvents,
} from "hooks/google-analytics";
import { useTabsInHeader } from "hooks/tabs/use-tabs-in-header";
import { useContextHeader } from "hooks/use-context-header";
import { getEventLabelByTab } from "utils/google-analytics/get-event-label-by-tab";

export const TabBarAuthorized = (): JSX.Element | null => {
  const { sendGAE } = useGoogleAnalyticsEvents();
  const {
    setTabAuthorizedPositions,
    setIsOpenTabTooltip,
    isOpenTabTooltip,
    setOpenedTabData,
    closeTabTooltip,
  } = useContextHeader();
  const { tabs, selectedTabId } = useTabsInHeader();

  const history = useHistory();

  const handleChangeTab = (
    event: React.SyntheticEvent,
    newTabIndex: number
  ): void => {
    const tab = tabs[newTabIndex];
    const tabType = tab.type;
    const tabHref: string = tab.href;
    const isRedirectTab: boolean = tab?.isRedirectTab ?? false;

    if (tabType === "folder") {
      if (isOpenTabTooltip) {
        closeTabTooltip();
      } else {
        setIsOpenTabTooltip(true);
        setOpenedTabData({
          index: newTabIndex,
          data: tab,
        });
      }
    } else {
      closeTabTooltip();

      if (isRedirectTab) {
        const redirectUrl: string = tab.href;
        if (redirectUrl) {
          window.open(redirectUrl, "_blank");
        }
      } else {
        const eventLabel: GAE_EVENT_LABEL = getEventLabelByTab(tabHref);

        sendGAE({
          id: "34-38",
          event: GAE_EVENT.vntMenu,
          eventCategory: GAE_EVENT_CATEGORY.menu,
          eventAction: GAE_EVENT_ACTION.menu_click,
          eventLabel: eventLabel,
          eventValue: null,
          eventContent: null,
          buttonLocation: GAE_BUTTON_LOCATION.screen,
          filterName: null,
          actionGroup: GAE_ACTION_GROUP.interactions,
          productName: null,
        });

        history.push(tabHref);
      }
    }
  };

  if (tabs.length) {
    return (
      <MTSTabs
        size="s"
        selectedTab={tabs.findIndex((tab) => tab.id === selectedTabId)}
        tabs={tabs}
        onChange={handleChangeTab}
        setButtonsPosition={setTabAuthorizedPositions}
        onClick={() => {}}
        onMouseEnter={() => {}}
      />
    );
  }

  return null;
};
