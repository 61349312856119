import { FIELD_NAMES } from "constants/keys";
import { TABLE_PAGE_SIZE } from "constants/table";

import React from "react";

import {
  useTable,
  usePagination,
  useSortBy,
  Hooks,
  useBlockLayout,
  useGlobalFilter,
  useFilters,
  useRowSelect,
} from "react-table";
import { useSticky } from "react-table-sticky";

import { DialogConfirm, IDialogConfirmProps } from "components/dialog-confirm";
import { MTSButtonIcon } from "components/mts-button-icon";
import { MTSCheckbox } from "components/mts-checkbox";
import { MTSIcon } from "components/mts-icon";
import { MTSPagination } from "components/mts-pagination";
import { RenewSubscriptionMessage } from "components/renew-subscription-message";
import {
  GAP_EVENT_TYPE,
  GA_SCREEN_NAME,
  useGoogleAnalyticsPages,
} from "hooks/google-analytics";
import { useTableSubscriptionsColumns } from "hooks/table/use-table-subscriptions-columns";
import { useMutationProlongationForSubscriptions } from "hooks/use-mutation-prolongation-for-subscriptions";
import { useServiceKinds } from "hooks/use-service-kinds";
import { useSubscriptionStatuses } from "hooks/use-subscription-statuses";
import { ISubscriptionStruct, ITableSubscriptionsFilters } from "interfaces";
import { ContextSubscriptions } from "store/context-subscriptions";
import { getDictionaryItemNameById } from "utils/get-dictionary-item-name-by-id";
import { getStatusRenewDialog } from "utils/get-status-renew-dialog";
import { getSubscriptionStatusName } from "utils/get-subscription-status-name";
import { SubscriptionStatusCheckerByCode } from "utils/subscription-status-checker-by-code";

import { renderFilterIcon } from "../../utils/render-filter-icon";
import { RenderFilter } from "../render-filter";
import { TableCellSubscriptionName } from "../table-cell-subscription-name";
import { TableCellWithTooltip } from "../table-cell-with-tooltip";
import { TableEmpty } from "../table-empty";
import { TableNotFound } from "../table-not-found";

import { TableInfoMessage } from "./components/table-info-message";
import classes from "./table-subscriptions-renew.module.css";

enum TableColumnIds {
  sticky = "sticky",
  checkbox = "checkbox",
  blank = "blank",
}

interface ITableSubscriptionsProps {
  data: ISubscriptionStruct[];
  tableFilters: ITableSubscriptionsFilters;
  tableSortBy: any;
  isCanCreatePayment?: boolean;
}

export const TableSubscriptionsRenew = (props: ITableSubscriptionsProps) => {
  const { data, tableFilters, tableSortBy, isCanCreatePayment } = props;

  useGoogleAnalyticsPages({
    screenName: GA_SCREEN_NAME.subs_renew,
    event: GAP_EVENT_TYPE.SCRN,
  });

  const { subscriptionCounts } = React.useContext(ContextSubscriptions);
  const { tableColumns, widthOfColumns, hiddenColumns } =
    useTableSubscriptionsColumns();
  const mutationDoProlongationSubscription =
    useMutationProlongationForSubscriptions();

  const [dialogConfirm, setDialogConfirm] =
    React.useState<IDialogConfirmProps | null>(null);

  const { data: serviceKinds } = useServiceKinds();
  const { data: subscriptionStatuses } = useSubscriptionStatuses();

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setFilter,
    setGlobalFilter,
    state: { pageIndex, selectedRowIds },
  } = useTable(
    {
      columns: tableColumns,
      data: data,
      initialState: {
        pageIndex: 0,
        pageSize: TABLE_PAGE_SIZE,
        sortBy: tableSortBy,
        hiddenColumns,
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks: Hooks<any>) => {
      hooks.visibleColumns.push((columns) => {
        return [
          {
            id: TableColumnIds.checkbox,
            Header: ({
              getToggleAllPageRowsSelectedProps,
              toggleRowSelected,
              isAllPageRowsSelected,
              selectedFlatRows,
              page: currentPage,
            }) => {
              const modifiedOnChange = (event: any) => {
                currentPage.forEach((row) => {
                  !row.original.disabled &&
                    toggleRowSelected(row.id, event.currentTarget.checked);
                });
              };

              let selectableRowsInCurrentPage = 0;
              let selectedRowsInCurrentPage = 0;
              currentPage.forEach((row) => {
                row.isSelected && selectedRowsInCurrentPage++;
                !row.original.disabled && selectableRowsInCurrentPage++;
              });

              const disabled =
                selectableRowsInCurrentPage === 0 ||
                isCanCreatePayment === false;
              const checked =
                (isAllPageRowsSelected ||
                  selectableRowsInCurrentPage === selectedRowsInCurrentPage) &&
                !disabled;

              return (
                <div className={classes.checkbox}>
                  <MTSCheckbox
                    boxSize="small"
                    onChange={modifiedOnChange}
                    checked={checked}
                    disabled={disabled}
                    data-testid="chk_subs_all"
                  />
                </div>
              );
            },
            Cell: ({ row }) => {
              return (
                <div className={classes.checkbox}>
                  <MTSCheckbox
                    boxSize="small"
                    {...row.getToggleRowSelectedProps({
                      title: undefined,
                    })}
                    disabled={
                      !!row.original?.disabled || isCanCreatePayment === false
                    }
                    data-test-id="chk_subs"
                  />
                </div>
              );
            },
            sticky: "left",
            width: widthOfColumns.checkbox,
          },
          ...columns,
          {
            id: TableColumnIds.blank,
            Header: () => {
              return null;
            },
            Cell: () => {
              return null;
            },
            width: 0,
          },
          {
            id: TableColumnIds.sticky,
            Header: ({ selectedFlatRows }) => {
              const selectedSubs: ISubscriptionStruct[] = selectedFlatRows.map(
                (row) => row.original
              );

              return (
                <div
                  className={classes.buttons}
                  style={{
                    visibility: selectedSubs?.length > 0 ? "visible" : "hidden",
                  }}
                >
                  <MTSButtonIcon
                    size="XS"
                    Icon={MTSIcon.RestartCustom}
                    iconSize={16}
                    variant="secondary"
                    onClick={() => {
                      const selectedSubscriptionIds: number[] =
                        selectedSubs.map((sub) => sub.id);

                      mutationDoProlongationSubscription.mutate(
                        selectedSubscriptionIds
                      );
                    }}
                    disabled={isCanCreatePayment === false}
                    data-testid="btn_prolongation_subs"
                  />
                </div>
              );
            },

            sticky: "right",
            width: widthOfColumns.sticky,
          },
        ];
      });
    },
    useBlockLayout,
    useSticky
  );

  const countSelectedSubscriptions: number = Object.keys(selectedRowIds).length;

  React.useEffect(() => {
    setGlobalFilter(tableFilters.search || "");
    setFilter(
      FIELD_NAMES.SUBSCRIPTION_SERVICE_KIND,
      tableFilters.serviceKind?.id || null
    );

    setFilter(FIELD_NAMES.SUBSCRIPTION_DURATION, tableFilters.duration?.name);
  }, [setFilter, setGlobalFilter, tableFilters, data]);

  const handleRenewSub = (subId: number): void => {
    const doProlongation = (): void => {
      mutationDoProlongationSubscription.mutate([subId]);
    };

    if (getStatusRenewDialog().isDontShowDialog) {
      doProlongation();
    } else {
      if (subscriptionCounts.canRenew > 5) {
        const closeDialog = (): void => {
          setDialogConfirm((prevDialogProps) => ({
            ...prevDialogProps,
            isOpen: false,
          }));
        };

        const handleDoPrologation = (): void => {
          closeDialog();
          doProlongation();
        };

        const dialogProps: IDialogConfirmProps = {
          isOpen: true,
          title: "Скидка за продление подписок",
          content: <RenewSubscriptionMessage />,
          confirmLabel: "Продлить несколько",
          cancelLabel: "Продлить одну",
          handleCancel: () => handleDoPrologation(),
          handleClose: () => closeDialog(),
          handleConfirm: () => closeDialog(),
        };

        setDialogConfirm(dialogProps);
      } else {
        doProlongation();
      }
    }
  };

  if (data.length === 0) {
    return (
      <TableEmpty pageSize={TABLE_PAGE_SIZE} icon="subs" text="Подписок нет" />
    );
  }

  if (data.length && page.length === 0) {
    return <TableNotFound />;
  }

  return (
    <>
      <TableInfoMessage
        countSelectedSubscriptions={countSelectedSubscriptions}
        canCreatePayment={isCanCreatePayment}
      />
      <div className={classes.tableContainer}>
        <div className={classes.tableWrap}>
          <div className={classes.table} {...getTableProps()}>
            <div className={classes.thead}>
              {headerGroups.map((headerGroup: any) => (
                <div
                  {...headerGroup.getHeaderGroupProps()}
                  className={classes.theadTR}
                >
                  {headerGroup.headers.map((column: any) => {
                    const columnId: string = column.id;

                    if (columnId === TableColumnIds.blank) {
                      return <div className={classes.blank}></div>;
                    }

                    if (
                      columnId === TableColumnIds.checkbox ||
                      columnId === TableColumnIds.sticky
                    ) {
                      return (
                        <div
                          className={classes.th}
                          {...column.getHeaderProps({
                            style: {
                              width: column.width,
                              paddingLeft: 0,
                              justifyContent: "flex-end",
                            },
                            title: null,
                          })}
                        >
                          {column.render("Header")}
                        </div>
                      );
                    }

                    return (
                      <div
                        className={classes.th}
                        {...column.getHeaderProps({
                          style: {
                            width: column.width,
                          },
                          ...column.getSortByToggleProps({
                            title: null,
                          }),
                        })}
                      >
                        <p
                          className={classes.theadTypography}
                          data-testid={"txt_column_subs_" + columnId}
                        >
                          {column.render("Header")}
                        </p>
                        {renderFilterIcon(columnId) && (
                          <RenderFilter
                            isSorted={column.isSorted}
                            isSortedDesc={column.isSortedDesc}
                            dataTestId={"btn_sort_subs_" + columnId}
                          />
                        )}
                      </div>
                    );
                  })}
                </div>
              ))}
            </div>

            <div className={classes.tbody} {...getTableBodyProps()}>
              {page.map((pageRow: any, i: number) => {
                prepareRow(pageRow);

                return (
                  <div {...pageRow.getRowProps()} className={classes.tbodyTR}>
                    {pageRow.cells.map((cell: any) => {
                      const { column, value, row } = cell;
                      const columnId: string = column.id;
                      const { status } = row.values;
                      const textStyles: React.CSSProperties = {
                        color: SubscriptionStatusCheckerByCode.isExpired(status)
                          ? "var(--color-text-tertiary)"
                          : "",
                      };

                      if (columnId === FIELD_NAMES.SUBSCRIPTION_NAME) {
                        const subscriptionId: number = row.original.id;

                        return (
                          <div className={classes.td} {...cell.getCellProps()}>
                            <TableCellSubscriptionName
                              subscriptionId={subscriptionId}
                              value={value}
                              subscriptionCountCanRenew={
                                subscriptionCounts.canRenew
                              }
                              columnWidth={column.width}
                              dataTestId={"btn_subs_" + columnId}
                            />
                          </div>
                        );
                      }

                      if (columnId === FIELD_NAMES.SUBSCRIPTION_SERVICE_KIND) {
                        const serviceKindId = value;

                        return (
                          <div className={classes.td} {...cell.getCellProps()}>
                            <TableCellWithTooltip
                              text={getDictionaryItemNameById(
                                serviceKindId,
                                serviceKinds
                              )}
                              textStyles={textStyles}
                              cellWidth={column.width}
                              dataTestId={"txt_subs_" + columnId}
                            />
                          </div>
                        );
                      }

                      if (
                        columnId === FIELD_NAMES.SUBSCRIPTION_DURATION ||
                        columnId === FIELD_NAMES.SUBSCRIPTION_PHONE ||
                        columnId === FIELD_NAMES.SUBSCRIPTION_EMAIL ||
                        columnId === FIELD_NAMES.SUBSCRIPTION_DESCRIPTION
                      ) {
                        return (
                          <div className={classes.td} {...cell.getCellProps()}>
                            <TableCellWithTooltip
                              text={value}
                              textStyles={textStyles}
                              cellWidth={column.width}
                              dataTestId={"txt_subs_" + columnId}
                            />
                          </div>
                        );
                      }

                      if (columnId === FIELD_NAMES.SUBSCRIPTION_STATUS) {
                        const { createdDate, expiredDate } = row.values;
                        const statusName = getSubscriptionStatusName(
                          value,
                          subscriptionStatuses,
                          createdDate,
                          expiredDate
                        );

                        return (
                          <div className={classes.td} {...cell.getCellProps()}>
                            <TableCellWithTooltip
                              text={statusName}
                              textStyles={textStyles}
                              cellWidth={column.width}
                              dataTestId={"txt_subs_" + columnId}
                            />
                          </div>
                        );
                      }

                      if (columnId === TableColumnIds.blank) {
                        return <div className={classes.blank}></div>;
                      }

                      if (columnId === TableColumnIds.checkbox) {
                        return (
                          <div
                            className={classes.td}
                            {...cell.getCellProps({
                              style: {
                                paddingLeft: 0,
                              },
                            })}
                            dataTestId={"txt_subs_" + columnId}
                          >
                            {cell.render("Cell")}
                          </div>
                        );
                      }

                      if (columnId === TableColumnIds.sticky) {
                        const { values } = row;
                        const { id } = values as ISubscriptionStruct;
                        const isDisabled: boolean =
                          row.original?.disabled ||
                          isCanCreatePayment === false;

                        return (
                          <div
                            className={classes.td}
                            {...cell.getCellProps({
                              style: {
                                paddingLeft: "0px",
                                justifyContent: "flex-end",
                              },
                            })}
                          >
                            <div className={classes.buttons}>
                              <MTSButtonIcon
                                size="XS"
                                Icon={MTSIcon.RestartCustom}
                                iconSize={16}
                                variant="secondary"
                                onClick={() => handleRenewSub(id)}
                                disabled={isDisabled}
                                data-testid="btn_prolongation_sub"
                              />
                            </div>
                          </div>
                        );
                      }

                      return (
                        <div className={classes.td} {...cell.getCellProps()}>
                          <TableCellWithTooltip
                            text={value}
                            cellWidth={column.width}
                            dataTestId={"txt_subs_" + columnId}
                          />
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        {Boolean(pageCount > 1) && (
          <MTSPagination
            pageIndex={pageIndex}
            pageCount={pageCount}
            canNextPage={canNextPage}
            canPreviousPage={canPreviousPage}
            gotoPage={gotoPage}
            nextPage={nextPage}
            previousPage={previousPage}
          />
        )}

        <DialogConfirm {...dialogConfirm} />
      </div>
    </>
  );
};
