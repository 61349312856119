import { QueryKeys } from "constants/keys";

import { useMutation, useQueryClient } from "react-query";

import { useNotifications } from "hooks/use-notifications";
import { refreshRole } from "services/api";

export const useMutationRefreshRole = () => {
  const { addErrorMessage } = useNotifications();
  const queryClient = useQueryClient();

  return useMutation(() => refreshRole(), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(QueryKeys.PROFILE);
    },
    onError: (error) => {
      addErrorMessage({ message: "Не удалось обновить роль пользователя" });
    },
  });
};
