import { _tabNames } from "constants/tabs";

import React from "react";

import { useHistory } from "react-router-dom";

import { MTSTabs } from "components/mts-tabs";
import {
  GAE_ACTION_GROUP,
  GAE_BUTTON_LOCATION,
  GAE_EVENT,
  GAE_EVENT_ACTION,
  GAE_EVENT_CATEGORY,
  GAE_EVENT_LABEL,
  useGoogleAnalyticsEvents,
} from "hooks/google-analytics";
import { useTabsInHeader } from "hooks/tabs/use-tabs-in-header";
import { useContextHeader } from "hooks/use-context-header";
import { useContextMain } from "hooks/use-context-main";
import { getEventLabelByTab } from "utils/google-analytics/get-event-label-by-tab";

export const TabBarNotAuthorized = (): JSX.Element | null => {
  const history = useHistory();
  const { isAuthorized } = useContextMain();
  const {
    setTabNotAuthorizedPositions,
    setIsOpenTabTooltip,
    isOpenTabTooltip,
    setOpenedTabData,
    closeTabTooltip,
  } = useContextHeader();
  const { tabsNotAuthorized, selectedTabId } = useTabsInHeader();
  const { sendGAE } = useGoogleAnalyticsEvents();

  const handleChangeTab = (
    event: React.SyntheticEvent,
    newTabIndex: number
  ): void => {
    const tab = tabsNotAuthorized[newTabIndex];
    const tabType = tab.type;
    const tabHref: string = tab.href;
    const isRedirectTab: boolean = tab?.isRedirectTab ?? false;

    if (tabType === "folder") {
      if (isOpenTabTooltip) {
        closeTabTooltip();
      } else {
        setIsOpenTabTooltip(true);
        setOpenedTabData({
          index: newTabIndex,
          data: tab,
        });
      }
    } else {
      closeTabTooltip();

      if (isRedirectTab) {
        const redirectUrl: string = tab.href;
        if (redirectUrl) {
          window.open(redirectUrl, "_blank");
        }
      } else {
        const eventLabel: GAE_EVENT_LABEL = getEventLabelByTab(tabHref);

        sendGAE({
          id: "34-38",
          event: GAE_EVENT.vntMenu,
          eventCategory: GAE_EVENT_CATEGORY.menu,
          eventAction: GAE_EVENT_ACTION.menu_click,
          eventLabel: eventLabel,
          eventValue: null,
          eventContent: null,
          buttonLocation: GAE_BUTTON_LOCATION.screen,
          filterName: null,
          actionGroup: GAE_ACTION_GROUP.interactions,
          productName: null,
        });

        history.push(tabHref);
      }
    }
  };

  const handleSendMetrics = (e: React.SyntheticEvent, tabName: string) => {
    if (tabName === _tabNames.industry) {
      if (isAuthorized) {
        if (e.type === "click") {
          sendGAE({
            id: 70,
            event: GAE_EVENT.vntMain,
            eventCategory: GAE_EVENT_CATEGORY.menu,
            eventAction: GAE_EVENT_ACTION.menu_click,
            eventLabel: GAE_EVENT_LABEL.otraslevye_resheniya,
            eventValue: null,
            eventContent: null,
            buttonLocation: null,
            filterName: null,
            actionGroup: GAE_ACTION_GROUP.non_interactions,
            productName: null,
          });
        }
      } else {
        if (e.type === "click") {
          sendGAE({
            id: 49,
            event: GAE_EVENT.vntMain,
            eventCategory: GAE_EVENT_CATEGORY.glavnaya,
            eventAction: GAE_EVENT_ACTION.element_click,
            eventLabel: GAE_EVENT_LABEL.otraslevye_resheniya,
            eventValue: null,
            eventContent: null,
            buttonLocation: GAE_BUTTON_LOCATION.screen,
            filterName: null,
            actionGroup: GAE_ACTION_GROUP.interactions,
            productName: null,
          });
        } else if (e.type === "mouseenter") {
          sendGAE({
            id: 50,
            event: GAE_EVENT.vntMain,
            eventCategory: GAE_EVENT_CATEGORY.glavnaya,
            eventAction: GAE_EVENT_ACTION.element_hover,
            eventLabel: GAE_EVENT_LABEL.otraslevye_resheniya,
            eventValue: null,
            eventContent: null,
            buttonLocation: GAE_BUTTON_LOCATION.screen,
            filterName: null,
            actionGroup: GAE_ACTION_GROUP.interactions,
            productName: null,
          });
        }
      }
    }
  };

  return (
    <MTSTabs
      size="s"
      selectedTab={tabsNotAuthorized.findIndex(
        (tab) => tab.id === selectedTabId
      )}
      tabs={tabsNotAuthorized}
      onChange={handleChangeTab}
      setButtonsPosition={setTabNotAuthorizedPositions}
      onClick={handleSendMetrics}
      onMouseEnter={handleSendMetrics}
    />
  );
};
