export enum QueryKeys {
  PAYMENTS = "payments",
  PAYMENT_DATA = "payment-data",
  PROFILE_SUBSCRIPTIONS = "profile-subscriptions",
  PROFILE_SUBSCRIPTIONS_BY_PAYMENT = "profile-subscriptions-by-payment",
  BILLING_SUBSCRIPTIONS = "billing-subscriptions",
  BILLING_SUBSCRIPTIONS_BY_PAYMENT = "billing-subscriptions-by-payment",
  PROFILE = "profile",
  USER_ACCESS_DOWNLOAD_RINEX = "user-access-download-rinex",
  SUBSCRIPTION_INFO_COMMON = "subscription-info-common",
  BASE_STATIONS = "base-stations",
  BASE_STATIONS_COMMON = "base-stations-common",
  PLANNED_BASE_STATIONS = "planned-base-stations",
  CHECK_ABILITY_TO_CREATE_PAYMENTS = "check-ability-to-create-payments",
  TEST_SUBSCRIPTION_USED = "test-subscription-used",
  CHECK_PAYMENT_ORDER_STATUS = "check-payment-order-status",
  TEST_SUBSCRIPTION = "test-subscription",
  CUSTOMER_ADMIN_DATA = "customer-admin-data",
  CUSTOMER_ADMIN_DATA_FOR_BASE_USERS = "customer-admin-data-for-base-users",
  SUBSCRIPTION_INFO = "subscription-info",
  WIDGET_AUTH_DATA = "widget-auth-data",
  RINEX_FILE_REQUESTS = "rinex-file-requests",

  // NOTE: DICTIONARIES
  SUBSCRIPTION_STATUSES = "subscription-statuses",
  REGIONS = "regions",
  SERVICE_KINDS = "service-kinds",
  DURATIONS = "durations",
  DISCOUNTS = "discounts",
  TIMEZONES = "timezones",
}

export enum MutationKeys {
  DOWNLOAD_RINEX_FILE = "donwload-rinex-file",
}

export enum NOTIFICATION_MODE {
  SUCCESS = "success",
  ERROR = "error",
  INFO = "info",
  WARNING = "warning",
  MESSAGE = "message",
}

export enum PAYMENT_STATUSES {
  DRAFT = "DRAFT",
  IN_PROCESS = "IN_PROCESS",
  DONE = "DONE",
  FAILED = "FAILED",
  TEST = "TEST",
  TO_QUEUE = "TO_QUEUE",
}

export enum PAYMENT_ACTION_TYPE {
  ADD = "ADD",
  MODIFY = "MODIFY",
}

export enum PAYMENT_TYPE {
  NEW = "NEW",
  PROLONGATION = "PROLONGATION",
}

export enum DHW_ORDER_STATUS {
  INVOICE_COMPLETED = "INVOICE_COMPLETED",
  ERROR = "ERROR",
  NEW = "NEW",
  ORGANIZATION_WAS_SET = "ORGANIZATION_WAS_SET",
  EMAIL_WAS_SET = "EMAIL_WAS_SET",
  MANGO_PROCESSING = "MANGO_PROCESSING",
  PAID = "PAID",
  CANCELED = "CANCELED",
}

export enum SUBSCRIPTION_STATUSES {
  PAID = "PAID",
  ACTIVATED = "ACTIVATED",
  EXPIRED = "EXPIRED",
}

export enum SUBSCRIPTION_STATUS_FILLED {
  EDITABLE = "EDITABLE",
  FILLED = "FILLED",
}

export enum SUBSCRIPTION_SERVICE_KINDS {
  RTK_RINEX = "RTK_RINEX",
  RINEX = "RINEX",
}

export enum SUBSCRIPTION_DURATIONS {
  DAY = "DAY",
  MONTH = "MONTH",
  QUARTER = "QUARTER",
  HALF_YEAR = "HALF_YEAR",
  YEAR = "YEAR",
  TWO_WEEKS = "TWO_WEEKS",
}

export enum SUBSCRIPTION_REGIONS {
  ALL = "ALL",
}

export enum MTS_OPERATOR_KEYS {
  MMTS = "mMTS",
  MSMARTSU = "mSMARTSU",
  MMGTS = "mMGTS",
}

export enum DISCOUNT_TYPE {
  SUBSCRIPTION_COUNT_DISCOUNT = "SUBSCRIPTION_COUNT_DISCOUNT",
  PARTNER_DISCOUNT = "PARTNER_DISCOUNT",
  REGION_DISCOUNT = "REGION_DISCOUNT",
}

export enum PROFILE_ROLE {
  CUSTOMER_ADMIN = "CUSTOMER_ADMIN",
  POTENTIAL = "POTENTIAL",
  CUSTOMER_BASE = "CUSTOMER_BASE",
  MTS_TB = "MTS_TB",
  MTS_ADMIN = "MTS_ADMIN",
  DEALER = "DEALER",
}

export enum PROFILE_KEYS {
  TYPE = "profile:type",
  OPERATOR = "mnp:operator",
  REGION = "mnp:region",
  ORGANIZATION = "is_organization",
}

export enum TEST_SUBSCRIPTION_STATUS {
  RTK_RINEX_NOT_USED = "RTK_RINEX_NOT_USED",
  RTK_RINEX_USED = "RTK_RINEX_USED",
  RTK_RINEX_USING = "RTK_RINEX_USING",
}

export enum FILES_LIST_STATUS {
  COMPLETE = "COMPLETE",
  INCOMPLETE = "INCOMPLETE",
  EMPTY = "EMPTY",
}

export enum FIELD_NAMES {
  // NOTE: SUBSCRIPTION
  SUBSCRIPTION_ID = "id",
  SUBSCRIPTION_NAME = "name",
  SUBSCRIPTION_DEVICE_LOGIN = "deviceLogin",
  SUBSCRIPTION_DEVICE_PASSWORD = "devicePassword",
  SUBSCRIPTION_CREATED_DATE = "createdDate",
  SUBSCRIPTION_EXPIRED_DATE = "expiredDate",
  SUBSCRIPTION_SERVICE_KIND = "serviceKind",
  SUBSCRIPTION_DURATION = "duration",
  SUBSCRIPTION_PHONE = "phone",
  SUBSCRIPTION_STATUS = "status",
  SUBSCRIPTION_EMAIL = "email",
  SUBSCRIPTION_DESCRIPTION = "description",
  SUBSCRIPTION_EDIT_STATUS = "editStatus",

  // NOTE: TABLE WITH SUBSCRIPTIONS
  TABLE_SUBSCRIPTIONS_FILTER_SEARCH = "search",
  TABLE_COLUMN_ACTION = "action",
  TABLE_COLUMN_SELECTION = "selection",

  // NOTE: PAYMENT CONFIGURATOR
  PAYMENT_CONFIGURATOR_COUNT = "count",
  PAYMENT_CONFIGURATOR_REGION = "region",
  PAYMENT_CONFIGURATOR_DURATION = "duration",
  PAYMENT_CONFIGURATOR_SERVICE_KIND = "serviceKind",
  PAYMENT_CONFIGURATOR_PAY_GROUP_ID = "payGroupId",

  // NOTE: RINEX DOWNLOAD FILE
  RINEX_MEASUREMENT_START_DAY = "rinexMeasurementStartDay",
  RINEX_MEASUREMENT_START_HOUR = "rinexMeasurementStartHour",
  RINEX_DURATION = "rinexDuration",
  RINEX_FREQUENCY = "rinexFrequency",
  RINEX_TIMEZONE = "rinexTimezone",
  RINEX_VERSION = "rinexVersion",
  RINEX_STATION = "rinexStation",

  // MAP: MENU
  MAP_MENU_PLAN = "plan",
  MAP_MENU_COVERAGE_OFF = "coverageOff",

  // NEW USER PAGE
  ADMIN_EMAIL = "email",

  // TARIFF CARD
  TARIFF_CATEGORY = "category",

  // COMPANY INFO
  COMPANY_INFO_REGION = "region",
  COMPANY_INFO_COMPANY_NAME = "companyName",
  COMPANY_INFO_FIO = "fio",
  COMPANY_INFO_EMAIL = "email",

  // CONTACT US
  CONTACT_US_FIO = "fio",
  CONTACT_US_PHONE = "phoneNumber",
  CONTACT_US_EMAIL = "email",
  CONTACT_US_COMPANY_NAME = "companyName",
  CONTACT_US_REGION = "region",
}

export enum HTTPStatusCode {
  OK_200 = 200,
  UNAUTHORIZED_401 = 401,
  NOT_FOUND_404 = 404,
  BAD_REQUEST_400 = 400,
  FORBIDDEN_403 = 403,
}

export enum BACKEND_SEAMLESS_AUTH_CODES {
  LOGIN = 401,
  REFRESH = 205,
  OK = 200,
}

export enum BACKEND_ERROR_CODES {
  ILLEGAL_ARGUMENT = 1101,
  ILLEGAL_STATE = 1102,
  UNEXPECTED_ERROR = 1103,
  BAD_REQUEST = 1103,

  SUBSCRIPTION_NOT_FOUND = 1201,
  CUSTOMER_BASE_NOT_FOUND = 1202,
  DEVICE_NOT_FOUND = 1203,
  POTENTIAL_NOT_FOUND = 1204,
  CUSTOMER_ADMIN_NOT_FOUND = 1205,
  PAYMENT_NOT_FOUND = 1206,
  BALANCE_NOT_FOUND = 1207,
  TARIFF_NOT_FOUND = 1208,
  REGION_NOT_FOUND = 1209,
  FORIS_LABEL_NOT_FOUND = 1210,
  FORIS_NEW_PRICE_NOT_FOUND = 1211,
  FORIS_PARAMETERS_NOT_FOUND = 1212,

  AUTHORIZATION_ERROR = 1301,
  INSUFFICIENT_ROLE = 1302, // 403
  INVALID_TOKEN = 1303, // 401
  TOKEN_REQUIRED = 1304, // 401
  TOKEN_EXPIRED = 1305, // 401

  DEVICE_PASSWORD_IS_EMPTY = 1601,
  CUSTOMER_BASE_LOGIN_IS_EMPTY = 1602,

  CUSTOMER_BASE_ALREADY_EXISTS = 1701,
  CUSTOMER_ADMIN_ALREADY_EXISTS = 1702,
  FORIS_LABEL_ALREADY_PRESENT = 1703,
  FORIS_NEW_PRICE_ALREADY_PRESENT = 1704,
  FORIS_PARAMETERS_ALREADY_PRESENT = 1705,

  PAYMENT_CANNOT_BE_DROPPED = 1801,
  PAYMENT_CANNOT_BE_IN_PROCESS = 1802,
  PAYMENT_CANNOT_BE_PAID = 1803,
  SUBSCRIPTION_CANNOT_BE_DROPPED = 1804,
  SUBSCRIPTION_CANNOT_BE_ACTIVATED = 1805,
  SUBSCRIPTION_CANNOT_BE_EXPIRED = 1806,
  PAYMENT_CANNOT_BE_CHANGED = 1807,
  SUBSCRIPTION_CANNOT_BE_CREATED = 1808,
  TEST_MODE_ALREADY_USED = 1809,
  SUBSCRIPTION_CANNOT_BE_UPDATED = 1810,
  ACCEPTANCE_ALREADY_RECEIVED = 1811,
}

export const PROMPT_MESSAGE_UNSAVED_DATA =
  "Внесенные изменения будут утеряны, покинуть страницу?";

export const REQUIRED_FIELD_MESSAGE = "Поле должно быть заполнено";

export const PHONE_MASK = "+7 999 999-99-99";
export const TIME_MASK = "99.99.9999, 99:99";

export const EMPTY_CELL_VALUE = "-";

export const FileRequestStatuses = {
  queue: "QUEUE",
  inProgress: "IN_PROGRESS",
  complete: "COMPLETE",
  error: "ERROR",
  deleted: "DELETED",
} as const;

export const TableSortStates = {
  asc: "ASC",
  desc: "DESC",
};

export const MapVariants = {
  egis: "map-egis",
  yandex: "map-yandex",
} as const;

// COOKIES
export enum APPLICATION_COOKIE_KEYS {
  LOGIN_MTS_LOGOUT = "login.mts.ru.logout",
}

// LOCAL STORAGE
export const LS_USER_FROM_LANDING = "landing";
export const LS_GAE_SUCCESS_AUTH = "dataGAESuccessAuth";
export const LS_MAP_VARIANT = "map";

// SESSION STORAGE
export const SS_DONT_SHOW_RENEW_DIALOG = "dontShowRenewDialog";
