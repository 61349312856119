import { HTTPStatusCode, QueryKeys } from "constants/keys";

import { useQuery } from "react-query";

import { AxiosError } from "axios";
import { useNotifications } from "hooks/use-notifications";
import {
  getProfileSubscriptionInfo,
  getBillingSubscriptionInfo,
} from "services/api";

export const useSubscriptionInfo = (id: string) => {
  const { addErrorMessage } = useNotifications();

  return useQuery(
    [QueryKeys.SUBSCRIPTION_INFO, id],
    () =>
      Promise.all([
        getProfileSubscriptionInfo(id),
        getBillingSubscriptionInfo(id),
      ]).then((responses) => responses),
    {
      refetchOnWindowFocus: false,
      retry: 0,
      onError: (error: AxiosError) => {
        const status = error.response?.status;

        if (status !== HTTPStatusCode.UNAUTHORIZED_401) {
          addErrorMessage({
            message: "Не удалось получить информацию по подписке",
          });
        }
      },
    }
  );
};
