import { HTTPStatusCode, QueryKeys } from "constants/keys";

import { useMutation, useQueryClient } from "react-query";

import { AxiosError } from "axios";
import { useNotifications } from "hooks/use-notifications";
import { IUpdateDevicePasswordPayload } from "interfaces";
import { updateDevicePassword } from "services/api";

export const useMutationUpdateDevicePassword = () => {
  const { addSuccessMessage, addErrorMessage } = useNotifications();
  const queryClient = useQueryClient();

  return useMutation(
    (payload: IUpdateDevicePasswordPayload) => {
      return updateDevicePassword(payload);
    },
    {
      onError: (error: AxiosError) => {
        const status = error.response?.status;

        if (status !== HTTPStatusCode.UNAUTHORIZED_401) {
          addErrorMessage({ message: "Не удалось сохранить пароль" });
        }
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries(QueryKeys.SUBSCRIPTION_INFO_COMMON);
        addSuccessMessage({ message: "Пароль сохранён" });
      },
    }
  );
};
