import { IPlannedBaseStationEchoDTO } from "interfaces";
import ReactDOMServer from "react-dom/server";
import { getDateFormatted } from "utils/get-date-formatted";

import "../../index.css";
import { getCompactAddress } from "../../utils/get-compact-address";

import classes from "./card-planned-station.module.css";

export const CardPlannedStation = (properties: IPlannedBaseStationEchoDTO) => {
  const { address, plannedDate } = properties;
  const formattedAddress: string = address?.formatted_address ?? "-";
  const city: string = address?.city ?? "";
  const areaDisctrict: string = address?.area_district ?? "";
  const area: string = address?.area ?? "-";
  const { date } = getDateFormatted(plannedDate);

  return ReactDOMServer.renderToString(
    <>
      <div className={"mts-map-custom-popup-header"}>
        <p className={classes.headerTitle} data-testid="txt_header_planned">
          Планируемая станция
        </p>
      </div>
      <div className={"mts-map-custom-popup-info"}>
        <p
          className={classes.infoAddressLabel}
          data-testid="txt_locality_planned"
        >
          {getCompactAddress({ city, areaDisctrict, area })}
        </p>

        <p className={classes.infoAddressText}>{formattedAddress}</p>

        <p className={classes.infoDescription} data-testid="txt_date_planned">
          Планируемая дата включения {date} г.
        </p>
      </div>
    </>
  );
};
