import { FIELD_NAMES } from "constants/keys";
import { TABLE_PAGE_SIZE } from "constants/table";

import React from "react";

import {
  useTable,
  usePagination,
  useSortBy,
  Hooks,
  useBlockLayout,
  useGlobalFilter,
  useFilters,
  useRowSelect,
} from "react-table";
import { useSticky } from "react-table-sticky";

import Tooltip from "@mui/material/Tooltip";
import { DeleteSubscriptionMessage } from "components/delete-subscription-message";
import { DialogConfirm, IDialogConfirmProps } from "components/dialog-confirm";
import { MTSButtonIcon } from "components/mts-button-icon";
import { MTSCheckbox } from "components/mts-checkbox";
import { MTSIcon } from "components/mts-icon";
import { MTSPagination } from "components/mts-pagination";
import {
  GAP_EVENT_TYPE,
  GA_SCREEN_NAME,
  useGoogleAnalyticsPages,
} from "hooks/google-analytics";
import { useTableSubscriptionsColumns } from "hooks/table/use-table-subscriptions-columns";
import { useMutationDeleteSubscription } from "hooks/use-mutation-delele-subscription";
import { useServiceKinds } from "hooks/use-service-kinds";
import { useSubscriptionStatuses } from "hooks/use-subscription-statuses";
import { ISubscriptionStruct, ITableSubscriptionsFilters } from "interfaces";
import { ContextSubscriptions } from "store/context-subscriptions";
import { getDictionaryItemNameById } from "utils/get-dictionary-item-name-by-id";
import { getSubscriptionStatusName } from "utils/get-subscription-status-name";
import { SubscriptionStatusCheckerByCode } from "utils/subscription-status-checker-by-code";

import { renderFilterIcon } from "../../utils/render-filter-icon";
import { RenderFilter } from "../render-filter";
import { TableCellSubscriptionName } from "../table-cell-subscription-name";
import { TableCellWithTooltip } from "../table-cell-with-tooltip";
import { TableEmpty } from "../table-empty";
import { TableNotFound } from "../table-not-found";

import classes from "./table-subscriptions-expired.module.css";

enum TableColumnIds {
  sticky = "sticky",
  checkbox = "checkbox",
  blank = "blank",
}

interface ITableSubscriptionsProps {
  data: ISubscriptionStruct[];
  tableFilters: ITableSubscriptionsFilters;
  tableSortBy: any;
}

export const TableSubscriptionsExpired = (props: ITableSubscriptionsProps) => {
  const { data, tableFilters, tableSortBy } = props;

  useGoogleAnalyticsPages({
    screenName: GA_SCREEN_NAME.subs_expired,
    event: GAP_EVENT_TYPE.SCRN,
  });

  const { subscriptionCounts } = React.useContext(ContextSubscriptions);

  const { tableColumns, widthOfColumns, hiddenColumns } =
    useTableSubscriptionsColumns();

  const [dialogConfirm, setDialogConfirm] =
    React.useState<IDialogConfirmProps | null>(null);

  const { data: serviceKinds } = useServiceKinds();
  const { data: subscriptionStatuses } = useSubscriptionStatuses();
  const mutationDeleteSubscription = useMutationDeleteSubscription();

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setFilter,
    setGlobalFilter,
    state: { pageIndex },
  } = useTable(
    {
      columns: tableColumns,
      data: data,
      initialState: {
        pageIndex: 0,
        pageSize: TABLE_PAGE_SIZE,
        sortBy: tableSortBy,
        hiddenColumns,
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks: Hooks<any>) => {
      hooks.visibleColumns.push((columns) => {
        return [
          {
            id: TableColumnIds.checkbox,
            Header: ({
              getToggleAllPageRowsSelectedProps,
              toggleRowSelected,
              isAllPageRowsSelected,
              selectedFlatRows,
              page: currentPage,
            }) => {
              const modifiedOnChange = (event: any) => {
                currentPage.forEach((row) => {
                  !row.original.disabled &&
                    toggleRowSelected(row.id, event.currentTarget.checked);
                });
              };

              let selectableRowsInCurrentPage = 0;
              let selectedRowsInCurrentPage = 0;
              currentPage.forEach((row) => {
                row.isSelected && selectedRowsInCurrentPage++;
                !row.original.disabled && selectableRowsInCurrentPage++;
              });

              const disabled = selectableRowsInCurrentPage === 0;
              const checked =
                (isAllPageRowsSelected ||
                  selectableRowsInCurrentPage === selectedRowsInCurrentPage) &&
                !disabled;

              return (
                <div className={classes.checkbox}>
                  <MTSCheckbox
                    boxSize="small"
                    onChange={modifiedOnChange}
                    checked={checked}
                    disabled={disabled}
                    data-test-id="chk_subs_all"
                  />
                </div>
              );
            },
            Cell: ({ row }) => {
              return (
                <div className={classes.checkbox}>
                  <MTSCheckbox
                    boxSize="small"
                    {...row.getToggleRowSelectedProps({
                      title: undefined,
                    })}
                    disabled={row.original?.disabled ?? false}
                    data-test-id="chk_subs"
                  />
                </div>
              );
            },
            sticky: "left",
            width: widthOfColumns.checkbox,
          },
          ...columns,
          {
            id: TableColumnIds.blank,
            Header: () => {
              return null;
            },
            Cell: () => {
              return null;
            },
            width: 0,
          },
          {
            id: TableColumnIds.sticky,
            Header: ({ selectedFlatRows }) => {
              const selectedSubs: ISubscriptionStruct[] = selectedFlatRows.map(
                (row) => row.original
              );

              return (
                <div
                  className={classes.buttons}
                  style={{
                    visibility: selectedSubs?.length > 0 ? "visible" : "hidden",
                  }}
                >
                  <MTSButtonIcon
                    size="XS"
                    Icon={MTSIcon.DeleteFill}
                    iconSize={16}
                    variant="secondary"
                    onClick={() => {
                      const selectedSubIds: number[] = selectedSubs.map(
                        (sub) => sub.id
                      );
                      handleDeleteSubs(selectedSubIds);
                    }}
                    data-testid="btn_del_subs"
                  />
                </div>
              );
            },
            Cell: ({ row }) => {
              const { original } = row;
              const { id, inProlongation } = original as ISubscriptionStruct;

              return (
                <div className={classes.buttons}>
                  {inProlongation ? (
                    <Tooltip
                      title="В процессе продления"
                      arrow
                      disableInteractive
                    >
                      <div>
                        <MTSIcon.Waiting
                          sx={{
                            width: 24,
                            color: "var(--color-icon-tertiary)",
                          }}
                          data-testid="icon_wait_in_renewal"
                        />
                      </div>
                    </Tooltip>
                  ) : (
                    <MTSButtonIcon
                      size="XS"
                      Icon={MTSIcon.DeleteFill}
                      iconSize={16}
                      variant="secondary"
                      onClick={() => handleDeleteSub(id)}
                      data-testid="btn_del_sub"
                    />
                  )}
                </div>
              );
            },
            sticky: "right",
            width: widthOfColumns.sticky,
          },
        ];
      });
    },
    useBlockLayout,
    useSticky
  );

  React.useEffect(() => {
    setGlobalFilter(tableFilters.search || "");
    setFilter(
      FIELD_NAMES.SUBSCRIPTION_SERVICE_KIND,
      tableFilters.serviceKind?.id || null
    );

    setFilter(FIELD_NAMES.SUBSCRIPTION_DURATION, tableFilters.duration?.name);
  }, [setFilter, setGlobalFilter, tableFilters, data]);

  const handleDeleteSub = (subId: number): void => {
    const closeDialog = (): void => {
      setDialogConfirm((prevDialogProps) => ({
        ...prevDialogProps,
        isOpen: false,
      }));
    };

    const handleConfirmDialog = (): void => {
      closeDialog();
      mutationDeleteSubscription.mutate(String(subId));
    };

    const dialogProps: IDialogConfirmProps = {
      isOpen: true,
      title: "Удаление подписки",
      content: <DeleteSubscriptionMessage />,
      confirmLabel: "Удалить",
      handleCancel: () => closeDialog(),
      handleClose: () => closeDialog(),
      handleConfirm: () => handleConfirmDialog(),
    };

    setDialogConfirm(dialogProps);
  };

  const handleDeleteSubs = (subIds: number[]): void => {
    const closeDialog = (): void => {
      setDialogConfirm((prevDialogProps) => ({
        ...prevDialogProps,
        isOpen: false,
      }));
    };

    const handleConfirmDialog = (): void => {
      subIds.forEach((id) => {
        mutationDeleteSubscription.mutateAsync(String(id));
      });
      closeDialog();
    };

    const dialogProps: IDialogConfirmProps = {
      isOpen: true,
      title: "Удаление подписок",
      content: (
        <p className={classes.dialogText}>
          При удалении подписок статистика по пользователям{" "}
          <span style={{ whiteSpace: "nowrap" }}>и устройствам</span> будет
          удалена навсегда.
          <br />
          Вы точно хотите удалить подписки?
        </p>
      ),
      confirmLabel: "Удалить",
      handleCancel: () => closeDialog(),
      handleClose: () => closeDialog(),
      handleConfirm: () => handleConfirmDialog(),
    };

    setDialogConfirm(dialogProps);
  };

  if (data.length === 0) {
    return (
      <TableEmpty pageSize={TABLE_PAGE_SIZE} icon="subs" text="Подписок нет" />
    );
  }

  if (data.length && page.length === 0) {
    return <TableNotFound />;
  }

  return (
    <div className={classes.tableContainer}>
      <div className={classes.tableWrap}>
        <div className={classes.table} {...getTableProps()}>
          <div className={classes.thead}>
            {headerGroups.map((headerGroup: any) => (
              <div
                {...headerGroup.getHeaderGroupProps()}
                className={classes.theadTR}
              >
                {headerGroup.headers.map((column: any) => {
                  const columnId: string = column.id;

                  if (columnId === TableColumnIds.blank) {
                    return <div className={classes.blank}></div>;
                  }

                  if (
                    columnId === TableColumnIds.checkbox ||
                    columnId === TableColumnIds.sticky
                  ) {
                    return (
                      <div
                        className={classes.th}
                        {...column.getHeaderProps({
                          style: {
                            width: column.width,
                            paddingLeft: 0,
                            justifyContent: "flex-end",
                          },
                          title: null,
                        })}
                      >
                        {column.render("Header")}
                      </div>
                    );
                  }

                  return (
                    <div
                      className={classes.th}
                      {...column.getHeaderProps({
                        style: {
                          width: column.width,
                        },
                        ...column.getSortByToggleProps({
                          title: null,
                        }),
                      })}
                    >
                      <p
                        className={classes.theadTypography}
                        data-testid={"txt_column_subs_" + columnId}
                      >
                        {column.render("Header")}
                      </p>
                      {renderFilterIcon(columnId) && (
                        <RenderFilter
                          isSorted={column.isSorted}
                          isSortedDesc={column.isSortedDesc}
                          dataTestId={"btn_sort_subs_" + columnId}
                        />
                      )}
                    </div>
                  );
                })}
              </div>
            ))}
          </div>

          <div className={classes.tbody} {...getTableBodyProps()}>
            {page.map((pageRow: any, i: number) => {
              prepareRow(pageRow);

              return (
                <div {...pageRow.getRowProps()} className={classes.tbodyTR}>
                  {pageRow.cells.map((cell: any) => {
                    const { column, value, row } = cell;
                    const columnId: string = column.id;
                    const { status } = row.values;
                    const textStyles: React.CSSProperties = {
                      color: SubscriptionStatusCheckerByCode.isExpired(status)
                        ? "var(--color-text-tertiary)"
                        : "",
                    };

                    if (columnId === FIELD_NAMES.SUBSCRIPTION_NAME) {
                      const subscriptionId: number = row.original.id;

                      return (
                        <div className={classes.td} {...cell.getCellProps()}>
                          <TableCellSubscriptionName
                            subscriptionId={subscriptionId}
                            value={value}
                            subscriptionCountCanRenew={
                              subscriptionCounts.canRenew
                            }
                            columnWidth={column.width}
                            dataTestId={"btn_subs_" + columnId}
                          />
                        </div>
                      );
                    }

                    if (columnId === FIELD_NAMES.SUBSCRIPTION_SERVICE_KIND) {
                      const serviceKindId = value;

                      return (
                        <div className={classes.td} {...cell.getCellProps()}>
                          <TableCellWithTooltip
                            text={getDictionaryItemNameById(
                              serviceKindId,
                              serviceKinds
                            )}
                            textStyles={textStyles}
                            cellWidth={column.width}
                            dataTestId={"txt_subs_" + columnId}
                          />
                        </div>
                      );
                    }

                    if (
                      columnId === FIELD_NAMES.SUBSCRIPTION_DURATION ||
                      columnId === FIELD_NAMES.SUBSCRIPTION_PHONE ||
                      columnId === FIELD_NAMES.SUBSCRIPTION_EMAIL ||
                      columnId === FIELD_NAMES.SUBSCRIPTION_DESCRIPTION
                    ) {
                      return (
                        <div className={classes.td} {...cell.getCellProps()}>
                          <TableCellWithTooltip
                            text={value}
                            textStyles={textStyles}
                            cellWidth={column.width}
                            dataTestId={"txt_subs_" + columnId}
                          />
                        </div>
                      );
                    }

                    if (columnId === FIELD_NAMES.SUBSCRIPTION_STATUS) {
                      const { createdDate, expiredDate } = row.values;
                      const statusName = getSubscriptionStatusName(
                        value,
                        subscriptionStatuses,
                        createdDate,
                        expiredDate
                      );

                      return (
                        <div className={classes.td} {...cell.getCellProps()}>
                          <TableCellWithTooltip
                            text={statusName}
                            textStyles={textStyles}
                            cellWidth={column.width}
                            dataTestId={"txt_subs_" + columnId}
                          />
                        </div>
                      );
                    }

                    if (columnId === TableColumnIds.blank) {
                      return <div className={classes.blank}></div>;
                    }

                    if (
                      columnId === TableColumnIds.sticky ||
                      columnId === TableColumnIds.checkbox
                    ) {
                      return (
                        <div
                          className={classes.td}
                          {...cell.getCellProps({
                            style: {
                              paddingLeft: 0,
                              justifyContent: "flex-end",
                            },
                          })}
                          dataTestId={"txt_subs_" + columnId}
                        >
                          {cell.render("Cell")}
                        </div>
                      );
                    }

                    return (
                      <div className={classes.td} {...cell.getCellProps()}>
                        <TableCellWithTooltip
                          text={value}
                          cellWidth={column.width}
                        />
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
      </div>

      {Boolean(pageCount > 1) && (
        <MTSPagination
          pageIndex={pageIndex}
          pageCount={pageCount}
          canNextPage={canNextPage}
          canPreviousPage={canPreviousPage}
          gotoPage={gotoPage}
          nextPage={nextPage}
          previousPage={previousPage}
        />
      )}

      <DialogConfirm {...dialogConfirm} />
    </div>
  );
};
