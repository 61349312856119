import React from "react";

import classNames from "classnames";
import { MTSIcon } from "components/mts-icon";

import classes from "./mts-chip.module.css";

enum Size {
  S = "S",
  M = "M",
  L = "L",
}

type ISize = keyof typeof Size;

interface IMTSChipProps {
  size: ISize;
  value: string;
  onClick: () => void;
}

const getCloseSizeIcon = (size: ISize): number => {
  switch (size) {
    case Size.S:
    case Size.M:
      return 16;
    case Size.L:
      return 24;
    default:
      return 16;
  }
};

export const MTSChip = (props: IMTSChipProps): JSX.Element => {
  const { size, value, onClick } = props;

  const chipRef = React.useRef<HTMLButtonElement>(null);
  const [isHoverButton, setIsHoverButton] = React.useState<boolean>(false);

  const chipStyles = classNames({
    [classes.chip]: true,
    [classes.chipSizeS]: size === Size.S,
    [classes.chipSizeM]: size === Size.M,
    [classes.chipSizeL]: size === Size.L,
  });

  const chipTextStyles = classNames({
    [classes.text]: true,
    [classes.textSizeS]: size === Size.S,
    [classes.textSizeM]: size === Size.M,
    [classes.textSizeL]: size === Size.L,
  });

  React.useEffect(() => {
    const chipRefObject = chipRef?.current;

    const doMouseOver = () => {
      setIsHoverButton(true);
    };
    const doMouseLeave = () => {
      setIsHoverButton(false);
    };

    if (chipRefObject) {
      chipRefObject.addEventListener("mouseover", doMouseOver);
      chipRefObject.addEventListener("mouseleave", doMouseLeave);
    }

    return () => {
      if (chipRefObject) {
        chipRefObject.removeEventListener("mouseover", doMouseOver);
        chipRefObject.removeEventListener("mouseleave", doMouseLeave);
      }
    };
  }, []);

  return (
    <button ref={chipRef} onClick={onClick} className={chipStyles}>
      <span className={chipTextStyles}>{value}</span>

      <MTSIcon.Close
        sx={{
          fontSize: getCloseSizeIcon(size),
          color: isHoverButton
            ? "var(--color-icon-primary)"
            : "var(--color-icon-secondary)",
          transition: "0.3s color ease-out",
        }}
      />
    </button>
  );
};
