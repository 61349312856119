import Button, { ButtonProps } from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import cn from "classnames";
import { MTSSpinner } from "components/mts-spinner";

import { Size, Mode, MTSButtonIconVariant } from "./constants";
import { useButtonIconColor } from "./hooks/use-button-icon-color";
import { IMTSButtonIconVariant } from "./interfaces";
import { useStyles } from "./styles";
import { getIconSize } from "./utils/get-icon-size";

type ISize = keyof typeof Size;
type IMode = keyof typeof Mode;

interface IMTSButtonIconProps extends Omit<ButtonProps, "size" | "variant"> {
  mode?: IMode;
  size?: ISize;
  variant: IMTSButtonIconVariant;
  disabled?: boolean;
  loading?: boolean;
  noWrap?: boolean;
  Icon: any;
  iconColor?: string;
  iconSize?: number;
  iconProps?: React.CSSProperties;
}

const CustomButtonIcon = styled(Button)(({ theme }) => ({
  boxShadow: "none",
  textTransform: "none",
  color: "var(--color-background-primary)",
  backgroundColor: "var(--color-brand)",
  borderColor: "none",
  border: "none",
  padding: "0px 0px",

  "&:hover": {
    backgroundColor: "rgba(212, 6, 16, 1)",
    boxShadow: "none",
  },
  "&:active": {
    backgroundColor: "rgba(212, 6, 16, 1)",
    boxShadow: "none",
  },
  "&:focus": {
    backgroundColor: "rgba(212, 6, 16, 1)",
    boxShadow: "none",
  },
}));

export const MTSButtonIcon = (props: IMTSButtonIconProps) => {
  const {
    size = Size.S,
    variant,
    loading = false,
    disabled = false,
    Icon,
    iconColor,
    iconSize,
    iconProps,
    mode = Mode.standard,
    ...rest
  } = props;
  const classes = useStyles();
  const defaultIconColor = useButtonIconColor({ variant });

  const btnClasses = cn({
    [classes.btn]: true,
    [classes.btnRound]: mode === Mode.round && true,
    [classes.btnSizeXS]: size === Size.XS,
    [classes.btnSizeS]: size === Size.S,
    [classes.btnSizeM]: size === Size.M,
    [classes.btnSizeL]: size === Size.L,
    [classes.btnDisabled]: disabled,
    [classes.btnPrimary]: !disabled && variant === MTSButtonIconVariant.primary,
    [classes.btnSecondary]:
      !disabled && variant === MTSButtonIconVariant.secondary,
    [classes.btnSecondaryInverted]:
      !disabled && variant === MTSButtonIconVariant.secondary_inverted,
    [classes.btnGhost]: !disabled && variant === MTSButtonIconVariant.ghost,
    [classes.btnOutline]: !disabled && variant === MTSButtonIconVariant.outline,
  });

  const iconClasses = cn({
    [classes.icon]: true,
    [classes.iconVisibility]: loading,
  });

  const renderSpinner = (
    btnSize: string,
    btnVariant: IMTSButtonIconVariant
  ): JSX.Element => {
    if (variant === MTSButtonIconVariant.primary) {
      if (btnSize === Size.XS || btnSize === Size.S) {
        return <MTSSpinner size="S" color="white" />;
      }

      if (btnSize === Size.M || btnSize === Size.L) {
        return <MTSSpinner color="white" />;
      }
    }

    if (
      btnVariant === MTSButtonIconVariant.secondary ||
      btnVariant === MTSButtonIconVariant.secondary_inverted ||
      btnVariant === MTSButtonIconVariant.ghost ||
      btnVariant === MTSButtonIconVariant.outline
    ) {
      if (btnSize === Size.XS || btnSize === Size.S) {
        return <MTSSpinner size="S" color="black" />;
      }

      if (btnSize === Size.M || btnSize === Size.L) {
        return <MTSSpinner color="black" />;
      }
    }

    return <MTSSpinner size="S" color="white" />;
  };

  return (
    <CustomButtonIcon
      classes={{ root: btnClasses }}
      disableRipple
      disabled={disabled}
      {...rest}
    >
      {loading && (
        <div className={classes.spinnerBlock}>
          {renderSpinner(size, variant)}
        </div>
      )}
      <div className={iconClasses}>
        <Icon
          sx={{
            fontSize: iconSize ? iconSize : getIconSize(size),
            color: disabled ? "" : iconColor ? iconColor : defaultIconColor,
            ...iconProps,
          }}
        />
      </div>
    </CustomButtonIcon>
  );
};
