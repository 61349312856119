import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const DotsMenu = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 9C7 7.89543 7.89543 7 9 7C10.1046 7 11 7.89543 11 9C11 10.1046 10.1046 11 9 11C7.89543 11 7 10.1046 7 9ZM7 16C7 14.8954 7.89543 14 9 14C10.1046 14 11 14.8954 11 16C11 17.1046 10.1046 18 9 18C7.89543 18 7 17.1046 7 16ZM16 7C14.8954 7 14 7.89543 14 9C14 10.1046 14.8954 11 16 11C17.1046 11 18 10.1046 18 9C18 7.89543 17.1046 7 16 7ZM14 16C14 14.8954 14.8954 14 16 14C17.1046 14 18 14.8954 18 16C18 17.1046 17.1046 18 16 18C14.8954 18 14 17.1046 14 16ZM23 7C21.8954 7 21 7.89543 21 9C21 10.1046 21.8954 11 23 11C24.1046 11 25 10.1046 25 9C25 7.89543 24.1046 7 23 7ZM21 16C21 14.8954 21.8954 14 23 14C24.1046 14 25 14.8954 25 16C25 17.1046 24.1046 18 23 18C21.8954 18 21 17.1046 21 16ZM9 21C7.89543 21 7 21.8954 7 23C7 24.1046 7.89543 25 9 25C10.1046 25 11 24.1046 11 23C11 21.8954 10.1046 21 9 21ZM14 23C14 21.8954 14.8954 21 16 21C17.1046 21 18 21.8954 18 23C18 24.1046 17.1046 25 16 25C14.8954 25 14 24.1046 14 23ZM23 21C21.8954 21 21 21.8954 21 23C21 24.1046 21.8954 25 23 25C24.1046 25 25 24.1046 25 23C25 21.8954 24.1046 21 23 21Z"
      />
    </SvgIcon>
  );
};

export default DotsMenu;
