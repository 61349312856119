import { PATH_SUBSCRIPTIONS } from "constants/routes";

import React from "react";

import classes from "./success-test-access-message.module.css";

export const SuccessTestAccessMessage = (): JSX.Element => {
  return (
    <div className={classes.blockMessage}>
      <p className={classes.blockMessageText}>
        Ваша подписка появится в разделе&nbsp;
        <a
          className={classes.blockMessageTextLink}
          href={PATH_SUBSCRIPTIONS}
          target="_blank"
          rel="noreferrer"
        >
          Подписки
        </a>
        &nbsp;в течение нескольких минут
      </p>
    </div>
  );
};
