import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const Plus = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_6114_270559)">
        <path d="M11.5 19C11.5 19.5523 11.9477 20 12.5 20C13.0523 20 13.5 19.5523 13.5 19V13H19.5C20.0523 13 20.5 12.5523 20.5 12C20.5 11.4477 20.0523 11 19.5 11H13.5V5C13.5 4.44771 13.0523 4 12.5 4C11.9477 4 11.5 4.44771 11.5 5L11.5 11H5.5C4.94771 11 4.5 11.4477 4.5 12C4.5 12.5523 4.94771 13 5.5 13H11.5L11.5 19Z" />
      </g>
      <defs>
        <clipPath id="clip0_6114_270559">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default Plus;
