import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const Customization = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_12554_13517)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15 21C16.6569 21 18 19.6569 18 18L20 18C20.5523 18 21 17.5523 21 17C21 16.4477 20.5523 16 20 16L18 16C18 14.3431 16.6569 13 15 13H12C10.3431 13 9 14.3431 9 16L4 16C3.44772 16 3 16.4477 3 17C3 17.5523 3.44772 18 4 18L9 18C9 19.6569 10.3431 21 12 21H15ZM11 18L11 16C11 15.4477 11.4477 15 12 15H15C15.5523 15 16 15.4477 16 16L16 18C16 18.5523 15.5523 19 15 19H12C11.4477 19 11 18.5523 11 18Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 8L4 8C3.44772 8 3 7.55229 3 7C3 6.44772 3.44772 6 4 6L6 6C6 4.34315 7.34315 3 9 3L12 3C13.6569 3 15 4.34315 15 6H20C20.5523 6 21 6.44772 21 7C21 7.55228 20.5523 8 20 8L15 8C15 9.65685 13.6569 11 12 11H9C7.34315 11 6 9.65686 6 8ZM13 6C13 5.44771 12.5523 5 12 5L9 5C8.44772 5 8 5.44772 8 6V8C8 8.55229 8.44772 9 9 9H12C12.5523 9 13 8.55228 13 8V6Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_12554_13517">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default Customization;
