import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export const PersonalAreaIcon = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon
      width="96"
      height="96"
      viewBox="0 0 96 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="48" cy="48" r="31" stroke="black" strokeWidth="2" />
      <circle cx="48" cy="39" r="11" stroke="black" strokeWidth="2" />
      <path
        d="M27 70C31.5 64 36.5 59 48 59C59.5 59 65 64 69 70"
        stroke="black"
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
