import { PAYMENT_TYPE } from "constants/keys";

import React from "react";

const activateSubscriptionsMessage = "activateSubscriptionsMessage";

interface IUseNotificationMessageActivateSubscriptionsProps {
  paymentType: string;
}

export const useNotificationMessageActivateSubscriptions = (
  props: IUseNotificationMessageActivateSubscriptionsProps
) => {
  const { paymentType } = props;
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const activateSubscriptionsStatus: string | null = sessionStorage.getItem(
    activateSubscriptionsMessage
  );

  const handleCloseMessage = (): void => {
    sessionStorage.setItem(activateSubscriptionsMessage, "0");
    setIsOpen(false);
  };

  React.useEffect(() => {
    if (paymentType === PAYMENT_TYPE.NEW) {
      switch (activateSubscriptionsStatus) {
        case "1": {
          setIsOpen(true);
          break;
        }
        case "0": {
          setIsOpen(false);
          break;
        }
        default: {
          sessionStorage.setItem(activateSubscriptionsMessage, "1");
          break;
        }
      }
    }
  }, [isOpen, activateSubscriptionsStatus, paymentType]);

  return { isOpen, handleCloseMessage };
};
