import { UseFormRegister } from "react-hook-form";

import { MTSButtonIcon } from "components/mts-button-icon";
import { MTSIcon } from "components/mts-icon";

import classes from "./input-search.module.css";

interface IInputSearchProps {
  isFilled: boolean;
  onClearInput: () => void;
  register: UseFormRegister<any>;
  registerFieldName: string;
  placeholder: string;
  dataTestId?: string | null;
}

export const InputSearch = (props: IInputSearchProps): JSX.Element => {
  const {
    isFilled,
    onClearInput,
    register,
    registerFieldName,
    placeholder,
    dataTestId = null,
  } = props;

  return (
    <div className={classes.blockInputSearch} data-testid={dataTestId || ""}>
      <MTSIcon.Search
        sx={{
          fontSize: 24,
          color: "var(--color-icon-tertiary)",
        }}
      />
      <input
        className={classes.input}
        autoComplete="off"
        {...register(registerFieldName)}
        type="text"
        placeholder={placeholder}
      />
      {isFilled && (
        <MTSButtonIcon
          mode="round"
          Icon={MTSIcon.Clear}
          iconSize={24}
          iconColor={"var(--color-icon-secondary)"}
          onClick={onClearInput}
          variant="ghost"
          size="XS"
        />
      )}
    </div>
  );
};
