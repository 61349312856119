import { LS_MAP_VARIANT, MapVariants } from "constants/keys";

import React from "react";

import { MenuOptionType } from "components/pages/map-page/components/map/constants";
import { IDictionaryDTO } from "interfaces";
import { IMapVariants } from "interfaces/local";

export interface IContextMap {
  mapVariant: IMapVariants;
  updateMapVariant: (data: IMapVariants) => void;
  searchValue: IDictionaryDTO | null;
  setSearchValue: React.Dispatch<React.SetStateAction<IDictionaryDTO | null>>;
  searchOptions: IDictionaryDTO[];
  setSearchOptions: React.Dispatch<React.SetStateAction<IDictionaryDTO[]>>;
  menuOption: MenuOptionType;
  setMenuOption: React.Dispatch<React.SetStateAction<MenuOptionType>>;
  coverageOperator: MenuOptionType;
  setCoverageOperator: React.Dispatch<React.SetStateAction<MenuOptionType>>;
  isDisableCoverage: boolean;
  setIsDisableCoverage: React.Dispatch<React.SetStateAction<boolean>>;
  isShowPlannedStations: boolean;
  setIsShowPlannedStations: React.Dispatch<React.SetStateAction<boolean>>;
  isDisabledPlannedStations: boolean;
  setIsDisabledPlannedStations: React.Dispatch<React.SetStateAction<boolean>>;
  isDisabledVRS: boolean;
  setIsDisabledVRS: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ContextMap = React.createContext<IContextMap | null>(null);

export const ContextMapProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [mapVariant, setMapVariant] = React.useState<IMapVariants>(
    (): IMapVariants => {
      let initialMapVariant: IMapVariants = MapVariants.egis;

      // TODO: Удаление логики выбора сохраненной карты
      // const savedMapVariant = localStorage.getItem(LS_MAP_VARIANT);

      // if (savedMapVariant) {
      //   const mapVariantsValues = Object.values(MapVariants);

      //   if (mapVariantsValues.some((value) => value === savedMapVariant)) {
      //     initialMapVariant = savedMapVariant as IMapVariants;
      //   } else {
      //     localStorage.setItem(LS_MAP_VARIANT, initialMapVariant);
      //   }
      // } else {
      //   localStorage.setItem(LS_MAP_VARIANT, initialMapVariant);
      // }

      return initialMapVariant;
    }
  );

  const [searchValue, setSearchValue] = React.useState<IDictionaryDTO | null>(
    null
  );
  const [searchOptions, setSearchOptions] = React.useState<IDictionaryDTO[]>(
    []
  );
  const [menuOption, setMenuOption] = React.useState<MenuOptionType>(
    MenuOptionType.RTK
  );
  const [coverageOperator, setCoverageOperator] =
    React.useState<MenuOptionType>(MenuOptionType.NONE);
  const [isDisableCoverage, setIsDisableCoverage] =
    React.useState<boolean>(true);
  const [isShowPlannedStations, setIsShowPlannedStations] =
    React.useState<boolean>(false);
  const [isDisabledPlannedStations, setIsDisabledPlannedStations] =
    React.useState<boolean>(false);
  const [isDisabledVRS, setIsDisabledVRS] = React.useState<boolean>(false);

  const updateMapVariant = React.useCallback(
    (newMapVariant: IMapVariants): void => {
      setMapVariant(newMapVariant);
      localStorage.setItem(LS_MAP_VARIANT, newMapVariant);
    },
    []
  );

  const value = {
    mapVariant,
    updateMapVariant,
    searchValue,
    setSearchValue,
    searchOptions,
    setSearchOptions,
    menuOption,
    setMenuOption,
    coverageOperator,
    setCoverageOperator,
    isDisableCoverage,
    setIsDisableCoverage,
    isShowPlannedStations,
    setIsShowPlannedStations,
    isDisabledPlannedStations,
    setIsDisabledPlannedStations,
    isDisabledVRS,
    setIsDisabledVRS,
  };

  return <ContextMap.Provider value={value}>{children}</ContextMap.Provider>;
};
