import React from "react";

import { useInView } from "react-intersection-observer";

import { BlockContent } from "components/block-content";
import { BlockWide } from "components/block-wide";
import { InfoIcon } from "components/info-icon";
import { MTSIcon } from "components/mts-icon";
import {
  GAE_ACTION_GROUP,
  GAE_EVENT,
  GAE_EVENT_ACTION,
  GAE_EVENT_CATEGORY,
  GAE_EVENT_LABEL,
  useGoogleAnalyticsEvents,
} from "hooks/google-analytics";

import classes from "./advantages.module.css";

interface IAdvantageData {
  icon: JSX.Element;
  title: string | JSX.Element;
  description: string | JSX.Element;
  badge?: string;
  tooltip?: string;
}

export const Advantages = () => {
  const { sendGAE } = useGoogleAnalyticsEvents();
  const { ref: blockAdvantagesRef, inView: inViewBlockAdvantages } = useInView({
    initialInView: false,
    threshold: 0.5,
    triggerOnce: true,
  });

  const [advantagesData] = React.useState<IAdvantageData[]>([
    {
      icon: (
        <MTSIcon.Realiability sx={{ color: "transparent", fontSize: 96 }} />
      ),
      title: "Надёжность",
      description: (
        <>
          Надёжная инфраструктура{" "}
          <span style={{ display: "block" }}>операторской сети</span>
        </>
      ),
    },
    {
      icon: <MTSIcon.Accuracy sx={{ color: "transparent", fontSize: 96 }} />,
      title: "Точность",
      description: (
        <>
          При подключении к одиночной
          <span style={{ display: "inline-block" }}>
            {" "}
            станции точность составляет{" "}
          </span>
          <span style={{ whiteSpace: "nowrap" }}> 2-5 см</span>
        </>
      ),
    },
    {
      icon: <MTSIcon.VRSIcon sx={{ color: "transparent", fontSize: 96 }} />,
      title: (
        <>
          Персональная виртуальная станция{" "}
          <span style={{ whiteSpace: "nowrap" }}>(V-RS)&nbsp;</span>
          <InfoIcon text="V-RS  (виртуальная  референсная станция)  – объединяет несколько станций для повышения надёжности и неизменности точности при удалении от реальной референсной станции" />
        </>
      ),
      description: (
        <>
          В режиме V-RS из нескольких станций рядом{" "}
          <span style={{ whiteSpace: "nowrap" }}>с вами</span> создаётся
          виртуальная станция.{" "}
          <span className={classes.cardDescriptionThird}>
            Она обеспечивает точность и надёжность
          </span>
        </>
      ),
    },
    {
      icon: <MTSIcon.Connection sx={{ color: "transparent", fontSize: 96 }} />,
      title: "Улучшение качества связи",
      description: (
        <>
          Мы контролируем качество мобильной связи{" "}
          <span style={{ whiteSpace: "nowrap" }}>в зоне</span> ваших работ и
          расширяем покрытие,
          <span className={classes.cardDescriptionFourth}>
            &nbsp;если это необходимо
          </span>
        </>
      ),
    },
    {
      icon: <MTSIcon.Traffic sx={{ color: "transparent", fontSize: 96 }} />,
      title: "Вы не платите за трафик",
      description: (
        <>
          В рамках услуги при использовании{" "}
          <span style={{ whiteSpace: "nowrap" }}>SIM-карты МТС</span>
        </>
      ),
    },
    {
      icon: <MTSIcon.Supporting sx={{ color: "transparent", fontSize: 96 }} />,
      title: "Мы рядом 24/7",
      description: "Поддержка по техническим вопросам",
    },
  ]);

  React.useEffect(() => {
    if (inViewBlockAdvantages) {
      sendGAE({
        id: 44,
        event: GAE_EVENT.vntMain,
        eventCategory: GAE_EVENT_CATEGORY.glavnaya,
        eventAction: GAE_EVENT_ACTION.block_show,
        eventLabel: GAE_EVENT_LABEL.preimuschestva,
        eventValue: null,
        eventContent: null,
        buttonLocation: null,
        filterName: null,
        actionGroup: GAE_ACTION_GROUP.non_interactions,
        productName: null,
      });
    }
  }, [inViewBlockAdvantages, sendGAE]);

  return (
    <div ref={blockAdvantagesRef} className={classes.blockAdvantages}>
      <BlockWide>
        <BlockContent>
          <>
            <h2 className={classes.title}>Преимущества</h2>

            <div className={classes.cards}>
              {advantagesData.map((advantageData, index) => (
                <div key={index} className={classes.card}>
                  <div className={classes.cardIcon}>{advantageData.icon}</div>
                  <div className={classes.cardTitle}>
                    <h3 className={classes.cardTitleText}>
                      {advantageData.title}
                    </h3>
                  </div>
                  <div className={classes.cardInfo}>
                    <p className={classes.cardInfoText}>
                      {advantageData.description}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </>
        </BlockContent>
      </BlockWide>
    </div>
  );
};
