import { MAP_ELEMENT_ID } from "constants/dom-element-ids";

import React from "react";

import { useContextMap } from "hooks/use-context-map";
import { useDisplayStatus } from "hooks/use-display-status";

import { ButtonMobilePanel } from "./components/button-mobile-panel";
import { DialogMobilePanel } from "./components/dialog-mobile-panel";
import { Panel } from "./components/panel";
import { useBodyOverflowHidden } from "./hooks/use-body-overflow-hidden";
import { useMap } from "./hooks/use-map";
import classes from "./map.module.css";
import "./index.css";
import "leaflet/dist/leaflet.css";
import "./yandex";

export const Map = (): JSX.Element => {
  const { searchValue } = useContextMap();
  const { isMobileVersion } = useDisplayStatus();
  useBodyOverflowHidden(isMobileVersion);
  useMap();

  const [isOpenMobilePanel, setIsOpenMobilePanel] =
    React.useState<boolean>(false);
  const [isMountedMobilePanel, setIsMountedMobilePanel] =
    React.useState<boolean>(false);

  const handleCloseDialogMobilePanel = (): void => {
    setIsOpenMobilePanel(false);
    setTimeout(() => {
      setIsMountedMobilePanel(false);
    }, 500);
  };

  const handleToggleDialogMobilePanel = (): void => {
    if (isOpenMobilePanel) {
      handleCloseDialogMobilePanel();
    } else {
      setIsMountedMobilePanel(true);
      setIsOpenMobilePanel(true);
    }
  };

  React.useEffect(() => {
    if (searchValue) {
      handleCloseDialogMobilePanel();
    }
  }, [searchValue]);

  return (
    <>
      <main className={classes.main}>
        {isMobileVersion ? (
          <div className={classes.buttonPanelMobile}>
            <ButtonMobilePanel
              onClick={() => handleToggleDialogMobilePanel()}
            />
          </div>
        ) : (
          <div className={classes.panel}>
            <Panel />
          </div>
        )}

        <div className={classes.map}>
          <div className={classes.mapContainer} id={MAP_ELEMENT_ID} />
        </div>
      </main>
      {isMobileVersion ? (
        <DialogMobilePanel
          isOpen={isOpenMobilePanel}
          isMounted={isMountedMobilePanel}
          onToggle={() => handleToggleDialogMobilePanel()}
        />
      ) : null}
    </>
  );
};
