import { IS_DEVELOPMENT_MODE } from "constants/env";

import React from "react";

import classNames from "classnames";
import { Logo } from "components/logo";
import { UserInfo } from "components/user-info";
import { useContextHeader } from "hooks/use-context-header";
import { useContextMain } from "hooks/use-context-main";
import { getUserLabel } from "utils/get-user-role-label";

import { HeaderDevAuth } from "./components/header-dev-auth";
import { HeaderProfile } from "./components/header-profile";
import { HeaderTabTooltip } from "./components/header-tab-tooltip";
import { TabBarAuthorized } from "./components/tab-bar-authorized";
import { TabBarNotAuthorized } from "./components/tab-bar-not-authorized";
import classes from "./header.module.css";

interface IHeaderProps {
  isMobile: boolean;
}

export const Header = (props: IHeaderProps): JSX.Element => {
  const { isMobile } = props;
  const { closeTabTooltip } = useContextHeader();
  const { isAuthorized, profile } = useContextMain();

  const headerStyles = classNames({
    [classes.header]: true,
    [classes.headerAuthorized]: isAuthorized && !isMobile,
  });

  return (
    <header className={headerStyles}>
      <div className={classes.headerContent}>
        <div className={classes.headerContentBlock}>
          <div className={classes.headerContentBlockLogo}>
            <Logo onClick={closeTabTooltip} />
          </div>

          {!isMobile && (
            <div className={classes.headerContentBlockTabs}>
              <TabBarNotAuthorized />
            </div>
          )}
        </div>

        <div className={classes.headerContentAuth}>
          {IS_DEVELOPMENT_MODE ? <HeaderDevAuth /> : <HeaderProfile />}
        </div>
      </div>

      {isAuthorized && !isMobile && (
        <div className={classes.headerContentBottom}>
          <div className={classes.headerContentBottomBlock}>
            <div className={classes.headerContentBottomBlockTabs}>
              <TabBarAuthorized />
            </div>
            <div className={classes.headerContentBottomUser}>
              <UserInfo userRole={getUserLabel(profile?.role || "")} size="L" />
            </div>
          </div>
        </div>
      )}
      {!isMobile && <HeaderTabTooltip />}
    </header>
  );
};
