import React from "react";

import { BlockContent } from "components/block-content";
import { BlockWide } from "components/block-wide";

import classes from "./footer-of-personal-area.module.css";

export const FooterOfPersonalArea = (): JSX.Element => {
  const [currentYear] = React.useState<number>(new Date().getFullYear());

  return (
    <footer className={classes.blockFooter}>
      <BlockWide>
        <BlockContent>
          <div className={classes.content}>
            <p className={classes.contentText} data-testid="txt_pao">
              © {currentYear} ПАО «МТС». 18+
            </p>
          </div>
        </BlockContent>
      </BlockWide>
    </footer>
  );
};
