import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export const ArrowPrevIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="-1.5"
        y="1.5"
        width="61"
        height="61"
        rx="30.5"
        transform="matrix(-1 0 0 1 61 0)"
        stroke="white"
        strokeWidth="3"
      />
      <path
        d="M32.3633 45.6449L18.9087 32.0008L32.3633 18.3568"
        stroke="white"
        strokeWidth="3"
      />
      <line
        y1="-1.5"
        x2="27.6364"
        y2="-1.5"
        transform="matrix(1 -8.74228e-08 -8.74228e-08 -1 18.9102 31.2727)"
        stroke="white"
        strokeWidth="3"
      />
    </SvgIcon>
  );
};
