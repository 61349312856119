import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const Payment = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.4444 6C21.0581 6 21.5556 6.49746 21.5556 7.11111C21.5556 7.72476 21.0581 8.22222 20.4444 8.22222H9.33333C8.71968 8.22222 8.22222 8.71968 8.22222 9.33333C8.22222 9.94698 8.71968 10.4444 9.33333 10.4444H21.5556C22.7829 10.4444 23.7778 11.4394 23.7778 12.6667L23.7783 13.4156C22.7678 12.9354 21.6375 12.6667 20.4444 12.6667C16.1496 12.6667 12.6667 16.1496 12.6667 20.4444C12.6667 21.6375 12.9354 22.7678 13.4156 23.7783L8.22222 23.7778C6.99492 23.7778 6 22.7829 6 21.5556V9.33333C6 6.87873 6.87873 6 9.33333 6H20.4444ZM20.4444 14.8889C17.3767 14.8889 14.8889 17.3767 14.8889 20.4444C14.8889 23.5122 17.3767 26 20.4444 26C23.5122 26 26 23.5122 26 20.4444C26 17.3767 23.5122 14.8889 20.4444 14.8889ZM20.4444 17.1111C21.0578 17.1111 21.5556 17.6089 21.5556 18.2222V19.3333H22.6667C23.28 19.3333 23.7778 19.8311 23.7778 20.4444C23.7778 21.0578 23.28 21.5556 22.6667 21.5556H21.5556V22.6667C21.5556 23.28 21.0578 23.7778 20.4444 23.7778C19.8311 23.7778 19.3333 23.28 19.3333 22.6667V21.5556H18.2222C17.6089 21.5556 17.1111 21.0578 17.1111 20.4444C17.1111 19.8311 17.6089 19.3333 18.2222 19.3333H19.3333V18.2222C19.3333 17.6089 19.8311 17.1111 20.4444 17.1111Z"
      />
    </SvgIcon>
  );
};

export default Payment;
