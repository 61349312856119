import { PATH_PAYMENTS } from "constants/routes";

import { useMutation } from "react-query";

import { useHistory } from "react-router";

import { useNotifications } from "hooks/use-notifications";
import { IPayGroupDTOPayload } from "interfaces";
import { updatePayment } from "services/api";

interface IPayloadUseMutationUpdatePayment {
  payload: IPayGroupDTOPayload[];
  paymentId?: string;
}

export const useMutationUpdatePayment = () => {
  const { addErrorMessage, addSuccessMessage } = useNotifications();
  const history = useHistory();

  return useMutation(
    (payload: IPayloadUseMutationUpdatePayment) => {
      return updatePayment(payload.payload, payload.paymentId);
    },
    {
      onSuccess: (response) => {
        const { id } = response;
        history.push(`${PATH_PAYMENTS}/${id}`);
        addSuccessMessage({ message: "Информация успешно обновлена" });
      },
      onError: (error) => {
        addErrorMessage({
          message: "Не удалось обновить инофрмацию по платежу",
        });
      },
    }
  );
};
