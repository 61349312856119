import { FIELD_NAMES } from "constants/keys";

import { Controller, useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import { MTSAutocomplete } from "components/mts-autocomplete";
import { MTSButton } from "components/mts-button";
import { MTSInput } from "components/mts-input";
import {
  GAE_ACTION_GROUP,
  GAE_BUTTON_LOCATION,
  GAE_EVENT,
  GAE_EVENT_ACTION,
  GAE_EVENT_CATEGORY,
  GAE_EVENT_LABEL,
  GA_SCREEN_NAME,
  useGoogleAnalyticsEvents,
} from "hooks/google-analytics";
import { useMutationCreateCustomerAdmin } from "hooks/use-mutation-create-customer-admin";
import { useRegions } from "hooks/use-regions";
import { IDictionaryDTO } from "interfaces";
import { AuthService } from "services/auth";
import { fieldSchema } from "utils/yup-schema";
import * as yup from "yup";

import classes from "./new-user-form.module.css";

export interface INewUserFormStruct {
  [FIELD_NAMES.COMPANY_INFO_REGION]: IDictionaryDTO | null;
  [FIELD_NAMES.COMPANY_INFO_COMPANY_NAME]: string;
  [FIELD_NAMES.COMPANY_INFO_FIO]: string;
  [FIELD_NAMES.COMPANY_INFO_EMAIL]: string;
}

const schema = yup.object({
  [FIELD_NAMES.COMPANY_INFO_REGION]: fieldSchema.required.region,
  [FIELD_NAMES.COMPANY_INFO_COMPANY_NAME]: fieldSchema.required.companyName,
  [FIELD_NAMES.COMPANY_INFO_FIO]: fieldSchema.required.fio,
  [FIELD_NAMES.COMPANY_INFO_EMAIL]: fieldSchema.required.email,
});

interface INewUserFormProps {
  initialFormData: INewUserFormStruct;
}

export const NewUserForm = (props: INewUserFormProps): JSX.Element => {
  const { initialFormData } = props;
  const { sendGAE } = useGoogleAnalyticsEvents();
  const { data: regions, isLoading: isLoadingRegions } = useRegions();

  const mutationCreateCustomerAdmin = useMutationCreateCustomerAdmin();

  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    control,
    formState: { errors, isDirty, dirtyFields },
  } = useForm<INewUserFormStruct>({
    mode: "onChange",
    defaultValues: initialFormData,
    resolver: yupResolver(schema),
  });

  const handleLogout = (): void => {
    AuthService.logout();
  };

  const onSubmit = (data: INewUserFormStruct): void => {
    const { region, fio, companyName, email } = data;
    const regionCode: string = region?.code ?? "";

    sendGAE({
      id: 18,
      event: GAE_EVENT.vntLogin,
      eventCategory: GAE_EVENT_CATEGORY.registraciya,
      eventAction: GAE_EVENT_ACTION.button_click,
      eventLabel: GAE_EVENT_LABEL.zaregistrirovatsya,
      eventValue: null,
      screenName: GA_SCREEN_NAME.registration,
      eventContent: null,
      buttonLocation: GAE_BUTTON_LOCATION.screen,
      filterName: null,
      actionGroup: GAE_ACTION_GROUP.interactions,
      productName: null,
    });

    mutationCreateCustomerAdmin.mutate({
      region: regionCode,
      email,
      fio,
      companyName,
    });
  };

  return (
    <>
      <form className={classes.blockForm}>
        <div className={classes.blockFormItem}>
          <Controller
            control={control}
            name={FIELD_NAMES.CONTACT_US_REGION}
            render={(renderProps) => {
              const { field, fieldState } = renderProps;
              const { onChange, value } = field;
              const errorMessage: string = fieldState?.error?.message || "";

              return (
                <MTSAutocomplete
                  required
                  size="m"
                  label="Регион"
                  placeholder="Выберите"
                  labelTooltipText="Укажите регион, где юридически зарегистрирована ваша компания"
                  value={value}
                  onChangeValue={onChange}
                  options={regions || []}
                  loading={isLoadingRegions}
                  errorMessage={errorMessage}
                />
              );
            }}
          />
        </div>
        <div className={classes.blockFormItem}>
          <MTSInput
            required
            label="Наименование компании"
            placeholder="Введите"
            description="Не более 56 символов"
            {...register(FIELD_NAMES.COMPANY_INFO_COMPANY_NAME)}
            errorMessage={
              errors?.[FIELD_NAMES.COMPANY_INFO_COMPANY_NAME]?.message
            }
            onClear={() => {
              setValue(FIELD_NAMES.COMPANY_INFO_COMPANY_NAME, "");
              trigger(FIELD_NAMES.COMPANY_INFO_COMPANY_NAME);
            }}
            isDirty={
              dirtyFields?.[FIELD_NAMES.COMPANY_INFO_COMPANY_NAME] ?? false
            }
          />
        </div>
        <div className={classes.blockFormItem}>
          <MTSInput
            required
            label="ФИО"
            placeholder="Введите"
            infoText="ФИО будет отображаться в личных кабинетах ваших сотрудников"
            {...register(FIELD_NAMES.COMPANY_INFO_FIO)}
            errorMessage={errors?.[FIELD_NAMES.COMPANY_INFO_FIO]?.message}
            onClear={() => {
              setValue(FIELD_NAMES.COMPANY_INFO_FIO, "");
              trigger(FIELD_NAMES.COMPANY_INFO_FIO);
            }}
            isDirty={dirtyFields?.[FIELD_NAMES.COMPANY_INFO_FIO] ?? false}
          />
        </div>
        <div className={classes.blockFormItem}>
          <MTSInput
            required
            label="Email"
            placeholder="Введите"
            infoText="На этот адрес вам будут приходить уведомления по основным 
            событиям в системе и платёжные документы для оплаты услуги. 
            Вы сможете изменить электронный адрес в настройках личного 
            кабинета"
            {...register(FIELD_NAMES.COMPANY_INFO_EMAIL)}
            errorMessage={errors?.[FIELD_NAMES.COMPANY_INFO_EMAIL]?.message}
            onClear={() => {
              setValue(FIELD_NAMES.COMPANY_INFO_EMAIL, "");
              trigger(FIELD_NAMES.COMPANY_INFO_EMAIL);
            }}
            isDirty={dirtyFields?.[FIELD_NAMES.COMPANY_INFO_EMAIL] ?? false}
          />
        </div>
      </form>

      <div className={classes.blockAction}>
        <MTSButton
          variant="secondary"
          size="M"
          onClick={handleLogout}
          sx={{ minWidth: "221px" }}
        >
          Назад
        </MTSButton>
        <MTSButton
          variant="primary"
          size="M"
          onClick={handleSubmit(onSubmit)}
          loading={mutationCreateCustomerAdmin.isLoading}
          disabled={!isDirty}
          sx={{ minWidth: "221px" }}
        >
          Зарегистрироваться
        </MTSButton>
      </div>
    </>
  );
};
