import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const Power = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.33333 2H8.66667V8.66667H7.33333V2ZM10.94 4.39333L11.8867 3.44667C13.18 4.54667 14 6.17333 14 8C14 11.3133 11.3133 14 8 14C4.68667 14 2 11.3133 2 8C2 6.17333 2.82 4.54667 4.11333 3.44667L5.05333 4.38667C4.00667 5.24 3.33333 6.54 3.33333 8C3.33333 10.58 5.42 12.6667 8 12.6667C10.58 12.6667 12.6667 10.58 12.6667 8C12.6667 6.54 11.9933 5.24 10.94 4.39333Z"
      />
    </SvgIcon>
  );
};

export default Power;
