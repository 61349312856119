import MUIBreadcrumbs from "@mui/material/Breadcrumbs";
import classNames from "classnames";
import { MTSIcon } from "components/mts-icon";
import { MTSLink } from "components/mts-link";

import classes from "./breadcrumbs.module.css";

export interface IBreadcrumbsLink {
  name: string;
  href: string;
}

interface IBreadcrumbsProps {
  links: IBreadcrumbsLink[];
  size?: "S" | "M";
  dataTestId?: string | null;
}

export const Breadcrumbs = (props: IBreadcrumbsProps) => {
  const { links, size = "S", dataTestId } = props;
  const isSizeS = size === "S";
  const isSizeM = size === "M";

  const linkStyles = classNames({
    [classes.link]: true,
    [classes.linkSizeS]: isSizeS,
    [classes.linkSizeM]: isSizeM,
  });
  const linkLastStyles = classNames({
    [classes.linkLast]: true,
    [classes.linkSizeS]: isSizeS,
    [classes.linkSizeM]: isSizeM,
  });

  return (
    <MUIBreadcrumbs
      separator={
        <MTSIcon.Right
          sx={{
            fontSize: isSizeS ? 16 : 24,
            color: "var(--color-text-secondary-link)",
          }}
        />
      }
      sx={{
        "& .MuiBreadcrumbs-li": {
          display: "flex",
          alignItems: "center",
          height: "100%",
        },
        "& .MuiBreadcrumbs-separator": {
          marginLeft: "4px",
          marginRight: "4px",
          transform: "translateY(-1px)",
        },
      }}
      data-testid={dataTestId || ""}
    >
      {links.map((link, index) => {
        if (index === links.length - 1) {
          return (
            <span className={linkLastStyles} key={index}>
              {link.name}
            </span>
          );
        } else
          return (
            <MTSLink key={index} to={link.href}>
              <span className={linkStyles}>{link.name}</span>
            </MTSLink>
          );
      })}
    </MUIBreadcrumbs>
  );
};
