import { QueryKeys } from "constants/keys";

import { useMutation, useQueryClient } from "react-query";

import { useNotifications } from "hooks/use-notifications";
import { deleteSubscription } from "services/api";

export const useMutationDeleteSubscription = () => {
  const { addErrorMessage, addSuccessMessage } = useNotifications();
  const queryClient = useQueryClient();

  return useMutation(
    (id: string) => {
      return deleteSubscription(id);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(QueryKeys.PROFILE_SUBSCRIPTIONS);
        await queryClient.invalidateQueries(QueryKeys.BILLING_SUBSCRIPTIONS);
        await queryClient.invalidateQueries(
          QueryKeys.BILLING_SUBSCRIPTIONS_BY_PAYMENT
        );
        addSuccessMessage({ message: "Подписка успешно удалена" });
      },
      onError: (error) => {
        addErrorMessage({ message: "Не удалось удалить подписку" });
      },
    }
  );
};
