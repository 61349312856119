import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export const TriangleDownIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_12681_2409)">
        <path
          d="M6.19895 8.99839C7.00311 10.3387 7.40519 11 7.99969 11C8.59419 11 8.99626 10.3387 9.80042 8.99839L9.96393 8.72589C10.6891 7.51732 11.0516 6.91304 10.7931 6.4565C10.5346 5.99995 9.82994 5.99995 8.42052 5.99995H7.57885C6.16943 5.99995 5.46472 5.99995 5.20623 6.4565C4.94774 6.91304 5.31031 7.51732 6.03545 8.72589L6.19895 8.99839Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_12681_2409">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
