import { DISCOUNT_TYPE } from "constants/keys";

import { MTSIcon } from "components/mts-icon";
import {
  MessageUnpaidPaymentIfRenew,
  NotificationMessage,
} from "components/notification-message";
import { useDiscounts } from "hooks/use-discounts";
import { IDiscountDTO } from "interfaces";
import { getDiscountPercentByCount } from "utils/get-discount-percent-by-count";

import classes from "./table-info-message.module.css";

interface ITableInfoMessageProps {
  countSelectedSubscriptions: number;
  canCreatePayment: boolean | undefined;
}

export const TableInfoMessage = (
  props: ITableInfoMessageProps
): JSX.Element | null => {
  const { countSelectedSubscriptions, canCreatePayment } = props;

  const { data } = useDiscounts();
  const subscriptionTypeDiscounts: IDiscountDTO[] =
    data?.filter(
      (discount) => discount.type === DISCOUNT_TYPE.SUBSCRIPTION_COUNT_DISCOUNT
    ) ?? [];

  if (canCreatePayment === false) {
    return (
      <div className={classes.message}>
        <NotificationMessage
          show={true}
          text={
            <div className={classes.messageText}>
              <MessageUnpaidPaymentIfRenew />
            </div>
          }
        />
      </div>
    );
  }

  if (canCreatePayment === true) {
    return (
      <div className={classes.message}>
        <NotificationMessage
          show={true}
          text={
            <div className={classes.messageText}>
              {countSelectedSubscriptions > 0 ? (
                <>
                  Скидка за продление {countSelectedSubscriptions}{" "}
                  {countSelectedSubscriptions === 1 ? "подписки" : "подписок"}
                  &nbsp;
                  <strong>
                    {getDiscountPercentByCount(
                      countSelectedSubscriptions,
                      subscriptionTypeDiscounts
                    )}
                    %
                  </strong>
                </>
              ) : (
                <>
                  Выберите нужные подписки с помощью чекбоксов и продлите их,
                  нажав на кнопку&nbsp;
                  <MTSIcon.RestartCustom
                    sx={{
                      width: 16,
                      color: "var(--color-icon-primary)",
                    }}
                  />
                </>
              )}
            </div>
          }
        />
      </div>
    );
  }

  return null;
};
