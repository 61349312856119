import { _tabNames } from "constants/tabs";

import React from "react";

import { useHistory } from "react-router-dom";

import classNames from "classnames";
import { IMTSTab } from "components/mts-tabs";
import {
  GAE_ACTION_GROUP,
  GAE_BUTTON_LOCATION,
  GAE_EVENT,
  GAE_EVENT_ACTION,
  GAE_EVENT_CATEGORY,
  GAE_EVENT_LABEL,
  useGoogleAnalyticsEvents,
} from "hooks/google-analytics";
import { useContextHeader } from "hooks/use-context-header";

import { IndustryRenderIcon } from "./components/industry-render-icon";
import classes from "./header-tab-tooltip.module.css";

const TOOLTIP_WIDTH = 480;

interface ITooltipOffset {
  left: number;
  right: number;
}

export const HeaderTabTooltip = React.memo((): JSX.Element | null => {
  const { sendGAE } = useGoogleAnalyticsEvents();

  const {
    isOpenTabTooltip,
    tabNotAuthorizedPositions,
    openedTabData,
    closeTabTooltip,
  } = useContextHeader();

  const [tooltipArrowLeftOffset, setTooltipArrowLeftOffset] =
    React.useState<number>(0);
  const [tooltipOffset, setTooltipOffset] = React.useState<ITooltipOffset>({
    left: 0,
    right: 0,
  });
  const history = useHistory();

  const bgStyles = classNames({
    [classes.bg]: true,
    [classes.bgOpen]: isOpenTabTooltip,
  });

  const tooltipStyles = classNames({
    [classes.tooltip]: true,
    [classes.tooltipOpen]: isOpenTabTooltip,
  });

  const tooltipArrowStyles = classNames({
    [classes.tooltipArrow]: true,
    [classes.tooltipArrowOpen]: isOpenTabTooltip,
  });

  const handleRedirect = (path: string): void => {
    closeTabTooltip();
    history.push(path);
  };

  const handleSendMetrics = (e: React.SyntheticEvent, name: string) => {
    if (name === _tabNames.agro) {
      sendGAE({
        id: 51,
        event: GAE_EVENT.vntMain,
        eventCategory: GAE_EVENT_CATEGORY.glavnaya,
        eventAction: GAE_EVENT_ACTION.element_click,
        eventLabel: GAE_EVENT_LABEL.tochnoe_zemledelie,
        eventValue: null,
        eventContent: null,
        buttonLocation: GAE_BUTTON_LOCATION.popup,
        filterName: null,
        actionGroup: GAE_ACTION_GROUP.interactions,
        productName: null,
      });
    } else if (name === _tabNames.geodesyCadastrе) {
      sendGAE({
        id: 71,
        event: GAE_EVENT.vntMenu,
        eventCategory: GAE_EVENT_CATEGORY.glavnaya,
        eventAction: GAE_EVENT_ACTION.element_click,
        eventLabel: GAE_EVENT_LABEL.geodeziya_i_kadastr,
        eventValue: null,
        eventContent: null,
        buttonLocation: GAE_BUTTON_LOCATION.popup,
        filterName: null,
        actionGroup: GAE_ACTION_GROUP.non_interactions,
        productName: null,
      });
    }
  };

  React.useEffect(() => {
    const resetTooltipPosition = (): void => {
      setTooltipArrowLeftOffset(0);
      setTooltipOffset({
        left: 0,
        right: 0,
      });
    };

    if (isOpenTabTooltip && openedTabData && openedTabData?.index > -1) {
      const buttonPosition: DOMRect =
        tabNotAuthorizedPositions[openedTabData.index];
      const arrowWidth = 20;
      const screenWidth = window.innerWidth;

      const tooltipOffsetData: ITooltipOffset = {
        left: 0,
        right: 0,
      };
      let tooltipArrowLeftOffsetData: number = 0;

      const centerTabLeftOffset =
        buttonPosition.left + buttonPosition.width / 2;

      tooltipArrowLeftOffsetData = Math.round(
        centerTabLeftOffset - arrowWidth / 2
      );

      const isTooltipRightSideBigger =
        screenWidth - centerTabLeftOffset <= TOOLTIP_WIDTH / 2;

      if (isTooltipRightSideBigger) {
        tooltipOffsetData.right = 10;
      } else {
        tooltipOffsetData.right = 0;

        if (centerTabLeftOffset <= TOOLTIP_WIDTH / 2) {
          tooltipOffsetData.left = TOOLTIP_WIDTH / 2 + 10;
        } else {
          tooltipOffsetData.left = Math.round(
            centerTabLeftOffset - arrowWidth / 2
          );
        }
      }

      setTooltipOffset(tooltipOffsetData);
      setTooltipArrowLeftOffset(tooltipArrowLeftOffsetData);
    } else {
      resetTooltipPosition();
    }

    return () => {
      resetTooltipPosition();
    };
  }, [isOpenTabTooltip, openedTabData, tabNotAuthorizedPositions]);

  if (isOpenTabTooltip) {
    return (
      <div className={bgStyles} onClick={() => closeTabTooltip()}>
        <span
          className={tooltipArrowStyles}
          style={{
            left: `${tooltipArrowLeftOffset + 2}px`,
          }}
        />
        <div
          className={tooltipStyles}
          style={{
            minWidth: `${TOOLTIP_WIDTH}px`,
            right:
              tooltipOffset.right > 0 ? `${tooltipOffset.right}px` : undefined,
            left:
              tooltipOffset.right > 0 ? undefined : `${tooltipOffset.left}px`,
            transform:
              tooltipOffset.right === 0 ? "translateX(-50%)" : undefined,
          }}
          onClick={(e: React.SyntheticEvent) => {
            e.stopPropagation();
          }}
        >
          {openedTabData?.data?.children
            ? openedTabData.data.children.map((tabData: IMTSTab) => {
                const tabHref = tabData.href;
                const tabName = tabData.name;
                const tabDescription = tabData?.description || "";

                return (
                  <button
                    key={tabData.id}
                    className={classes.btn}
                    onClick={(e) => {
                      handleSendMetrics(e, tabData.name);
                      handleRedirect(tabHref);
                    }}
                  >
                    <IndustryRenderIcon
                      linkHref={tabHref}
                      iconStyles={{ fontSize: "48px", color: "transparent" }}
                    />
                    <div className={classes.btnInfo}>
                      <span className={classes.btnInfoLabel}>{tabName}</span>

                      {tabDescription && (
                        <span className={classes.btnInfoDesc}>
                          {tabDescription}
                        </span>
                      )}
                    </div>
                  </button>
                );
              })
            : null}
        </div>
      </div>
    );
  }

  return null;
});
