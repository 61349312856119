import { FileRequestStatuses } from "constants/keys";

import React from "react";

import Tooltip from "@mui/material/Tooltip";
import classNames from "classnames";

import classes from "./table-cell-file-request-status.module.css";

type ITableCellFileRequestStatusProps = {
  statusCode: string;
};

export const TableCellFileRequestStatus = (
  props: ITableCellFileRequestStatusProps
) => {
  const { statusCode } = props;
  let statusTitle = "";
  let tooltipText: React.ReactNode | null = null;

  const indicatorStyles = classNames({
    [classes.indicator]: true,
    [classes.indicatorComplete]: statusCode === FileRequestStatuses.complete,
    [classes.indicatorError]: statusCode === FileRequestStatuses.error,
    [classes.indicatorDeleted]: statusCode === FileRequestStatuses.deleted,
  });

  const textSyles = classNames({
    [classes.text]: true,
    [classes.textInactive]:
      statusCode === FileRequestStatuses.complete ||
      statusCode === FileRequestStatuses.error ||
      statusCode === FileRequestStatuses.deleted,
  });

  switch (statusCode) {
    case FileRequestStatuses.queue:
    case FileRequestStatuses.inProgress: {
      statusTitle = "Выполняется";

      break;
    }
    case FileRequestStatuses.complete: {
      statusTitle = "Выполнен";
      break;
    }
    case FileRequestStatuses.error: {
      statusTitle = "Ошибка";
      break;
    }
    case FileRequestStatuses.deleted: {
      statusTitle = "Файл удалён";
      tooltipText = (
        <>
          Файл автоматически удалён,
          <br />
          срок хранения файла — 7 дней.
        </>
      );
      break;
    }
    default:
      break;
  }

  return (
    <Tooltip
      title={tooltipText || ""}
      arrow
      disableInteractive
      disableHoverListener={tooltipText ? false : true}
      disableFocusListener={tooltipText ? false : true}
      disableTouchListener={tooltipText ? false : true}
    >
      <div className={classes.blockIndicator}>
        <span className={indicatorStyles} />
        <span className={textSyles}>{statusTitle}</span>
      </div>
    </Tooltip>
  );
};
