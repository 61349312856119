import React from "react";

export const useLatest = (value: any) => {
  const valueRef = React.useRef(value);

  React.useLayoutEffect(() => {
    valueRef.current = value;
  }, [value]);

  return valueRef;
};
