import { PATH_AGRONOMY, PATH_GEODESY_CADASTRE } from "constants/routes";

import React from "react";

import { AgroIcon } from "./components/agro-icon";
import { GeodesyCadastreIcon } from "./components/geodesy-cadastre-icon";

interface IIndustryRenderIconProps {
  linkHref: string;
  iconStyles: React.CSSProperties;
}

export const IndustryRenderIcon = React.memo(
  (props: IIndustryRenderIconProps): JSX.Element | null => {
    const { linkHref, iconStyles } = props;

    if (linkHref === PATH_AGRONOMY) {
      return <AgroIcon sx={iconStyles} />;
    } else if (linkHref === PATH_GEODESY_CADASTRE) {
      return <GeodesyCadastreIcon sx={iconStyles} />;
    }

    return null;
  }
);
