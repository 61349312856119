import React from "react";

import { useInView } from "react-intersection-observer";

import { useMediaQuery } from "@mui/material";
import { BlockContent } from "components/block-content";
import { BlockWide } from "components/block-wide";
import { DialogContactUs } from "components/dialog-contact-us";
import { MTSButton } from "components/mts-button";
import { MTSIcon } from "components/mts-icon";
import {
  GAE_ACTION_GROUP,
  GAE_BUTTON_LOCATION,
  GAE_EVENT,
  GAE_EVENT_ACTION,
  GAE_EVENT_CATEGORY,
  GAE_EVENT_LABEL,
  useGoogleAnalyticsEvents,
} from "hooks/google-analytics";
import { useAppTheme } from "hooks/use-app-theme";

import { SliderPartners } from "./components/slider-partners";
import AgroSturman from "./icons/agro-shturman";
import Geomir from "./icons/geomir";
import GSNN from "./icons/gnss";
import Metrica from "./icons/metrica";
import Orient from "./icons/orient";
import UGT from "./icons/ugt";
import computerImage from "./img/computerImage.png";
import classes from "./partners.module.css";

const options = [
  {
    title: (
      <>
        Специальные
        <br />
        цены
      </>
    ),
    icon: <MTSIcon.RubleCircle sx={{ fontSize: 40, color: "#FFFFFF" }} />,
  },
  {
    title: (
      <>
        Поддержка
        <br />
        24/7
      </>
    ),
    icon: <MTSIcon.Support sx={{ fontSize: 40, color: "#FFFFFF" }} />,
  },
  {
    title: (
      <>
        Увеличение
        <br />
        продаж
      </>
    ),

    icon: <MTSIcon.Smile sx={{ fontSize: 40, color: "#FFFFFF" }} />,
  },
  {
    title: (
      <>
        Дополнительные
        <br />
        скидки
      </>
    ),

    icon: <MTSIcon.Discount sx={{ fontSize: 40, color: "#FFFFFF" }} />,
  },
];

export const Partners = (): JSX.Element => {
  const theme = useAppTheme();

  const { sendGAE } = useGoogleAnalyticsEvents();
  const { ref: blockPartnersRef, inView: inViewBlockPartners } = useInView({
    initialInView: false,
    threshold: 0.5,
    triggerOnce: true,
  });

  const isMinWidthDesktop_1280 = useMediaQuery(
    theme.breakpoints.up("desktopS_1280")
  );

  const [isOpenDialogBecomePartner, setIsOpenDialogBecomePartner] =
    React.useState<boolean>(false);

  const handleOpenDialog = (): void => {
    setIsOpenDialogBecomePartner(true);
  };

  const handleCloseDialog = (): void => {
    setIsOpenDialogBecomePartner(false);
  };

  React.useEffect(() => {
    if (inViewBlockPartners) {
      sendGAE({
        id: 46,
        event: GAE_EVENT.vntMain,
        eventCategory: GAE_EVENT_CATEGORY.glavnaya,
        eventAction: GAE_EVENT_ACTION.block_show,
        eventLabel: GAE_EVENT_LABEL.stante_partnerom,
        eventValue: null,
        eventContent: null,
        buttonLocation: null,
        filterName: null,
        actionGroup: GAE_ACTION_GROUP.non_interactions,
        productName: null,
      });
    }
  }, [inViewBlockPartners, sendGAE]);

  const handleSendMetrics = () => {
    sendGAE({
      id: 84,
      event: GAE_EVENT.vntMain,
      eventCategory: GAE_EVENT_CATEGORY.glavnaya,
      eventAction: GAE_EVENT_ACTION.element_click,
      eventLabel: GAE_EVENT_LABEL.stat_partnerom,
      eventValue: null,
      eventContent: null,
      buttonLocation: GAE_BUTTON_LOCATION.screen,
      filterName: null,
      actionGroup: GAE_ACTION_GROUP.non_interactions,
      productName: null,
    });
  };

  return (
    <div ref={blockPartnersRef} className={classes.blockPartners}>
      <BlockWide>
        <BlockContent>
          <div className={classes.header}>
            <h2 className={classes.headerTitle}>Станьте нашим партнёром</h2>

            <p className={classes.headerDesc}>
              Продавайте вместе с оборудованием доступ к RTK и RINEX и
              зарабатывайте больше
            </p>
          </div>

          <div className={classes.contentInner}>
            <img
              className={classes.contentImg}
              src={computerImage}
              alt="computerImg"
            />
            <div className={classes.contentAdvantage}>
              <div className={classes.options}>
                {options.map((option, index) => (
                  <div key={index} className={classes.optionItem}>
                    <div className={classes.optionItemTitle}>
                      <h4 className={classes.optionItemTitleText}>
                        {option.title}
                      </h4>
                    </div>
                    {option.icon}
                  </div>
                ))}
              </div>
              <MTSButton
                size="L"
                sx={{ width: "100%", maxWidth: "254px" }}
                onClick={() => {
                  handleOpenDialog();
                  handleSendMetrics();
                }}
              >
                Стать партнёром
              </MTSButton>
            </div>
          </div>
          <DialogContactUs
            isOpen={isOpenDialogBecomePartner}
            handleCloseDialog={handleCloseDialog}
            feedbackType="PARTNER"
            title="Стать партнёром"
          />

          <div className={classes.example}>
            <p className={classes.exampleTitle}>С нами сотрудничают</p>

            {isMinWidthDesktop_1280 ? (
              <div className={classes.exampleLogo}>
                <Orient sx={{ width: 178, height: 84 }} />
                <GSNN sx={{ width: 178, height: 84 }} />
                <UGT sx={{ width: 178, height: 84 }} />
                <Metrica sx={{ width: 178, height: 84 }} />
                <Geomir sx={{ width: 178, height: 84 }} />
                <AgroSturman sx={{ width: 178, height: 84 }} />
              </div>
            ) : (
              <SliderPartners />
            )}
          </div>
        </BlockContent>
      </BlockWide>
    </div>
  );
};
