import React from "react";

import { MTSChip } from "components/mts-chip";
import { getDateFormatted } from "utils/get-date-formatted";

interface IPaymentInfoChipProps {
  onClose: () => void;
  modifiedDate: string;
}

export const PaymentInfoChip = (
  props: IPaymentInfoChipProps
): JSX.Element | null => {
  const { modifiedDate, onClose } = props;
  const { date } = getDateFormatted(modifiedDate);

  const [isOpen, setIsOpen] = React.useState<boolean>(true);

  const handleClose = (): void => {
    onClose();
    setIsOpen(false);
  };

  if (!isOpen) return null;

  return (
    <MTSChip
      size="M"
      value={`Платёж от ${date ? date : "..."}`}
      onClick={handleClose}
    />
  );
};
