import {
  SUBSCRIPTION_DURATIONS,
  SUBSCRIPTION_REGIONS,
  SUBSCRIPTION_STATUSES,
} from "constants/keys";

import {
  IDictionaryDTO,
  ITariffSliderValue,
  IQuantityDiscountStruct,
} from "interfaces";

interface IMockData {
  regions: IDictionaryDTO[];
  subscriptionStatuses: IDictionaryDTO[];
  durations: IDictionaryDTO[];
  serviceKinds: IDictionaryDTO[];
  tariffs: IDictionaryDTO[];
  timezones: IDictionaryDTO[];
  rinexFrequencies: IDictionaryDTO[];
  rinexVersions: IDictionaryDTO[];
  tariffSliderValues: ITariffSliderValue[];
  quantityDiscounts: IQuantityDiscountStruct[];
  rinexStartHours: IDictionaryDTO[];
  rinexDurationHours: IDictionaryDTO[];
}

const regions: IDictionaryDTO[] = [
  {
    id: 0,
    code: SUBSCRIPTION_REGIONS.ALL,
    name: "Все регионы",
    description: "Все регионы",
  },
];

const subscriptionStatuses: IDictionaryDTO[] = [
  {
    id: 0,
    name: "Оплачена",
    code: SUBSCRIPTION_STATUSES.PAID,
    description: "",
  },
  {
    id: 1,
    name: "Активна",
    code: SUBSCRIPTION_STATUSES.ACTIVATED,
    description: "",
  },
  {
    id: 2,
    name: "Истёк срок действия",
    code: SUBSCRIPTION_STATUSES.EXPIRED,
    description: "",
  },
];

const durations: IDictionaryDTO[] = [
  {
    id: 1,
    code: "DAY",
    name: "1 день",
    description: "1 день",
  },
  {
    id: 2,
    code: "MONTH",
    name: "1 месяц",
    description: "30 дней / 1 месяц",
  },
  {
    id: 3,
    code: "QUARTER",
    name: "3 месяца",
    description: "90 дней / 3 месяца",
  },
  {
    id: 4,
    code: "HALF_YEAR",
    name: "6 месяцев",
    description: "180 дней / 6 месяцев",
  },
  {
    id: 5,
    code: "YEAR",
    name: "1 год",
    description: "365 дней / 1 год",
  },
  {
    id: 6,
    code: "TWO_WEEKS",
    name: "14 дней",
    description: "14 дней",
  },
];

const serviceKinds: IDictionaryDTO[] = [
  {
    id: 1,
    code: "RTK_RINEX",
    name: "RTK+RINEX",
    description: "Услуга RTK+RINEX",
  },
  {
    id: 2,
    code: "RINEX",
    name: "RINEX",
    description: "Услуга RINEX",
  },
];

const tariffs: IDictionaryDTO[] = [
  { id: 0, name: "RTK (1 день)", code: "RTK (1 день)", description: "" },
  { id: 1, name: "RTK (1 нед.)", code: "RTK (1 нед.)", description: "" },
  { id: 2, name: "RTK (1 мес.)", code: "RTK (1 мес.)", description: "" },
  { id: 3, name: "RINEX (1 день)", code: "RINEX (1 день)", description: "" },
  { id: 4, name: "RINEX (1 нед.)", code: "RINEX (1 нед.)", description: "" },
  { id: 5, name: "RINEX (1 мес.)", code: "RINEX (1 мес.)", description: "" },
];

const tariffSliderValues: ITariffSliderValue[] = [
  {
    value: 0,
    label: "1 день",
    fullLabel: "1 день",
    duration: SUBSCRIPTION_DURATIONS.DAY,
  },
  {
    value: 1,
    label: "1 мес",
    fullLabel: "1 месяц",
    duration: SUBSCRIPTION_DURATIONS.MONTH,
  },
  {
    value: 2,
    label: "3 мес",
    fullLabel: "3 месяца",
    duration: SUBSCRIPTION_DURATIONS.QUARTER,
  },
  {
    value: 3,
    label: "6 мес",
    fullLabel: "6 месяцев",
    duration: SUBSCRIPTION_DURATIONS.HALF_YEAR,
  },
  {
    value: 4,
    label: "1 год",
    fullLabel: "1 год",
    duration: SUBSCRIPTION_DURATIONS.YEAR,
  },
];

const timezones: IDictionaryDTO[] = [
  {
    id: 1,
    name: "UTC −12:00",
    code: "-12:00",
    description: "",
  },
  {
    id: 2,
    name: "UTC −11:00",
    code: "-11:00",
    description: "",
  },

  {
    id: 3,
    name: "UTC −10:00",
    code: "-10:00",
    description: "",
  },

  {
    id: 4,
    name: "UTC −09:30",
    code: "-09:30",
    description: "",
  },
  {
    id: 5,
    name: "UTC −09:00",
    code: "-09:00",
    description: "",
  },
  {
    id: 6,
    name: "UTC −08:00",
    code: "-08:00",
    description: "",
  },

  {
    id: 7,
    name: "UTC −07:00",
    code: "-07:00",
    description: "",
  },

  {
    id: 8,
    name: "UTC −06:00",
    code: "-06:00",
    description: "",
  },

  {
    id: 9,
    name: "UTC −05:00",
    code: "-05:00",
    description: "",
  },

  {
    id: 10,
    name: "UTC −04:00",
    code: "-04:00",
    description: "",
  },

  {
    id: 11,
    name: "UTC −03:30",
    code: "-03:30",
    description: "",
  },
  {
    id: 12,
    name: "UTC −03:00",
    code: "-03:00",
    description: "",
  },

  {
    id: 13,
    name: "UTC −02:00",
    code: "-02:00",
    description: "",
  },

  {
    id: 14,
    name: "UTC −01:00",
    code: "-01:00",
    description: "",
  },

  {
    id: 15,
    name: "UTC ±00:00",
    code: "+00:00",
    description: "",
  },

  {
    id: 16,
    name: "UTC +01:00",
    code: "+01:00",
    description: "",
  },

  {
    id: 17,
    name: "UTC +02:00",
    code: "+02:00",
    description: "",
  },

  {
    id: 18,
    name: "UTC +03:00",
    code: "+03:00",
    description: "",
  },

  {
    id: 19,
    name: "UTC +03:30",
    code: "+03:30",
    description: "",
  },
  {
    id: 20,
    name: "UTC +04:00",
    code: "+04:00",
    description: "",
  },

  {
    id: 21,
    name: "UTC +04:30",
    code: "+04:30",
    description: "",
  },
  {
    id: 22,
    name: "UTC +05:00",
    code: "+05:00",
    description: "",
  },

  {
    id: 23,
    name: "UTC +05:30",
    code: "+05:30",
    description: "",
  },

  {
    id: 24,
    name: "UTC +05:45",
    code: "+05:45",
    description: "",
  },
  {
    id: 25,
    name: "UTC +06:00",
    code: "+06:00",
    description: "",
  },

  {
    id: 26,
    name: "UTC +06:30",
    code: "+06:30",
    description: "",
  },

  {
    id: 27,
    name: "UTC +07:00",
    code: "+07:00",
    description: "",
  },

  {
    id: 28,
    name: "UTC +08:00",
    code: "+08:00",
    description: "",
  },

  {
    id: 29,
    name: "UTC +08:45",
    code: "+08:45",
    description: "",
  },
  {
    id: 30,
    name: "UTC +09:00",
    code: "+09:00",
    description: "",
  },

  {
    id: 31,
    name: "UTC +09:30",
    code: "+09:30",
    description: "",
  },
  {
    id: 32,
    name: "UTC +10:00",
    code: "+10:00",
    description: "",
  },

  {
    id: 33,
    name: "UTC +10:30",
    code: "+10:30",
    description: "",
  },
  {
    id: 34,
    name: "UTC +11:00",
    code: "+11:00",
    description: "",
  },

  {
    id: 35,
    name: "UTC +12:00",
    code: "+12:00",
    description: "",
  },

  {
    id: 36,
    name: "UTC +12:45",
    code: "+12:45",
    description: "",
  },
  {
    id: 37,
    name: "UTC +13:00",
    code: "+13:00",
    description: "",
  },

  {
    id: 38,
    name: "UTC +14:00",
    code: "+14:00",
    description: "",
  },
];

const rinexFrequencies: IDictionaryDTO[] = [
  {
    id: 0,
    name: "1 сек.",
    code: "1",
    description: "1 секунда",
  },
];

const rinexVersions: IDictionaryDTO[] = [
  {
    id: 0,
    name: "3.02",
    code: "3.02",
    description: "3.02",
  },
];

const quantityDiscounts: IQuantityDiscountStruct[] = [
  {
    counts: "1 – 5",
    description: "1-5 подписок",
    discountSize: 0,
    minCounts: 1,
    maxCounts: 5,
  },
  {
    counts: "6 – 10",
    description: "6-10 подписок",
    discountSize: 3,
    minCounts: 6,
    maxCounts: 10,
  },
  {
    counts: "11 – 50",
    description: "11-50 подписок",
    discountSize: 5,
    minCounts: 11,
    maxCounts: 50,
  },
  {
    counts: "51 – 250",
    description: "51-250 подписок",
    discountSize: 10,
    minCounts: 51,
    maxCounts: 250,
  },
  {
    counts: "251 – 500",
    description: "251-500 подписок",
    discountSize: 15,
    minCounts: 251,
    maxCounts: 500,
  },
  {
    counts: "501 – 1000",
    description: "501-1000 подписок",
    discountSize: 20,
    minCounts: 501,
    maxCounts: 1000,
  },
];

export const rinexStartHours: IDictionaryDTO[] = [
  {
    id: 1,
    name: "00:00",
    code: "00:00",
    description: "",
  },
  {
    id: 2,
    name: "01:00",
    code: "01:00",
    description: "",
  },
  {
    id: 3,
    name: "02:00",
    code: "02:00",
    description: "",
  },
  {
    id: 4,
    name: "03:00",
    code: "03:00",
    description: "",
  },
  {
    id: 5,
    name: "04:00",
    code: "04:00",
    description: "",
  },
  {
    id: 6,
    name: "05:00",
    code: "05:00",
    description: "",
  },
  {
    id: 7,
    name: "06:00",
    code: "06:00",
    description: "",
  },
  {
    id: 8,
    name: "07:00",
    code: "07:00",
    description: "",
  },
  {
    id: 9,
    name: "08:00",
    code: "08:00",
    description: "",
  },
  {
    id: 10,
    name: "09:00",
    code: "09:00",
    description: "",
  },
  {
    id: 11,
    name: "10:00",
    code: "10:00",
    description: "",
  },
  {
    id: 12,
    name: "11:00",
    code: "11:00",
    description: "",
  },
  {
    id: 13,
    name: "12:00",
    code: "12:00",
    description: "",
  },
  {
    id: 14,
    name: "13:00",
    code: "13:00",
    description: "",
  },
  {
    id: 15,
    name: "14:00",
    code: "14:00",
    description: "",
  },
  {
    id: 16,
    name: "15:00",
    code: "15:00",
    description: "",
  },
  {
    id: 17,
    name: "16:00",
    code: "16:00",
    description: "",
  },
  {
    id: 18,
    name: "17:00",
    code: "17:00",
    description: "",
  },
  {
    id: 19,
    name: "18:00",
    code: "18:00",
    description: "",
  },
  {
    id: 20,
    name: "19:00",
    code: "19:00",
    description: "",
  },
  {
    id: 21,
    name: "20:00",
    code: "20:00",
    description: "",
  },
  {
    id: 22,
    name: "21:00",
    code: "21:00",
    description: "",
  },
  {
    id: 23,
    name: "22:00",
    code: "22:00",
    description: "",
  },
  {
    id: 24,
    name: "23:00",
    code: "23:00",
    description: "",
  },
];

export const rinexDurationHours: IDictionaryDTO[] = [
  {
    id: 1,
    name: "1 час",
    code: "1",
    description: "",
  },
  {
    id: 2,
    name: "2 часа",
    code: "2",
    description: "",
  },
  {
    id: 3,
    name: "3 часа",
    code: "3",
    description: "",
  },
  {
    id: 4,
    name: "4 часа",
    code: "4",
    description: "",
  },
  {
    id: 5,
    name: "5 часов",
    code: "5",
    description: "",
  },
  {
    id: 6,
    name: "6 часов",
    code: "6",
    description: "",
  },
  {
    id: 7,
    name: "7 часов",
    code: "7",
    description: "",
  },
  {
    id: 8,
    name: "8 часов",
    code: "8",
    description: "",
  },
  {
    id: 9,
    name: "9 часов",
    code: "9",
    description: "",
  },
  {
    id: 10,
    name: "10 часов",
    code: "10",
    description: "",
  },
  {
    id: 11,
    name: "11 часов",
    code: "11",
    description: "",
  },
  {
    id: 12,
    name: "12 часов",
    code: "12",
    description: "",
  },
  {
    id: 13,
    name: "13 часов",
    code: "13",
    description: "",
  },
  {
    id: 14,
    name: "14 часов",
    code: "14",
    description: "",
  },
  {
    id: 15,
    name: "15 часов",
    code: "15",
    description: "",
  },
  {
    id: 16,
    name: "16 часов",
    code: "16",
    description: "",
  },
  {
    id: 17,
    name: "17 часов",
    code: "17",
    description: "",
  },
  {
    id: 18,
    name: "18 часов",
    code: "18",
    description: "",
  },
  {
    id: 19,
    name: "19 часов",
    code: "19",
    description: "",
  },
  {
    id: 20,
    name: "20 часов",
    code: "20",
    description: "",
  },
  {
    id: 21,
    name: "21 час",
    code: "21",
    description: "",
  },
  {
    id: 22,
    name: "22 часа",
    code: "22",
    description: "",
  },
  {
    id: 23,
    name: "23 часа",
    code: "23",
    description: "",
  },
  {
    id: 24,
    name: "24 часа",
    code: "24",
    description: "",
  },
];

export const mockData: IMockData = {
  regions,
  subscriptionStatuses,
  durations,
  serviceKinds,
  tariffs,
  timezones,
  rinexFrequencies,
  rinexVersions,
  tariffSliderValues,
  quantityDiscounts,
  rinexStartHours,
  rinexDurationHours,
};
