import React from "react";

import TagManager from "react-gtm-module";

export const useInitGoogleAnalytics = (): void => {
  React.useEffect(() => {
    const tagManagerArgs = {
      gtmId: "GTM-PLRDC84",
    };

    TagManager.initialize(tagManagerArgs);
  }, []);
};
