import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const Megafon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="32" height="32" rx="8" />
      <path
        d="M20.8179 19.8296C20.3236 19.8296 19.9531 19.4178 19.9531 18.9648C19.9531 18.4708 20.3649 18.1 20.8179 18.1C21.3119 18.1 21.6824 18.5118 21.6824 18.9648C21.6824 19.4178 21.3119 19.8296 20.8179 19.8296ZM18.7588 19.8296C18.2648 19.8296 17.8942 19.4178 17.8942 18.9648C17.8942 18.4708 18.306 18.1 18.7588 18.1C19.2531 18.1 19.6236 18.5118 19.6236 18.9648C19.6236 19.4178 19.2531 19.8296 18.7588 19.8296ZM16.7002 19.8296C16.2059 19.8296 15.8354 19.4178 15.8354 18.9648C15.8354 18.4708 16.2472 18.1 16.7002 18.1C17.1942 18.1 17.5647 18.5118 17.5647 18.9648C17.606 19.4178 17.1942 19.8296 16.7002 19.8296ZM16.8647 9.04108V16.2472C16.8647 16.5765 16.6177 16.8237 16.2882 16.8237H15.4236C15.3824 16.8237 15.3411 16.8647 15.3411 16.906V22.959C15.5471 22.959 15.7529 23.0001 15.9999 23.0001C19.8706 23.0001 23 19.8706 23 16.0002C23 12.4177 20.3236 9.45285 16.8647 9.04108Z"
        fill="white"
      />
      <path
        d="M14.6413 14.7646C14.147 14.7646 13.7765 14.353 13.7765 13.9C13.7765 13.4058 14.1883 13.0352 14.6413 13.0352C15.1353 13.0352 15.5058 13.447 15.5058 13.9C15.5058 14.353 15.1353 14.7646 14.6413 14.7646ZM15.9999 9C12.1294 9 9 12.1294 9 16.0001C9 19.3765 11.3471 22.1767 14.5176 22.8355V16.5354C14.5176 16.2471 14.7648 16.0001 15.0528 15.9589H15.9176C15.9589 15.9589 15.9999 15.9176 15.9999 15.8764V9Z"
        fill="white"
      />
    </SvgIcon>
  );
};

export default Megafon;
