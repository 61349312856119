import React from "react";

import { Theme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

import classes from "./copyright.module.css";

export const Copyright = (): JSX.Element => {
  const isLess768px = useMediaQuery((_theme: Theme) =>
    _theme.breakpoints.down("tablet_768")
  );

  const [currentYear] = React.useState<number>(new Date().getFullYear());

  return (
    <div className={classes.blockCopyright}>
      <p className={classes.copyrightText}>
        © {currentYear} ПАО «МТС». {isLess768px ? null : "Все права защищены"}
      </p>

      <p className={classes.copyrightText}>
        {isLess768px ? "Все права защищены. " : null}18+
      </p>
    </div>
  );
};
