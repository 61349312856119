import { PATH_ADD_SUBSCRIPTIONS } from "constants/routes";

import React from "react";

import { useHistory, useLocation } from "react-router";

import { AppHelmet } from "components/app-helmet";
import { ContainerContent } from "components/container-content";
import { FooterOfPersonalArea } from "components/footer-of-personal-area";
import { IconWrapper } from "components/icon-wrapper";
import { Loader } from "components/loader";
import { MTSButton } from "components/mts-button";
import { MTSIcon } from "components/mts-icon";
import { PageTitle } from "components/page-title";
import { Plug } from "components/plug";
import { SubscriptionTables } from "components/subscription-tables";
import { TableSubscriptionsFilters } from "components/table-subscriptions-filters";
import { TableSubscriptionsTabs } from "components/table-subscriptions-tabs";
import { TemplateServiceNotAvailable } from "components/template-service-not-available";
import {
  GAE_ACTION_GROUP,
  GAE_BUTTON_LOCATION,
  GAE_EVENT,
  GAE_EVENT_ACTION,
  GAE_EVENT_CATEGORY,
  GAE_EVENT_LABEL,
  useGoogleAnalyticsEvents,
} from "hooks/google-analytics";
import { useTableSubscriptionsFilters } from "hooks/table/use-table-subscriptions-filters";
import { useTabNames } from "hooks/tabs/use-tab-names";
import { useTabsInSubscriptionsPage } from "hooks/tabs/use-tabs-in-subscriptions-page";
import { useSubscriptionsByStatuses } from "hooks/use-subscriptions-by-statuses";
import { ISubscriptionsByStatuses } from "interfaces";

import classes from "./subscriptions-info.module.css";

const PAGE_TITLE = "Подписки";

export const SubscriptionsInfo = (): JSX.Element => {
  const history = useHistory();
  const location = useLocation();

  const { sendGAE } = useGoogleAnalyticsEvents();
  const { tabNames } = useTabNames();

  const { subscriptionsByStatuses, isDataReady, isError } =
    useSubscriptionsByStatuses();

  const { tableFilters, isSearchFilled, clearSearchInput, register, control } =
    useTableSubscriptionsFilters();
  const subscriptions: ISubscriptionsByStatuses = React.useMemo(
    () => subscriptionsByStatuses,
    [subscriptionsByStatuses]
  );
  const isEmptyData = subscriptions?.all?.length ? false : true;

  const { tabs, selectedTabId } = useTabsInSubscriptionsPage(
    subscriptions,
    tableFilters
  );

  const handleChangeTab = (
    event: React.SyntheticEvent,
    newTabNumber: number
  ): void => {
    const tabHref: string = tabs[newTabNumber].href;

    const locationSearchPararms = new URLSearchParams(location.search);

    history.push({
      pathname: tabHref,
      search: locationSearchPararms.toString(),
    });
  };

  const handleAddSubscriptions = (): void => {
    sendGAE({
      id: 24,
      event: GAE_EVENT.vntPodpiski,
      eventCategory: GAE_EVENT_CATEGORY.podpiski,
      eventAction: GAE_EVENT_ACTION.button_click,
      eventLabel: GAE_EVENT_LABEL.dobavit_podpiski,
      eventValue: null,
      eventContent: null,
      buttonLocation: GAE_BUTTON_LOCATION.screen,
      filterName: null,
      actionGroup: GAE_ACTION_GROUP.interactions,
      productName: null,
    });

    history.push(PATH_ADD_SUBSCRIPTIONS, {
      from: {
        pageName: tabNames.subscriptions,
        pathname: location.pathname,
      },
    });
  };

  return (
    <>
      <AppHelmet title={`${PAGE_TITLE} - Личный кабинет`} />

      <ContainerContent>
        <PageTitle text={PAGE_TITLE} dataTestId="txt_subscriptions" />

        {isError ? (
          <TemplateServiceNotAvailable />
        ) : (
          <>
            {isDataReady ? (
              <>
                {!isEmptyData && (
                  <div className={classes.blockAction}>
                    <TableSubscriptionsFilters
                      register={register}
                      control={control}
                      isSearchFilled={isSearchFilled}
                      clearSearchInput={clearSearchInput}
                    />

                    <div className={classes.blockButton}>
                      <MTSButton
                        data-testid="btn_subs_add"
                        variant="secondary"
                        size="M"
                        onClick={handleAddSubscriptions}
                        noWrap
                        IconStart={MTSIcon.Plus}
                      >
                        Добавить подписки
                      </MTSButton>
                    </div>
                  </div>
                )}
                {!isEmptyData && (
                  <div className={classes.blockTabs}>
                    <TableSubscriptionsTabs
                      selectedTab={tabs.findIndex(
                        (tab) => tab.id === selectedTabId
                      )}
                      tabs={tabs}
                      onChange={handleChangeTab}
                    />
                  </div>
                )}
                {isEmptyData ? (
                  <div className={classes.blockEmptyData}>
                    <Plug
                      icon={
                        <IconWrapper
                          size={64}
                          icon={
                            <MTSIcon.Mail
                              sx={{
                                fontSize: "24px",
                                color: "var(--color-constant-blackberry-light)",
                              }}
                            />
                          }
                        />
                      }
                      label="Подписок нет"
                    >
                      <div className={classes.buttonWrapper}>
                        <MTSButton
                          variant="secondary"
                          onClick={handleAddSubscriptions}
                          data-testid="btn_add_sub"
                        >
                          Добавить
                        </MTSButton>
                      </div>
                    </Plug>
                  </div>
                ) : (
                  <SubscriptionTables
                    subscriptions={subscriptions}
                    tableFilters={tableFilters}
                  />
                )}
              </>
            ) : (
              <div className={classes.blockLoader}>
                <Loader />
              </div>
            )}
          </>
        )}
      </ContainerContent>
      <FooterOfPersonalArea />
    </>
  );
};
