import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const Warning = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.91155 4.15855C6.86958 3.53156 7.36897 3 7.99999 3C8.63511 3 9.13594 3.53818 9.08769 4.16882L8.68868 9.38398C8.66209 9.73152 8.37115 10 8.02115 10H7.92894C7.57649 10 7.2844 9.72788 7.26096 9.37768L6.91155 4.15855Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.97208 13C8.52668 13 8.97628 12.5523 8.97628 12C8.97628 11.4477 8.52668 11 7.97208 11C7.41748 11 6.96789 11.4477 6.96789 12C6.96789 12.5523 7.41748 13 7.97208 13Z"
        fill="white"
      />
    </SvgIcon>
  );
};

export default Warning;
