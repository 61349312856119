import { Controller, Control } from "react-hook-form";

import { styled } from "@mui/material/styles";
import Switch, { SwitchProps } from "@mui/material/Switch";
import classNames from "classnames";

import LockSvg from "./img/Lock.svg";
import { useStyles } from "./styles";

enum Size {
  S = "S", // 16
  M = "M", // 24
  L = "L", // 32
}

type ISize = keyof typeof Size;

interface S extends SwitchProps {
  _size: ISize;
}

const CustomSwitch = styled((props: S) => {
  const { _size, ...rest } = props;

  return (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...rest} />
  );
})(({ theme, _size, disabled }) => {
  const isShowLock: boolean =
    _size !== Size.S && typeof disabled === "boolean" && disabled;

  return {
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 0,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        color: "var(--color-background-primary)",
        "& + .MuiSwitch-track": {
          backgroundColor: "var(--color-accent-positive)",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 1,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "var(--color-accent-positive)",
        border: "6px solid",
        borderColor: "var(--color-control-alternative)",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color: "var(--color-background-primary)",
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 1,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      position: "relative",

      "&:before": {
        content: "''",
        position: "absolute",
        width: "100%",
        height: "100%",
        left: 0,
        top: 0,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundImage: isShowLock ? `url(${LockSvg})` : null,
      },
    },
    "& .MuiSwitch-track": {
      backgroundColor: "var(--color-control-inactive)",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  };
});

interface IMTSSwitcherProps extends Omit<SwitchProps, "size"> {
  size?: ISize;
  control: Control<any>;
  controlFieldName: string;
}

export const MTSSwitch = (props: IMTSSwitcherProps) => {
  const { size = Size.M, control, controlFieldName, ...rest } = props;
  const classes = useStyles();

  const switchStyles = classNames({
    [classes.switch]: true,
    [classes.size_S_16]: size === Size.S,
    [classes.size_M_24]: size === Size.M,
    [classes.size_L_32]: size === Size.L,
  });

  return (
    <Controller
      name={controlFieldName}
      control={control}
      defaultValue={false}
      render={({ field: { value, onChange } }) => {
        return (
          <CustomSwitch
            _size={size}
            checked={value}
            className={switchStyles}
            onChange={(e) => onChange(e.target.checked)}
            {...rest}
          />
        );
      }}
    />
  );
};
