import { REQUIRED_FIELD_MESSAGE } from "constants/keys";

import * as yup from "yup";

export const fieldRegionRequired = yup
  .object()
  .shape({
    id: yup.number(),
    name: yup.string(),
    code: yup.string(),
    description: yup.string(),
  })
  .required(REQUIRED_FIELD_MESSAGE)
  .nullable();

export const fieldRegion = yup
  .object()
  .shape({
    id: yup.number(),
    name: yup.string(),
    code: yup.string(),
    description: yup.string(),
  })
  .nullable();
