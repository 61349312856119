import { PATH_PAYMENTS, SEARCH_PARAM_PAYMENT_IN_SUBS } from "constants/routes";

import React from "react";

import { useHistory, useLocation } from "react-router";

import { AppHelmet } from "components/app-helmet";
import { Breadcrumbs } from "components/breadcrumbs";
import { ContainerContent } from "components/container-content";
import { FooterOfPersonalArea } from "components/footer-of-personal-area";
import { IconWrapper } from "components/icon-wrapper";
import { Loader } from "components/loader";
import { MTSIcon } from "components/mts-icon";
import { NotificationMessage } from "components/notification-message";
import { PageTitle } from "components/page-title";
import { Plug } from "components/plug";
import { SubscriptionTables } from "components/subscription-tables";
import { TableSubscriptionsFilters } from "components/table-subscriptions-filters";
import { TableSubscriptionsTabs } from "components/table-subscriptions-tabs";
import { TemplateServiceNotAvailable } from "components/template-service-not-available";
import { useTableSubscriptionsFilters } from "hooks/table/use-table-subscriptions-filters";
import { useTabNames } from "hooks/tabs/use-tab-names";
import { useTabsInSubscriptionsPage } from "hooks/tabs/use-tabs-in-subscriptions-page";
import { usePaymentData } from "hooks/use-payment-data";
import { usePaymentSubscriptionsByStatuses } from "hooks/use-payment-subscriptions-by-statuses";
import { ISubscriptionsByStatuses } from "interfaces";

import { PaymentInfoChip } from "./components/payment-info-chip";
import { useNotificationMessageActivateSubscriptions } from "./hooks/use-message-activate-subscriptions";
import classes from "./subscriptions-info-by-payment.module.css";

const PAGE_TITLE = "Подписки";

interface ISubscriptionsInfoByPaymentProps {
  paymentId: string;
}

export const SubscriptionsInfoByPayment = (
  props: ISubscriptionsInfoByPaymentProps
): JSX.Element => {
  const { paymentId } = props;
  const history = useHistory();
  const location = useLocation();

  const { tabNames } = useTabNames();

  const breadcrumbLinks = React.useMemo(
    () => [
      {
        name: tabNames.payments,
        href: PATH_PAYMENTS,
      },
      {
        name: PAGE_TITLE,
        href: "",
      },
    ],
    [tabNames.payments]
  );

  const { data: paymentData } = usePaymentData(paymentId);
  const paymentType: string = paymentData?.type ?? "";

  const {
    isOpen: isOpenMessageActivateSubscriptions,
    handleCloseMessage: handleCloseMessageActivateSubscriptions,
  } = useNotificationMessageActivateSubscriptions({
    paymentType,
  });

  const { subscriptionsByStatuses, isDataReady, isError } =
    usePaymentSubscriptionsByStatuses(paymentId);
  const { tableFilters, isSearchFilled, clearSearchInput, register, control } =
    useTableSubscriptionsFilters();

  const subscriptions: ISubscriptionsByStatuses = React.useMemo(
    () => subscriptionsByStatuses,
    [subscriptionsByStatuses]
  );
  const isEmptyData = subscriptions?.all?.length ? false : true;

  const { tabs, selectedTabId } = useTabsInSubscriptionsPage(
    subscriptions,
    tableFilters
  );

  const handleChangeTab = (
    event: React.SyntheticEvent,
    newTabNumber: number
  ): void => {
    const tabHref: string = tabs[newTabNumber].href;
    const locationSearchPararms = new URLSearchParams(location.search);

    locationSearchPararms.set(SEARCH_PARAM_PAYMENT_IN_SUBS, paymentId);

    history.push({
      pathname: tabHref,
      search: locationSearchPararms.toString(),
    });
  };

  const handleClearPaymentIdFromSearchParams = (): void => {
    const locationSearchPararms = new URLSearchParams(location.search);

    locationSearchPararms.delete(SEARCH_PARAM_PAYMENT_IN_SUBS);

    history.push({
      pathname: location.pathname,
      search: locationSearchPararms.toString(),
    });
  };

  return (
    <>
      <AppHelmet title={`${PAGE_TITLE} - Личный кабинет`} />

      <ContainerContent>
        <div className={classes.blockIntro}>
          <Breadcrumbs links={breadcrumbLinks} />

          <div className={classes.blockHeader}>
            <PageTitle text={PAGE_TITLE} />

            <PaymentInfoChip
              onClose={handleClearPaymentIdFromSearchParams}
              modifiedDate={paymentData?.modified ?? ""}
            />
          </div>
        </div>

        {isError ? (
          <TemplateServiceNotAvailable />
        ) : (
          <>
            {isDataReady ? (
              <>
                {!isEmptyData && (
                  <>
                    <div className={classes.blockAction}>
                      <TableSubscriptionsFilters
                        register={register}
                        control={control}
                        isSearchFilled={isSearchFilled}
                        clearSearchInput={clearSearchInput}
                      />
                    </div>

                    <div className={classes.blockTabs}>
                      <TableSubscriptionsTabs
                        selectedTab={tabs.findIndex(
                          (tab) => tab.id === selectedTabId
                        )}
                        tabs={tabs}
                        onChange={handleChangeTab}
                      />
                    </div>

                    {isOpenMessageActivateSubscriptions && (
                      <div className={classes.blockMessage}>
                        <NotificationMessage
                          show={isOpenMessageActivateSubscriptions}
                          text="Активируйте подписки, чтобы начать ими пользоваться"
                          handleClose={handleCloseMessageActivateSubscriptions}
                        />
                      </div>
                    )}
                  </>
                )}
                {isEmptyData ? (
                  <Plug
                    icon={
                      <IconWrapper
                        size={64}
                        icon={
                          <MTSIcon.Mail
                            sx={{
                              fontSize: "24px",
                              color: "var(--color-constant-blackberry-light)",
                            }}
                          />
                        }
                      />
                    }
                    label="Подписки были удалены"
                  />
                ) : (
                  <SubscriptionTables
                    subscriptions={subscriptions}
                    tableFilters={tableFilters}
                  />
                )}
              </>
            ) : (
              <div className={classes.blockLoader}>
                <Loader />
              </div>
            )}
          </>
        )}
      </ContainerContent>
      <FooterOfPersonalArea />
    </>
  );
};
