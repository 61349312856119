import { QueryKeys } from "constants/keys";

import { useInfiniteQuery } from "react-query";

import { getPayments } from "services/api";

export const usePayments = () => {
  return useInfiniteQuery(
    [QueryKeys.PAYMENTS],
    ({ pageParam = 0 }) =>
      getPayments({ page: pageParam, size: 10, sort: ["modified,desc"] }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage, pages) => {
        const isLastPage: boolean = lastPage.pageIsLast;
        const lastCurrentPage: number = lastPage.page;

        return isLastPage ? undefined : lastCurrentPage + 1;
      },
    }
  );
};
