import Tooltip from "@mui/material/Tooltip";
import { MTSIcon } from "components/mts-icon";

import classes from "./info-icon.module.css";

interface IInfoIconProps {
  text: string;
}

export const InfoIcon = (props: IInfoIconProps): JSX.Element => {
  const { text } = props;

  return (
    <Tooltip title={text} arrow disableInteractive>
      <div className={classes.blockInfoIcon}>
        <div className={classes.icon}>
          <MTSIcon.InfoCircleFill
            sx={{
              fontSize: 16,
              color: "var(--color-icon-secondary)",
            }}
          />
        </div>
      </div>
    </Tooltip>
  );
};
