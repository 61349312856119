import React from "react";

type IUseHeaderHeightProps = {
  isAuthorized: boolean;
  isMobileVersion: boolean;
};

export const useHeaderHeight = (props: IUseHeaderHeightProps): void => {
  const { isAuthorized, isMobileVersion } = props;

  React.useEffect(() => {
    if (isAuthorized && !isMobileVersion) {
      document.documentElement.style.setProperty(
        "--header-desktop-height",
        "calc(var(--header-top-section-height) + var(--header-bottom-section-height))"
      );
    } else {
      document.documentElement.style.setProperty(
        "--header-desktop-height",
        "var(--header-top-section-height)"
      );
    }
  }, [isAuthorized, isMobileVersion]);
};
