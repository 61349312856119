import { PROFILE_ROLE } from "constants/keys";

export const getUserLabel = (userRole: string): string => {
  let userRoleLabel: string = "";

  switch (userRole) {
    case PROFILE_ROLE.CUSTOMER_ADMIN:
      userRoleLabel = "Администратор";
      break;
    case PROFILE_ROLE.CUSTOMER_BASE:
      userRoleLabel = "Пользователь";
      break;
    case PROFILE_ROLE.POTENTIAL:
      userRoleLabel = "Регистрация не завершена";
      break;
    case PROFILE_ROLE.DEALER:
      userRoleLabel = "Партнёр";
      break;
    case PROFILE_ROLE.MTS_ADMIN:
      userRoleLabel = "МТС Админ";
      break;
    case PROFILE_ROLE.MTS_TB:
      userRoleLabel = "МТС ТБ";
      break;
    default:
      break;
  }

  return userRoleLabel;
};
