import { PATH_SUBSCRIPTION_INFO } from "constants/routes";

import { useHistory, useLocation } from "react-router-dom";

import { ISubscriptionInfoLocationState } from "components/pages/subsription-info-page";

import { TooltipCellButton } from "../tooltip-cell-button";

interface ITableCellSubscriptionNameProps {
  subscriptionId: number;
  columnWidth: number;
  value: string;
  subscriptionCountCanRenew: number;
  dataTestId?: string | null;
}

export const TableCellSubscriptionName = (
  props: ITableCellSubscriptionNameProps
): JSX.Element => {
  const {
    subscriptionId,
    columnWidth,
    value,
    subscriptionCountCanRenew,
    dataTestId,
  } = props;
  const history = useHistory();
  const location = useLocation();

  const handleRedirect = (): void => {
    const locationState: ISubscriptionInfoLocationState = {
      from: {
        pathname: location.pathname,
        search: location.search,
        subscriptionCountCanRenew,
      },
    };

    history.push(`${PATH_SUBSCRIPTION_INFO}/${subscriptionId}`, locationState);
  };

  return (
    <TooltipCellButton
      text={value}
      cellWidth={columnWidth}
      onClick={handleRedirect}
      dataTestId={dataTestId}
    />
  );
};
