import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export const ElectroIcon = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon
      width="96"
      height="96"
      viewBox="0 0 96 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="48" cy="48" r="31" stroke="black" strokeWidth="2" />
      <path
        d="M35 49.3699L55.15 28L51.9 45.5342H61L40.2 68L44.1 49.3699H35Z"
        stroke="black"
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
