import { FIELD_NAMES } from "constants/keys";

import React from "react";

import { useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import Tooltip from "@mui/material/Tooltip";
import { MTSButton } from "components/mts-button";
import { MTSButtonIcon } from "components/mts-button-icon";
import { MTSIcon } from "components/mts-icon";
import { MTSInput } from "components/mts-input";
import { useMutationUpdateDevicePassword } from "hooks/use-mutation-update-device-password";
import { fieldSchema } from "utils/yup-schema";
import * as yup from "yup";

import classes from "./device-password-info.module.css";

const schema = yup.object({
  [FIELD_NAMES.SUBSCRIPTION_DEVICE_PASSWORD]:
    fieldSchema.required.devicePassword,
});

interface IFormStruct {
  [FIELD_NAMES.SUBSCRIPTION_DEVICE_PASSWORD]: string;
}

interface IDevicePasswordInfoProps {
  deviceLogin: string;
  devicePassword: string;
  isLoadingDevicePassword: boolean;
}

export const DevicePasswordInfo = (
  props: IDevicePasswordInfoProps
): JSX.Element => {
  const { deviceLogin, devicePassword, isLoadingDevicePassword } = props;

  const mutationUpdateDevicePassword = useMutationUpdateDevicePassword();

  const [isShowPassword, setIsShowPassword] = React.useState<boolean>(false);
  const [isEditPassword, setIsEditPassword] = React.useState<boolean>(false);
  const [devicePasswordBeforeEdit, setDevicePasswordBeforeEdit] =
    React.useState<string>("");
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors, dirtyFields },
  } = useForm<IFormStruct>({
    mode: "onChange",
    defaultValues: {
      [FIELD_NAMES.SUBSCRIPTION_DEVICE_PASSWORD]: devicePassword,
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: IFormStruct): void => {
    const { devicePassword: newDevicePassword } = data;

    if (devicePasswordBeforeEdit === newDevicePassword) {
      setIsShowPassword(false);
      setIsEditPassword(false);
    } else {
      mutationUpdateDevicePassword.mutate({
        deviceLogin,
        newDevicePassword,
      });
    }
  };

  const onStartEditPassword = (): void => {
    setIsShowPassword(true);
    setIsEditPassword((prev) => !prev);
    setDevicePasswordBeforeEdit(devicePassword);
  };

  const onToggleVisibilityPassword = (): void => {
    setIsShowPassword((prev) => !prev);
  };

  React.useEffect(() => {
    if (!isLoadingDevicePassword) {
      setValue(FIELD_NAMES.SUBSCRIPTION_DEVICE_PASSWORD, devicePassword);
    }
  }, [devicePassword, isLoadingDevicePassword, setValue]);

  React.useEffect(() => {
    if (mutationUpdateDevicePassword.isSuccess) {
      setIsEditPassword(false);
      setIsShowPassword(true);
    }
  }, [mutationUpdateDevicePassword.isSuccess]);

  return (
    <div className={classes.block}>
      <div className={classes.label}>
        <p className={classes.labelText}>Пароль:</p>
      </div>

      {isEditPassword ? (
        <div className={classes.fieldEdit}>
          <MTSInput
            required
            size="S"
            style={{ width: "210px" }}
            type={isShowPassword ? "text" : "password"}
            placeholder="Введите"
            hideLabel={true}
            {...register(FIELD_NAMES.SUBSCRIPTION_DEVICE_PASSWORD)}
            errorMessage={
              errors?.[FIELD_NAMES.SUBSCRIPTION_DEVICE_PASSWORD]?.message
            }
            isDirty={
              dirtyFields?.[FIELD_NAMES.SUBSCRIPTION_DEVICE_PASSWORD] || false
            }
          />
          <div className={classes.fieldEditButton}>
            <MTSButton
              size="S"
              variant="secondary"
              onClick={handleSubmit(onSubmit)}
              loading={mutationUpdateDevicePassword.isLoading}
            >
              Сохранить
            </MTSButton>
          </div>
        </div>
      ) : (
        <div className={classes.fieldInfo}>
          <p className={classes.fieldInfoText}>
            {" "}
            {isShowPassword
              ? devicePassword
              : devicePassword
                  .split("")
                  .map(() => "●")
                  .join("")}
          </p>
          <Tooltip
            arrow
            title={isShowPassword ? "Скрыть пароль" : "Показать пароль"}
            disableInteractive
          >
            <div>
              <MTSButtonIcon
                size="XS"
                variant="ghost"
                Icon={isShowPassword ? MTSIcon.Hide : MTSIcon.Show}
                iconSize={16}
                onClick={onToggleVisibilityPassword}
              />
            </div>
          </Tooltip>
          <Tooltip arrow title="Изменить пароль" disableInteractive>
            <div>
              <MTSButtonIcon
                size="XS"
                variant="ghost"
                Icon={MTSIcon.Edit}
                iconSize={16}
                onClick={onStartEditPassword}
              />
            </div>
          </Tooltip>
        </div>
      )}
    </div>
  );
};
