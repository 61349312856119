import React from "react";

import { useHistory } from "react-router-dom";

import classNames from "classnames";
import { MTSButton } from "components/mts-button";
import { IMTSTab } from "components/mts-tabs";
import { useTabsInHeader } from "hooks/tabs/use-tabs-in-header";

import { DialogLinks } from "./components/dialog-links/dialog-links";
import { DialogMenu } from "./components/dialog-menu";
import { MobileMenuIcon } from "./icons/mobile-menu-icon";
import classes from "./mobile-bar.module.css";

export const MobileBar = (): JSX.Element => {
  const history = useHistory();

  const { tabsNotAuthorized, tabs, selectedTabId } = useTabsInHeader();

  const blockRef = React.useRef<HTMLDivElement>(null);
  const mouseCoords = React.useRef({
    startX: 0,
    startY: 0,
    scrollLeft: 0,
    scrollTop: 0,
  });
  const [isBlockDragging, setIsBlockDragging] = React.useState(false);

  const [isOpenMenu, setIsOpenMenu] = React.useState<boolean>(false);
  const [isMountedMenu, setIsMountedMenu] = React.useState<boolean>(false);

  const [isOpenDialog, setIsOpenDialog] = React.useState<boolean>(false);
  const [isMountedDialog, setIsMountedDialog] = React.useState<boolean>(false);

  const [dialogTabData, setDialogTabData] = React.useState<IMTSTab | null>(
    null
  );

  const handleDragStart = (e: any): void => {
    setIsBlockDragging(true);
    document.body.style.cursor = "grabbing";

    if (blockRef.current) {
      const slider: any = blockRef.current.children[0];
      const startX = e.pageX - slider.offsetLeft;
      const startY = e.pageY - slider.offsetTop;
      const scrollLeft = slider.scrollLeft;
      const scrollTop = slider.scrollTop;
      mouseCoords.current = { startX, startY, scrollLeft, scrollTop };
    }
  };

  const handleDragEnd = () => {
    setIsBlockDragging(false);
    document.body.style.cursor = "default";
  };

  const handleDrag = (e: any) => {
    e.preventDefault();

    if (isBlockDragging && blockRef.current) {
      const blockRefObject = blockRef.current;
      const slider: any = blockRefObject.children[0];
      const x = e.pageX - slider.offsetLeft;
      const y = e.pageY - slider.offsetTop;
      const walkX = (x - mouseCoords.current.startX) * 1.5;
      const walkY = (y - mouseCoords.current.startY) * 1.5;

      const coordX = mouseCoords.current.scrollLeft - walkX;
      const coordY = mouseCoords.current.scrollTop - walkY;

      blockRefObject.scrollLeft = coordX;
      blockRefObject.scrollTop = coordY;
    }
  };

  const blockMobileBarStyles = classNames({
    [classes.blockMobileBar]: true,
    [classes.blockMobileBarShow]: isOpenMenu,
  });

  const handleToggleMenu = (): void => {
    if (isOpenMenu) {
      setIsOpenMenu(false);
      setTimeout(() => setIsMountedMenu(false), 500);
    } else {
      if (isOpenDialog) {
        handleToggleDialog();
      }

      setIsMountedMenu(true);
      setIsOpenMenu(true);
    }
  };

  const handleToggleDialog = (data?: IMTSTab) => {
    if (isOpenDialog) {
      setIsOpenDialog(false);
      setTimeout(() => {
        setDialogTabData(null);
        setIsMountedDialog(false);
      }, 500);
    } else {
      if (isOpenMenu) {
        handleToggleMenu();
      }

      setDialogTabData(data ? data : null);

      setIsMountedDialog(true);
      setIsOpenDialog(true);
    }
  };

  const handleClickTab = (tabData: IMTSTab): void => {
    const tabType = tabData.type;

    if (tabType === "link" || tabType === "link-child") {
      const tabHref = tabData.href || "/";

      if (tabData?.isRedirectTab) {
        window.open(tabHref, "_blank");
      } else {
        history.push(tabHref);
      }
    } else {
      handleToggleDialog(tabData);
    }
  };

  React.useEffect(() => {
    if (blockRef.current) {
      const blockRefObject = blockRef.current;

      blockRefObject.addEventListener("mouseleave", handleDragEnd);
    }
  }, []);

  return (
    <>
      <div className={blockMobileBarStyles}>
        <MobileMenuIcon sx={{ fontSize: 24 }} onClick={handleToggleMenu} />

        <div
          className={classes.links}
          ref={blockRef}
          onMouseDown={handleDragStart}
          onMouseUp={handleDragEnd}
          onMouseMove={handleDrag}
        >
          {tabsNotAuthorized.map((tabData: IMTSTab, index: number) => {
            const tabName = tabData.name;
            const isSelectedButton: boolean =
              tabData.id === selectedTabId ||
              tabData.href === dialogTabData?.href;

            return (
              <MTSButton
                key={tabData.id}
                variant={isSelectedButton ? "primary_alternate" : "secondary"}
                size="S"
                onClick={() => handleClickTab(tabData)}
                noWrap
              >
                {tabName}
              </MTSButton>
            );
          })}
        </div>
      </div>

      <DialogMenu
        onToggle={handleToggleMenu}
        isOpen={isOpenMenu}
        isMounted={isMountedMenu}
        tabsAuthorized={tabs}
        tabsNotAuthorized={tabsNotAuthorized}
      />
      <DialogLinks
        onToggle={handleToggleDialog}
        isOpen={isOpenDialog}
        isMounted={isMountedDialog}
        tabData={dialogTabData}
      />
    </>
  );
};
