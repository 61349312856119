import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const Hide = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.64951 3.53131C3.35662 3.23841 3.35662 2.76354 3.64951 2.47065C3.9424 2.17775 4.41728 2.17775 4.71017 2.47065L14.0435 11.804C14.3364 12.0969 14.3364 12.5717 14.0435 12.8646C13.7506 13.1575 13.2757 13.1575 12.9828 12.8646L11.6174 11.4992C10.6938 12.1607 10.0369 12.5146 8.71013 12.6722C8.40205 12.7088 7.60292 12.7088 7.29484 12.6722C5.71563 12.4846 5.08535 12.0189 3.82481 11.0874C3.16137 10.5971 2.5572 10.063 2.05464 9.57909C1.35415 8.90452 1.00391 8.56724 1.00391 8.00018C1.00391 7.43311 1.35415 7.09583 2.05464 6.42126C2.5572 5.9373 3.16137 5.40325 3.82481 4.91299C4.07888 4.72524 4.30736 4.5564 4.52315 4.40495L3.64951 3.53131ZM5.60211 5.48391C5.364 5.64242 5.08687 5.84548 4.71627 6.11934C4.11854 6.56105 3.5645 7.04972 3.09512 7.50173C2.91471 7.67546 2.78144 7.80404 2.67139 7.91744C2.64138 7.94836 2.61543 7.97581 2.59296 8.00018C2.61543 8.02454 2.64138 8.05199 2.67139 8.08291C2.78144 8.19631 2.91471 8.32489 3.09512 8.49862C3.5645 8.95063 4.11854 9.4393 4.71627 9.88101C5.37437 10.3673 5.73771 10.6304 6.11126 10.8172C6.43974 10.9814 6.81847 11.1051 7.47175 11.1827C7.66231 11.2053 8.34267 11.2053 8.53322 11.1827C9.18651 11.1051 9.56524 10.9814 9.89372 10.8172C10.0962 10.7159 10.2958 10.5922 10.5409 10.4227L9.29544 9.17723C8.97523 9.52947 8.51335 9.75061 7.99982 9.75061C7.03307 9.75061 6.24936 8.9669 6.24936 8.00015C6.24936 7.48662 6.47049 7.02474 6.82274 6.70453L5.60211 5.48391Z"
      />
      <path d="M8.91187 3.35418C8.50174 3.29613 8.12221 3.58154 8.06417 3.99167C8.00612 4.40179 8.29153 4.78132 8.70166 4.83937C9.25145 4.91719 9.59264 5.03244 9.89369 5.18299C10.267 5.3697 10.6299 5.63236 11.2887 6.11916C11.8864 6.56086 12.4404 7.04953 12.9098 7.50154C13.1639 7.74625 13.3155 7.89529 13.4124 7.99988C13.3388 8.07939 13.2319 8.18673 13.07 8.34372C12.7727 8.63212 12.7655 9.10693 13.0539 9.40426C13.3423 9.70158 13.8171 9.70882 14.1144 9.42043C14.3755 9.16723 14.5866 8.95674 14.7384 8.74913C14.8114 8.64928 14.8854 8.53099 14.9358 8.38997C14.9905 8.2368 15.001 8.10293 15.001 7.99999C15.001 7.89705 14.9905 7.76318 14.9358 7.61001C14.8854 7.46899 14.8114 7.35069 14.7384 7.25084C14.5632 7.01132 14.2999 6.75777 13.9521 6.42275L13.9503 6.42108C13.4478 5.93712 12.8428 5.40249 12.1794 4.91223C11.5501 4.44724 11.0772 4.09773 10.5646 3.84141C10.0988 3.60847 9.59834 3.45134 8.91187 3.35418Z" />
    </SvgIcon>
  );
};

export default Hide;
