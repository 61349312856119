import { PATH_PAYMENTS } from "constants/routes";

import { useMutation } from "react-query";

import { useHistory } from "react-router";

import {
  GAE_ACTION_GROUP,
  GAE_BUTTON_LOCATION,
  GAE_EVENT,
  GAE_EVENT_ACTION,
  GAE_EVENT_CATEGORY,
  GAE_EVENT_LABEL,
  useGoogleAnalyticsEvents,
} from "hooks/google-analytics";
import { useNotifications } from "hooks/use-notifications";
import { IPayGroupDTO, IPayGroupDTOPayload, IPaymentDTO } from "interfaces";
import { createPayment } from "services/api";
import { getDurationTranslit } from "utils/get-duration-translit.ts";
import { getProductNameByPayment } from "utils/google-analytics/get-pruduct-name-by-payment";

export const useMutationCreatePayment = () => {
  const { addErrorMessage } = useNotifications();
  const { sendGAE } = useGoogleAnalyticsEvents();
  const history = useHistory();

  return useMutation(
    (payload: IPayGroupDTOPayload[]) => {
      return createPayment(payload);
    },
    {
      onSuccess: (response: IPaymentDTO) => {
        const { id } = response;

        const paymentAmount: number = response.calculation?.amount ?? 0;
        const filterNameTranslit = response.payGroups
          .map((payGroup: IPayGroupDTO) => {
            const durationTranslit: string = getDurationTranslit(
              payGroup.duration ?? ""
            );
            const subCount: number = payGroup.subscriptionsCount;
            const serviceKindCode: string = payGroup.serviceKind.toLowerCase();

            return `${subCount}_podpisok_${serviceKindCode}_${durationTranslit}`;
          })
          .join("|");
        const serviceKindCodes = new Set(
          response.payGroups?.map(
            (payGroup: IPayGroupDTOPayload) => payGroup.serviceKind
          )
        );

        const productName: string = getProductNameByPayment([
          ...serviceKindCodes,
        ]);

        sendGAE({
          id: 26,
          event: GAE_EVENT.vntPodpiski,
          eventCategory: GAE_EVENT_CATEGORY.podpiski,
          eventAction: GAE_EVENT_ACTION.confirmed,
          eventLabel: GAE_EVENT_LABEL.dobavlenie_podpiski,
          eventValue: String(paymentAmount),
          eventContent: null,
          buttonLocation: GAE_BUTTON_LOCATION.screen,
          filterName: filterNameTranslit,
          actionGroup: GAE_ACTION_GROUP.conversions,
          productName: productName,
          formOrderId: String(id),
        });

        history.push(`${PATH_PAYMENTS}/${id}`);
      },
      onError: (error) => {
        addErrorMessage({ message: "Не удалось создать платёж" });
      },
    }
  );
};
