import React from "react";

import { useInView } from "react-intersection-observer";

import Accordion, { AccordionProps } from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { styled } from "@mui/material/styles";
import { BlockContent } from "components/block-content";
import { BlockWide } from "components/block-wide";
import { MTSIcon } from "components/mts-icon";
import {
  GAE_ACTION_GROUP,
  GAE_EVENT,
  GAE_EVENT_ACTION,
  GAE_EVENT_CATEGORY,
  GAE_EVENT_LABEL,
  useGoogleAnalyticsEvents,
} from "hooks/google-analytics";
import { useAppTheme } from "hooks/use-app-theme";

import classes from "./questions.module.css";

const CustomAccordion = styled((props: AccordionProps) => (
  <Accordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderBottom: "1px solid var(--color-background-stroke)",

  "&:before": {
    display: "none",
  },
}));

export const Questions = (): JSX.Element => {
  const theme = useAppTheme();

  interface IQuestions {
    id: number;
    question: string | JSX.Element;
    facts: string[] | JSX.Element[];
  }

  const questionsData: IQuestions[] = [
    {
      id: 1,
      question: "Какие есть возможности в тестовом режиме?",
      facts: [
        "Доступна одна подписка для одного устройства.",
        "Срок действия подписки — две недели, однократно. Действует только для новых пользователей.",
        "Доступ к RTK и RINEX без ограничений в зонах действия покрытия сети.",
      ],
    },
    {
      id: 2,
      question:
        "Можно ли подключить услугу LocationPro от МТС на устройствах с симкой других операторов?",
      facts: [
        "Да, подключаться можно по любому удобному каналу связи. В то же время при использовании сотовой сети МТС, трафик услуги не будет тарифицироваться.",
      ],
    },
    {
      id: 3,
      question: "Будет ли расширяться референсная сеть в будущем?",
      facts: [
        "Да, мы готовы расширять покрытие под запрос клиентов. Заполните заявку и мы свяжемся с вами для обсуждения вариантов.",
      ],
    },
    {
      id: 4,
      question: (
        <>
          Референсная сеть МТС является геодезической сетью специального
          назначения?
          <span> </span>
          <span className={classes.itemSubtext}>
            Отчёты о её создании содержатся в федеральном фонде пространственных
            данных?
          </span>
        </>
      ),
      facts: [
        "Нет, в настоящее время референсная сеть МТС не является геодезической сетью специального назначения. В то же время запланирована официальная регистрация сети с передачей отчёта о её создании и каталога координат пунктов сети в федеральный фонд пространственных данных, держателем которого является ФГБУ «Центр геодезии, картографии и ИПД».",
      ],
    },
    {
      id: 5,
      question: "Какие есть способы подключения к референсным станциям МТС?",
      facts: [
        <>
          Существуют три способа подключения: подключение к ближайшей
          референсной станции, которая автоматически определяется на основе
          вашего местоположения, выбор конкретной референсной станции вручную, и
          режим виртуальной базовой станции, при котором по координатам
          пользователя формируется поток от&nbsp;виртуальной базовой станции.
          Параметры подключения есть в личном кабинете услуги.
        </>,
      ],
    },
  ];

  const { sendGAE } = useGoogleAnalyticsEvents();
  const { ref: blockQuestionsRef, inView: inViewBlockQuestions } = useInView({
    initialInView: false,
    threshold: 0.5,
    triggerOnce: true,
  });

  const [expandedAccordion, setExpandedAccordion] = React.useState<
    number | false
  >(false);

  const handleChange =
    (panel: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpandedAccordion(isExpanded ? panel : false);
    };

  React.useEffect(() => {
    if (inViewBlockQuestions) {
      sendGAE({
        id: 45,
        event: GAE_EVENT.vntMain,
        eventCategory: GAE_EVENT_CATEGORY.glavnaya,
        eventAction: GAE_EVENT_ACTION.block_show,
        eventLabel: GAE_EVENT_LABEL.voprosy_i_otvety,
        eventValue: null,
        eventContent: null,
        buttonLocation: null,
        filterName: null,
        actionGroup: GAE_ACTION_GROUP.non_interactions,
        productName: null,
      });
    }
  }, [inViewBlockQuestions, sendGAE]);

  return (
    <div ref={blockQuestionsRef} className={classes.blockQuestions}>
      <BlockWide>
        <BlockContent>
          <>
            <h1 className={classes.title}>
              Вопросы&nbsp;
              <span className={classes.titleSubtext}>и ответы</span>
            </h1>
            <div className={classes.list}>
              {questionsData.map((faq) => (
                <CustomAccordion
                  key={faq.id}
                  expanded={expandedAccordion === faq.id}
                  onChange={handleChange(faq.id)}
                >
                  <AccordionSummary
                    expandIcon={
                      <MTSIcon.ChevronDown
                        sx={{
                          fontSize: "32px",
                          color: "var(--color-icon-primary)",
                          margin: "-4px 0",
                          [theme.breakpoints.down("tablet_768")]: {
                            fontSize: "24px",
                          },
                        }}
                      />
                    }
                    sx={{
                      margin: "20px 0px",
                      padding: "0",
                      minHeight: "24px",
                      "& .MuiAccordionSummary-content": {
                        margin: "0",
                      },
                      [theme.breakpoints.down("tablet_768")]: {
                        margin: "10px 0px",
                      },
                    }}
                  >
                    <p className={classes.listQuestion}>{faq.question}</p>
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{ display: "flex", padding: "0px 0px 24px 0px" }}
                  >
                    <ul className={classes.listItem}>
                      {faq.facts.map(
                        (fact: string | JSX.Element, index: number) => (
                          <p className={classes.listItemFact} key={index}>
                            {fact}
                          </p>
                        )
                      )}
                    </ul>
                  </AccordionDetails>
                </CustomAccordion>
              ))}
            </div>
          </>
        </BlockContent>
      </BlockWide>
    </div>
  );
};
