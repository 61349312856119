import React from "react";

import { MTSButtonIcon } from "components/mts-button-icon";
import { MTSIcon } from "components/mts-icon";

interface IButtonMobilePanelProps {
  onClick: () => void;
}

export const ButtonMobilePanel = (
  props: IButtonMobilePanelProps
): JSX.Element => {
  const { onClick } = props;

  return (
    <MTSButtonIcon
      mode="standard"
      Icon={MTSIcon.Customization}
      iconSize={24}
      iconColor={"var(--color-icon-primary)"}
      onClick={() => onClick()}
      variant="secondary_inverted"
      size="M"
    />
  );
};
