import { DISCOUNT_TYPE } from "constants/keys";

export const getDiscountLabel = (
  discountType: string | null | undefined
): string => {
  switch (discountType) {
    case DISCOUNT_TYPE.REGION_DISCOUNT:
      return "на этапе строительства";
    case DISCOUNT_TYPE.SUBSCRIPTION_COUNT_DISCOUNT:
      return "за количество подписок";
    default:
      return "";
  }
};
