import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export const HightCostIcon = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon
      width="96"
      height="96"
      viewBox="0 0 96 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M59.5505 48C59.5505 61.7972 48.2444 73 34.2753 73C20.3062 73 9 61.7972 9 48C9 34.2028 20.3062 23 34.2753 23C48.2444 23 59.5505 34.2028 59.5505 48Z"
        stroke="black"
        strokeWidth="2"
      />
      <path
        d="M48.4238 23C60.5509 24 73.6885 31 73.6885 49C73.6885 58 67.625 71 48.4238 73"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M62.5723 23C74.6993 24 89.7374 33.0748 87.8369 50C86.8264 59 81.7734 71 62.5723 73"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M41.344 42C41.344 40.3333 39.8481 36 34.8166 36C31.5528 36 27.2012 37.2 27.2012 42C27.201 44 27.8539 47.2 34.8166 48C41.7792 48.8 41.344 54 41.344 55C41.344 58 38.0803 60 34.8166 60C31.5528 60 27.2012 58.8 27.2012 54"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M30.4648 32V36"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M38.0801 32V36"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M30.4648 60V64"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M38.0801 60V64"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
};
