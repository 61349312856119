import React from "react";

import { Collapse } from "@mui/material";
import { useContextMap } from "hooks/use-context-map";
import { useProfileData } from "hooks/use-profile-data";

import { MenuOptionType } from "../../constants";
import { useLayerBaseCommonStations } from "../../hooks/use-layer-base-common-stations";
import { useLayerBaseStations } from "../../hooks/use-layer-base-stations";
import { ButtonBecomeClient } from "../button-become-client";
import { MenuOptionCoverage } from "../menu-option-coverage";
import { MenuOptionPlan } from "../menu-option-plan";
import { MenuOptionRadio } from "../menu-option-radio";

import { SearchInput } from "./components/search-input";
import classes from "./panel.module.css";

export const Panel = (): JSX.Element => {
  const {
    setSearchValue,
    searchOptions,
    setSearchOptions,
    menuOption,
    setMenuOption,
    coverageOperator,
    setCoverageOperator,
    isDisableCoverage,
    setIsDisableCoverage,
    isShowPlannedStations,
    setIsShowPlannedStations,
    isDisabledPlannedStations,
    setIsDisabledPlannedStations,
    isDisabledVRS,
    setIsDisabledVRS,
  } = useContextMap();
  const { isAuthorized, isLoadingAuthData } = useProfileData();

  const baseStationsData = useLayerBaseStations();
  const baseCommonStationsData = useLayerBaseCommonStations();

  React.useEffect(() => {
    if (isShowPlannedStations) {
      setIsDisabledVRS(true);
    } else {
      setIsDisabledVRS(false);
    }

    if (menuOption === MenuOptionType.VRS) {
      setIsDisabledPlannedStations(true);
    } else {
      setIsDisabledPlannedStations(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuOption, isShowPlannedStations]);

  React.useEffect(() => {
    if (baseStationsData.isCanUseBaseStations) {
      setSearchOptions(baseStationsData.stationsAsDictionary);
    } else if (baseCommonStationsData.isCanUseBaseCommonStations) {
      setSearchOptions(baseCommonStationsData.stationsAsDictionary);
    }

    return () => {
      setSearchOptions([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    baseCommonStationsData.isCanUseBaseCommonStations,
    baseCommonStationsData.stationsAsDictionary,
    baseStationsData.isCanUseBaseStations,
    baseStationsData.stationsAsDictionary,
  ]);

  return (
    <div className={classes.panel}>
      <div className={classes.panelSearch}>
        <SearchInput options={searchOptions} setSearchValue={setSearchValue} />
      </div>

      {/* TODO: Скрыт переключатель карт */}
      {/* <div className={classes.panelMapSwitcher}>
        <p data-testid="txt_ref_map" className={classes.panelMapSwitcherLabel}>
          Карты
        </p>
        <MapSwitcher />
      </div> */}

      <p data-testid="txt_ref_coverage" className={classes.panelTitle}>
        Покрытия
      </p>

      <div className={classes.panelItems}>
        <MenuOptionRadio
          menuType={MenuOptionType.RTK}
          selectedMenuOption={menuOption}
          setSelectedMenuOption={setMenuOption}
          dataTestIdIcon="icon_RTK"
          dataTestIdLabel="txt_RTK"
          dataTestIdRadio="rbtn_RTK"
        />

        <div className={classes.panelItemsDivider} />

        <MenuOptionRadio
          menuType={MenuOptionType.RINEX}
          selectedMenuOption={menuOption}
          setSelectedMenuOption={setMenuOption}
          dataTestIdIcon="icon_RINEX"
          dataTestIdLabel="txt_RINEX"
          dataTestIdRadio="rbtn_RINEX"
        />

        <div className={classes.panelItemsDivider} />
        <MenuOptionRadio
          menuType={MenuOptionType.VRS}
          selectedMenuOption={menuOption}
          setSelectedMenuOption={setMenuOption}
          disabled={isDisabledVRS}
          dataTestIdIcon="icon_VRS"
          dataTestIdLabel="txt_VRS"
          dataTestIdRadio="rbtn_VRS"
        />
      </div>

      <div className={classes.panelItems} style={{ display: "none" }}>
        <MenuOptionPlan
          menuType={MenuOptionType.PLANNED_STATIONS}
          isShow={isShowPlannedStations}
          setIsShow={setIsShowPlannedStations}
          disabled={isDisabledPlannedStations}
          dataTestIdIcon="icon_ref_plan"
          dataTestIdLabel="txt_ref_plan"
          dataTestIdSwitch="swh_ref_plan"
        />
      </div>
      {!isLoadingAuthData && !isAuthorized && (
        <div className={classes.buttonIntro}>
          <ButtonBecomeClient />
        </div>
      )}
      {!isLoadingAuthData && isAuthorized && (
        <div className={classes.sectionCoverage}>
          <div className={classes.panelItemsDivider} />

          <div className={classes.sectionCoverageInner}>
            <MenuOptionCoverage
              menuType={MenuOptionType.NONE}
              selectedMenuOption={coverageOperator}
              setSelectedMenuOption={setCoverageOperator}
              isDisableCoverage={isDisableCoverage}
              setIsDisableCoverage={setIsDisableCoverage}
              dataTestIdLabel="txt_mobile_coverage"
              dataTestIdSwitch="swh_mobile_coverage"
            />

            <Collapse in={!isDisableCoverage}>
              <div className={classes.sectionCoverageSubmenu}>
                <MenuOptionRadio
                  menuType={MenuOptionType.MTS}
                  selectedMenuOption={coverageOperator}
                  setSelectedMenuOption={setCoverageOperator}
                  disabled={isDisableCoverage}
                  dataTestIdIcon="icon_mts"
                  dataTestIdLabel="txt_mts"
                  dataTestIdRadio="rbtn_mts"
                />

                <div className={classes.panelItemsDivider} />
                <MenuOptionRadio
                  menuType={MenuOptionType.BEELINE}
                  selectedMenuOption={coverageOperator}
                  setSelectedMenuOption={setCoverageOperator}
                  disabled={isDisableCoverage}
                  dataTestIdIcon="icon_beeline"
                  dataTestIdLabel="txt_beeline"
                  dataTestIdRadio="rbtn_beeline"
                />

                <div className={classes.panelItemsDivider} />
                <MenuOptionRadio
                  menuType={MenuOptionType.MEGAFON}
                  selectedMenuOption={coverageOperator}
                  setSelectedMenuOption={setCoverageOperator}
                  disabled={isDisableCoverage}
                  dataTestIdIcon="icon_megafon"
                  dataTestIdLabel="txt_megafon"
                  dataTestIdRadio="rbtn_megafon"
                />

                <div className={classes.panelItemsDivider} />
                <MenuOptionRadio
                  menuType={MenuOptionType.TELE2}
                  selectedMenuOption={coverageOperator}
                  setSelectedMenuOption={setCoverageOperator}
                  disabled={isDisableCoverage}
                  dataTestIdIcon="icon_tele2"
                  dataTestIdLabel="txt_tele2"
                  dataTestIdRadio="rbtn_tele2"
                />
              </div>
            </Collapse>
          </div>
        </div>
      )}
    </div>
  );
};
