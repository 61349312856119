import { RINEX_SERVICE_URL } from "constants/urls";

import axios from "axios";
import {
  IFileRequestPageableDTO,
  IFileRequestPaginationDTO,
  IFileRequestDTO,
  IFileResponseDTO,
  ITimeZoneDTO,
} from "interfaces";
import { $api } from "services/http";

export const getStatusUserAccessToDownloadRinex = async (): Promise<{
  allowed: boolean;
}> => {
  const url = `${RINEX_SERVICE_URL}/user`;

  return await axios
    .get(url, {
      withCredentials: true,
    })
    .then((response) => response.data);
};

export const getRinexFileRequests = async (
  payload: IFileRequestPageableDTO | null
): Promise<IFileRequestPaginationDTO> => {
  const url = `${RINEX_SERVICE_URL}/rinex-requests`;

  return await $api.post(url, payload).then((resp) => resp.data);
};

export const getTimezones = async (): Promise<ITimeZoneDTO[]> => {
  const url = `${RINEX_SERVICE_URL}/timezones`;

  return $api.get(url).then((resp) => resp.data);
};

export const createRinexRequest = async (
  payload: IFileRequestDTO
): Promise<IFileResponseDTO> => {
  const url = `${RINEX_SERVICE_URL}/rinex-request/create`;

  return $api.post(url, payload).then((resp) => resp.data);
};

export const downloadRinexFile = async (requestId: string) => {
  const url = `${RINEX_SERVICE_URL}/rinex-request/download-file/${requestId}`;

  return await $api.get(url, {
    responseType: "blob",
    headers: {
      "Content-Type": "application/octet-stream",
    },
  });
};
