import { BACKEND_ERROR_CODES, HTTPStatusCode } from "constants/keys";

import React from "react";

import { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { useContextMain } from "hooks/use-context-main";
import { AuthService } from "services/auth";
import { $api } from "services/http";

interface AxiosCustomRequestConfig extends AxiosRequestConfig {
  _isRetry?: boolean;
}

interface IAxiosInterceptoprProps {
  children: React.ReactElement;
}

export const AxiosInterceptor = ({ children }: IAxiosInterceptoprProps) => {
  const { profile, setProfile } = useContextMain();

  React.useMemo(() => {
    $api.interceptors.request.use((config: AxiosRequestConfig) => config);

    $api.interceptors.response.use(
      (response: AxiosResponse) => {
        return response;
      },
      async (error: AxiosError) => {
        const { response, config } = error;

        if (response && config) {
          const originalRequest: AxiosCustomRequestConfig = config;
          const statusCode = response?.status;

          if (statusCode === HTTPStatusCode.UNAUTHORIZED_401) {
            if (!originalRequest._isRetry) {
              originalRequest._isRetry = true;

              try {
                const responseValidate = await AuthService.refresh();

                if (responseValidate.status === HTTPStatusCode.OK_200) {
                  try {
                    const responseProfile = await AuthService.getProfile();
                    const dataProfile = responseProfile.data;

                    if (dataProfile.role !== profile?.role) {
                      setProfile(dataProfile);
                    }
                  } catch (_error) {
                    AuthService.login();
                  }

                  return $api.request(originalRequest);
                }
              } catch (__error) {
                const axiosError = __error as AxiosError;
                const status = axiosError?.response?.status;

                if (status === HTTPStatusCode.UNAUTHORIZED_401) {
                  AuthService.login();

                  return Promise.reject(error);
                }

                if (status === HTTPStatusCode.FORBIDDEN_403) {
                  try {
                    const responseProfile = await AuthService.getProfile();
                    const dataProfile = responseProfile.data;

                    if (
                      responseProfile.status === HTTPStatusCode.OK_200 &&
                      dataProfile.role !== profile?.role
                    ) {
                      setProfile(dataProfile);

                      try {
                        const _responseValidate = await AuthService.refresh();

                        if (
                          _responseValidate.status === HTTPStatusCode.OK_200
                        ) {
                          return $api.request(originalRequest);
                        }
                      } catch (_error) {
                        console.error(
                          "Ошибка доступа после смены роли пользователя"
                        );
                      }
                    }
                  } catch (_error) {
                    AuthService.login();
                  }
                }
              }

              AuthService.login();

              return Promise.reject(error);
            }

            AuthService.login();

            return Promise.reject(error);
          }

          if (statusCode === HTTPStatusCode.FORBIDDEN_403) {
            const data = error?.response?.data;
            const backendErrorCode = data?.code;

            if (backendErrorCode === BACKEND_ERROR_CODES.INSUFFICIENT_ROLE) {
              AuthService.login();

              return Promise.reject(error);
            } else {
              return Promise.reject(error);
            }
          }

          return Promise.reject(error);
        }

        return Promise.reject(error);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return children;
};
