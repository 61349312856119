import Collapse from "@mui/material/Collapse";
import Fade from "@mui/material/Fade";
import { CrossedOutText } from "components/crossed-out-text";
import { ICalculationDTO } from "interfaces";
import { getFormattedNumberWithThousandSeparator } from "utils/get-formatted-number-with-thousand-separator";

import { PaymentConfiguratorDiscounts } from "../payment-configurator-discounts";

import classes from "./payment-configurator-calculations.module.css";

interface IPaymentConfiguratorCalculationsProps {
  calculations: ICalculationDTO | null;
}

export const PaymentConfiguratorCalculations = (
  props: IPaymentConfiguratorCalculationsProps
) => {
  const { calculations } = props;
  const amountBeforeDiscount: number = calculations?.amountBeforeDiscount ?? 0;
  const amountWithDiscount: number = calculations?.amount ?? 0;
  const isShowCalculations: boolean = Boolean(amountBeforeDiscount > 0);
  const isShowPriceWithoutDiscount =
    isShowCalculations && amountBeforeDiscount > amountWithDiscount;

  return (
    <>
      <div className={classes.prices}>
        <Collapse in={isShowPriceWithoutDiscount}>
          <Fade
            in={isShowPriceWithoutDiscount}
            style={{ transformOrigin: "0 0 0" }}
            {...(isShowPriceWithoutDiscount ? { timeout: 1500 } : {})}
          >
            <div className={classes.priceBlock}>
              <p
                className={classes.priceBlockTitle}
                data-testid="txt_hint_amount_paid_without_discount"
              >
                Сумма без скидки
              </p>

              <div className={classes.priceBlockValue}>
                <CrossedOutText>
                  <span
                    className={classes.priceBlockValueText}
                    data-testid="txt_amount_paid_without_discount"
                  >
                    {getFormattedNumberWithThousandSeparator({
                      value: amountBeforeDiscount,
                      showFractionDigits: true,
                    })}
                  </span>
                </CrossedOutText>
                &nbsp;
                <span className={classes.priceBlockValueText}>₽</span>
              </div>
            </div>
          </Fade>
        </Collapse>

        <div className={classes.priceDiscountBlock}>
          <div className={classes.priceDiscountBlockHeader}>
            <p className={classes.priceDiscountBlockHeaderTitle}>
              Сумма к оплате
            </p>
          </div>

          <p className={classes.priceDiscountBlockText}>
            {getFormattedNumberWithThousandSeparator({
              value: amountWithDiscount,
              showFractionDigits: true,
            })}{" "}
            ₽
          </p>
        </div>
      </div>
      <div className={classes.discounts}>
        <Fade
          in={isShowCalculations}
          style={{ transformOrigin: "0 0 0" }}
          {...(isShowCalculations ? { timeout: 1500 } : {})}
        >
          <div>
            <PaymentConfiguratorDiscounts
              discounts={calculations?.discounts ?? []}
              regionDiscount={calculations?.regionDiscount ?? 0}
              subscriptionsDiscount={calculations?.subscriptionDiscount ?? 0}
            />
          </div>
        </Fade>
      </div>
    </>
  );
};
