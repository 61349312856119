import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export const CellTowerIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="32" height="32" rx="8" />
      <path
        d="M16.0001 10.0919C17.5571 10.0919 18.8197 11.3452 18.8197 12.8907C18.8197 14.1152 18.0169 15.1965 16.8447 15.5618V23.1724C16.8447 24.2759 15.1553 24.2759 15.1553 23.1724V15.5618C13.9827 15.1967 13.1803 14.115 13.1803 12.8907C13.1803 11.3452 14.4431 10.0919 16.0001 10.0919ZM19.119 10.5731C18.2386 9.78234 19.4512 8.45141 20.3321 9.24238C22.5969 11.28 22.5147 14.5742 20.3321 16.539C19.4512 17.3298 18.2386 15.9989 19.119 15.2083C20.5215 13.9452 20.5587 11.8696 19.119 10.5731ZM12.8808 10.5731C13.7614 9.78234 12.5488 8.45141 11.6681 9.24238C9.40314 11.28 9.48514 14.5742 11.6681 16.539C12.5488 17.3298 13.7614 15.9989 12.8808 15.2083C11.4785 13.9452 11.4411 11.8696 12.8808 10.5731ZM16.0001 11.7693C15.3759 11.7693 14.8704 12.2711 14.8704 12.8907C14.8704 13.5105 15.3759 14.0123 16.0001 14.0123C16.6241 14.0123 17.1299 13.5105 17.1299 12.8907C17.1299 12.2711 16.6241 11.7693 16.0001 11.7693Z"
        fill="white"
      />
    </SvgIcon>
  );
};
