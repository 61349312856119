import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const VRSIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="96"
      height="96"
      viewBox="0 0 96 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M48 86V69"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M21 80L31 67"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M75 80L65 67"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <circle cx="48" cy="38" r="5" stroke="black" strokeWidth="2" />
      <path
        d="M21 38C21 32.2583 21.309 27.7127 22.1087 24.1144C22.9058 20.5274 24.1773 17.9434 26.0604 16.0604C27.9434 14.1773 30.5274 12.9058 34.1144 12.1087C37.7127 11.309 42.2583 11 48 11C53.7417 11 58.2873 11.309 61.8856 12.1087C65.4726 12.9058 68.0566 14.1773 69.9396 16.0604C71.8227 17.9434 73.0942 20.5274 73.8913 24.1144C74.691 27.7127 75 32.2583 75 38C75 43.7417 74.691 48.2873 73.8913 51.8856C73.0942 55.4726 71.8227 58.0566 69.9396 59.9396C68.0566 61.8227 65.4726 63.0942 61.8856 63.8913C58.2873 64.691 53.7417 65 48 65C42.2583 65 37.7127 64.691 34.1144 63.8913C30.5274 63.0942 27.9434 61.8227 26.0604 59.9396C24.1773 58.0566 22.9058 55.4726 22.1087 51.8856C21.309 48.2873 21 43.7417 21 38Z"
        stroke="black"
        strokeWidth="2"
      />
    </SvgIcon>
  );
};

export default VRSIcon;
