import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const Download = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.99986 1C7.58565 1 7.24986 1.33579 7.24986 1.75V8.64444C7.16998 8.5439 7.08259 8.43322 6.98623 8.31101C6.68614 7.93044 6.43763 7.61461 6.23249 7.34579C6.07027 7.13322 5.81948 7.00502 5.55208 7.00502C4.93133 7.00502 4.57101 7.63379 4.94442 8.12967C5.18665 8.45134 5.475 8.81703 5.80828 9.23969C6.61726 10.2656 7.02182 10.7787 7.52648 10.9298C7.83825 11.0231 8.17052 11.0231 8.4823 10.9298C8.98696 10.7787 9.39145 10.2657 10.2004 9.23979C10.5337 8.81708 10.8221 8.45137 11.0644 8.12967C11.4378 7.63378 11.0774 7.00502 10.4567 7.00502C10.1893 7.00502 9.93851 7.13322 9.77629 7.34579C9.57115 7.61461 9.32264 7.93044 9.02255 8.31101C8.92253 8.43785 8.83219 8.55227 8.74986 8.65583V1.75C8.74986 1.33579 8.41408 1 7.99986 1Z"
        fill="#1D2023"
      />
      <path
        d="M2.75 12.5001C2.33579 12.5001 2 12.8359 2 13.2501C2 13.6644 2.33579 14.0001 2.75 14.0001H13.25C13.6642 14.0001 14 13.6644 14 13.2501C14 12.8359 13.6642 12.5001 13.25 12.5001H2.75Z"
        fill="#1D2023"
      />
    </SvgIcon>
  );
};

export default Download;
