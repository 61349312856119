import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const Check = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.3039 7.2821C17.7003 6.89763 18.3334 6.90737 18.7179 7.30385C19.0728 7.66984 19.0918 8.23743 18.7808 8.62498L18.6961 8.7179L10.4461 16.7179C10.0881 17.0651 9.53523 17.0918 9.14721 16.798L9.05385 16.7179L5.30385 13.0815C4.90737 12.697 4.89763 12.0639 5.2821 11.6675C5.637 11.3015 6.20374 11.265 6.60067 11.564L6.69615 11.6457L9.75 14.607L17.3039 7.2821Z"
      />
    </SvgIcon>
  );
};

export default Check;
