import React from "react";

import classNames from "classnames";
import { MTSButtonIcon } from "components/mts-button-icon";
import { MTSIcon } from "components/mts-icon";
import { IMTSTab } from "components/mts-tabs";

import { MobileMenuNav } from "../mobile-menu-nav";

import classes from "./dialog-links.module.css";

interface IDialogLinksProps {
  onToggle: () => void;
  isOpen: boolean;
  isMounted: boolean;
  tabData: IMTSTab | null;
}

export const DialogLinks = (props: IDialogLinksProps): JSX.Element => {
  const { onToggle, isOpen, isMounted, tabData } = props;

  const bgStyles = classNames({
    [classes.bg]: true,
    [classes.bgOpen]: isOpen,
    [classes.bgClose]: !isOpen,
  });

  const dialogBlockStyles = classNames({
    [classes.dialogBlock]: true,
    [classes.dialogBlockOpen]: isOpen,
    [classes.dialogBlockClose]: !isOpen,
  });

  return (
    <>
      {isMounted && (
        <div className={bgStyles} onClick={() => onToggle()}>
          <div
            className={dialogBlockStyles}
            onClick={(e: React.SyntheticEvent) => {
              e.stopPropagation();
            }}
          >
            <div className={classes.blockHeader}>
              <h4 className={classes.blockHeaderTitle}>
                {tabData?.name || "-"}
              </h4>

              <MTSButtonIcon
                onClick={() => onToggle()}
                mode="standard"
                Icon={MTSIcon.Close}
                iconSize={24}
                iconColor={"var(--color-icon-primary)"}
                variant="secondary"
                size="S"
              />
            </div>

            <div className={classes.blockMenuNav}>
              <MobileMenuNav
                onClickLink={() => onToggle()}
                data={tabData?.children || []}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
