import { DateTime } from "luxon";

export interface IDateFormatted {
  date: string;
  time: string;
}

// NOTE: Возвращает дату в формате дд.мм.гггг
export const getDateFormatted = (dateISO: string): IDateFormatted => {
  let date: string = "";
  let time: string = "";

  if (dateISO) {
    const _date = DateTime.fromISO(dateISO);

    if (_date.isValid) {
      date = _date.toFormat("dd.MM.yyyy");
      time = _date.toFormat("T");
    }
  }

  return {
    date,
    time,
  };
};
