import { PROFILE_KEYS, PROFILE_ROLE } from "constants/keys";

import { ProfileChecker } from "utils/profile-checker";

import { useContextMain } from "./use-context-main";

export const useProfileData = () => {
  const { profile, isAuthorized, isLoadingAuthData } = useContextMain();

  const isPotentialRole = profile?.role === PROFILE_ROLE.POTENTIAL;
  const isCustomerBaseRole = profile?.role === PROFILE_ROLE.CUSTOMER_BASE;
  const isCustomerAdminRole = profile?.role === PROFILE_ROLE.CUSTOMER_ADMIN;
  const profileOperator: string = profile?.["mnp:operator"] ?? "";
  const isMTSOperator: boolean = ProfileChecker.isMTSOperator(profileOperator);
  const isOrganization: boolean = ProfileChecker.isOrganization(
    profile?.[PROFILE_KEYS.ORGANIZATION]
  );

  return {
    isAuthorized,
    profile,
    isLoadingAuthData,
    isMTSOperator,
    isOrganization,
    isPotentialRole,
    isCustomerBaseRole,
    isCustomerAdminRole,
  };
};
