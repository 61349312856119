import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const Orient = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="178"
      height="84"
      viewBox="0 0 178 84"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.0039 16.7705C48.0049 17.5502 53.2895 21.1886 56.1901 26.4825L60.4192 6L42.0039 16.7705ZM26.1792 40.9479C26.1792 47.0178 30.4776 51.1545 35.9407 51.1545C41.4479 51.1545 45.7464 47.0178 45.7464 40.9479C45.7464 34.9227 41.4479 30.7413 35.9407 30.7413C30.4776 30.7413 26.1792 34.9227 26.1792 40.9479ZM51.926 40.9479C51.926 49.8509 44.7615 57 35.9407 57C27.1195 57 20 49.8509 20 40.9479C20 32.0903 27.1195 24.8961 35.9407 24.8961C44.7615 24.8961 51.926 32.0903 51.926 40.9479ZM64.0189 46.5237C64.0189 42.2521 66.1235 39.4193 70.2876 39.4193H72.571V33.8888H71.6311C67.422 33.8888 64.4217 35.9123 62.7649 39.0596L62.1383 34.2034H58.332V56.6852H64.0189V46.5237ZM78.5665 34.203H84.2534V56.6847H78.5665V34.203ZM78.0742 29.2122C78.0742 27.5481 79.5517 26.2446 81.3878 26.2446C83.2681 26.2446 84.746 27.5481 84.746 29.2122C84.746 30.8755 83.2681 32.1795 81.3878 32.1795C79.5517 32.1795 78.0742 30.8755 78.0742 29.2122ZM107.051 43.4214H96.7967C96.8866 41.263 98.9462 39.105 101.991 39.105C104.14 39.105 105.752 40.1842 106.513 41.6231C106.872 42.1625 107.051 42.882 107.051 43.4214ZM112.2 47.0183C112.827 45.0851 112.782 42.0275 111.842 39.959C110.14 36.1824 106.782 33.8891 101.991 33.8891C95.0059 33.8891 90.707 39.0599 90.707 45.4448C90.707 52.0094 95.3192 56.9554 102.125 56.9554C106.11 56.9554 109.469 55.2465 111.887 53.4479L108.887 50.1656C106.872 51.2001 104.454 51.7395 102.125 51.7395C99.0802 51.7395 96.5729 50.0758 96.5729 47.0183H112.2ZM140.356 44.8149V56.6852H134.669V45.1746C134.669 40.9932 132.699 39.4193 129.699 39.4193C126.43 39.4193 124.371 41.3529 124.371 45.6693V56.6852H118.684V34.2038H122.49L122.893 37.7109C124.415 35.1928 127.281 33.8888 130.594 33.8888C137.042 33.8888 140.356 38.2954 140.356 44.8149ZM149.36 56.6852H155.091V39.7343H158.002V34.2034H155.091V26.6499H149.36V34.2034H146.449V39.7343H149.36V56.6852Z"
        fill="url(#paint0_linear_5962_406408)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_5962_406408"
          x1="158.342"
          y1="47.2725"
          x2="15.871"
          y2="173.789"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.3" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
};

export default Orient;
