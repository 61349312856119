import React from "react";

import Collapse from "@mui/material/Collapse";
import Fade from "@mui/material/Fade";
import { MTSButtonIcon } from "components/mts-button-icon";
import { MTSIcon } from "components/mts-icon";

import classes from "./notification-message.module.css";

interface INotificationMessageProps {
  show: boolean | undefined;
  handleClose?: () => void;
  text: JSX.Element | string;
  sx?: React.CSSProperties;
}

export const NotificationMessage = React.memo(
  (props: INotificationMessageProps): JSX.Element => {
    const { show, handleClose, text, sx } = props;

    return (
      <Collapse in={show} sx={{ width: "100%" }}>
        <Fade in={show} {...(show ? { timeout: 1500 } : {})}>
          <div className={classes.info} style={sx}>
            <div className={classes.infoContent}>
              <MTSIcon.Information
                sx={{
                  color: "var(--color-accent-active)",
                  width: 24,
                }}
              />
              <span className={classes.infoContentText}>{text}</span>
            </div>
            {handleClose && (
              <MTSButtonIcon
                variant="ghost"
                Icon={MTSIcon.Close}
                iconColor={"var(--color-icon-primary)"}
                iconSize={24}
                mode="round"
                onClick={handleClose}
              />
            )}
          </div>
        </Fade>
      </Collapse>
    );
  }
);
