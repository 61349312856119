import Slider from "@mui/material/Slider";
import { styled } from "@mui/material/styles";

export const MTSSlider = styled(Slider)(({ theme }) => ({
  color: "var(--color-control-inactive)",
  height: 4,
  "& .MuiSlider-track": {
    border: "none",
    color: "var(--color-brand)",
  },
  "& .MuiSlider-thumb": {
    height: 32,
    width: 32,
    backgroundColor: "var(--color-background-primary)",
    boxShadow: "var(--shadow-middle)",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "var(--shadow-middle)",
    },
    "&:before": {
      display: "none",
    },
  },
  "& .MuiSlider-mark": {
    display: "none",
  },
  "& .MuiSlider-markLabel": {
    fontFamily: "var(--typography-v3-p4-regular-comp-font-family)",
    fontSize: "var(--typography-v3-p4-regular-comp-font-size)",
    lineHeight: "var(--typography-v3-p4-regular-comp-line-height)",
    fontWeight: "var(--typography-v3-p4-regular-comp-font-weight)",
    color: "var(--color-text-secondary)",
    marginTop: "4px",
  },
}));
