import classes from "./delete-subscription-message.module.css";

export const DeleteSubscriptionMessage = (): JSX.Element => {
  return (
    <p className={classes.text}>
      При удалении подписки статистика по подпискам{" "}
      <span style={{ whiteSpace: "nowrap" }}>и устройствам</span> будет удалена
      навсегда.
      <br />
      Вы точно хотите удалить подписки?
    </p>
  );
};
