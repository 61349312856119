import React from "react";

import { TableDiscount } from "components/table-discount";

import classes from "./payment-configurator-table-discounts.module.css";

export const PaymentConfiguratorTableDiscounts = React.memo(() => {
  return (
    <>
      <div className={classes.header}>
        <h4 className={classes.headerTitle} data-testid="txt_head_discount">
          Скидки
        </h4>
      </div>

      <div className={classes.discounts}>
        <p
          className={classes.discountQuantityLabel}
          data-testid="txt_discount_for_subs"
        >
          За количество подписок*
        </p>
        <TableDiscount />

        <p
          className={classes.discountQuantityDesc}
          data-testid="txt_hint_discount"
        >
          * Скидка рассчитывается от общего количества подписок в данном платеже
        </p>
      </div>
    </>
  );
});
