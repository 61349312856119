import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const FileOnCloud = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.6311 21.3736L28.2388 17.7948C28.3007 17.4303 28.3333 17.0536 28.3333 16.6667C28.3333 12.9848 25.3486 9.99999 21.6667 9.99999C18.3627 9.99999 15.6144 12.4067 15.0905 15.5626L14.6158 18.4219L11.7183 18.349C11.6739 18.3479 11.6294 18.3473 11.5846 18.3473C8.77783 18.3473 6.66667 20.5342 6.66667 23.0322C6.66667 24.7054 7.59314 26.2179 9.06753 27.0579L9.17109 27.1169L9.27018 27.1832C10.0303 27.6914 10.7257 27.8234 13.5294 28.0668C15.3559 28.2254 17.5139 28.3339 20 28.3339C21.7511 28.3339 23.3988 28.2801 24.922 28.1922C29.3476 27.9368 30.2958 27.7739 31.354 26.9718L31.4014 26.936L31.45 26.9018C32.6716 26.0427 33.3333 24.6592 33.3333 23.9469C33.3333 22.7538 32.4147 21.7718 31.2486 21.6747L27.6311 21.3736ZM31.5251 18.3529C31.8474 18.3797 32.1619 18.4338 32.4664 18.5128C34.8825 19.1394 36.6667 21.3348 36.6667 23.9469C36.6667 26.0077 35.2368 28.3138 33.3674 29.6284C31.3461 31.1604 29.2601 31.2807 25.1141 31.52C23.5311 31.6114 21.8192 31.6673 20 31.6673C17.4167 31.6673 15.1626 31.5545 13.241 31.3877C10.616 31.1597 9.01226 31.0205 7.41749 29.9542C4.97407 28.5622 3.33333 25.9834 3.33333 23.0322C3.33333 18.6039 7.02756 15.014 11.5846 15.014C11.6574 15.014 11.7299 15.0149 11.8021 15.0167C12.5887 10.279 16.7058 6.66666 21.6667 6.66666C27.1848 6.66666 31.659 11.1361 31.6667 16.6525C31.6667 16.6572 31.6667 16.6619 31.6667 16.6667C31.6667 17.2413 31.6182 17.8047 31.5251 18.3529Z"
      />
    </SvgIcon>
  );
};

export default FileOnCloud;
