import { getProfileSubscriptions } from "services/api";

export const isAdminHasAnySubscriptions = async (): Promise<boolean> => {
  let hasAnySubscriptions: boolean = false;

  try {
    const data = await getProfileSubscriptions({
      isUseAxios: true,
    });

    if (data?.length) {
      hasAnySubscriptions = true;
    }
  } catch (error) {}

  return hasAnySubscriptions;
};
