import { MAP_ELEMENT_ID } from "constants/dom-element-ids";

import { IBaseStationEchoDTO } from "interfaces";
import ReactDOMServer from "react-dom/server";
import { getStationCoordinates } from "utils/get-station-coordinates";

import { BUTTON_REDIRECT_EVENT_ID_TO_CREATE_RINEX_REQUEST } from "../../constants";
import round24IconOffLine from "../../icons/round-24-offline.svg";
import round24Icon from "../../icons/round-24.svg";

import "../../index.css";
import classes from "./card-base-station.module.css";

interface ICardBaseStationProps {
  properties: IBaseStationEchoDTO;
  isShowDownloadButton: boolean;
  isCanDownloadRinex: boolean;
}

export const CardBaseStation = (props: ICardBaseStationProps) => {
  const { properties, isShowDownloadButton, isCanDownloadRinex } = props;

  const stationId: string = String(properties?.id) ?? "";
  const stationMountpoint = properties?.mountPoint ?? "";
  const isStationOnline: boolean = properties?.conditionNetwork === "1";
  const formattedAddress: string = properties?.address?.formatted_address ?? "";
  const receiverName: string = properties?.receiveType ?? "";
  const stationCoordinates: string = getStationCoordinates({
    lat: properties?.coordinateLatitude,
    lng: properties?.coordinateLongitude,
  });
  const antenna: string = properties?.antenna ?? "";
  const ip: string = properties?.connectionIp ?? "";
  const port: string | number = properties?.connectionPort ?? "";
  const address: string = properties?.address?.area ?? "";
  const isPartnerStation: boolean = properties?.partner ?? "";
  const isDisabledDownloadButton: boolean =
    isPartnerStation || isCanDownloadRinex === false;

  const buttonForDownloadRinexFile: string = isShowDownloadButton
    ? `<button data-testid="btn_rinex_upload_base" class="mts-map-custom-popup-button"
    ${isDisabledDownloadButton ? "disabled" : ""}
    onclick="
    function a(){
    const event = new CustomEvent('${BUTTON_REDIRECT_EVENT_ID_TO_CREATE_RINEX_REQUEST}', { detail: '${stationId}' });
    const elem = document.getElementById('${MAP_ELEMENT_ID}');
    elem.dispatchEvent(event);
    };
    a();"
    >
Запросить файл RINEX
</button>`
    : "";

  return (
    ReactDOMServer.renderToString(
      <>
        <div className={"mts-map-custom-popup-header"}>
          <p className={classes.headerTitle} data-testid="txt_area_base">
            {address}
          </p>
        </div>

        <div className={"mts-map-custom-popup-info"}>
          <div className={"mts-map-custom-popup-info__status"}>
            <img
              alt="Иконка не загрузилась"
              src={isStationOnline ? round24Icon : round24IconOffLine}
            />
            <p className={classes.infoStatusText} data-testid="txt_status_base">
              {isStationOnline ? "В сети" : "Не в сети"}
            </p>
          </div>

          <div className={"mts-map-custom-popup-info__location"}>
            <p
              className={classes.infoLocationText}
              data-testid="txt_area_address_base"
            >
              {formattedAddress}
            </p>
          </div>

          <div className="mts-map-custom-popup-info__list">
            <div
              className={"mts-map-custom-popup-info__list__item"}
              data-testid="txt_coord_base"
            >
              <p className={classes.listItemLabel}>Координаты:</p>
              <p className={classes.listItemText}>{stationCoordinates}</p>
            </div>
            <div
              className={"mts-map-custom-popup-info__list__item"}
              data-testid="txt_st_id_base"
            >
              <p className={classes.listItemLabel}>ID станции:</p>
              <p className={classes.listItemText}>{stationMountpoint}</p>
            </div>
            <div
              className={"mts-map-custom-popup-info__list__item"}
              data-testid="txt_receiver_type_base"
            >
              <p className={classes.listItemLabel}>Тип приёмника:</p>
              <p className={classes.listItemText}>{receiverName}</p>
            </div>
            <div
              className={"mts-map-custom-popup-info__list__item"}
              data-testid="txt_antenna_base"
            >
              <p className={classes.listItemLabel}>Антенна:</p>
              <p className={classes.listItemText}>{antenna}</p>
            </div>
            {ip && port ? (
              <div
                className={"mts-map-custom-popup-info__list__item"}
                data-testid="txt_connection_data_base"
              >
                <p className={classes.listItemLabel}>Для подключения:</p>
                <p className={classes.listItemText}>
                  {ip}:{port}
                </p>
              </div>
            ) : null}
          </div>
        </div>
      </>
    ) +
    buttonForDownloadRinexFile +
    ReactDOMServer.renderToString(
      isShowDownloadButton ? (
        <>
          {isPartnerStation ? (
            <div className="mts-map-custom-popup-msg">
              <p
                className={classes.footerText}
                data-testid="txt_access_info_base"
              >
                На этой станции отсутствует функциональность загрузки файлов
                RINEX.
                <br />
                Пожалуйста, выберите другую станцию.
              </p>
            </div>
          ) : (
            <>
              {isCanDownloadRinex === false && (
                <div className="mts-map-custom-popup-msg">
                  <p
                    className={classes.footerText}
                    data-testid="txt_access_base"
                  >
                    Активируйте подписку для доступа к RINEX
                  </p>
                </div>
              )}
            </>
          )}
        </>
      ) : (
        <></>
      )
    )
  );
};
