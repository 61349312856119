import { PROFILE_ROLE } from "constants/keys";
import {
  PATH_SUBSCRIPTION_INFO,
  PATH_PERSONAL_AREA,
  PATH_ADD_SUBSCRIPTIONS,
  PATH_PAYMENT_EDIT,
  PATH_SUBSCRIPTIONS,
  PATH_TARIFFS,
  PATH_PAYMENTS,
  PATH_SETTINGS,
  PATH_RINEX_REQUESTS,
  PATH_ADD_RINEX_REQUEST,
} from "constants/routes";

import { Route, Switch } from "react-router-dom";

import { DialogScreenRestriction } from "components/dialog-screen-restriction";
import { Loader } from "components/loader";
import { AddingSubscriptions } from "components/pages/adding-subscriptions";
import { ErrorPage, ErrorPageVariant } from "components/pages/error-page";
import { OnlyNewUserPage } from "components/pages/only-new-user-page";
import { PaymentEditor } from "components/pages/payment-editor";
import { RinexRequestAddPage } from "components/pages/rinex-request-add-page";
import { RinexRequestsPage } from "components/pages/rinex-requests-page";
import { SubscriptionInfoPage } from "components/pages/subsription-info-page";
import { TabPayments } from "components/pages/tab-payments";
import { TabSettings } from "components/pages/tab-settings";
import { TabSubscriptions } from "components/pages/tab-subscriptions";
import { TabTariffs } from "components/pages/tab-tariffs";
import { PrivateRoute } from "components/private-route";
import { useProfileData } from "hooks/use-profile-data";

export const PersonalArea = () => {
  const { isLoadingAuthData, isPotentialRole } = useProfileData();

  if (isLoadingAuthData) {
    return <Loader />;
  }

  if (isPotentialRole) {
    return (
      <>
        <OnlyNewUserPage />;
        <DialogScreenRestriction />
      </>
    );
  }

  return (
    <>
      <Switch>
        <PrivateRoute
          exact
          path={PATH_RINEX_REQUESTS}
          hasAccessRoles={[
            PROFILE_ROLE.CUSTOMER_ADMIN,
            PROFILE_ROLE.CUSTOMER_BASE,
            PROFILE_ROLE.DEALER,
          ]}
        >
          <RinexRequestsPage />
        </PrivateRoute>

        <PrivateRoute
          exact
          path={PATH_ADD_RINEX_REQUEST}
          hasAccessRoles={[
            PROFILE_ROLE.CUSTOMER_BASE,
            PROFILE_ROLE.CUSTOMER_ADMIN,
            PROFILE_ROLE.DEALER,
          ]}
        >
          <RinexRequestAddPage />
        </PrivateRoute>

        <PrivateRoute
          path={PATH_SUBSCRIPTIONS}
          hasAccessRoles={[PROFILE_ROLE.CUSTOMER_ADMIN, PROFILE_ROLE.DEALER]}
        >
          <TabSubscriptions />
        </PrivateRoute>

        <PrivateRoute
          exact
          path={PATH_TARIFFS}
          hasAccessRoles={[PROFILE_ROLE.CUSTOMER_ADMIN, PROFILE_ROLE.DEALER]}
        >
          <TabTariffs />
        </PrivateRoute>

        <PrivateRoute
          path={PATH_PAYMENTS}
          hasAccessRoles={[PROFILE_ROLE.CUSTOMER_ADMIN, PROFILE_ROLE.DEALER]}
        >
          <TabPayments />
        </PrivateRoute>

        <PrivateRoute
          path={PATH_SETTINGS}
          hasAccessRoles={[
            PROFILE_ROLE.CUSTOMER_BASE,
            PROFILE_ROLE.CUSTOMER_ADMIN,
            PROFILE_ROLE.DEALER,
          ]}
        >
          <TabSettings />
        </PrivateRoute>

        <PrivateRoute
          exact
          path={PATH_ADD_SUBSCRIPTIONS}
          hasAccessRoles={[PROFILE_ROLE.CUSTOMER_ADMIN, PROFILE_ROLE.DEALER]}
        >
          <AddingSubscriptions />
        </PrivateRoute>

        <PrivateRoute
          exact
          path={`${PATH_PAYMENT_EDIT}/:id`}
          hasAccessRoles={[PROFILE_ROLE.CUSTOMER_ADMIN, PROFILE_ROLE.DEALER]}
        >
          <PaymentEditor />
        </PrivateRoute>

        <PrivateRoute
          exact
          path={`${PATH_SUBSCRIPTION_INFO}/:id`}
          hasAccessRoles={[PROFILE_ROLE.CUSTOMER_ADMIN, PROFILE_ROLE.DEALER]}
        >
          <SubscriptionInfoPage />
        </PrivateRoute>

        <Route path="*">
          <ErrorPage
            variants={ErrorPageVariant.PAGE_NOT_FOUND}
            buttonLabel="Перейти в личный кабинет"
            redirectURL={PATH_PERSONAL_AREA}
          />
        </Route>
      </Switch>
      <DialogScreenRestriction />
    </>
  );
};
