import React from "react";

import Radio from "@mui/material/Radio";
import classNames from "classnames";
import { MTSIcon } from "components/mts-icon";

import { MenuOptionType } from "../../constants";
import { MTSIconSvg } from "../mts-icon-svg";

import classes from "./menu-option-radio.module.css";

export enum RadioSize {
  S_16 = "S_16",
  M_24 = "M_24",
  L_32 = "L_32",
}

interface IMenuOptionRadioProps {
  menuType: MenuOptionType;
  disabled?: boolean;
  selectedMenuOption: MenuOptionType;
  setSelectedMenuOption: (v: MenuOptionType) => void;
  dataTestIdIcon?: string;
  dataTestIdLabel?: string;
  dataTestIdRadio?: string;
}

export const MenuOptionRadio = React.memo(
  (props: IMenuOptionRadioProps): JSX.Element => {
    const {
      menuType,
      disabled = false,
      selectedMenuOption,
      setSelectedMenuOption,
      dataTestIdIcon = "",
      dataTestIdLabel = "",
      dataTestIdRadio = "",
    } = props;

    const size: any = RadioSize.S_16;
    const iconStyles = classNames({
      [classes.icon]: true,
      [classes.size_S_16]: size === RadioSize.S_16,
      [classes.size_M_24]: size === RadioSize.M_24,
      [classes.size_L_32]: size === RadioSize.L_32,
    });

    const checkedIconStyles = classNames({
      [classes.icon]: true,
      [classes.size_S_16]: size === RadioSize.S_16,
      [classes.size_M_24]: size === RadioSize.M_24,
      [classes.size_L_32]: size === RadioSize.L_32,
      [classes.checkedIcon]: true,
    });

    return (
      <div className={classes.option}>
        <div className={classes.optionItem}>
          {menuType === MenuOptionType.RTK && (
            <MTSIcon.RTK
              data-testid={dataTestIdIcon}
              sx={{
                fontSize: 32,
                color:
                  selectedMenuOption === MenuOptionType.RTK
                    ? "var(--color-constant-blackberry-light)"
                    : "var(--color-constant-greyscale-200)",
              }}
            />
          )}
          {menuType === MenuOptionType.RINEX && (
            <MTSIcon.RINEX
              data-testid={dataTestIdIcon}
              sx={{
                fontSize: 32,
                color:
                  selectedMenuOption === MenuOptionType.RINEX
                    ? "var(--color-constant-apple-normal)"
                    : "var(--color-constant-greyscale-200)",
              }}
            />
          )}
          {menuType === MenuOptionType.VRS && (
            <MTSIcon.VRS
              data-testid={dataTestIdIcon}
              sx={{
                fontSize: 32,
                color:
                  selectedMenuOption === MenuOptionType.VRS
                    ? "var(--color-constant-plum-light)"
                    : "var(--color-constant-greyscale-200)",
              }}
            />
          )}
          {menuType === MenuOptionType.PLANNED_STATIONS && (
            <MTSIcon.Ref
              data-testid={dataTestIdIcon}
              sx={{
                fontSize: 32,
                color:
                  selectedMenuOption === MenuOptionType.PLANNED_STATIONS
                    ? "var(--color-constant-greyscale-400)"
                    : "var(--color-constant-greyscale-200)",
              }}
            />
          )}
          {menuType === MenuOptionType.MTS && (
            <MTSIconSvg
              data-testid={dataTestIdIcon}
              sx={{
                fontSize: 32,
                color:
                  selectedMenuOption === MenuOptionType.MTS
                    ? "var(--color-brand)"
                    : "var(--color-constant-greyscale-200)",
              }}
            />
          )}
          {menuType === MenuOptionType.BEELINE &&
            selectedMenuOption === MenuOptionType.BEELINE && (
              <MTSIcon.Beeline
                data-testid={dataTestIdIcon}
                sx={{
                  fontSize: 32,
                  color: "#F8D247",
                }}
              />
            )}
          {menuType === MenuOptionType.BEELINE &&
            selectedMenuOption !== MenuOptionType.BEELINE && (
              <MTSIcon.BeelineDisabled
                data-testid={dataTestIdIcon}
                sx={{
                  fontSize: 32,
                }}
              />
            )}
          {menuType === MenuOptionType.MEGAFON && (
            <MTSIcon.Megafon
              data-testid={dataTestIdIcon}
              sx={{
                fontSize: 32,
                color:
                  selectedMenuOption === MenuOptionType.MEGAFON
                    ? "#00985F"
                    : "var(--color-constant-greyscale-200)",
              }}
            />
          )}
          {menuType === MenuOptionType.TELE2 && (
            <MTSIcon.Tele2
              data-testid={dataTestIdIcon}
              sx={{
                fontSize: 32,
                color:
                  selectedMenuOption === MenuOptionType.TELE2
                    ? "var(--color-constant-greyscale-900)"
                    : "var(--color-constant-greyscale-200)",
              }}
            />
          )}

          <p className={classes.optionItemLabel} data-testid={dataTestIdLabel}>
            {menuType}
          </p>
        </div>
        <Radio
          data-testid={dataTestIdRadio}
          disableRipple
          disabled={disabled}
          color="error"
          checked={selectedMenuOption === menuType}
          onClick={() => setSelectedMenuOption(menuType)}
          checkedIcon={<span className={checkedIconStyles} />}
          icon={<span className={iconStyles} />}
          sx={{
            padding: "8px",
            "&.Mui-checked": {
              color: "transparent !important",
            },
          }}
        />
      </div>
    );
  }
);
