import { ITabNames } from "constants/tabs";

import React from "react";

import { useContextMain } from "hooks/use-context-main";

import { useProfileData } from "../use-profile-data";

export const useTabsByRole = (): void => {
  const { setTabNames } = useContextMain();

  const { isCustomerAdminRole, isCustomerBaseRole, isLoadingAuthData } =
    useProfileData();

  React.useEffect(() => {
    if (!isLoadingAuthData) {
      let tabSettings: string = "Настройки";

      if (isCustomerBaseRole) {
        tabSettings = "Справка";
      }

      setTabNames((prevTabs: ITabNames) => ({
        ...prevTabs,
        settings: tabSettings,
      }));
    }
  }, [isCustomerAdminRole, isCustomerBaseRole, isLoadingAuthData, setTabNames]);
};
