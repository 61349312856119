import { IDictionaryDTO } from "interfaces";
import { DateTime } from "luxon";

export const getRinexStartDays = (UTC: string): IDictionaryDTO[] => {
  return new Array(8)
    .fill({ id: 0, name: "", code: "", description: "" })
    .map((day: IDictionaryDTO, index: number) => {
      const date: string = DateTime.now()
        .setZone(UTC)
        .minus({ days: index })
        .toFormat("dd.MM.yyyy");

      return {
        ...day,
        id: index,
        code: date,
        name: date,
      };
    });
};
