import { FIELD_NAMES } from "constants/keys";
import {
  PATH_SUBSCRIPTIONS,
  ROUTE_SUBSCRIPTIONS_ACTIVATED,
  ROUTE_SUBSCRIPTIONS_ALL,
  ROUTE_SUBSCRIPTIONS_EXPIRED,
  ROUTE_SUBSCRIPTIONS_PAID,
  ROUTE_SUBSCRIPTIONS_RENEW,
} from "constants/routes";

import React from "react";

import { Route, Switch, Redirect } from "react-router-dom";

import { Loader } from "components/loader";
import { ErrorPage, ErrorPageVariant } from "components/pages/error-page";
import {
  TableSubscriptionsAll,
  TableSubscriptionsActivated,
  TableSubscriptionsExpired,
  TableSubscriptionsRenew,
  TableSubscriptionsPaid,
} from "components/tables";
import { useCheckAbilityToCreatePayments } from "hooks/use-check-ability-to-create-payments";
import {
  ISubscriptionsByStatuses,
  ITableSubscriptionsFilters,
} from "interfaces";

interface ISubscriptionTablesProps {
  subscriptions: ISubscriptionsByStatuses;
  tableFilters: ITableSubscriptionsFilters;
}

export const SubscriptionTables = (props: ISubscriptionTablesProps) => {
  const { subscriptions, tableFilters } = props;

  const {
    data: abilityToCreatePayments,
    isLoading: isLoadingAbilityToCreatePayments,
  } = useCheckAbilityToCreatePayments({});

  const tableSortBy = React.useMemo(
    () => [
      {
        id: FIELD_NAMES.SUBSCRIPTION_CREATED_DATE,
        desc: true,
      },
    ],
    []
  );

  if (isLoadingAbilityToCreatePayments) {
    return <Loader />;
  }

  return (
    <Switch>
      <Route exact path={PATH_SUBSCRIPTIONS}>
        <Redirect to={`${PATH_SUBSCRIPTIONS}/${ROUTE_SUBSCRIPTIONS_ALL}`} />
      </Route>

      <Route exact path={`${PATH_SUBSCRIPTIONS}/${ROUTE_SUBSCRIPTIONS_ALL}`}>
        <TableSubscriptionsAll
          data={subscriptions.all}
          tableFilters={tableFilters}
          tableSortBy={tableSortBy}
        />
      </Route>

      <Route
        exact
        path={`${PATH_SUBSCRIPTIONS}/${ROUTE_SUBSCRIPTIONS_ACTIVATED}`}
      >
        <TableSubscriptionsActivated
          data={subscriptions.activated}
          tableFilters={tableFilters}
          tableSortBy={tableSortBy}
        />
      </Route>

      <Route exact path={`${PATH_SUBSCRIPTIONS}/${ROUTE_SUBSCRIPTIONS_PAID}`}>
        <TableSubscriptionsPaid
          data={subscriptions.paid}
          tableFilters={tableFilters}
          tableSortBy={tableSortBy}
        />
      </Route>

      <Route exact path={`${PATH_SUBSCRIPTIONS}/${ROUTE_SUBSCRIPTIONS_RENEW}`}>
        <TableSubscriptionsRenew
          data={subscriptions.renew}
          tableFilters={tableFilters}
          tableSortBy={tableSortBy}
          isCanCreatePayment={abilityToCreatePayments?.canCreatePayment}
        />
      </Route>

      <Route
        exact
        path={`${PATH_SUBSCRIPTIONS}/${ROUTE_SUBSCRIPTIONS_EXPIRED}`}
      >
        <TableSubscriptionsExpired
          data={subscriptions.expired}
          tableFilters={tableFilters}
          tableSortBy={tableSortBy}
        />
      </Route>

      <Route exact path="*">
        <ErrorPage
          variants={ErrorPageVariant.PAGE_NOT_FOUND}
          buttonLabel="Продолжить работу с подписками"
          redirectURL={PATH_SUBSCRIPTIONS}
        />
      </Route>
    </Switch>
  );
};
