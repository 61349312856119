import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const Renewal = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M8.00004 2.66667V1.47333C8.00004 1.17333 8.36004 1.02667 8.56671 1.24L10.4334 3.1C10.5667 3.23333 10.5667 3.44 10.4334 3.57333L8.57337 5.43333C8.36004 5.64 8.00004 5.49333 8.00004 5.19333V4C5.79337 4 4.00004 5.79333 4.00004 8C4.00004 8.52667 4.10004 9.04 4.29338 9.5C4.39337 9.74 4.32004 10.0133 4.14004 10.1933C3.80004 10.5333 3.22671 10.4133 3.04671 9.96667C2.80004 9.36 2.66671 8.69333 2.66671 8C2.66671 5.05333 5.05337 2.66667 8.00004 2.66667ZM8.00004 12C10.2067 12 12 10.2067 12 8C12 7.47333 11.9 6.96 11.7067 6.5C11.6067 6.26 11.68 5.98667 11.86 5.80667C12.2 5.46667 12.7734 5.58667 12.9534 6.03333C13.2 6.64 13.3334 7.30667 13.3334 8C13.3334 10.9467 10.9467 13.3333 8.00004 13.3333V14.5267C8.00004 14.8267 7.64004 14.9733 7.43337 14.76L5.57337 12.9C5.44004 12.7667 5.44004 12.56 5.57337 12.4267L7.43337 10.5667C7.64004 10.36 8.00004 10.5067 8.00004 10.8067V12Z" />
    </SvgIcon>
  );
};

export default Renewal;
