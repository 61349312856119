interface IPayload {
  value: number | null | undefined;
  showFractionDigits?: boolean;
}

export const getFormattedNumberWithThousandSeparator = ({
  value,
  showFractionDigits = false,
}: IPayload): string => {
  let result = "";

  if (typeof value === "number" && isFinite(value)) {
    result = value.toLocaleString(
      "ru",
      showFractionDigits
        ? {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }
        : {}
    );
  }

  return result;
};
