import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export const TechniqueIcon = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon
      width="96"
      height="96"
      viewBox="0 0 96 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx="54.4282"
        cy="57.9048"
        r="8.90476"
        stroke="black"
        strokeWidth="2"
      />
      <circle
        cx="21.001"
        cy="62.8572"
        r="5.19048"
        stroke="black"
        strokeWidth="2"
      />
      <circle
        cx="54.4292"
        cy="57.9047"
        r="15.0952"
        stroke="black"
        strokeWidth="2"
      />
      <circle
        cx="21.0003"
        cy="62.8572"
        r="10.1429"
        stroke="black"
        strokeWidth="2"
      />
      <path
        d="M11.0957 49.2381V60.381"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38.9524 56.0476L8.74837 48.1946C8.30765 48.08 8 47.6821 8 47.2268V36C8 35.4477 8.44772 35 9 35H32.0991C32.3744 35 32.6376 34.8865 32.8265 34.6863L44.4997 22.3137C44.6886 22.1135 44.9518 22 45.2271 22H72.619C73.1713 22 73.6191 22.4477 73.6191 23V25.3333C73.6191 25.8856 73.1713 26.3333 72.619 26.3333H69.9395M69.9395 26.3333H48.2488C47.6965 26.3333 47.2488 26.781 47.2488 27.3333V38.3333C47.2488 38.8856 47.6965 39.3333 48.2488 39.3333H58.5941M69.9395 26.3333V38.3333C69.9395 38.8856 69.4918 39.3333 68.9395 39.3333H58.5941M58.5941 39.3333V26.9524"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.2852 67.1905H42.6661"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M64.9531 39.9524V46.7619"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M86.9743 71.4485L72.4175 49.4289C71.8925 48.6349 70.666 48.9532 70.6016 49.9029C70.1954 55.8921 69.7438 64.8491 70.1752 67.913C70.6844 71.5304 73.7826 72.8116 75.268 73H86.1402C86.9371 73 87.4138 72.1133 86.9743 71.4485Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
