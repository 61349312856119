import React from "react";

import classes from "./info-numbers.module.css";

export const InfoNumbers = (): JSX.Element => {
  return (
    <div className={classes.blockInfoNumbers}>
      <h4 className={classes.title}>CAPEX</h4>

      <div className={classes.blockNumbers}>
        <div className={classes.blockNumber}>
          <p className={classes.blockNumberText}>
            4,05
            <span>млн ₽</span>
          </p>

          <p className={classes.blockNumberInfo}>
            оборудование из расчёта&nbsp;
            <br className={classes.blockNumberBreak} />
            450 тыс. руб на 1 ед. техники
          </p>
        </div>

        <div className={classes.blockNumber}>
          <p className={classes.blockNumberText}>
            1,08
            <span>млн ₽</span>
          </p>

          <p className={classes.blockNumberInfo}>
            подписка на услугу стоимостью
            <br />
            40 тыс. руб на 1 ед.&nbsp;
            <span style={{ whiteSpace: "nowrap" }}>в год</span>
          </p>
        </div>
      </div>
    </div>
  );
};
