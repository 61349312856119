import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export const LaboriounessIcon = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon
      width="96"
      height="96"
      viewBox="0 0 96 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M28.522 49.262H30.8352C31.6443 49.262 32.1184 50.1729 31.6542 50.8357L26.0489 58.8389C25.6507 59.4073 24.8088 59.4073 24.4107 58.8389L18.8053 50.8357C18.3411 50.1729 18.8153 49.262 19.6244 49.262H21.3707C21.9371 49.262 22.3895 48.7951 22.3988 48.2288C22.4777 43.4338 24.1192 34.1323 30.4043 28.2364C35.2653 23.2525 48.9387 15.3407 64.7447 23.564C72.7418 28.0807 85.9134 42.2535 74.6234 62.8118C72.1145 67.4842 63.4275 76.642 48.7506 75.8944"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M29.4648 66.5496C29.4648 66.5496 31.3465 69.353 34.169 70.7547M36.9915 72.6236C38.8732 73.7138 40.7548 74.9598 44.9886 75.427"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M54.3341 48.3274C54.3341 50.3492 52.6821 51.9997 50.6299 51.9997C48.5778 51.9997 46.9258 50.3492 46.9258 48.3274C46.9258 46.3056 48.5778 44.655 50.6299 44.655C52.6821 44.655 54.3341 46.3056 54.3341 48.3274Z"
        stroke="black"
        strokeWidth="2"
      />
      <path
        d="M50.6309 34.176V43.7544"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M60.0427 56.2705L53.7188 51.0337"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
};
