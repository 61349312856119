import React from "react";

import { IOpenedTabData } from "interfaces/local";

interface IContextHeader {
  tabNotAuthorizedPositions: DOMRect[];
  setTabNotAuthorizedPositions: React.Dispatch<React.SetStateAction<DOMRect[]>>;
  tabAuthorizedPositions: DOMRect[];
  setTabAuthorizedPositions: React.Dispatch<React.SetStateAction<DOMRect[]>>;
  isOpenTabTooltip: boolean;
  setIsOpenTabTooltip: React.Dispatch<React.SetStateAction<boolean>>;
  openedTabData: IOpenedTabData | null;
  setOpenedTabData: React.Dispatch<React.SetStateAction<IOpenedTabData | null>>;
  closeTabTooltip: () => void;
}

export const ContextHeader = React.createContext<IContextHeader>({
  tabNotAuthorizedPositions: [],
  setTabNotAuthorizedPositions: () => [],
  tabAuthorizedPositions: [],
  setTabAuthorizedPositions: () => [],
  isOpenTabTooltip: false,
  setIsOpenTabTooltip: () => false,
  openedTabData: null,
  setOpenedTabData: () => null,
  closeTabTooltip: () => undefined,
});
