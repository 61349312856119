import {
  SUBSCRIPTION_DURATIONS,
  SUBSCRIPTION_SERVICE_KINDS,
} from "constants/keys";

import React from "react";

import { IPayGroupDTO, IPayGroupByDurations } from "interfaces";

interface ICalculatedPayGroup {
  newPayGroupsRTKRINEX: IPayGroupByDurations;
  newPayGroupsRINEX: IPayGroupByDurations;
}

const doCalculate = (
  store: IPayGroupByDurations,
  durationType: string,
  payload: number
): IPayGroupByDurations => {
  switch (durationType) {
    case SUBSCRIPTION_DURATIONS.DAY:
      return { ...store, day: store.day + payload };
    case SUBSCRIPTION_DURATIONS.MONTH:
      return { ...store, month: store.month + payload };
    case SUBSCRIPTION_DURATIONS.QUARTER:
      return { ...store, quarter: store.quarter + payload };
    case SUBSCRIPTION_DURATIONS.HALF_YEAR:
      return { ...store, halfYear: store.halfYear + payload };
    case SUBSCRIPTION_DURATIONS.YEAR:
      return { ...store, year: store.year + payload };
    case SUBSCRIPTION_DURATIONS.TWO_WEEKS:
      return { ...store, testPeriod: store.testPeriod + payload };
    default:
      return store;
  }
};

class InitialStateCalculatedPayGroup implements IPayGroupByDurations {
  day = 0;
  month = 0;
  quarter = 0;
  halfYear = 0;
  year = 0;
  testPeriod = 0;
}

export const usePayGroups = (data: IPayGroupDTO[]): ICalculatedPayGroup => {
  const [calculatedPayGroups, setCalculatedPayGroups] =
    React.useState<ICalculatedPayGroup>({
      newPayGroupsRTKRINEX: new InitialStateCalculatedPayGroup(),
      newPayGroupsRINEX: new InitialStateCalculatedPayGroup(),
    });

  React.useEffect(() => {
    if (data?.length) {
      let newPayGroupsRTKRINEX: IPayGroupByDurations =
        new InitialStateCalculatedPayGroup();
      let newPayGroupsRINEX: IPayGroupByDurations =
        new InitialStateCalculatedPayGroup();

      data.forEach((payGroup: IPayGroupDTO) => {
        if (payGroup.serviceKind === SUBSCRIPTION_SERVICE_KINDS.RTK_RINEX) {
          newPayGroupsRTKRINEX = doCalculate(
            newPayGroupsRTKRINEX,
            payGroup.duration,
            payGroup.subscriptionsCount
          );
        } else if (payGroup.serviceKind === SUBSCRIPTION_SERVICE_KINDS.RINEX) {
          newPayGroupsRINEX = doCalculate(
            newPayGroupsRINEX,
            payGroup.duration,
            payGroup.subscriptionsCount
          );
        }
      });

      setCalculatedPayGroups({
        newPayGroupsRTKRINEX,
        newPayGroupsRINEX,
      });
    }
  }, [data]);

  return calculatedPayGroups;
};
