import { PATH_PERSONAL_AREA } from "constants/routes";

import React from "react";

import { Route } from "react-router";

import { Loader } from "components/loader";
import { ErrorPage, ErrorPageVariant } from "components/pages/error-page";
import { useContextMain } from "hooks/use-context-main";
import { AuthService } from "services/auth";

interface IPrivateRotueProps {
  children: React.ReactNode;
  hasAccessRoles: string[];
  [key: string]: any;
}

export const PrivateRoute = (props: IPrivateRotueProps): JSX.Element => {
  const { children, hasAccessRoles, ...rest } = props;
  const { isAuthorized, isLoadingAuthData, profile } = useContextMain();
  const role: string | undefined = profile?.role;

  if (!isLoadingAuthData && !isAuthorized) {
    AuthService.login();
  }

  if (isAuthorized) {
    return (
      <Route {...rest}>
        {role && hasAccessRoles.includes(role) ? (
          children
        ) : (
          <ErrorPage
            variants={ErrorPageVariant.NO_ACCESS}
            buttonLabel="Продолжить работу"
            redirectURL={PATH_PERSONAL_AREA}
          />
        )}
      </Route>
    );
  }

  return <Loader />;
};
