import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const Odnoklassniki = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_111296_312910)">
        <path d="M25.143 14.6581C25.143 12.9726 23.7227 11.602 21.9765 11.602C20.2318 11.602 18.8101 12.9726 18.8101 14.6581C18.8101 16.3423 20.2318 17.7138 21.9765 17.7138C23.7227 17.7138 25.143 16.3423 25.143 14.6581Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.194233 16.9231C0.670174 10.5282 0.908144 7.3308 4.11752 4.12142C7.3269 0.912045 10.5243 0.674076 16.9192 0.198137C18.577 0.0747617 20.287 0 21.997 0C23.707 0 25.417 0.074763 27.0747 0.198139C33.4696 0.67408 36.6671 0.912051 39.8765 4.12143C43.0858 7.3308 43.3238 10.5282 43.7997 16.9231C43.9231 18.5808 43.9979 20.2909 43.9979 22.0009C43.9979 23.7109 43.9231 25.4209 43.7997 27.0787C43.3238 33.4735 43.0858 36.671 39.8765 39.8804C36.6671 43.0897 33.4696 43.3277 27.0748 43.8037C25.417 43.927 23.707 44.0018 21.997 44.0018C20.287 44.0018 18.577 43.927 16.9192 43.8036C10.5243 43.3277 7.32689 43.0897 4.11751 39.8804C0.908139 36.671 0.670169 33.4735 0.194231 27.0786C0.0708554 25.4209 -0.00390625 23.7109 -0.00390625 22.0009C-0.00390625 20.2909 0.0708567 18.5809 0.194233 16.9231ZM21.9786 22.0378C26.197 22.0378 29.6262 18.7273 29.6262 14.6575C29.6262 10.5864 26.197 7.27496 21.9786 7.27496C17.7611 7.27496 14.3311 10.5864 14.3311 14.6575C14.3311 18.7273 17.7611 22.0378 21.9786 22.0378ZM25.0905 28.0613C26.6472 27.719 28.1485 27.1245 29.5313 26.2861C30.5778 25.6492 30.8931 24.314 30.2337 23.3038C29.5748 22.2914 28.1924 21.9867 27.1441 22.6236C24.0115 24.5251 19.9784 24.5246 16.8476 22.6236C15.7993 21.9867 14.4165 22.2914 13.759 23.3038C13.0996 24.3148 13.4139 25.6492 14.4604 26.2861C15.8432 27.1236 17.3446 27.719 18.9013 28.0613L14.6256 32.1885C13.7516 33.0331 13.7516 34.4023 14.6265 35.2469C15.0644 35.6687 15.6373 35.8798 16.2102 35.8798C16.784 35.8798 17.3578 35.6687 17.7957 35.2469L21.9954 31.1912L26.1988 35.2469C27.0727 36.0914 28.4908 36.0914 29.3657 35.2469C30.2415 34.4023 30.2415 33.0322 29.3657 32.1885L25.0905 28.0613Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_111296_312910">
          <rect width="44" height="44" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default Odnoklassniki;
