import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const WarningCircle = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_4624_336067)">
        <path
          d="M12 13C12.5523 13 13 12.5523 13 12L13 8C13 7.44771 12.5523 7 12 7C11.4477 7 11 7.44772 11 8L11 12C11 12.5523 11.4477 13 12 13Z"
          fill="#6384E0"
        />
        <path
          d="M12 14.7523C12.6897 14.7523 13.2489 15.3114 13.2489 16.0011C13.2489 16.6909 12.6897 17.25 12 17.25C11.3103 17.25 10.7511 16.6909 10.7511 16.0011C10.7511 15.3114 11.3103 14.7523 12 14.7523Z"
          fill="#6384E0"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.68597 19.314C3.3731 18.0012 3.27575 16.6932 3.08105 14.0772C3.03058 13.399 3 12.6995 3 12C3 11.3005 3.03058 10.601 3.08105 9.92282C3.27575 7.30684 3.3731 5.99884 4.68597 4.68597C5.99884 3.37309 7.30684 3.27575 9.92282 3.08105C10.601 3.03058 11.3005 3 12 3C12.6995 3 13.399 3.03058 14.0772 3.08105C16.6932 3.27575 18.0012 3.37309 19.314 4.68597C20.6269 5.99884 20.7243 7.30684 20.9189 9.92282C20.9694 10.601 21 11.3005 21 12C21 12.6995 20.9694 13.399 20.9189 14.0772C20.7243 16.6932 20.6269 18.0012 19.314 19.314C18.0012 20.6269 16.6932 20.7243 14.0772 20.9189C13.399 20.9694 12.6995 21 12 21C11.3005 21 10.601 20.9694 9.92282 20.9189C7.30684 20.7243 5.99884 20.6269 4.68597 19.314ZM12 19C11.3578 19 10.7084 18.9719 10.0713 18.9245C7.3209 18.7198 6.80638 18.606 6.10018 17.8998C5.39398 17.1936 5.28023 16.6791 5.07554 13.9287C5.02812 13.2916 5 12.6422 5 12C5 11.3578 5.02812 10.7084 5.07554 10.0713C5.28023 7.3209 5.39398 6.80638 6.10018 6.10018C6.80638 5.39398 7.3209 5.28023 10.0713 5.07554C10.7084 5.02812 11.3578 5 12 5C12.6422 5 13.2916 5.02812 13.9287 5.07554C16.6791 5.28023 17.1936 5.39398 17.8998 6.10018C18.606 6.80638 18.7198 7.3209 18.9245 10.0713C18.9719 10.7084 19 11.3578 19 12C19 12.6422 18.9719 13.2916 18.9245 13.9287C18.7198 16.6791 18.606 17.1936 17.8998 17.8998C17.1936 18.606 16.6791 18.7198 13.9287 18.9245C13.2916 18.9719 12.6422 19 12 19Z"
          fill="#6384E0"
        />
      </g>
      <defs>
        <clipPath id="clip0_4624_336067">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default WarningCircle;
