import { REQUIRED_FIELD_MESSAGE } from "constants/keys";

import { validationMethod } from "utils/validation-method";
import * as yup from "yup";

export const fieldPhoneRequired = yup
  .mixed()
  .test(
    "correctPhone",
    "Телефон должен быть в формате +7 ххх ххх хх хх",
    validationMethod.correctPhone
  )
  .test(
    "requiredPhone",
    REQUIRED_FIELD_MESSAGE,
    validationMethod.requiredPhone
  );
