import { PRODUCT_SUPPORT_URL } from "constants/urls";

import { MTSIcon } from "components/mts-icon";
import { MTSLink } from "components/mts-link";

import classes from "./mobile-menu-support.module.css";

export const MobileMenuSupport = (): JSX.Element => {
  return (
    <MTSLink
      to={{
        pathname: PRODUCT_SUPPORT_URL,
      }}
      target="_blank"
    >
      <span className={classes.blockMobileMenuSupport}>
        <MTSIcon.QuestionCircle
          sx={{
            fontSize: "24px",
            color: "var(--color-icon-primary)",
          }}
        />
        <span className={classes.text}>Поддержка</span>
      </span>
    </MTSLink>
  );
};
