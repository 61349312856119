import React from "react";

import { useInView } from "react-intersection-observer";

import { BlockContent } from "components/block-content";
import {
  GAE_ACTION_GROUP,
  GAE_EVENT,
  GAE_EVENT_ACTION,
  GAE_EVENT_CATEGORY,
  GAE_EVENT_LABEL,
  useGoogleAnalyticsEvents,
} from "hooks/google-analytics";

import { CropsIcon } from "./icons/crops-icon";
import { HarvestIcon } from "./icons/harvest-icon";
import { OverlapGapsIcon } from "./icons/overlap-gaps-icon";
import { TechniqueIcon } from "./icons/technique-icon";
import classes from "./problems.module.css";

export const Problems = React.memo((): JSX.Element => {
  const { sendGAE } = useGoogleAnalyticsEvents();
  const { ref: agroBlockProblemsRef, inView: inViewAgroBlockProblems } =
    useInView({
      initialInView: false,
      threshold: 0.5,
      triggerOnce: true,
    });

  React.useEffect(() => {
    if (inViewAgroBlockProblems) {
      sendGAE({
        id: 63,
        event: GAE_EVENT.vntZeml,
        eventCategory: GAE_EVENT_CATEGORY.tochnoe_zemledelie,
        eventAction: GAE_EVENT_ACTION.block_show,
        eventLabel: GAE_EVENT_LABEL.kakie_problemy_vy_reshite,
        eventValue: null,
        eventContent: null,
        buttonLocation: null,
        filterName: null,
        actionGroup: GAE_ACTION_GROUP.non_interactions,
        productName: null,
      });
    }
  }, [inViewAgroBlockProblems, sendGAE]);

  return (
    <div ref={agroBlockProblemsRef} className={classes.blockProblems}>
      <BlockContent sx={{ alignSelf: "center" }}>
        <h2 className={classes.title}>
          Какие проблемы <span style={{ whiteSpace: "nowrap" }}>вы решите</span>
        </h2>

        <ul className={classes.list}>
          <li className={classes.listItem}>
            <OverlapGapsIcon sx={{ fontSize: "96px", color: "transparent" }} />

            <p className={classes.listItemText}>
              Пропуски и перекрытия <br />
              при обработке полей сельхозтехникой
            </p>
          </li>
          <li className={classes.listItem}>
            <HarvestIcon sx={{ fontSize: "96px", color: "transparent" }} />

            <p className={classes.listItemText}>Недобор урожая</p>
          </li>
          <li className={classes.listItem}>
            <CropsIcon sx={{ fontSize: "96px", color: "transparent" }} />

            <p className={classes.listItemText}>
              Перерасход семян <br />и удобрений при посевах
            </p>
          </li>
          <li className={classes.listItem}>
            <TechniqueIcon sx={{ fontSize: "96px", color: "transparent" }} />

            <p className={classes.listItemText}>
              Простой <br />
              сельхозтехники
            </p>
          </li>
        </ul>
      </BlockContent>
    </div>
  );
});
