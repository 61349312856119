import { IS_DEVELOPMENT_MODE } from "constants/env";
import { PROFILE_ROLE } from "constants/keys";
import {
  PATH_PERSONAL_AREA,
  PATH_ERROR,
  PATH_MAP,
  PATH_PUBLIC_OFFER,
  PATH_INDUSTRY,
  PATH_TEST_PAGE,
} from "constants/routes";

import React from "react";

import { Switch, Route, Redirect } from "react-router-dom";

import { DialogOk } from "components/dialog-ok";
import { Header } from "components/header";
import { MobileBar } from "components/mobile-bar";
import { NotificationBar } from "components/notification-bar";
import { ErrorPage, ErrorPageVariant } from "components/pages/error-page";
import { Industry } from "components/pages/industry";
import { Landing } from "components/pages/landing";
import { MapPage } from "components/pages/map-page";
import { PersonalArea } from "components/pages/personal-area";
import { PublicOffer } from "components/pages/public-offer";
import { TestPage } from "components/pages/test-page";
import { PrivateRoute } from "components/private-route";
import { useInitGoogleAnalytics } from "hooks/google-analytics";
import { useTabsByRole } from "hooks/tabs/use-tabs-by-role";
import { useApplicationInfo } from "hooks/use-application-info";
import { useCheckAuthorization } from "hooks/use-check-authorization";
import { useCheckProfile } from "hooks/use-check-profile";
import { useContextMain } from "hooks/use-context-main";
import { useDeviceViewport } from "hooks/use-device-viewport";
import { useDisplayStatus } from "hooks/use-display-status";
import { useHeaderHeight } from "hooks/use-header-height";
import { useScrollToTop } from "hooks/use-scroll-to-top";
import { useSynchronizeAuth } from "hooks/use-synchronize-auth";
import { ContextHeaderProvider } from "store/context-header";

import classes from "./app.module.css";

export const App = (): JSX.Element => {
  const { dialogOk, isAuthorized } = useContextMain();
  const { isMobileVersion } = useDisplayStatus();

  useDeviceViewport();
  useCheckProfile();
  useCheckAuthorization();
  useSynchronizeAuth();
  useTabsByRole();
  useApplicationInfo();
  useInitGoogleAnalytics();
  useScrollToTop();
  useHeaderHeight({
    isAuthorized,
    isMobileVersion,
  });

  return (
    <div className={classes.app}>
      <ContextHeaderProvider>
        <Header isMobile={isMobileVersion} />
      </ContextHeaderProvider>

      <Switch>
        <Route exact path="/">
          <main className={classes.main}>
            <Landing />
          </main>
        </Route>
        <Route exact path={PATH_MAP}>
          <MapPage />
        </Route>
        <Route path={PATH_INDUSTRY}>
          <main className={classes.main}>
            <Industry />
          </main>
        </Route>
        <PrivateRoute
          exact
          path={PATH_PERSONAL_AREA}
          hasAccessRoles={[
            PROFILE_ROLE.CUSTOMER_BASE,
            PROFILE_ROLE.CUSTOMER_ADMIN,
            PROFILE_ROLE.POTENTIAL,
            PROFILE_ROLE.DEALER,
          ]}
        >
          <Redirect to={PATH_MAP} />
        </PrivateRoute>
        <PrivateRoute
          path={PATH_PUBLIC_OFFER}
          exact
          hasAccessRoles={[
            PROFILE_ROLE.POTENTIAL,
            PROFILE_ROLE.CUSTOMER_ADMIN,
            PROFILE_ROLE.CUSTOMER_BASE,
            PROFILE_ROLE.DEALER,
          ]}
        >
          <main className={classes.main}>
            <PublicOffer />
          </main>
        </PrivateRoute>
        <PrivateRoute
          path={`${PATH_PERSONAL_AREA}/:tabName`}
          hasAccessRoles={[
            PROFILE_ROLE.POTENTIAL,
            PROFILE_ROLE.CUSTOMER_ADMIN,
            PROFILE_ROLE.CUSTOMER_BASE,
            PROFILE_ROLE.DEALER,
          ]}
        >
          <main className={classes.main}>
            <PersonalArea />
          </main>
        </PrivateRoute>
        {IS_DEVELOPMENT_MODE && (
          <Route exact path={PATH_TEST_PAGE}>
            <main className={classes.main}>
              <TestPage />
            </main>
          </Route>
        )}
        <Route path={PATH_ERROR} exact>
          <main className={classes.main}>
            <ErrorPage variants={ErrorPageVariant.UNKNOWN_ERROR} />
          </main>
        </Route>
        <Route path="*">
          <main className={classes.main}>
            <ErrorPage variants={ErrorPageVariant.PAGE_NOT_FOUND} />
          </main>
        </Route>
      </Switch>
      <DialogOk {...dialogOk} />

      <NotificationBar />
      {isMobileVersion && <MobileBar />}
    </div>
  );
};
