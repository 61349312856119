import {
  EMAIL_SUPPORT,
  PHONE_SUPPORT,
  PHONE_SUPPORT_MAPPED,
} from "constants/common";
import { PATH_AGRONOMY, PATH_MAIN } from "constants/routes";

import React from "react";

import { useLocation } from "react-router-dom";

import { useInView } from "react-intersection-observer";

import { BlockContent } from "components/block-content";
import { DialogContactUs } from "components/dialog-contact-us";
import { MTSButton } from "components/mts-button";
import { MTSCardCustom } from "components/mts-card-custom";
import {
  GAE_ACTION_GROUP,
  GAE_BUTTON_LOCATION,
  GAE_EVENT,
  GAE_EVENT_ACTION,
  GAE_EVENT_CATEGORY,
  GAE_EVENT_LABEL,
  useGoogleAnalyticsEvents,
} from "hooks/google-analytics";
import { useAppTheme } from "hooks/use-app-theme";

import classes from "./landing-contact-us.module.css";

type ILandingContactUsProps = {
  title?: string;
  description?: string;
};

export const LandingContactUs = React.memo(
  (props: ILandingContactUsProps): JSX.Element => {
    const { title, description } = props;
    const theme = useAppTheme();
    const location = useLocation();
    const pathname = location.pathname;

    const { sendGAE } = useGoogleAnalyticsEvents();

    const { ref: blockContactUsRef, inView: inViewBlockContactUs } = useInView({
      initialInView: false,
      threshold: 0.5,
      triggerOnce: true,
    });

    const [isOpenDialogContactUs, setIsOpenDialogContactUs] =
      React.useState<boolean>(false);

    const handleOpenDialog = (): void => {
      setIsOpenDialogContactUs(true);
    };

    const handleCloseDialog = (): void => {
      setIsOpenDialogContactUs(false);
    };

    const handleSendMetrics = React.useCallback((): void => {
      if (pathname === PATH_AGRONOMY) {
        sendGAE({
          id: 62,
          event: GAE_EVENT.vntZeml,
          eventCategory: GAE_EVENT_CATEGORY.tochnoe_zemledelie,
          eventAction: GAE_EVENT_ACTION.button_click,
          eventLabel: GAE_EVENT_LABEL.obratnyi_zvonok,
          eventValue: null,
          eventContent: null,
          buttonLocation: GAE_BUTTON_LOCATION.screen,
          filterName: null,
          actionGroup: GAE_ACTION_GROUP.interactions,
          productName: null,
        });
      }
    }, [pathname, sendGAE]);

    const onClickByPhoneLink = React.useCallback((): void => {
      if (pathname === PATH_MAIN) {
        sendGAE({
          id: 32,
          event: GAE_EVENT.vntMain,
          eventCategory: GAE_EVENT_CATEGORY.glavnaya,
          eventAction: GAE_EVENT_ACTION.element_click,
          eventLabel: GAE_EVENT_LABEL.phone,
          eventValue: null,
          eventContent: null,
          buttonLocation: GAE_BUTTON_LOCATION.screen,
          filterName: null,
          actionGroup: GAE_ACTION_GROUP.interactions,
          productName: null,
        });
      }
    }, [pathname, sendGAE]);

    const onClickByEmailLink = React.useCallback((): void => {
      if (pathname === PATH_MAIN) {
        sendGAE({
          id: 33,
          event: GAE_EVENT.vntMain,
          eventCategory: GAE_EVENT_CATEGORY.glavnaya,
          eventAction: GAE_EVENT_ACTION.element_click,
          eventLabel: GAE_EVENT_LABEL.email,
          eventValue: null,
          eventContent: null,
          buttonLocation: GAE_BUTTON_LOCATION.screen,
          filterName: null,
          actionGroup: GAE_ACTION_GROUP.interactions,
          productName: null,
        });
      } else if (pathname === PATH_AGRONOMY) {
        sendGAE({
          id: 61,
          event: GAE_EVENT.vntZeml,
          eventCategory: GAE_EVENT_CATEGORY.tochnoe_zemledelie,
          eventAction: GAE_EVENT_ACTION.element_click,
          eventLabel: GAE_EVENT_LABEL.email,
          eventValue: null,
          eventContent: null,
          buttonLocation: GAE_BUTTON_LOCATION.screen,
          filterName: null,
          actionGroup: GAE_ACTION_GROUP.interactions,
          productName: null,
        });
      }
    }, [pathname, sendGAE]);

    React.useEffect(() => {
      if (inViewBlockContactUs) {
        if (pathname === PATH_MAIN) {
          sendGAE({
            id: 47,
            event: GAE_EVENT.vntMain,
            eventCategory: GAE_EVENT_CATEGORY.glavnaya,
            eventAction: GAE_EVENT_ACTION.block_show,
            eventLabel: GAE_EVENT_LABEL.ostalis_voprosy,
            eventValue: null,
            eventContent: null,
            buttonLocation: null,
            filterName: null,
            actionGroup: GAE_ACTION_GROUP.non_interactions,
            productName: null,
          });
        } else if (pathname === PATH_AGRONOMY) {
          sendGAE({
            id: 69,
            event: GAE_EVENT.vntZeml,
            eventCategory: GAE_EVENT_CATEGORY.tochnoe_zemledelie,
            eventAction: GAE_EVENT_ACTION.block_show,
            eventLabel: GAE_EVENT_LABEL.svyazhites_s_nami,
            eventValue: null,
            eventContent: null,
            buttonLocation: null,
            filterName: null,
            actionGroup: GAE_ACTION_GROUP.non_interactions,
            productName: null,
          });
        }
      }
    }, [inViewBlockContactUs, sendGAE, pathname]);

    return (
      <>
        <div className={classes.blockLandingContactUs} ref={blockContactUsRef}>
          <BlockContent>
            <MTSCardCustom sx={{ width: "100%" }}>
              <div className={classes.card}>
                <div className={classes.cardHeader}>
                  <h2 className={classes.cardHeaderText}>
                    {title || (
                      <>
                        Свяжитесь&nbsp;
                        <span style={{ whiteSpace: "nowrap" }}>с нами</span>
                      </>
                    )}
                  </h2>

                  <p className={classes.cardHeaderDesc}>
                    {description || "И мы вместе подберём подходящее решение"}
                  </p>
                </div>

                <div className={classes.cardContacts}>
                  <div className={classes.cardContactsItems}>
                    <a
                      className={classes.cardContactsItemsLinkPhone}
                      href={`tel:${PHONE_SUPPORT}`}
                      onClick={onClickByPhoneLink}
                    >
                      {PHONE_SUPPORT_MAPPED}
                    </a>

                    <a
                      className={classes.cardContactsItemsLinkEmail}
                      href={`mailto:${EMAIL_SUPPORT}`}
                      onClick={onClickByEmailLink}
                    >
                      {EMAIL_SUPPORT}
                    </a>
                  </div>
                </div>

                <div className={classes.cardButton}>
                  <MTSButton
                    size="L"
                    variant="primary"
                    noWrap
                    sx={{
                      width: "100%",
                      [theme.breakpoints.between("tablet_768", "desktopS_960")]:
                        {
                          padding: "14px 10px !important",
                        },
                      [theme.breakpoints.down("tablet_768")]: {
                        width: "292px",
                      },
                      "@media (max-width: 500px)": {
                        width: "100%",
                      },
                    }}
                    onClick={(e) => {
                      handleOpenDialog();
                      handleSendMetrics();
                    }}
                  >
                    Обратный звонок
                  </MTSButton>
                </div>
              </div>
            </MTSCardCustom>
          </BlockContent>
        </div>
        <DialogContactUs
          isOpen={isOpenDialogContactUs}
          handleCloseDialog={handleCloseDialog}
          feedbackType="CALL_REQUEST"
          title="Обратный звонок"
        />
      </>
    );
  }
);
