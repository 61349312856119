import React from "react";

import classes from "./tariff-card-header.module.css";

interface ITariffCardHeaderProps {
  title: string | JSX.Element;
}

export const TariffCardHeader = React.memo(
  (props: ITariffCardHeaderProps): JSX.Element => {
    const { title } = props;

    return <h3 className={classes.blockTariffCardHeader}>{title}</h3>;
  }
);
