import React from "react";

import { useHistory } from "react-router-dom";

import Accordion, { AccordionProps } from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { styled } from "@mui/material/styles";
import classNames from "classnames";
import { MTSIcon } from "components/mts-icon";
import { IMTSTabType } from "components/mts-tabs";

import classes from "./mobile-menu-nav.module.css";

const CustomAccordion = styled((props: AccordionProps) => (
  <Accordion disableGutters elevation={0} square {...props} />
))(() => ({
  "&:before": {
    display: "none",
  },
}));

interface IMobileMenuNavProps {
  onClickLink: () => void;
  data: any[];
}

const RenderLink = ({
  onClickLink,
  label,
  tabType,
}: {
  onClickLink: () => void;
  label: string;
  tabType: IMTSTabType;
}): JSX.Element => {
  const isLinkChild: boolean = tabType === "link-child";

  const navLinkStyles = classNames({
    [classes.navLink]: true,
    [classes.navLinkChild]: isLinkChild,
  });
  const navLinkTextStyles = classNames({
    [classes.navLinkText]: true,
    [classes.navLinkTextChild]: isLinkChild,
  });

  return (
    <div className={navLinkStyles} onClick={onClickLink}>
      <span className={navLinkTextStyles}>{label}</span>
    </div>
  );
};

export const MobileMenuNav = (props: IMobileMenuNavProps): JSX.Element => {
  const { onClickLink, data } = props;
  const history = useHistory();

  const [openTabId, setOpenTabId] = React.useState<string | false>(false);

  const handleChange =
    (tabId: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setOpenTabId(isExpanded ? tabId : false);
    };

  const handleRedirect = (href: string) => {
    onClickLink();
    history.push(href);
  };

  return (
    <div className={classes.blockMobileMenuNav}>
      {data.map((tabData) => {
        const tabId = tabData.id;
        const tabName = tabData.name;
        const tabHref = tabData?.href || "";
        const tabType = tabData.type;
        const isLinkChild: boolean = tabType === "link-child";

        const navLinkTextStyles = classNames({
          [classes.navLinkText]: true,
          [classes.navLinkTextChild]: isLinkChild,
        });

        if (tabType === "folder" && tabData?.children?.length) {
          return (
            <CustomAccordion
              key={tabId}
              expanded={openTabId === tabId}
              onChange={handleChange(tabId)}
            >
              <AccordionSummary
                expandIcon={
                  <MTSIcon.ArrowDown
                    sx={{
                      fontSize: "32px",
                      color: "var(--color-icon-primary)",
                    }}
                  />
                }
                sx={{
                  height: "48px",
                  margin: "0px 0px",
                  padding: "0px 20px",
                  "& .MuiAccordionSummary-content": {
                    margin: "0",
                  },
                }}
              >
                <span className={navLinkTextStyles}>{tabName}</span>
              </AccordionSummary>
              <AccordionDetails sx={{ display: "flex", padding: "0px 0px" }}>
                <MobileMenuNav
                  onClickLink={onClickLink}
                  data={tabData.children}
                />
              </AccordionDetails>
            </CustomAccordion>
          );
        }

        if ((tabType === "link" || tabType === "link-child") && tabHref) {
          return (
            <RenderLink
              key={tabId}
              onClickLink={() => handleRedirect(tabHref)}
              label={tabName}
              tabType={tabType}
            />
          );
        }

        return null;
      })}
    </div>
  );
};
