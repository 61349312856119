import React from "react";

import { IDictionaryDTO } from "interfaces";

import { useBaseStations } from "./use-base-stations";

export const useBaseStationsAsDictionary = () => {
  const query = useBaseStations({ enabled: true });

  const data: IDictionaryDTO[] = React.useMemo(() => {
    if (query.data) {
      const dictionaryData = query.data
        .map((station) => {
          return {
            id: station.id,
            code: station.name,
            name: station.mountPoint,
            description: "",
          };
        })
        .sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }

          return 0;
        });

      return dictionaryData;
    }

    return [];
  }, [query.data]);

  return {
    ...query,
    data,
  };
};
