import {
  PATH_INSTRUCTION_CARDS,
  PATH_SUBSCRIPTION_INFO_PERSONAL,
  PATH_SETTINGS,
  PATH_COMPANY_INFO,
} from "constants/routes";

import { Redirect, Route, Switch } from "react-router-dom";

import { ContainerContent } from "components/container-content";
import { FooterOfPersonalArea } from "components/footer-of-personal-area";
import { Loader } from "components/loader";
import { PageTitle } from "components/page-title";
import { useTabNames } from "hooks/tabs/use-tab-names";
import { useAccessStatusDownloadRinex } from "hooks/use-access-status-download-rinex";

import { CompanyInfo } from "./components/company-info";
import { InsructionCards } from "./components/instruction-cards";
import { PersonalSubscriptionInfo } from "./components/personal-subscription-info";
import { TabBarInSettingsPage } from "./components/tabbar-in-settings-page";

export const TabSettings = (): JSX.Element => {
  const {
    data: accessStatusDownloadRinex,
    isLoading: isLoadingAccessStatusDownloadRinex,
  } = useAccessStatusDownloadRinex();
  const { tabNames } = useTabNames();

  if (isLoadingAccessStatusDownloadRinex) {
    return <Loader />;
  }

  return (
    <>
      <ContainerContent>
        <PageTitle text={tabNames.settings || ""} />

        <TabBarInSettingsPage
          isCanDownloadRinex={accessStatusDownloadRinex?.allowed ? true : false}
        />

        <Switch>
          <Route path={PATH_SETTINGS} exact>
            <Redirect to={PATH_COMPANY_INFO} />
          </Route>
          <Route path={PATH_COMPANY_INFO} exact>
            <CompanyInfo />
          </Route>
          <Route path={PATH_SUBSCRIPTION_INFO_PERSONAL} exact>
            <PersonalSubscriptionInfo />
          </Route>
          <Route path={PATH_INSTRUCTION_CARDS} exact>
            <InsructionCards />
          </Route>
        </Switch>
      </ContainerContent>
      <FooterOfPersonalArea />
    </>
  );
};
