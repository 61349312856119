import { REQUIRED_FIELD_MESSAGE } from "constants/keys";

import * as yup from "yup";

export const fieldEmailRequired = yup
  .string()
  .required(REQUIRED_FIELD_MESSAGE)
  .trim()
  .matches(
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
    "Email должен быть в формате user@company.ru"
  );
