import { MTSSpinner } from "components/mts-spinner";

import classes from "./loader.module.css";

export const Loader = (): JSX.Element => {
  return (
    <div className={classes.loader}>
      <MTSSpinner size="L" color="black" />
    </div>
  );
};
