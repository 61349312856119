import { PATH_MAP } from "constants/routes";

import React from "react";

import { useHistory } from "react-router-dom";

import { useInView } from "react-intersection-observer";

import { useMediaQuery } from "@mui/material";
import { BlockContent } from "components/block-content";
import { BlockWide } from "components/block-wide";
import { MTSButton } from "components/mts-button";
import { MTSCardCustom } from "components/mts-card-custom";
import { MTSLink } from "components/mts-link";
import {
  GAE_ACTION_GROUP,
  GAE_BUTTON_LOCATION,
  GAE_EVENT,
  GAE_EVENT_ACTION,
  GAE_EVENT_CATEGORY,
  GAE_EVENT_LABEL,
  useGoogleAnalyticsEvents,
} from "hooks/google-analytics";
import { useAppTheme } from "hooks/use-app-theme";

import { ElectroIcon } from "./icons/electro-icon";
import { ITIcon } from "./icons/it-icon";
import { NetworkIcon } from "./icons/network-icon";
import { PersonalAreaIcon } from "./icons/personal-area-icon";
import { ServiceIcon } from "./icons/service-icon";
import classes from "./resolve-cases.module.css";

const infoWithCorrectiveAmendmends: string[] = [
  "Качественная сеть в 14 регионах РФ",
  "Точность геопозиционирования до 2-5 см",
  "Строительство сети под запросы клиента",
];

const intergartionMTSLinks: {
  label: string;
  href: string;
}[] = [
  {
    label: "Контроль полевых сотрудников и сезонного персонала",
    href: "https://www.mpoisk.ru/#employees",
  },
  {
    label: "Контроль автопарка и наёмного транспорта",
    href: "https://www.mpoisk.ru/#transport",
  },
  {
    label: "Контроль ГСМ",
    href: "https://www.mpoisk.ru/industry/transport-and-logistics",
  },
  {
    label: "Анализ манеры вождения",
    href: "https://www.mpoisk.ru/transport/score",
  },
  {
    label: "Удалённый мониторинг объектов инфраструктуры",
    href: "https://moskva.mts.ru/business/internet-veshhej-iot/iot-platforma",
  },
  {
    label: "Контроль температуры и влажности",
    href: "https://moskva.mts.ru/business/internet-veshhej-iot/iot-platforma",
  },
  {
    label: "Контроль заполнения резервуаров, бункеров, силосов",
    href: "https://moskva.mts.ru/business/internet-veshhej-iot/iot-platforma",
  },
  {
    label: "Технический учёт потребления воды, электроэнергии, газа",
    href: "https://moskva.mts.ru/business/internet-veshhej-iot/iot-platforma",
  },
];

const infoWithSpecialTechnique: (string | JSX.Element)[] = [
  "Поставка специального оборудования",
  "Аренда спецтехники с последующим выкупом",
  "Поддержка установленного оборудования",
  "Подбор и комплектация",
  <>Trade-in: продажа нового оборудования с&nbsp;зачётом старого</>,
  <>Сервисная поддержка уже установлен&shy;ной и&nbsp;новой спецтехники</>,
];

const infoWithAeroPhotography: (string | JSX.Element)[] = [
  "Аэрофотосъемка/мультиспектральная аэросъемка",
  <>Рисовка векторных карт полей по&nbsp;орто&shy;фото&shy;плану</>,
  <>Формирование ортофотоплана и&nbsp;цифровой модели рельефа</>,
  "Формирование карты вегетационных индексов",
  "Формирование производной продукции по цифровой модели рельефа – карты крутизны склонов, карты экспозиций склонов, карта весенних водотоков и др.",
];

const infoWithRemoteMonitoring: (string | JSX.Element)[] = [
  <>Агроскаутинг полей беспилотником с&nbsp;мульти&shy;спектральной камерой</>,
  "Инвентаризация земель с/х назначения",
];

const infoWithFieldProcessing: (string | JSX.Element)[] = [
  <>Обработка угодий средствами защиты растений с&nbsp;применением агродрона</>,
  "Внесение удобрений",
];

export const ResolveCases = React.memo((): JSX.Element => {
  const theme = useAppTheme();
  const history = useHistory();
  const isMinWidth_1440 = useMediaQuery(theme.breakpoints.up("desktopM_1440"));
  const isMinWidth_960 = useMediaQuery(theme.breakpoints.up("desktopS_960"));
  const isMinWidth_768 = useMediaQuery(theme.breakpoints.up("tablet_768"));

  const { sendGAE } = useGoogleAnalyticsEvents();

  const { ref: agroBlockResolveRef, inView: inViewAgroBlockResolve } =
    useInView({
      initialInView: false,
      threshold: 0.5,
      triggerOnce: true,
    });

  const handleRedirectToMap = (): void => {
    history.push(PATH_MAP);
  };

  const handleSendMetrics = (name?: string, index?: number): void => {
    if (name) {
      let _eventLabel;
      index === 1
        ? (_eventLabel =
            GAE_EVENT_LABEL.kontrol_avtoparka_i_naemnogo_transporta)
        : index === 2
        ? (_eventLabel = GAE_EVENT_LABEL.kontrol_gsm)
        : index === 3
        ? (_eventLabel = GAE_EVENT_LABEL.analiz_manery_vozhdeniya)
        : index === 4
        ? (_eventLabel =
            GAE_EVENT_LABEL.udalennyi_monitoring_obektov_infrastruktury)
        : index === 5
        ? (_eventLabel = GAE_EVENT_LABEL.kontrol_temperatury_i_vlazhnosti)
        : index === 6
        ? (_eventLabel =
            GAE_EVENT_LABEL.kontrol_zapolneniya_rezervuarov_bunkerov_silosov)
        : index === 7
        ? (_eventLabel =
            GAE_EVENT_LABEL.tehnicheskii_uchet_potrebleniya_vody_elektroenergii_gaza)
        : (_eventLabel =
            GAE_EVENT_LABEL.kontrol_polevyh_sotrudnikov_i_sezonnogo_personala);

      sendGAE({
        id: 57,
        event: GAE_EVENT.vntZeml,
        eventCategory: GAE_EVENT_CATEGORY.tochnoe_zemledelie,
        eventAction: GAE_EVENT_ACTION.link_click,
        eventLabel: _eventLabel,
        eventValue: null,
        eventContent: null,
        buttonLocation: GAE_BUTTON_LOCATION.screen,
        filterName: null,
        actionGroup: GAE_ACTION_GROUP.interactions,
        productName: null,
      });
    } else {
      sendGAE({
        id: 56,
        event: GAE_EVENT.vntZeml,
        eventCategory: GAE_EVENT_CATEGORY.tochnoe_zemledelie,
        eventAction: GAE_EVENT_ACTION.button_click,
        eventLabel: GAE_EVENT_LABEL.smotret_kartu_pokrytiya,
        eventValue: null,
        eventContent: null,
        buttonLocation: GAE_BUTTON_LOCATION.screen,
        filterName: null,
        actionGroup: GAE_ACTION_GROUP.interactions,
        productName: null,
      });
    }
  };

  React.useEffect(() => {
    if (inViewAgroBlockResolve) {
      sendGAE({
        id: 65,
        event: GAE_EVENT.vntZeml,
        eventCategory: GAE_EVENT_CATEGORY.tochnoe_zemledelie,
        eventAction: GAE_EVENT_ACTION.block_show,
        eventLabel: GAE_EVENT_LABEL.chto_vhodit_v_reshenie,
        eventValue: null,
        eventContent: null,
        buttonLocation: null,
        filterName: null,
        actionGroup: GAE_ACTION_GROUP.non_interactions,
        productName: null,
      });
    }
  }, [inViewAgroBlockResolve, sendGAE]);

  return (
    <div ref={agroBlockResolveRef} className={classes.blockResolveCases}>
      <BlockWide>
        <BlockContent>
          <h2 className={classes.title}>Что входит в&nbsp;решение</h2>

          <div className={classes.blockResolveCasesContent}>
            <div className={classes.cards}>
              <MTSCardCustom
                sx={{
                  padding: isMinWidth_1440
                    ? "56px 32px 72px"
                    : isMinWidth_960
                    ? "40px 32px 72px"
                    : isMinWidth_768
                    ? "32px 24px 72px"
                    : "24px 20px 72px",
                }}
              >
                <div className={classes.cardContent}>
                  <h3 className={classes.cardContentTitle}>
                    КОРРЕКТИРУЮЩИЕ ПОПРАВКИ
                  </h3>

                  <ul className={classes.cardContentList}>
                    {infoWithCorrectiveAmendmends.map(
                      (label: string, index: number) => (
                        <li className={classes.cardContentListItem} key={index}>
                          <p className={classes.cardContentListItemText}>
                            {label}
                          </p>
                        </li>
                      )
                    )}
                  </ul>
                  <MTSButton
                    size="M"
                    variant="primary"
                    sx={{
                      width: "100%",
                      maxWidth: "288px",
                      alignSelf: "center",
                    }}
                    onClick={() => {
                      handleRedirectToMap();
                      handleSendMetrics();
                    }}
                  >
                    Смотреть карту покрытия
                  </MTSButton>
                </div>
              </MTSCardCustom>

              <MTSCardCustom
                sx={{
                  padding: isMinWidth_1440
                    ? "56px 32px 32px"
                    : isMinWidth_960
                    ? "40px 32px 32px"
                    : isMinWidth_768
                    ? "32px 24px 32px"
                    : "24px 20px 24px",
                }}
              >
                <div className={classes.cardContent}>
                  <h3 className={classes.cardContentTitle}>
                    ИНТЕГРАЦИЯ С ЭКОСИСТЕМОЙ МТС
                  </h3>

                  <ul className={classes.cardContentList}>
                    {intergartionMTSLinks.map((linkData, index: number) => (
                      <li className={classes.cardContentListItem} key={index}>
                        <MTSLink
                          to={{
                            pathname: linkData.href,
                          }}
                          target="_blank"
                          onClick={() =>
                            handleSendMetrics(linkData.label, index)
                          }
                        >
                          <p className={classes.cardContentListItemTextLink}>
                            {linkData.label}
                          </p>
                        </MTSLink>
                      </li>
                    ))}
                  </ul>
                </div>
              </MTSCardCustom>

              <MTSCardCustom
                sx={{
                  padding: isMinWidth_1440
                    ? "56px 32px 32px"
                    : isMinWidth_960
                    ? "40px 32px 32px"
                    : isMinWidth_768
                    ? "32px 24px 32px"
                    : "24px 20px 24px",
                }}
              >
                <div className={classes.cardContent}>
                  <h3 className={classes.cardContentTitle}>СПЕЦТЕХНИКА</h3>

                  <ul className={classes.cardContentList}>
                    {infoWithSpecialTechnique.map(
                      (label: string | JSX.Element, index: number) => (
                        <li className={classes.cardContentListItem} key={index}>
                          <p className={classes.cardContentListItemText}>
                            {label}
                          </p>
                        </li>
                      )
                    )}
                  </ul>
                </div>
              </MTSCardCustom>

              <MTSCardCustom
                sx={{
                  padding: isMinWidth_1440
                    ? "56px 32px 32px"
                    : isMinWidth_960
                    ? "40px 32px 32px"
                    : isMinWidth_768
                    ? "32px 24px 32px"
                    : "24px 20px 24px",
                }}
              >
                <div className={classes.cardContent}>
                  <h3 className={classes.cardContentTitle}>
                    АЭРОФОТО&shy;СЪЁМКА
                  </h3>

                  <ul className={classes.cardContentList}>
                    {infoWithAeroPhotography.map(
                      (label: string | JSX.Element, index: number) => (
                        <li className={classes.cardContentListItem} key={index}>
                          <p className={classes.cardContentListItemText}>
                            {label}
                          </p>
                        </li>
                      )
                    )}
                  </ul>
                </div>
              </MTSCardCustom>

              <MTSCardCustom
                sx={{
                  padding: isMinWidth_1440
                    ? "56px 32px 32px"
                    : isMinWidth_960
                    ? "40px 32px 32px"
                    : isMinWidth_768
                    ? "32px 24px 32px"
                    : "24px 20px 24px",
                }}
              >
                <div className={classes.cardContent}>
                  <h3 className={classes.cardContentTitle}>
                    ДИСТАНЦИОННЫЙ МОНИТОРИНГ
                  </h3>

                  <ul className={classes.cardContentList}>
                    {infoWithRemoteMonitoring.map(
                      (label: string | JSX.Element, index: number) => (
                        <li className={classes.cardContentListItem} key={index}>
                          <p className={classes.cardContentListItemText}>
                            {label}
                          </p>
                        </li>
                      )
                    )}
                  </ul>
                </div>
              </MTSCardCustom>

              <MTSCardCustom
                sx={{
                  padding: isMinWidth_1440
                    ? "56px 32px 32px"
                    : isMinWidth_960
                    ? "40px 32px 32px"
                    : isMinWidth_768
                    ? "32px 24px 32px"
                    : "24px 20px 24px",
                }}
              >
                <div className={classes.cardContent}>
                  <h3 className={classes.cardContentTitle}>ОБРАБОТКА ПОЛЕЙ</h3>

                  <ul className={classes.cardContentList}>
                    {infoWithFieldProcessing.map(
                      (label: string | JSX.Element, index: number) => (
                        <li className={classes.cardContentListItem} key={index}>
                          <p className={classes.cardContentListItemText}>
                            {label}
                          </p>
                        </li>
                      )
                    )}
                  </ul>
                </div>
              </MTSCardCustom>
            </div>

            <div className={classes.cases}>
              <div className={classes.case}>
                <ElectroIcon
                  sx={{
                    width: "96px",
                    height: "96px",
                    color: "transparent",
                    [theme.breakpoints.between("tablet_768", "desktopS_1280")]:
                      {
                        width: "76px",
                        height: "76px",
                      },
                  }}
                />

                <h4 className={classes.caseTitle}>
                  Бесперебойное
                  <br />
                  энергоснабжение
                </h4>
              </div>

              <div className={classes.case}>
                <NetworkIcon
                  sx={{
                    width: "96px",
                    height: "96px",
                    color: "transparent",
                    [theme.breakpoints.between("tablet_768", "desktopS_1280")]:
                      {
                        width: "76px",
                        height: "76px",
                      },
                  }}
                />

                <h4 className={classes.caseTitle}>
                  Надёжный
                  <br />
                  интернет
                </h4>
              </div>

              <div className={classes.case}>
                <ITIcon
                  sx={{
                    width: "96px",
                    height: "96px",
                    color: "transparent",
                    [theme.breakpoints.between("tablet_768", "desktopS_1280")]:
                      {
                        width: "76px",
                        height: "76px",
                      },
                  }}
                />

                <h4 className={classes.caseTitle}>
                  Развитая IT-инфраструктура
                </h4>
              </div>

              <div className={classes.case}>
                <ServiceIcon
                  sx={{
                    width: "96px",
                    height: "96px",
                    color: "transparent",
                    [theme.breakpoints.between("tablet_768", "desktopS_1280")]:
                      {
                        width: "76px",
                        height: "76px",
                      },
                  }}
                />

                <h4 className={classes.caseTitle}>
                  Сервисная
                  <br />
                  служба
                </h4>
              </div>

              <div className={classes.case}>
                <PersonalAreaIcon
                  sx={{
                    width: "96px",
                    height: "96px",
                    color: "transparent",
                    [theme.breakpoints.between("tablet_768", "desktopS_1280")]:
                      {
                        width: "76px",
                        height: "76px",
                      },
                  }}
                />

                <h4 className={classes.caseTitle}>
                  Личный
                  <br />
                  кабинет
                </h4>
              </div>
            </div>
          </div>
        </BlockContent>
      </BlockWide>
    </div>
  );
});
