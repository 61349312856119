import {
  fieldCompanyName,
  fieldCompanyNameRequired,
} from "./fields/company-name";
import { fieldDevicePasswordRequired } from "./fields/device-password";
import { fieldEmailRequired } from "./fields/email";
import { fieldFIO, fieldFIORequired } from "./fields/fio";
import { fieldPhoneRequired } from "./fields/phone";
import { fieldRegion, fieldRegionRequired } from "./fields/region";

export const fieldSchema = {
  required: {
    fio: fieldFIORequired,
    devicePassword: fieldDevicePasswordRequired,
    email: fieldEmailRequired,
    phone: fieldPhoneRequired,
    companyName: fieldCompanyNameRequired,
    region: fieldRegionRequired,
  },
  notRequired: {
    fio: fieldFIO,
    companyName: fieldCompanyName,
    region: fieldRegion,
  },
};
