import React from "react";

import Checkbox, { CheckboxProps } from "@mui/material/Checkbox";
import { styled } from "@mui/material/styles";

import { BoxSize } from "./constants";
import { getBoxSize } from "./utils/getBoxSize";
import { getIconBySize } from "./utils/getIconBySize";

type IBoxSize = keyof typeof BoxSize;

interface MTSCheckboxProps extends CheckboxProps {
  boxSize: IBoxSize;
}

export const MTSCheckbox = (props: MTSCheckboxProps) => {
  const { boxSize, ...rest } = props;

  const BpIcon = styled("span")(() => ({
    borderRadius:
      boxSize === BoxSize.medium || boxSize === BoxSize.large ? 6 : 4,
    width: getBoxSize(boxSize),
    height: getBoxSize(boxSize),
    border: "2px solid",
    borderColor: "var(--color-control-inactive)",
    backgroundColor: "var(--color-background-primary)",

    ".Mui-focusVisible &": {
      outline: "1px solid rgba(0, 124, 255, 0.6)",
      borderRadius: 2,
      outlineOffset: 4,
    },
    "input:hover ~ &": {
      backgroundColor: "var(--color-background-primary)",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      borderColor: "transparent",
      background: "var(--color-control-inactive)",
    },
  }));

  const BpCheckedIcon = styled(BpIcon)(({ theme }) => ({
    backgroundColor: "var(--color-brand)",
    border: "2px solid transparent",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: "100%",
      height: "100%",
      backgroundImage: `url(${getIconBySize(boxSize)})`,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "var(--color-brand)",
    },
  }));

  const BpIndeterminateIcon = styled(BpIcon)(({ theme }) => ({
    background: "var(--color-brand)",
    border: "2px solid transparent",
    "&:before": {
      position: "absolute",
      left: "50%",
      top: "50%",
      transform: "translate(-50%, -50%)",
      width:
        boxSize === BoxSize.small
          ? "10px"
          : boxSize === BoxSize.medium
          ? "12px"
          : boxSize === BoxSize.large
          ? "18px"
          : "10px",
      height:
        boxSize === BoxSize.small
          ? "1.5px"
          : boxSize === BoxSize.medium
          ? "2px"
          : boxSize === BoxSize.large
          ? "2.5px"
          : "1.5px",
      background: "var(--color-background-primary)",
      content: "''",
    },
    "input:hover ~ &": {
      backgroundColor: "var(--color-brand)",
    },
  }));

  return (
    <Checkbox
      sx={{
        "&:hover": { bgcolor: "transparent" },
        "&.MuiCheckbox-root": {
          padding: "0px",
        },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      indeterminateIcon={<BpIndeterminateIcon />}
      icon={<BpIcon />}
      inputProps={{ "aria-label": "Checkbox demo" }}
      {...rest}
    />
  );
};
