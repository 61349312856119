import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const Company = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="52"
      height="52"
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M0 26C0 11.6406 11.6406 0 26 0C40.3594 0 52 11.6406 52 26C52 40.3594 40.3594 52 26 52C11.6406 52 0 40.3594 0 26Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32 18.2632H38C39.665 18.2632 41 19.4342 41 20.8947V35.3684C41 36.8289 39.665 38 38 38H14C12.335 38 11 36.8289 11 35.3684L11.015 20.8947C11.015 19.4342 12.335 18.2632 14 18.2632H20V15.6316C20 14.1711 21.335 13 23 13H29C30.665 13 32 14.1711 32 15.6316V18.2632ZM23 18.2632H29V15.6316H23V18.2632Z"
        fill="white"
      />
    </SvgIcon>
  );
};

export default Company;
