import { EMPTY_CELL_VALUE } from "constants/keys";

import React from "react";

import Tooltip from "@mui/material/Tooltip";

import classes from "./tooltip-cell-button.module.css";

interface ITooltipCellButtonProps {
  text: string;
  cellWidth: number;
  onClick: () => void;
  dataTestId?: string | null;
}

export const TooltipCellButton = React.memo(
  (props: ITooltipCellButtonProps) => {
    const { text, cellWidth = 0, onClick, dataTestId } = props;

    const textElementRef = React.useRef<HTMLInputElement | null>(null);
    const [isOverflow, setIsOverflow] = React.useState<boolean>(false);

    React.useEffect(() => {
      if (textElementRef?.current && cellWidth) {
        const scrollWidth: number = textElementRef?.current?.scrollWidth || 0;

        setIsOverflow(scrollWidth > cellWidth);
      }
    }, [cellWidth]);

    return (
      <Tooltip
        title={text}
        arrow
        disableHoverListener={!isOverflow}
        disableInteractive
      >
        <button
          className={classes.btn}
          style={{
            width: "auto",
            maxWidth: cellWidth > 14 ? cellWidth - 14 : undefined,
          }}
          onClick={onClick}
          data-testid={dataTestId || ""}
        >
          <span ref={textElementRef} className={classes.btnText}>
            {text ? text : EMPTY_CELL_VALUE}
          </span>
        </button>
      </Tooltip>
    );
  }
);
