import { DISCOUNT_TYPE } from "constants/keys";

import { getDiscountDescription } from "components/payment-configurator/utils/get-discount-description";
import { IDiscountDTO } from "interfaces";
import { getFormattedNumberWithThousandSeparator } from "utils/get-formatted-number-with-thousand-separator";

import classes from "./payment-configurator-discounts.module.css";

interface IPaymentConfiguratorDiscountsProps {
  discounts: IDiscountDTO[];
  regionDiscount: number;
  subscriptionsDiscount: number;
}

export const PaymentConfiguratorDiscounts = (
  props: IPaymentConfiguratorDiscountsProps
): JSX.Element | null => {
  const { discounts, regionDiscount, subscriptionsDiscount } = props;

  if (discounts?.length === 0) {
    return null;
  }

  return (
    <div className={classes.block}>
      <h4 className={classes.blockTitle} data-testid="txt_head_discount">
        Скидки
      </h4>

      <ul className={classes.list}>
        {discounts.map((discountData: IDiscountDTO, index: number) => {
          const discountPercent: number | undefined = discountData?.percent;
          const discountType: string | undefined = discountData?.type;
          let discountNumber: number = 0;

          switch (discountType) {
            case DISCOUNT_TYPE.REGION_DISCOUNT:
              discountNumber = regionDiscount;
              break;
            case DISCOUNT_TYPE.SUBSCRIPTION_COUNT_DISCOUNT:
              discountNumber = subscriptionsDiscount;
              break;
            default:
              break;
          }

          return (
            <li className={classes.listItem} key={index}>
              <div className={classes.discountInfo}>
                <p
                  className={classes.discountInfoText}
                  data-testid="txt_discount_percentage"
                >
                  -{discountPercent}%
                </p>
                <p
                  className={classes.discountInfoText}
                  style={{ whiteSpace: "nowrap" }}
                  data-testid="txt_discount_rub"
                >
                  {getFormattedNumberWithThousandSeparator({
                    value: discountNumber,
                    showFractionDigits: true,
                  })}
                  &nbsp;₽
                </p>
              </div>
              <p
                className={classes.discountType}
                data-testid="txt_hint_discount"
              >
                {getDiscountDescription(discountType)}
              </p>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
