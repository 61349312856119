import React from "react";

import { IDictionaryDTO } from "interfaces";
import { mockData } from "services/mock-data";

export const useRinexFrequencies = (): { data: IDictionaryDTO[] } => {
  const rinexFrequencies = React.useMemo(() => mockData.rinexFrequencies, []);

  return { data: rinexFrequencies };
};
