import React from "react";

import { MTSButton } from "components/mts-button";

import classes from "./error-boundary-info.module.css";

interface IErrorBoundaryInfoProps {
  error: Error | null;
  errorInfo: React.ErrorInfo | null;
}

export const ErrorBoundaryInfo = (
  props: IErrorBoundaryInfoProps
): JSX.Element => {
  const { error, errorInfo } = props;

  const handleClick = (): void => {
    let url: string = "/";

    window.location.href = url;
  };

  return (
    <div className={classes.block}>
      <div className={classes.content}>
        <div className={classes.contentInfo}>
          <h4 className={classes.contentInfoTitle}>Что-то пошло не так</h4>
          <p className={classes.contentInfoText}>
            Попробуйте обновить страницу позже
          </p>
          {!!error && (
            <p className={classes.contentInfoErrorText}>{error.toString()}</p>
          )}
          {!!errorInfo && (
            <p className={classes.contentText}>{errorInfo.componentStack}</p>
          )}
        </div>

        <div className={classes.contentBtn}>
          <MTSButton onClick={handleClick} size="M" sx={{ minWidth: "227px" }}>
            На главную
          </MTSButton>
        </div>
      </div>
    </div>
  );
};
