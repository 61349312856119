import { QueryKeys } from "constants/keys";

import { useQuery } from "react-query";

import { AxiosError } from "axios";
import { useNotifications } from "hooks/use-notifications";
import { getAllBaseStations } from "services/api";

interface IUseBaseStationsProps {
  enabled: boolean;
}

export const useBaseStations = (props: IUseBaseStationsProps) => {
  const { enabled } = props;
  const { addErrorMessage } = useNotifications();

  return useQuery([QueryKeys.BASE_STATIONS], () => getAllBaseStations(), {
    enabled,
    retry: 0,
    refetchOnWindowFocus: false,
    onError: (error: AxiosError) => {
      addErrorMessage({
        message: "Не удалось получить данные по базовым станциям",
      });
    },
  });
};
