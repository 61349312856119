type IStandNames = "PROD" | "PRODLIKE" | "TEST" | "DEV" | "";

export const IS_DEVELOPMENT_MODE = process.env.NODE_ENV === "development";

export const STAND_NAME = (process.env.REACT_APP_STAND_NAME ||
  "") as IStandNames;
export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || "";
export const FRONTEND_URL = process.env.REACT_APP_FRONTEND_URL || "";

export const MTS_LOGOUT_URL = process.env.REACT_APP_MTS_LOGOUT_URL || "";

export const PROFILE_WIDGET_ENV =
  process.env.REACT_APP_PROFILE_WIDGET_ENV || "";

export const EGIS_AUTH_KEY = process.env.REACT_APP_EGIS_AUTH_KEY || "";
