import React from "react";

import { Controller, useForm, useWatch } from "react-hook-form";

import { MTSAutocompleteSearch } from "components/mts-autocomplete-search";
import { IDictionaryDTO } from "interfaces";

const _fieldNames = {
  search: "search",
};

type ISearchInputProps = {
  options: IDictionaryDTO[];
  setSearchValue: (value: any) => void;
};

export const SearchInput = (props: ISearchInputProps): JSX.Element => {
  const { options, setSearchValue } = props;
  const { control } = useForm({
    defaultValues: {
      [_fieldNames.search]: null,
    },
  });

  const searchValue = useWatch({
    control: control,
    name: _fieldNames.search,
  });

  React.useEffect(() => {
    if (typeof searchValue === "object") {
      setSearchValue(searchValue);
    } else {
      setSearchValue(null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  return (
    <form
      style={{ width: "100%" }}
      autoComplete="off"
      onSubmit={(e: React.FormEvent) => {
        e.preventDefault();
      }}
    >
      <Controller
        control={control}
        name={_fieldNames.search}
        render={(renderProps) => {
          const { field, fieldState } = renderProps;
          const { onChange, value } = field;
          const errorMessage: string = fieldState?.error?.message || "";

          return (
            <MTSAutocompleteSearch
              required
              size="m"
              placeholder="Название, адрес станции"
              value={value}
              onChangeValue={onChange}
              options={options || []}
              errorMessage={errorMessage}
              dataTestId="fld_st_search"
              hideLabel
            />
          );
        }}
      />
    </form>
  );
};
