import { FIELD_NAMES, SUBSCRIPTION_SERVICE_KINDS } from "constants/keys";
import {
  PATH_ADD_SUBSCRIPTIONS,
  PATH_PERSONAL_AREA,
  SEARCH_PARAM_PAY_GROUPS_IN_PAYMENT_CONFIGURATOR,
} from "constants/routes";
import { DEBOUNCE_REQUEST_TIME } from "constants/server";

import React from "react";

import { useHistory, useLocation } from "react-router-dom";

import { Controller, useForm, useWatch } from "react-hook-form";

import { CrossedOutText } from "components/crossed-out-text";
import { InfoIcon } from "components/info-icon";
import { MTSAutocomplete } from "components/mts-autocomplete";
import { MTSBadge } from "components/mts-badge";
import { MTSButton } from "components/mts-button";
import { MTSSlider } from "components/mts-slider";
import {
  GAE_ACTION_GROUP,
  GAE_BUTTON_LOCATION,
  GAE_EVENT,
  GAE_EVENT_ACTION,
  GAE_EVENT_CATEGORY,
  GAE_EVENT_LABEL,
  GAE_PRODUCT_NAME,
  GA_SCREEN_NAME,
  useGoogleAnalyticsEvents,
} from "hooks/google-analytics";
import { useTabNames } from "hooks/tabs/use-tab-names";
import { useAppTheme } from "hooks/use-app-theme";
import { useContextMain } from "hooks/use-context-main";
import { useDebounce } from "hooks/use-debounce";
import { useMutationPaymentCalculations } from "hooks/use-mutation-payment-calculations";
import { useProfileData } from "hooks/use-profile-data";
import { useQuantityDiscounts } from "hooks/use-quantity-discounts";
import {
  ICalculationDTO,
  IPayGroupDTOPayload,
  IQuantityDiscountStruct,
  ITariffSliderValue,
} from "interfaces";
import { mockData } from "services/mock-data";
import { generateId } from "utils/generate-id";
import { getDurationTranslit } from "utils/get-duration-translit.ts";
import { getFormattedNumberWithThousandSeparator } from "utils/get-formatted-number-with-thousand-separator";

import { TariffCardHeader } from "./components/tariff-card-header";
import {
  ITariffCardOption,
  TariffCardOptions,
} from "./components/tariff-card-options";
import classes from "./tarrif-card.module.css";

interface ITariffCardProps {
  isPersonalAreaPage: boolean;
  serviceKindType:
    | SUBSCRIPTION_SERVICE_KINDS.RINEX
    | SUBSCRIPTION_SERVICE_KINDS.RTK_RINEX;
}

const InfoIconRTK = () => (
  <InfoIcon text="RTK -  режим определения текущего местоположения в реальном времени с сантиметровой точностью за счёт поправок с референсных станций" />
);

const InfoIconRINEX = () => (
  <InfoIcon text="RINEX – формат файлов для постобработки данных спутниковых навигационных приёмников для более точных вычислений местоположения" />
);

const optionsRTK_RINEX: ITariffCardOption[] = [
  "RTK-поправки в реальном времени",
  "RINEX-файлы с дискретностью до 1 с",
  "Скидка за количество подписок",
];

const optionsRINEX: ITariffCardOption[] = [
  "RINEX-файлы с дискретностью до 1 с",
  "Скидка за количество подписок",
];

export const TariffCard = (props: ITariffCardProps): JSX.Element => {
  const { isPersonalAreaPage, serviceKindType } = props;
  const theme = useAppTheme();
  const history = useHistory();
  const location = useLocation();

  const { sendGAE } = useGoogleAnalyticsEvents();
  const { isAuthorized } = useContextMain();
  const quantityDiscounts = useQuantityDiscounts();
  const { isCustomerBaseRole } = useProfileData();
  const { tabNames } = useTabNames();

  const mutationPaymentCalculations = useMutationPaymentCalculations();
  const { control } = useForm({
    defaultValues: {
      [FIELD_NAMES.TARIFF_CATEGORY]: quantityDiscounts[0],
    },
  });
  const selectedCategory = useWatch({
    control: control,
    name: FIELD_NAMES.TARIFF_CATEGORY,
  });
  const [tariffSliderValues] = React.useState<ITariffSliderValue[]>(
    mockData.tariffSliderValues
  );
  const [options] = React.useState<ITariffCardOption[]>(
    serviceKindType === SUBSCRIPTION_SERVICE_KINDS.RTK_RINEX
      ? optionsRTK_RINEX
      : optionsRINEX
  );
  const [sliderPosition, setSliderPosition] = React.useState<number>(
    tariffSliderValues[2].value
  );
  const [sliderPositionDebouce, setSliderPositionDebounce] =
    React.useState<number>(sliderPosition);

  const changeSliderPosition = useDebounce(() => {
    setSliderPositionDebounce(sliderPosition);
  }, DEBOUNCE_REQUEST_TIME);

  const [calculations, setCalculations] =
    React.useState<ICalculationDTO | null>(null);

  const amountBeforeDiscount: number = calculations?.amountBeforeDiscount ?? 0;
  const amountWithDiscounts: number = calculations?.amount ?? 0;
  const discountTotal: number | undefined = Array.isArray(
    calculations?.discounts
  )
    ? calculations?.discounts?.reduce((total, item) => total + item.percent, 0)
    : 0;
  const isServiceKindRTKRINEX: boolean =
    serviceKindType === SUBSCRIPTION_SERVICE_KINDS.RTK_RINEX;
  const isServiceKindRINEX: boolean =
    serviceKindType === SUBSCRIPTION_SERVICE_KINDS.RINEX;

  const handleRedirect = (url: string): void => {
    history.push(url, {
      from: {
        pageName: tabNames.tariff,
        pathname: location.pathname,
      },
    });
  };

  const handleClick = (): void => {
    if (selectedCategory) {
      const data: IPayGroupDTOPayload[] = [
        {
          subscriptionsCount: Number(selectedCategory.description),
          serviceKind: serviceKindType,
          duration: tariffSliderValues[sliderPosition].duration,
          payGroupId: generateId(),
        },
      ];
      const encodeData = encodeURIComponent(btoa(JSON.stringify(data)));
      let redirectURL: string = `${PATH_ADD_SUBSCRIPTIONS}?${SEARCH_PARAM_PAY_GROUPS_IN_PAYMENT_CONFIGURATOR}=${encodeData}`;

      const selectedCategoryValue: null | IQuantityDiscountStruct =
        Number.isNaN(selectedCategory.id)
          ? null
          : mockData.quantityDiscounts[selectedCategory.id];
      const subscriptionCountTranslit: string = `${selectedCategoryValue?.minCounts}_${selectedCategoryValue?.maxCounts}_podpisok`;
      const serviceKindTranslit: string = serviceKindType.toLocaleLowerCase();
      const durationTranslit: string = getDurationTranslit(
        tariffSliderValues[sliderPositionDebouce].duration ?? ""
      );
      const filterNameTranslit: string = `${subscriptionCountTranslit}_${serviceKindTranslit}_${durationTranslit}`;
      const eventValue: string =
        amountWithDiscounts && !Number.isNaN(amountWithDiscounts)
          ? String(Math.round(amountWithDiscounts))
          : "0";

      // NOTE: Страница с лэндинга
      if (!isPersonalAreaPage) {
        sendGAE({
          id: 29,
          event: GAE_EVENT.vntMain,
          eventCategory: GAE_EVENT_CATEGORY.glavnaya,
          eventAction: GAE_EVENT_ACTION.button_click,
          eventLabel: GAE_EVENT_LABEL.podkluchit,
          eventValue: eventValue,
          eventContent: null,
          buttonLocation: GAE_BUTTON_LOCATION.screen,
          filterName: filterNameTranslit,
          actionGroup: GAE_ACTION_GROUP.interactions,
          productName: isServiceKindRTKRINEX
            ? GAE_PRODUCT_NAME.tarif_rinex
            : GAE_PRODUCT_NAME.tarif_rinex,
        });

        if (isAuthorized) {
          if (isCustomerBaseRole) {
            redirectURL = PATH_PERSONAL_AREA;
          }
        } else {
          sendGAE({
            id: 14,
            event: GAE_EVENT.vntLogin,
            eventCategory: GAE_EVENT_CATEGORY.avtorizaciya,
            eventAction: GAE_EVENT_ACTION.button_click,
            eventLabel: GAE_EVENT_LABEL.voiti,
            eventValue: null,
            screenName: GA_SCREEN_NAME.main,
            eventContent: "Podkluchit",
            buttonLocation: GAE_BUTTON_LOCATION.screen,
            filterName: null,
            actionGroup: GAE_ACTION_GROUP.interactions,
            productName: null,
          });

          if (isServiceKindRTKRINEX) {
            sendGAE({
              id: 16,
              event: GAE_EVENT.vntMain,
              eventCategory: GAE_EVENT_CATEGORY.glavnaya,
              eventAction: GAE_EVENT_ACTION.button_click,
              eventLabel: GAE_EVENT_LABEL.podkluchit_rtk_rinex,
              eventValue: null,
              screenName: GA_SCREEN_NAME.main,
              eventContent: null,
              buttonLocation: GAE_BUTTON_LOCATION.screen,
              filterName: null,
              actionGroup: GAE_ACTION_GROUP.interactions,
              productName: GAE_PRODUCT_NAME.tarif_rtk_rinex,
            });
          }

          if (isServiceKindRINEX) {
            sendGAE({
              id: 17,
              event: GAE_EVENT.vntMain,
              eventCategory: GAE_EVENT_CATEGORY.glavnaya,
              eventAction: GAE_EVENT_ACTION.button_click,
              eventLabel: GAE_EVENT_LABEL.podkluchit_rinex,
              eventValue: null,
              screenName: GA_SCREEN_NAME.main,
              eventContent: null,
              buttonLocation: GAE_BUTTON_LOCATION.screen,
              filterName: null,
              actionGroup: GAE_ACTION_GROUP.interactions,
              productName: GAE_PRODUCT_NAME.tarif_rinex,
            });
          }
        }
      }

      if (isServiceKindRTKRINEX) {
        sendGAE({
          id: 27,
          event: GAE_EVENT.vntTarify,
          eventCategory: GAE_EVENT_CATEGORY.tarify,
          eventAction: GAE_EVENT_ACTION.button_click,
          eventLabel: GAE_EVENT_LABEL.podkluchit,
          eventValue:
            amountWithDiscounts && !Number.isNaN(amountWithDiscounts)
              ? String(Math.round(amountWithDiscounts))
              : "0",
          eventContent: null,
          buttonLocation: GAE_BUTTON_LOCATION.screen,
          filterName: filterNameTranslit,
          actionGroup: GAE_ACTION_GROUP.interactions,
          productName: GAE_PRODUCT_NAME.tarif_rtk_rinex,
        });
      }

      if (isServiceKindRINEX) {
        sendGAE({
          id: 28,
          event: GAE_EVENT.vntTarify,
          eventCategory: GAE_EVENT_CATEGORY.tarify,
          eventAction: GAE_EVENT_ACTION.button_click,
          eventLabel: GAE_EVENT_LABEL.podkluchit,
          eventValue:
            amountWithDiscounts && !Number.isNaN(amountWithDiscounts)
              ? String(Math.round(amountWithDiscounts))
              : "0",
          eventContent: null,
          buttonLocation: GAE_BUTTON_LOCATION.screen,
          filterName: filterNameTranslit,
          actionGroup: GAE_ACTION_GROUP.interactions,
          productName: GAE_PRODUCT_NAME.tarif_rinex,
        });
      }

      handleRedirect(redirectURL);
    }
  };

  React.useEffect(() => {
    if (selectedCategory) {
      const payload: IPayGroupDTOPayload[] = [
        {
          subscriptionsCount: Number(selectedCategory.description),
          serviceKind: serviceKindType,
          duration: tariffSliderValues[sliderPositionDebouce].duration,
          payGroupId: "",
        },
      ];
      mutationPaymentCalculations.mutateAsync(payload, {
        onSuccess: (response: ICalculationDTO) => {
          setCalculations(response);
        },
      });
    } else {
      setCalculations(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory, sliderPositionDebouce]);

  return (
    <div className={classes.blockCard}>
      <div className={classes.card}>
        <div className={classes.cardBlock}>
          <TariffCardHeader
            title={
              serviceKindType === SUBSCRIPTION_SERVICE_KINDS.RTK_RINEX ? (
                <>
                  RTK&nbsp;
                  <InfoIconRTK />
                  &nbsp;+ RINEX&nbsp;
                  <InfoIconRINEX />
                </>
              ) : (
                <>
                  RINEX&nbsp;
                  <InfoIconRINEX />
                </>
              )
            }
          />

          <TariffCardOptions options={options} />
        </div>

        <div className={classes.cardBlock}>
          <div className={classes.tariff}>
            <div className={classes.tariffCategory}>
              <Controller
                control={control}
                name={FIELD_NAMES.TARIFF_CATEGORY}
                render={(renderProps) => {
                  const { value, onChange } = renderProps.field;

                  return (
                    <MTSAutocomplete
                      size="s"
                      hideLabel
                      placeholder="Выберите"
                      options={quantityDiscounts}
                      value={value}
                      onChangeValue={onChange}
                    />
                  );
                }}
              />
            </div>

            <h4 className={classes.tariffInfo}>
              {tariffSliderValues[sliderPosition].label}
            </h4>
            <div className={classes.cardSlider}>
              <MTSSlider
                value={sliderPosition}
                onChange={(event, value) => {
                  changeSliderPosition();
                  setSliderPosition(value as number);
                }}
                step={1}
                marks={tariffSliderValues}
                min={0}
                max={tariffSliderValues.length - 1}
              />
            </div>
          </div>

          <div className={classes.tariffCalculations}>
            <div className={classes.tariffPrices}>
              <p className={classes.tariffPrice}>
                от&nbsp;
                <span className={classes.tariffPriceNumber}>
                  {amountWithDiscounts
                    ? getFormattedNumberWithThousandSeparator({
                        value: amountWithDiscounts,
                      })
                    : 0}
                </span>
                &nbsp;₽
              </p>
              {amountBeforeDiscount > 0 &&
                amountBeforeDiscount > amountWithDiscounts && (
                  <div className={classes.tariffPriceWithoutDiscount}>
                    <CrossedOutText>
                      <span
                        className={classes.tariffPriceWithoutDiscountNumber}
                      >
                        {getFormattedNumberWithThousandSeparator({
                          value: amountBeforeDiscount,
                        })}
                      </span>
                    </CrossedOutText>
                    &nbsp;
                    <span
                      className={classes.tariffPriceWithoutDiscountCurrency}
                    >
                      ₽
                    </span>
                  </div>
                )}
            </div>
            {!!discountTotal && discountTotal > 0 && (
              <div className={classes.tariffDiscounts}>
                <MTSBadge
                  size="S"
                  variant="CUSTOM_COLOR"
                  colorBackground={"var(--color-constant-greyscale-900)"}
                >
                  Скидка {discountTotal}%
                </MTSBadge>
              </div>
            )}
          </div>

          <div className={classes.cardBtn}>
            <MTSButton
              variant="secondary"
              size="M"
              onClick={handleClick}
              sx={{
                width: "100%",
                [theme.breakpoints.down("tablet_768")]: {
                  width: "292px",
                },
                "@media (max-width: 500px)": {
                  width: "100%",
                },
              }}
            >
              Подключить
            </MTSButton>
          </div>
        </div>
      </div>
    </div>
  );
};
