import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const Realiability = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="96"
      height="96"
      viewBox="0 0 96 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M59.7482 46.9883C58.3539 52.6154 53.1774 56.3077 46.2378 56.3077H22.6763C16.9407 56.3077 8.00092 51.9586 8.00092 40.1538C8.00092 28.3491 16.9407 24 22.6763 24H46.2557C53.1774 24 57.7268 28.0385 59.7656 33.3196"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M36.2557 62.6807C37.65 68.3078 42.8265 72.0001 49.7661 72.0001H73.3276C79.0632 72.0001 88.003 67.651 88.003 55.8462C88.003 44.0415 79.0632 39.6924 73.3276 39.6924H49.7482C42.8265 39.6924 38.2771 43.7308 36.2383 49.012"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
};

export default Realiability;
