import React from "react";

import { useBillingSubscriptions } from "hooks/use-billing-subscriptions";
import { useDurations } from "hooks/use-durations";
import { useProfileSubscriptions } from "hooks/use-profile-subscriptions";
import { useServiceKinds } from "hooks/use-service-kinds";
import {
  ISubscriptionDTO,
  ISubscriptionStruct,
  ISubscriptionsByStatuses,
} from "interfaces";
import { getMappedSubscriptionStructData } from "utils/get-mapped-subscription-struct-data";
import { getSubscriptionsByStatuses } from "utils/get-subscriptions-by-statuses";

export const useSubscriptionsByStatuses = () => {
  const [subscriptionsByStatuses, setSubscriptionsByStatuses] =
    React.useState<ISubscriptionsByStatuses>({
      all: [],
      activated: [],
      paid: [],
      renew: [],
      expired: [],
      canRenew: [],
    });

  const {
    data: profileSubscriptions,
    isLoading: isLoadingProfileSubscriptions,
    isError: isErrorProfileSubscriptions,
  } = useProfileSubscriptions();
  const {
    data: billingSubscriptions,
    isLoading: isLoadingBillingSubscriptions,
    isError: isErrorBillingSubscriptions,
  } = useBillingSubscriptions();

  const { data: durations, isLoading: isLoadingDurations } = useDurations();
  const { data: serviceKinds, isLoading: isLoadingServiceKinds } =
    useServiceKinds();
  const [isDataReady, setIsDataReady] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (
      !isLoadingProfileSubscriptions &&
      !isLoadingBillingSubscriptions &&
      !isLoadingServiceKinds &&
      !isLoadingDurations
    ) {
      setIsDataReady(false);
      const _subscriptions: ISubscriptionStruct[] = [];

      if (
        profileSubscriptions?.length &&
        billingSubscriptions?.length &&
        durations &&
        serviceKinds
      ) {
        for (let profileSubscription of profileSubscriptions) {
          const subscriptionId = profileSubscription.id;

          const billingSubscription: ISubscriptionDTO | undefined =
            billingSubscriptions.find((_s) => _s.id === subscriptionId);

          if (billingSubscription) {
            const subscriptionStructData = getMappedSubscriptionStructData({
              billingSubscription,
              profileSubscription,
              durations,
              serviceKinds,
            });

            _subscriptions.push(subscriptionStructData);
          }
        }
      }

      const _subscriptionsByStatuses: ISubscriptionsByStatuses =
        getSubscriptionsByStatuses({
          subscriptions: _subscriptions,
          durations,
        });

      setSubscriptionsByStatuses(_subscriptionsByStatuses);

      setIsDataReady(true);
    }
  }, [
    billingSubscriptions,
    durations,
    isLoadingBillingSubscriptions,
    isLoadingDurations,
    isLoadingProfileSubscriptions,
    isLoadingServiceKinds,
    profileSubscriptions,
    serviceKinds,
  ]);

  return {
    subscriptionsByStatuses,
    isDataReady,
    isError: isErrorBillingSubscriptions || isErrorProfileSubscriptions,
  };
};
