import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const WarningRectangle = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.07059 6.15484C1.24366 3.82943 1.33019 2.66672 2.49724 1.49967C3.66428 0.332629 4.82699 0.246095 7.15241 0.0730264C7.75521 0.0281626 8.37704 0.000976562 8.99886 0.000976562C9.62069 0.000976562 10.2425 0.0281631 10.8453 0.0730271C13.1707 0.246097 14.3334 0.332631 15.5005 1.49968C16.6675 2.66672 16.7541 3.82943 16.9271 6.15485C16.972 6.75766 16.9992 7.37948 16.9992 8.0013C16.9992 8.62313 16.972 9.24495 16.9271 9.84776C16.7541 12.1732 16.6675 13.3359 15.5005 14.5029C14.3334 15.67 13.1707 15.7565 10.8453 15.9296C10.2425 15.9744 9.62068 16.0016 8.99886 16.0016C8.37704 16.0016 7.75521 15.9744 7.1524 15.9296C4.82699 15.7565 3.66428 15.67 2.49723 14.5029C1.33019 13.3359 1.24365 12.1732 1.07058 9.84776C1.02572 9.24495 0.998535 8.62313 0.998535 8.0013C0.998535 7.37948 1.02572 6.75765 1.07059 6.15484Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.00108 8.77471C9.48352 8.77471 9.8746 8.37752 9.8746 7.88758L9.87459 4.34123C9.87459 3.85128 9.4835 3.4541 9.00107 3.4541C8.51863 3.4541 8.12755 3.85129 8.12755 4.34123L8.12756 7.88758C8.12756 8.37753 8.51865 8.77471 9.00108 8.77471ZM10.091 11.4371C10.091 10.8252 9.60258 10.3292 9.00009 10.3292C8.3976 10.3292 7.90918 10.8252 7.90918 11.4371C7.90918 12.049 8.3976 12.545 9.00009 12.545C9.60258 12.545 10.091 12.049 10.091 11.4371Z"
        fill="white"
      />
    </SvgIcon>
  );
};

export default WarningRectangle;
