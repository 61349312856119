import { PATH_AGRONOMY, PATH_GEODESY_CADASTRE } from "constants/routes";

import React from "react";

import { useInView } from "react-intersection-observer";

import classNames from "classnames";
import { BlockContent } from "components/block-content";
import { BlockWide } from "components/block-wide";
import {
  GAE_ACTION_GROUP,
  GAE_BUTTON_LOCATION,
  GAE_EVENT,
  GAE_EVENT_ACTION,
  GAE_EVENT_CATEGORY,
  GAE_EVENT_LABEL,
  useGoogleAnalyticsEvents,
} from "hooks/google-analytics";

import classes from "./application.module.css";
import agriculture_mp4 from "./assets/agriculture.mp4";
import aircraft_mp4 from "./assets/aircraft.mp4";

export const Application = (): JSX.Element => {
  const { sendGAE } = useGoogleAnalyticsEvents();
  const { ref: blockApplicationRef, inView: inViewBlockApplication } =
    useInView({
      initialInView: false,
      threshold: 0.5,
      triggerOnce: true,
    });

  const cardAgricultureStyles = classNames({
    [classes.cardLink]: true,
    [classes.cardAgriculture]: true,
  });

  const cardGeodesyStyles = classNames({
    [classes.cardLink]: true,
    [classes.cardGeodesy]: true,
  });

  React.useEffect(() => {
    if (inViewBlockApplication) {
      sendGAE({
        id: 42,
        event: GAE_EVENT.vntMain,
        eventCategory: GAE_EVENT_CATEGORY.glavnaya,
        eventAction: GAE_EVENT_ACTION.block_show,
        eventLabel: GAE_EVENT_LABEL.gde_primenyaut_reshenie,
        eventValue: null,
        eventContent: null,
        buttonLocation: null,
        filterName: null,
        actionGroup: GAE_ACTION_GROUP.non_interactions,
        productName: null,
      });
    }
  }, [inViewBlockApplication, sendGAE]);

  const handleSendMetricsAgro = (e: React.SyntheticEvent) => {
    let _id = 52;
    let _eventAction = GAE_EVENT_ACTION.card_click;
    if (e.type === "mouseenter") {
      _id = 53;
      _eventAction = GAE_EVENT_ACTION.card_hover;
    }
    sendGAE({
      id: _id,
      event: GAE_EVENT.vntMain,
      eventCategory: GAE_EVENT_CATEGORY.glavnaya,
      eventAction: _eventAction,
      eventLabel: GAE_EVENT_LABEL.tochnoe_zemledelie,
      eventValue: null,
      eventContent: null,
      buttonLocation: GAE_BUTTON_LOCATION.screen,
      filterName: null,
      actionGroup: GAE_ACTION_GROUP.interactions,
      productName: null,
    });
  };

  const handleSendMetricsGeodesy = (e: React.SyntheticEvent) => {
    let _id = 73;
    let _eventAction = GAE_EVENT_ACTION.card_click;
    if (e.type === "mouseenter") {
      _id = 72;
      _eventAction = GAE_EVENT_ACTION.card_hover;
    }
    sendGAE({
      id: _id,
      event: GAE_EVENT.vntMain,
      eventCategory: GAE_EVENT_CATEGORY.glavnaya,
      eventAction: _eventAction,
      eventLabel: GAE_EVENT_LABEL.geodeziya_i_kadastr,
      eventValue: null,
      eventContent: null,
      buttonLocation: GAE_BUTTON_LOCATION.screen,
      filterName: null,
      actionGroup: GAE_ACTION_GROUP.non_interactions,
      productName: null,
    });
  };

  return (
    <div ref={blockApplicationRef} className={classes.blockApplication}>
      <BlockWide>
        <BlockContent>
          <>
            <div className={classes.blockApplicationInfo}>
              <h2 className={classes.blockApplicationInfoTitle}>
                Где применяют решение
              </h2>
            </div>
            <div className={classes.blockApplicationCard}>
              <a
                href={PATH_AGRONOMY}
                className={cardAgricultureStyles}
                onClick={handleSendMetricsAgro}
                onMouseEnter={handleSendMetricsAgro}
              >
                <div className={classes.cardShadow} />
                <div className={classes.cardBackground}>
                  <video
                    className={classes.cardBackgroundVideo}
                    autoPlay
                    loop
                    playsInline
                    muted
                  >
                    <source src={agriculture_mp4} type="video/mp4" />
                  </video>
                </div>
                <div className={classes.cardContent}>
                  <h3 className={classes.cardContentTitle}>
                    ТОЧНОЕ
                    <br />
                    ЗЕМЛЕДЕЛИЕ
                  </h3>

                  <ul className={classes.cardList}>
                    <li className={classes.cardListItem}>
                      <p className={classes.cardListItemText}>
                        Устранение пропусков и перекрытий
                      </p>
                    </li>
                    <li className={classes.cardListItem}>
                      <p className={classes.cardListItemText}>
                        Рост рентабельности{" "}
                        <span className={classes.cardListItemTextExtend}>
                          на 10-25%
                        </span>
                      </p>
                    </li>
                    <li className={classes.cardListItem}>
                      <p className={classes.cardListItemText}>
                        Защита от перерасхода материалов{" "}
                        <span style={{ whiteSpace: "nowrap" }}>и топлива</span>
                      </p>
                    </li>
                    <li className={classes.cardListItem}>
                      <p className={classes.cardListItemText}>
                        Высокая точность работ при использовании дронов,
                        составлении карт урожайности и дифференцированном
                        внесении удобрений
                      </p>
                    </li>
                  </ul>
                </div>
              </a>

              <a
                href={PATH_GEODESY_CADASTRE}
                className={cardGeodesyStyles}
                onClick={handleSendMetricsGeodesy}
                onMouseEnter={handleSendMetricsGeodesy}
              >
                <div className={classes.cardContent}>
                  <h3 className={classes.cardContentTitle}>
                    ГЕОДЕЗИЯ
                    <br />И КАДАСТР
                  </h3>

                  <ul className={classes.cardList}>
                    <li className={classes.cardListItem}>
                      <p className={classes.cardListItemText}>
                        Высокая точность работ
                      </p>
                    </li>
                    <li className={classes.cardListItem}>
                      <p className={classes.cardListItemText}>
                        Ускорение работ{" "}
                        <span className={classes.cardListItemTextExtend}>
                          в 3 раза
                        </span>
                      </p>
                    </li>
                    <li className={classes.cardListItem}>
                      <p className={classes.cardListItemText}>
                        Снижение расходов на оборудование
                      </p>
                    </li>
                  </ul>
                </div>
              </a>

              <div className={classes.cardEquipment}>
                <div className={classes.cardContent}>
                  <h3
                    className={classNames(
                      classes.cardContentTitle,
                      classes.cardContentTitleExtend
                    )}
                  >
                    АВТОМАТИЗА&shy;ЦИЯ СПЕЦ&shy;ТЕХНИКИ
                  </h3>

                  <ul className={classes.cardList}>
                    <li className={classes.cardListItem}>
                      <p className={classes.cardListItemText}>
                        Работы с точностью{" "}
                        <span className={classes.cardListItemTextExtend}>
                          до 2 см
                        </span>
                      </p>
                    </li>
                    <li className={classes.cardListItem}>
                      <p className={classes.cardListItemText}>
                        Дистанционное управление техникой
                      </p>
                    </li>

                    <li className={classes.cardListItem}>
                      <p className={classes.cardListItemText}>
                        Оперативное реагирование на аварийные ситуации
                      </p>
                    </li>
                  </ul>
                </div>
              </div>

              <div className={classes.cardDeformation}>
                <div className={classes.cardContent}>
                  <h3 className={classes.cardContentTitle}>
                    МОНИТОРИНГ
                    <br />
                    ДЕФОРМАЦИЙ
                  </h3>
                  <ul className={classes.cardList}>
                    <li className={classes.cardListItem}>
                      <p className={classes.cardListItemText}>
                        Предотвращение аварий
                      </p>
                    </li>
                    <li className={classes.cardListItem}>
                      <p className={classes.cardListItemText}>
                        Контроль смещения{" "}
                        <span style={{ whiteSpace: "nowrap" }}>
                          и колебания
                        </span>{" "}
                        сооружений
                      </p>
                    </li>
                    <li className={classes.cardListItem}>
                      <p className={classes.cardListItemText}>
                        Уменьшение стоимости проектов
                      </p>
                    </li>
                  </ul>
                </div>
              </div>

              <div className={classes.cardAircraft}>
                <div className={classes.cardShadow} />
                <div className={classes.cardBackground}>
                  <video
                    className={classes.cardBackgroundVideo}
                    autoPlay
                    loop
                    playsInline
                    muted
                  >
                    <source src={aircraft_mp4} type="video/mp4" />
                  </video>
                </div>
                <div className={classes.cardContent}>
                  <h3 className={classes.cardContentTitle}>
                    БЕСПИЛОТНЫЙ
                    <br />
                    ТРАНСПОРТ
                  </h3>
                  <ul>
                    <li className={classes.cardListItem}>
                      <p className={classes.cardListItemText}>
                        Точная и надёжная навигация при использовании совместно
                        с лидарами, камерами и инерциальными системами. Даже в
                        условиях непогоды{" "}
                        <span style={{ whiteSpace: "break-spaces" }}>
                          и при{" "}
                        </span>
                        встречном свете фар
                      </p>
                    </li>
                  </ul>
                </div>
              </div>

              <div className={classes.cardNavigation}>
                <div className={classes.cardContent}>
                  <h3 className={classes.cardContentTitle}>
                    МОРСКАЯ{" "}
                    <span style={{ whiteSpace: "nowrap" }}>И РЕЧНАЯ</span>
                    <br />
                    НАВИГАЦИЯ
                  </h3>
                  <ul className={classes.cardList}>
                    <li className={classes.cardListItem}>
                      <p className={classes.cardListItemText}>
                        Определение точных координат судов
                      </p>
                    </li>
                    <li className={classes.cardListItem}>
                      <p className={classes.cardListItemText}>
                        Облегчение судовождения при плохой видимости
                      </p>
                    </li>
                    <li className={classes.cardListItem}>
                      <p className={classes.cardListItemText}>
                        Предотвращение аварий в портах{" "}
                        <span style={{ whiteSpace: "nowrap" }}>
                          и при прохождении
                        </span>{" "}
                        шлюзов
                      </p>
                    </li>
                  </ul>
                </div>
              </div>

              <div className={classes.cardStorage}>
                <div className={classes.cardContent}>
                  <h3 className={classes.cardContentTitle}>
                    УМНЫЙ
                    <br />
                    СКЛАД
                  </h3>
                  <ul className={classes.cardList}>
                    <li className={classes.cardListItem}>
                      <p className={classes.cardListItemText}>
                        Учёт товаров на складах под открытым небом
                      </p>
                    </li>
                    <li className={classes.cardListItem}>
                      <p className={classes.cardListItemText}>
                        Автоматизация постановки и снятия товаров с учёта
                      </p>
                    </li>
                    <li className={classes.cardListItem}>
                      <p className={classes.cardListItemText}>
                        Сокращение затрат{" "}
                        <span style={{ whiteSpace: "nowrap" }}>и времени</span>
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </>
        </BlockContent>
      </BlockWide>
    </div>
  );
};
