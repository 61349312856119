import React from "react";

import { SubscriptionStatus } from "../subscription-status";

import classes from "./subscription-info-header.module.css";

interface ISubscriptionInfoHeaderProps {
  name: string;
  statusCode: string;
  createdDate: string;
  expiredOnDate: string;
}

export const SubscriptionInfoHeader = React.memo(
  (props: ISubscriptionInfoHeaderProps) => {
    const { name, statusCode, createdDate, expiredOnDate } = props;

    return (
      <div className={classes.block}>
        <h2 className={classes.blockLabel}>{name}</h2>

        <SubscriptionStatus
          subscriptionStatus={statusCode}
          createdDate={createdDate}
          expiredDate={expiredOnDate}
        />
      </div>
    );
  }
);
