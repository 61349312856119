import React from "react";

import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import { styled } from "@mui/material/styles";
import { TransitionProps } from "@mui/material/transitions";
import { MTSButton } from "components/mts-button";

import classes from "./dialog-confirm.module.css";

export type IDialogConfirmProps = {
  isOpen?: boolean;
  title?: string;
  content?: string | JSX.Element;
  confirmLabel?: string;
  cancelLabel?: string;
  handleClose?: () => void;
  handleConfirm?: () => void;
  handleCancel?: () => void;
  width?: number;
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CustomDialog = styled(Dialog)(() => ({
  "& .MuiDialog-paper": {
    overflowY: "unset",
    borderRadius: "20px",
    background: "var(--color-background-modal)",
    padding: "20px",
  },
  "& .MuiDialogContent-root": {
    padding: "16px 0 0 0",
    display: "flex",
    justifyContent: "center",
  },
  "& .MuiDialogActions-root": {
    display: "flex",
    gap: "16px",
    padding: "32px 0 0 0",
  },
}));

export const DialogConfirm = (props: IDialogConfirmProps) => {
  const {
    isOpen = false,
    title,
    content,
    confirmLabel = "Да",
    cancelLabel = "Отмена",
    handleClose,
    handleConfirm,
    handleCancel,
    width = 440,
  } = props;

  return (
    <CustomDialog
      open={isOpen}
      onClose={handleClose}
      TransitionComponent={Transition}
      sx={{
        zIndex: 1001,
        "& .MuiDialog-paper": {
          width: "100%",
          maxWidth: `${width}px`,
        },
      }}
    >
      <div className={classes.dialogHeader}>
        <h4 className={classes.dialogHeaderTitle}>{title}</h4>
      </div>
      <div className={classes.dialogContent}>{content}</div>
      <div className={classes.dialogButtons}>
        <MTSButton
          fullWidth
          size="M"
          variant="secondary"
          onClick={() => {
            handleCancel && handleCancel();
          }}
        >
          {cancelLabel}
        </MTSButton>
        <MTSButton
          noWrap
          fullWidth
          size="M"
          variant="primary"
          onClick={() => {
            handleConfirm && handleConfirm();
          }}
        >
          {confirmLabel}
        </MTSButton>
      </div>
    </CustomDialog>
  );
};
