import React from "react";

import { ContainerContent } from "components/container-content";
import { Loader } from "components/loader";
import {
  GAP_EVENT_TYPE,
  GA_SCREEN_NAME,
  useGoogleAnalyticsPages,
} from "hooks/google-analytics";
import { useContextMain } from "hooks/use-context-main";

import { INewUserFormStruct, NewUserForm } from "./components/new-user-form";
import { Offer } from "./components/offer";
import classes from "./only-new-user-page.module.css";

export const OnlyNewUserPage = (): JSX.Element => {
  useGoogleAnalyticsPages({
    screenName: GA_SCREEN_NAME.registration,
    event: GAP_EVENT_TYPE.SCRN,
  });

  const { profile, isLoadingAuthData } = useContextMain();

  const [initialFormData, setInitialFormData] =
    React.useState<INewUserFormStruct | null>(null);

  React.useEffect(() => {
    if (!isLoadingAuthData && profile) {
      const email = profile?.email ?? "";
      const fio = profile?.name ?? "";
      const companyName = profile?.organization ?? "";

      setInitialFormData({
        region: null,
        email,
        companyName,
        fio,
      });
    }
  }, [isLoadingAuthData, profile]);

  if (isLoadingAuthData) {
    return <Loader />;
  }

  return (
    <ContainerContent sx={{ alignItems: "center", justifyContent: "center" }}>
      <div className={classes.blockNewUser}>
        <div className={classes.blockInfo}>
          <h4 className={classes.blockInfoHeader}>Регистрация в LocationPro</h4>

          <p className={classes.blockInfoDesc}>
            Напоминаем, что услуга доступна только юрлицам
          </p>
        </div>

        {initialFormData && <NewUserForm initialFormData={initialFormData} />}
        <Offer />
      </div>
    </ContainerContent>
  );
};
