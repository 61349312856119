import React from "react";

import classNames from "classnames";
import { BlockContent } from "components/block-content";
import { useDisplayStatus } from "hooks/use-display-status";

import { Copyright } from "./components/copyright";
import { FooterLinks } from "./components/footer-links";
import classes from "./mts-footer.module.css";

interface IMTSFooterProps {
  sx?: React.CSSProperties;
}

export const MTSFooter = React.memo((props: IMTSFooterProps): JSX.Element => {
  const { sx } = props;
  const { isMobileVersion } = useDisplayStatus();

  const blockMTSFooterStyles = classNames({
    [classes.blockMTSFooter]: true,
    [classes.blockMTSFooterMobile]: isMobileVersion,
  });

  return (
    <footer className={blockMTSFooterStyles} style={sx}>
      <BlockContent>
        <FooterLinks />
        <Copyright />
      </BlockContent>
    </footer>
  );
});
