import { PRODUCT_NAME } from "constants/common";

import { useHistory } from "react-router-dom";

import { Helmet } from "react-helmet";

import { MTSButton } from "components/mts-button";
import {
  GAP_EVENT_TYPE,
  GA_SCREEN_NAME,
  useGoogleAnalyticsPages,
} from "hooks/google-analytics";

import classes from "./error-page.module.css";
import illustrationImg from "./img/illustration-img.png";
import { getButtonLabel } from "./utils/get-button-label";
import { getHelmetTitle } from "./utils/get-helmet-title";

export enum ErrorPageVariant {
  PAGE_NOT_FOUND = "page-not-found",
  UNKNOWN_ERROR = "unknown-error",
  NO_ACCESS = "no-access",
}

interface IErrorPageProps {
  variants:
    | ErrorPageVariant.PAGE_NOT_FOUND
    | ErrorPageVariant.UNKNOWN_ERROR
    | ErrorPageVariant.NO_ACCESS;
  redirectURL?: string;
  buttonLabel?: string;
}

export const ErrorPage = (props: IErrorPageProps): JSX.Element => {
  const { variants, redirectURL, buttonLabel } = props;
  const history = useHistory();

  useGoogleAnalyticsPages({
    screenName: GA_SCREEN_NAME.error,
    event: GAP_EVENT_TYPE.SCRN,
  });

  const handleClick = (): void => {
    let url: string = redirectURL ? redirectURL : "/";

    if (!redirectURL) {
      if (variants === ErrorPageVariant.UNKNOWN_ERROR) {
        url = ``;
      }
    }

    history.push(url);
  };

  return (
    <>
      <Helmet>
        <title>
          {getHelmetTitle(variants)}| {PRODUCT_NAME}
        </title>
        <meta name="description" content={getHelmetTitle(variants)} />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content={`${getHelmetTitle(variants)} | ${PRODUCT_NAME}`}
        />
        <meta property="og:description" content={getHelmetTitle(variants)} />
        <meta property="og:site_name" content={getHelmetTitle(variants)} />
      </Helmet>
      <div className={classes.block}>
        <img
          className={classes.img}
          src={illustrationImg}
          alt="illustrationImg"
        />
        <div className={classes.content}>
          <div className={classes.contentInfo}>
            {variants === ErrorPageVariant.PAGE_NOT_FOUND && (
              <>
                <h1 className={classes.contentInfoHeader}>404</h1>
                <h4 className={classes.contentInfoTitle}>Такой страницы нет</h4>
                <p className={classes.contentInfoDesc}>
                  Проверьте ссылку или перейдите на главную
                </p>
              </>
            )}
            {variants === ErrorPageVariant.UNKNOWN_ERROR && (
              <>
                <h4 className={classes.contentInfoTitle}>
                  Что-то пошло не так
                </h4>
                <p
                  className={classes.contentInfoDesc}
                  style={{ color: "var(--color-text-secondary)" }}
                >
                  Попробуйте обновить страницу позже
                </p>
              </>
            )}
            {variants === ErrorPageVariant.NO_ACCESS && (
              <>
                <h4 className={classes.contentInfoTitle}>Доступ ограничен</h4>
                <p className={classes.contentInfoDesc}>
                  Для просмотра данной страницы необходимы особые права
                </p>
              </>
            )}
          </div>

          <div className={classes.contentBtn}>
            <MTSButton
              onClick={handleClick}
              size="M"
              sx={{ minWidth: "227px" }}
            >
              {getButtonLabel(buttonLabel, variants)}
            </MTSButton>
          </div>
        </div>
      </div>
    </>
  );
};
