import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const MTSBusiness = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="44" height="44" />
      <path
        d="M28.2375 6.09229V7.85355H32.0149V14.7442L32.0156 14.8576H34.1304V7.85355H37.9077V6.09229H28.2375Z"
        fill="white"
      />
      <path
        d="M14.634 6.11853L11.8713 11.8803L9.10863 6.11853H6.09229V14.8306H8.20696V8.59557L10.9074 13.8631H12.8353L15.5365 8.59557V14.8306H17.6511V6.11853H14.634Z"
        fill="white"
      />
      <path
        d="M35.7877 35.1581C35.7482 35.4799 35.6023 35.779 35.3733 36.0075C35.1355 36.2249 34.8431 36.373 34.5278 36.436C34.0669 36.5277 33.5977 36.5704 33.1279 36.5634C32.6278 36.5724 32.1295 36.4991 31.6529 36.3464C31.2622 36.2189 30.9252 35.9637 30.6958 35.6214C30.4652 35.2786 30.3514 34.7836 30.3514 34.1543V33.2617C30.3514 32.6278 30.4676 32.1328 30.6958 31.79C30.9247 31.4484 31.2608 31.1935 31.6505 31.0658C32.1272 30.913 32.6255 30.8397 33.1257 30.8488C33.5955 30.8417 34.0647 30.8844 34.5255 30.9762C34.8408 31.0393 35.1331 31.1874 35.371 31.4047C35.6 31.6332 35.7458 31.9323 35.7853 32.254H37.9016C37.8633 31.5839 37.6088 30.9445 37.1767 30.4325C36.7505 29.9617 36.1988 29.6236 35.5871 29.4582C34.7847 29.2455 33.9568 29.1459 33.1272 29.1624C32.1133 29.1624 31.2339 29.3091 30.5136 29.5978C29.8011 29.8734 29.2042 30.3861 28.8225 31.0503C28.4497 31.6935 28.2537 32.5521 28.2406 33.6045V33.7011L28.3537 33.7057H28.2406V33.8021C28.2537 34.8546 28.4497 35.7133 28.8225 36.3565C29.2042 37.0207 29.8011 37.5333 30.5136 37.8088C31.2347 38.0976 32.1149 38.2443 33.1272 38.2443C33.9569 38.2611 34.7848 38.1616 35.5871 37.9486C36.1991 37.7838 36.7509 37.4455 37.1767 36.9741C37.609 36.4623 37.8635 35.8228 37.9016 35.1527L35.7877 35.1581Z"
        fill="white"
      />
    </SvgIcon>
  );
};

export default MTSBusiness;
