import {
  ISubscriptionResponseDTO,
  ISubscriptionStruct,
  IDictionaryDTO,
  ISubscriptionDTO,
} from "interfaces";
import { getDictionaryItemIdByCode } from "utils/get-dictionary-item-id-by-code";
import { getDictionaryItemNameByCode } from "utils/get-dictionary-item-name-by-code";
import { getPhoneNumbeWithMask } from "utils/get-phone-number-with-mask";

interface IGetMappedSubscriptionStructDataProps {
  billingSubscription: ISubscriptionDTO;
  profileSubscription: ISubscriptionResponseDTO;
  durations: IDictionaryDTO[];
  serviceKinds: IDictionaryDTO[];
}

export const getMappedSubscriptionStructData = (
  props: IGetMappedSubscriptionStructDataProps
): ISubscriptionStruct => {
  const { billingSubscription, profileSubscription, serviceKinds, durations } =
    props;

  // NOTE: DATA FROM BILLING SERVICE
  const serviceKindId = getDictionaryItemIdByCode(
    billingSubscription?.serviceKind,
    serviceKinds
  );
  const duration: string =
    billingSubscription?.duration ?? ""
      ? getDictionaryItemNameByCode(billingSubscription.duration, durations)
      : "";
  const expiredDate = billingSubscription?.expiredOn ?? "";
  const status = billingSubscription?.status ?? "";

  // NOTE: DATA FROM PROFILE SERVICE
  const editStatus = profileSubscription?.editStatus ?? "";
  const phoneWithMask = getPhoneNumbeWithMask(
    profileSubscription?.subscribed?.login
  );

  const subscriptionStructData: ISubscriptionStruct = {
    id: profileSubscription?.id,
    name: profileSubscription?.name,
    serviceKind: serviceKindId,
    duration,
    createdDate: profileSubscription.created,
    expiredDate,
    phone: phoneWithMask,
    status,
    email: profileSubscription?.email ?? "",
    description: profileSubscription?.description ?? "",
    editStatus,
    inProlongation: billingSubscription?.inProlongation ?? false,
    disabled: billingSubscription?.inProlongation ?? false,
  };

  return subscriptionStructData;
};
