import { createTheme } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    xs: false;
    sm: false;
    md: false;
    lg: false;
    xl: false;
    mobile_360: true;
    tablet_768: true;
    desktopS_960: true;
    desktopS_1280: true;
    desktopM_1440: true;
    desktopL_1680: true;
    desktopXL_1920: true;
  }
}

export const theme = createTheme({
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiTooltip: {
      styleOverrides: {
        arrow: {
          color: "var(--color-background-inverted)",
        },
        tooltip: {
          maxWidth: "364px",
          fontFamily: "var(--typography-v3-p4-regular-comp-font-family)",
          fontSize: "var(--typography-v3-p4-regular-comp-font-size)",
          lineHeight: "var(--typography-v3-p4-regular-comp-line-height)",
          fontWeight: "var(--typography-v3-p4-regular-comp-font-weight)",
          color: "var(--color-text-inverted)",
          backgroundColor: "var(--color-background-inverted)",
          padding: "10px 12px",
          borderRadius: "8px",
        },
        popper: {
          "& .MuiTooltip-tooltipPlacementBottom": {
            marginTop: "9px !important",
          },
        },
      },
    },
  },

  breakpoints: {
    values: {
      mobile_360: 360,
      tablet_768: 768,
      desktopS_960: 960,
      desktopS_1280: 1280,
      desktopM_1440: 1440,
      desktopL_1680: 1680,
      desktopXL_1920: 1920,
    },
  },
});
