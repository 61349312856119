import { HTTPStatusCode, QueryKeys } from "constants/keys";

import { useQuery } from "react-query";

import { AxiosError } from "axios";
import { useNotifications } from "hooks/use-notifications";
import { getProfileSubscriptionsByPayment } from "services/api";

export const useProfileSubscriptionsPayment = (ids: number[]) => {
  const { addErrorMessage } = useNotifications();

  return useQuery(
    [QueryKeys.PROFILE_SUBSCRIPTIONS_BY_PAYMENT, ids.join(",")],
    () => getProfileSubscriptionsByPayment(ids),
    {
      enabled: Boolean(ids.length),
      refetchOnWindowFocus: false,
      retry: 0,
      onError: (error: AxiosError) => {
        const status = error.response?.status;

        if (status !== HTTPStatusCode.UNAUTHORIZED_401) {
          addErrorMessage({
            message:
              "Не удалось получить данные с подписками по текущему платежу",
          });
        }
      },
    }
  );
};
