import { Link } from "react-router-dom";

import classNames from "classnames";

import classes from "./mts-card.module.css";

enum Size {
  S = "S",
  M = "M",
  L = "L",
  XL = "XL",
}

type ISize = keyof typeof Size;

interface IMTSCardProps {
  size: ISize;
  title: String;
  children: JSX.Element;
  description?: string;
  to?: string;
  href?: string;
}

export const MTSCard = (props: IMTSCardProps): JSX.Element | null => {
  const { title, children, to = "", size, description = "", href = "" } = props;

  const titleStyles = classNames({
    [classes.title]: true,
    [classes.titleSizeS]: size === Size.S,
    [classes.titleSizeM]: size === Size.M,
    [classes.titleSizeL]: size === Size.L,
    [classes.titleSizeXL]: size === Size.XL,
  });
  const descriptionTextStyles = classNames({
    [classes.descriptionText]: true,
    [classes.descriptionTextSizeS]: size === Size.S,
    [classes.descriptionTextSizeM]: size === Size.M,
    [classes.descriptionTextSizeL]: size === Size.L,
    [classes.descriptionTextSizeXL]: size === Size.XL,
  });

  const MTSCardContent = (): JSX.Element => {
    return (
      <>
        <h4 className={titleStyles}>{title}</h4>

        {description && (
          <div className={classes.cardDescription}>
            <p className={descriptionTextStyles}>{description}</p>
          </div>
        )}
        <div className={classes.cardContent}>{children}</div>
      </>
    );
  };

  if (to) {
    return (
      <Link to={to} className={classes.card}>
        <MTSCardContent />
      </Link>
    );
  }

  if (href) {
    return (
      <a href={href} target="_blank" className={classes.card} rel="noreferrer">
        <MTSCardContent />
      </a>
    );
  }

  return null;
};
