import { SS_DONT_SHOW_RENEW_DIALOG } from "constants/keys";

import React from "react";

import Collapse from "@mui/material/Collapse";
import Fade from "@mui/material/Fade";
import FormControlLabel from "@mui/material/FormControlLabel";
import { MTSCheckbox } from "components/mts-checkbox";
import { MTSIcon } from "components/mts-icon";
import { TableDiscount } from "components/table-discount";

import classes from "./renew-subscription-message.module.css";

export const RenewSubscriptionMessage = (): JSX.Element => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [isDontShowDialog, setIsDontShowDialog] =
    React.useState<boolean>(false);

  return (
    <div className={classes.block}>
      <p className={classes.blockInfo}>
        У вас есть другие подписки, которые можно продлить. Продлите несколько
        подписок и получите&nbsp;
        <div className={classes.buttonBlock}>
          <button
            className={classes.button}
            onClick={() => setIsOpen((prev) => !prev)}
          >
            скидку до 25 %&nbsp;
            <div className={classes.buttonIcon}>
              <MTSIcon.ArrowUp
                style={{
                  rotate: isOpen ? "0deg" : "-180deg",
                  transition: "all 0.05s ease-in",

                  width: "24px",
                  height: "24px",
                }}
              />
            </div>
          </button>
        </div>
      </p>
      <Collapse in={isOpen}>
        <Fade in={isOpen} {...(isOpen ? { timeout: 1500 } : {})}>
          <div className={classes.tableBlock}>
            <div className={classes.table}>
              <TableDiscount
                titleCountColumn="Подписки"
                titleDiscountColumn="Скидка"
              />
            </div>
          </div>
        </Fade>
      </Collapse>
      <div className={classes.actionBlock}>
        <FormControlLabel
          control={
            <MTSCheckbox
              boxSize="small"
              checked={isDontShowDialog}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const _isDontShowDialog = event.target.checked;
                setIsDontShowDialog(event.target.checked);

                if (_isDontShowDialog) {
                  sessionStorage.setItem(SS_DONT_SHOW_RENEW_DIALOG, "1");
                } else {
                  sessionStorage.removeItem(SS_DONT_SHOW_RENEW_DIALOG);
                }
              }}
            />
          }
          label={
            <span className={classes.actionBlockCheckboxText}>
              Больше не показывать
            </span>
          }
          sx={{ marginLeft: 0 }}
        />
      </div>
    </div>
  );
};
