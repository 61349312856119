import { useMutation } from "react-query";

import { AxiosError } from "axios";
import {
  GAE_ACTION_GROUP,
  GAE_BUTTON_LOCATION,
  GAE_EVENT,
  GAE_EVENT_ACTION,
  GAE_EVENT_CATEGORY,
  GAE_EVENT_LABEL,
  GA_SCREEN_NAME,
  useGoogleAnalyticsEvents,
} from "hooks/google-analytics";
import { useMutationRefreshRole } from "hooks/use-mutation-refresh-role";
import { useNotifications } from "hooks/use-notifications";
import { ICustomerAdminCreateDTOPayload, ICustomerAdminDTO } from "interfaces";
import { createCustomerAdmin } from "services/api";

export const useMutationCreateCustomerAdmin = () => {
  const { addErrorMessage, addSuccessMessage } = useNotifications();
  const mutationRefreshRole = useMutationRefreshRole();
  const { sendGAE } = useGoogleAnalyticsEvents();

  return useMutation(
    (payload: ICustomerAdminCreateDTOPayload) => {
      return createCustomerAdmin(payload);
    },
    {
      onSuccess: (response: ICustomerAdminDTO) => {
        const { id } = response;

        sendGAE({
          id: 19,
          event: GAE_EVENT.vntLogin,
          eventCategory: GAE_EVENT_CATEGORY.registraciya,
          eventAction: GAE_EVENT_ACTION.confirmed,
          eventLabel: GAE_EVENT_LABEL.uspeshnaya_registraciya,
          eventValue: null,
          screenName: GA_SCREEN_NAME.registration,
          eventContent: null,
          buttonLocation: GAE_BUTTON_LOCATION.screen,
          filterName: null,
          actionGroup: GAE_ACTION_GROUP.conversions,
          productName: null,
          formOrderId: String(id),
        });

        mutationRefreshRole.mutate();

        addSuccessMessage({ message: "Добро пожаловать в сервис LocationPro" });
      },
      onError: (error: AxiosError) => {
        addErrorMessage({ message: "Что-то пошло не так. Повторите попытку" });
      },
    }
  );
};
