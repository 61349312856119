import { IDictionaryDTO } from "interfaces";

export const getExpandedRegionOptions = (
  initialRegion: IDictionaryDTO[],
  fetchedRegions: IDictionaryDTO[] | undefined
): IDictionaryDTO[] => {
  let allRegions = initialRegion;

  if (fetchedRegions?.length) {
    allRegions = initialRegion.concat(fetchedRegions);
  }

  return allRegions;
};
