import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const RestartCustom = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2426 1.78833C12.6568 1.78833 12.9926 2.12412 12.9926 2.53833V5.36676C12.9926 5.78097 12.6568 6.11676 12.2426 6.11676H9.41417C8.99996 6.11676 8.66417 5.78097 8.66417 5.36676C8.66417 4.95254 8.99996 4.61676 9.41417 4.61676H10.1487C8.41638 3.6969 6.21819 3.96655 4.75905 5.42568C2.96915 7.21559 2.96915 10.1176 4.75905 11.9075C6.54896 13.6974 9.45096 13.6974 11.2409 11.9075C12.2815 10.8669 12.7176 9.45112 12.5476 8.09344C12.4961 7.68244 12.7876 7.30754 13.1986 7.25608C13.6096 7.20462 13.9845 7.49609 14.0359 7.90709C14.261 9.70439 13.6834 11.5863 12.3015 12.9682C9.92584 15.3438 6.07408 15.3438 3.69839 12.9682C1.3227 10.5925 1.3227 6.74071 3.69839 4.36502C5.82011 2.24331 9.11916 2.01648 11.4926 3.68455V2.53833C11.4926 2.12412 11.8284 1.78833 12.2426 1.78833Z"
      />
    </SvgIcon>
  );
};

export default RestartCustom;
