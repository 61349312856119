import { Size } from "../constants";

export const getIconSize = (btnSize: string): number => {
  switch (btnSize) {
    case Size.XS:
      return 12;
    case Size.S:
    case Size.M:
    case Size.L:
      return 16;
    default:
      return 16;
  }
};
