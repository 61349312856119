import React from "react";

import { BlockContent } from "components/block-content";
import { BlockWide } from "components/block-wide";
import {
  GAP_EVENT_TYPE,
  GA_SCREEN_NAME,
  useGoogleAnalyticsPages,
} from "hooks/google-analytics";

import classes from "./public-offer.module.css";

export const PublicOffer = (): JSX.Element => {
  useGoogleAnalyticsPages({
    screenName: GA_SCREEN_NAME.offer,
    event: GAP_EVENT_TYPE.SCRN,
  });

  const [publicOfferDate] = React.useState<string>("24.03.2023");

  return (
    <BlockWide>
      <BlockContent>
        <div className={classes.block}>
          <div className={classes.blockInfo}>
            <h2 className={classes.blockInfoTitle}>
              Пользовательское соглашение (Публичная оферта)
            </h2>
            <div className={classes.blockInfoContent}>
              <p className={classes.blockInfoContentTitle}>
                Актуальная версия действует с {publicOfferDate}
              </p>
              <ul className={classes.blockInfoContentList}>
                <li>
                  ПАО «МТС» предлагает Пользователям заключить настоящее
                  Пользовательское соглашение (далее – Соглашение) на
                  приведённых ниже условиях.
                </li>
                <li>
                  Пользователь удостоверяет, что условия Соглашения принимаются
                  им без каких-либо возражений, Пользователь понял и принял
                  значение используемых в Соглашении терминов, слов и выражений
                  согласно их нормативно-правовому определению и/или толкованию,
                  указанному в Соглашении.
                </li>
                <li>
                  Соглашение не требует двустороннего подписания и действительно
                  в электронном виде.
                </li>
              </ul>
            </div>
          </div>
          <div className={classes.blockChapter}>
            <p className={classes.chapterTitle}>1. Термины и определения</p>
            <ul className={classes.chapterList}>
              <li>
                1.1. Авторизация – процедура предоставления доступа к Сервису,
                производимая на основании успешной Аутентификации.
              </li>
              <li>
                1.2. Акцепт Соглашения – прохождение процедуры регистрации в
                Сервисе посредством совершения действий в соответствии с п. 3.2.
                настоящего Соглашения. После совершения Акцепта Соглашения
                Пользователь считается принявшим безоговорочно и в целом все
                условия настоящего Соглашения.
              </li>
              <li>
                1.3. Аутентификация – процедура проверки подлинности
                Пользователя или Конечного пользователя с помощью методов
                аутентификации, указанных в разделе 4 настоящего Соглашения,
                необходимая для Авторизации такого Пользователя или Конечного
                пользователя.
              </li>
              <li>
                1.4. Заказ – совокупность действий, совершённых Пользователем с
                целью приобретения конкретного объёма услуг в рамках Сервиса.
              </li>
              <li>
                1.5. Идентификация – процесс подтверждения Пользователем данных,
                указанных им в Аккаунте.
              </li>
              <li>
                1.6. Личный кабинет – защищённая часть Сайта, доступ к которой
                предоставляется после Аутентификации в соответствии с условиями
                настоящего Соглашения, и предназначенная для доступа к Сервису и
                управления им.
              </li>
              <li>
                1.7. Данные – данные (поправки) для уточнения местоположения
                Устройства в режиме реального времени (RTK) или в режиме
                постобработки (RINEX), предоставляемые МТС Пользователю
                посредством Платформы за счёт информации, полученной Платформой
                с Устройств и референсной сети станций МТС, обрабатывающей
                спутниковые сигналы о положении станций относительно
                географического пространства и соотносящей эти данные с
                информацией, полученной с Устройств Пользователя (Конечного
                пользователя). Точность Данных составляет 1-5 сантиметров в
                зависимости от удалённости от референсной станции.
              </li>
              <li>
                1.8. Подключение – действия МТС по предоставлению Учётных данных
                Пользователю или Конечному пользователю, а также действия
                Пользователя по подключению Устройств к Платформе для
                пользования Сервиса.
              </li>
              <li>
                1.9. Платформа «LocationPro», «Платформа» – комплекс
                информационно-технических средств, позволяющий осуществлять
                Подключение для целей получения Сервиса Пользователем. Доступ к
                платформе предоставляется посредством Сайта.
              </li>
              <li>
                1.10. Устройство – электронное устройство Пользователя
                (Конечного пользователя), подключённое к сети Интернет, дающее
                Пользователю (Конечному пользователю) возможность пользоваться
                Сервисом.
              </li>
              <li>
                1.11. Учётные данные – в зависимости от контекста: данные для
                входа в Личный кабинет Пользователя (абонентский номер и код из
                короткого текстового сообщения), или логин и пароль,
                направляемые МТС на указанный Пользователем в Личном кабинете
                адрес электронной почты Конечного пользователя, позволяющие
                Конечному пользователю осуществить пользование Сервисом.
              </li>
              <li>
                1.12. Подписка – запрос на приобретение/продление доступа к
                платному функционалу Сервиса в определённом объёме (определяется
                параметрами тарифа Подписки, указанного на Сайте) и на
                определённый срок. Одна Подписка предоставляет доступ для одного
                Устройства (Конечного пользователя).
              </li>
              <li>
                1.13. МТС – Публичное акционерное общество «Мобильные
                ТелеСистемы» (место нахождения: Российская Федерация, 109147, г.
                Москва, ул. Марксистская, д. 4, ИНН/КПП 7740000076/770901001,
                ОГРН 1027700149124), предоставляющее Сервис.
              </li>
              <li>
                1.14. Пользователь – юридическое лицо или индивидуальный
                предприниматель, принявший условия Соглашения, имеющий все права
                для его заключения.
              </li>
              <li>
                1.15. Конечный пользователь – физическое лицо, которым
                Пользователь предоставил возможность непосредственно
                использовать Сервис. Действия Конечных пользователей,
                осуществляемые в рамках использования Сервиса, признаются
                действиями Пользователя.
              </li>
              <li>
                1.16. Представитель – лицо, уполномоченное Пользователем на
                Акцепт Соглашения, создание Личного кабинета, использование
                Сервиса и другие необходимые действия и действующее от имени и в
                интересах Пользователя.
              </li>
              <li>
                1.17. Сайт – страница в сети Интернет, размещённая по адресу:
                https://locationpro.mts.ru/
              </li>
              <li>
                1.18. Сервис – услуги, описанные в разделе 2 Соглашения,
                оказываемые МТС с использованием Платформы.
              </li>
              <li>
                1.19. Тестовый доступ – предоставление Сервиса на ограниченный
                период времени, указанный на Сайте, исключительно для
                ознакомления, тестирования и/или проверки работоспособности
                Сервиса для целей принятия решения Пользователем о
                целесообразности дальнейшего приобретения Сервиса на
                коммерческих условиях.
              </li>
              <li>
                1.20. Условия Единого доступа – Условия Единого доступа к
                сервисам ПАО «МТС», размещённые в сети Интернет по адресу:
                https://static.ssl.mts.ru/mts_rf/images/usloviya_edinogo_dostupa_k_servisam_MTS.html.
              </li>
              <li>
                Иные термины трактуются в соответствии с Условиями Единого
                доступа, действующим законодательством Российской Федерации и
                практикой делового оборота в части, непротиворечащей настоящему
                Соглашению.
              </li>
            </ul>
          </div>

          <div className={classes.blockChapter}>
            <p className={classes.chapterTitle}>2. Предмет Соглашения</p>
            <ul className={classes.chapterList}>
              <li>
                2.1. Настоящее Соглашение устанавливает общие условия
                предоставления Сервиса.
              </li>
              <li>
                2.2. Сервис предусматривает оказание МТС Пользователю следующих
                услуг: комплекс действий МТС по предоставлению Пользователю по
                Подписке Учётных данных, а также доступа к пакету Данных.
                Конкретный объём услуг, предоставляемых Пользователю в рамках
                Сервиса, определяется Пользователем, исходя из выбранной
                Подписки.
              </li>
              <li>
                2.3. Сервис начинает предоставляться Пользователю с момента
                Акцепта Соглашения.
              </li>
              <li>
                2.4. Доступ к платному функционалу Сервиса предоставляется после
                оформления Подписки по Заказу Пользователя и выполнения
                Пользователем действий, определённых в разделе 4 настоящего
                Соглашения. Выбор Подписки (Заказ) осуществляется Пользователем
                в Личном кабинете. Подписка считается оформленной с момента
                оплаты Пользователем счета по Заказу согласно разделу 8
                Соглашения.
              </li>
              <li>
                2.5. Пользователь может однократно оформить Тестовый доступ к
                Сервису.
              </li>
            </ul>
          </div>

          <div className={classes.blockChapter}>
            <p className={classes.chapterTitle}>3. Акцепт Соглашения</p>
            <ul className={classes.chapterList}>
              <li>
                3.1. Для получения Сервиса Пользователь обязан в порядке,
                установленном Соглашением, подтвердить, что прочитал, понял,
                согласен соблюдать настоящее Соглашение, и присоединиться к нему
                в целом путём Акцепта Соглашения. Для Акцепта Соглашения
                Пользователю необходимо совершить следующие действия:
              </li>
              <li>
                3.1.1. пройти Аутентификацию в соответствии с п. 4.1 Соглашения
              </li>
              <li>
                3.1.2. ознакомиться с условиями настоящего Соглашения и
                подтвердить согласие с ними путем проставления галочки в
                чек-боксе.
              </li>
              <li>
                3.2. С момента Акцепта Соглашения Пользователем настоящее
                Соглашение считается заключённым с Пользователем, Пользователь
                приобретает и осуществляет права и обязанности, предусмотренные
                Соглашением.
              </li>
              <li>
                3.3. Принимая условия Соглашения, Пользователь соглашается на
                получение от МТС сообщений по указанным Пользователем контактным
                данным о следующих событиях: изменение Сервиса, появление
                специальных предложений, проведение акций, иной информации, а
                также рекламных/маркетинговых сообщений.
              </li>
            </ul>
          </div>

          <div className={classes.blockChapter}>
            <p className={classes.chapterTitle}>
              4. Авторизация и Аутентификация
            </p>
            <ul className={classes.chapterList}>
              <li>
                4.1. Для получения доступа к функционалу Сайта (Авторизации)
                Пользователю необходимо пройти Аутентификацию в порядке,
                предусмотренном Условиями Единого доступа.
              </li>
              <li>
                4.2. Для предоставления доступа к Сервису Конечным пользователям
                Пользователь после оформления Подписки указывает адрес
                электронной почты Конечного пользователя, Устройство которого
                будет использоваться для пользования Сервисом. На указанный
                адрес электронной почты Конечный пользователь получает от МТС
                логин и пароль (Учётные данные) для доступа к Сервису.
              </li>
              <li>
                4.3. Предоставление доступа Данным происходит в момент первого
                подключения Конечного пользователя к Платформе (получения
                поправок RTK или скачивания файлов RINEX), но не позже чем через
                1 (один) год после предоставления Учетных данных со стороны МТС.
              </li>
              <li>
                4.4. Пользователь несёт ответственность за безопасность Учётных
                данных, в том числе Учётных данных Конечных пользователей, а
                также за всё, что будет сделано в рамках Сервиса после
                Авторизации под Учётными данными. Действия, совершаемые в Личном
                кабинете на Сайте с использованием Учётных данных, признаются
                действиями Пользователя и порождают у такого Пользователя
                соответствующие права и обязанности.
              </li>
              <li>
                4.5. Пользователь обязан немедленно уведомить МТС о любом случае
                неавторизованного доступа под Учётными данными и/или о любом
                нарушении безопасности. МТС не отвечает за возможную потерю или
                порчу данных, которая может произойти из-за нарушения
                Пользователем положений настоящего пункта Соглашения.
              </li>
              <li>
                4.6. В случае передачи Учетных данных третьему лицу всю
                ответственность несёт непосредственно сам Пользователь. За
                ущерб, причинённый в результате несанкционированного доступа к
                Учетным данным Пользователя, МТС ответственности не несёт.
              </li>
            </ul>
          </div>

          <div className={classes.blockChapter}>
            <p className={classes.chapterTitle}>
              5. Порядок использования Аккаунта
            </p>
            <ul className={classes.chapterList}>
              <li>
                5.1. После прохождения Пользователем Авторизации Платформа
                получает доступ ко всем данным Аккаунта.
              </li>
              <li>
                5.2. В рамках функционала Сайта возможность установки/изменения
                данных, содержащихся в Аккаунте, не предоставляется.
              </li>
              <li>
                5.3. В случае блокировки Пользователю доступа к Аккаунту в
                соответствии с Условиями Единого доступа МТС приостанавливает
                доступ к Личному кабинету до момента возобновления доступа
                Пользователя к Аккаунту.
              </li>
            </ul>
          </div>

          <div className={classes.blockChapter}>
            <p className={classes.chapterTitle}>6. Идентификация</p>
            <ul className={classes.chapterList}>
              <li>
                6.1. Для предоставления доступа к функционалу Сайта прохождение
                дополнительной Идентификации и подтверждения своих полномочий не
                требуется.
              </li>
            </ul>
          </div>

          <div className={classes.blockChapter}>
            <p className={classes.chapterTitle}>
              7. Права и обязанности Сторон
            </p>
            <ul className={classes.chapterList}>
              <li>7.1. Пользователь вправе:</li>
              <li>
                7.1.1. Использовать в полной мере все функциональные
                возможности, предоставляемые Сервисом, согласно условиям
                настоящего Соглашения.
              </li>
              <li>
                7.1.2. Получать необходимую информацию об МТС, условиях
                предоставления Сервиса.
              </li>
              <li>
                7.1.3. Отказаться от использования Сервиса и расторгнуть
                Соглашение посредством реализации отключения Сервиса
                предусмотренными для этого способами.
              </li>
              <li>7.2. Пользователь обязан:</li>
              <li>
                7.2.1. Полностью ознакомиться с условиями Соглашения и соблюдать
                их;
              </li>
              <li>
                7.2.2. Не осуществлять действия, запрещённые настоящим
                Соглашением и законодательством Российской Федерации.
              </li>
              <li>
                7.2.3. Не осуществлять какие-либо действия, направленные на
                дестабилизацию работы Сервиса, осуществление попыток
                несанкционированного доступа к Сервису, результатам
                интеллектуальной деятельности, размещённым на нём/доступным
                через Сервис, а также не осуществлять любых иных действий,
                нарушающих права МТС и/или третьих лиц.
              </li>
              <li>
                7.2.4. Принимать надлежащие меры для обеспечения сохранности
                Учетных данных и нести ответственность за все действия,
                совершённые на Сервисе под Учётными данными. Пользователь обязан
                незамедлительно уведомить МТС о любых случаях доступа к Сервису
                третьих лиц под Учётными данными. Пользователь не имеет права
                передавать, уступать, продавать, передавать в пользование и т.п.
                Учётные данные на Сервисе третьим лицам без согласия МТС.
              </li>
              <li>
                7.2.5. Своевременно оплачивать установленную МТС стоимость
                Сервиса.
              </li>
              <li>
                7.2.6. Самостоятельно решать все вопросы приобретения прав
                доступа в Интернет, покупки и наладки для этого соответствующего
                оборудования и программных продуктов, которые подпадают под
                действие настоящего Соглашения.
              </li>
              <li>
                7.3. Не использовать Данные в каких-либо противоправных целях.
                При нарушении данного обязательства Пользователь обязуется
                самостоятельно и за свой счет урегулировать все
                вопросы/претензии третьих лиц и возместить причиненные убытки в
                полном объеме, связанные с противоправной обработкой и
                использованием Данных.
              </li>
              <li>
                7.4. Обеспечить конфиденциальность сведений, получаемых в
                результате использования Сервиса, в т. ч. не предоставлять
                третьим лицам свои Учётные данные или Данные.
              </li>
              <li>7.5. МТС вправе:</li>
              <li>
                7.5.1. Определять состав Сервиса, Сайта, их структуру и внешний
                вид, разрешать и ограничивать доступ к Сервису в соответствии с
                настоящим Соглашением и законодательством Российской Федерации.
              </li>
              <li>
                7.5.2. Решать вопросы, связанные с коммерческим использованием
                Сервиса, в частности вопросы о возможности размещения на Сервисе
                рекламы, участия в партнёрских программах и т.д.
              </li>
              <li>
                7.5.3. В случае нарушения Пользователем настоящего Соглашения
                и/или законодательства Российской Федерации приостановить доступ
                к Сервису в порядке, предусмотренном п. 11.1 Соглашения, не
                отвечая за любой вред, который может быть причинён Пользователю
                таким действием.
              </li>
              <li>
                7.5.4. Удалять информацию Пользователя из Сервиса в случае
                расторжения Соглашения по любому основанию;
              </li>
              <li>
                7.5.5. Привлекать в целях реализации прав и обязанностей в
                рамках настоящего Соглашения любых третьих лиц.
              </li>
              <li>
                7.5.6. Размещать рекламную и/или иную информацию в любом разделе
                Сервиса, прерывать работу Сервиса рекламной информацией, на что
                Пользователь даёт им согласие путём Акцепта Соглашения.
              </li>
              <li>
                7.5.7. Устанавливать возрастные ограничения при доступе к
                Сервису/его разделам, предназначенным для аудитории
                определённого возраста. Это означает, что лица, не достигшие
                указанного возраста, обязуются воздержаться от доступа к
                Сервису, о чём МТС может уведомлять Пользователя указанием на
                Сервисе знаков информационной продукции или посредством
                информационных сообщений при попытке Пользователя
                воспользоваться Сервисом/его разделами, предназначенными для
                аудитории определённого возраста.
              </li>
              <li>
                7.5.8. Осуществлять иные права, предусмотренные настоящим
                Соглашением и/или законодательством Российской Федерации.
              </li>
              <li>7.6. МТС обязан:</li>
              <li>
                7.6.1. Обеспечивать техническую возможность пользования Сервисом
                в порядке, определённом настоящим Соглашением.
              </li>
              <li>
                7.6.2. Осуществлять текущее управление разделами Сервиса,
                обеспечивать возможность пользования Сервисом в соответствии с
                установленными им технологическими параметрами для работы
                Сервиса.
              </li>
              <li>
                7.7. Предоставлять Учётные данные и информацию о порядке доступа
                к Платформе (ссылку на адрес в сети Интернет для доступа к
                Платформе) в рамках Подписки. Услуги считаются оказанными в
                полном объеме с момента предоставления Пользователю доступа к
                Подписке.
              </li>
              <li>
                7.8. Предоставлять Учётные данные Конечным пользователям в
                порядке, определённом настоящим Соглашением.
              </li>
              <li>
                7.9. Предоставлять Данные на Устройства Конечных пользователей,
                при этом предоставление Данных осуществляется только после
                выполнения Подключения. Предоставление Данных осуществляется
                исключительно при нахождении Конечных пользователей в зоне
                покрытия референсной сети станций МТС, указанной на Сайте, а
                также при наличии стабильного соединения Устройств с сетью
                Интернет.
              </li>
              <li>
                7.10. При поступлении обращений от Пользователя, связанных с
                неработоспособностью (ненадлежащей работоспособностью)
                Платформы, принимать меры по устранению недостатков.
              </li>
              <li>
                7.11. Проводить профилактические работы с временным прекращением
                доступа к Платформе, о времени проведения профилактических работ
                сообщать Пользователю заблаговременно.
              </li>
              <li>
                7.12. Самостоятельно определять порядок выполнения запросов,
                связанных с ненадлежащей работоспособностью Платформы в
                зависимости от их сложности.
              </li>
            </ul>
          </div>

          <div className={classes.blockChapter}>
            <p className={classes.chapterTitle}>
              8. Стоимость Сервиса и порядок расчётов
            </p>
            <ul className={classes.chapterList}>
              <li>
                8.1. Стоимость Сервиса устанавливается в соответствии с
                тарифами, указанными на Сайте.
              </li>
              <li>
                8.2. Стоимость Сервиса может быть изменена МТС в одностороннем
                порядке путём соответствующего уведомления Пользователя на Сайте
                не позднее 5 (пяти) календарных дней до предполагаемого
                изменения тарифов.
              </li>
              <li>
                8.3. Пользователь обязан оплатить стоимость Сервиса в следующем
                порядке: Пользователь обязан оплатить стоимость Подписки по
                Заказу путём 100% единовременной предоплаты.
              </li>
              <li>
                8.4. Пользователь оплачивает стоимость Подписки на расчётный
                счет МТС на основании выставленного счета. Оплата производится в
                рублях РФ в объёме, указанном в счёте МТС.
              </li>
              <li>
                8.5. Сервис считается оказанным МТС Пользователю в полном объёме
                с момента предоставления МТС Пользователю доступа к Подписке на
                основании Заказа Пользователя после совершения Пользователем
                оплаты Сервиса согласно п. 8.3 Соглашения.
              </li>
              <li>
                8.6. В течение 10 (десяти) дней с момента предоставления МТС
                Пользователю доступа к Подписки МТС направляет Пользователю Акт
                оказанных услуг и счёт-фактуру. Если в течение 5 (пяти) дней с
                момента направления МТС Пользователю Акта оказанных услуг
                Пользователь не подпишет и не направит МТС подписанный со своей
                стороны Акт оказанных услуг или мотивированный отказ от его
                подписания, то Сервис считается надлежаще
                предоставленным/принятым за отчетный период.
              </li>
            </ul>
          </div>

          <div className={classes.blockChapter}>
            <p className={classes.chapterTitle}>
              9. Интеллектуальная собственность
            </p>
            <ul className={classes.chapterList}>
              <li>
                9.1. Все используемые и размещённые на Сайте результаты
                интеллектуальной деятельности, а также сам Сайт являются
                интеллектуальной собственностью их законных правообладателей и
                охраняются законодательством об интеллектуальной собственности
                Российской Федерации, а также соответствующими международными
                правовыми конвенциями.
              </li>
              <li>
                9.2. Любое использование размещённых на Сайте результатов
                интеллектуальной деятельности (в том числе элементов визуального
                оформления Сервиса, символики, текстов, графических изображений,
                иллюстраций, фото, видео, программ, музыки, и других объектов)
                за пределами Соглашения без разрешения МТС и/или надлежащего
                правообладателя является незаконным и может послужить причиной
                для судебного разбирательства и привлечения нарушителей к
                гражданско-правовой, административной и уголовной
                ответственности.
              </li>
              <li>
                9.3. Любое использование Сайта или результатов интеллектуальной
                деятельности, размещённых на них, кроме разрешённого в
                Соглашении или в случае явно выраженного согласия автора
                (правообладателя) на такое использование, без предварительного
                письменного разрешения правообладателя категорически запрещено.
              </li>
              <li>
                9.4. Пользователь не имеет права воспроизводить, повторять,
                копировать, продавать, перепродавать, а также использовать любым
                способом для каких-либо коммерческих целей Сайт и (или)
                какие-либо части содержимого Сайта без согласия МТС и/или
                надлежащего правообладателя результатов интеллектуальной
                деятельности, размещённых на Сайте.
              </li>
              <li>
                9.5. Пользователь понимает и соглашается с тем, что МТС может
                удалять или перемещать любые результаты интеллектуальной
                деятельности, размещённые на Сайте, по своему личному
                усмотрению, по любой причине или без причины, включая без всяких
                ограничений перемещение или удаление результатов
                интеллектуальной деятельности Сайта.
              </li>
            </ul>
          </div>

          <div className={classes.blockChapter}>
            <p className={classes.chapterTitle}>
              10. Ответственность Пользователя
            </p>
            <ul className={classes.chapterList}>
              <li>
                10.1. В случае нарушения Пользователем настоящего Соглашения
                и/или законодательства Российской Федерации, или совершения
                Пользователем противоправных действий или действий, потенциально
                угрожающих нарушению прав третьих лиц или их имущественных
                интересов, МТС вправе приостановить предоставление Сервиса, не
                отвечая за любой вред, который может быть причинён Пользователю
                таким действием. Пользователь обязан устранить нарушение,
                ставшее основанием для приостановления предоставления Сервиса, в
                течение 5 (пяти) дней с момента приостановления МТС
                предоставления Сервиса. МТС вправе в одностороннем порядке
                отказаться от Соглашения в случае, если Пользователь не устранит
                нарушение в течение вышеуказанного срока.
              </li>
              <li>
                10.2. Пользователь несёт полную личную ответственность за
                соответствие способов использования им информации,
                представленной на Сервисе, нормам российского или международного
                законодательства (в том числе нормам права об интеллектуальной
                собственности и о защите информации).
              </li>
              <li>
                10.3. Пользователь соглашается с тем, что возместит МТС любые
                убытки, причинённые МТС в связи с использованием Пользователем
                Сервиса и/или нарушением Пользователем настоящего Соглашения
                и/или прав (в том числе авторских, смежных, патентных,
                информационных и любых иных) третьих лиц.
              </li>
              <li>
                10.4. В случае предъявления третьими лицами претензий к МТС,
                связанных с использованием Пользователем Сервиса с нарушением
                условий Соглашения, Пользователь обязуется своими силами и за
                свой счёт урегулировать указанные претензии с третьими лицами,
                оградив МТС от возможных убытков и разбирательств.
              </li>
            </ul>
          </div>

          <div className={classes.blockChapter}>
            <p className={classes.chapterTitle}>
              11. Ограничение ответственности МТС
            </p>
            <ul className={classes.chapterList}>
              <li>
                11.1. Сервис предоставляется по принципу «как есть», и МТС не
                гарантирует, что все его функциональные возможности будут
                отвечать ожиданиям и целям Пользователя, или смогут быть
                применимы для конкретной его цели, или что функционирование
                Сервиса будет бесперебойным, защищённым или безошибочным. МТС не
                гарантирует исправление всех дефектов, предотвращение перерывов,
                вызванных третьими сторонами или несанкционированным доступом
                третьих лиц.
              </li>
              <li>
                11.2. МТС не несёт ответственности за любые ошибки, упущения,
                прерывания, удаление, дефекты, задержку в обработке или передаче
                данных, кражу, уничтожение или неправомерный доступ третьих лиц
                к результатам интеллектуальной деятельности, размещённым в
                Сервисе (доступным через него).
              </li>
              <li>
                11.3. МТС не несёт ответственности перед Пользователем за любые
                убытки, понесённые Пользователем из-за использования Сервиса,
                возникшие, в том числе, вследствие прекращения работы,
                компьютерного сбоя, проблемами с доступом к сети Интернет или
                ненадлежащей работой Сервиса, а также частичную или полную
                потерю любой информации Пользователя, связанную с использованием
                или с невозможностью использования Сервиса, в том числе в случае
                предварительного уведомления со стороны Пользователя о
                возможности такого ущерба.
              </li>
              <li>
                11.4. МТС не несёт ответственности за наличие или отсутствие в
                Сервисе нужных Пользователю данных.
              </li>
              <li>
                11.5. МТС не инициирует и не контролирует размещение
                Пользователем любых объектов в процессе использования Сервиса,
                не влияет на содержание и целостность информации/материалов, а
                также в момент их размещения не знает и не может знать, нарушают
                ли они охраняемые законом права и интересы третьих лиц,
                международные договоры и законодательство Российской Федерации.
              </li>
              <li>
                11.6. МТС не несёт ответственности перед Пользователем или
                любыми третьими лицами за:
              </li>
              <li>11.6.1. действия Пользователя при использовании Сервиса;</li>
              <li>
                11.6.2. содержание, законность, достоверность и последствия
                применения информации, размещаемой в Сервисе третьими лицами;
              </li>
              <li>
                11.6.3. достоверность рекламной информации, размещаемой в
                Сервисе третьими лицами.
              </li>
              <li>
                11.7. Если при использовании Сервиса будут обнаружены ошибки,
                МТС предпримет меры для их исправления в максимально короткие
                сроки. Стороны соглашаются, что точное определение срока
                устранения ошибок, если такие будут найдены, не может быть
                установлено.
              </li>
              <li>
                11.8. Сервис может содержать ссылки на другие ресурсы сети
                Интернет. Пользователь признает и соглашается с тем, что МТС не
                контролирует и не несёт никакой ответственности за доступность
                этих ресурсов и за их содержание, а также за любые последствия,
                связанные с использованием этих ресурсов. Любые переходы по
                ссылкам, осуществляемые Пользователем, последний производит на
                свой страх и риск.
              </li>
              <li>
                11.9. В любом случае ответственность МТС по Соглашению в
                соответствии со статьёй 15 ГК РФ ограничивается суммой 10 000
                (десять тысяч) рублей. Ответственность может быть возложена на
                МТС только при наличии в его действиях вины.
              </li>
            </ul>
          </div>

          <div className={classes.blockChapter}>
            <p className={classes.chapterTitle}>
              12. Порядок разрешения споров
            </p>
            <ul className={classes.chapterList}>
              <li>
                12.1. Стороны примут все меры для разрешения возникающих
                разногласий посредством переговоров.
              </li>
              <li>
                12.2. В случае недостижения соглашения в ходе переговоров
                заинтересованная Сторона направляет претензию в письменной
                форме, подписанную уполномоченным лицом.
              </li>
              <li>12.3. Претензия направляется любым из следующих способов:</li>
              <li>
                (1) заказным письмом с уведомлением о вручении по адресу,
                указанному в ЕГРЮЛ/ЕГРИП;
              </li>
              <li>
                (2) курьерской доставкой по адресу, указанному в ЕГРЮЛ/ЕГРИП. В
                этом случае факт получения претензии должен подтверждаться
                распиской, которая должна содержать наименование документа и
                дату его получения, а также фамилию, инициалы, должность и
                подпись лица, получившего данный документ.
              </li>
              <li>
                12.4. Претензия влечёт гражданско-правовые последствия для
                Стороны, которой направлена, с момента её доставки указанной
                Стороне или её представителю. Такие последствия возникают и в
                случае, когда претензия не была вручена адресату по зависящим от
                него обстоятельствам.
              </li>
              <li>
                12.5. К претензии должны прилагаться документы, обосновывающие
                предъявленные заинтересованной Стороной требования (в случае их
                отсутствия у другой Стороны), и документы, подтверждающие
                полномочия лица, подписавшего претензию. Указанные документы
                представляются в форме надлежащим образом заверенных копий. Если
                претензия направлена без документов, подтверждающих полномочия
                лица, которое её подписало, то она считается непредъявленной и
                рассмотрению не подлежит.
              </li>
              <li>
                12.6. Сторона, которой направлена претензия, обязана рассмотреть
                полученную претензию и о результатах уведомить заинтересованную
                Сторону в течение 15 (пятнадцати) рабочих дней со дня получения
                претензии. Ответ на претензию направляется любым из способов,
                указанных в п. 12.3 Соглашения.
              </li>
              <li>
                12.7. В случае неурегулирования разногласий в претензионном
                порядке, а также в случае неполучения ответа на претензию в
                течение срока, указанного в п. 12.6 Соглашения, в соответствии
                со статьей 37 Арбитражного процессуального кодекса РФ Стороны
                пришли к соглашению об изменении подсудности, установленной
                статьями 35 и 36 Арбитражного процессуального кодекса РФ,
                следующим образом.
              </li>
              <li>
                Если иное не установлено в Соглашении, то иск (заявление),
                вытекающий из Соглашения (включая Заказы), предъявляется в
                арбитражный суд по месту нахождения истца или по адресу любого
                из филиалов истца. Выбор между арбитражными судами, которым
                согласно настоящему пункту подсудно дело, принадлежит истцу.
              </li>
              <li>
                Если цена иска, вытекающего из Соглашения, превышает 15 000 000
                рублей, то такой иск предъявляется в Арбитражный суд города
                Москвы.
              </li>
            </ul>
          </div>

          <div className={classes.blockChapter}>
            <p className={classes.chapterTitle}>
              13. Обмен электронными сообщениями
            </p>
            <ul className={classes.chapterList}>
              <li>
                13.1. Электронные сообщения, отправленные посредством Личного
                кабинета и электронной почты, являются электронными документами,
                равнозначными документам на бумажном носителе, подписанным
                собственноручной подписью Пользователя (при отправке
                электронного сообщения Пользователем и/или электронного письма с
                адреса электронной почты, указанного при совершении Подписки)
                или уполномоченного лица МТС (при отправке личного сообщения от
                имени МТС и/или электронного письма с корпоративного адреса
                электронной почты МТС).
              </li>
              <li>
                13.2. Стороны признают юридическую и доказательную силу
                электронных сообщений, указанных в п. 13.1 Соглашения, в том
                числе в целях доказывания в судебном процессах.
              </li>
            </ul>
          </div>

          <div className={classes.blockChapter}>
            <p className={classes.chapterTitle}>
              14. Действие, изменение и прекращение Соглашения
            </p>
            <ul className={classes.chapterList}>
              <li>
                14.1. Настоящее Соглашение вступает в силу с даты Акцепта
                Соглашения и действует в течение 1 (одного) года. В случае, если
                не менее чем за 10 (десять) дня до окончания срока действия
                Соглашения ни одна из Сторон не направит другой Стороне
                письменный отказ от продления его действия, Соглашение считается
                пролонгированным каждый раз на тех же условиях на срок,
                аналогичный по протяжённости сроку, указанному в настоящем
                пункте, неограниченное количество раз.
              </li>
              <li>
                14.2. МТС оставляет за собой право по своему личному усмотрению
                изменять и (или) дополнять Соглашение в любое время с
                предварительным уведомлением Пользователя на Сайте или любым
                другим способом по выбору МТС. Действующая редакция Соглашения
                доступна на Сайте.
              </li>
              <li>
                14.3. Дальнейшее использование Сайта и Сервиса после любых
                подобных изменений означает согласие Пользователя с такими
                изменениями и/или дополнениями. Если Пользователь не согласен
                соблюдать настоящее Соглашение, он обязан прекратить
                использование Сайта.
              </li>
              <li>
                14.4. Пользователь обязуется регулярно просматривать Сайт для
                ознакомления с действующей редакцией Соглашения и несёт
                ответственность за последствия такого ознакомления.
              </li>
              <li>14.5. Соглашение может быть расторгнуто досрочно:</li>
              <li>14.5.1. По взаимному соглашению Сторон;</li>
              <li>
                14.5.2. По инициативе МТС в одностороннем порядке с
                предварительным уведомлением Пользователя за 10 (десять)
                календарных дней путём размещения соответствующего
                информационного сообщения на Сайте.
              </li>
            </ul>
          </div>

          <div className={classes.blockChapter}>
            <p className={classes.chapterTitle}>
              15. Антикоррупционная оговорка
            </p>
            <ul className={classes.chapterList}>
              <li>
                15.1. Пользователь обязуется в рамках исполнения настоящего
                Соглашения соблюдать требования применимого антикоррупционного
                законодательства и не предпринимать никаких действий, которые
                могут нарушить нормы антикоррупционного законодательства или
                стать причиной такого нарушения Пользователем, в том числе не
                требовать, не получать, не предлагать, не санкционировать, не
                обещать и не совершать незаконные платежи напрямую, через
                третьих лиц или в качестве посредника, включая (но не
                ограничиваясь) взятки в денежной или любой иной форме,
                каким-либо физическим или юридическим лицам, включая (но не
                ограничиваясь) коммерческим организациям, органам власти и
                самоуправления, государственным служащим, частным компаниям и их
                представителям.
              </li>
              <li>
                15.2. В случае нарушения Пользователем изложенных выше
                антикоррупционных обязательств, МТС вправе в одностороннем
                порядке приостановить исполнение своих обязательств по
                Соглашению до устранения причин такого нарушения или отказаться
                от исполнения Соглашения, направив об этом письменное
                уведомление.
              </li>
            </ul>
          </div>

          <div className={classes.blockChapter}>
            <p className={classes.chapterTitle}>16. Заключительные положения</p>
            <ul className={classes.chapterList}>
              <li>
                16.1. Настоящее Соглашение и отношения между МТС и Пользователем
                регулируются и толкуются в соответствии с законодательством
                Российской Федерации. Вопросы, не урегулированные Соглашением,
                подлежат разрешению в соответствии с законодательством
                Российской Федерации.
              </li>
              <li>
                16.2. Если по тем или иным причинам какие-либо из условий
                Соглашения являются недействительными или не имеющими
                юридической силы, это не оказывает влияния на действительность
                или применимость остальных условий Соглашения.
              </li>
              <li>
                16.3. В течение срока действия Соглашения, а также по истечении
                срока его действия Стороны обязуются не разглашать и не
                использовать в своих интересах, равно как и в интересах любых
                третьих лиц, любую конфиденциальную информацию, в том числе
                деловую, коммерческую, техническую и иную информацию, которая не
                может быть известна Сторонам из общедоступных источников,
                переданную одной Стороной другой Стороне и которая стала
                известной Сторонам в связи с заключением и исполнением
                Соглашения.
              </li>
            </ul>
          </div>
        </div>
      </BlockContent>
    </BlockWide>
  );
};
