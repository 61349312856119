import classNames from "classnames";

import classes from "./mts-badge.module.css";

enum Size {
  XS = "XS",
  S = "S",
  M = "M",
  L = "L",
}
type ISize = keyof typeof Size;

enum BadgeVariant {
  GREY = "GREY",
  CUSTOM_COLOR = "CUSTOM_COLOR",
}

type IBadgeVariant = keyof typeof BadgeVariant;

interface IMTSBadgeProps {
  size: ISize;
  variant?: IBadgeVariant;
  children: any;
  noWrap?: boolean;
  colorBackground?: string;
}

export const MTSBadge = (props: IMTSBadgeProps): JSX.Element => {
  const {
    children,
    size,
    variant = BadgeVariant.GREY,
    noWrap = true,
    colorBackground = "",
  } = props;

  const badgeStyles = classNames({
    [classes.badge]: true,
    [classes.badgeSizeXS]: size === Size.XS,
    [classes.badgeSizeS]: size === Size.S,
    [classes.badgeSizeM]: size === Size.M,
    [classes.badgeSizeL]: size === Size.L,
    [classes.badgeVariantGrey]: variant === BadgeVariant.GREY,
    [classes.badgeVariantCustomColor]: variant === BadgeVariant.CUSTOM_COLOR,
  });

  const badgeTextStyles = classNames({
    [classes.text]: true,
    [classes.textNoWrap]: noWrap,
    [classes.textGrey]: variant === BadgeVariant.GREY,
    [classes.textCustomColor]: variant === BadgeVariant.CUSTOM_COLOR,
    [classes.textSizeXS]: size === Size.XS,
    [classes.textSizeS]: size === Size.S,
    [classes.textSizeM]: size === Size.M,
    [classes.textSizeL]: size === Size.L,
  });

  return (
    <div
      className={badgeStyles}
      style={{
        backgroundColor:
          variant === BadgeVariant.CUSTOM_COLOR && colorBackground
            ? colorBackground
            : undefined,
      }}
    >
      <span className={badgeTextStyles}>{children}</span>
    </div>
  );
};
