import { QueryKeys, DHW_ORDER_STATUS } from "constants/keys";

import { useQuery, useQueryClient } from "react-query";

import { ICheckPaymentOrderStatusPayload, IOrderStatusDTO } from "interfaces";
import { checkPaymentOrderStatus } from "services/api";

import { useNotifications } from "./use-notifications";

interface IUseCheckPaymentOrderStatusProps {
  payload: ICheckPaymentOrderStatusPayload;
}

export const useCheckPaymentOrderStatus = (
  props: IUseCheckPaymentOrderStatusProps
) => {
  const { payload } = props;
  const { addInfoMessage, addErrorMessage } = useNotifications();

  const queryClient = useQueryClient();

  return useQuery(
    [QueryKeys.CHECK_PAYMENT_ORDER_STATUS, payload.paymentId],
    () => checkPaymentOrderStatus(payload),
    {
      enabled: false,
      retry: 0,
      onError: (error: Error) => {
        addErrorMessage({ message: "Не удалось скачать счёт" });
      },
      onSuccess: async (response: IOrderStatusDTO) => {
        const { invoiceLink, dhwOrderStatus } = response;

        if (dhwOrderStatus === DHW_ORDER_STATUS.ERROR) {
          await queryClient.invalidateQueries(QueryKeys.PAYMENTS);
        } else if (
          dhwOrderStatus === DHW_ORDER_STATUS.INVOICE_COMPLETED &&
          invoiceLink
        ) {
          window.open(invoiceLink, "_blank");
        } else {
          addInfoMessage({
            message: "Счёт формируется. Повторите попытку чуть позже",
          });
        }
      },
    }
  );
};
