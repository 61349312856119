import { PATH_MAP, PATH_PERSONAL_AREA } from "constants/routes";

import React from "react";

import { useHistory } from "react-router-dom";

import { useMediaQuery } from "@mui/material";
import { BlockContent } from "components/block-content";
import { BlockWide } from "components/block-wide";
import { MTSButton } from "components/mts-button";
import { MTSCardCustom } from "components/mts-card-custom";
import {
  GAE_ACTION_GROUP,
  GAE_BUTTON_LOCATION,
  GAE_EVENT,
  GAE_EVENT_ACTION,
  GAE_EVENT_CATEGORY,
  GAE_EVENT_LABEL,
  useGoogleAnalyticsEvents,
} from "hooks/google-analytics";
import { useAppTheme } from "hooks/use-app-theme";
import { useProfileData } from "hooks/use-profile-data";

import { ElectroIcon } from "./icons/electro-icon";
import { ITIcon } from "./icons/it-icon";
import { NetworkIcon } from "./icons/network-icon";
import { PersonalAreaIcon } from "./icons/personal-area-icon";
import { ServiceIcon } from "./icons/service-icon";
import classes from "./reslove-cases.module.css";

const infoWithComfortData: string[] = [
  "Передача данных в режиме реального времени (Real-Time Kinematic)",
  "Выгрузка для постобработки спутниковых данных в формате RINEX",
];

const infoWithGoodNetwork: (string | JSX.Element)[] = [
  "14 регионов РФ",
  <>
    Точность позиционирования{" "}
    <span style={{ whiteSpace: "nowrap" }}>до 2-5 сантиметров</span>
  </>,
  "Строительство сети под запросы клиентов",
  "Высокий SLA",
];

const infoWithEcosystemMTS: (string | JSX.Element)[] = [
  "Личный кабинет",
  <>Возможность самостоятельно контролировать и&nbsp;продлевать подписки</>,
  "Электронный документооборот",
];

const infoWithAeroPhotography: (string | JSX.Element)[] = [
  "Съемка протяжённых линейных объектов",
  "Отслеживание хода и этапов строительства",
  <>
    Контроль качества и объёма выполненных работ, мониторинг работы техники
    и&nbsp;подрядчиков
  </>,
  "Рекламная фото- и видеосъёмка",
];

const infoWithAirLaserScan: (string | JSX.Element)[] = [
  <>
    Инженерно-геодезические изыскания и&nbsp;создание трехмерной модели
    местности и&nbsp;объектов
  </>,
  <>Получение трёхмерных моделей объектов в&nbsp;высоком качестве</>,
  <>
    Сканирование объектов со сложным рельефом, покрытых лесным массивом,
    с&nbsp;отсутствием чётких контуров, получение истинного рельефа без потери
    точности
  </>,
  <>
    Создание детальных топографических планов и&nbsp;карт труднодоступной
    местности
  </>,
];

const infoWithIntegration: (string | JSX.Element)[] = [
  "Интеграция с информационными системами девелоперов и застройщиков",
  <>Поддержка технологий BIM и&nbsp;кастомизированных ИТ-решений</>,
];

export const ResolveCases = React.memo((): JSX.Element => {
  const theme = useAppTheme();
  const history = useHistory();
  const { isAuthorized } = useProfileData();
  const { sendGAE } = useGoogleAnalyticsEvents();

  const isMinWidth_1440 = useMediaQuery(theme.breakpoints.up("desktopM_1440"));
  const isMinWidth_960 = useMediaQuery(theme.breakpoints.up("desktopS_960"));
  const isMinWidth_768 = useMediaQuery(theme.breakpoints.up("tablet_768"));

  const handleRedirectToMap = (): void => {
    history.push(PATH_MAP);
  };

  const handleRegistration = (): void => {
    history.push(PATH_PERSONAL_AREA);
  };

  const handleSendMetricsButtonRegistration = (): void => {
    sendGAE({
      id: 76,
      event: GAE_EVENT.vntGeod,
      eventCategory: GAE_EVENT_CATEGORY.geodeziya_i_kadastr,
      eventAction: GAE_EVENT_ACTION.button_click,
      eventLabel: GAE_EVENT_LABEL.zaregistrirovatsya,
      eventValue: null,
      eventContent: null,
      buttonLocation: GAE_BUTTON_LOCATION.screen,
      filterName: null,
      actionGroup: GAE_ACTION_GROUP.non_interactions,
      productName: null,
    });
  };

  const handleSendMetricsButtonToMap = (): void => {
    sendGAE({
      id: 77,
      event: GAE_EVENT.vntGeod,
      eventCategory: GAE_EVENT_CATEGORY.geodeziya_i_kadastr,
      eventAction: GAE_EVENT_ACTION.button_click,
      eventLabel: GAE_EVENT_LABEL.smotret_kartu_pokrytiya,
      eventValue: null,
      eventContent: null,
      buttonLocation: GAE_BUTTON_LOCATION.screen,
      filterName: null,
      actionGroup: GAE_ACTION_GROUP.non_interactions,
      productName: null,
    });
  };

  return (
    <div className={classes.blockResolveCases}>
      <BlockWide>
        <BlockContent>
          <h2 className={classes.title}>Что входит в&nbsp;решение</h2>

          <div className={classes.blockResolveCasesContent}>
            <div className={classes.cards}>
              <MTSCardCustom
                sx={{
                  padding: isMinWidth_1440
                    ? "56px 32px 72px"
                    : isMinWidth_960
                    ? "40px 32px 72px"
                    : isMinWidth_768
                    ? "32px 24px 72px"
                    : "24px 20px 72px",
                }}
              >
                <div className={classes.cardContent}>
                  <h3 className={classes.cardContentTitle}>
                    ДАННЫЕ В&nbsp;УДОБНОМ ФОРМАТЕ
                  </h3>

                  <ul className={classes.cardContentList}>
                    {infoWithComfortData.map((label: string, index: number) => (
                      <li className={classes.cardContentListItem} key={index}>
                        <p className={classes.cardContentListItemText}>
                          {label}
                        </p>
                      </li>
                    ))}
                  </ul>
                  {isAuthorized ? null : (
                    <MTSButton
                      size="M"
                      variant="primary"
                      sx={{
                        width: "100%",
                        maxWidth: "288px",
                        alignSelf: "center",
                      }}
                      onClick={() => {
                        handleSendMetricsButtonRegistration();
                        handleRegistration();
                      }}
                    >
                      Зарегистрироваться
                    </MTSButton>
                  )}
                </div>
              </MTSCardCustom>

              <MTSCardCustom
                sx={{
                  padding: isMinWidth_1440
                    ? "56px 32px 72px"
                    : isMinWidth_960
                    ? "40px 32px 72px"
                    : isMinWidth_768
                    ? "32px 24px 72px"
                    : "24px 20px 72px",
                }}
              >
                <div className={classes.cardContent}>
                  <h3 className={classes.cardContentTitle}>
                    КАЧЕСТВЕННАЯ
                    <br />
                    СЕТЬ
                  </h3>

                  <ul className={classes.cardContentList}>
                    {infoWithGoodNetwork.map((label, index: number) => (
                      <li className={classes.cardContentListItem} key={index}>
                        <p className={classes.cardContentListItemText}>
                          {label}
                        </p>
                      </li>
                    ))}
                  </ul>
                  <MTSButton
                    size="M"
                    variant="primary"
                    sx={{
                      width: "100%",
                      maxWidth: "288px",
                      alignSelf: "center",
                    }}
                    onClick={() => {
                      handleRedirectToMap();
                      handleSendMetricsButtonToMap();
                    }}
                  >
                    Смотреть карту покрытия
                  </MTSButton>
                </div>
              </MTSCardCustom>

              <MTSCardCustom
                sx={{
                  padding: isMinWidth_1440
                    ? "56px 32px 32px"
                    : isMinWidth_960
                    ? "40px 32px 32px"
                    : isMinWidth_768
                    ? "32px 24px 32px"
                    : "24px 20px 24px",
                }}
              >
                <div className={classes.cardContent}>
                  <h3 className={classes.cardContentTitle}>
                    ЭКОСИСТЕМА
                    <br />
                    МТС
                  </h3>

                  <ul className={classes.cardContentList}>
                    {infoWithEcosystemMTS.map(
                      (label: string | JSX.Element, index: number) => (
                        <li className={classes.cardContentListItem} key={index}>
                          <p className={classes.cardContentListItemText}>
                            {label}
                          </p>
                        </li>
                      )
                    )}
                  </ul>
                </div>
              </MTSCardCustom>

              <MTSCardCustom
                sx={{
                  padding: isMinWidth_1440
                    ? "56px 32px 32px"
                    : isMinWidth_960
                    ? "40px 32px 32px"
                    : isMinWidth_768
                    ? "32px 24px 32px"
                    : "24px 20px 24px",
                }}
              >
                <div className={classes.cardContent}>
                  <h3 className={classes.cardContentTitle}>
                    АЭРОФОТО&shy;СЪЁМКА
                  </h3>

                  <ul className={classes.cardContentList}>
                    {infoWithAeroPhotography.map(
                      (label: string | JSX.Element, index: number) => (
                        <li className={classes.cardContentListItem} key={index}>
                          <p className={classes.cardContentListItemText}>
                            {label}
                          </p>
                        </li>
                      )
                    )}
                  </ul>
                </div>
              </MTSCardCustom>

              <MTSCardCustom
                sx={{
                  padding: isMinWidth_1440
                    ? "56px 32px 32px"
                    : isMinWidth_960
                    ? "40px 32px 32px"
                    : isMinWidth_768
                    ? "32px 24px 32px"
                    : "24px 20px 24px",
                }}
              >
                <div className={classes.cardContent}>
                  <h3 className={classes.cardContentTitle}>
                    ВОЗДУШНО-ЛАЗЕРНОЕ СКАНИРОВАНИЕ
                  </h3>

                  <ul className={classes.cardContentList}>
                    {infoWithAirLaserScan.map(
                      (label: string | JSX.Element, index: number) => (
                        <li className={classes.cardContentListItem} key={index}>
                          <p className={classes.cardContentListItemText}>
                            {label}
                          </p>
                        </li>
                      )
                    )}
                  </ul>
                </div>
              </MTSCardCustom>

              <MTSCardCustom
                sx={{
                  padding: isMinWidth_1440
                    ? "56px 32px 32px"
                    : isMinWidth_960
                    ? "40px 32px 32px"
                    : isMinWidth_768
                    ? "32px 24px 32px"
                    : "24px 20px 24px",
                }}
              >
                <div className={classes.cardContent}>
                  <h3 className={classes.cardContentTitle}>ИНТЕГРАЦИИ</h3>

                  <ul className={classes.cardContentList}>
                    {infoWithIntegration.map(
                      (label: string | JSX.Element, index: number) => (
                        <li className={classes.cardContentListItem} key={index}>
                          <p className={classes.cardContentListItemText}>
                            {label}
                          </p>
                        </li>
                      )
                    )}
                  </ul>
                </div>
              </MTSCardCustom>
            </div>

            <div className={classes.cases}>
              <div className={classes.case}>
                <ElectroIcon
                  sx={{
                    width: "96px",
                    height: "96px",
                    color: "transparent",
                    [theme.breakpoints.between("tablet_768", "desktopS_1280")]:
                      {
                        width: "76px",
                        height: "76px",
                      },
                  }}
                />

                <h4 className={classes.caseTitle}>
                  Бесперебойное
                  <br />
                  энергоснабжение
                </h4>
              </div>

              <div className={classes.case}>
                <NetworkIcon
                  sx={{
                    width: "96px",
                    height: "96px",
                    color: "transparent",
                    [theme.breakpoints.between("tablet_768", "desktopS_1280")]:
                      {
                        width: "76px",
                        height: "76px",
                      },
                  }}
                />

                <h4 className={classes.caseTitle}>
                  Надёжный
                  <br />
                  интернет
                </h4>
              </div>

              <div className={classes.case}>
                <ITIcon
                  sx={{
                    width: "96px",
                    height: "96px",
                    color: "transparent",
                    [theme.breakpoints.between("tablet_768", "desktopS_1280")]:
                      {
                        width: "76px",
                        height: "76px",
                      },
                  }}
                />

                <h4 className={classes.caseTitle}>
                  Развитая IT-инфраструктура
                </h4>
              </div>

              <div className={classes.case}>
                <ServiceIcon
                  sx={{
                    width: "96px",
                    height: "96px",
                    color: "transparent",
                    [theme.breakpoints.between("tablet_768", "desktopS_1280")]:
                      {
                        width: "76px",
                        height: "76px",
                      },
                  }}
                />

                <h4 className={classes.caseTitle}>
                  Сервисная
                  <br />
                  служба
                </h4>
              </div>

              <div className={classes.case}>
                <PersonalAreaIcon
                  sx={{
                    width: "96px",
                    height: "96px",
                    color: "transparent",
                    [theme.breakpoints.between("tablet_768", "desktopS_1280")]:
                      {
                        width: "76px",
                        height: "76px",
                      },
                  }}
                />

                <h4 className={classes.caseTitle}>
                  Личный
                  <br />
                  кабинет
                </h4>
              </div>
            </div>
          </div>
        </BlockContent>
      </BlockWide>
    </div>
  );
});
