import { IPayGroupFormStruct } from "components/payment-configurator";
import { IPayGroupDTOPayload } from "interfaces";

export const getPayGroupsPayload = (
  formData: IPayGroupFormStruct[]
): IPayGroupDTOPayload[] => {
  let payload: IPayGroupDTOPayload[] = [];

  if (formData?.length) {
    payload = formData
      .filter((payGroup) => {
        return (
          payGroup.count &&
          payGroup.duration &&
          payGroup.serviceKind &&
          payGroup.payGroupId
        );
      })
      .map((payGroup) => {
        return {
          subscriptionsCount: Math.round(Number(payGroup.count)),
          duration: payGroup?.duration?.code || "",
          serviceKind: payGroup?.serviceKind?.code || "",
          payGroupId: payGroup.payGroupId,
        };
      });
  }

  return payload;
};
