import React from "react";

import { BrowserRouter as Router } from "react-router-dom";

import ReactDOM from "react-dom";

import { ThemeProvider } from "@mui/material";
import { App } from "components/app";
import { AxiosInterceptor } from "components/axios-interceptor";
import { ErrorBoundary } from "components/error-boundary";
import { ReactQueryWrapper } from "components/react-query-wrapper";
import { ContextMainProvider } from "store/context-main";
import { ContextProfileWidgetProvider } from "store/context-profile-widget";
import { theme } from "theme";
import "css/index.css";

ReactDOM.render(
  <ReactQueryWrapper>
    <ErrorBoundary>
      <ContextMainProvider>
        <ContextProfileWidgetProvider>
          <AxiosInterceptor>
            <ThemeProvider theme={theme}>
              <Router>
                <App />
              </Router>
            </ThemeProvider>
          </AxiosInterceptor>
        </ContextProfileWidgetProvider>
      </ContextMainProvider>
    </ErrorBoundary>
  </ReactQueryWrapper>,
  document.getElementById("root")
);
