import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const WifiTethering = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 12.675C2 7.15499 6.48 2.67499 12 2.67499C17.52 2.67499 22 7.15499 22 12.675C22 16.375 19.99 19.595 17 21.325L16 19.595C18.39 18.205 20 15.635 20 12.675C20 8.25499 16.42 4.67499 12 4.67499C7.58 4.67499 4 8.25499 4 12.675C4 15.635 5.61 18.205 7.99 19.595L6.99 21.325C4.01 19.595 2 16.375 2 12.675ZM18 12.675C18 9.36499 15.31 6.67499 12 6.67499C8.69 6.67499 6 9.36499 6 12.675C6 14.895 7.21 16.825 9 17.865L10 16.125C8.81 15.425 8 14.155 8 12.675C8 10.465 9.79 8.67499 12 8.67499C14.21 8.67499 16 10.465 16 12.675C16 14.155 15.19 15.425 14 16.125L15 17.865C16.79 16.825 18 14.895 18 12.675ZM12 10.675C10.9 10.675 10 11.575 10 12.675C10 13.775 10.9 14.675 12 14.675C13.1 14.675 14 13.775 14 12.675C14 11.575 13.1 10.675 12 10.675Z"
      />
    </SvgIcon>
  );
};

export default WifiTethering;
