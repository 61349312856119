import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const RTK = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="32" height="32" rx="8" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 9.33333C12.3266 9.33333 9.33331 12.3267 9.33331 16C9.33331 19.6733 12.3266 22.6667 16 22.6667C19.6733 22.6667 22.6666 19.6733 22.6666 16C22.6666 12.3267 19.6733 9.33333 16 9.33333ZM16 21.3333C13.06 21.3333 10.6666 18.94 10.6666 16C10.6666 13.06 13.06 10.6667 16 10.6667C18.94 10.6667 21.3333 13.06 21.3333 16C21.3333 18.94 18.94 21.3333 16 21.3333ZM16 18C17.1066 18 18 17.1067 18 16C18 14.8933 17.1066 14 16 14C14.8933 14 14 14.8933 14 16C14 17.1067 14.8933 18 16 18Z"
        fill="white"
      />
    </SvgIcon>
  );
};

export default RTK;
