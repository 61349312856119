import { FIELD_NAMES } from "constants/keys";
import { TABLE_PAGE_SIZE } from "constants/table";

import React from "react";

import {
  useTable,
  usePagination,
  useSortBy,
  Hooks,
  useBlockLayout,
  useGlobalFilter,
  useFilters,
  useRowSelect,
} from "react-table";
import { useSticky } from "react-table-sticky";

import { MTSPagination } from "components/mts-pagination";
import {
  GAP_EVENT_TYPE,
  GA_SCREEN_NAME,
  useGoogleAnalyticsPages,
} from "hooks/google-analytics";
import { useTableSubscriptionsColumns } from "hooks/table/use-table-subscriptions-columns";
import { useServiceKinds } from "hooks/use-service-kinds";
import { useSubscriptionStatuses } from "hooks/use-subscription-statuses";
import { ISubscriptionStruct, ITableSubscriptionsFilters } from "interfaces";
import { ContextSubscriptions } from "store/context-subscriptions";
import { getDictionaryItemNameById } from "utils/get-dictionary-item-name-by-id";
import { getSubscriptionStatusName } from "utils/get-subscription-status-name";
import { SubscriptionStatusCheckerByCode } from "utils/subscription-status-checker-by-code";

import { renderFilterIcon } from "../../utils/render-filter-icon";
import { RenderFilter } from "../render-filter";
import { TableCellSubscriptionName } from "../table-cell-subscription-name";
import { TableCellWithTooltip } from "../table-cell-with-tooltip";
import { TableEmpty } from "../table-empty";
import { TableNotFound } from "../table-not-found";

import classes from "./table-subscriptions-paid.module.css";

enum TableColumnIds {
  blank = "blank",
}

interface ITableSubscriptionsProps {
  data: ISubscriptionStruct[];
  tableFilters: ITableSubscriptionsFilters;
  tableSortBy: any;
}

export const TableSubscriptionsPaid = (props: ITableSubscriptionsProps) => {
  const { data, tableFilters, tableSortBy } = props;

  useGoogleAnalyticsPages({
    screenName: GA_SCREEN_NAME.subs_paid,
    event: GAP_EVENT_TYPE.SCRN,
  });

  const { subscriptionCounts } = React.useContext(ContextSubscriptions);
  const { tableColumns, hiddenColumns } = useTableSubscriptionsColumns();

  const { data: serviceKinds } = useServiceKinds();
  const { data: subscriptionStatuses } = useSubscriptionStatuses();

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setFilter,
    setGlobalFilter,
    state: { pageIndex },
  } = useTable(
    {
      columns: tableColumns,
      data: data,
      initialState: {
        pageIndex: 0,
        pageSize: TABLE_PAGE_SIZE,
        sortBy: tableSortBy,
        hiddenColumns,
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks: Hooks<any>) => {
      hooks.visibleColumns.push((columns) => {
        return [
          ...columns,
          {
            id: TableColumnIds.blank,
            Header: () => {
              return null;
            },
            Cell: () => {
              return null;
            },
            width: 0,
          },
        ];
      });
    },
    useBlockLayout,
    useSticky
  );

  React.useEffect(() => {
    setGlobalFilter(tableFilters.search || "");
    setFilter(
      FIELD_NAMES.SUBSCRIPTION_SERVICE_KIND,
      tableFilters.serviceKind?.id || null
    );

    setFilter(FIELD_NAMES.SUBSCRIPTION_DURATION, tableFilters.duration?.name);
  }, [setFilter, setGlobalFilter, tableFilters, data]);

  if (data.length === 0) {
    return (
      <TableEmpty pageSize={TABLE_PAGE_SIZE} icon="subs" text="Подписок нет" />
    );
  }

  if (data.length && page.length === 0) {
    return <TableNotFound />;
  }

  return (
    <div className={classes.tableContainer}>
      <div className={classes.tableWrap}>
        <div className={classes.table} {...getTableProps()}>
          <div className={classes.thead}>
            {headerGroups.map((headerGroup: any) => (
              <div
                {...headerGroup.getHeaderGroupProps()}
                className={classes.theadTR}
              >
                {headerGroup.headers.map((column: any) => {
                  const columnId: string = column.id;

                  if (columnId === TableColumnIds.blank) {
                    return <div className={classes.blank}></div>;
                  }

                  return (
                    <div
                      className={classes.th}
                      {...column.getHeaderProps({
                        style: {
                          width: column.width,
                        },
                        ...column.getSortByToggleProps({
                          title: null,
                        }),
                      })}
                    >
                      <p
                        className={classes.theadTypography}
                        data-testid={"txt_column_subs_" + columnId}
                      >
                        {column.render("Header")}
                      </p>
                      {renderFilterIcon(columnId) && (
                        <RenderFilter
                          isSorted={column.isSorted}
                          isSortedDesc={column.isSortedDesc}
                          dataTestId={"btn_sort_subs_" + columnId}
                        />
                      )}
                    </div>
                  );
                })}
              </div>
            ))}
          </div>

          <div className={classes.tbody} {...getTableBodyProps()}>
            {page.map((pageRow: any, i: number) => {
              prepareRow(pageRow);

              return (
                <div {...pageRow.getRowProps()} className={classes.tbodyTR}>
                  {pageRow.cells.map((cell: any) => {
                    const { column, value, row } = cell;
                    const columnId: string = column.id;
                    const { status } = row.values;
                    const textStyles: React.CSSProperties = {
                      color: SubscriptionStatusCheckerByCode.isExpired(status)
                        ? "var(--color-text-tertiary)"
                        : "",
                    };

                    if (columnId === FIELD_NAMES.SUBSCRIPTION_NAME) {
                      const subscriptionId: number = row.original.id;

                      return (
                        <div className={classes.td} {...cell.getCellProps()}>
                          <TableCellSubscriptionName
                            subscriptionId={subscriptionId}
                            value={value}
                            subscriptionCountCanRenew={
                              subscriptionCounts.canRenew
                            }
                            columnWidth={column.width}
                            dataTestId={"btn_subs_" + columnId}
                          />
                        </div>
                      );
                    }

                    if (columnId === FIELD_NAMES.SUBSCRIPTION_SERVICE_KIND) {
                      const serviceKindId = value;

                      return (
                        <div className={classes.td} {...cell.getCellProps()}>
                          <TableCellWithTooltip
                            text={getDictionaryItemNameById(
                              serviceKindId,
                              serviceKinds
                            )}
                            textStyles={textStyles}
                            cellWidth={column.width}
                            dataTestId={"txt_subs_" + columnId}
                          />
                        </div>
                      );
                    }

                    if (
                      columnId === FIELD_NAMES.SUBSCRIPTION_DURATION ||
                      columnId === FIELD_NAMES.SUBSCRIPTION_PHONE ||
                      columnId === FIELD_NAMES.SUBSCRIPTION_EMAIL ||
                      columnId === FIELD_NAMES.SUBSCRIPTION_DESCRIPTION
                    ) {
                      return (
                        <div className={classes.td} {...cell.getCellProps()}>
                          <TableCellWithTooltip
                            text={value}
                            textStyles={textStyles}
                            cellWidth={column.width}
                            dataTestId={"txt_subs_" + columnId}
                          />
                        </div>
                      );
                    }

                    if (columnId === FIELD_NAMES.SUBSCRIPTION_STATUS) {
                      const { createdDate, expiredDate } = row.values;
                      const statusName = getSubscriptionStatusName(
                        value,
                        subscriptionStatuses,
                        createdDate,
                        expiredDate
                      );

                      return (
                        <div className={classes.td} {...cell.getCellProps()}>
                          <TableCellWithTooltip
                            text={statusName}
                            textStyles={textStyles}
                            cellWidth={column.width}
                            dataTestId={"txt_subs_" + columnId}
                          />
                        </div>
                      );
                    }

                    if (columnId === TableColumnIds.blank) {
                      return <div className={classes.blank}></div>;
                    }

                    return (
                      <div className={classes.td} {...cell.getCellProps()}>
                        <TableCellWithTooltip
                          text={value}
                          cellWidth={column.width}
                          dataTestId={"txt_subs_" + columnId}
                        />
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
      </div>

      {Boolean(pageCount > 1) && (
        <MTSPagination
          pageIndex={pageIndex}
          pageCount={pageCount}
          canNextPage={canNextPage}
          canPreviousPage={canPreviousPage}
          gotoPage={gotoPage}
          nextPage={nextPage}
          previousPage={previousPage}
        />
      )}
    </div>
  );
};
