import { SS_DONT_SHOW_RENEW_DIALOG } from "constants/keys";

export const getStatusRenewDialog = (): {
  isDontShowDialog: boolean;
} => {
  const isDontShowDialog: boolean =
    sessionStorage.getItem(SS_DONT_SHOW_RENEW_DIALOG) === "1";

  return {
    isDontShowDialog,
  };
};
