import { HTTPStatusCode, QueryKeys } from "constants/keys";

import { useQuery } from "react-query";

import { AxiosError } from "axios";
import { useProfileData } from "hooks/use-profile-data";
import { checkAbilityToCreatePayments } from "services/api";

import { useNotifications } from "./use-notifications";

interface IUseCheckAbilityToCreatePaymentsProps {
  refetchOnWindowFocus?: boolean;
}

export const useCheckAbilityToCreatePayments = (
  props: IUseCheckAbilityToCreatePaymentsProps
) => {
  const { refetchOnWindowFocus = true } = props;
  const { addErrorMessage } = useNotifications();
  const { isCustomerAdminRole } = useProfileData();

  return useQuery(
    QueryKeys.CHECK_ABILITY_TO_CREATE_PAYMENTS,
    () => checkAbilityToCreatePayments(),
    {
      enabled: isCustomerAdminRole,
      retry: 0,
      refetchOnWindowFocus: refetchOnWindowFocus,
      onError: (error: AxiosError) => {
        const status = error.response?.status;

        if (status !== HTTPStatusCode.UNAUTHORIZED_401) {
          addErrorMessage({
            message:
              "Не удалось проверить статус возможности создания платежей",
          });
        }
      },
    }
  );
};
