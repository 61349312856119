import { PRODUCT_SUPPORT_URL } from "constants/urls";

import React from "react";

import { MTSIcon } from "components/mts-icon";
import {
  GAE_ACTION_GROUP,
  GAE_BUTTON_LOCATION,
  GAE_EVENT,
  GAE_EVENT_ACTION,
  GAE_EVENT_CATEGORY,
  GAE_EVENT_LABEL,
  useGoogleAnalyticsEvents,
} from "hooks/google-analytics";
import { useAppTheme } from "hooks/use-app-theme";

import classes from "./footer-links.module.css";

interface IAppLink {
  icon: React.ReactNode;
  path: string;
  title: string;
  text: string;
}

interface ILink {
  name: string;
  path: string;
}

const otherLinks: ILink[] = [
  {
    name: "Поддержка",
    path: PRODUCT_SUPPORT_URL,
  },
  {
    name: "Контакты",
    path: "https://moskva.mts.ru/personal/kontaktu/",
  },
  {
    name: "Раскрытие информации",
    path: "https://moskva.mts.ru/about/investoram-i-akcioneram/korporativnoe-upravlenie/raskritie-informacii",
  },
  {
    name: "Документы ПАО «МТС»",
    path: "https://moskva.mts.ru/about/investoram-i-akcioneram/korporativnoe-upravlenie/dokumenti-pao-mts",
  },
  {
    name: "Политика обработки cookies",
    path: "https://moskva.mts.ru/personal/cookie/",
  },
  {
    name: "English",
    path: "http://www.mtsgsm.com/",
  },
  {
    name: "Карта сайта",
    path: "/",
  },
  {
    name: "Новости",
    path: "https://moskva.mts.ru/personal/novosti/",
  },
  {
    name: "Комплаенс и деловая этика",
    path: "https://moskva.mts.ru/about/obshhestvennosti-i-smi/komplaens-i-delovaya-etika",
  },
  {
    name: "Пользовательское соглашение",
    path: "https://static.mts.ru/dpc_upload/images/agreement.pdf?_ga=2.166532667.48180783.1633331529-369494301.1632905117",
  },
];

export const FooterLinks = (): JSX.Element => {
  const theme = useAppTheme();

  const { sendGAE } = useGoogleAnalyticsEvents();

  const [appLinks] = React.useState<IAppLink[]>([
    {
      icon: (
        <MTSIcon.MTSBusiness
          sx={{
            color: "var(--color-brand)",
            borderRadius: "12px",
            fontSize: 44,
            [theme.breakpoints.between("tablet_768", "desktopM_1440")]: {
              fontSize: 32,
            },
          }}
        />
      ),
      path: "https://mymts.onelink.me/uQ4e/mymts",
      title: "Мой МТС",
      text: "О приложении",
    },
    {
      icon: (
        <MTSIcon.MTSCashback
          sx={{
            color: "var(--color-brand)",
            borderRadius: "12px",
            fontSize: 44,
            [theme.breakpoints.between("tablet_768", "desktopM_1440")]: {
              fontSize: 32,
            },
          }}
        />
      ),
      path: "https://snh82.app.goo.gl/B4tX",
      title: "МТС Cashback",
      text: "О приложении",
    },
  ]);

  const handleSendMetrics = (name: string) => {
    if (name === otherLinks[1].name) {
      sendGAE({
        id: 48,
        event: GAE_EVENT.vntCross,
        eventCategory: GAE_EVENT_CATEGORY.kross_elementy,
        eventAction: GAE_EVENT_ACTION.link_click,
        eventLabel: GAE_EVENT_LABEL.kontakty,
        eventValue: null,
        eventContent: null,
        buttonLocation: GAE_BUTTON_LOCATION.screen,
        filterName: null,
        actionGroup: GAE_ACTION_GROUP.interactions,
        productName: null,
      });
    }
  };

  return (
    <div className={classes.blockFooterLinks}>
      <div className={classes.appLinks}>
        {appLinks.map((link: IAppLink) => (
          <a href={link.path} className={classes.appLink} key={link.title}>
            {link.icon}
            <div className={classes.appLinkContent}>
              <p className={classes.appLinkContentTitle}>{link.title}</p>
              <p className={classes.appLinkContentText}>{link.text}</p>
            </div>
          </a>
        ))}
      </div>
      <div className={classes.links}>
        {otherLinks.map((link: ILink) => (
          <a
            className={classes.link}
            href={link.path}
            key={link.name}
            onClick={() => handleSendMetrics(link.name)}
          >
            {link.name}
          </a>
        ))}
      </div>
    </div>
  );
};
