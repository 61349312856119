import { Variant } from "../constants";

export const useButtonTextColor = (props: {
  variant: keyof typeof Variant;
  disabled: boolean;
}): string => {
  const { variant, disabled } = props;

  if (disabled) {
    return "var(--color-text-tertiary)";
  }

  switch (variant) {
    case Variant.primary: {
      return "var(--color-constant-greyscale-0)";
    }
    case Variant.primary_alternate: {
      return "var(--color-text-inverted)";
    }
    case Variant.always_white: {
      return "var(--color-constant-greyscale-800)";
    }
    case Variant.secondary: {
      return "var(--color-text-primary)";
    }
    case Variant.ghost: {
      return "var(--color-text-primary)";
    }
    case Variant.negative: {
      return "var(--color-text-negative)";
    }
    default: {
      return "var(--color-constant-greyscale-0)";
    }
  }
};
