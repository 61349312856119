import { SUBSCRIPTION_SERVICE_KINDS } from "constants/keys";

import React from "react";

import { useInView } from "react-intersection-observer";

import { BlockContent } from "components/block-content";
import { BlockWide } from "components/block-wide";
import { Loader } from "components/loader";
import { TariffCard, TariffCardTest } from "components/tariff-card";
import {
  GAE_ACTION_GROUP,
  GAE_EVENT,
  GAE_EVENT_ACTION,
  GAE_EVENT_CATEGORY,
  GAE_EVENT_LABEL,
  useGoogleAnalyticsEvents,
} from "hooks/google-analytics";
import { useStatusTestSubscription } from "hooks/use-test-subscription-status";

import classes from "./tariffs.module.css";

export const Tariffs = (): JSX.Element => {
  const {
    data: testSubscriptionStatusData,
    isLoading: isLoadingTestSubscriptionStatus,
    isTestSubscriptionUsed,
    isTestSubscriptionUsing,
    isTestSubscriptionNotUsed,
  } = useStatusTestSubscription();
  const { sendGAE } = useGoogleAnalyticsEvents();
  const { ref: blockTariffsRef, inView: inViewBlockTariffs } = useInView({
    initialInView: false,
    threshold: 0.5,
    triggerOnce: true,
  });

  React.useEffect(() => {
    if (inViewBlockTariffs) {
      sendGAE({
        id: 43,
        event: GAE_EVENT.vntMain,
        eventCategory: GAE_EVENT_CATEGORY.glavnaya,
        eventAction: GAE_EVENT_ACTION.block_show,
        eventLabel: GAE_EVENT_LABEL.tarify,
        eventValue: null,
        eventContent: null,
        buttonLocation: null,
        filterName: null,
        actionGroup: GAE_ACTION_GROUP.non_interactions,
        productName: null,
      });
    }
  }, [inViewBlockTariffs, sendGAE]);

  if (isLoadingTestSubscriptionStatus) {
    return <Loader />;
  }

  return (
    <div ref={blockTariffsRef} className={classes.blockTariffs}>
      <BlockWide>
        <BlockContent>
          <>
            <h1 className={classes.title}>Тарифы</h1>

            <div className={classes.cards}>
              <TariffCardTest
                isPersonalAreaPage={false}
                isTestSubscriptionUsed={isTestSubscriptionUsed}
                isTestSubscriptionUsing={isTestSubscriptionUsing}
                isTestSubscriptionNotUsed={isTestSubscriptionNotUsed}
                expiredDate={
                  testSubscriptionStatusData?.testModeExpiredOn ?? ""
                }
              />
              <div className={classes.cardsTariffs}>
                <TariffCard
                  isPersonalAreaPage={false}
                  serviceKindType={SUBSCRIPTION_SERVICE_KINDS.RTK_RINEX}
                />
                <TariffCard
                  isPersonalAreaPage={false}
                  serviceKindType={SUBSCRIPTION_SERVICE_KINDS.RINEX}
                />
              </div>
            </div>

            <p className={classes.info}>
              <p className={classes.infoText}>
                Сервис предоставляет данные в системе{" "}
                <span style={{ whiteSpace: "nowrap" }}>
                  координат ITRF2014, эпоха — январь 2022
                </span>
              </p>
            </p>
          </>
        </BlockContent>
      </BlockWide>
    </div>
  );
};
