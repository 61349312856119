import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const Avatar = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.908 27.4313C23.2538 29.7338 19.7896 31.1273 16 31.1273C12.2104 31.1273 8.74626 29.7338 6.09204 27.4313C6.91577 27.0506 7.78479 26.7719 8.61116 26.507C10.4537 25.9162 12.0842 25.3934 12.5277 23.9587V21.4645C12.0068 21.1881 10.5183 19.2765 10.3609 17.7818C10.345 17.6306 10.2148 17.5314 10.0433 17.4007C9.71741 17.1522 9.2422 16.79 9.11783 15.7413C9.01597 14.8822 9.42108 14.3985 9.66646 14.2465C9.66646 14.2465 9.05532 12.8601 9.05532 11.4851C9.05532 7.39478 11.0901 4 16 4C18.6506 4 19.4723 5.87013 19.4723 5.87013C21.8405 5.87013 22.9447 8.45421 22.9447 11.4851C22.9447 12.9959 22.3336 14.2465 22.3336 14.2465C22.5789 14.3985 22.984 14.8822 22.8822 15.7413C22.6924 17.3419 22.0488 17.7473 21.6391 17.7818C21.4817 19.2742 19.9932 21.1858 19.4723 21.4645V23.9587C19.9158 25.3934 21.5463 25.9162 23.3888 26.507C24.2152 26.7719 25.0842 27.0506 25.908 27.4313Z"
        fill="white"
      />
    </SvgIcon>
  );
};

export default Avatar;
