import React from "react";

import { SelectChangeEvent, useMediaQuery } from "@mui/material";
import { BlockContent } from "components/block-content";
import { BlockWide } from "components/block-wide";
import { IMTSTab, MTSTabs } from "components/mts-tabs";
import { TabsMobile } from "components/tabs-mobile";
import {
  GAE_ACTION_GROUP,
  GAE_BUTTON_LOCATION,
  GAE_EVENT,
  GAE_EVENT_ACTION,
  GAE_EVENT_CATEGORY,
  GAE_EVENT_LABEL,
  useGoogleAnalyticsEvents,
} from "hooks/google-analytics";
import { useAppTheme } from "hooks/use-app-theme";

import classes from "./cases.module.css";
import constructionCompanyImg from "./img/construction-company-img.jpg";
import forestryImg from "./img/forestry-img.jpg";
import industryCompanyImg from "./img/industry-company-img.jpg";
import roadCompanyImg from "./img/road-company-img.jpg";

const enum TAB_NAMES {
  CONSTRUCTION_COMPANY = "constructionCompany",
  INDUSTRY_COMPANY = "industryCopmany",
  ROAD_COMPANY = "roadCompany",
  FORESTRY = "forestry",
}

const tabs: IMTSTab[] = [
  {
    id: TAB_NAMES.CONSTRUCTION_COMPANY,
    name: "Строительная компания",
    type: "link",
    href: "",
    access: ["all"],
  },
  {
    id: TAB_NAMES.INDUSTRY_COMPANY,
    name: "Промышленное предприятие",
    type: "link",
    href: "",
    access: ["all"],
  },
  {
    id: TAB_NAMES.ROAD_COMPANY,
    name: "Автодорожное предприятие",
    type: "link",
    href: "",
    access: ["all"],
  },
  {
    id: TAB_NAMES.FORESTRY,
    name: "Лесное хозяйство",
    type: "link",
    href: "",
    access: ["all"],
  },
];

export const Cases = React.memo((): JSX.Element => {
  const theme = useAppTheme();
  const { sendGAE } = useGoogleAnalyticsEvents();

  const isMinWidthDesktop_1280 = useMediaQuery(
    theme.breakpoints.up("desktopS_1280")
  );

  const [selectedTab, setSelectedTab] = React.useState<number | boolean>(0);
  const [selectedTabId, setSelectedTabId] = React.useState<string>(tabs[0].id);
  const [selectedMobileTab, setSelectedMobileTab] = React.useState<string>(
    tabs[0].name
  );

  const handleChangeTab = (
    event: React.SyntheticEvent,
    newTabNumber: number
  ): void => {
    const tabId: string = tabs[newTabNumber].id;
    setSelectedTabId(tabId);
    setSelectedTab(newTabNumber);
  };

  const handleChangeMobileTab = (event: SelectChangeEvent): void => {
    setSelectedMobileTab(event.target.value);
  };

  const handleSendMetrics = (e: React.SyntheticEvent, tabName: string) => {
    if (e.type === "click") {
      let eventLabel = GAE_EVENT_LABEL.stroitelnaya_kompaniya;
      let id = 80;
      if (tabName === tabs[1].name) {
        eventLabel = GAE_EVENT_LABEL.promyshlennoe_predpriyatie;
        id = 81;
      } else if (tabName === tabs[2].name) {
        eventLabel = GAE_EVENT_LABEL.avtodorozhnoe_predpriyatie;
        id = 82;
      } else if (tabName === tabs[3].name) {
        eventLabel = GAE_EVENT_LABEL.lesnoe_hozyaistvo;
        id = 83;
      }

      sendGAE({
        id: id,
        event: GAE_EVENT.vntGeod,
        eventCategory: GAE_EVENT_CATEGORY.geodeziya_i_kadastr,
        eventAction: GAE_EVENT_ACTION.tab_click,
        eventLabel: eventLabel,
        eventValue: null,
        eventContent: null,
        buttonLocation: GAE_BUTTON_LOCATION.screen,
        filterName: null,
        actionGroup: GAE_ACTION_GROUP.non_interactions,
        productName: null,
      });
    }
  };

  return (
    <div className={classes.blockCases}>
      <BlockWide>
        <BlockContent>
          <h2 className={classes.title}>Кейсы</h2>

          {isMinWidthDesktop_1280 ? (
            <div className={classes.tabs}>
              <MTSTabs
                size="m"
                selectedTab={selectedTab}
                tabs={tabs}
                onChange={handleChangeTab}
                onClick={(e, tabName: string) => handleSendMetrics(e, tabName)}
              />
            </div>
          ) : (
            <div className={classes.tabsMobile}>
              <TabsMobile
                tabs={tabs}
                selectedMobileTab={selectedMobileTab}
                handleChangeMobileTab={handleChangeMobileTab}
                geodesyCadastre={true}
                onClick={(e, tabName: string) => handleSendMetrics(e, tabName)}
              />
            </div>
          )}

          <div className={classes.contentWrapper}>
            {(isMinWidthDesktop_1280
              ? selectedTabId === TAB_NAMES.CONSTRUCTION_COMPANY
              : selectedMobileTab === tabs[0].name) && (
              <>
                <div className={classes.contentInfo}>
                  <div className={classes.contentInfoItem}>
                    <h2 className={classes.contentInfoItemTitle}>Задача</h2>

                    <div className={classes.contentInfoItemList}>
                      <p className={classes.contentInfoItemListText}>
                        Организовать геодезическую поддержку строящегося
                        многофункционального комплекса на&nbsp;всех этапах
                        строительства.
                      </p>
                      <p className={classes.contentInfoItemListText}>
                        Провести инструментальную оценку соответствия
                        возводимого здания и сопутствующих сооружений проектной
                        геометрии.
                      </p>
                    </div>
                  </div>

                  <div className={classes.contentInfoItem}>
                    <h2 className={classes.contentInfoItemTitle}>Решение</h2>

                    <div className={classes.contentInfoItemList}>
                      <p className={classes.contentInfoItemListText}>
                        Предоставление доступов специалистам компании,
                        отвечающим за выполнение кадастровых
                        и&nbsp;геодезических работ.
                      </p>
                      <p className={classes.contentInfoItemListText}>
                        Обучение специалистов клиента, помощь в&nbsp;настройке
                        профиля и подключении оборудования.
                      </p>
                      <p className={classes.contentInfoItemListText}>
                        Предоставление корректирующих поправок в&nbsp;форматах
                        RTK и RINEX, обеспечивающих выполнение геодезических
                        работ с высокой точностью.
                      </p>
                    </div>
                  </div>

                  <div className={classes.contentInfoItem}>
                    <h2 className={classes.contentInfoItemTitle}>Результат</h2>

                    <div className={classes.contentInfoItemList}>
                      <p className={classes.contentInfoItemListText}>
                        Снижение риска возникновения внештатных ситуаций,
                        связанных с отклонениями объектов от&nbsp;проектной
                        геометрии, последующей потерей объектами надёжности или
                        полной её утратой.
                      </p>
                      <p className={classes.contentInfoItemListText}>
                        Снижение риска дополнительных затрат на&nbsp;переделку
                        и&nbsp;исправление ошибок.
                      </p>
                      <p className={classes.contentInfoItemListText}>
                        Снижение стоимости страховки возводимых зданий
                        и&nbsp;сооружений, а также объектов, находящихся в зоне
                        влияния строительства.
                      </p>
                    </div>
                  </div>
                </div>
                <div className={classes.contentImg}>
                  <img
                    src={constructionCompanyImg}
                    alt="constructionCompanyImg"
                  />
                </div>
              </>
            )}

            {(isMinWidthDesktop_1280
              ? selectedTabId === TAB_NAMES.INDUSTRY_COMPANY
              : selectedMobileTab === tabs[1].name) && (
              <>
                <div className={classes.contentInfo}>
                  <div className={classes.contentInfoItem}>
                    <h2 className={classes.contentInfoItemTitle}>Задача</h2>

                    <div className={classes.contentInfoItemList}>
                      <p className={classes.contentInfoItemListText}>
                        Геодезическая привязка геологических выработок.
                      </p>
                      <p className={classes.contentInfoItemListText}>
                        Исследование гидрологических створов, точек
                        геофизической разведки.
                      </p>
                      <p className={classes.contentInfoItemListText}>
                        Снизить возможные последствия структурных деформаций
                        сооружений.
                      </p>
                    </div>
                  </div>

                  <div className={classes.contentInfoItem}>
                    <h2 className={classes.contentInfoItemTitle}>Решение</h2>

                    <div className={classes.contentInfoItemList}>
                      <p className={classes.contentInfoItemListText}>
                        Предоставление доступов специалистам компании,
                        отвечающим за выполнение кадастровых
                        и&nbsp;геодезических работ.
                      </p>
                      <p className={classes.contentInfoItemListText}>
                        Обучение специалистов клиента, помощь в&nbsp;настройке
                        профиля и подключении оборудования.
                      </p>
                      <p className={classes.contentInfoItemListText}>
                        Предоставление корректирующих поправок в&nbsp;форматах
                        RTK и RINEX, обеспечивающих выполнение геодезических
                        работ с высокой точностью.
                      </p>
                      <p className={classes.contentInfoItemListText}>
                        Подбор оборудования для организации мониторинга
                        деформаций.
                      </p>
                      <p className={classes.contentInfoItemListText}>
                        Настройка онлайн-мониторинга за текущими значениями
                        отклонений.
                      </p>
                      <p className={classes.contentInfoItemListText}></p>
                    </div>
                  </div>

                  <div className={classes.contentInfoItem}>
                    <h2 className={classes.contentInfoItemTitle}>Результат</h2>

                    <div className={classes.contentInfoItemList}>
                      <p className={classes.contentInfoItemListText}>
                        Выполнение необходимых инженерно-геодезических
                        изысканий: подготовка геодезической основы, выполнение
                        топографической съёмки, подготовка топографической
                        документации.
                      </p>
                      <p className={classes.contentInfoItemListText}>
                        Разбивка осей здания, перенос проекта на строительную
                        площадку, расчёт земляных работ.
                      </p>
                      <p className={classes.contentInfoItemListText}>
                        Разработка проекта прокладки коммуникаций, подключения к
                        водоснабжению, газовым магистралям и электросетям и
                        оформление полного пакета документации.
                      </p>
                      <p className={classes.contentInfoItemListText}>
                        Снижение риска обрушения, дополнительных расходов на
                        эксплуатацию за счёт своевременного реагирования на
                        структурные изменения сооружений.
                      </p>
                    </div>
                  </div>
                </div>
                <div className={classes.contentImg}>
                  <img src={industryCompanyImg} alt="industryCompanyImg" />
                </div>
              </>
            )}

            {(isMinWidthDesktop_1280
              ? selectedTabId === TAB_NAMES.ROAD_COMPANY
              : selectedMobileTab === tabs[2].name) && (
              <>
                <div className={classes.contentInfo}>
                  <div className={classes.contentInfoItem}>
                    <h2 className={classes.contentInfoItemTitle}>Задача</h2>

                    <div className={classes.contentInfoItemList}>
                      <p className={classes.contentInfoItemListText}>
                        Определить уровень положения дорожного покрытия.
                      </p>
                      <p className={classes.contentInfoItemListText}>
                        Вычислить оптимальные углы поворотов магистралей.
                      </p>
                      <p className={classes.contentInfoItemListText}>
                        Организовать постоянный мониторинг за осадкой
                        и&nbsp;деформацией дорог.
                      </p>
                    </div>
                  </div>

                  <div className={classes.contentInfoItem}>
                    <h2 className={classes.contentInfoItemTitle}>Решение</h2>

                    <div className={classes.contentInfoItemList}>
                      <p className={classes.contentInfoItemListText}>
                        Организация аэрофотосъёмки на участке, где планируются
                        строительные работы.
                      </p>
                      <p className={classes.contentInfoItemListText}>
                        Создание детальных топографических планов и&nbsp;карт
                        проекта.
                      </p>
                      <p className={classes.contentInfoItemListText}>
                        Предоставление доступов специалистам компании,
                        отвечающим за выполнение кадастровых
                        и&nbsp;геодезических работ.
                      </p>
                      <p className={classes.contentInfoItemListText}>
                        Обучение специалистов клиента, помощь в&nbsp;настройке
                        профиля и подключении оборудования.
                      </p>
                      <p className={classes.contentInfoItemListText}>
                        Предоставление корректирующих поправок в&nbsp;форматах
                        RTK и RINEX, обеспечивающих выполнение геодезических
                        работ с высокой точностью.
                      </p>
                    </div>
                  </div>

                  <div className={classes.contentInfoItem}>
                    <h2 className={classes.contentInfoItemTitle}>Результат</h2>

                    <div className={classes.contentInfoItemList}>
                      <p className={classes.contentInfoItemListText}>
                        Сокращение затрат на строительство дорог значительной
                        протяжённости.
                      </p>
                      <p className={classes.contentInfoItemListText}>
                        Повышение качества строительства дорожного полотна.
                      </p>
                      <p className={classes.contentInfoItemListText}>
                        Снижение эксплуатационных издержек на&nbsp;мониторинг
                        состояния дорог.
                      </p>
                    </div>
                  </div>
                </div>
                <div className={classes.contentImg}>
                  <img src={roadCompanyImg} alt="roadCompanyImg" />
                </div>
              </>
            )}

            {(isMinWidthDesktop_1280
              ? selectedTabId === TAB_NAMES.FORESTRY
              : selectedMobileTab === tabs[3].name) && (
              <>
                <div className={classes.contentInfo}>
                  <div className={classes.contentInfoItem}>
                    <h2 className={classes.contentInfoItemTitle}>Задача</h2>

                    <div className={classes.contentInfoItemList}>
                      <p className={classes.contentInfoItemListText}>
                        Сканирование объектов со сложным рельефом, покрытых
                        лесным массивом, с отсутствием чётких контуров,
                        получение истинного рельефа без потери точности.
                      </p>
                      <p className={classes.contentInfoItemListText}>
                        Подготовка первичных и повторных лесоустроительных
                        работ.
                      </p>
                      <p className={classes.contentInfoItemListText}>
                        Организация территории лесного фонда.
                      </p>
                      <p className={classes.contentInfoItemListText}>
                        Проведение лесотаксационных работ.
                      </p>
                      <p className={classes.contentInfoItemListText}>
                        Проведение инвентаризации лесонасаждений.
                      </p>
                      <p className={classes.contentInfoItemListText}>
                        Отвод лесосек под рубки леса.
                      </p>
                    </div>
                  </div>

                  <div className={classes.contentInfoItem}>
                    <h2 className={classes.contentInfoItemTitle}>Решение</h2>

                    <div className={classes.contentInfoItemList}>
                      <p className={classes.contentInfoItemListText}>
                        Проектирование специального оборудования
                        для&nbsp;выполнения необходимых работ в условиях
                        отсутствия покрытия мобильной связи.
                      </p>
                      <p className={classes.contentInfoItemListText}>
                        Размещение заказа на производство оборудования
                        и&nbsp;авторский надзор.
                      </p>
                      <p className={classes.contentInfoItemListText}>
                        Предоставление поправок в форматах RTK и&nbsp;RINEX.
                      </p>
                      <p className={classes.contentInfoItemListText}>
                        Обучение сотрудников клиента.
                      </p>
                      <p className={classes.contentInfoItemListText}>
                        Организация аэрофотосъёмки и воздушно-лазерного
                        сканирования лесного массива и&nbsp;последующая
                        постобработка данных.
                      </p>
                    </div>
                  </div>

                  <div className={classes.contentInfoItem}>
                    <h2 className={classes.contentInfoItemTitle}>Результат</h2>

                    <div className={classes.contentInfoItemList}>
                      <p className={classes.contentInfoItemListText}>
                        Сокращение затрат на картрирование и&nbsp;детальное
                        описание массива лесного хозяйства.
                      </p>
                      <p className={classes.contentInfoItemListText}>
                        Повышение безопасности проведения работ по&nbsp;рубке
                        леса.
                      </p>
                    </div>
                  </div>
                </div>
                <div className={classes.contentImg}>
                  <img src={forestryImg} alt="forestryImg" />
                </div>
              </>
            )}
          </div>
        </BlockContent>
      </BlockWide>
    </div>
  );
});
