import { BoxSize } from "../constants";
import ok_large from "../svg/ok_large.svg";
import ok_medium from "../svg/ok_medium.svg";
import ok_small from "../svg/ok_small.svg";

export const getIconBySize = (boxSize: string) => {
  switch (boxSize) {
    case BoxSize.small:
      return ok_small;
    case BoxSize.medium:
      return ok_medium;
    case BoxSize.large:
      return ok_large;
    default:
      return ok_small;
  }
};
