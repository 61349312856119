import { BACKEND_ERROR_CODES, QueryKeys } from "constants/keys";

import { useQueryClient, useMutation } from "react-query";

import { AxiosError } from "axios";
import { useNotifications } from "hooks/use-notifications";
import { updateDoPaymentInProcess } from "services/api";

export const useMutationDoPaymentInProcess = () => {
  const { addErrorMessage, addInfoMessage } = useNotifications();
  const queryClient = useQueryClient();

  return useMutation(
    (id: string) => {
      return updateDoPaymentInProcess(id);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(QueryKeys.PAYMENT_DATA);
        await queryClient.invalidateQueries(QueryKeys.PAYMENTS);
      },
      onError: (error: AxiosError) => {
        const codeError: number | null = error?.response?.data?.code ?? null;

        if (
          codeError &&
          codeError === BACKEND_ERROR_CODES.PAYMENT_CANNOT_BE_IN_PROCESS
        ) {
          addInfoMessage({
            message: "Счёт формируется. Повторите попытку чуть позже",
          });
        } else {
          addErrorMessage({ message: "Не удалось запросить платёж" });
        }
      },
    }
  );
};
