import { BoxSize } from "../constants";

export const getBoxSize = (boxSize: string): number => {
  switch (boxSize) {
    case BoxSize.small:
      return 16;
    case BoxSize.medium:
      return 24;
    case BoxSize.large:
      return 32;
    default:
      return 16;
  }
};
