import { PATH_TEST_PAGE } from "constants/routes";

import React from "react";

import { useHistory, useLocation } from "react-router-dom";

import { AppHelmet } from "components/app-helmet";
import { BlockContent } from "components/block-content";
import { BlockWide } from "components/block-wide";
import { IMTSTab, MTSTabs } from "components/mts-tabs";
import { generateId } from "utils/generate-id";

import { TestMtsIcons } from "./components/test-mts-icons";
import { TestNotificationPage } from "./components/test-notification-page";
import { TypographyPage } from "./components/typography-page";
import classes from "./test-page.module.css";

const tabs: IMTSTab[] = [
  { id: generateId(), type: "link", name: "Иконки", href: "", access: ["all"] },
  {
    id: generateId(),
    type: "link",
    name: "Типографика",
    href: "",
    access: ["all"],
  },
  {
    id: generateId(),
    type: "link",
    name: "Уведомления",
    href: "",
    access: ["all"],
  },
];

export const TestPage = (): JSX.Element => {
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const tabNumberFromPath = searchParams.get("tab") || "0";
  const [selectedTab, setSelectedTab] = React.useState<number>(
    Number(tabNumberFromPath)
  );

  const handleChangeTab = (
    event: React.SyntheticEvent,
    newTabNumber: number
  ) => {
    setSelectedTab(newTabNumber);
    history.push({
      pathname: PATH_TEST_PAGE,
      search: `tab=${newTabNumber}`,
    });
  };

  return (
    <>
      <AppHelmet title="Тестовая страница" />

      <BlockWide>
        <BlockContent>
          <div className={classes.tabs}>
            <MTSTabs
              size="s"
              selectedTab={selectedTab}
              tabs={tabs}
              onChange={handleChangeTab}
            />
          </div>
          <div className={classes.content}>
            {selectedTab === 0 && <TestMtsIcons />}
            {selectedTab === 1 && <TypographyPage />}
            {selectedTab === 2 && <TestNotificationPage />}
          </div>
        </BlockContent>
      </BlockWide>
    </>
  );
};
