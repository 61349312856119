import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const BeelineDisabled = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="32" height="32" rx="8" fill="#E2E5EB" />
      <path
        d="M19.006 21.2144C16.6306 22.5877 14.052 22.833 12.4054 22.0132C14.5916 23.3165 17.2893 23.3305 19.4895 22.0622C21.6687 20.829 23.014 18.4606 22.986 15.8961C22.8879 17.7319 21.3814 19.841 19.006 21.2144Z"
        fill="white"
      />
      <path
        d="M16.8828 9.51934C17.3663 10.3532 16.2031 11.9297 14.2832 13.0369C12.3633 14.144 10.4223 14.3682 9.93886 13.5344C9.91784 13.4923 9.89682 13.4503 9.88281 13.4082C9.79172 12.8967 9.93186 12.3782 10.2682 11.9858C10.3242 11.9017 10.3873 11.8246 10.4504 11.7406C11.0109 11.0118 11.7116 10.4022 12.5034 9.94677C13.2952 9.4773 14.1711 9.183 15.075 9.06388C15.1801 9.04987 15.2782 9.03586 15.3763 9.02885C15.8808 8.93776 16.3993 9.07089 16.7987 9.40723C16.8268 9.44226 16.8618 9.4773 16.8828 9.51934Z"
        fill="white"
      />
      <path
        d="M19.8607 10.4162C20.7016 11.8736 19.0059 14.4242 16.0699 16.1199C13.134 17.8156 10.072 18.0048 9.23812 16.5473C9.09798 16.302 9.0209 16.0288 9.00689 15.7415C8.97185 16.7435 9.14703 17.7385 9.53241 18.6634C9.58847 18.8036 9.65153 18.9437 9.7216 19.0838L9.72861 19.1049C9.99487 19.5463 10.3733 19.9037 10.8217 20.1349C12.4683 21.0178 15.2501 20.7165 17.8567 19.21C20.4563 17.7105 22.11 15.4472 22.1731 13.5833C22.2011 13.0718 22.082 12.5673 21.8297 12.1259C21.7316 11.9857 21.6335 11.8456 21.5284 11.7125C20.9188 10.9277 20.155 10.283 19.2862 9.82758C19.5244 9.97472 19.7206 10.1779 19.8607 10.4162Z"
        fill="white"
      />
    </SvgIcon>
  );
};

export default BeelineDisabled;
