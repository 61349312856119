import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const Clear = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.18814 4.47921C8.24692 4.45141 8.32036 4.41856 8.38026 4.39327C9.31166 4 10.2101 4 12.0069 4C13.5551 4 14.9062 4.06759 16.0581 4.16763C18.0819 4.3434 19.0938 4.43128 20.3823 5.7253C21.6708 7.01931 21.7516 7.99011 21.9131 9.9317C21.967 10.5802 22 11.2736 22 12C22 12.7265 21.967 13.42 21.9131 14.0685C21.7516 16.01 21.6708 16.9807 20.3823 18.2747C19.0938 19.5687 18.082 19.6566 16.0583 19.8324C14.9064 19.9325 13.5552 20.0001 12.0069 20.0001C10.2105 20.0001 9.31227 20.0001 8.38104 19.607C8.32071 19.5815 8.24674 19.5484 8.18754 19.5204C7.27381 19.0881 6.62964 18.3678 5.34132 16.9272L5.34131 16.9272L4.51173 15.9995L4.46902 15.9507C2.82306 14.0683 2.00007 13.1271 2 12C1.99993 10.8728 2.82279 9.93155 4.4685 8.04896L4.46851 8.04896L4.51173 7.99951L5.34091 7.07256L5.34091 7.07256C6.62971 5.63177 7.27412 4.91138 8.18814 4.47921ZM16.2106 8.79241C16.6008 9.18293 16.6008 9.81609 16.2106 10.2066L14.4187 11.9998L16.2103 13.7927C16.6006 14.1833 16.6006 14.8164 16.2103 15.207C15.8201 15.5975 15.1874 15.5975 14.7972 15.207L13.0055 13.414L11.2142 15.2066C10.8239 15.5971 10.1912 15.5971 9.801 15.2066C9.41075 14.8161 9.41075 14.1829 9.801 13.7924L11.5923 11.9998L9.80078 10.207C9.41054 9.81643 9.41054 9.18327 9.80078 8.79274C10.191 8.40222 10.8237 8.40222 11.214 8.79274L13.0055 10.5856L14.7974 8.79241C15.1876 8.40188 15.8203 8.40188 16.2106 8.79241Z"
        />
      </g>
    </SvgIcon>
  );
};

export default Clear;
