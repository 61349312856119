import Collapse from "@mui/material/Collapse";
import Fade from "@mui/material/Fade";

interface ITransitionCollapseFadeProps {
  isShow: boolean;
  delay?: number;
  children: any;
}

export const TransitionCollapseFade = (
  props: ITransitionCollapseFadeProps
): JSX.Element => {
  const { isShow, delay = 700, children } = props;

  return (
    <Collapse in={isShow} sx={{ width: "100%" }}>
      <Fade in={isShow} timeout={delay}>
        <div>{children}</div>
      </Fade>
    </Collapse>
  );
};
