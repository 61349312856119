import classes from "./pulse-button.module.css";

interface IPulseButtonProps {
  onClick: () => void;
}

export const PulseButton = (props: IPulseButtonProps): JSX.Element => {
  const { onClick } = props;

  return (
    <button className={classes.btn} onClick={onClick}>
      <span className={classes.btnContent}>
        <svg
          width="52"
          height="52"
          viewBox="0 0 52 52"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M45.9812 25.5095C45.9812 14.6722 36.9935 5.88684 25.9066 5.88684C14.8197 5.88684 5.83203 14.6722 5.83203 25.5095C5.83203 36.3468 14.8197 45.1321 25.9066 45.1321C36.9935 45.1321 45.9812 36.3468 45.9812 25.5095ZM6.27813 25.5095C6.27813 14.913 15.0661 6.3229 25.9066 6.3229C36.7471 6.3229 45.5351 14.913 45.5351 25.5095C45.5351 36.1059 36.7471 44.6961 25.9066 44.6961C15.0661 44.6961 6.27813 36.1059 6.27813 25.5095Z"
            fill="url(#paint0_linear_5944_407599)"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M37.9489 25.5094C37.9489 19.0071 32.5563 13.7358 25.9041 13.7358C19.252 13.7358 13.8594 19.0071 13.8594 25.5094C13.8594 32.0118 19.252 37.283 25.9041 37.283C32.5563 37.283 37.9489 32.0118 37.9489 25.5094ZM14.127 25.5094C14.127 19.1516 19.3998 13.9975 25.9041 13.9975C32.4084 13.9975 37.6812 19.1516 37.6812 25.5094C37.6812 31.8673 32.4084 37.0214 25.9041 37.0214C19.3998 37.0214 14.127 31.8673 14.127 25.5094Z"
            fill="url(#paint1_linear_5944_407599)"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M26 0C40.3594 0 52 11.3785 52 25.4146C52 35.6391 45.8232 44.4534 36.9181 48.4866V47.9985C45.5661 43.9967 51.5478 35.3885 51.5478 25.4146C51.5478 11.6226 40.1097 0.441994 26 0.441994C11.8903 0.441994 0.452174 11.6226 0.452174 25.4146C0.452174 35.387 6.43214 43.9942 15.0781 47.9967V48.4849C6.17507 44.451 0 35.6377 0 25.4146C0 11.3785 11.6406 0 26 0Z"
            fill="url(#paint2_linear_5944_407599)"
          />
          <path
            d="M23.088 25.6667C24.1166 23.591 27.0772 23.591 28.1057 25.6667L38.4229 46.4872C39.4897 48.6401 37.5276 51.0484 35.2035 50.4387L26.3074 48.1049C25.8416 47.9827 25.3522 47.9827 24.8864 48.1049L15.9902 50.4387C13.6661 51.0484 11.704 48.6401 12.7708 46.4872L23.088 25.6667Z"
            fill="black"
          />
          <defs>
            <linearGradient
              id="paint0_linear_5944_407599"
              x1="25.3356"
              y1="16.4395"
              x2="14.4857"
              y2="42.4326"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopOpacity="0.68" />
              <stop offset="1" stopColor="white" stopOpacity="0.66" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_5944_407599"
              x1="26.6897"
              y1="15.7834"
              x2="19.5265"
              y2="37.3823"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopOpacity="0.7" />
              <stop offset="0.442708" stopColor="white" stopOpacity="0.62" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_5944_407599"
              x1="0"
              y1="0"
              x2="0"
              y2="50.8302"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopOpacity="0.4" />
              <stop offset="1" stopColor="white" stopOpacity="0.61" />
            </linearGradient>
          </defs>
        </svg>

        <span className={classes.btnLabel}>
          карта
          <br />
          покрытия
        </span>
      </span>
    </button>
  );
};
