import { PHONE_MASK } from "constants/keys";

import { getPhoneNumberWithoutMask } from "utils/get-phone-number-without-mask";

const correctPhone = (value: string): boolean => {
  if (value.length === PHONE_MASK.length) {
    const phoneWM = getPhoneNumberWithoutMask(value);

    return phoneWM.length === 10;
  } else {
    const phoneNumber: string = value.replace(/\D/g, "");

    return (
      phoneNumber.length === 0 ||
      phoneNumber.length === 1 ||
      phoneNumber.length === 10
    );
  }
};

const requiredPhone = (value: string): boolean => {
  return value?.length === 10 || value?.length === PHONE_MASK.length;
};

const correctEmail = (value: string): boolean => {
  if (value) {
    const emailCheck =
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

    return emailCheck.test(value) ? true : false;
  }

  return true;
};

const requiredEmail = (value: string): boolean => {
  return !!value?.length;
};

export const validationMethod = {
  correctPhone,
  requiredPhone,
  correctEmail,
  requiredEmail,
};
