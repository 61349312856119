import React from "react";

import classes from "./block-content.module.css";

interface IBlockContentProps {
  children: React.ReactNode;
  sx?: React.CSSProperties;
}

export const BlockContent = (props: IBlockContentProps): JSX.Element => {
  const { children, sx } = props;

  return (
    <div className={classes.blockContent} style={sx}>
      {children}
    </div>
  );
};
