import { PAYMENT_ACTION_TYPE, PAYMENT_TYPE } from "constants/keys";
import {
  PATH_ADD_SUBSCRIPTIONS,
  PATH_SUBSCRIPTIONS,
  ROUTE_SUBSCRIPTIONS_ALL,
  SEARCH_PARAM_PAYMENT_IN_SUBS,
} from "constants/routes";

import React from "react";

import { useHistory } from "react-router-dom";

import { DialogConfirm, IDialogConfirmProps } from "components/dialog-confirm";
import { MTSButton } from "components/mts-button";
import { useCheckPaymentOrderStatus } from "hooks/use-check-payment-order-status";
import { useMutationDeletePayment } from "hooks/use-mutation-delele-payment";
import { useMutationDoPaymentInProcess } from "hooks/use-mutation-do-payment-in-process";
import { IPaymentDTO } from "interfaces";
import { PaymentStatusCheckerByCode } from "utils/payment-status-checker-by-code";

import { PaymentCardDiscounts } from "./components/payment-card-discounts";
import { PaymentCardInfo } from "./components/payment-card-info";
import { PaymentCardPayGroups } from "./components/payment-card-pay-groups";
import classes from "./payment-card.module.css";

interface IPaymentCardProps {
  data: IPaymentDTO;
  isPaymentInfoPage?: boolean;
}

export const PaymentCard = (props: IPaymentCardProps): JSX.Element => {
  const { data, isPaymentInfoPage = false } = props;
  const history = useHistory();
  const paymentId = String(data?.id) || "";
  const paymentStatusCode: string | undefined = data?.status;
  const invoiceURL: string = data?.order?.redirectLink ?? "";
  const actionType: string = data?.actionType ?? "";
  const paymentType: string = data?.type ?? "";

  const {
    isLoading: isLoadingCheckPaymentOrderStatus,
    refetch: checkPaymentOrderStatus,
  } = useCheckPaymentOrderStatus({
    payload: { paymentId },
  });

  const [dialogConfirm, setDialogConfirm] =
    React.useState<IDialogConfirmProps | null>(null);

  const mutationDoPayment = useMutationDoPaymentInProcess();

  const mutationDeletePayment = useMutationDeletePayment();

  const handleDoPayment = (id: string): void => {
    mutationDoPayment.mutate(id);
  };

  const handleDoRedirectToSubscriptionTab = (id: string): void => {
    history.push(
      `${PATH_SUBSCRIPTIONS}/${ROUTE_SUBSCRIPTIONS_ALL}?${SEARCH_PARAM_PAYMENT_IN_SUBS}=${id}`
    );
  };

  const handleDeletePayment = (id: string): void => {
    const closeDialog = (): void => {
      setDialogConfirm((prevDialogProps) => ({
        ...prevDialogProps,
        isOpen: false,
      }));
    };

    const handleConfirmDialog = (): void => {
      closeDialog();

      mutationDeletePayment.mutate(id, {
        onSuccess: () => {
          if (isPaymentInfoPage) {
            if (paymentType === PAYMENT_TYPE.PROLONGATION) {
              history.push(PATH_SUBSCRIPTIONS);
            } else {
              history.push(PATH_ADD_SUBSCRIPTIONS);
            }
          }
        },
      });
    };

    const dialogProps: IDialogConfirmProps = {
      isOpen: true,
      title: "Удаление платежа",
      content: (
        <p className={classes.dialogText}>
          Вы действительно хотите удалить платёж?
        </p>
      ),
      confirmLabel: "Удалить",
      handleCancel: () => closeDialog(),
      handleClose: () => closeDialog(),
      handleConfirm: () => handleConfirmDialog(),
    };

    setDialogConfirm(dialogProps);
  };

  const handleOpenInvoice = (redirectInvoiceURL: string): void => {
    window.open(redirectInvoiceURL, "_blank");
  };

  return (
    <>
      <div className={classes.blockPaymentCard}>
        <div className={classes.cardContent}>
          <div className={classes.cardInfo}>
            <PaymentCardInfo data={data} />
          </div>

          <div className={classes.cardDiscounts}>
            <PaymentCardDiscounts
              discounts={data?.calculation?.discounts ?? []}
            />
          </div>

          <div className={classes.cardPayGroups}>
            <PaymentCardPayGroups payGroups={data?.payGroups ?? []} />
          </div>
        </div>

        <div className={classes.cardButtons}>
          <div className={classes.btn}>
            {/* NOTE: DRAFT */}
            {PaymentStatusCheckerByCode.isDraft(paymentStatusCode) && (
              <MTSButton
                size="M"
                onClick={() => handleDoPayment(paymentId)}
                loading={mutationDoPayment.isLoading}
                disabled={mutationDeletePayment.isLoading}
              >
                Запросить счёт
              </MTSButton>
            )}

            {/* NOTE: IN PROCESS */}
            {PaymentStatusCheckerByCode.isInProcess(paymentStatusCode) &&
              actionType === PAYMENT_ACTION_TYPE.ADD && (
                <MTSButton
                  size="M"
                  onClick={() => handleOpenInvoice(invoiceURL)}
                  disabled={invoiceURL?.length === 0}
                >
                  Продолжить
                </MTSButton>
              )}

            {PaymentStatusCheckerByCode.isInProcess(paymentStatusCode) &&
              actionType === PAYMENT_ACTION_TYPE.MODIFY && (
                <MTSButton
                  size="M"
                  onClick={() => checkPaymentOrderStatus()}
                  loading={isLoadingCheckPaymentOrderStatus}
                >
                  Скачать счёт
                </MTSButton>
              )}

            {/* NOTE: DONE OR TEST */}
            {(PaymentStatusCheckerByCode.isDone(paymentStatusCode) ||
              PaymentStatusCheckerByCode.isTest(paymentStatusCode)) && (
              <MTSButton
                size="M"
                onClick={() => handleDoRedirectToSubscriptionTab(paymentId)}
              >
                К подпискам
              </MTSButton>
            )}

            {/* NOTE: FAILED */}
            {PaymentStatusCheckerByCode.isFailed(paymentStatusCode) && (
              <MTSButton
                size="M"
                onClick={() => handleDoPayment(paymentId)}
                loading={mutationDoPayment.isLoading}
                disabled={mutationDeletePayment.isLoading}
              >
                Запросить счёт
              </MTSButton>
            )}

            {/* NOTE: QUEUE */}
            {PaymentStatusCheckerByCode.isToQueue(paymentStatusCode) && (
              <MTSButton size="M" onClick={() => {}} disabled={true}>
                Запросить счёт
              </MTSButton>
            )}

            {/* NOTE: BUTTON DELETE */}
            {(PaymentStatusCheckerByCode.isDraft(paymentStatusCode) ||
              PaymentStatusCheckerByCode.isFailed(paymentStatusCode) ||
              PaymentStatusCheckerByCode.isInProcess(paymentStatusCode)) && (
              <MTSButton
                variant="negative"
                size="M"
                onClick={() => handleDeletePayment(paymentId)}
                loading={mutationDeletePayment.isLoading}
              >
                Удалить
              </MTSButton>
            )}
          </div>
        </div>
      </div>
      <DialogConfirm {...dialogConfirm} />
    </>
  );
};
