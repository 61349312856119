import {
  PATH_TARIFFS,
  PATH_SUBSCRIPTIONS,
  PATH_PAYMENTS,
  PATH_SETTINGS,
  PATH_MAP,
} from "constants/routes";

import { GAE_EVENT_LABEL } from "hooks/google-analytics";

export const getEventLabelByTab = (path: string): GAE_EVENT_LABEL => {
  let eventLabelReturn: GAE_EVENT_LABEL = GAE_EVENT_LABEL.pokrytie;

  if (path.includes(PATH_MAP)) {
    eventLabelReturn = GAE_EVENT_LABEL.pokrytie;
  } else if (path.includes(PATH_TARIFFS)) {
    eventLabelReturn = GAE_EVENT_LABEL.tarify;
  } else if (path.includes(PATH_SUBSCRIPTIONS)) {
    eventLabelReturn = GAE_EVENT_LABEL.podpiski;
  } else if (path.includes(PATH_PAYMENTS)) {
    eventLabelReturn = GAE_EVENT_LABEL.platezhi;
  } else if (path.includes(PATH_SETTINGS)) {
    eventLabelReturn = GAE_EVENT_LABEL.nastroiki;
  }

  return eventLabelReturn;
};
