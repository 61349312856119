import { PATH_PERSONAL_AREA, PATH_TARIFFS } from "constants/routes";

import React from "react";

import { useHistory } from "react-router-dom";

import classnames from "classnames";
import { DialogConfirm, IDialogConfirmProps } from "components/dialog-confirm";
import { DialogContactUs } from "components/dialog-contact-us";
import { IDialogOkProps } from "components/dialog-ok";
import { MTSButton } from "components/mts-button";
import {
  GAE_ACTION_GROUP,
  GAE_BUTTON_LOCATION,
  GAE_EVENT,
  GAE_EVENT_ACTION,
  GAE_EVENT_CATEGORY,
  GAE_EVENT_LABEL,
  GAE_PRODUCT_NAME,
  GA_SCREEN_NAME,
  useGoogleAnalyticsEvents,
} from "hooks/google-analytics";
import { useAppTheme } from "hooks/use-app-theme";
import { useContextMain } from "hooks/use-context-main";
import { useProfileData } from "hooks/use-profile-data";
import { useTestSubscription } from "hooks/use-test-subscription";
import { getDateFormatted, IDateFormatted } from "utils/get-date-formatted";

import { TariffCardHeader } from "../tariff-card-header";
import { TariffCardOptions } from "../tariff-card-options";

import { SuccessTestAccessMessage } from "./components/success-test-access-message";
import { TestAccessMessage } from "./components/test-access-message";
import classes from "./tariff-card-test.module.css";

const options: string[] = [
  "RTK-поправки в реальном времени",
  "RINEX-файлы с дискретностью до 1 с",
];

interface ITariffCardTestProps {
  isPersonalAreaPage: boolean;
  isTestSubscriptionUsing: boolean;
  isTestSubscriptionNotUsed: boolean;
  isTestSubscriptionUsed: boolean;
  expiredDate: string;
}

export const TariffCardTest = (
  props: ITariffCardTestProps
): JSX.Element | null => {
  const {
    isPersonalAreaPage,
    isTestSubscriptionUsing,
    isTestSubscriptionNotUsed,
    isTestSubscriptionUsed,
    expiredDate,
  } = props;
  const history = useHistory();
  const theme = useAppTheme();
  const { isAuthorized, setDialogOk } = useContextMain();
  const { sendGAE } = useGoogleAnalyticsEvents();
  const { isCustomerAdminRole, isCustomerBaseRole, isPotentialRole } =
    useProfileData();

  const [dialogConfirm, setDialogConfirm] =
    React.useState<IDialogConfirmProps | null>(null);
  const [isOpenDialogContactUs, setIsOpenDialogContactUs] =
    React.useState<boolean>(false);

  const testSubscriptionExpiredDate: IDateFormatted | null = expiredDate
    ? getDateFormatted(expiredDate)
    : null;

  const isShowInterfaceWithNotUsedTestSuscription: boolean =
    (isTestSubscriptionNotUsed &&
      !isTestSubscriptionUsed &&
      !isTestSubscriptionUsing) ||
    !isAuthorized ||
    (isAuthorized && isCustomerBaseRole);

  const [isShowCard, setIsShowCard] = React.useState<boolean>(false);

  const {
    isLoading: isLoadingTestSubscription,
    refetch: refetchTestSubscription,
  } = useTestSubscription({
    onSuccessRequest: (): void => {
      const dialogProps: IDialogOkProps = {
        isOpen: true,
        title: "Запрос на тестовый доступ отправлен",
        content: <SuccessTestAccessMessage />,
        buttonLabel: "Хорошо",
        handleClose: () => {
          setDialogOk((prevDialogOkProps) => ({
            ...prevDialogOkProps,
            isOpen: false,
          }));
        },
      };

      setDialogOk(dialogProps);
    },
  });

  const handleRedirect = (url: string): void => {
    history.push(url);
  };

  const handleClickToGetTestSubscription = (): void => {
    const closeDialog = (): void => {
      setDialogConfirm((prevDialogProps) => ({
        ...prevDialogProps,
        isOpen: false,
      }));
    };

    const handleCloseDialog = (): void => {
      sendGAE({
        id: 22,
        event: GAE_EVENT.vntTarify,
        eventCategory: GAE_EVENT_CATEGORY.tarify,
        eventAction: GAE_EVENT_ACTION.button_click,
        eventLabel: GAE_EVENT_LABEL.otmena,
        eventValue: "0",
        screenName: GA_SCREEN_NAME.tariffs,
        eventContent: null,
        buttonLocation: GAE_BUTTON_LOCATION.popup,
        filterName: null,
        actionGroup: GAE_ACTION_GROUP.interactions,
        productName: GAE_PRODUCT_NAME.tarif_testovyi_dostup,
      });
      closeDialog();
    };

    const handleConfirmDialog = (): void => {
      refetchTestSubscription();
      closeDialog();

      sendGAE({
        id: 23,
        event: GAE_EVENT.vntTarify,
        eventCategory: GAE_EVENT_CATEGORY.tarify,
        eventAction: GAE_EVENT_ACTION.button_click,
        eventLabel: GAE_EVENT_LABEL.podkluchit_i_aktivirovat,
        eventValue: "0",
        screenName: isPersonalAreaPage
          ? GA_SCREEN_NAME.tariffs
          : GA_SCREEN_NAME.main,
        eventContent: null,
        buttonLocation: GAE_BUTTON_LOCATION.popup,
        filterName: null,
        actionGroup: GAE_ACTION_GROUP.interactions,
        productName: GAE_PRODUCT_NAME.tarif_testovyi_dostup,
      });
    };

    const dialogProps: IDialogConfirmProps = {
      isOpen: true,
      title: "Подключение тестового доступа",
      content: <TestAccessMessage />,
      confirmLabel: "Подключить",
      handleCancel: () => handleCloseDialog(),
      handleClose: () => closeDialog(),
      handleConfirm: () => handleConfirmDialog(),
    };

    setDialogConfirm(dialogProps);
  };

  const handleGetTestSubscription = (): void => {
    if (isPersonalAreaPage) {
      // NOTE: Страница с личного кабинета
      sendGAE({
        id: 21,
        event: GAE_EVENT.vntTarify,
        eventCategory: GAE_EVENT_CATEGORY.tarify,
        eventAction: GAE_EVENT_ACTION.button_click,
        eventLabel: GAE_EVENT_LABEL.podkluchit,
        eventValue: "0",
        screenName: GA_SCREEN_NAME.tariffs,
        eventContent: null,
        buttonLocation: GAE_BUTTON_LOCATION.screen,
        filterName: null,
        actionGroup: GAE_ACTION_GROUP.interactions,
        productName: GAE_PRODUCT_NAME.tarif_testovyi_dostup,
      });

      if (isCustomerAdminRole) {
        handleClickToGetTestSubscription();
      }
    } else {
      // NOTE: Страница с лэндинга
      if (isAuthorized) {
        sendGAE({
          id: 21,
          event: GAE_EVENT.vntTarify,
          eventCategory: GAE_EVENT_CATEGORY.tarify,
          eventAction: GAE_EVENT_ACTION.button_click,
          eventLabel: GAE_EVENT_LABEL.podkluchit,
          eventValue: "0",
          screenName: GA_SCREEN_NAME.main,
          eventContent: null,
          buttonLocation: GAE_BUTTON_LOCATION.screen,
          filterName: null,
          actionGroup: GAE_ACTION_GROUP.interactions,
          productName: GAE_PRODUCT_NAME.tarif_testovyi_dostup,
        });

        if (isCustomerAdminRole) {
          handleClickToGetTestSubscription();
        }
        if (isPotentialRole) {
          handleRedirect(PATH_TARIFFS);
        }
        if (isCustomerBaseRole) {
          handleRedirect(PATH_PERSONAL_AREA);
        }
      } else {
        sendGAE({
          id: 14,
          event: GAE_EVENT.vntLogin,
          eventCategory: GAE_EVENT_CATEGORY.avtorizaciya,
          eventAction: GAE_EVENT_ACTION.button_click,
          eventLabel: GAE_EVENT_LABEL.voiti,
          eventValue: null,
          screenName: GA_SCREEN_NAME.main,
          eventContent: "Podkluchit",
          buttonLocation: GAE_BUTTON_LOCATION.screen,
          filterName: null,
          actionGroup: GAE_ACTION_GROUP.interactions,
          productName: null,
        });

        sendGAE({
          id: 15,
          event: GAE_EVENT.vntMain,
          eventCategory: GAE_EVENT_CATEGORY.glavnaya,
          eventAction: GAE_EVENT_ACTION.button_click,
          eventLabel: GAE_EVENT_LABEL.podkluchit_testovyi_dostup,
          eventValue: null,
          screenName: GA_SCREEN_NAME.main,
          eventContent: null,
          buttonLocation: GAE_BUTTON_LOCATION.screen,
          filterName: null,
          actionGroup: GAE_ACTION_GROUP.interactions,
          productName: GAE_PRODUCT_NAME.tarif_testovyi_dostup,
        });

        handleRedirect(PATH_TARIFFS);
      }
    }
  };

  const handleGetAcessTestSubscription = (): void => {
    setIsOpenDialogContactUs(true);
  };

  React.useEffect(() => {
    if (isAuthorized) {
      if (isTestSubscriptionUsing || isTestSubscriptionNotUsed) {
        setIsShowCard(true);
      } else if (isTestSubscriptionUsed) {
        setIsShowCard(false);
      }
    } else {
      setIsShowCard(true);
    }
  }, [
    isAuthorized,
    isTestSubscriptionNotUsed,
    isTestSubscriptionUsed,
    isTestSubscriptionUsing,
  ]);

  if (!isShowCard) {
    return null;
  }

  return (
    <>
      <div className={classes.blockCard}>
        <div className={classes.card}>
          <div className={classnames(classes.cardBlock, classes.cardBlockTest)}>
            <TariffCardHeader title="Тестовый доступ" />
            <TariffCardOptions options={options} />

            {isShowInterfaceWithNotUsedTestSuscription && (
              <>
                <h4 className={classes.tariffInfo}>14 дней</h4>

                <div
                  className={classnames(
                    classes.tariffCalculations,
                    classes.tariffCalculationsTest
                  )}
                >
                  <h3 className={classes.tariffPrice}>Бесплатно</h3>
                </div>

                <div
                  className={classnames(classes.cardBtn, classes.cardBtnTest)}
                >
                  <MTSButton
                    size="M"
                    variant="primary"
                    onClick={() => {
                      // TODO: Временное решение для получения тестового доступа
                      if (true) {
                        handleGetAcessTestSubscription();
                      } else {
                        handleGetTestSubscription();
                      }
                    }}
                    loading={isLoadingTestSubscription}
                    sx={{
                      width: "100%",
                      [theme.breakpoints.down("tablet_768")]: {
                        width: "292px",
                      },
                      "@media (max-width: 500px)": {
                        width: "100%",
                      },
                    }}
                  >
                    Подключить
                  </MTSButton>
                </div>
              </>
            )}

            {isTestSubscriptionUsing &&
              !isTestSubscriptionNotUsed &&
              !isTestSubscriptionUsed &&
              isAuthorized && (
                <h4 className={classes.cardInfo}>
                  Действует до {`${testSubscriptionExpiredDate?.time}`}
                  <br />
                  {`${testSubscriptionExpiredDate?.date}`}
                </h4>
              )}
          </div>
        </div>
      </div>

      <DialogConfirm {...dialogConfirm} />
      <DialogContactUs
        isOpen={isOpenDialogContactUs}
        handleCloseDialog={() => {
          setIsOpenDialogContactUs(false);
        }}
        feedbackType="TEST_SUBSCRIPTION_REQUEST"
        title="Тестовый доступ"
      />
    </>
  );
};
