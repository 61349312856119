import React from "react";

import { MTSSpinner } from "components/mts-spinner";
import { TransitionCollapseFade } from "components/transition-сollapse-fade";
import { useSubscriptionDiscounts } from "hooks/use-subscription-discounts";
import { IDiscountDTO } from "interfaces";

import classes from "./table-discount.module.css";

interface ITableDiscountProps {
  titleCountColumn?: string;
  titleDiscountColumn?: string;
}

export const TableDiscount = React.memo(
  (props: ITableDiscountProps): JSX.Element => {
    const { titleCountColumn = "", titleDiscountColumn = "" } = props;

    const { data: discounts, isLoading: isLoadingSubscriptionDiscounts } =
      useSubscriptionDiscounts();

    if (isLoadingSubscriptionDiscounts) {
      return (
        <div className={classes.loader}>
          <MTSSpinner size="M" />
        </div>
      );
    }

    return (
      <TransitionCollapseFade
        isShow={!isLoadingSubscriptionDiscounts}
        delay={1000}
      >
        <ul className={classes.list}>
          {titleCountColumn && titleDiscountColumn && (
            <li
              className={classes.listElem}
              data-testid="txt_discount_for_amount_subs"
            >
              <div className={classes.listElemCount}>{titleCountColumn}</div>
              <div className={classes.listElemDiscountSize}>
                {titleDiscountColumn}
              </div>
            </li>
          )}
          {discounts.map((discount: IDiscountDTO, index: number) => {
            const discountPercent: number = discount.percent;
            const discountMinCount: number = discount.min;
            const discountMaxCount: number = discount.max;

            return (
              <li
                key={index}
                className={classes.listElem}
                style={{
                  backgroundColor:
                    index % 2 === 0
                      ? "var(--color-background-primary)"
                      : "var(--color-background-secondary)",
                }}
                data-testid="txt_discount_for_amount_subs"
              >
                <div className={classes.listElemCount}>
                  {discountMinCount} - {discountMaxCount}
                </div>
                <div className={classes.listElemDiscountSize}>
                  {discountPercent} %
                </div>
              </li>
            );
          })}
        </ul>
      </TransitionCollapseFade>
    );
  }
);
