import { FIELD_NAMES } from "constants/keys";

import { mockData } from "services/mock-data";
import { generateId } from "utils/generate-id";

import { IPayGroupFormStruct } from "../interfaces";

export const MAX_TOTAL_SUB_COUNT = 1000;
export const TOTAL_SUB_COUNT_EXCEEDS_ERROR_MEESAGE = `За один платёж можно приобрести не более ${MAX_TOTAL_SUB_COUNT} подписок`;

export enum FormPaymentConfigurator {
  GROUPS = "paymentGroups",
}

export class InitialFormState implements IPayGroupFormStruct {
  [FIELD_NAMES.PAYMENT_CONFIGURATOR_COUNT] = "";
  [FIELD_NAMES.PAYMENT_CONFIGURATOR_SERVICE_KIND] = null;
  [FIELD_NAMES.PAYMENT_CONFIGURATOR_DURATION] = null;
  //
  // TODO: По бизнес логике, на первом этапе указываем "Все регионы" как значение по умолчанию, данное значение не изменяется пользователем = disabled. В будущем будет возможность выбрать регион
  //
  [FIELD_NAMES.PAYMENT_CONFIGURATOR_REGION] = mockData.regions[0];
  [FIELD_NAMES.PAYMENT_CONFIGURATOR_PAY_GROUP_ID] = generateId();
}
