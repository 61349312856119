import React from "react";

import classNames from "classnames";

import classes from "./list-calculation.module.css";

const listCalculationData: {
  price: string;
  label: string | JSX.Element;
  calc: string | JSX.Element;
  calcDesc?: string | JSX.Element;
}[] = [
  {
    price: "71 725",
    label: "Перерасход семян при посеве из-за перекрытий",
    calc: (
      <>
        1 000 га * 1,98% = 19,8 га перекрытий; 19,8 га * 230 кг *&nbsp;17,5
        руб/кг * 90%
      </>
    ),
  },
  {
    price: "249 480",
    label: (
      <>
        Недобор до 20% урожая{" "}
        <span style={{ whiteSpace: "nowrap" }}>из-за двойного</span> посева
        на&nbsp;перекрытиях
      </>
    ),
    calc: (
      <>
        1 000 га * 1,98% = 19,8 га перекрытий; 19,8 га * 5 т * 20% * 14 000
        руб/т *&nbsp;90%
      </>
    ),
  },
  {
    price: "151 200",
    label: (
      <>
        Потеря до 20% урожая{" "}
        <span style={{ whiteSpace: "nowrap" }}>из-за двойного</span> внесения
        гербицидов на&nbsp;перекрытиях
      </>
    ),
    calc: (
      <>
        1 000 га * 1,2% = 12 га перекрытий; 12 га * 5 т * 20% *&nbsp;14 000
        руб/т * 90%
      </>
    ),
  },
  {
    price: "207 900",
    label: <>Потери от затаптывания при&nbsp;опрыскивании (%)</>,
    calc: (
      <>
        1 000 га * 0,33% = 3,3 га; 3,3&nbsp;га * 5 т * 14 000 руб/т *&nbsp;90%
      </>
    ),
  },
  {
    price: "5 651 100",
    label: "Потери из-за увеличенных агросроков на 23% (7 дней за сезон)",
    calc: (
      <>33,4 га * 65 ц * 3% = 65,13 ц; 65 ц * 14 000 руб * 6,9 сут *&nbsp;90%</>
    ),
    calcDesc: (
      <>
        Из расчёта 65ц/га и осыпании 3%/сут. 33,4 га/смена без&nbsp;системы{" "}
        <span style={{ whiteSpace: "nowrap" }}>и 43,5 га/смена</span>{" "}
        с&nbsp;системой
      </>
    ),
  },
];

export const ListCalculation = (): JSX.Element => {
  return (
    <ul className={classes.blockListCalculation}>
      {listCalculationData.map((data, index) => {
        const isFirst: boolean = index === 0;
        const isLast: boolean = listCalculationData.length - 1 === index;

        return (
          <li
            key={index}
            className={classNames(
              classes.listItem,
              isFirst && classes.listItemFirst,
              isLast && classes.listItemLast
            )}
          >
            <div className={classes.listItemPrice}>
              <h4 className={classes.listItemPriceText}>
                {data.price}&nbsp;
                <span>₽</span>
              </h4>
            </div>
            <div className={classes.listItemInfo}>
              <p className={classes.listItemInfoHeaderText}>{data.label}</p>
              <p className={classes.listItemInfoText}>{data.calc}</p>

              {!!data?.calcDesc && (
                <p className={classes.listItemInfoText}>{data.calcDesc}</p>
              )}
            </div>
          </li>
        );
      })}
    </ul>
  );
};
