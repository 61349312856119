interface IProgressBarCircleProps {
  size: number;
  progress: number;
  indicatorWidth: number;
  indicatorColor?: string;
  indicatorCap?: "butt" | "round" | "square" | "inherit";
  indicatorShiftDegrees?: number;
}

export const ProgressBarCircle = (
  props: IProgressBarCircleProps
): JSX.Element => {
  const {
    size,
    progress,
    indicatorWidth,
    indicatorColor = "#FF0032",
    indicatorCap = "square",
    indicatorShiftDegrees = 0,
  } = props;

  const center = size / 2;
  const radius = center - indicatorWidth / 2;
  const dashArray = 2 * Math.PI * radius;
  const dashOffset = dashArray * ((100 - progress) / 100);

  return (
    <div style={{ position: "relative", width: size, height: size }}>
      <svg
        style={{
          width: size,
          height: size,
          transform: `rotate(${-90 + indicatorShiftDegrees}deg`,
        }}
      >
        <circle
          cx={center}
          cy={center}
          fill="transparent"
          r={radius}
          stroke={indicatorColor}
          strokeWidth={indicatorWidth}
          strokeDasharray={dashArray}
          strokeDashoffset={dashOffset}
          strokeLinecap={indicatorCap}
        />
      </svg>
    </div>
  );
};
