import {
  INSTRUCTION_HOW_TO_WORK_ADMIN_URL,
  INSTRUCTION_HOW_TO_WORK_CUSTOMER_BASE_URL,
  INSTRUCTION_DEVICE_CONNECT_URL,
} from "constants/urls";

import { AppHelmet } from "components/app-helmet";
import { MTSCard } from "components/mts-card";
import { MTSIcon } from "components/mts-icon";
import {
  GAP_EVENT_TYPE,
  GA_SCREEN_NAME,
  useGoogleAnalyticsPages,
} from "hooks/google-analytics";
import { useProfileData } from "hooks/use-profile-data";

import classes from "./instruction-cards.module.css";

export const InsructionCards = (): JSX.Element => {
  useGoogleAnalyticsPages({
    screenName: GA_SCREEN_NAME.instructions,
    event: GAP_EVENT_TYPE.SCRN,
  });

  const { isCustomerAdminRole } = useProfileData();

  return (
    <>
      <AppHelmet title="Инструкции - Личный кабинет" />
      <div className={classes.blockInstructionCards}>
        <div className={classes.cards}>
          <MTSCard
            title="Работа в личном кабинете"
            size="L"
            href={
              isCustomerAdminRole
                ? INSTRUCTION_HOW_TO_WORK_ADMIN_URL
                : INSTRUCTION_HOW_TO_WORK_CUSTOMER_BASE_URL
            }
          >
            <div className={classes.cardImg}>
              <MTSIcon.UserList sx={{ color: "#E7EAFA", fontSize: "80px" }} />
            </div>
          </MTSCard>

          {/* TODO: Карточка скрыта  */}
          {/* <MTSCard
  title="Работа в CDS.NET"
  size="L"
  to={}
>
  <div className={classes.cardImg}>
    <MTSIcon.MediaLive
      sx={{ color: "#E7EAFA", fontSize: "80px" }}
    />
  </div>
</MTSCard> */}

          <MTSCard
            title="Подключение устройства"
            size="L"
            href={INSTRUCTION_DEVICE_CONNECT_URL}
          >
            <div className={classes.cardImg}>
              <MTSIcon.Plug sx={{ color: "#E7EAFA", fontSize: "80px" }} />
            </div>
          </MTSCard>
        </div>
      </div>
    </>
  );
};
