import { QueryKeys } from "constants/keys";

import { useQuery } from "react-query";

import { AxiosError } from "axios";
import { useNotifications } from "hooks/use-notifications";
import { IPlannedBaseStationEchoDTO } from "interfaces";
import { getPlannedBaseStations } from "services/api";

export const usePlannedBaseStations = () => {
  const { addErrorMessage } = useNotifications();

  return useQuery<IPlannedBaseStationEchoDTO[], AxiosError>(
    [QueryKeys.PLANNED_BASE_STATIONS],
    () => getPlannedBaseStations(),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      onError: (error: AxiosError) => {
        addErrorMessage({
          message: "Не удалось получить данные по планируемым базовым станциям",
        });
      },
    }
  );
};
