import { HTTPStatusCode, QueryKeys } from "constants/keys";

import { useQuery } from "react-query";

import { AxiosError } from "axios";
import { useNotifications } from "hooks/use-notifications";
import { getBillingSubscriptions } from "services/api";

export const useBillingSubscriptions = () => {
  const { addErrorMessage } = useNotifications();

  return useQuery(
    QueryKeys.BILLING_SUBSCRIPTIONS,
    () => getBillingSubscriptions(),
    {
      refetchOnWindowFocus: false,
      retry: 0,
      onError: (error: AxiosError) => {
        const status = error.response?.status;

        if (status !== HTTPStatusCode.UNAUTHORIZED_401) {
          addErrorMessage({
            message: "Не удалось получить данные с подписками",
          });
        }
      },
    }
  );
};
