import { FIELD_NAMES } from "constants/keys";

import React from "react";

import { AppHelmet } from "components/app-helmet";
import { Loader } from "components/loader";
import {
  GAP_EVENT_TYPE,
  GA_SCREEN_NAME,
  useGoogleAnalyticsPages,
} from "hooks/google-analytics";
import { useCustomerAdminData } from "hooks/use-customer-admin-data";
import { useCustomerAdminDataForBaseUsers } from "hooks/use-customer-admin-data-for-base-users";
import { useProfileData } from "hooks/use-profile-data";
import { useRegions } from "hooks/use-regions";
import { IDictionaryDTO } from "interfaces";
import { getDictionaryItemByCode } from "utils/get-dictionary-item-by-code";

import classes from "./company-info.module.css";
import {
  CompanyInfoForm,
  ICompanyInfoFormStruct,
} from "./components/company-info-form";

export const CompanyInfo = (): JSX.Element => {
  const { isCustomerAdminRole } = useProfileData();

  useGoogleAnalyticsPages({
    screenName: GA_SCREEN_NAME.company_info,
    event: GAP_EVENT_TYPE.SCRN,
  });

  const [initialFormData, setInitialFormData] =
    React.useState<ICompanyInfoFormStruct | null>(null);
  const [isLoadingFormData, setIsLoadingFormData] =
    React.useState<boolean>(true);

  const { data: regions, isLoading: isLoadingRegions } = useRegions();
  const { data: customerAdminData, isLoading: isLoadingCustomerAdminData } =
    useCustomerAdminData();
  const {
    data: customerAdminDataForBaseUsers,
    isLoading: isLoadingCustomerAdminDataForBaseUsers,
  } = useCustomerAdminDataForBaseUsers();

  React.useEffect(() => {
    if (
      !isLoadingRegions &&
      (isCustomerAdminRole
        ? !isLoadingCustomerAdminData
        : !isLoadingCustomerAdminDataForBaseUsers)
    ) {
      setIsLoadingFormData(true);

      let region: IDictionaryDTO | null = null;
      let companyName: string = "";
      let fio: string = "";
      let email: string = "";

      if (customerAdminData) {
        region = getDictionaryItemByCode(customerAdminData.region, regions);
        companyName = customerAdminData?.companyName ?? "";
        fio = customerAdminData?.fio ?? "";
        email = customerAdminData?.email ?? "";
      } else if (customerAdminDataForBaseUsers) {
        region = getDictionaryItemByCode(
          customerAdminDataForBaseUsers.region,
          regions
        );
        companyName = customerAdminDataForBaseUsers?.companyName ?? "";
        fio = customerAdminDataForBaseUsers?.fio ?? "";
        email = customerAdminDataForBaseUsers?.email ?? "";
      }

      setInitialFormData({
        [FIELD_NAMES.COMPANY_INFO_REGION]: region,
        [FIELD_NAMES.COMPANY_INFO_COMPANY_NAME]: companyName,
        [FIELD_NAMES.COMPANY_INFO_FIO]: fio,
        [FIELD_NAMES.COMPANY_INFO_EMAIL]: email,
      });

      setIsLoadingFormData(false);
    }
  }, [
    customerAdminData,
    customerAdminDataForBaseUsers,
    isCustomerAdminRole,
    isLoadingCustomerAdminData,
    isLoadingCustomerAdminDataForBaseUsers,
    isLoadingRegions,
    regions,
  ]);

  if (isLoadingFormData || initialFormData === null) {
    return <Loader />;
  }

  return (
    <>
      <AppHelmet title="Компания - Личный кабинет" />

      <div className={classes.block}>
        {initialFormData && (
          <CompanyInfoForm initialFormData={initialFormData} />
        )}
      </div>
    </>
  );
};
