import { MTS_OPERATOR_KEYS } from "constants/keys";

export class ProfileChecker {
  static isMTSOperator(key: string | undefined): boolean {
    const operatorKeys: string[] = Object.values(MTS_OPERATOR_KEYS);

    return key ? operatorKeys.includes(key) : false;
  }

  // NOTE: "Person" - Физ. лицо, "Organization" - Юр. лицо
  static isOrganization(value: string | undefined): boolean {
    let status: boolean = false;

    if (value === "Organization") {
      status = true;
    }

    return status;
  }

  static isPerson(value: string | undefined): boolean {
    let status: boolean = false;

    if (value === "Person") {
      status = true;
    }

    return status;
  }

  // NOTE: "1" - предприниматель, "0" - нет
  static isPrivate(value: string | undefined): boolean {
    let status: boolean = false;

    if (value === "1") {
      status = true;
    }

    return status;
  }
}
