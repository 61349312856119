import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const CompanyLogo = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.1885 9.00342C17.1401 4.99889 22.8599 4.99884 24.8115 9.00342L33.4382 26.7044C35.4959 30.9266 31.5542 35.3431 27.2446 34.1935L20 32.2611L12.7554 34.1935C8.4458 35.3431 4.50414 30.9266 6.56184 26.7044L15.1885 9.00342ZM20 18.1814L16.9516 24.4363L17.8735 24.1904C19.2665 23.8189 20.7335 23.8189 22.1266 24.1904L23.0484 24.4363L20 18.1814Z"
      />
    </SvgIcon>
  );
};

export default CompanyLogo;
