import { PAYMENT_ACTION_TYPE, PAYMENT_TYPE } from "constants/keys";
import { PATH_PAYMENT_EDIT } from "constants/routes";

import { useLocation } from "react-router-dom";

import { CrossedOutText } from "components/crossed-out-text";
import { MTSLink } from "components/mts-link";
import { IPaymentConfiguratorLocationState } from "components/payment-configurator";
import { IPaymentDTO } from "interfaces";
import lodash from "lodash";
import { getDateFormatted, IDateFormatted } from "utils/get-date-formatted";
import { getFormattedNumberWithThousandSeparator } from "utils/get-formatted-number-with-thousand-separator";
import { PaymentStatusCheckerByCode } from "utils/payment-status-checker-by-code";

import { PaymentCardStatus } from "./components/payment-card-status";
import classes from "./payment-card-info.module.css";

interface IPaymentCardInfoProps {
  data: IPaymentDTO;
}

export const PaymentCardInfo = (props: IPaymentCardInfoProps) => {
  const { data } = props;
  const location = useLocation();
  const orderExpirationDate: IDateFormatted = getDateFormatted(
    data?.order?.expirationDate || ""
  );
  const amountBeforeDiscount: number =
    data?.calculation?.amountBeforeDiscount ?? 0;
  const amount: number | null = lodash.isNumber(data?.calculation?.amount)
    ? data?.calculation?.amount
    : null;
  const modifiedDate: IDateFormatted = getDateFormatted(data.modified || "");

  const paymentStatusCode: string | undefined = data?.status;
  const paymentActionType: string = data?.actionType ?? "";
  const paymentType: string = data?.type ?? "";

  return (
    <>
      <div className={classes.infoPayment}>
        <PaymentCardStatus paymentStatus={data?.status || ""} />

        <div className={classes.infoPaymentPrices}>
          <h3 className={classes.infoPaymentPricesNumber}>
            {getFormattedNumberWithThousandSeparator({
              value: amount,
              showFractionDigits: true,
            })}
            &nbsp;₽
          </h3>

          {amountBeforeDiscount > 0 &&
            amount &&
            amountBeforeDiscount > amount && (
              <div className={classes.priceWithoutDiscounts}>
                <CrossedOutText>
                  <span className={classes.priceWithoutDiscountsText}>
                    {getFormattedNumberWithThousandSeparator({
                      value: amountBeforeDiscount,
                    })}
                  </span>
                </CrossedOutText>
                &nbsp;
                <span className={classes.priceWithoutDiscountsText}>₽</span>
              </div>
            )}
        </div>

        <div className={classes.infoPaymentDetails}>
          {PaymentStatusCheckerByCode.isDraft(paymentStatusCode) &&
            paymentType === PAYMENT_TYPE.NEW && (
              <p
                className={classes.infoPaymentDetailsInfo}
                style={{ maxWidth: 211 }}
              >
                Оплатите выбранные подписки или&nbsp;
                <MTSLink
                  to={{
                    pathname: `${PATH_PAYMENT_EDIT}/${data?.id}`,
                    state: {
                      redirectBackTo: location.pathname,
                    } as IPaymentConfiguratorLocationState,
                  }}
                >
                  внесите изменения
                </MTSLink>
                &nbsp; в заказ
              </p>
            )}

          {PaymentStatusCheckerByCode.isInProcess(paymentStatusCode) &&
            paymentActionType === PAYMENT_ACTION_TYPE.ADD && (
              <p className={classes.infoPaymentDetailsInfo}>
                Ожидаем подтверждения реквизитов и оплаты до&nbsp;
                {!!orderExpirationDate?.date ? orderExpirationDate.date : "..."}
              </p>
            )}

          {PaymentStatusCheckerByCode.isInProcess(paymentStatusCode) &&
            paymentActionType === PAYMENT_ACTION_TYPE.MODIFY && (
              <p className={classes.infoPaymentDetailsInfo}>
                Счёт отправлен на email и в SMS.
                <br />
                {!!orderExpirationDate?.date &&
                  `Оплатите его до ${orderExpirationDate.date}`}
              </p>
            )}

          {PaymentStatusCheckerByCode.isToQueue(paymentStatusCode) && (
            <p className={classes.infoPaymentDetailsInfo}>
              Подождите, пожалуйста.
              <br />
              Обработка платежа может занять до 15 минут
            </p>
          )}

          <p className={classes.infoPaymentDetailsDate}>
            {modifiedDate.date && modifiedDate.time
              ? `${modifiedDate.date}, ${modifiedDate.time}`
              : ""}
          </p>
        </div>
      </div>
    </>
  );
};
