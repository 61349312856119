import {
  PATH_ADD_SUBSCRIPTIONS,
  PATH_COMPANY_INFO,
  PATH_PAYMENTS,
  PATH_SUBSCRIPTION_TAB_ALL,
  PATH_TARIFFS,
  PATH_SUBSCRIPTION_INFO_PERSONAL,
  PATH_INSTRUCTION_CARDS,
  PATH_PERSONAL_AREA,
  PATH_MAIN,
  PATH_MAP,
  PATH_ADD_RINEX_REQUEST,
  PATH_SUBSCRIPTION_TAB_PAID,
  PATH_SUBSCRIPTION_TAB_ACTIVATED,
  PATH_SUBSCRIPTION_TAB_RENEW,
  PATH_SUBSCRIPTION_TAB_EXPIRED,
  PATH_SUBSCRIPTION_INFO,
  PATH_PAYMENT_EDIT,
  PATH_PUBLIC_OFFER,
  PATH_ERROR,
  PATH_AGRONOMY,
  PATH_GEODESY_CADASTRE,
} from "constants/routes";

export const GA_SCREEN_NAME = {
  main: PATH_MAIN,
  map_landing: PATH_MAP,
  offer: PATH_PUBLIC_OFFER,
  error: PATH_ERROR,
  personal_area: PATH_PERSONAL_AREA,
  registration: `${PATH_PERSONAL_AREA}/routeId`,
  rinex_request: PATH_ADD_RINEX_REQUEST,
  tariffs: PATH_TARIFFS,
  subs_all: PATH_SUBSCRIPTION_TAB_ALL,
  subs_paid: PATH_SUBSCRIPTION_TAB_PAID,
  subs_activated: PATH_SUBSCRIPTION_TAB_ACTIVATED,
  subs_renew: PATH_SUBSCRIPTION_TAB_RENEW,
  subs_expired: PATH_SUBSCRIPTION_TAB_EXPIRED,
  sub_info: `${PATH_SUBSCRIPTION_INFO}/subscriptionId`,
  add_subs: PATH_ADD_SUBSCRIPTIONS,
  payments: PATH_PAYMENTS,
  payment_info: `${PATH_PAYMENTS}/paymentId`,
  payment_edit: `${PATH_PAYMENT_EDIT}/paymentId`,
  company_info: PATH_COMPANY_INFO,
  sub_info_personal: PATH_SUBSCRIPTION_INFO_PERSONAL,
  instructions: PATH_INSTRUCTION_CARDS,
  agronomy: PATH_AGRONOMY,
  geodesy_cadastre: PATH_GEODESY_CADASTRE,
};
