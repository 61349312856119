import { MTSButtonIconVariant } from "../constants";
import { IMTSButtonIconVariant } from "../interfaces";

export const useButtonIconColor = ({
  variant,
}: {
  variant: IMTSButtonIconVariant;
}): string => {
  let iconColor = "var(--color-text-inverted)";

  switch (variant) {
    case MTSButtonIconVariant.primary:
      iconColor = "var(--color-text-inverted)";
      break;
    case MTSButtonIconVariant.secondary:
    case MTSButtonIconVariant.secondary_inverted:
    case MTSButtonIconVariant.ghost:
    case MTSButtonIconVariant.outline:
      iconColor = "var(--color-icon-primary)";
      break;
    default:
      iconColor = "var(--color-text-inverted)";
      break;
  }

  return iconColor;
};
