import usePagination from "@mui/material/usePagination";
import cn from "classnames";
import { MTSButtonIcon } from "components/mts-button-icon";
import { MTSIcon } from "components/mts-icon";

import classes from "./mts-pagination.module.css";

interface IMTSPaginationProps {
  pageIndex: number;
  pageCount: number;
  canPreviousPage: boolean;
  canNextPage: boolean;
  gotoPage: (num: number) => void;
  previousPage: () => void;
  nextPage: () => void;
}

export const MTSPagination = (props: IMTSPaginationProps) => {
  const {
    pageIndex,
    pageCount,
    canPreviousPage,
    canNextPage,
    gotoPage,
    previousPage,
    nextPage,
  } = props;
  const { items } = usePagination({
    page: pageIndex,
    count: pageCount,
  });

  const btnPageStyles = cn({
    [classes.cell]: true,
    [classes.btnPage]: true,
  });

  const btnPageSelected = cn({
    [classes.cell]: true,
    [classes.btnPageSelected]: true,
  });

  const btnNavStyles = cn({
    [classes.cell]: true,
    [classes.btnNav]: true,
  });

  return (
    <ul className={classes.pagination}>
      {items.map(({ page: pageNumber, type, selected, ...item }, index) => {
        let children = null;

        if (type === "start-ellipsis" || type === "end-ellipsis") {
          children = (
            <div className={classes.pageBreadcrumbs}>
              <p className={classes.pageBreadcrumbsText}>...</p>
            </div>
          );
        } else if (type === "page") {
          children = (
            <button
              className={
                pageIndex === pageNumber - 1 ? btnPageSelected : btnPageStyles
              }
              type="button"
              {...item}
              onClick={() => {
                gotoPage(pageNumber - 1);
              }}
            >
              <span className={classes.cellText}>{pageNumber}</span>
            </button>
          );
        } else if (type === "next") {
          children = (
            <MTSButtonIcon
              size="S"
              variant="ghost"
              onClick={nextPage}
              disabled={!canNextPage}
              className={btnNavStyles}
              Icon={MTSIcon.ArrowRight}
              iconColor={"var(--color-icon-primary)"}
              style={{ visibility: canNextPage ? "visible" : "hidden" }}
            />
          );
        } else if (type === "previous") {
          children = (
            <MTSButtonIcon
              size="S"
              variant="ghost"
              onClick={previousPage}
              disabled={!canPreviousPage}
              className={btnNavStyles}
              Icon={MTSIcon.ArrowLeft}
              iconColor={"var(--color-icon-primary)"}
              style={{ visibility: canPreviousPage ? "visible" : "hidden" }}
            />
          );
        }

        return (
          <li className={classes.paginationItem} key={index}>
            {children}
          </li>
        );
      })}
    </ul>
  );
};
