import { PROFILE_WIDGET_ACCOUNT_ID } from "constants/dom-element-ids";
import { PROFILE_WIDGET_ENV } from "constants/env";
import { PRODUCT_SUPPORT_URL } from "constants/urls";

import React from "react";

import {
  GAE_ACTION_GROUP,
  GAE_BUTTON_LOCATION,
  GAE_EVENT,
  GAE_EVENT_ACTION,
  GAE_EVENT_CATEGORY,
  GAE_EVENT_LABEL,
  GA_SCREEN_NAME,
  useGoogleAnalyticsEvents,
} from "hooks/google-analytics";
import { useContextProfileWidget } from "hooks/use-context-profile-widget";
import { useDisplayStatus } from "hooks/use-display-status";
import { AuthService } from "services/auth";

export const HeaderProfile = (): JSX.Element => {
  const { sendGAE } = useGoogleAnalyticsEvents();
  const { profileWidget, setProfileWidget, reloadProfileWidget } =
    useContextProfileWidget();
  const { isMobileVersion } = useDisplayStatus();

  React.useEffect(() => {
    if (!profileWidget) {
      try {
        const _widget = new (window as any).profileWidgetApp({
          target: document.getElementById(PROFILE_WIDGET_ACCOUNT_ID),
          props: {
            env: PROFILE_WIDGET_ENV,
            loginEndpoint: () => {
              sendGAE({
                id: 14,
                event: GAE_EVENT.vntLogin,
                eventCategory: GAE_EVENT_CATEGORY.avtorizaciya,
                eventAction: GAE_EVENT_ACTION.button_click,
                eventLabel: GAE_EVENT_LABEL.voiti,
                eventValue: null,
                screenName: GA_SCREEN_NAME.main,
                eventContent: "Voiti",
                buttonLocation: GAE_BUTTON_LOCATION.screen,
                filterName: null,
                actionGroup: GAE_ACTION_GROUP.interactions,
                productName: null,
              });
              AuthService.login();
            },
            logoutEndpoint: () => {
              AuthService.logout();
            },
            // serviceManageEndpoint: () => {
            // },
            // serviceManageTitle: "Личный кабинет LocationPro",
            queryHideProductsButton: 1280,
            supportLink: PRODUCT_SUPPORT_URL,
            // productsMobileTarget: document.getElementById(MOBILE_TARGET),
            theme: "Light",
            blockLoading: false,
            disableNotifications: false,
            disableBusinessProfileSwitcher: false,
            disableProductsWidget: false,
            isB2C: true,
            disableWidget: false,
            profileManageItemValue: false,
          },
        });

        setProfileWidget(_widget);
      } catch (error) {}
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileWidget]);

  // TODO: Убрать данный эффект когда рефакторинг структуры сайта будеты
  React.useEffect(() => {
    reloadProfileWidget();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobileVersion]);

  return (
    <div>
      <div id={PROFILE_WIDGET_ACCOUNT_ID} />
    </div>
  );
};
