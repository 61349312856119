import { TableSortStates } from "constants/keys";
import { PATH_ADD_RINEX_REQUEST, PATH_RINEX_REQUESTS } from "constants/routes";
import { SearchParamsKeys } from "constants/search-params";
import { TABLE_PAGE_SIZE } from "constants/table";

import React from "react";

import { useHistory, useLocation } from "react-router";

import { Tooltip } from "@mui/material";
import { AppHelmet } from "components/app-helmet";
import { ContainerContent } from "components/container-content";
import { FooterOfPersonalArea } from "components/footer-of-personal-area";
import { MTSButton } from "components/mts-button";
import { MTSIcon } from "components/mts-icon";
import { MTSPaginationManual } from "components/mts-pagination-manual";
import { PageTitle } from "components/page-title";
import { TableRinexRequests } from "components/tables";
import { useAccessStatusDownloadRinex } from "hooks/use-access-status-download-rinex";
import { useRinexFileRequests } from "hooks/use-rinex-file-requests";
import { useTablePagination } from "hooks/use-table-pagination";
import { IFileRequestPageableDTO, ITableSort } from "interfaces";

import classes from "./rinex-requests-page.module.css";

const PAGE_TITLE = "Мои запросы";

export const RinexRequestsPage = () => {
  const location = useLocation();
  const history = useHistory();
  const [requestPayload, setRequestPayload] =
    React.useState<IFileRequestPageableDTO | null>(null);

  const { data: rinexFileRequestsData, isLoading: isLoadingRinexFileRequests } =
    useRinexFileRequests({
      payload: requestPayload,
    });
  const rinexFileRequestsDataContent = rinexFileRequestsData?.content || [];
  const { data: userAccessDownloadRinex } = useAccessStatusDownloadRinex();
  const isUserHasAccessDownloadRinex: boolean =
    userAccessDownloadRinex?.allowed || false;
  const [sorting, setSorting] = React.useState<ITableSort>([]);

  const { pagination, isCanNextPage, isCanPreviousPage } = useTablePagination({
    data: rinexFileRequestsData
      ? {
          page: rinexFileRequestsData.page,
          totalPages: rinexFileRequestsData.totalPages,
          elements: rinexFileRequestsData.elements,
          totalElements: rinexFileRequestsData.totalElements,
          pageIsFirst: rinexFileRequestsData.pageIsFirst,
          pageIsLast: rinexFileRequestsData.pageIsLast,
          size: TABLE_PAGE_SIZE,
        }
      : null,
  });

  const handleCreateRinexRequest = (): void => {
    history.push(PATH_ADD_RINEX_REQUEST);
  };

  const handleGoToPage = (newPageNumber: number): void => {
    const searchParams = location.search;
    const params = new URLSearchParams(searchParams);

    params.set(
      SearchParamsKeys.pagination.pageNumber,
      newPageNumber.toString()
    );

    history.push({
      pathname: PATH_RINEX_REQUESTS,
      search: params.toString(),
    });
  };

  React.useEffect(() => {
    const searchParams = location.search;
    const params = new URLSearchParams(searchParams);

    const newRequestPayload: IFileRequestPageableDTO = {
      page: 1,
      size: pagination.size,
      field: "",
      direction: "",
    };

    // SORT
    if (sorting.length) {
      const { id, desc } = sorting[0];

      newRequestPayload.field = id;
      newRequestPayload.direction = desc
        ? TableSortStates.desc
        : TableSortStates.asc;
    }

    // PAGE NUMBER
    const pageNumberValue: string | null = params.get(
      SearchParamsKeys.pagination.pageNumber
    );
    if (pageNumberValue && Number.isSafeInteger(Number(pageNumberValue))) {
      const newPageNumber = Number(pageNumberValue);

      newRequestPayload.page = newPageNumber;
    }

    setRequestPayload(newRequestPayload);
  }, [location.search, sorting, pagination.size]);

  return (
    <>
      <AppHelmet title={`${PAGE_TITLE} - Личный кабинет`} />

      <ContainerContent>
        <div className={classes.header}>
          <div className={classes.headerInfo}>
            <PageTitle text={PAGE_TITLE} dataTestId="txt_requests" />
            <p className={classes.headerInfoDesc}>
              Максимальный срок хранения сформированных файлов 7 календарных
              дней
            </p>
          </div>

          <Tooltip
            title={
              !isUserHasAccessDownloadRinex && (
                <>
                  Активируйте подписку<br></br>для доступа к RINEX.
                </>
              )
            }
            arrow
            disableInteractive
            disableFocusListener={isUserHasAccessDownloadRinex}
            disableHoverListener={isUserHasAccessDownloadRinex}
            disableTouchListener={isUserHasAccessDownloadRinex}
          >
            <div>
              <MTSButton
                data-testid="btn_rinex_add"
                variant="primary_alternate"
                onClick={handleCreateRinexRequest}
                noWrap
                disabled={!isUserHasAccessDownloadRinex}
                style={{ width: "100%" }}
              >
                <div className={classes.buttonText}>
                  <MTSIcon.Plus sx={{ fontSize: "16px" }} />
                  Создать запрос
                </div>
              </MTSButton>
            </div>
          </Tooltip>
        </div>

        <div className={classes.table}>
          <TableRinexRequests
            data={rinexFileRequestsDataContent}
            isLoading={isLoadingRinexFileRequests}
            pageSize={pagination.size}
            pageIndex={pagination.page - 1}
            sorting={sorting}
            setSorting={setSorting}
            isShowTableEmpty={
              pagination.totalElements === 0 &&
              rinexFileRequestsDataContent.length === 0
            }
          />
        </div>
        <div className={classes.tablePagination}>
          <MTSPaginationManual
            pageIndex={pagination.page - 1}
            pageCount={pagination.totalPages}
            canPreviousPage={isCanPreviousPage}
            canNextPage={isCanNextPage}
            gotoPage={handleGoToPage}
            isLoading={isLoadingRinexFileRequests}
          />
        </div>
      </ContainerContent>
      <FooterOfPersonalArea />
    </>
  );
};
