import { PRODUCT_NAME } from "constants/common";
import { FRONTEND_URL } from "constants/env";
import { PATH_MAP } from "constants/routes";

import { Helmet } from "react-helmet";

import {
  GAP_EVENT_TYPE,
  GA_SCREEN_NAME,
  useGoogleAnalyticsPages,
} from "hooks/google-analytics";
import { ContextMapProvider } from "store/context-map";

import { Map } from "./components/map";

export const MapPage = (): JSX.Element => {
  useGoogleAnalyticsPages({
    screenName: GA_SCREEN_NAME.map_landing,
    event: GAP_EVENT_TYPE.SCRN,
  });

  return (
    <>
      <Helmet>
        <title>Карта покрытия референсной сети | {PRODUCT_NAME}</title>
        <meta
          name="description"
          content="Карта с сетью референсных станций от МТС. Решение позволяет определять местоположение объекта с высокой точностью."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={FRONTEND_URL + PATH_MAP} />
        <meta
          property="og:title"
          content={`Карта покрытия референсной сети | ${PRODUCT_NAME}`}
        />
        <meta
          property="og:description"
          content="Карта с сетью референсных станций от МТС. Решение позволяет определять местоположение объекта с высокой точностью."
        />
        <meta property="og:site_name" content="Карта" />
      </Helmet>

      <ContextMapProvider>
        <Map />
      </ContextMapProvider>
    </>
  );
};
