export enum Size {
  S = "S", // 16
  M = "M", // 24
  L = "L", // 40
}

export const MTSSpinnerColor = {
  white: "white",
  black: "black",
  orange: "orange",
  red: "red",
};
