import { HTTPStatusCode, QueryKeys } from "constants/keys";
import { DICTIONARY_SLATE_TIME } from "constants/server";

import { useQuery } from "react-query";

import { AxiosError } from "axios";
import { useNotifications } from "hooks/use-notifications";
import { getSubscriptionStatuses } from "services/api/index";

export const useSubscriptionStatuses = () => {
  const { addErrorMessage } = useNotifications();

  return useQuery(
    QueryKeys.SUBSCRIPTION_STATUSES,
    () => getSubscriptionStatuses(),
    {
      staleTime: DICTIONARY_SLATE_TIME,
      retry: 0,
      onError: (error: AxiosError) => {
        const status = error.response?.status;

        if (status !== HTTPStatusCode.UNAUTHORIZED_401) {
          addErrorMessage({
            message: "Не удалось получить справочник со статусами подписок",
          });
        }
      },
    }
  );
};
