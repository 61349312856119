import {
  PATH_ADD_SUBSCRIPTIONS,
  PATH_COMPANY_INFO,
  PATH_PAYMENTS,
  PATH_SUBSCRIPTION_TAB_ALL,
  PATH_TARIFFS,
  PATH_SUBSCRIPTION_INFO_PERSONAL,
  PATH_INSTRUCTION_CARDS,
  PATH_MAIN,
  PATH_MAP,
  PATH_ADD_RINEX_REQUEST,
  PATH_SUBSCRIPTION_TAB_PAID,
  PATH_SUBSCRIPTION_TAB_ACTIVATED,
  PATH_SUBSCRIPTION_TAB_RENEW,
  PATH_SUBSCRIPTION_TAB_EXPIRED,
  PATH_SUBSCRIPTION_INFO,
  PATH_PAYMENT_EDIT,
  PATH_PUBLIC_OFFER,
  PATH_ERROR,
  PATH_AGRONOMY,
  PATH_GEODESY_CADASTRE,
} from "constants/routes";

import { useLocation } from "react-router-dom";

import { GA_SCREEN_NAME } from "./constants";

export const useScreenName = (): string => {
  let currentScreenName = "";
  const location = useLocation();
  const pathname = location.pathname;

  if (PATH_MAIN === pathname) {
    currentScreenName = GA_SCREEN_NAME.main;
  } else if (PATH_MAP === pathname) {
    currentScreenName = GA_SCREEN_NAME.map_landing;
  } else if (PATH_PUBLIC_OFFER === pathname) {
    currentScreenName = GA_SCREEN_NAME.offer;
  } else if (PATH_ERROR === pathname) {
    currentScreenName = GA_SCREEN_NAME.error;
  } else if (pathname.includes(PATH_ADD_RINEX_REQUEST)) {
    currentScreenName = GA_SCREEN_NAME.rinex_request;
  } else if (PATH_TARIFFS === pathname) {
    currentScreenName = GA_SCREEN_NAME.tariffs;
  } else if (PATH_SUBSCRIPTION_TAB_ALL === pathname) {
    currentScreenName = GA_SCREEN_NAME.subs_all;
  } else if (PATH_SUBSCRIPTION_TAB_PAID === pathname) {
    currentScreenName = GA_SCREEN_NAME.subs_paid;
  } else if (PATH_SUBSCRIPTION_TAB_ACTIVATED === pathname) {
    currentScreenName = GA_SCREEN_NAME.subs_activated;
  } else if (PATH_SUBSCRIPTION_TAB_RENEW === pathname) {
    currentScreenName = GA_SCREEN_NAME.subs_renew;
  } else if (PATH_SUBSCRIPTION_TAB_EXPIRED === pathname) {
    currentScreenName = GA_SCREEN_NAME.subs_expired;
  } else if (pathname.includes(PATH_SUBSCRIPTION_INFO)) {
    currentScreenName = GA_SCREEN_NAME.sub_info;
  } else if (pathname.includes(PATH_ADD_SUBSCRIPTIONS)) {
    currentScreenName = GA_SCREEN_NAME.add_subs;
  } else if (PATH_PAYMENTS === pathname) {
    currentScreenName = GA_SCREEN_NAME.payments;
  } else if (pathname.includes(PATH_PAYMENTS) && PATH_PAYMENTS !== pathname) {
    currentScreenName = GA_SCREEN_NAME.payment_info;
  } else if (pathname.includes(PATH_PAYMENT_EDIT)) {
    currentScreenName = GA_SCREEN_NAME.payment_edit;
  } else if (PATH_COMPANY_INFO === pathname) {
    currentScreenName = GA_SCREEN_NAME.company_info;
  } else if (PATH_SUBSCRIPTION_INFO_PERSONAL === pathname) {
    currentScreenName = GA_SCREEN_NAME.sub_info_personal;
  } else if (PATH_INSTRUCTION_CARDS === pathname) {
    currentScreenName = GA_SCREEN_NAME.instructions;
  } else if (PATH_AGRONOMY === pathname) {
    currentScreenName = GA_SCREEN_NAME.agronomy;
  } else if (PATH_GEODESY_CADASTRE === pathname) {
    currentScreenName = GA_SCREEN_NAME.geodesy_cadastre;
  }

  return currentScreenName;
};
