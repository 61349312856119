import { HTTPStatusCode, QueryKeys } from "constants/keys";

import { useQuery } from "react-query";

import { AxiosError } from "axios";
import { useNotifications } from "hooks/use-notifications";
import { getBillingSubscriptionsByPayment } from "services/api";

export const useBillingSubscriptionsByPayment = (id: string) => {
  const { addErrorMessage } = useNotifications();

  return useQuery(
    [QueryKeys.BILLING_SUBSCRIPTIONS_BY_PAYMENT, id],
    () => getBillingSubscriptionsByPayment(id),
    {
      enabled: Boolean(id),
      refetchOnWindowFocus: false,
      retry: 0,
      onError: (error: AxiosError) => {
        const status = error.response?.status;

        if (id && status !== HTTPStatusCode.UNAUTHORIZED_401) {
          addErrorMessage({ message: "Не удалось получить данные по платежу" });
        }
      },
    }
  );
};
