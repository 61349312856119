import { FIELD_NAMES } from "constants/keys";
import { PATH_SUBSCRIPTIONS } from "constants/routes";

import React from "react";

import { useParams } from "react-router";

import { AppHelmet } from "components/app-helmet";
import { Breadcrumbs } from "components/breadcrumbs";
import { ContainerContent } from "components/container-content";
import { FooterOfPersonalArea } from "components/footer-of-personal-area";
import { Loader } from "components/loader";
import { TemplateServiceNotAvailable } from "components/template-service-not-available";
import {
  GAP_EVENT_TYPE,
  GA_SCREEN_NAME,
  useGoogleAnalyticsPages,
} from "hooks/google-analytics";
import { useTabNames } from "hooks/tabs/use-tab-names";
import { useCheckAbilityToCreatePayments } from "hooks/use-check-ability-to-create-payments";
import { useDurations } from "hooks/use-durations";
import { useServiceKinds } from "hooks/use-service-kinds";
import { useSubscriptionInfo } from "hooks/use-subscription-info";
import { useSubscriptionsByStatuses } from "hooks/use-subscriptions-by-statuses";
import { IDictionaryDTO } from "interfaces";
import { getDictionaryItemByCode } from "utils/get-dictionary-item-by-code";
import { getPhoneNumbeWithMask } from "utils/get-phone-number-with-mask";

import { SubscriptionInfoForm } from "./components/subscription-info-form";
import { ISubscriptionInfoFormStruct } from "./interfaces";

interface IMatchParams {
  id: string;
}

export const SubscriptionInfoPage = (): JSX.Element => {
  const { id } = useParams<IMatchParams>();

  useGoogleAnalyticsPages({
    event: GAP_EVENT_TYPE.SCRN,
    screenName: GA_SCREEN_NAME.sub_info,
  });

  const { data: serviceKinds, isLoading: isLoadingServiceKinds } =
    useServiceKinds();
  const { data: durations, isLoading: isLoadingDurations } = useDurations();
  const [initialFormData, setInitialFormData] =
    React.useState<ISubscriptionInfoFormStruct | null>(null);

  const { tabNames } = useTabNames();

  const { subscriptionsByStatuses } = useSubscriptionsByStatuses();
  const {
    data: abilityToCreatePayments,
    isLoading: isLoadingAbilityToCreatePayments,
    isError: isErrorAbilityToCreatePayments,
    isFetching: isFetchingAbilityToCreatePayments,
  } = useCheckAbilityToCreatePayments({
    refetchOnWindowFocus: false,
  });

  const {
    data: subscriptionInfo,
    isLoading: isLoadingSubscriptionInfo,
    isError: isErrorSubscriptionInfo,
  } = useSubscriptionInfo(id);

  const profileSubscriptionData = subscriptionInfo?.length
    ? subscriptionInfo[0]
    : null;
  const billingSubscriptionData = subscriptionInfo?.length
    ? subscriptionInfo[1]
    : null;

  const isError: boolean =
    isErrorAbilityToCreatePayments || isErrorSubscriptionInfo;

  const subscriptionName: string = profileSubscriptionData?.name ?? "";

  const breadcrumbLinks = React.useMemo(
    () => [
      {
        name: tabNames.subscriptions,
        href: PATH_SUBSCRIPTIONS,
      },
      {
        name: subscriptionName ? subscriptionName : "Подписка",
        href: "",
      },
    ],
    [subscriptionName, tabNames.subscriptions]
  );

  React.useEffect(() => {
    if (
      !isLoadingSubscriptionInfo &&
      !isLoadingDurations &&
      !isLoadingServiceKinds
    ) {
      // NOTE: DATA FROM BILLING SERVICE
      const optionDuration: IDictionaryDTO | null = getDictionaryItemByCode(
        billingSubscriptionData?.duration,
        durations
      );
      const optionServiceKind: IDictionaryDTO | null = getDictionaryItemByCode(
        billingSubscriptionData?.serviceKind,
        serviceKinds
      );

      // NOTE: DATA FROM PROFILE SEVICE
      const deviceLogin: string = profileSubscriptionData?.device?.id
        ? String(profileSubscriptionData.device.id)
        : "";
      const devicePassword: string = profileSubscriptionData?.device?.password
        ? profileSubscriptionData.device.password
        : "";

      const phoneWithMask = profileSubscriptionData?.subscribed?.login
        ? getPhoneNumbeWithMask(profileSubscriptionData?.subscribed?.login)
        : "";

      const formStruct: ISubscriptionInfoFormStruct = {
        [FIELD_NAMES.SUBSCRIPTION_NAME]: profileSubscriptionData?.name
          ? profileSubscriptionData.name
          : "",
        [FIELD_NAMES.SUBSCRIPTION_SERVICE_KIND]: optionServiceKind,
        [FIELD_NAMES.SUBSCRIPTION_DURATION]: optionDuration,
        [FIELD_NAMES.SUBSCRIPTION_PHONE]: phoneWithMask,
        [FIELD_NAMES.SUBSCRIPTION_EMAIL]: profileSubscriptionData?.email
          ? profileSubscriptionData?.email
          : "",
        [FIELD_NAMES.SUBSCRIPTION_DESCRIPTION]:
          profileSubscriptionData?.description
            ? profileSubscriptionData.description
            : "",
        [FIELD_NAMES.SUBSCRIPTION_DEVICE_LOGIN]: deviceLogin,
        [FIELD_NAMES.SUBSCRIPTION_DEVICE_PASSWORD]: devicePassword,
      };

      setInitialFormData(formStruct);
    }
  }, [
    durations,
    serviceKinds,
    isLoadingSubscriptionInfo,
    billingSubscriptionData?.duration,
    billingSubscriptionData?.serviceKind,
    profileSubscriptionData?.email,
    profileSubscriptionData?.subscribed?.login,
    profileSubscriptionData?.description,
    profileSubscriptionData?.device?.id,
    profileSubscriptionData?.device?.password,
    profileSubscriptionData?.name,
    isLoadingDurations,
    isLoadingServiceKinds,
  ]);

  if (
    isLoadingSubscriptionInfo ||
    isFetchingAbilityToCreatePayments ||
    isLoadingAbilityToCreatePayments ||
    isLoadingDurations ||
    isLoadingServiceKinds
  ) {
    return <Loader />;
  }

  return (
    <>
      <AppHelmet
        title={
          subscriptionName
            ? `Информация по подписке ${subscriptionName} - Личный кабинет`
            : "Информация по подписке - Личный кабинет"
        }
      />

      {isError ? (
        <ContainerContent>
          <Breadcrumbs links={breadcrumbLinks} />

          <TemplateServiceNotAvailable />
        </ContainerContent>
      ) : (
        <ContainerContent>
          <Breadcrumbs links={breadcrumbLinks} />

          {initialFormData &&
            profileSubscriptionData &&
            billingSubscriptionData && (
              <SubscriptionInfoForm
                subscriptionId={id}
                profileSubscriptionData={profileSubscriptionData}
                billingSubscriptionData={billingSubscriptionData}
                initialFormData={initialFormData}
                subscriptionCountCanRenew={
                  subscriptionsByStatuses.canRenew.length
                }
                isCanCreatePayment={abilityToCreatePayments?.canCreatePayment}
              />
            )}
        </ContainerContent>
      )}

      <FooterOfPersonalArea />
    </>
  );
};
