import React from "react";

import { IconWrapper } from "components/icon-wrapper";
import { MTSIcon } from "components/mts-icon";
import { Plug } from "components/plug";

import classes from "./no-payments.module.css";

export const NoPayments = React.memo((): JSX.Element => {
  return (
    <Plug
      icon={
        <IconWrapper
          size={64}
          icon={
            <MTSIcon.ChequeDone
              sx={{
                fontSize: "24px",
                color: "var(--color-constant-blackberry-light)",
              }}
            />
          }
        />
      }
      label={
        <div className={classes.text}>
          <span>Платежей нет</span>
          <span>Добавьте подписки</span>
        </div>
      }
    />
  );
});
