import { IDictionaryDTO } from "interfaces";

export const getDictionaryItemByCode = (
  itemCode: string | undefined | null,
  dictionary: IDictionaryDTO[] | undefined
): IDictionaryDTO | null => {
  let option = null;

  if (itemCode && dictionary?.length) {
    const result = dictionary.find((item) => item.code === itemCode);
    if (result) option = result;
  }

  return option;
};
