import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const Support = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M36 19.9995H35.9921C35.9921 11.1632 28.8305 4 19.9962 4C11.1619 4 4.00024 11.1633 4.0002 19.9995L4 23.9986C4 24.296 4.00777 24.5934 4.02146 24.8866C4.10672 26.7127 4.14935 27.6258 5.14526 28.5918C6.14118 29.5577 6.96503 29.5708 8.61273 29.597C8.7381 29.599 8.86417 29.599 8.98953 29.5969C10.6358 29.5701 11.459 29.5567 12.4533 28.5922C13.4475 27.6277 13.4903 26.7156 13.5759 24.8913C13.5897 24.5966 13.5976 24.2976 13.5976 23.9986C13.5976 23.6997 13.5897 23.4007 13.5759 23.106C13.4903 21.2817 13.4475 20.3696 12.4533 19.4051C11.459 18.4406 10.6358 18.4272 8.98953 18.4004C8.92606 18.3993 8.86246 18.3988 8.79878 18.3988L7.29847 18.3995C8.08564 12.0856 13.4705 7.19991 19.9962 7.19991C26.5219 7.19991 31.9067 12.0856 32.6939 18.3995C32.1303 18.3993 31.5661 18.3912 31.0026 18.4004C29.3563 18.4272 28.5331 18.4406 27.5388 19.4051C26.5446 20.3696 26.5018 21.2817 26.4162 23.106C26.4024 23.4007 26.3945 23.6997 26.3945 23.9986C26.3945 24.2976 26.4024 24.5966 26.4162 24.8913C26.5018 26.7156 26.5446 27.6277 27.5388 28.5922C28.5331 29.5567 29.3563 29.5701 31.0026 29.5969C31.3864 29.6032 31.771 29.5937 32.1545 29.58C31.9974 29.9639 31.7287 30.3869 31.137 30.9809C30.4213 31.6993 29.941 31.9446 29.4414 32.0986C28.7679 32.3062 27.9307 32.394 26.2048 32.5438C25.2882 32.6234 24.2847 32.6898 23.1947 32.7351C23.1606 30.9974 21.7457 29.6034 20.0002 29.6034C18.2334 29.6034 16.8002 31.0319 16.8002 32.7992C16.8002 34.5664 18.2293 36 19.9962 36C22.4747 36 24.6376 35.8918 26.4814 35.7318C29.7208 35.4505 31.3405 35.3099 33.4031 33.2395C35.4633 31.1716 35.5947 29.6197 35.8526 26.5207L36 19.9995Z" />
    </SvgIcon>
  );
};

export default Support;
