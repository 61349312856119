import { REQUIRED_FIELD_MESSAGE } from "constants/keys";

import * as yup from "yup";

const regexValidFIO = /[А-ЯЁа-яё-]*([\s][А-ЯЁа-яё-]*)+/;
const VALID_FIO_MESSAGE = "Не менее 2 слов, кириллица";

const regexInvalidSymbols = /^[аА-яЯёЁ\s-]+$/;
const INVALID_SYMBOLS_MESSAGE = "Недопустимые символы/цифры";

const MAX_LENGTH = 56;
const MAX_LENGHT_MESSAGE = "Не более 56 символов";

export const fieldFIO = yup
  .string()
  .trim()
  .max(MAX_LENGTH, MAX_LENGHT_MESSAGE)
  .matches(regexValidFIO, VALID_FIO_MESSAGE)
  .matches(regexInvalidSymbols, INVALID_SYMBOLS_MESSAGE);

export const fieldFIORequired = yup
  .string()
  .required(REQUIRED_FIELD_MESSAGE)
  .trim()
  .max(MAX_LENGTH, MAX_LENGHT_MESSAGE)
  .matches(regexValidFIO, VALID_FIO_MESSAGE)
  .matches(regexInvalidSymbols, INVALID_SYMBOLS_MESSAGE);
