import { PAYMENT_STATUSES } from "constants/keys";

export class PaymentStatusCheckerByCode {
  static isDraft(status: string | undefined): boolean {
    return status === PAYMENT_STATUSES.DRAFT;
  }
  static isInProcess(status: string | undefined): boolean {
    return status === PAYMENT_STATUSES.IN_PROCESS;
  }
  static isDone(status: string | undefined): boolean {
    return status === PAYMENT_STATUSES.DONE;
  }
  static isFailed(status: string | undefined): boolean {
    return status === PAYMENT_STATUSES.FAILED;
  }
  static isTest(status: string | undefined): boolean {
    return status === PAYMENT_STATUSES.TEST;
  }
  static isToQueue(status: string | undefined): boolean {
    return status === PAYMENT_STATUSES.TO_QUEUE;
  }
}
