import { QueryKeys, HTTPStatusCode } from "constants/keys";

import { useQuery } from "react-query";

import { AxiosError } from "axios";
import { ICustomerAdminDTO } from "interfaces";
import { getCustomerAdminData } from "services/api/index";

import { useNotifications } from "./use-notifications";
import { useProfileData } from "./use-profile-data";

export const useCustomerAdminData = () => {
  const { addErrorMessage } = useNotifications();
  const { isCustomerAdminRole, isLoadingAuthData } = useProfileData();

  return useQuery<ICustomerAdminDTO, AxiosError>(
    QueryKeys.CUSTOMER_ADMIN_DATA,
    () => getCustomerAdminData(),
    {
      enabled: !isLoadingAuthData && isCustomerAdminRole,
      retry: 0,
      onError: (error: AxiosError) => {
        const status = error.response?.status;

        if (status !== HTTPStatusCode.UNAUTHORIZED_401) {
          addErrorMessage({ message: "Не удалось получить данные о компании" });
        }
      },
    }
  );
};
