import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export const GeodesyCadastreIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8 6.5V38.0574L19.4285 41.5L29.7142 38.6311L39.9999 41.5V9.94262L29.1428 6.5L18.2857 9.94262L8 6.5Z"
        stroke="#626C77"
        strokeLinecap="round"
      />
      <path
        d="M8.57031 29.4501L23.9988 24.2861H39.9987"
        stroke="#626C77"
        strokeLinecap="round"
      />
      <path
        d="M13.1426 8.7959L17.1426 26.009"
        stroke="#626C77"
        strokeLinecap="round"
      />
      <path
        d="M25.7148 8.22095L28.572 24.2865"
        stroke="#626C77"
        strokeLinecap="round"
      />
      <path d="M39.4285 17.4023H28" stroke="#626C77" strokeLinecap="round" />
      <path
        d="M20.5703 26.0078L22.2846 40.3521"
        stroke="#626C77"
        strokeLinecap="round"
      />
      <path
        d="M22.2852 32.8923L39.9994 30.0234"
        stroke="#626C77"
        strokeLinecap="round"
      />
      <path d="M32 38.6304V31.7451" stroke="#626C77" strokeLinecap="round" />
    </SvgIcon>
  );
};
