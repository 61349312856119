import React from "react";

import { IOpenedTabData } from "interfaces/local";

import { ContextHeader } from "./context-header";

export const ContextHeaderProvider: React.FC = ({ children }) => {
  const [tabNotAuthorizedPositions, setTabNotAuthorizedPositions] =
    React.useState<DOMRect[]>([]);
  const [tabAuthorizedPositions, setTabAuthorizedPositions] = React.useState<
    DOMRect[]
  >([]);
  const [isOpenTabTooltip, setIsOpenTabTooltip] =
    React.useState<boolean>(false);
  const [openedTabData, setOpenedTabData] =
    React.useState<IOpenedTabData | null>(null);

  const closeTabTooltip = (): void => {
    setIsOpenTabTooltip(false);
    setOpenedTabData(null);
  };

  return (
    <ContextHeader.Provider
      value={{
        tabNotAuthorizedPositions,
        setTabNotAuthorizedPositions,
        tabAuthorizedPositions,
        setTabAuthorizedPositions,
        isOpenTabTooltip,
        setIsOpenTabTooltip,
        openedTabData,
        setOpenedTabData,
        closeTabTooltip,
      }}
    >
      {children}
    </ContextHeader.Provider>
  );
};
