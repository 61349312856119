import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export const NetworkIcon = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon
      width="96"
      height="96"
      viewBox="0 0 96 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M48.0001 16C30.34 16 16 30.3396 16 48.0001C16 59.4958 22.0599 69.5687 31.1725 75.2062C31.6689 75.5133 32.4002 75.3409 32.7073 74.8444C33.0144 74.348 32.8412 73.6167 32.3448 73.3096C23.8588 68.0587 18.2068 58.7198 18.2068 47.9992C18.2068 31.5321 31.5327 18.2059 48 18.2059C64.4673 18.2059 77.7932 31.5319 77.7932 47.9992C77.7932 58.7196 72.1412 68.0584 63.6552 73.3096C63.1588 73.6167 62.9856 74.3479 63.2927 74.8444C63.5998 75.3409 64.331 75.5133 64.8275 75.2062C73.9401 69.5673 80 59.495 80 48.0001C80 30.34 65.6596 16 47.9999 16H48.0001ZM48.0001 29.2412C37.6529 29.2412 29.2416 37.6527 29.2416 47.9997C29.2416 53.8004 31.8901 58.9734 36.0352 62.4132C36.4593 62.885 37.3098 62.8643 37.7108 62.3732C38.1118 61.8821 37.9617 61.0447 37.4145 60.7237C33.7546 57.6872 31.4485 53.1335 31.4485 48.0005C31.4485 38.8464 38.8462 31.4487 48.0003 31.4487C57.1545 31.4487 64.5521 38.8464 64.5521 48.0005C64.5521 53.1345 62.2461 57.6872 58.587 60.7245C58.0397 61.0454 57.8896 61.8829 58.2906 62.374C58.6917 62.865 59.5422 62.8858 59.9663 62.414C64.1111 58.9742 66.7598 53.801 66.7598 48.0005C66.7598 37.6533 58.3484 29.242 48.0013 29.242L48.0001 29.2412ZM48.0001 41.3796C44.3564 41.3796 41.379 44.3568 41.379 48.0007C41.379 51.6437 44.3562 54.6218 48.0001 54.6218C51.6431 54.6218 54.6212 51.6447 54.6212 48.0007C54.6212 44.357 51.6441 41.3796 48.0001 41.3796ZM48.0001 43.5863C50.451 43.5863 52.4136 45.5491 52.4136 47.9997C52.4136 50.4504 50.4508 52.4132 48.0001 52.4132C45.5495 52.4132 43.5867 50.4496 43.5867 47.9997C43.5867 45.5489 45.5495 43.5863 48.0001 43.5863Z"
        fill="black"
      />
    </SvgIcon>
  );
};
