import React from "react";

import { useInView } from "react-intersection-observer";

import classNames from "classnames";
import { BlockContent } from "components/block-content";
import { DialogContactUs } from "components/dialog-contact-us";
import { MTSButton } from "components/mts-button";
import { SliderNav } from "components/slider-nav";
import {
  GAE_ACTION_GROUP,
  GAE_BUTTON_LOCATION,
  GAE_EVENT,
  GAE_EVENT_ACTION,
  GAE_EVENT_CATEGORY,
  GAE_EVENT_CONTENT,
  GAE_EVENT_LABEL,
  useGoogleAnalyticsEvents,
} from "hooks/google-analytics";
import { useAppTheme } from "hooks/use-app-theme";
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { SwiperClass } from "swiper/react";

import "swiper/modules/navigation/navigation.min.css";

import "swiper/swiper.css";
import agronavImg from "./img/agronav-img.png";
import autopilotImg from "./img/autopilot-img.png";
import bgImg from "./img/bg-img.jpg";
import blockImg from "./img/block-img.png";
import deviceImg from "./img/device-img.png";
import classes from "./slider.module.css";

export const Slider = (): JSX.Element => {
  const slides = [
    {
      title: (
        <>
          Система агронавигации
          <br />
          AGN АТ5-RTK
        </>
      ),
      texts: [
        <>
          Универсальная система навигации, в базовой комплектации&nbsp;работает
          как курсоуказатель
        </>,
      ],
      backgroundImg: bgImg,
      contentImg: agronavImg,
    },
    {
      title: (
        <>
          Блок управления опрыскивателем
          <br />
          AGN CMU-01M
        </>
      ),
      texts: [
        <>
          Часть системы контроля за внесением жидких
          <br />и сухих химикатов, в том числе удобрений
        </>,
      ],
      backgroundImg: bgImg,
      contentImg: blockImg,
    },
    {
      title: (
        <>
          Электрический автопилот
          <br />
          FJ DYNAMICS (РТК&nbsp;АШ)
        </>
      ),
      texts: [
        <>
          Система автоматического вождения подходит для боронования,
          опрыскивания, почво&shy;обработки, посева зерновых, внесения удобрений
          и средств защиты растений
        </>,
      ],
      backgroundImg: bgImg,
      contentImg: autopilotImg,
    },
    {
      title: (
        <>
          Подрудливающее устройство
          <br />
          АШ-ДРАЙВ G4
        </>
      ),
      texts: [
        <>
          Удерживает машину на заданной траектории{" "}
          <br className={classes.break_1} />
          во время рабочего хода, что приводит к улучшению качества работы,
          точности проходов, снижает утомляемость оператора. Подходит для
          установки на любую современную сельхозтехнику
        </>,
      ],
      backgroundImg: bgImg,
      contentImg: deviceImg,
    },
  ];

  const theme = useAppTheme();

  const { sendGAE } = useGoogleAnalyticsEvents();

  const { ref: agroLowerSlideRef, inView: inViewAgroDownSlider } = useInView({
    initialInView: false,
    threshold: 0.5,
    triggerOnce: true,
  });

  const [slideProgress, setSlideProgress] = React.useState<number>(0);

  const [isOpenDialogContactUs, setIsOpenDialogContactUs] =
    React.useState<boolean>(false);

  const handleOpenDialogContactUs = (): void => {
    setIsOpenDialogContactUs(true);
  };

  const handleCloseDialogContactUs = (): void => {
    setIsOpenDialogContactUs(false);
  };

  const onAutoplayTimeLeft = (
    s: SwiperClass,
    time: number,
    progress: number
  ) => {
    setSlideProgress(progress);
  };

  const handleSendMetrics = (direction?: string) => {
    if (direction) {
      let _eventLabel;
      direction === "prev"
        ? (_eventLabel = GAE_EVENT_LABEL.nazad)
        : (_eventLabel = GAE_EVENT_LABEL.vpered);

      sendGAE({
        id: 59,
        event: GAE_EVENT.vntZeml,
        eventCategory: GAE_EVENT_CATEGORY.tochnoe_zemledelie,
        eventAction: GAE_EVENT_ACTION.element_click,
        eventLabel: _eventLabel,
        eventValue: null,
        eventContent: GAE_EVENT_CONTENT.down,
        buttonLocation: GAE_BUTTON_LOCATION.screen,
        filterName: null,
        actionGroup: GAE_ACTION_GROUP.interactions,
        productName: null,
      });
    } else {
      sendGAE({
        id: 58,
        event: GAE_EVENT.vntZeml,
        eventCategory: GAE_EVENT_CATEGORY.tochnoe_zemledelie,
        eventAction: GAE_EVENT_ACTION.button_click,
        eventLabel: GAE_EVENT_LABEL.ostavit_zayavku,
        eventValue: null,
        eventContent: null,
        buttonLocation: GAE_BUTTON_LOCATION.screen,
        filterName: null,
        actionGroup: GAE_ACTION_GROUP.interactions,
        productName: null,
      });
    }
  };

  React.useEffect(() => {
    if (inViewAgroDownSlider) {
      sendGAE({
        id: 66,
        event: GAE_EVENT.vntZeml,
        eventCategory: GAE_EVENT_CATEGORY.tochnoe_zemledelie,
        eventAction: GAE_EVENT_ACTION.block_show,
        eventLabel: GAE_EVENT_LABEL.down_slider,
        eventValue: null,
        eventContent: null,
        buttonLocation: null,
        filterName: null,
        actionGroup: GAE_ACTION_GROUP.non_interactions,
        productName: null,
      });
    }
  }, [inViewAgroDownSlider, sendGAE]);

  return (
    <>
      <div className={classes.blockSlider} ref={agroLowerSlideRef}>
        <Swiper
          spaceBetween={0}
          centeredSlides={true}
          loop={true}
          autoplay={{
            delay: 15000,
            disableOnInteraction: false,
          }}
          modules={[Autoplay]}
          onAutoplayTimeLeft={onAutoplayTimeLeft}
          className={classes.swiper}
        >
          {slides.map((slideItem, index: number) => {
            return (
              <SwiperSlide
                key={index}
                className={classes.swiperSlide}
                style={{ backgroundImage: `url(${slideItem.backgroundImg})` }}
              >
                <BlockContent
                  sx={{
                    position: "relative",
                    height: "100%",
                    padding: "0 0",
                    zIndex: 1,
                  }}
                >
                  <img
                    className={classNames(
                      classes.contentImg,
                      index === 0
                        ? classes.contentImg_agronav
                        : index === 1
                        ? classes.contentImg_block
                        : index === 2
                        ? classes.contentImg_autopilot
                        : index === 3
                        ? classes.contentImg_device
                        : ""
                    )}
                    src={slideItem.contentImg}
                    alt="contentImg"
                  />
                  <div className={classes.swiperSlideContent}>
                    <h4 className={classes.swiperSlideContentIntro}>
                      Закажите оборудование&nbsp;
                      <br className={classes.title__break} />
                      со скидками от объёма заказа
                    </h4>

                    <h2 className={classes.swiperSlideContentTitle}>
                      {slideItem.title}
                    </h2>

                    <ul className={classes.contentList}>
                      {slideItem.texts.map(
                        (text: string | JSX.Element, _index: number) => (
                          <p className={classes.contentListText} key={_index}>
                            {text}
                          </p>
                        )
                      )}
                    </ul>

                    <MTSButton
                      size="L"
                      variant="primary"
                      sx={{
                        width: "292px",
                        marginTop: "24px",
                        [theme.breakpoints.down("tablet_768")]: {
                          width: "320px",
                        },
                      }}
                      onClick={() => {
                        handleOpenDialogContactUs();
                        handleSendMetrics();
                      }}
                    >
                      Оставить заявку
                    </MTSButton>
                  </div>
                </BlockContent>
              </SwiperSlide>
            );
          })}
          <SliderNav
            sliderProgress={slideProgress}
            onClickNext={(next) => handleSendMetrics(next)}
            onClickPrev={(prev) => handleSendMetrics(prev)}
          />
        </Swiper>
      </div>
      <DialogContactUs
        isOpen={isOpenDialogContactUs}
        handleCloseDialog={handleCloseDialogContactUs}
        title="Оставить заявку на оборудование"
        feedbackType="EQUIPMENT"
      />
    </>
  );
};
