import { FIELD_NAMES } from "constants/keys";
import {
  PATH_SUBSCRIPTION_TAB_ALL,
  PATH_SUBSCRIPTION_TAB_ACTIVATED,
  PATH_SUBSCRIPTION_TAB_PAID,
  PATH_SUBSCRIPTION_TAB_EXPIRED,
  PATH_SUBSCRIPTION_TAB_RENEW,
} from "constants/routes";

import React from "react";

import { useTable, useGlobalFilter, useFilters } from "react-table";

import { IMTSTab } from "components/mts-tabs";
import { useTableSubscriptionsColumns } from "hooks/table/use-table-subscriptions-columns";
import { useDurations } from "hooks/use-durations";
import {
  ISubscriptionsByStatuses,
  ITableSubscriptionsFilters,
} from "interfaces";
import { ContextSubscriptions } from "store/context-subscriptions";
import { generateId } from "utils/generate-id";
import { getDictionaryItemCodeByName } from "utils/get-dictionary-item-code-by-name";
import { isSubscriptionReadyToRenew } from "utils/is-subscription-ready-to-renew.ts";
import { SubscriptionStatusCheckerByCode } from "utils/subscription-status-checker-by-code";

import { useTabSelected } from "./use-tab-selected";

enum SubscriptionTableTabs {
  ALL = "Все",
  ACTIVATED = "Активные",
  PAID = "Ждут активации",
  RENEW = "Пора продлить",
  EXPIRED = "Истёк срок действия",
}

const subscriptionsPageTabs: { [key: string]: IMTSTab } = {
  all: {
    id: generateId(),
    type: "link",
    name: SubscriptionTableTabs.ALL,
    href: PATH_SUBSCRIPTION_TAB_ALL,
    count: 0,
    dataTestId: "lnk_subs_all",
    access: ["all"],
  },
  paid: {
    id: generateId(),
    type: "link",
    href: PATH_SUBSCRIPTION_TAB_PAID,
    name: SubscriptionTableTabs.PAID,
    count: 0,
    dataTestId: "lnk_subs_waiting",
    access: ["all"],
  },
  activated: {
    id: generateId(),
    type: "link",
    href: PATH_SUBSCRIPTION_TAB_ACTIVATED,
    name: SubscriptionTableTabs.ACTIVATED,
    count: 0,
    dataTestId: "lnk_subs_active",
    access: ["all"],
  },
  renew: {
    id: generateId(),
    type: "link",
    href: PATH_SUBSCRIPTION_TAB_RENEW,
    name: SubscriptionTableTabs.RENEW,
    count: 0,
    dataTestId: "lnk_subs_extend",
    access: ["all"],
  },
  expired: {
    id: generateId(),
    type: "link",
    href: PATH_SUBSCRIPTION_TAB_EXPIRED,
    name: SubscriptionTableTabs.EXPIRED,
    count: 0,
    dataTestId: "lnk_subs_expired",
    access: ["all"],
  },
};

export const useTabsInSubscriptionsPage = (
  subscriptions: ISubscriptionsByStatuses,
  filters: ITableSubscriptionsFilters
) => {
  const { tableColumns } = useTableSubscriptionsColumns();
  const { setSubscriptionCounts } = React.useContext(ContextSubscriptions);

  const [tabs, setTabs] = React.useState<IMTSTab[]>([
    subscriptionsPageTabs.all,
    subscriptionsPageTabs.paid,
    subscriptionsPageTabs.activated,
    subscriptionsPageTabs.renew,
    subscriptionsPageTabs.expired,
  ]);
  const { selectedTabId } = useTabSelected(tabs);

  const { data: durations, isLoading: isLoadingDurations } = useDurations();
  const { setFilter, setGlobalFilter, rows } = useTable(
    {
      columns: tableColumns,
      data: subscriptions.all,
    },
    useFilters,
    useGlobalFilter
  );

  React.useEffect(() => {
    setGlobalFilter(filters.search || "");
    setFilter(
      FIELD_NAMES.SUBSCRIPTION_SERVICE_KIND,
      filters.serviceKind?.id || null
    );

    setFilter(FIELD_NAMES.SUBSCRIPTION_DURATION, filters.duration?.name);
  }, [setFilter, setGlobalFilter, filters, subscriptions?.all]);

  React.useEffect(() => {
    if (!isLoadingDurations) {
      let countOfAllSubs = rows.length;
      let countOfActivatedSubs = 0;
      let countOfPaidSubs = 0;
      let countOfRenewSubs = 0;
      let countOfExpiredSubs = 0;
      let countOfCanRenewSubs = 0;

      rows.forEach((rowData) => {
        const subscriptionStatus: string = rowData?.values?.status;
        const expiredDate: string = rowData?.values?.expiredDate ?? "";
        const inProlongation: boolean = !!rowData?.original?.inProlongation;
        const durationCode: string =
          getDictionaryItemCodeByName(rowData?.original?.duration, durations) ??
          "";
        const isSubscriptionReadyForRenew: boolean = isSubscriptionReadyToRenew(
          {
            durationCode,
            expiredDate,
            statusCode: subscriptionStatus,
            inProlongation,
          }
        );

        if (
          SubscriptionStatusCheckerByCode.isActivated(subscriptionStatus) &&
          countOfActivatedSubs <= 999
        ) {
          countOfActivatedSubs++;
        } else if (
          SubscriptionStatusCheckerByCode.isPaid(subscriptionStatus) &&
          countOfPaidSubs <= 999
        ) {
          countOfPaidSubs++;
        } else if (
          SubscriptionStatusCheckerByCode.isExpired(subscriptionStatus) &&
          countOfExpiredSubs <= 999
        ) {
          countOfExpiredSubs++;
        }

        if (isSubscriptionReadyForRenew && countOfRenewSubs <= 999) {
          countOfRenewSubs++;

          if (!inProlongation) {
            countOfCanRenewSubs++;
          }
        }
      });

      setSubscriptionCounts({
        all: countOfAllSubs,
        activated: countOfActivatedSubs,
        paid: countOfPaidSubs,
        renew: countOfRenewSubs,
        expired: countOfExpiredSubs,
        canRenew: countOfCanRenewSubs,
      });

      setTabs([
        {
          ...subscriptionsPageTabs.all,
          count: countOfAllSubs,
        },
        {
          ...subscriptionsPageTabs.paid,
          count: countOfPaidSubs,
        },
        {
          ...subscriptionsPageTabs.activated,
          count: countOfActivatedSubs,
        },
        {
          ...subscriptionsPageTabs.renew,
          count: countOfRenewSubs,
        },
        {
          ...subscriptionsPageTabs.expired,
          count: countOfExpiredSubs,
        },
      ]);
    }

    return () => {
      setSubscriptionCounts({
        all: 0,
        activated: 0,
        paid: 0,
        renew: 0,
        expired: 0,
        canRenew: 0,
      });
    };
  }, [durations, isLoadingDurations, rows, setSubscriptionCounts]);

  return { tabs, selectedTabId };
};
