import { DISCOUNT_TYPE } from "constants/keys";

import { IDiscountDTO } from "interfaces";

import { useDiscounts } from "./use-discounts";

export const useSubscriptionDiscounts = () => {
  const discountsQuery = useDiscounts();

  const filteredAndSortedData: IDiscountDTO[] =
    discountsQuery.data
      ?.filter(
        (discount: IDiscountDTO) =>
          discount.type === DISCOUNT_TYPE.SUBSCRIPTION_COUNT_DISCOUNT
      )
      .sort((a: IDiscountDTO, b: IDiscountDTO) => {
        if (a.percent < b.percent) {
          return -1;
        }
        if (a.percent > b.percent) {
          return 1;
        }

        return 0;
      }) ?? [];

  return {
    ...discountsQuery,
    data: filteredAndSortedData,
  };
};
