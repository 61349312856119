import { IDictionaryDTO } from "interfaces";

export const getDictionaryItemNameByCode = (
  itemCode: string | undefined,
  dictionary: IDictionaryDTO[] | undefined
): string => {
  let itemName: string = "";

  if (itemCode && dictionary?.length) {
    const dictionaryItem: IDictionaryDTO | undefined = dictionary.find(
      (item) => item.code === itemCode
    );

    if (dictionaryItem) {
      itemName = dictionaryItem.name;
    }
  }

  return itemName;
};
