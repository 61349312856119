import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export const ArrowNextIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="1.5"
        y="1.5"
        width="61"
        height="61"
        rx="30.5"
        stroke="white"
        strokeWidth="3"
      />
      <path
        d="M31.6367 45.6445L45.0913 32.0005L31.6367 18.3564"
        stroke="white"
        strokeWidth="3"
      />
      <line
        x1="45.0898"
        y1="32.7734"
        x2="17.4535"
        y2="32.7734"
        stroke="white"
        strokeWidth="3"
      />
    </SvgIcon>
  );
};
