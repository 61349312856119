import { useMutation } from "react-query";

import { useNotifications } from "hooks/use-notifications";
import { IFeedbackDTO } from "interfaces";
import { doContactUs } from "services/api";

export const useMutationContactUs = () => {
  const { addErrorMessage, addSuccessMessage } = useNotifications();

  return useMutation((payload: IFeedbackDTO) => doContactUs(payload), {
    onSuccess: (response) => {
      addSuccessMessage({ message: "Данные отправлены" });
    },
    onError: (error) => {
      addErrorMessage({ message: "Что-то пошло не так. Повторите попытку" });
    },
  });
};
