import { MapVariants } from "constants/keys";

import React from "react";

import { IMapVariants } from "interfaces/local";
import * as L from "leaflet";

import { INITIAL_MAP_PARAMS, MAP_LAYERS_NAME, WMS_URL } from "../constants";

interface IUseCreateMapProps {
  DOMElementId: string;
  mapVariant: IMapVariants;
}

export const useCreateMap = (
  props: IUseCreateMapProps
): { theMap: L.Map | null } => {
  const { DOMElementId, mapVariant } = props;
  const [theMap, setTheMap] = React.useState<L.Map | null>(null);
  const [currenMapVariant, setCurrentMapVariant] =
    React.useState<IMapVariants | null>(null);
  const mapLayerRef = React.useRef<L.Layer>();

  React.useEffect(() => {
    const addMap = (_map: L.Map, _mapVariant: IMapVariants): void => {
      switch (mapVariant) {
        // NOTE: Добавление карты YANDEX
        case MapVariants.yandex:
          // @ts-ignore
          const mapLayer = L.yandex();

          const baseLayers = {
            "Yandex map": mapLayer,
          };
          L.control.layers(baseLayers, undefined, { collapsed: false });

          mapLayerRef.current = mapLayer;
          mapLayer.addTo(_map);
          break;
        // NOTE: Добавление карты EGIS

        case MapVariants.egis:
          const wmsParamsRussia = {
            tiled: true,
            transparent: true,
            layers: MAP_LAYERS_NAME,
            format: "image/png8",
          };

          const newMapLayer = L.tileLayer.wms(WMS_URL, wmsParamsRussia);
          mapLayerRef.current = newMapLayer;
          newMapLayer.addTo(_map);
          break;
        default:
          break;
      }

      setCurrentMapVariant(mapVariant);
    };

    if (!!theMap) {
      if (currenMapVariant !== mapVariant && !!mapLayerRef.current) {
        theMap.removeLayer(mapLayerRef.current);
        addMap(theMap, mapVariant);
      }
    } else {
      // NOTE: Создание карты
      const newMap = L.map(DOMElementId, {
        crs: L.CRS.EPSG900913,
        preferCanvas: true,
        renderer: L.canvas(),
        zoomControl: false,
      });

      // NOTE: Инициализация начальных координат и зума
      newMap.setView(INITIAL_MAP_PARAMS.center, INITIAL_MAP_PARAMS.zoom);

      // NOTE: Добавление ссылки на MTS LocationPro
      newMap.attributionControl.setPosition("bottomleft").setPrefix("");

      // NOTE: Настройка кнопок зума
      const zoomContainer = L.control.zoom({
        position: "topright",
        zoomInTitle: "Увеличить",
        zoomOutTitle: "Уменьшить",
      });
      zoomContainer.addTo(newMap);

      addMap(newMap, mapVariant);

      setTheMap(newMap);
    }
  }, [currenMapVariant, DOMElementId, mapVariant, theMap]);

  return { theMap };
};
