import React from "react";

import { BlockContent } from "components/block-content";

import { AccuracyIcon } from "./icons/accuracy-icon";
import { DistortionIcon } from "./icons/distortion-icon";
import { HightCostIcon } from "./icons/high-cost-icon";
import { LaboriounessIcon } from "./icons/laboriousness-icon";
import classes from "./problems.module.css";

export const Problems = React.memo((): JSX.Element => {
  return (
    <div className={classes.blockProblems}>
      <BlockContent sx={{ alignSelf: "center" }}>
        <h2 className={classes.title}>Какие проблемы вы&nbsp;решите</h2>

        <ul className={classes.list}>
          <li className={classes.listItem}>
            <AccuracyIcon sx={{ fontSize: "96px", color: "transparent" }} />
            <h4 className={classes.listItemTitle}>
              Важна сантиметровая точность
            </h4>
          </li>
          <li className={classes.listItem}>
            <HightCostIcon sx={{ fontSize: "96px", color: "transparent" }} />
            <h4 className={classes.listItemTitle}>
              Высокая стоимость дополнительного специального оборудования
            </h4>
          </li>
          <li className={classes.listItem}>
            <LaboriounessIcon sx={{ fontSize: "96px", color: "transparent" }} />
            <h4 className={classes.listItemTitle}>
              Трудоёмкость подготовки детальных описаний сложных рельефов
            </h4>
          </li>
          <li className={classes.listItem}>
            <DistortionIcon sx={{ fontSize: "96px", color: "transparent" }} />
            <h4 className={classes.listItemTitle}>
              Линейные искажения при исследовании объектов значительной
              протяжённости
            </h4>
          </li>
        </ul>
      </BlockContent>
    </div>
  );
});
