import Grow from "@mui/material/Grow";
import { MTSIcon } from "components/mts-icon";

import { Size } from "./constants";
import { useSpinnerColor } from "./hooks/use-spinner-color";
import { IMTSSpinnerColor } from "./interfaces";
import classes from "./mts-spinner.module.css";
import { getSpinnerSize } from "./utils/get-spinner-size";

type ISize = keyof typeof Size;

interface ISpinnerProps {
  size?: ISize;
  color?: IMTSSpinnerColor;
  open?: boolean;
}

export const MTSSpinner = (props: ISpinnerProps): JSX.Element => {
  const { size = Size.M, color = "red", open = true } = props;
  const spinnerColor = useSpinnerColor({ spinnerColor: color });

  return (
    <Grow in={open} timeout={open ? 500 : 700}>
      <div className={classes.blockMTSSpinner}>
        <MTSIcon.Spinner
          className={classes.spinnerIcon}
          sx={{
            fontSize: getSpinnerSize(size),
            color: spinnerColor,
            "@keyframes spin": {
              from: { transform: "rotate(0deg)" },
              to: { transform: "rotate(360deg)" },
            },
          }}
        />
      </div>
    </Grow>
  );
};
