import React from "react";

import { BlockContent } from "components/block-content";
import { MTSIcon } from "components/mts-icon";
import { SliderNav } from "components/slider-nav";
import {
  GAE_ACTION_GROUP,
  GAE_BUTTON_LOCATION,
  GAE_EVENT,
  GAE_EVENT_ACTION,
  GAE_EVENT_CATEGORY,
  GAE_EVENT_CONTENT,
  GAE_EVENT_LABEL,
  useGoogleAnalyticsEvents,
} from "hooks/google-analytics";
import { useAppTheme } from "hooks/use-app-theme";
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { SwiperClass } from "swiper/react";

import "swiper/modules/navigation/navigation.min.css";

import "swiper/swiper.css";
import buildObjectsImg from "./img/build-objects-img.jpg";
import createMapsPlansImg from "./img/create-maps-plans-img.jpg";
import createNavSystemsImg from "./img/create-nav-systems-img.jpg";
import operationOfBuildingsImg from "./img/operation-of-buildings.jpg";
import classes from "./slider.module.css";

export const Slider = (): JSX.Element => {
  const slides = [
    {
      title: (
        <>
          Создание точных <br className={classes.break} />
          карт и&nbsp;планов
        </>
      ),
      texts: [
        <>Топографическая съёмка местности и&nbsp;различных коммуникаций</>,
        "Составление топографических планов территорий",
        "Актуализация данных об особенностях местности",
      ],
      img: createMapsPlansImg,
    },
    {
      title: (
        <>
          Строительство промышлен&shy;ных <br className={classes.break} />и
          граждан&shy;ских объектов недвижимости
        </>
      ),
      texts: [
        "Разработка и составление генерального плана застройки местности",
        "Изучение особенностей рельефа местности при планировании на ней построек",
        "Нанесение коммуникаций на план земельного участка",
        "Вынос в натуру данных строительного проекта",
        "Контроль соблюдения проектных замыслов и геометрических пропорций постройки в процессе строительных работ",
      ],
      img: buildObjectsImg,
    },
    {
      title: (
        <>
          Эксплуатация зданий
          <br />и сооружений
        </>
      ),
      texts: [
        <>Измерение границ территории здания или&nbsp;сооружения</>,
        <>
          Вычисление площади, объёма и&nbsp;периметра уже построенных зданий
          и&nbsp;помещений в них
        </>,
        <>
          Проведение исследовательских и&nbsp;вычислительных работ по установке
          деформаций и аварийности
        </>,
      ],
      img: operationOfBuildingsImg,
    },
    {
      title: (
        <>
          Создание навигацион&shy;ных <br className={classes.break} />
          систем
        </>
      ),
      texts: [
        "Создание планового геодезического обоснования",
        "Развитие топографических карт",
        "Разбивочные работы, контрольно-исполнительная съёмка",
      ],
      img: createNavSystemsImg,
    },
  ];

  const theme = useAppTheme();
  const [slideProgress, setSlideProgress] = React.useState<number>(0);
  const onAutoplayTimeLeft = (
    s: SwiperClass,
    time: number,
    progress: number
  ) => {
    setSlideProgress(progress);
  };

  const { sendGAE } = useGoogleAnalyticsEvents();

  const handleSendMetrics = (direction: string) => {
    let _eventLabel = GAE_EVENT_LABEL.vpered;
    if (direction === "prev") {
      _eventLabel = GAE_EVENT_LABEL.nazad;
    }
    sendGAE({
      id: 75,
      event: GAE_EVENT.vntGeod,
      eventCategory: GAE_EVENT_CATEGORY.geodeziya_i_kadastr,
      eventAction: GAE_EVENT_ACTION.element_click,
      eventLabel: _eventLabel,
      eventValue: null,
      eventContent: GAE_EVENT_CONTENT.up,
      buttonLocation: GAE_BUTTON_LOCATION.screen,
      filterName: null,
      actionGroup: GAE_ACTION_GROUP.non_interactions,
      productName: null,
    });
  };

  return (
    <div className={classes.blockSlider}>
      <Swiper
        spaceBetween={0}
        centeredSlides={true}
        loop={true}
        autoplay={{
          delay: 15000,
          disableOnInteraction: false,
        }}
        modules={[Autoplay]}
        onAutoplayTimeLeft={onAutoplayTimeLeft}
        className={classes.swiper}
      >
        {slides.map((slideItem, index: number) => {
          return (
            <SwiperSlide
              key={index}
              className={classes.swiperSlide}
              style={{ backgroundImage: `url(${slideItem.img})` }}
            >
              <BlockContent
                sx={{
                  position: "relative",
                  height: "100%",
                }}
              >
                <div className={classes.swiperSlideContent}>
                  <h2 className={classes.swiperSlideContentTitle}>
                    {slideItem.title}
                  </h2>

                  <ul className={classes.contentList}>
                    {slideItem.texts.map(
                      (text: string | JSX.Element, _index: number) => (
                        <li className={classes.contentListItem} key={_index}>
                          <MTSIcon.OkRound
                            sx={{
                              fontSize: "24px",
                              color: "var(--color-constant-greyscale-0)",
                              marginTop: "2px",
                              [theme.breakpoints.down("desktopS_960")]: {
                                fontSize: "20px",
                              },
                            }}
                          />

                          <p className={classes.contentListItemText}>{text}</p>
                        </li>
                      )
                    )}
                  </ul>
                </div>
              </BlockContent>
            </SwiperSlide>
          );
        })}
        <SliderNav
          sliderProgress={slideProgress}
          onClickNext={(next) => handleSendMetrics(next)}
          onClickPrev={(prev) => handleSendMetrics(prev)}
        />
      </Swiper>
    </div>
  );
};
