import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const Facebook = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_111296_312916)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.194233 16.9251C0.670174 10.5302 0.908144 7.33275 4.11752 4.12337C7.3269 0.913998 10.5243 0.676029 16.9192 0.20009C18.577 0.0767148 20.287 0.00195312 21.997 0.00195312C23.707 0.00195312 25.417 0.0767161 27.0747 0.200092C33.4696 0.676033 36.6671 0.914004 39.8765 4.12338C43.0858 7.33276 43.3238 10.5302 43.7997 16.9251C43.9231 18.5828 43.9979 20.2928 43.9979 22.0029C43.9979 23.7129 43.9231 25.4229 43.7997 27.0806C43.3238 33.4755 43.0858 36.6729 39.8765 39.8823C36.6671 43.0917 33.4696 43.3297 27.0747 43.8056C25.417 43.929 23.707 44.0037 21.997 44.0037C20.287 44.0037 18.577 43.929 16.9192 43.8056C10.5243 43.3297 7.32689 43.0917 4.11751 39.8823C0.908139 36.6729 0.670169 33.4755 0.194231 27.0806C0.0708554 25.4229 -0.00390625 23.7129 -0.00390625 22.0028C-0.00390625 20.2928 0.0708567 18.5828 0.194233 16.9251ZM25.7723 39.3061L25.7723 24.055L29.9823 24.055L30.5402 18.7994H25.7723L25.7795 16.169C25.7795 14.7982 25.9097 14.0637 27.8785 14.0637H30.5104V8.80756H26.2998C21.2423 8.80756 19.4622 11.3571 19.4622 15.6446V18.8H14.416V24.0556H19.4622L19.4622 39.3061L25.7723 39.3061Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_111296_312916">
          <rect width="44" height="44" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default Facebook;
