import { QueryKeys, HTTPStatusCode } from "constants/keys";

import { useQuery } from "react-query";

import { AxiosError } from "axios";
import { ICustomerAdminForBaseUsersDTO } from "interfaces";
import { getCustomerAdminDataForBaseUsers } from "services/api/index";

import { useNotifications } from "./use-notifications";
import { useProfileData } from "./use-profile-data";

export const useCustomerAdminDataForBaseUsers = () => {
  const { addErrorMessage } = useNotifications();
  const { isCustomerBaseRole, isLoadingAuthData } = useProfileData();

  return useQuery<ICustomerAdminForBaseUsersDTO, AxiosError>(
    QueryKeys.CUSTOMER_ADMIN_DATA_FOR_BASE_USERS,
    () => getCustomerAdminDataForBaseUsers(),
    {
      enabled: !isLoadingAuthData && isCustomerBaseRole,
      retry: 0,
      onError: (error: AxiosError) => {
        const status = error.response?.status;

        if (status !== HTTPStatusCode.UNAUTHORIZED_401) {
          addErrorMessage({ message: "Не удалось получить данные о компании" });
        }
      },
    }
  );
};
