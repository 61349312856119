import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export const DistortionIcon = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon
      width="96"
      height="96"
      viewBox="0 0 96 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M32.5 47L18.5 77"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M64.5 47L77.5 77"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M47.2234 23.3163H36.5C35.9477 23.3163 35.5 23.764 35.5 24.3163V32.2347C35.5 32.787 35.9477 33.2347 36.5 33.2347H47.2234C47.7757 33.2347 48.2234 33.6824 48.2234 34.2347V39.0925C48.2234 39.9824 49.2986 40.429 49.9291 39.801L60.8031 28.9697C61.1898 28.5845 61.1962 27.9603 60.8174 27.5672L49.9434 16.2847C49.319 15.6368 48.2234 16.0788 48.2234 16.9786V22.3163C48.2234 22.8686 47.7757 23.3163 47.2234 23.3163Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M48.5 77V47"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeDasharray="5 6"
      />
    </SvgIcon>
  );
};
