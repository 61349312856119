import React from "react";

import { Skeleton } from "@mui/material";
import usePagination from "@mui/material/usePagination";
import cn from "classnames";

import { MTSIcon } from "../mts-icon";

import classes from "./mts-pagination-manual.module.css";

type IMTSPaginationManualProps = {
  pageIndex: number;
  pageCount: number;
  canPreviousPage: boolean;
  canNextPage: boolean;
  gotoPage: (newPageIndex: number) => void;
  isLoading: boolean;
};

export const MTSPaginationManual = (
  props: IMTSPaginationManualProps
): JSX.Element | null => {
  const {
    pageIndex,
    pageCount,
    canPreviousPage,
    canNextPage,
    gotoPage,
    isLoading,
  } = props;
  const { items } = usePagination({
    page: pageIndex,
    count: pageCount,
  });

  const btnPageStyles = cn({
    [classes.btn]: true,
    [classes.btnPage]: true,
  });

  const btnPageSelected = cn({
    [classes.btn]: true,
    [classes.btnPageSelected]: true,
  });

  const btnNavStyles = cn({
    [classes.btn]: true,
    [classes.btnNav]: true,
  });

  const handleNextPage = (): void => {
    const pageNumber = pageIndex + 1;
    gotoPage(pageNumber + 1);
  };

  const handlePreviousPage = (): void => {
    const pageNumber = pageIndex + 1;
    gotoPage(pageNumber - 1);
  };

  if (isLoading) {
    return <Skeleton variant="rectangular" height={32} width={400} />;
  }

  return (
    <ul className={classes.blockMTSPagination}>
      {items.map(({ page: pageNumber, type, ...item }, index) => {
        let children: JSX.Element | null = null;

        if (type === "start-ellipsis" || type === "end-ellipsis") {
          children = (
            <div className={classes.btnBreadcrumbs}>
              <p className={classes.btnText}>...</p>
            </div>
          );
        } else if (type === "page" && typeof pageNumber === "number") {
          children = (
            <button
              className={
                pageIndex === pageNumber - 1 ? btnPageSelected : btnPageStyles
              }
              type="button"
              {...item}
              onClick={() => {
                gotoPage(pageNumber);
              }}
            >
              <p className={classes.btnText}>{pageNumber}</p>
            </button>
          );
        } else if (type === "next") {
          children = (
            <button
              onClick={() => handleNextPage()}
              disabled={!canNextPage}
              className={btnNavStyles}
              style={{ visibility: canNextPage ? "visible" : "hidden" }}
            >
              <MTSIcon.ChevronRight
                sx={{
                  width: 24,
                  height: 24,
                  color: "var(--color-icon-primary)",
                }}
              />
            </button>
          );
        } else if (type === "previous") {
          children = (
            <button
              onClick={() => handlePreviousPage()}
              disabled={!canPreviousPage}
              className={btnNavStyles}
              style={{ visibility: canPreviousPage ? "visible" : "hidden" }}
            >
              <MTSIcon.ChevronLeft
                sx={{
                  width: 24,
                  height: 24,
                  color: "var(--color-icon-primary)",
                }}
              />
            </button>
          );
        }

        return (
          <li className={classes.listItem} key={index}>
            {children}
          </li>
        );
      })}
    </ul>
  );
};
