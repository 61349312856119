import {
  PATH_SUBSCRIPTION_INFO_PERSONAL,
  PATH_INSTRUCTION_CARDS,
  PATH_COMPANY_INFO,
} from "constants/routes";

import React from "react";

import { IMTSTab } from "components/mts-tabs";
import { useTabNames } from "hooks/tabs/use-tab-names";
import { generateId } from "utils/generate-id";

import { useTabSelected } from "./use-tab-selected";

interface IUseTabsInSettingsPageProps {
  isCanDownloadRinex: boolean;
}

export const useTabsInSettingsPage = (props: IUseTabsInSettingsPageProps) => {
  const { isCanDownloadRinex } = props;

  const [tabs, setTabs] = React.useState<IMTSTab[]>([]);
  const { selectedTabId } = useTabSelected(tabs);
  const { tabNames } = useTabNames();

  React.useEffect(() => {
    const newTabs: IMTSTab[] = [];

    const tabCompanyInfo: IMTSTab = {
      id: generateId(),
      name: tabNames.companyInfo,
      type: "link",
      href: PATH_COMPANY_INFO,
      access: ["all"],
    };

    const tabPersonalSubscriptionInfo: IMTSTab = {
      id: generateId(),
      name: tabNames.subscriptionInfoPersonal,
      type: "link",
      href: PATH_SUBSCRIPTION_INFO_PERSONAL,
      access: ["all"],
    };

    const tabInstructionCards: IMTSTab = {
      id: generateId(),
      name: tabNames.instructions,
      type: "link",
      href: PATH_INSTRUCTION_CARDS,
      access: ["all"],
    };

    if (isCanDownloadRinex) {
      newTabs.push(
        ...[tabCompanyInfo, tabPersonalSubscriptionInfo, tabInstructionCards]
      );
    } else {
      newTabs.push(...[tabCompanyInfo, tabInstructionCards]);
    }

    setTabs(newTabs);
  }, [
    isCanDownloadRinex,
    tabNames.companyInfo,
    tabNames.instructions,
    tabNames.subscriptionInfoPersonal,
  ]);

  return { tabs, selectedTabId };
};
