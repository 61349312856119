import { PATH_RINEX_REQUESTS } from "constants/routes";

import React from "react";

import { AppHelmet } from "components/app-helmet";
import { Breadcrumbs } from "components/breadcrumbs";
import { ContainerContent } from "components/container-content";
import { FooterOfPersonalArea } from "components/footer-of-personal-area";
import {
  GAP_EVENT_TYPE,
  GA_SCREEN_NAME,
  useGoogleAnalyticsPages,
} from "hooks/google-analytics";
import { useTabNames } from "hooks/tabs/use-tab-names";

import { RinexRequestCreateForm } from "./components/rinex-request-create-form";

const PAGE_TITLE = "Запрос RINEX";

export const RinexRequestAddPage = () => {
  const { tabNames } = useTabNames();

  useGoogleAnalyticsPages({
    screenName: GA_SCREEN_NAME.rinex_request,
    event: GAP_EVENT_TYPE.SCRN,
  });

  const breadcrumbLinks = React.useMemo(
    () => [
      {
        name: tabNames.request,
        href: PATH_RINEX_REQUESTS,
      },
      {
        name: PAGE_TITLE,
        href: "",
      },
    ],
    [tabNames.request]
  );

  return (
    <>
      <AppHelmet title={`${PAGE_TITLE} - Личный кабинет`} />
      <ContainerContent>
        <Breadcrumbs links={breadcrumbLinks} />
        <RinexRequestCreateForm pageTitle={PAGE_TITLE} />
      </ContainerContent>
      <FooterOfPersonalArea />
    </>
  );
};
