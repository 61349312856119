import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const Beta = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.26013 11.5268V14H5V5.30165C5 4.26113 5.24947 3.45073 5.7484 2.87044C6.24733 2.29015 6.94243 2 7.83369 2C8.56716 2 9.15991 2.18009 9.61194 2.54027C10.064 2.90045 10.29 3.37469 10.29 3.96298C10.29 4.43522 10.145 4.87043 9.85501 5.26863C9.56503 5.66683 9.1322 6.02801 8.5565 6.35218C9.31983 6.58029 9.91791 6.93347 10.3507 7.41171C10.7836 7.88994 11 8.43722 11 9.05353C11 9.84993 10.7068 10.5033 10.1205 11.0135C9.53412 11.5238 8.78252 11.7789 7.86567 11.7789C7.31557 11.7789 6.78038 11.6948 6.26013 11.5268ZM6.26006 10.5003C6.86987 10.7604 7.40931 10.8905 7.8784 10.8905C8.39439 10.8905 8.83255 10.7154 9.19289 10.3652C9.55324 10.0151 9.73341 9.59384 9.73341 9.1016C9.73341 8.47728 9.48394 7.97703 8.98501 7.60084C8.48607 7.22466 7.81869 7.03656 6.98287 7.03656H6.73341V6.14812H6.96368C7.51379 6.14812 7.9946 5.93901 8.40611 5.5208C8.81763 5.1026 9.02339 4.61535 9.02339 4.05907C9.02339 3.67088 8.91677 3.37873 8.70356 3.18264C8.49034 2.98654 8.17264 2.88849 7.75046 2.88849C7.25153 2.88849 6.8784 3.07458 6.63106 3.44677C6.38373 3.81895 6.26006 4.37523 6.26006 5.1156V10.5003Z"
      />
    </SvgIcon>
  );
};

export default Beta;
