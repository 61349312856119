export enum MTSInputSize {
  S = "S", // 32
  M = "M", // 44
  L = "L", // 52
  XL = "XL", // 72
}
export enum InputType {
  TEXT = "text",
  PASSWORD = "password",
  TEL = "tel",
  NUMBER = "number",
}
