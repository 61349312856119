import { ErrorPageVariant } from "../error-page";

export const getHelmetTitle = (
  variants: typeof ErrorPageVariant[keyof typeof ErrorPageVariant]
): string => {
  let title: string = "";

  switch (variants) {
    case ErrorPageVariant.PAGE_NOT_FOUND: {
      title = "Страница не найдена";
      break;
    }
    case ErrorPageVariant.NO_ACCESS: {
      title = "Доступ ограничен";
      break;
    }
    case ErrorPageVariant.UNKNOWN_ERROR: {
      title = "Неизвестная ошибка";
      break;
    }
    default:
      break;
  }

  return title;
};
