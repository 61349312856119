import React from "react";

import { BlockWide } from "components/block-wide";

import classes from "./cases-slide.module.css";
import { Slider } from "./components/slider";

export const CasesSlide = React.memo((): JSX.Element => {
  return (
    <div className={classes.block}>
      <BlockWide>
        <Slider />
      </BlockWide>
    </div>
  );
});
