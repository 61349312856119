import { STAND_NAME } from "constants/env";
import { FRONTEND_URL, BACKEND_URL } from "constants/env";

import React from "react";

import * as packageJSON from "../../package.json";

export const useApplicationInfo = (): void => {
  const [appInfo] = React.useState({
    version: packageJSON.version || "-",
    standName: STAND_NAME,
    frontendURL: FRONTEND_URL,
    backendURL: BACKEND_URL,
  });

  React.useEffect(() => {
    console.log(`v${appInfo.version}`);
    console.log("ApplicationInfo:", appInfo);
  }, [appInfo]);
};
