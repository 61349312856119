import { QueryKeys } from "constants/keys";

import { useMutation, useQueryClient } from "react-query";

import { IFileRequestDTO } from "interfaces";
import { createRinexRequest } from "services/api";

import { useNotifications } from "./use-notifications";

export const useMutationCreateRinexRequest = () => {
  const { addErrorMessage, addSuccessMessage } = useNotifications();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (payload: IFileRequestDTO) => createRinexRequest(payload),
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeys.RINEX_FILE_REQUESTS]);
      addSuccessMessage({
        message: "Запрос создан",
      });
    },
    onError: () => {
      addErrorMessage({
        message: "Что-то пошло не так. Повторите попытку",
      });
    },
  });
};
