import { PATH_SUBSCRIPTIONS } from "constants/routes";

import React from "react";

import { useHistory, useLocation } from "react-router";

import { MTSButton } from "components/mts-button";
import { IPaymentConfiguratorLocationState } from "components/payment-configurator/payment-configurator";

export const CancelButton = React.memo((props: { isEditMode?: boolean }) => {
  const history = useHistory();
  const location = useLocation();
  const locationState = location.state as IPaymentConfiguratorLocationState;

  const handleCancel = React.useCallback((): void => {
    if (props.isEditMode) {
      if (locationState?.redirectBackTo) {
        history.push(locationState.redirectBackTo);
      } else {
        history.push(PATH_SUBSCRIPTIONS);
      }
    } else {
      history.push(PATH_SUBSCRIPTIONS);
    }
  }, [history, locationState?.redirectBackTo, props.isEditMode]);

  return (
    <MTSButton
      variant="secondary"
      size="M"
      sx={{ width: 136, height: 44 }}
      onClick={handleCancel}
    >
      Отмена
    </MTSButton>
  );
});
