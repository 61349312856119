import {
  PATH_PAYMENTS,
  SEARCH_PARAM_PAY_GROUPS_IN_PAYMENT_CONFIGURATOR,
} from "constants/routes";

import React from "react";

import { useLocation } from "react-router-dom";

import { AppHelmet } from "components/app-helmet";
import { Breadcrumbs } from "components/breadcrumbs";
import { ContainerContent } from "components/container-content";
import { FooterOfPersonalArea } from "components/footer-of-personal-area";
import { Loader } from "components/loader";
import { MTSLink } from "components/mts-link";
import { NotificationMessage } from "components/notification-message";
import { PageTitle } from "components/page-title";
import {
  IPayGroupFormStruct,
  PaymentConfigurator,
} from "components/payment-configurator";
import { InitialFormState } from "components/payment-configurator/constants";
import {
  GAP_EVENT_TYPE,
  GA_SCREEN_NAME,
  useGoogleAnalyticsPages,
} from "hooks/google-analytics";
import { useCheckAbilityToCreatePayments } from "hooks/use-check-ability-to-create-payments";
import { useDurations } from "hooks/use-durations";
import { useServiceKinds } from "hooks/use-service-kinds";
import { IDictionaryDTO, IPayGroupDTO } from "interfaces";
import { mockData } from "services/mock-data";
import { getParsedFormDataPayGroups } from "utils/get-parsed-form-data-pay-groups";

import classes from "./adding-subscriptions.module.css";

const PAGE_TITLE = "Добавление подписки";

interface ILocationStateProps {
  from: {
    pageName: string;
    pathname: string;
  };
}

export const AddingSubscriptions = () => {
  const location = useLocation<ILocationStateProps>();
  const locationSearchParams = new URLSearchParams(location.search);
  const initialDataJSON: string | null = locationSearchParams.get(
    SEARCH_PARAM_PAY_GROUPS_IN_PAYMENT_CONFIGURATOR
  );
  const initialData = initialDataJSON
    ? (JSON.parse(atob(initialDataJSON)) as IPayGroupDTO[])
    : [];

  useGoogleAnalyticsPages({
    screenName: GA_SCREEN_NAME.add_subs,
    event: GAP_EVENT_TYPE.SCRN,
  });

  const { data: durations, isLoading: isLoadingDurations } = useDurations();
  const { data: serviceKinds, isLoading: isLoadingServiceKinds } =
    useServiceKinds();
  const {
    data: abilityToCreatePayments,
    isLoading: isLoadingAbilityToCreatePayments,
  } = useCheckAbilityToCreatePayments({});
  const regions: IDictionaryDTO[] = mockData.regions;

  const [isShowNotification, setIsShowNotification] =
    React.useState<boolean>(false);
  const [initialFormData, setInitialFormData] = React.useState<
    IPayGroupFormStruct[]
  >([]);

  const breadcrumbLinks = React.useMemo(() => {
    const links = [];

    if (location.state?.from?.pageName && location.state?.from?.pathname) {
      links.push({
        name: location.state.from.pageName,
        href: location.state.from.pathname,
      });
    }

    links.push({ name: PAGE_TITLE, href: "" });

    return links;
  }, [location.state?.from?.pageName, location.state?.from?.pathname]);

  React.useEffect(() => {
    if (!isLoadingDurations && !isLoadingServiceKinds) {
      let payGroupsFormData: IPayGroupFormStruct[] = [];

      if (initialData?.length && durations && serviceKinds) {
        payGroupsFormData = getParsedFormDataPayGroups({
          payGroups: initialData,
          serviceKinds,
          durations,
          regions,
        });
      } else {
        payGroupsFormData = [new InitialFormState()];
      }

      setInitialFormData(payGroupsFormData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingDurations, isLoadingServiceKinds, durations, serviceKinds]);

  React.useEffect(() => {
    if (!isLoadingAbilityToCreatePayments && abilityToCreatePayments) {
      setIsShowNotification(!abilityToCreatePayments.canCreatePayment);
    }
  }, [abilityToCreatePayments, isLoadingAbilityToCreatePayments]);

  return (
    <>
      <AppHelmet title={`${PAGE_TITLE} - Личный кабинет`} />
      <ContainerContent>
        <Breadcrumbs
          links={breadcrumbLinks}
          dataTestId="breadcrumbs_add_subs"
        />

        <PageTitle
          text={PAGE_TITLE}
          sx={{ marginTop: "16px" }}
          dataTestId="txt_head_add_subs"
        />

        <NotificationMessage
          show={isShowNotification}
          text={
            <>
              У вас есть&nbsp;
              <MTSLink to={PATH_PAYMENTS}>неоплаченный счёт.</MTSLink>
              &nbsp;Оплатите или удалите его, чтобы создать новый платёж.
            </>
          }
          sx={{ marginTop: "24px" }}
        />

        {Boolean(initialFormData?.length) ? (
          <PaymentConfigurator
            initialFormData={initialFormData}
            disableToCreatePayment={
              abilityToCreatePayments?.canCreatePayment === true ? false : true
            }
          />
        ) : (
          <div className={classes.loader}>
            <Loader />
          </div>
        )}
      </ContainerContent>
      <FooterOfPersonalArea />
    </>
  );
};
