import { STAND_NAME } from "constants/env";

import React from "react";

import TagManager from "react-gtm-module";

import { useProfileData } from "hooks/use-profile-data";

import { useScreenName } from "./use-screen-name";

export enum GAE_EVENT {
  vntMain = "vntMain",
  vntLogin = "vntLogin",
  vntTarify = "vntTarify",
  vntPodpiski = "vntPodpiski",
  vntCross = "vntCross",
  vntMenu = "vntMenu",
  vntZeml = "vntZeml",
  vntGeod = "vntGeod",
}

export enum GAE_EVENT_CATEGORY {
  glavnaya = "glavnaya",
  avtorizaciya = "avtorizaciya",
  registraciya = "registraciya",
  tarify = "tarify",
  podpiski = "podpiski",
  menu = "menu",
  kross_elementy = "kross_elementy",
  tochnoe_zemledelie = "tochnoe_zemledelie",
  geodeziya_i_kadastr = "geodeziya_i_kadastr",
}

export enum GAE_EVENT_ACTION {
  button_click = "button_click",
  confirmed = "confirmed",
  element_click = "element_click",
  menu_click = "menu_click",
  block_show = "block_show",
  link_click = "link_click",
  element_hover = "element_hover",
  card_click = "card_click",
  card_hover = "card_hover",
  tab_click = "tab_click",
  popup_show = "popup_show",
}

export enum GAE_EVENT_LABEL {
  stat_klientom = "stat_klientom",
  voiti = "voiti",
  podkluchit_testovyi_dostup = "podkluchit_testovyi_dostup",
  podkluchit_rtk_rinex = "podkluchit_rtk_rinex",
  podkluchit_rinex = "podkluchit_rinex",
  zaregistrirovatsya = "zaregistrirovatsya",
  uspeshnaya_registraciya = "uspeshnaya_registraciya",
  uspeshnaya_avtorizaciya = "uspeshnaya_avtorizaciya",
  podkluchit = "podkluchit",
  otmena = "otmena",
  podkluchit_i_aktivirovat = "podkluchit_i_aktivirovat",
  dobavit_podpiski = "dobavit_podpiski",
  dobavit = "dobavit",
  dobavlenie_podpiski = "dobavlenie_podpiski",
  karta_pokrytiya = "karta_pokrytiya",
  pereiti_v_lichnyi_kabinet = "pereiti_v_lichnyi_kabinet",
  phone = "phone",
  email = "email",
  pokrytie = "pokrytie",
  podpiski = "podpiski",
  tarify = "tarify",
  platezhi = "platezhi",
  nastroiki = "nastroiki",
  logo = "logo",
  uvedomleniya = "uvedomleniya",
  chto_takoe_locationpro = "chto_takoe_locationpro",
  gde_primenyaut_reshenie = "gde_primenyaut_reshenie",
  preimuschestva = "preimuschestva",
  voprosy_i_otvety = "voprosy_i_otvety",
  stante_partnerom = "stante_partnerom",
  ostalis_voprosy = "ostalis_voprosy",
  kontakty = "kontakty",
  otraslevye_resheniya = "otraslevye_resheniya",
  tochnoe_zemledelie = "tochnoe_zemledelie",
  zakazat_demonstraciu = "zakazat_demonstraciu",
  vpered = "vpered",
  nazad = "nazad",
  smotret_kartu_pokrytiya = "smotret_kartu_pokrytiya",
  ostavit_zayavku = "ostavit_zayavku",
  obratnyi_zvonok = "obratnyi_zvonok",
  kakie_problemy_vy_reshite = "kakie_problemy_vy_reshite",
  upper_slider = "upper_slider",
  chto_vhodit_v_reshenie = "chto_vhodit_v_reshenie",
  down_slider = "down_slider",
  keisy = "keisy",
  effekt_ot_vnedreniya = "effekt_ot_vnedreniya",
  svyazhites_s_nami = "svyazhites_s_nami",
  kontrol_polevyh_sotrudnikov_i_sezonnogo_personala = "kontrol_polevyh_sotrudnikov_i_sezonnogo_personala",
  kontrol_avtoparka_i_naemnogo_transporta = "kontrol_avtoparka_i_naemnogo_transporta",
  kontrol_gsm = "kontrol_gsm",
  analiz_manery_vozhdeniya = "analiz_manery_vozhdeniya",
  udalennyi_monitoring_obektov_infrastruktury = "udalennyi_monitoring_obektov_infrastruktury",
  kontrol_temperatury_i_vlazhnosti = "kontrol_temperatury_i_vlazhnosti",
  kontrol_zapolneniya_rezervuarov_bunkerov_silosov = "kontrol_zapolneniya_rezervuarov_bunkerov_silosov",
  tehnicheskii_uchet_potrebleniya_vody_elektroenergii_gaza = "tehnicheskii_uchet_potrebleniya_vody_elektroenergii_gaza",
  selskohozyaistvennyi_kompleks = "selskohozyaistvennyi_kompleks",
  selskohozyaistvennaya_kompaniya = "selskohozyaistvennaya_kompaniya",
  selskohozyaistvennyi_holding = "selskohozyaistvennyi_holding",
  geodeziya_i_kadastr = "geodeziya_i_kadastr",
  stroitelnaya_kompaniya = "stroitelnaya_kompaniya",
  promyshlennoe_predpriyatie = "promyshlennoe_predpriyatie",
  avtodorozhnoe_predpriyatie = "avtodorozhnoe_predpriyatie",
  lesnoe_hozyaistvo = "lesnoe_hozyaistvo",
  stat_partnerom = "stat_partnerom",
  dannye_otpravleny = "dannye_otpravleny",
}

export enum GAE_EVENT_CONTENT {
  up = "up",
  down = "down",
}

export enum GAE_BUTTON_LOCATION {
  screen = "screen",
  popup = "popup",
  // null
}

export enum GAE_ACTION_GROUP {
  interactions = "interactions",
  conversions = "conversions",
  non_interactions = "non_interactions",
}

export enum GAE_PRODUCT_NAME {
  tarif_testovyi_dostup = "tarif_testovyi_dostup",
  tarif_rtk_rinex = "tarif_rtk_rinex",
  tarif_rinex = "tarif_rinex",
}

interface ISendGAEPayload {
  id?: number | string;
  event: GAE_EVENT;
  eventCategory: GAE_EVENT_CATEGORY;
  eventAction: GAE_EVENT_ACTION;
  eventLabel: GAE_EVENT_LABEL;
  eventValue: null | string;
  screenName?: string;
  eventContent: string | null;
  buttonLocation: GAE_BUTTON_LOCATION | null;
  filterName: string | null;
  actionGroup: GAE_ACTION_GROUP;
  productName: GAE_PRODUCT_NAME | string | null;
  formOrderId?: string;
}

export const useGoogleAnalyticsEvents = () => {
  const { profile, isAuthorized } = useProfileData();
  const currentScreenName: string = useScreenName();

  const sendGAE = React.useCallback(
    (payload: ISendGAEPayload) => {
      const {
        id,
        event,
        eventCategory,
        eventAction,
        eventLabel,
        eventValue,
        screenName,
        eventContent,
        buttonLocation,
        filterName,
        actionGroup,
        productName,
        formOrderId = null,
      } = payload;
      const userId: string | null = profile?.guid ?? null;
      const userAuth: string = isAuthorized ? "1" : "0";

      const tagManagerArgs = {
        dataLayer: {
          event,
          eventCategory,
          eventAction,
          eventLabel,
          eventValue,
          userId,
          userAuth,
          screenName: screenName ? screenName : currentScreenName,
          eventContent,
          buttonLocation,
          filterName,
          actionGroup,
          touchPoint: "web",
          productName,
          currentTariff: null,
          formOrderId,
        },
      };

      switch (STAND_NAME) {
        case "PROD":
          TagManager.dataLayer(tagManagerArgs);
          break;
        case "PRODLIKE":
          console.log(`[${id}]-GAEvent`, tagManagerArgs.dataLayer);
          break;
        case "TEST":
        case "DEV":
          break;
        default:
          break;
      }
    },
    [currentScreenName, isAuthorized, profile?.guid]
  );

  return { sendGAE };
};
