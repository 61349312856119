import React from "react";

import { MTSIcon } from "components/mts-icon";
import { MTSSpinner } from "components/mts-spinner";
import { PaymentStatusCheckerByCode } from "utils/payment-status-checker-by-code";

interface IPaymentCardIconProps {
  status: string;
}

export const PaymentCardIcon = React.memo(
  ({ status }: IPaymentCardIconProps): JSX.Element | null => {
    if (PaymentStatusCheckerByCode.isDraft(status)) {
      return (
        <MTSIcon.Error
          sx={{
            fontSize: 24,
            color: "var(--color-icon-tertiary)",
          }}
        />
      );
    }

    if (
      PaymentStatusCheckerByCode.isDone(status) ||
      PaymentStatusCheckerByCode.isTest(status)
    ) {
      return (
        <MTSIcon.Success
          sx={{
            fontSize: 24,
            color: "var(--color-accent-positive)",
          }}
        />
      );
    }

    if (PaymentStatusCheckerByCode.isFailed(status)) {
      return (
        <MTSIcon.Error
          sx={{
            fontSize: 24,
            color: "var(--color-accent-negative)",
          }}
        />
      );
    }

    if (PaymentStatusCheckerByCode.isToQueue(status)) {
      return <MTSSpinner size="M" />;
    }

    if (PaymentStatusCheckerByCode.isInProcess(status)) {
      return (
        <MTSIcon.PromisedPayment
          sx={{ fontSize: 24, color: "var(--color-accent-active)" }}
        />
      );
    }

    return null;
  }
);
