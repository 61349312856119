import { SUBSCRIPTION_STATUSES } from "constants/keys";

import { IDictionaryDTO } from "interfaces";

import { getDateFormatted, IDateFormatted } from "../get-date-formatted";

export const getSubscriptionStatusName = (
  statusCode: string,
  dictionary: IDictionaryDTO[] | undefined,
  createdDate: string,
  expiredDate: string
): string => {
  let statusName = "";

  if (dictionary?.length) {
    const statusData: IDictionaryDTO | undefined = dictionary.find(
      (item) => item.code === statusCode
    );

    if (statusData?.name) {
      statusName = statusData.name;

      if (statusData?.code === SUBSCRIPTION_STATUSES.PAID) {
        const createdDateString: IDateFormatted = getDateFormatted(createdDate);

        statusName += ` ${
          createdDateString.date ? createdDateString.date : "-"
        }`;
      } else if (statusData?.code === SUBSCRIPTION_STATUSES.ACTIVATED) {
        const expiredDateString: IDateFormatted = getDateFormatted(expiredDate);

        statusName += ` до ${
          expiredDateString.date ? expiredDateString.date : "-"
        }`;
      }
    }
  }

  return statusName;
};
