import React from "react";

import { MTSBadge } from "components/mts-badge";
import { MTSIcon } from "components/mts-icon";

import classes from "./user-info.module.css";

type IUserInfoProps = {
  userRole: string;
  size: "M" | "L";
};

export const UserInfo = (props: IUserInfoProps): JSX.Element | null => {
  const { userRole, size } = props;

  if (userRole) {
    if (size === "M") {
      return (
        <MTSBadge
          size="M"
          variant="CUSTOM_COLOR"
          colorBackground="var(--color-background-secondary-elevated)"
        >
          <MTSIcon.Profile
            sx={{ color: "var(--color-icon-primary)", fontSize: "16px" }}
          />
          <span className={classes.text}>{userRole}</span>
        </MTSBadge>
      );
    }

    if (size === "L") {
      return (
        <MTSBadge
          size="L"
          variant="CUSTOM_COLOR"
          colorBackground="var(--color-background-secondary-elevated)"
        >
          <MTSIcon.Profile
            sx={{ color: "var(--color-icon-primary)", fontSize: "24px" }}
          />
          <span className={classes.text}>{userRole}</span>
        </MTSBadge>
      );
    }

    return null;
  }

  return null;
};
