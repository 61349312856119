import { SUBSCRIPTION_DURATIONS } from "constants/keys";

export const getDurationTranslit = (durationCode: string): string => {
  let durationTranslit = "";

  if (durationCode === SUBSCRIPTION_DURATIONS.DAY) {
    durationTranslit = "1_den";
  } else if (durationCode === SUBSCRIPTION_DURATIONS.MONTH) {
    durationTranslit = "1_mesyats";
  } else if (durationCode === SUBSCRIPTION_DURATIONS.QUARTER) {
    durationTranslit = "3_mesyatsa";
  } else if (durationCode === SUBSCRIPTION_DURATIONS.HALF_YEAR) {
    durationTranslit = "6_mesyatsev";
  } else if (durationCode === SUBSCRIPTION_DURATIONS.YEAR) {
    durationTranslit = "1_god";
  }

  return durationTranslit;
};
