import { REQUIRED_FIELD_MESSAGE } from "constants/keys";

import * as yup from "yup";

const MAC_SYMBOLS_COUNT = 56;
const MAX_SYMBOLS_MESSAGE = "Не более 56 символов";

export const fieldCompanyNameRequired = yup
  .string()
  .required(REQUIRED_FIELD_MESSAGE)
  .trim()
  .max(MAC_SYMBOLS_COUNT, MAX_SYMBOLS_MESSAGE);

export const fieldCompanyName = yup
  .string()
  .trim()
  .max(MAC_SYMBOLS_COUNT, MAX_SYMBOLS_MESSAGE);
