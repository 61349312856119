import { QueryKeys, TEST_SUBSCRIPTION_STATUS } from "constants/keys";

import { useQuery, useQueryClient } from "react-query";

import { AxiosError } from "axios";
import { useNotifications } from "hooks/use-notifications";
import { getTestSubscription } from "services/api";

import { useContextMain } from "./use-context-main";

interface IUseTestSubscriptionProps {
  onSuccessRequest: () => void;
}

export const useTestSubscription = ({
  onSuccessRequest,
}: IUseTestSubscriptionProps) => {
  const { addErrorMessage } = useNotifications();
  const { setTestSubscriptionStatus } = useContextMain();
  const queryClient = useQueryClient();

  return useQuery(QueryKeys.TEST_SUBSCRIPTION, () => getTestSubscription(), {
    enabled: false,
    retry: 0,
    onSuccess: async () => {
      await queryClient.invalidateQueries(QueryKeys.TEST_SUBSCRIPTION_USED);
      await queryClient.invalidateQueries(QueryKeys.USER_ACCESS_DOWNLOAD_RINEX);
      setTestSubscriptionStatus(TEST_SUBSCRIPTION_STATUS.RTK_RINEX_USING);
      onSuccessRequest();
    },
    onError: (error: AxiosError) => {
      addErrorMessage({ message: "Не удалось подключить тестовый доступ" });
    },
  });
};
