import { PHONE_MASK } from "constants/keys";

//
// NOTE: Функция принимает стринговый phoneNumber формата -> "9998887766"
//
export const getPhoneNumbeWithMask = (
  phoneNumber: string | undefined
): string => {
  let phoneNumberWithMask = "";

  if (phoneNumber?.length === 10) {
    let count = 0;
    phoneNumberWithMask = PHONE_MASK.split("")
      .map((symbol) => {
        if (symbol === "9") {
          const changedSymbol = phoneNumber[count];
          count++;

          return changedSymbol;
        }

        return symbol;
      })
      .join("");
  }

  return phoneNumberWithMask;
};
