import { QueryKeys } from "constants/keys";
import { PATH_PAYMENTS } from "constants/routes";

import { useHistory } from "react-router-dom";

import { useMutation, useQueryClient } from "react-query";

import { IPaymentDTO } from "interfaces";
import { doProlongationForSubscriptions } from "services/api";

import { useNotifications } from "./use-notifications";

export const useMutationProlongationForSubscriptions = () => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const { addErrorMessage } = useNotifications();

  return useMutation(
    (payload: number[]) => doProlongationForSubscriptions(payload),
    {
      onSuccess: async (data: IPaymentDTO) => {
        const { id } = data;

        await queryClient.invalidateQueries(QueryKeys.BILLING_SUBSCRIPTIONS);
        await queryClient.invalidateQueries(QueryKeys.PROFILE_SUBSCRIPTIONS);

        history.push(`${PATH_PAYMENTS}/${id}`);
      },
      onError: () => {
        addErrorMessage({ message: "Не удалось продлить подписку/подписки" });
      },
    }
  );
};
