import { FIELD_NAMES } from "constants/keys";

import React from "react";

import { Column } from "react-table";

import { ISubscriptionStruct } from "interfaces";

export const useTableSubscriptionsColumns = () => {
  const [widthOfColumns] = React.useState({
    checkbox: 40,
    sticky: 60,
  });

  const tableColumns = React.useMemo<Column<ISubscriptionStruct>[]>(
    () => [
      {
        Header: "ID",
        accessor: FIELD_NAMES.SUBSCRIPTION_ID,
        disableGlobalFilter: true,
      },
      {
        Header: "Создан",
        accessor: FIELD_NAMES.SUBSCRIPTION_CREATED_DATE,
        disableGlobalFilter: true,
      },
      {
        Header: "Название",
        accessor: FIELD_NAMES.SUBSCRIPTION_NAME,
        width: 184,
      },
      {
        Header: "Тип доступа",
        accessor: FIELD_NAMES.SUBSCRIPTION_SERVICE_KIND,
        width: 144,
        disableGlobalFilter: true,
      },
      {
        Header: "Срок действия",
        accessor: FIELD_NAMES.SUBSCRIPTION_DURATION,
        width: 144,
        disableGlobalFilter: true,
      },
      {
        Header: "Статус",
        accessor: FIELD_NAMES.SUBSCRIPTION_STATUS,
        width: 184,
        disableGlobalFilter: true,
      },
      {
        Header: "Срок действия до",
        accessor: FIELD_NAMES.SUBSCRIPTION_EXPIRED_DATE,
        width: 184,
        disableGlobalFilter: true,
      },
      {
        Header: "Телефон",
        accessor: FIELD_NAMES.SUBSCRIPTION_PHONE,
        width: 184,
      },
      {
        Header: "E-mail",
        accessor: FIELD_NAMES.SUBSCRIPTION_EMAIL,
        width: 184,
      },
      {
        Header: "Комментарий",
        accessor: FIELD_NAMES.SUBSCRIPTION_DESCRIPTION,
        disableGlobalFilter: true,
      },
      {
        Header: "Заполнена",
        accessor: FIELD_NAMES.SUBSCRIPTION_EDIT_STATUS,
        disableGlobalFilter: true,
      },
    ],
    []
  );

  const hiddenColumns = React.useMemo(
    () => [
      FIELD_NAMES.SUBSCRIPTION_EXPIRED_DATE,
      FIELD_NAMES.SUBSCRIPTION_CREATED_DATE,
      FIELD_NAMES.SUBSCRIPTION_ID,
      FIELD_NAMES.SUBSCRIPTION_EDIT_STATUS,
      FIELD_NAMES.SUBSCRIPTION_DESCRIPTION,
    ],
    []
  );

  return {
    tableColumns,
    widthOfColumns,
    hiddenColumns,
  };
};
