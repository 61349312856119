import React from "react";

import { useDurations } from "hooks/use-durations";
import { useServiceKinds } from "hooks/use-service-kinds";
import {
  ISubscriptionStruct,
  ISubscriptionResponseDTO,
  ISubscriptionsByStatuses,
} from "interfaces";
import { getMappedSubscriptionStructData } from "utils/get-mapped-subscription-struct-data";
import { getSubscriptionsByStatuses } from "utils/get-subscriptions-by-statuses";

import { useBillingSubscriptionsByPayment } from "./use-billing-subscriptions-by-payment";
import { useProfileSubscriptionsPayment } from "./use-profile-subscriptions-by-payment";

export const usePaymentSubscriptionsByStatuses = (paymentId: string) => {
  const [subscriptionsByStatuses, setSubscriptionsByStatuses] =
    React.useState<ISubscriptionsByStatuses>({
      all: [],
      activated: [],
      paid: [],
      renew: [],
      expired: [],
      canRenew: [],
    });

  const {
    data: billingSubscriptionsByPayment,
    isLoading: isLoadingBillingSubscriptionsByPayment,
    isError: isErrorBillingSubscriptionByPayment,
  } = useBillingSubscriptionsByPayment(paymentId);

  const subIds: number[] = Array.isArray(billingSubscriptionsByPayment)
    ? billingSubscriptionsByPayment.map((sub) => sub.id)
    : [];

  const {
    data: profileSubscriptionsByPayment,
    isLoading: isLoadingProfileSubscriptionsByPayment,
    isError: isErrorProfileSubscriptionByPayment,
  } = useProfileSubscriptionsPayment(subIds);

  const { data: durations, isLoading: isLoadingDurations } = useDurations();
  const { data: serviceKinds, isLoading: isLoadingServiceKinds } =
    useServiceKinds();
  const [isDataReady, setIsDataReady] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (
      paymentId &&
      !isLoadingProfileSubscriptionsByPayment &&
      !isLoadingBillingSubscriptionsByPayment &&
      !isLoadingServiceKinds &&
      !isLoadingDurations
    ) {
      setIsDataReady(false);
      const _subscriptions: ISubscriptionStruct[] = [];

      if (
        profileSubscriptionsByPayment?.length &&
        billingSubscriptionsByPayment?.length &&
        durations &&
        serviceKinds
      ) {
        for (let billingSubscription of billingSubscriptionsByPayment) {
          const subscriptionId = billingSubscription.id;

          const profileSubscription: ISubscriptionResponseDTO | undefined =
            profileSubscriptionsByPayment.find(
              (_s: ISubscriptionResponseDTO) => _s.id === subscriptionId
            );

          if (profileSubscription) {
            const subscriptionStructData = getMappedSubscriptionStructData({
              billingSubscription,
              profileSubscription,
              durations,
              serviceKinds,
            });

            _subscriptions.push(subscriptionStructData);
          }
        }
      }

      const _subscriptionsByStatuses: ISubscriptionsByStatuses =
        getSubscriptionsByStatuses({
          subscriptions: _subscriptions,
          durations,
        });

      setSubscriptionsByStatuses(_subscriptionsByStatuses);

      setIsDataReady(true);
    }
  }, [
    durations,
    serviceKinds,
    isLoadingDurations,
    isLoadingServiceKinds,
    billingSubscriptionsByPayment,
    isLoadingBillingSubscriptionsByPayment,
    profileSubscriptionsByPayment,
    isLoadingProfileSubscriptionsByPayment,
    paymentId,
  ]);

  return {
    subscriptionsByStatuses,
    isDataReady,
    isError:
      isErrorBillingSubscriptionByPayment ||
      isErrorProfileSubscriptionByPayment,
  };
};
