type ITabKey =
  | "main"
  | "request"
  | "map"
  | "industry"
  | "agro"
  | "geodesyCadastrе"
  | "tariff"
  | "subscriptions"
  | "payments"
  | "settings"
  | "companyInfo"
  | "subscriptionInfoPersonal"
  | "instructions"
  | "support";

export type ITabNames = {
  [TAB_KEY in ITabKey]: string;
};

export const _tabNames: ITabNames = {
  main: "LOCATIONPRO",
  request: "Мои запросы",
  map: "Покрытие",
  industry: "Отраслевые решения",
  agro: "Точное земледелие",
  geodesyCadastrе: "Геодезия и кадастр",
  tariff: "Тарифы",
  subscriptions: "Подписки",
  payments: "Платежи",
  settings: "Настройки",
  companyInfo: "Компания",
  subscriptionInfoPersonal: "Данные о подписке",
  instructions: "Инструкции",
  support: "Поддержка",
};
