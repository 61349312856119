import { IS_SERVER_MODE_MOCKED } from "constants/server";
import {
  PROFILE_SERVICE_URL,
  BILLING_SERVICE_URL,
  IDENTITY_SERVICE_URL,
  CDC_DATA_SERVICE_URL,
} from "constants/urls";

import axios, { AxiosResponse } from "axios";
import {
  IDictionaryDTO,
  IUpdateSubscriptionInfoPayload,
  IPaymentDTO,
  ISubscriptionDTO,
  ISubscriptionResponseDTO,
  ICalculationDTO,
  IProfileStruct,
  ITestSubscriptionStatusResponse,
  IPayGroupDTOPayload,
  ISubscriptionResponseCommonDTO,
  IBaseStationEchoDTO,
  IUpdateDevicePasswordPayload,
  ICustomerAdminCreateDTOPayload,
  ICheckAbilityToCreatePaymentsPayload,
  ICheckPaymentOrderStatusPayload,
  IOrderStatusDTO,
  IDiscountDTO,
  IUpdateSubscriptionInfoByBillingServicePayload,
  IBaseStationEchoCommonDTO,
  ICustomerAdminDTO,
  ICustomerAdminUpdateDTOPayload,
  ICustomerAdminForBaseUsersDTO,
  IPlannedBaseStationEchoDTO,
  ISubscriptionResponseForUpdateDTO,
  IGetPaymentPayload,
  IPaymentPaginationDTO,
  IValidateWEBSSOAuthResponse,
  IFeedbackDTO,
} from "interfaces";
import { $api } from "services/http";
import { mockData } from "services/mock-data";

export * from "./rinex-service";

export const getProfileSubscriptionInfo = async (
  id: string
): Promise<ISubscriptionResponseDTO | undefined> => {
  if (IS_SERVER_MODE_MOCKED) {
    return Promise.resolve(undefined);
  } else {
    const url = `${PROFILE_SERVICE_URL}/customer-admins/subscriptions/${id}`;

    return await $api.get(url).then((response) => response.data);
  }
};

export const getBillingSubscriptionInfo = async (
  id: string
): Promise<ISubscriptionDTO | undefined> => {
  if (IS_SERVER_MODE_MOCKED) {
    return Promise.resolve(undefined);
  } else {
    const url = `${BILLING_SERVICE_URL}/subscriptions/${id}`;

    return await $api.get(url).then((response) => response.data);
  }
};

interface IGetProfileSubscriptionsProps {
  isUseAxios?: boolean;
}

export const getProfileSubscriptions = async (
  props: IGetProfileSubscriptionsProps
): Promise<ISubscriptionResponseDTO[]> => {
  const url = `${PROFILE_SERVICE_URL}/customer-admins/subscriptions`;

  if (props?.isUseAxios) {
    return await axios
      .get(url, { withCredentials: true })
      .then((response) => response.data);
  } else {
    return await $api.get(url).then((response) => response.data);
  }
};

export const getProfileSubscriptionsByPayment = async (
  ids: number[]
): Promise<ISubscriptionResponseDTO[]> => {
  if (IS_SERVER_MODE_MOCKED) {
    return Promise.resolve([]);
  } else {
    const url = `${PROFILE_SERVICE_URL}/customer-admins/subscriptions`;

    return await $api.post(url, ids).then((response) => response.data);
  }
};

export const getBillingSubscriptions = async (): Promise<
  ISubscriptionDTO[]
> => {
  const url = `${BILLING_SERVICE_URL}/subscriptions`;

  return await $api.get(url).then((response) => response.data);
};

export const getBillingSubscriptionsByPayment = async (
  id: string
): Promise<ISubscriptionDTO[]> => {
  if (IS_SERVER_MODE_MOCKED) {
    return Promise.resolve([]);
  } else {
    const url = `${BILLING_SERVICE_URL}/payments/${id}/subscriptions`;

    return await $api.get(url).then((response) => response.data);
  }
};

export const createPayment = async (
  payload: IPayGroupDTOPayload[]
): Promise<IPaymentDTO> => {
  const url = `${BILLING_SERVICE_URL}/payments`;

  return await $api.post(url, payload).then((response) => response.data);
};

export const updatePayment = async (
  payload: IPayGroupDTOPayload[],
  id: string | undefined
): Promise<IPaymentDTO> => {
  const url = `${BILLING_SERVICE_URL}/payments/${id}`;

  return await $api.put(url, payload).then((response) => response.data);
};

export const checkPaymentOrderStatus = async (
  payload: ICheckPaymentOrderStatusPayload
): Promise<IOrderStatusDTO> => {
  const url = `${BILLING_SERVICE_URL}/payments/order-status/${payload.paymentId}`;

  return await $api.get(url).then((response) => response.data);
};

export const getPaymentCalculation = async (
  payload: IPayGroupDTOPayload[]
): Promise<ICalculationDTO> => {
  const url = `${BILLING_SERVICE_URL}/payments/payment-calculation`;

  return await $api.post(url, payload).then((response) => response.data);
};

export const updateSubscriptionInfoByProfileService = async (
  payload: IUpdateSubscriptionInfoPayload
): Promise<ISubscriptionResponseForUpdateDTO> => {
  const { id, ...restPayload } = payload;
  const url = `${PROFILE_SERVICE_URL}/customer-admins/subscriptions/${id}`;

  return await $api.put(url, restPayload).then((response) => response.data);
};

export const updateSubscriptionInfoByBillingService = async (
  payload: IUpdateSubscriptionInfoByBillingServicePayload
): Promise<ISubscriptionDTO> => {
  const { id, ...restPayload } = payload;
  const url = `${BILLING_SERVICE_URL}/subscriptions/${id}`;

  return await $api.put(url, restPayload).then((response) => response.data);
};

export const getPayments = async (
  payload: IGetPaymentPayload
): Promise<IPaymentPaginationDTO> => {
  const url = `${BILLING_SERVICE_URL}/payments`;
  const { sort, ...restPayload } = payload;

  return await $api
    .get(url, {
      params: { ...restPayload, sort: sort?.join("|") },
    })
    .then((response) => response.data);
};

export const getPaymentData = async (id: string): Promise<IPaymentDTO> => {
  const url = `${BILLING_SERVICE_URL}/payments/${id}`;

  return await $api.get(url).then((response) => response.data);
};

export const deletePayment = async (id: string): Promise<any> => {
  const url = `${BILLING_SERVICE_URL}/payments/${id}`;

  return await $api.delete(url).then((response) => response);
};

export const deleteSubscription = async (id: string): Promise<any> => {
  const url = `${BILLING_SERVICE_URL}/subscriptions/${id}`;

  return await $api.delete(url).then((response) => response);
};

export const updateDoPaymentInProcess = async (
  id: string
): Promise<IPaymentDTO> => {
  const url = `${BILLING_SERVICE_URL}/payments/${id}/in-process`;

  return await $api.put(url).then((response) => response.data);
};

export const doPaymentActivation = async (
  id: string
): Promise<ISubscriptionDTO> => {
  const url = `${BILLING_SERVICE_URL}/subscriptions/${id}/activated`;

  return await $api.put(url).then((response) => response.data);
};

export const getServiceKinds = async (): Promise<IDictionaryDTO[]> => {
  if (IS_SERVER_MODE_MOCKED) {
    return Promise.resolve(mockData.serviceKinds);
  } else {
    const url = `${BILLING_SERVICE_URL}/dictionaries/service-kinds`;

    return await $api.get(url).then((response) => response.data);
  }
};

export const getDurations = async (): Promise<IDictionaryDTO[]> => {
  if (IS_SERVER_MODE_MOCKED) {
    return Promise.resolve(mockData.durations);
  } else {
    const url = `${BILLING_SERVICE_URL}/dictionaries/durations`;

    return await $api.get(url).then((response) => response.data);
  }
};

export const getRegions = async (): Promise<IDictionaryDTO[]> => {
  if (IS_SERVER_MODE_MOCKED) {
    return Promise.resolve([]);
  } else {
    const url = `${PROFILE_SERVICE_URL}/regions`;

    return await $api.get(url).then((response) => response.data);
  }
};

export const getSubscriptionStatuses = async (): Promise<IDictionaryDTO[]> => {
  return Promise.resolve(mockData.subscriptionStatuses);
};

export const getProfile = async (): Promise<AxiosResponse<IProfileStruct>> => {
  const url = `${IDENTITY_SERVICE_URL}/profile`;

  return await axios.get(url, { withCredentials: true });
};

export const activateProfile = async (): Promise<any> => {
  const url = `${BILLING_SERVICE_URL}/service/activate`;

  return await $api.put(url).then((response) => response);
};

export const deactivateProfile = async (): Promise<any> => {
  const url = `${BILLING_SERVICE_URL}/service/deactivate`;

  return await $api.put(url).then((response) => response);
};

export const getTestSubscription = async (): Promise<
  AxiosResponse<IPaymentDTO>
> => {
  const url = `${BILLING_SERVICE_URL}/subscriptions/test`;

  return await $api.get(url);
};

export const getTestSubscriptionStatus =
  async (): Promise<ITestSubscriptionStatusResponse> => {
    const url = `${BILLING_SERVICE_URL}/customer-admins`;

    return await $api.get(url).then((response) => response.data);
  };

export const createCustomerAdmin = async (
  payload: ICustomerAdminCreateDTOPayload
): Promise<ICustomerAdminDTO> => {
  const url = `${PROFILE_SERVICE_URL}/potential`;

  const { data } = await $api.put(url, payload);

  return data;
};

export const updateCustomerAdminInfo = async (
  payload: ICustomerAdminUpdateDTOPayload
) => {
  const url = `${PROFILE_SERVICE_URL}/customer-admins`;

  return $api.put(url, payload).then((response) => response.data);
};

export const getCustomerAdminData = async (): Promise<ICustomerAdminDTO> => {
  const url = `${PROFILE_SERVICE_URL}/customer-admins`;

  return await $api.get(url).then((response) => response.data);
};

export const getCustomerAdminDataForBaseUsers =
  async (): Promise<ICustomerAdminForBaseUsersDTO> => {
    const url = `${PROFILE_SERVICE_URL}/base-users/customer-admins`;

    return await $api.get(url).then((response) => response.data);
  };

export const getSubscriptionInfoCommon =
  async (): Promise<ISubscriptionResponseCommonDTO> => {
    const url = `${PROFILE_SERVICE_URL}/subscription`;

    return await $api.get(url).then((response) => response.data);
  };

export const getAllBaseStations = async (): Promise<IBaseStationEchoDTO[]> => {
  const url = `${CDC_DATA_SERVICE_URL}/basestations/all`;

  return await $api.get(url).then((response) => response.data);
};

export const getAllBaseStationsCommon = async (): Promise<
  IBaseStationEchoCommonDTO[]
> => {
  const url = `${CDC_DATA_SERVICE_URL}/basestations/common/all`;

  return await $api.get(url).then((response) => response.data);
};

export const getPlannedBaseStations = async (): Promise<
  IPlannedBaseStationEchoDTO[]
> => {
  const url = `${CDC_DATA_SERVICE_URL}/planned-basestations/all`;

  return await $api.get(url).then((response) => response.data);
};

export const updateDevicePassword = async (
  payload: IUpdateDevicePasswordPayload
): Promise<any> => {
  const { deviceLogin, newDevicePassword } = payload;
  const url = `${PROFILE_SERVICE_URL}/devices/${deviceLogin}`;

  const { data } = await $api.put(url, {
    password: newDevicePassword,
  });

  return data;
};

export const checkAbilityToCreatePayments =
  async (): Promise<ICheckAbilityToCreatePaymentsPayload> => {
    const url = `${BILLING_SERVICE_URL}/payments/check-creating`;

    const { data } = await $api.get(url);

    return data;
  };

export const doProlongationForSubscriptions = async (
  payload: number[]
): Promise<IPaymentDTO> => {
  const url = `${BILLING_SERVICE_URL}/payments/subscriptions/prolongation`;

  return await $api.put(url, payload).then((response) => response.data);
};

export const getDiscounts = async (): Promise<IDiscountDTO[]> => {
  const url = `${BILLING_SERVICE_URL}/dictionaries/discounts`;

  return await $api.get(url).then((response) => response.data);
};

export const refreshRole = async (): Promise<any> => {
  const url = `${IDENTITY_SERVICE_URL}/customer-admins`;

  return await $api.put(url).then((response) => response.data);
};

export const getValidateWEBSSOAuth =
  async (): Promise<IValidateWEBSSOAuthResponse> => {
    const url = "https://login.mts.ru/amserver/oauth2/sso/validate";

    return await axios
      .get(url, { withCredentials: true })
      .then((resp) => resp.data);
  };

export const doContactUs = async (payload: IFeedbackDTO): Promise<any> => {
  const url = `${PROFILE_SERVICE_URL}/call-request`;

  return axios.post(url, payload).then((resp) => resp.data);
};
