import React from "react";

import { useHistory } from "react-router-dom";

import { MTSTabs } from "components/mts-tabs";
import { useTabsInSettingsPage } from "hooks/tabs/use-tabs-in-settings-page";

import classes from "./tabbar-in-settings-page.module.css";

interface ITabeInPageProps {
  isCanDownloadRinex: boolean;
}

export const TabBarInSettingsPage = (props: ITabeInPageProps): JSX.Element => {
  const { isCanDownloadRinex } = props;

  const { tabs, selectedTabId } = useTabsInSettingsPage({ isCanDownloadRinex });
  const history = useHistory();

  const handleChangeTab = (
    event: React.SyntheticEvent,
    newTabNumber: number
  ) => {
    const tabHref = tabs[newTabNumber]?.href || "";

    if (tabHref) {
      history.push(tabHref);
    }
  };

  return (
    <nav className={classes.nav}>
      <MTSTabs
        size="s"
        selectedTab={tabs.findIndex((tab) => tab.id === selectedTabId)}
        tabs={tabs}
        onChange={handleChangeTab}
      />
    </nav>
  );
};
