import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const Traffic = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="96"
      height="96"
      viewBox="0 0 96 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M52.2738 50.007C65.7403 56.3764 73.2914 61.3271 80.0081 59.6811C95.466 55.8928 88.1755 25.6726 60.9505 39.7309C50.2708 45.2456 29.6159 67.2188 14.0898 57.3343C4.06019 50.949 7.59097 38.2283 16.2148 36.2978C24.4045 34.4644 34.2959 41.5038 44.3787 46.2728M44.3787 46.2728C44.3787 46.2728 40.0085 43.7141 40.3491 40.9232M44.3787 46.2728C44.3787 46.2728 39.895 44.6444 38.6367 46.9029"
        stroke="black"
        strokeWidth="2"
      />
    </SvgIcon>
  );
};

export default Traffic;
