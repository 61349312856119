export const MTSButtonIconVariant = {
  primary: "primary",
  secondary: "secondary",
  secondary_inverted: "secondary_inverted",
  ghost: "ghost",
  outline: "outline",
};

export enum Size {
  XS = "XS",
  S = "S",
  M = "M",
  L = "L",
}

export enum Mode {
  standard = "standard",
  round = "round",
}
