import React from "react";

import classNames from "classnames";
import { InputLabel } from "components/input-label";
import { InputMessage } from "components/input-message";

import { ClearIcon } from "./components/icons/clear-icon";
import { ErrorIcon } from "./components/icons/error-icon";
import { EyeHideIcon } from "./components/icons/eye-hide-icon";
import { EyeIcon } from "./components/icons/eye-icon";
import { SuccessIcon } from "./components/icons/success-icon";
import { MTSInputSize, InputType } from "./constants";
import classes from "./mts-input.module.css";

export type IMTSInputSize = keyof typeof MTSInputSize;

interface IMTSInputProps {
  size?: IMTSInputSize;
  type?: string;
  placeholder?: string;
  label?: string;
  hideLabel?: boolean;
  infoText?: string;
  errorMessage?: string | undefined;
  description?: string;
  isDirty?: boolean;
  onClear?: () => void;
  dataTestId?: string | null;
  [x: string]: any;
}

export const MTSInput = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<IMTSInputProps>
>((props, ref) => {
  const {
    size = MTSInputSize.M,
    label = "",
    type = InputType.TEXT,
    hideLabel = false,
    placeholder = "",
    infoText = "",
    errorMessage = "",
    description = "",
    disabled = false,
    isDirty = false,
    onClear,
    dataTestId = null,
    required = true,
    ...rest
  } = props;

  const inputRef = React.useRef<HTMLInputElement>(null);
  const isRequiredField: boolean = required;

  const inputContainerRef = React.useRef<HTMLDivElement>(null);
  const inputButtonRef = React.useRef<HTMLDivElement>(null);

  const [isInputContainerFocused, setIsInputContainerFocused] =
    React.useState<boolean>(false);
  const [buttonType, setButtonType] = React.useState<string>(type);
  const [inputButtonWidth, setInputButtonWidth] = React.useState<number>(0);
  const [isInputFocused, setIsInputFocused] = React.useState<boolean>(false);
  const [isInputEmpty, setIsInputEmpty] = React.useState<boolean>(true);

  const isSizeXL: boolean = size === MTSInputSize.XL;
  const isOriginInputTypeText: boolean = type === InputType.TEXT;
  const isOriginInputTypeTel: boolean = type === InputType.TEL;
  const isOriginInputTypeNumber: boolean = type === InputType.NUMBER;
  const isOriginInputTypePassword: boolean = type === InputType.PASSWORD;
  const isTypePassword: boolean = buttonType === InputType.PASSWORD;

  const isShowClearIcon: boolean =
    !disabled &&
    !!onClear &&
    (isInputFocused || isInputContainerFocused) &&
    !isInputEmpty;

  const casePasswordDisabled =
    disabled === true &&
    isInputEmpty === false &&
    (isInputFocused || isInputContainerFocused);
  const casePasswordNotDisabled =
    disabled === false &&
    (isInputEmpty || !isInputEmpty) &&
    (isInputFocused || isInputContainerFocused);

  const isShowPasswordEyeIcon: boolean =
    casePasswordDisabled || casePasswordNotDisabled;

  const isShowErrorIcon: boolean =
    !isInputFocused && !isInputContainerFocused && !!errorMessage;
  const isShowSuccessIcon: boolean =
    isDirty &&
    !isInputEmpty &&
    !errorMessage &&
    !isInputFocused &&
    !isInputContainerFocused &&
    !disabled;
  const isShowInputSizeXL: boolean =
    (disabled && !isInputEmpty) ||
    (!disabled && (isInputContainerFocused || isInputFocused || !isInputEmpty));

  React.useEffect(() => {
    const _buttonWidth: number = inputButtonRef?.current?.offsetWidth ?? 0;
    setInputButtonWidth(_buttonWidth);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isShowClearIcon,
    isShowPasswordEyeIcon,
    isShowSuccessIcon,
    isShowErrorIcon,
  ]);

  const labelStyles = classNames({
    [classes.inputLabel]: true,
    [classes.inputLabelHide]: hideLabel,
    [classes.inputLabelFocusedSizeXL]: isSizeXL && isShowInputSizeXL,
    [classes.inputLabelUnfocusedSizeXL]: isSizeXL && !isShowInputSizeXL,
  });

  const inputStyles = classNames({
    [classes.input]: true,
    [classes.inputSizeS]: size === MTSInputSize.S,
    [classes.inputSizeM]: size === MTSInputSize.M,
    [classes.inputSizeL]: size === MTSInputSize.L,
    [classes.inputSizeXL]: size === MTSInputSize.XL,
    [classes.inputSizeXLHide]: size === MTSInputSize.XL && !isShowInputSizeXL,
    [classes.inputPasswordHiddenSizeS]:
      size === MTSInputSize.S &&
      !isInputEmpty &&
      isOriginInputTypePassword &&
      isTypePassword,
    [classes.inputPasswordHiddenSizeM]:
      size === MTSInputSize.M &&
      !isInputEmpty &&
      isOriginInputTypePassword &&
      isTypePassword,
    [classes.inputPasswordHiddenSizeL]:
      size === MTSInputSize.L &&
      !isInputEmpty &&
      isOriginInputTypePassword &&
      isTypePassword,
    [classes.inputPasswordHiddenSizeXL]:
      size === MTSInputSize.XL &&
      !isInputEmpty &&
      isOriginInputTypePassword &&
      isTypePassword,
    [classes.inputPasswordVisibleSizeS]:
      size === MTSInputSize.S &&
      !isInputEmpty &&
      isOriginInputTypePassword &&
      !isTypePassword,
    [classes.inputPasswordVisibleSizeM]:
      size === MTSInputSize.M &&
      !isInputEmpty &&
      isOriginInputTypePassword &&
      !isTypePassword,
    [classes.inputPasswordVisibleSizeL]:
      size === MTSInputSize.L &&
      !isInputEmpty &&
      isOriginInputTypePassword &&
      !isTypePassword,
    [classes.inputPasswordVisibleSizeXL]:
      size === MTSInputSize.XL &&
      !isInputEmpty &&
      isOriginInputTypePassword &&
      !isTypePassword,
    [classes.inputPasswordEmpty]:
      isInputEmpty && isOriginInputTypePassword && isTypePassword,
  });

  const inputContainerStyles = classNames({
    [classes.inputContainer]: true,
    [classes.inputContainerSizeS]: size === MTSInputSize.S,
    [classes.inputContainerSizeM]: size === MTSInputSize.M,
    [classes.inputContainerSizeL]: size === MTSInputSize.L,
    [classes.inputContainerSizeXL]: size === MTSInputSize.XL,
    [classes.inputContainerFocused]: isInputFocused,
    [classes.inputContainerError]: errorMessage ? true : false,
    [classes.inputContainerDisabled]: disabled,
  });

  const labelInputStyles = classNames({
    [classes.blockLabelInput]: true,
  });

  React.useEffect(() => {
    const inputContainerRefObject = inputContainerRef?.current;

    if (inputContainerRefObject) {
      inputContainerRefObject.addEventListener("mouseenter", () => {
        setIsInputContainerFocused(true);
      });

      inputContainerRefObject.addEventListener("mouseleave", () => {
        setIsInputContainerFocused(false);
      });
    }

    const inputRefObject = inputRef?.current;

    if (inputRefObject) {
      inputRefObject.addEventListener("focus", () => {
        setIsInputFocused(true);
      });

      inputRefObject.addEventListener("blur", () => {
        setIsInputFocused(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const _inputValue: string = inputRef?.current?.value ?? "";
    setIsInputEmpty(() => (_inputValue ? false : true));
  }, [inputRef?.current?.value]);

  const inputLabel = (
    <div className={labelStyles}>
      {!hideLabel && (
        <InputLabel
          size={size}
          label={label ? label : ""}
          isError={!!errorMessage}
          disabled={disabled}
          isInputInFocus={isInputFocused || isInputContainerFocused}
          isInputEmpty={isInputEmpty}
          isRequiredField={isRequiredField}
          infoText={infoText}
        />
      )}
    </div>
  );

  const input = (
    <input
      ref={inputRef}
      className={inputStyles}
      style={{ paddingRight: `${18 + inputButtonWidth}px` }}
      autoComplete="off"
      type={buttonType}
      placeholder={placeholder}
      disabled={disabled}
      required={isRequiredField}
      {...rest}
    />
  );

  const successIcon = (
    <SuccessIcon
      sx={{
        color: "var(--color-accent-positive)",
        fontSize: "24px",
      }}
    />
  );

  const errorIcon = (
    <ErrorIcon
      sx={{
        color: "var(--color-accent-negative)",
        fontSize: "24px",
      }}
    />
  );

  const clearIcon = (
    <ClearIcon
      sx={{
        color: "var(--color-icon-secondary)",
        fontSize: "24px",
      }}
    />
  );

  return (
    <div
      className={classes.blockMTSInput}
      ref={ref}
      data-testid={dataTestId || ""}
    >
      {!isSizeXL && inputLabel}
      <div ref={inputContainerRef} className={inputContainerStyles}>
        {isSizeXL ? (
          <div className={labelInputStyles}>
            {inputLabel}
            {input}
          </div>
        ) : (
          <>{input}</>
        )}

        <div className={classes.blockInputButton} ref={inputButtonRef}>
          {(isOriginInputTypeText ||
            isOriginInputTypeTel ||
            isOriginInputTypeNumber) && (
            <>
              {isShowClearIcon && (
                <div
                  className={classes.iconButton}
                  onClick={() => {
                    onClear && onClear();
                  }}
                >
                  {clearIcon}
                </div>
              )}
              {isShowSuccessIcon && <>{successIcon}</>}
              {isShowErrorIcon && <>{errorIcon}</>}
            </>
          )}

          {isOriginInputTypePassword && (
            <>
              {isShowSuccessIcon && <>{successIcon}</>}
              {isShowPasswordEyeIcon && (
                <div
                  className={classes.iconButton}
                  onClick={(e: React.SyntheticEvent) => {
                    setButtonType((prevButtonType) =>
                      prevButtonType === InputType.PASSWORD
                        ? InputType.TEXT
                        : InputType.PASSWORD
                    );
                  }}
                >
                  {isTypePassword ? (
                    <EyeIcon
                      sx={{
                        color: "var(--color-icon-secondary)",
                        fontSize: "24px",
                      }}
                    />
                  ) : (
                    <EyeHideIcon
                      sx={{
                        color: "var(--color-icon-secondary)",
                        fontSize: "24px",
                      }}
                    />
                  )}
                </div>
              )}
              {isShowErrorIcon && <>{errorIcon}</>}
            </>
          )}
        </div>
        {!!errorMessage && (
          <div className={classes.inputMessage}>
            <InputMessage text={errorMessage ?? ""} mode="error" />
          </div>
        )}
        {!!description && !errorMessage && (
          <div className={classes.inputMessage}>
            <InputMessage text={description ?? ""} mode="description" />
          </div>
        )}
      </div>
    </div>
  );
});
