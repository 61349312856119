import { FIELD_NAMES } from "constants/keys";

import { useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import { IDictionaryDTO } from "interfaces";
import { fieldSchema } from "utils/yup-schema";
import * as yup from "yup";

export interface IFormContactUs {
  [FIELD_NAMES.CONTACT_US_FIO]: string;
  [FIELD_NAMES.CONTACT_US_PHONE]: string;
  [FIELD_NAMES.CONTACT_US_EMAIL]: string;
  [FIELD_NAMES.CONTACT_US_COMPANY_NAME]: string;
  [FIELD_NAMES.CONTACT_US_REGION]: IDictionaryDTO | null;
}

const schema = yup.object({
  [FIELD_NAMES.CONTACT_US_FIO]: fieldSchema.required.fio,
  [FIELD_NAMES.CONTACT_US_PHONE]: fieldSchema.required.phone,
  [FIELD_NAMES.CONTACT_US_EMAIL]: fieldSchema.required.email,
  [FIELD_NAMES.CONTACT_US_COMPANY_NAME]: fieldSchema.required.companyName,
  [FIELD_NAMES.CONTACT_US_REGION]: fieldSchema.required.region,
});

export const useFormContactUs = () => {
  return useForm<IFormContactUs>({
    mode: "onChange",
    defaultValues: {
      fio: "",
      phoneNumber: "",
      email: "",
      companyName: "",
      region: null,
    },
    shouldFocusError: false,
    resolver: yupResolver(schema),
  });
};
