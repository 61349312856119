import { DateTime } from "luxon";

export interface IDateFormatted {
  date: string;
  time: string;
  zoneName: string;
}

// NOTE: Возвращает дату в формате дд.мм.гггг
export const getDateFormattedWithTimeZone = (
  dateISO: string
): IDateFormatted => {
  let date: string = "";
  let time: string = "";
  let zoneName: string = "";

  if (dateISO) {
    const _date = DateTime.fromISO(dateISO, { setZone: true });

    if (_date.isValid) {
      date = _date.toFormat("dd.MM.yyyy");
      time = _date.toFormat("T");
      zoneName = _date.zoneName;
    }
  }

  return {
    date,
    time,
    zoneName,
  };
};
