import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const ArrowsMin = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.78087 14.0239C8.38054 14.5243 7.61946 14.5243 7.21913 14.0239L5.29976 11.6247C4.77595 10.9699 5.24212 10 6.08062 10H9.91938C10.7579 10 11.2241 10.9699 10.7002 11.6247L8.78087 14.0239Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.21913 1.97609C7.61946 1.47568 8.38054 1.47568 8.78087 1.97609L10.7002 4.3753C11.2241 5.03007 10.7579 6 9.91938 6H6.08062C5.24212 6 4.77595 5.03007 5.29976 4.37531L7.21913 1.97609Z"
      />
    </SvgIcon>
  );
};

export default ArrowsMin;
