import { PROFILE_WIDGET_ALL_SERVICES_ID } from "constants/dom-element-ids";
import { PROFILE_WIDGET_ENV } from "constants/env";

import React from "react";

import { MTSButton } from "components/mts-button";
import { MTSIcon } from "components/mts-icon";

import classes from "./button-all-services.module.css";

const LOCAL_PROFILE_WIDGET_ACCOUNT_ID = "profile-widget-app";

interface IButtonAllServicesProps {
  onClick: () => void;
}

export const ButtonAllServices = (
  props: IButtonAllServicesProps
): JSX.Element => {
  const { onClick } = props;
  const [profileWidget, setProfileWidget] = React.useState<any>(null);

  React.useEffect(() => {
    if (!profileWidget) {
      try {
        const _widget = new (window as any).profileWidgetApp({
          target: document.getElementById(LOCAL_PROFILE_WIDGET_ACCOUNT_ID),
          props: {
            env: PROFILE_WIDGET_ENV,
            loginEndpoint: () => {},
            logoutEndpoint: () => {},
            queryHideProductsButton: 1280,
            supportLink: "",
            productsMobileTarget: document.getElementById(
              PROFILE_WIDGET_ALL_SERVICES_ID
            ),
            theme: "Light",
            blockLoading: false,
            disableNotifications: false,
            disableBusinessProfileSwitcher: false,
            disableProductsWidget: false,
            isB2C: true,
            disableWidget: false,
            profileManageItemValue: false,
          },
        });

        setProfileWidget(_widget);
      } catch (error) {}
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileWidget]);

  return (
    <>
      <MTSButton
        id={PROFILE_WIDGET_ALL_SERVICES_ID}
        variant="primary_alternate"
        size="M"
        onClick={onClick}
        sx={{
          width: "292px",
          "@media (max-width: 500px)": {
            width: "100%",
          },
        }}
      >
        <span className={classes.buttonText}>
          <MTSIcon.DotsMenu sx={{ color: "inherit", fontSize: "24px" }} />
          Все сервисы МТС
        </span>
      </MTSButton>
      <div
        className={classes.profileWidget}
        id={LOCAL_PROFILE_WIDGET_ACCOUNT_ID}
      />
    </>
  );
};
