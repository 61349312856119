import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const Profile = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M19.9998 10.8309C16.777 10.8309 14.1644 13.4435 14.1644 16.6663C14.1644 19.8891 16.777 22.5016 19.9998 22.5016C23.2226 22.5016 25.8352 19.8891 25.8352 16.6663C25.8352 13.4435 23.2226 10.8309 19.9998 10.8309Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.13509 16.538C5.45958 12.1781 5.62183 9.99807 7.80995 7.80995C9.99807 5.62183 12.1781 5.45958 16.538 5.13509C17.6683 5.05097 18.8341 5 20 5C21.1659 5 22.3317 5.05097 23.462 5.13509C27.8219 5.45958 30.0019 5.62183 32.1901 7.80995C34.3782 9.99807 34.5404 12.1781 34.8649 16.538C34.949 17.6683 35 18.8341 35 20C35 21.1659 34.949 22.3317 34.8649 23.462C34.5404 27.8219 34.3782 30.0019 32.1901 32.1901C30.0019 34.3782 27.8219 34.5404 23.462 34.8649C22.3317 34.949 21.1659 35 20 35C18.8341 35 17.6683 34.949 16.538 34.8649C12.1781 34.5404 9.99807 34.3782 7.80995 32.1901C5.62183 30.0019 5.45958 27.8219 5.13509 23.462C5.05097 22.3317 5 21.1659 5 20C5 18.8341 5.05097 17.6683 5.13509 16.538ZM16.7854 8.45923C17.8474 8.38019 18.9296 8.33333 20 8.33333C21.0704 8.33333 22.1526 8.38019 23.2146 8.45923C27.7985 8.80039 28.656 8.98997 29.833 10.167C31.01 11.344 31.1996 12.2015 31.5408 16.7854C31.6198 17.8474 31.6667 18.9296 31.6667 20C31.6667 21.0704 31.6198 22.1526 31.5408 23.2146C31.4161 24.89 31.3116 26.0676 31.1718 26.945C26.5285 25.7697 23.9935 25.1714 21.4159 25.0426C20.4752 24.9956 19.5328 24.9956 18.5921 25.0424C16.0143 25.1708 13.4768 25.7694 8.82822 26.9452C8.68839 26.0679 8.58394 24.8902 8.45923 23.2146C8.38019 22.1526 8.33333 21.0704 8.33333 20C8.33333 18.9296 8.38019 17.8474 8.45923 16.7854C8.80039 12.2015 8.98997 11.344 10.167 10.167C11.344 8.98997 12.2015 8.80039 16.7854 8.45923Z"
      />
    </SvgIcon>
  );
};

export default Profile;
