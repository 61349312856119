import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const ChevronUp = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M5.01246 15C4.11226 15 3.66144 13.9234 4.29798 13.2938C4.29797 13.2938 4.29798 13.2938 4.29798 13.2938L7.4273 10.1984C9.58289 8.06612 10.6607 7 12 7C13.3393 7 14.4171 8.06612 16.5727 10.1984L19.702 13.2938C20.3386 13.9234 19.8877 15 18.9875 15C18.7196 15 18.4625 14.8947 18.2731 14.7073L15.1437 11.6118C14.0255 10.5058 13.3276 9.82141 12.7544 9.38882C12.2371 8.99838 12.0565 8.99883 12.0028 8.99897L12 8.99897L11.9973 8.99897C11.9435 8.99883 11.7629 8.99838 11.2456 9.38882C10.6724 9.8214 9.97446 10.5058 8.85627 11.6118L5.72695 14.7073C5.72695 14.7073 5.72695 14.7073 5.72695 14.7073C5.53745 14.8947 5.28044 15 5.01246 15Z" />
    </SvgIcon>
  );
};

export default ChevronUp;
