import { TEST_SUBSCRIPTION_STATUS, QueryKeys } from "constants/keys";

import React from "react";

import { useQuery } from "react-query";

import { useProfileData } from "hooks/use-profile-data";
import { ITestSubscriptionStatusResponse } from "interfaces";
import { getTestSubscriptionStatus } from "services/api";

import { useContextMain } from "./use-context-main";

interface IUseTestSubscriptionStatusResult {
  data: ITestSubscriptionStatusResponse | undefined;
  isTestSubscriptionUsed: boolean;
  isTestSubscriptionUsing: boolean;
  isTestSubscriptionNotUsed: boolean;
  isLoading: boolean;
  isError: boolean;
}

export const useStatusTestSubscription =
  (): IUseTestSubscriptionStatusResult => {
    const { isAuthorized, testSubscriptionStatus, setTestSubscriptionStatus } =
      useContextMain();
    const { isCustomerAdminRole, isCustomerBaseRole, isPotentialRole } =
      useProfileData();

    const { data, isLoading, isError } =
      useQuery<ITestSubscriptionStatusResponse>(
        QueryKeys.TEST_SUBSCRIPTION_USED,
        () => getTestSubscriptionStatus(),
        {
          enabled: isAuthorized && isCustomerAdminRole,
          retry: 0,
          // Cache memory time is 1 hour
          staleTime: 1 * 60 * 60 * 1000,
        }
      );

    const testModeStatus: string = data?.testModeStatus ?? "";

    const isTestSubscriptionUsed: boolean =
      testModeStatus === TEST_SUBSCRIPTION_STATUS.RTK_RINEX_USED;
    const isTestSubscriptionUsing: boolean =
      testModeStatus === TEST_SUBSCRIPTION_STATUS.RTK_RINEX_USING;
    const isTestSubscriptionNotUsed: boolean =
      testModeStatus === TEST_SUBSCRIPTION_STATUS.RTK_RINEX_NOT_USED;

    React.useEffect(() => {
      if (isAuthorized) {
        if (isCustomerAdminRole && isLoading && data) {
          setTestSubscriptionStatus(testModeStatus);
        }

        if (isCustomerBaseRole) {
          setTestSubscriptionStatus("");
        }

        if (isPotentialRole) {
          setTestSubscriptionStatus("");
        }
      } else {
        setTestSubscriptionStatus("");
      }
    }, [
      isAuthorized,
      isCustomerAdminRole,
      isCustomerBaseRole,
      isPotentialRole,
      testSubscriptionStatus,
      setTestSubscriptionStatus,
      data?.testModeStatus,
      isLoading,
      data,
      testModeStatus,
    ]);

    return {
      isTestSubscriptionUsed,
      isTestSubscriptionNotUsed,
      isTestSubscriptionUsing,
      isLoading,
      isError,
      data,
    };
  };
