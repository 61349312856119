import { IDictionaryDTO } from "interfaces";

export const getDictionaryItemIdByCode = (
  itemCode: string | undefined,
  dictionary: IDictionaryDTO[] | undefined | null
): number | undefined => {
  let serviceKindId: number | undefined = undefined;

  if (itemCode && typeof itemCode === "string" && dictionary?.length) {
    const serviceKind: IDictionaryDTO | undefined = dictionary.find(
      (item) => item.code === itemCode
    );

    if (serviceKind) {
      serviceKindId = serviceKind.id;
    }
  }

  return serviceKindId;
};
