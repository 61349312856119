import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const ArrowUpMin = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.21913 6.97609C7.61946 6.47568 8.38054 6.47568 8.78087 6.97609L10.7002 9.3753C11.2241 10.0301 10.7579 11 9.91938 11H6.08062C5.24212 11 4.77595 10.0301 5.29976 9.37531L7.21913 6.97609Z"
      />
    </SvgIcon>
  );
};

export default ArrowUpMin;
