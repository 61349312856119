import { DISCOUNT_TYPE } from "constants/keys";

import { MTSBadge } from "components/mts-badge";
import { IDiscountDTO } from "interfaces";

import classes from "./payment-card-discounts.module.css";
import { getDiscountLabel } from "./utils/get-discount-label";

interface IPaymentCardDiscountsProps {
  discounts: IDiscountDTO[];
}

export const PaymentCardDiscounts = (
  props: IPaymentCardDiscountsProps
): JSX.Element | null => {
  const { discounts } = props;

  if (discounts?.length === 0) {
    return null;
  }

  return (
    <ul className={classes.discounts}>
      {discounts.map((discountData: IDiscountDTO, index: number) => {
        const isSubscriptionCountDiscount: boolean =
          discountData?.type === DISCOUNT_TYPE.SUBSCRIPTION_COUNT_DISCOUNT;

        return (
          <li key={index} className={classes.discount}>
            <div className={classes.discountPercent}>
              <MTSBadge
                size="S"
                variant={isSubscriptionCountDiscount ? "GREY" : "CUSTOM_COLOR"}
                colorBackground={"var(--color-control-primary-active)"}
              >
                Скидка {discountData.percent}%
              </MTSBadge>
            </div>

            <p className={classes.discountType}>
              {getDiscountLabel(discountData.type)}
            </p>
          </li>
        );
      })}
    </ul>
  );
};
