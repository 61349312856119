import { PAYMENT_TYPE } from "constants/keys";

import React from "react";

import { useParams } from "react-router-dom";

import { AppHelmet } from "components/app-helmet";
import { ContainerContent } from "components/container-content";
import { FooterOfPersonalArea } from "components/footer-of-personal-area";
import { IconWrapper } from "components/icon-wrapper";
import { Loader } from "components/loader";
import { MTSIcon } from "components/mts-icon";
import { PageTitle } from "components/page-title";
import {
  IPayGroupFormStruct,
  PaymentConfigurator,
} from "components/payment-configurator";
import { Plug } from "components/plug";
import {
  GAP_EVENT_TYPE,
  GA_SCREEN_NAME,
  useGoogleAnalyticsPages,
} from "hooks/google-analytics";
import { useDurations } from "hooks/use-durations";
import { usePaymentData } from "hooks/use-payment-data";
import { useServiceKinds } from "hooks/use-service-kinds";
import { IDictionaryDTO } from "interfaces";
import { mockData } from "services/mock-data";
import { getParsedFormDataPayGroups } from "utils/get-parsed-form-data-pay-groups";

const PAGE_TITLE = "Редактировать подписки";

interface IMatchParams {
  id: string;
}

export const PaymentEditor = (): JSX.Element => {
  const { id } = useParams<IMatchParams>();

  useGoogleAnalyticsPages({
    screenName: GA_SCREEN_NAME.payment_edit,
    event: GAP_EVENT_TYPE.SCRN,
  });

  const [initialFormData, setInitialFormData] = React.useState<
    IPayGroupFormStruct[]
  >([]);

  const { data: durations, isLoading: isLoadingDurations } = useDurations();
  const { data: serviceKinds, isLoading: isLoadingServiceKinds } =
    useServiceKinds();
  const regions: IDictionaryDTO[] = mockData.regions;
  const { data: paymentData, isLoading: isLoadingPaymentData } =
    usePaymentData(id);

  const paymentType: string = paymentData?.type ?? "";

  React.useEffect(() => {
    if (
      !isLoadingPaymentData &&
      !isLoadingDurations &&
      !isLoadingServiceKinds
    ) {
      if (paymentData?.payGroups?.length && durations && serviceKinds) {
        const dataForPaymentConfigurator: IPayGroupFormStruct[] =
          getParsedFormDataPayGroups({
            payGroups: paymentData?.payGroups,
            serviceKinds,
            durations,
            regions,
          });

        setInitialFormData(dataForPaymentConfigurator);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingPaymentData, isLoadingDurations, isLoadingServiceKinds]);

  if (isLoadingPaymentData || isLoadingDurations || isLoadingServiceKinds) {
    return <Loader />;
  }

  return (
    <>
      <AppHelmet title={`${PAGE_TITLE} - Личный кабинет`} />
      <ContainerContent>
        <PageTitle text={PAGE_TITLE} />

        {paymentType === PAYMENT_TYPE.NEW &&
          Boolean(initialFormData?.length) && (
            <PaymentConfigurator
              initialFormData={initialFormData}
              isEditMode
              paymentId={id}
            />
          )}

        {paymentType === PAYMENT_TYPE.PROLONGATION && (
          <Plug
            icon={
              <IconWrapper
                size={64}
                icon={
                  <MTSIcon.WarningCircle
                    sx={{
                      fontSize: "24px",
                      color: "var(--color-constant-blackberry-light)",
                    }}
                  />
                }
              />
            }
            label="Данный платеж невозможно редактировать"
          />
        )}
      </ContainerContent>
      <FooterOfPersonalArea />
    </>
  );
};
