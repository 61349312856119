import {
  FIELD_NAMES,
  PROMPT_MESSAGE_UNSAVED_DATA,
  REQUIRED_FIELD_MESSAGE,
} from "constants/keys";
import { DEBOUNCE_REQUEST_TIME } from "constants/server";

import React from "react";

import { FormProvider, useForm } from "react-hook-form";
import { Prompt } from "react-router";

import { yupResolver } from "@hookform/resolvers/yup";
import { MTSButton } from "components/mts-button";
import { PaymentConfiguratorTableDiscounts } from "components/payment-configurator/components/payment-configurator-table-discounts";
import {
  GAE_ACTION_GROUP,
  GAE_BUTTON_LOCATION,
  GAE_EVENT,
  GAE_EVENT_ACTION,
  GAE_EVENT_CATEGORY,
  GAE_EVENT_LABEL,
  useGoogleAnalyticsEvents,
} from "hooks/google-analytics";
import { useMutationCreatePayment } from "hooks/use-mutation-create-payment";
import { useMutationPaymentCalculations } from "hooks/use-mutation-payment-calculations";
import { useMutationUpdatePayment } from "hooks/use-mutation-update-payment";
import { ICalculationDTO, IPayGroupDTOPayload } from "interfaces";
import * as _ from "lodash";
import { getDurationTranslit } from "utils/get-duration-translit.ts";
import { getProductNameByPayment } from "utils/google-analytics/get-pruduct-name-by-payment";
import * as yup from "yup";

import { CancelButton } from "./components/cancel-button";
import { PaymentConfiguratorCalculations } from "./components/payment-configurator-calculations";
import { PaymentConfiguratorForm } from "./components/payment-configurator-form";
import {
  FormPaymentConfigurator,
  MAX_TOTAL_SUB_COUNT,
  TOTAL_SUB_COUNT_EXCEEDS_ERROR_MEESAGE,
} from "./constants";
import { IUseFormPayGroupsStruct, IPayGroupFormStruct } from "./interfaces";
import classes from "./payment-configurator.module.css";
import { getPayGroupsPayload } from "./utils/get-pay-groups-payload";

const schema = yup.object().shape({
  [FormPaymentConfigurator.GROUPS]: yup.array().of(
    yup.object().shape({
      [FIELD_NAMES.PAYMENT_CONFIGURATOR_COUNT]: yup
        .number()
        .transform((value) => (isNaN(value) ? 0 : value))
        .integer("Введите целое число")
        .positive("В одном платеже должна быть хотя бы одна подписка")
        .max(MAX_TOTAL_SUB_COUNT, TOTAL_SUB_COUNT_EXCEEDS_ERROR_MEESAGE),
      [FIELD_NAMES.PAYMENT_CONFIGURATOR_SERVICE_KIND]: yup
        .mixed()
        .required(REQUIRED_FIELD_MESSAGE),
      [FIELD_NAMES.PAYMENT_CONFIGURATOR_DURATION]: yup
        .mixed()
        .required(REQUIRED_FIELD_MESSAGE),
      [FIELD_NAMES.PAYMENT_CONFIGURATOR_REGION]: yup
        .mixed()
        .required(REQUIRED_FIELD_MESSAGE),
    })
  ),
});

export type IPaymentConfiguratorLocationState = {
  redirectBackTo: string;
};

interface IPaymentConfiguratorProps {
  initialFormData: IPayGroupFormStruct[];
  isEditMode?: boolean;
  paymentId?: string;
  disableToCreatePayment?: boolean;
}

export const PaymentConfigurator = (props: IPaymentConfiguratorProps) => {
  const {
    initialFormData,
    isEditMode,
    paymentId,
    disableToCreatePayment = true,
  } = props;
  const { sendGAE } = useGoogleAnalyticsEvents();

  const [calculations, setCalculations] =
    React.useState<ICalculationDTO | null>(null);
  const [prevPayGroupsIds, setPrevPayGroupsIds] = React.useState<string[]>([]);

  const [errorFieldIndexOfSubCount, setErrorFieldIndexOfSubCount] =
    React.useState<string | null>(null);
  const isFormValid: boolean = !!errorFieldIndexOfSubCount;

  const mutationCreatePayment = useMutationCreatePayment();
  const mutationUpdatePayment = useMutationUpdatePayment();
  const mutationPaymentCalculations = useMutationPaymentCalculations();

  const formMethods = useForm<any>({
    mode: "onChange",
    shouldFocusError: false,
    defaultValues: {
      [FormPaymentConfigurator.GROUPS]: initialFormData,
    },
    resolver: yupResolver(schema),
  });
  const {
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { isDirty, isValid },
  } = formMethods;

  const onSubmit = (formData: IUseFormPayGroupsStruct): void => {
    const payload: IPayGroupDTOPayload[] = getPayGroupsPayload(
      formData?.[FormPaymentConfigurator.GROUPS]
    );

    reset(formData);

    if (isEditMode) {
      mutationUpdatePayment.mutate({
        payload,
        paymentId,
      });
    } else {
      const filterNameTranslit: string = payload
        .map((payGroup) => {
          const durationTranslit: string = getDurationTranslit(
            payGroup.duration ?? ""
          );
          const subCount: number = payGroup.subscriptionsCount;
          const serviceKindCode: string = payGroup.serviceKind.toLowerCase();

          return `${subCount}_podpisok_${serviceKindCode}_${durationTranslit}`;
        })
        .join("|");

      const paymentAmount: string | null =
        calculations?.amount && Number.isNaN(calculations?.amount)
          ? "0"
          : String(Math.round(calculations?.amount as number));

      const serviceKinds = new Set(
        payload.map((payGroup: IPayGroupDTOPayload) => payGroup.serviceKind)
      );

      const productName: string = getProductNameByPayment([...serviceKinds]);

      sendGAE({
        id: 25,
        event: GAE_EVENT.vntPodpiski,
        eventCategory: GAE_EVENT_CATEGORY.podpiski,
        eventAction: GAE_EVENT_ACTION.button_click,
        eventLabel: GAE_EVENT_LABEL.dobavit,
        eventValue: paymentAmount,
        eventContent: null,
        buttonLocation: GAE_BUTTON_LOCATION.screen,
        filterName: filterNameTranslit,
        actionGroup: GAE_ACTION_GROUP.interactions,
        productName: productName,
      });

      mutationCreatePayment.mutate(payload);
    }
  };

  const doCalculations = _.debounce((formData: IPayGroupFormStruct[]): void => {
    const payGroupsPayloadForCalculation: IPayGroupDTOPayload[] =
      getPayGroupsPayload(formData);

    const totalCountSubs: number = payGroupsPayloadForCalculation.reduce(
      (countSubs, payGroupItem) =>
        (countSubs += payGroupItem.subscriptionsCount),
      0
    );

    if (totalCountSubs <= MAX_TOTAL_SUB_COUNT) {
      const newPayGroupsIds: string[] = payGroupsPayloadForCalculation.map(
        (payGroupPayload) => JSON.stringify(payGroupPayload)
      );

      let isDoRequestForCalculation: boolean = false;

      for (let newPayGroupId of newPayGroupsIds) {
        if (!prevPayGroupsIds.includes(newPayGroupId)) {
          isDoRequestForCalculation = true;
          break;
        }
      }

      if (newPayGroupsIds.length < prevPayGroupsIds.length) {
        isDoRequestForCalculation = true;
      }

      if (isDoRequestForCalculation) {
        setPrevPayGroupsIds(newPayGroupsIds);

        mutationPaymentCalculations.mutate(payGroupsPayloadForCalculation, {
          onSuccess: (response) => {
            setCalculations(response);
          },
        });
      }
    }
  }, DEBOUNCE_REQUEST_TIME);

  React.useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      const formData: IPayGroupFormStruct[] =
        value?.[FormPaymentConfigurator.GROUPS];

      // NOTE: Подсчет общего кол-ва подписок в платеже
      const subCountArray: number[] = formData.map(
        (_value: IPayGroupFormStruct) =>
          Number.isInteger(Number(_value.count)) ? Number(_value.count) : 0
      );

      const totalSubscriptionCountInPayment: number = subCountArray.reduce(
        (totalCount, count) => totalCount + count,
        0
      );

      if (
        totalSubscriptionCountInPayment > MAX_TOTAL_SUB_COUNT &&
        name &&
        type === "change"
      ) {
        const [, index, fieldName] = name?.split(".");

        if (
          fieldName === FIELD_NAMES.PAYMENT_CONFIGURATOR_COUNT &&
          index?.length &&
          Number.isInteger(Number(index))
        ) {
          setErrorFieldIndexOfSubCount(index);
        }
      } else {
        setErrorFieldIndexOfSubCount(null);
      }

      // NOTE: Логика по форматированию поля с количеством подписок
      formData.forEach((payGroup: IPayGroupFormStruct, index) => {
        if (payGroup) {
          const subCount: number = payGroup.count;

          if (subCount.toString().includes(".")) {
            setValue(
              `${FormPaymentConfigurator.GROUPS}.${index}.${FIELD_NAMES.PAYMENT_CONFIGURATOR_COUNT}`,
              Number(subCount.toString().replace(".", ""))
            );
          }
        }
      });

      // NOTE: Рассчет стоимости групп с подписками
      doCalculations(formData);
    });

    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch, prevPayGroupsIds]);

  React.useEffect(() => {
    doCalculations(initialFormData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.card}>
      <div className={classes.cardContent}>
        <div className={classes.cardGroups}>
          <FormProvider {...formMethods}>
            <PaymentConfiguratorForm
              payGroups={calculations?.payGroups ?? []}
              errorFieldIndexOfSubCount={errorFieldIndexOfSubCount}
            />
          </FormProvider>
        </div>
        <div className={classes.cardTableDiscounts}>
          <PaymentConfiguratorTableDiscounts />
        </div>
      </div>

      <div className={classes.cardFooter}>
        <div className={classes.cardFooterCalculations}>
          <PaymentConfiguratorCalculations calculations={calculations} />
        </div>

        <div className={classes.cardFooterActions}>
          <CancelButton isEditMode={isEditMode} />

          <MTSButton
            variant="primary"
            size="M"
            sx={{ width: 136, height: 44 }}
            onClick={handleSubmit(onSubmit)}
            disabled={
              (isEditMode ? false : !isValid || disableToCreatePayment) ||
              isFormValid
            }
            loading={
              mutationCreatePayment.isLoading || mutationUpdatePayment.isLoading
            }
          >
            {isEditMode ? "Сохранить" : "Добавить"}
          </MTSButton>
        </div>
      </div>

      <Prompt when={isDirty} message={PROMPT_MESSAGE_UNSAVED_DATA} />
    </div>
  );
};
