import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const MTS = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="32" height="32" rx="8" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 17.3847C11 20.1039 12.5435 22.8462 16 22.8462C19.4534 22.8462 21 20.1039 21 17.3847C21 15.5272 20.3657 13.3883 19.3071 11.6607C18.2776 9.99233 17.0407 9 16 9C14.9564 9 13.7189 9.99233 12.6983 11.6607C11.6347 13.3883 11 15.5272 11 17.3847Z"
        fill="white"
      />
    </SvgIcon>
  );
};

export default MTS;
