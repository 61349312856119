import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const MediaLive = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50.0002 40C50.0002 45.5228 45.523 50 40.0002 50C34.4773 50 30.0002 45.5228 30.0002 40C30.0002 34.4771 34.4773 30 40.0002 30C45.523 30 50.0002 34.4771 50.0002 40ZM43.3335 40C43.3335 41.8409 41.8411 43.3333 40.0002 43.3333C38.1592 43.3333 36.6668 41.8409 36.6668 40C36.6668 38.159 38.1592 36.6666 40.0002 36.6666C41.8411 36.6666 43.3335 38.159 43.3335 40Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.0002 63.3333C52.8868 63.3333 63.3335 52.8866 63.3335 40C63.3335 27.1133 52.8868 16.6666 40.0002 16.6666C27.1135 16.6666 16.6668 27.1133 16.6668 40C16.6668 52.8866 27.1135 63.3333 40.0002 63.3333ZM40.0002 56.6666C49.2049 56.6666 56.6668 49.2047 56.6668 40C56.6668 30.7952 49.2049 23.3333 40.0002 23.3333C30.7954 23.3333 23.3335 30.7952 23.3335 40C23.3335 49.2047 30.7954 56.6666 40.0002 56.6666Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.0002 76.6666C60.2506 76.6666 76.6668 60.2504 76.6668 40C76.6668 19.7495 60.2506 3.33331 40.0002 3.33331C19.7497 3.33331 3.3335 19.7495 3.3335 40C3.3335 60.2504 19.7497 76.6666 40.0002 76.6666ZM40.0002 70C56.5687 70 70.0002 56.5685 70.0002 40C70.0002 23.4314 56.5687 9.99998 40.0002 9.99998C23.4316 9.99998 10.0002 23.4314 10.0002 40C10.0002 56.5685 23.4316 70 40.0002 70Z"
      />
    </SvgIcon>
  );
};

export default MediaLive;
