import React from "react";

import Tooltip from "@mui/material/Tooltip";
import classNames from "classnames";
import { MTSIcon } from "components/mts-icon";
import { IMTSInputSize, MTSInputSize } from "components/mts-input";

import { LockIcon } from "./components/icons/lock-icon";
import classes from "./input-label.module.css";

interface InputLabelProps {
  size?: IMTSInputSize;
  label?: string;
  disabled?: boolean;
  isError?: boolean;
  isInputInFocus?: boolean;
  isInputEmpty?: boolean;
  isRequiredField?: boolean;
  infoText?: string;
}

export const InputLabel = React.memo((props: InputLabelProps) => {
  const {
    size = MTSInputSize.M,
    label,
    disabled = false,
    isError = false,
    isInputInFocus = false,
    isInputEmpty = false,
    isRequiredField = false,
    infoText = "",
  } = props;

  const isSizeXL: boolean = size === MTSInputSize.XL;

  const labelStyles = classNames({
    [classes.label]: true,
    [classes.labelError]: isError,
  });
  const labelSizeXLStyles = classNames({
    [classes.labelSizeXL]: true,
    [classes.labelSizeXLFocused]: isInputInFocus || !isInputEmpty,
    [classes.labelSizeXLError]: isError,
  });

  if (!label) {
    return <div className={classes.blockInputLabel}></div>;
  }

  return (
    <div className={classes.blockInputLabel}>
      {isSizeXL === false && <span className={labelStyles}>{label}</span>}
      {isSizeXL === true && <span className={labelSizeXLStyles}>{label}</span>}
      {disabled && (
        <div className={classes.icon}>
          <LockIcon
            sx={{
              color: "var(--color-icon-secondary)",
              fontSize: "16px",
            }}
          />
        </div>
      )}
      {!disabled && infoText && (
        <Tooltip title={infoText} arrow disableInteractive placement="right">
          <div className={classes.icon}>
            <MTSIcon.QuestionCircle
              sx={{
                fontSize: "16px",
                color: "var(--color-icon-secondary)",
              }}
            />
          </div>
        </Tooltip>
      )}
      {!isRequiredField && !!label && (
        <div className={classes.chip}>
          <span className={classes.chipText}>Необязательно</span>
        </div>
      )}
    </div>
  );
});
