import { FIELD_NAMES } from "constants/keys";

import React from "react";

import { Control, Controller, UseFormRegister } from "react-hook-form";

import { InputSearch } from "components/input-search";
import { MTSAutocomplete } from "components/mts-autocomplete";
import { useDurations } from "hooks/use-durations";
import { useServiceKinds } from "hooks/use-service-kinds";
import { useStatusTestSubscription } from "hooks/use-test-subscription-status";
import { getDurationsWithOutTest } from "utils/get-durations-with-out-test";

import classes from "./table-subscriptions-filters.module.css";

interface ITableSubscriptionsFiltersProps {
  isSearchFilled: boolean;
  clearSearchInput: () => void;
  control: Control<any>;
  register: UseFormRegister<any>;
}

export const TableSubscriptionsFilters = (
  props: ITableSubscriptionsFiltersProps
) => {
  const { isSearchFilled, register, control, clearSearchInput } = props;
  const { data: durations, isLoading: isLoadingDurations } = useDurations();
  const { data: serviceKinds, isLoading: isLoadingServiceKinds } =
    useServiceKinds();
  const { isTestSubscriptionUsed, isLoading: isLoadingTestSubscriptionStatus } =
    useStatusTestSubscription();

  const durationsWithOutTest = React.useMemo(
    () => getDurationsWithOutTest(durations),
    [durations]
  );

  return (
    <form className={classes.blockFilter} autoComplete="off">
      <div className={classes.filterSearch}>
        <InputSearch
          dataTestId="fld_subs_search"
          registerFieldName={FIELD_NAMES.TABLE_SUBSCRIPTIONS_FILTER_SEARCH}
          register={register}
          isFilled={isSearchFilled}
          onClearInput={clearSearchInput}
          placeholder="Название, телефон, email"
        />
      </div>

      <div className={classes.filterAutocomplete}>
        <Controller
          control={control}
          name={FIELD_NAMES.SUBSCRIPTION_SERVICE_KIND}
          render={(renderProps) => {
            const { value, onChange } = renderProps.field;

            return (
              <MTSAutocomplete
                dataTestId="fld_autocomplete_service_kind"
                required
                size="m"
                label="Тип доступа"
                placeholder="Выберите"
                value={value}
                onChangeValue={onChange}
                options={serviceKinds || []}
                loading={isLoadingServiceKinds}
              />
            );
          }}
        />
      </div>

      <div className={classes.filterAutocomplete}>
        <Controller
          control={control}
          name={FIELD_NAMES.SUBSCRIPTION_DURATION}
          render={(renderProps) => {
            const { value, onChange } = renderProps.field;

            return (
              <MTSAutocomplete
                dataTestId="fld_autocomplete_duration"
                required
                size="m"
                label="Срок действия"
                placeholder="Выберите"
                value={value}
                onChangeValue={onChange}
                options={
                  durations
                    ? isTestSubscriptionUsed
                      ? durations
                      : durationsWithOutTest
                    : []
                }
                loading={isLoadingDurations || isLoadingTestSubscriptionStatus}
              />
            );
          }}
        />
      </div>
    </form>
  );
};
