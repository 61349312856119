import {
  ISubscriptionsByStatuses,
  ISubscriptionStruct,
  IDictionaryDTO,
} from "interfaces";
import { getDictionaryItemCodeByName } from "utils/get-dictionary-item-code-by-name";
import { isSubscriptionReadyToRenew } from "utils/is-subscription-ready-to-renew.ts";
import { SubscriptionStatusCheckerByCode } from "utils/subscription-status-checker-by-code";

interface IGetSubscriptionsByStatusesProps {
  subscriptions: ISubscriptionStruct[];
  durations: IDictionaryDTO[] | undefined;
}

export const getSubscriptionsByStatuses = ({
  subscriptions,
  durations,
}: IGetSubscriptionsByStatusesProps): ISubscriptionsByStatuses => {
  const subscriptionsByStatuses: ISubscriptionsByStatuses = {
    all: [],
    activated: [],
    paid: [],
    renew: [],
    expired: [],
    canRenew: [],
  };

  if (subscriptions?.length && durations?.length) {
    const subsAllData: ISubscriptionStruct[] = subscriptions;
    const subsActivatedData: ISubscriptionStruct[] = [];
    const subsPaidData: ISubscriptionStruct[] = [];
    const subsRenewData: ISubscriptionStruct[] = [];
    const subsExpiredData: ISubscriptionStruct[] = [];

    subscriptions.forEach((subscription: ISubscriptionStruct) => {
      const {
        status: statusCode,
        expiredDate,
        duration: durationName,
        inProlongation,
      } = subscription;
      const durationCode: string =
        getDictionaryItemCodeByName(durationName, durations) ?? "";

      const isSubscriptionReadyForRenew: boolean = isSubscriptionReadyToRenew({
        statusCode,
        expiredDate,
        durationCode,
        inProlongation,
      });

      if (SubscriptionStatusCheckerByCode.isActivated(statusCode)) {
        subsActivatedData.push(subscription);
      } else if (SubscriptionStatusCheckerByCode.isPaid(statusCode)) {
        subsPaidData.push(subscription);
      } else if (SubscriptionStatusCheckerByCode.isExpired(statusCode)) {
        subsExpiredData.push(subscription);
      }

      if (isSubscriptionReadyForRenew) {
        subsRenewData.push(subscription);
      }
    });

    subscriptionsByStatuses.all = subsAllData;
    subscriptionsByStatuses.activated = subsActivatedData;
    subscriptionsByStatuses.paid = subsPaidData;
    subscriptionsByStatuses.renew = subsRenewData;
    subscriptionsByStatuses.expired = subsExpiredData;
    subscriptionsByStatuses.canRenew = subsRenewData.filter(
      (subscriptionData: ISubscriptionStruct) =>
        subscriptionData?.inProlongation === false
    );
  }

  return subscriptionsByStatuses;
};
