import { SUBSCRIPTION_SERVICE_KINDS } from "constants/keys";

import { AppHelmet } from "components/app-helmet";
import { Loader } from "components/loader";
import {
  GAP_EVENT_TYPE,
  GA_SCREEN_NAME,
  useGoogleAnalyticsPages,
} from "hooks/google-analytics";
import { useServiceKinds } from "hooks/use-service-kinds";
import { useSubscriptionInfoCommon } from "hooks/use-subscription-info-common";
import { getDateFormatted, IDateFormatted } from "utils/get-date-formatted";
import { getDictionaryItemNameByCode } from "utils/get-dictionary-item-name-by-code";
import { getPhoneNumbeWithMask } from "utils/get-phone-number-with-mask";

import { DevicePasswordInfo } from "./components/device-password-info";
import classes from "./personal-subscription-info.module.css";

export const PersonalSubscriptionInfo = (): JSX.Element => {
  useGoogleAnalyticsPages({
    screenName: GA_SCREEN_NAME.sub_info_personal,
    event: GAP_EVENT_TYPE.SCRN,
  });

  const { data: serviceKinds, isLoading: isLoadingServiceKinds } =
    useServiceKinds();
  const { data: subscriptionInfo, isLoading: isLoadingSubscriptionInfo } =
    useSubscriptionInfoCommon();

  const deviceId: number | undefined = subscriptionInfo?.device?.id;
  const deviceLogin: string = Number.isInteger(deviceId)
    ? String(deviceId)
    : "";
  const devicePassword: string = subscriptionInfo?.device?.password ?? "";
  const adminPhoneNumber: string = getPhoneNumbeWithMask(
    subscriptionInfo?.createdBy?.login
  );
  const serviceKindName: string = getDictionaryItemNameByCode(
    subscriptionInfo?.serviceKind,
    serviceKinds
  );
  const expiredOnDate: IDateFormatted = getDateFormatted(
    subscriptionInfo?.expiredOn ?? ""
  );
  const isShowLoginAndPassword: boolean =
    subscriptionInfo?.serviceKind === SUBSCRIPTION_SERVICE_KINDS.RTK_RINEX;

  const adminFullName: string = subscriptionInfo?.createdBy?.fio ?? "";

  if (isLoadingSubscriptionInfo || isLoadingServiceKinds) {
    return <Loader />;
  }

  return (
    <>
      <AppHelmet title="Данные о подписке - Личный кабинет" />
      <div className={classes.block}>
        {isShowLoginAndPassword && (
          <>
            <div className={classes.infoItem}>
              <div className={classes.infoItemName}>
                <p className={classes.infoItemNameText}>Логин:</p>
              </div>
              <div className={classes.infoItemDescription}>
                <p className={classes.infoItemDescriptionText}>{deviceLogin}</p>
              </div>
            </div>
            <DevicePasswordInfo
              deviceLogin={deviceLogin}
              devicePassword={devicePassword}
              isLoadingDevicePassword={isLoadingSubscriptionInfo}
            />
          </>
        )}
        <div className={classes.infoItem}>
          <div className={classes.infoItemName}>
            <p className={classes.infoItemNameText}>Срок действия:</p>
          </div>
          <div className={classes.infoItemDescription}>
            <p className={classes.infoItemDescriptionText}>
              {expiredOnDate.date && expiredOnDate.time
                ? `до ${expiredOnDate.date}, ${expiredOnDate.time}`
                : "-"}
            </p>
          </div>
        </div>
        <div className={classes.infoItem}>
          <div className={classes.infoItemName}>
            <p className={classes.infoItemNameText}>Тип доступа:</p>
          </div>
          <div className={classes.infoItemDescription}>
            <p className={classes.infoItemDescriptionText}>
              {serviceKindName ? serviceKindName : "-"}
            </p>
          </div>
        </div>
        <div className={classes.infoItem}>
          <div className={classes.infoItemName}>
            <p className={classes.infoItemNameText}>Администратор:</p>
          </div>
          <div className={classes.infoItemDescription}>
            <p className={classes.infoItemDescriptionText}>
              {!adminFullName && !adminPhoneNumber && "-"}
              {!adminFullName && adminPhoneNumber && `${adminPhoneNumber}`}
              {adminFullName &&
                adminPhoneNumber &&
                `${adminFullName}, ${adminPhoneNumber}`}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
