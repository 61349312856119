import { HTTPStatusCode } from "constants/keys";

import { useMutation } from "react-query";

import { AxiosError } from "axios";
import { useNotifications } from "hooks/use-notifications";
import { IPayGroupDTOPayload } from "interfaces";
import { getPaymentCalculation } from "services/api";

export const useMutationPaymentCalculations = () => {
  const { addErrorMessage } = useNotifications();

  return useMutation(
    (payload: IPayGroupDTOPayload[]) => {
      return getPaymentCalculation(payload);
    },
    {
      onError: (error: AxiosError) => {
        const status = error.response?.status;

        if (status !== HTTPStatusCode.UNAUTHORIZED_401) {
          addErrorMessage({ message: "Не удалось рассчитать стоимость" });
        }
      },
    }
  );
};
