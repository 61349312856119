export const getPhoneNumberWithoutMask = (
  phoneNumber: string | undefined
): string => {
  let phoneNumberWithoutMask = "";

  if (phoneNumber) {
    const parsedPhoneNumber = phoneNumber.replace(/\D/g, "").substring(1);

    if (parsedPhoneNumber.length === 10) {
      phoneNumberWithoutMask = parsedPhoneNumber;
    }
  }

  return phoneNumberWithoutMask;
};
