import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const Plug = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30 6.66669C28.159 6.66669 26.6667 8.15907 26.6667 10V16.6667C26.6667 18.5076 28.159 20 30 20C31.841 20 33.3333 18.5076 33.3333 16.6667V10C33.3333 8.15907 31.841 6.66669 30 6.66669ZM26.6667 30H53.3333V36.6667C53.3333 44.0305 47.3638 50 40 50C32.6362 50 26.6667 44.0305 26.6667 36.6667V30ZM43.3333 56.3902C52.7924 54.8032 60 46.5767 60 36.6667V23.3334H20V36.6667C20 46.5767 27.2076 54.8032 36.6667 56.3902V73.3334C36.6667 75.1743 38.159 76.6667 40 76.6667C41.841 76.6667 43.3333 75.1743 43.3333 73.3334V56.3902ZM46.6667 10C46.6667 8.15907 48.159 6.66669 50 6.66669C51.841 6.66669 53.3333 8.15907 53.3333 10V16.6667C53.3333 18.5076 51.841 20 50 20C48.159 20 46.6667 18.5076 46.6667 16.6667V10Z"
      />
    </SvgIcon>
  );
};

export default Plug;
