import React from "react";

import classes from "./page-title.module.css";

interface IPageTitleProps {
  text: string;
  sx?: React.CSSProperties;
  dataTestId?: string | null;
}

export const PageTitle = React.memo((props: IPageTitleProps): JSX.Element => {
  const { text, sx, dataTestId = null } = props;

  return (
    <h2 className={classes.pageTitle} style={sx} data-testid={dataTestId || ""}>
      {text}
    </h2>
  );
});
