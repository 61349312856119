export enum Variant {
  primary = "primary",
  primary_alternate = "primary_alternate",
  always_white = "always_white",
  secondary = "secondary",
  ghost = "ghost",
  negative = "negative",
}

export enum Size {
  S = "S",
  M = "M",
  L = "L",
  XL = "XL",
}
