import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const Success = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x="2" y="2" width="20" height="20" rx="10" />
      <path
        d="M14.9115 9.29694C15.1986 9.01218 15.6642 9.01218 15.9513 9.29694C16.2385 9.5817 16.2385 10.0434 15.9513 10.3281L11.5396 14.7031C11.2524 14.9879 10.7869 14.9879 10.4997 14.7031L8.04874 12.2726C7.76159 11.9878 7.76159 11.5261 8.04874 11.2414C8.33589 10.9566 8.80145 10.9566 9.0886 11.2414L11.0196 13.1563L14.9115 9.29694Z"
        fill="white"
      />
    </SvgIcon>
  );
};

export default Success;
