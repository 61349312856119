import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const Show = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M7.99979 6.00049C6.89522 6.00049 5.99979 6.89592 5.99979 8.00049C5.99979 9.10506 6.89522 10.0005 7.99979 10.0005C9.10436 10.0005 9.99979 9.10506 9.99979 8.00049C9.99979 6.89592 9.10436 6.00049 7.99979 6.00049Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.82481 4.91305C5.08535 3.98154 5.71563 3.51579 7.29484 3.32822C7.60293 3.29163 8.40205 3.29163 8.71013 3.32822C10.2893 3.51579 10.9196 3.98154 12.1802 4.91305C12.8436 5.40331 13.4478 5.93737 13.9503 6.42133C14.6508 7.09589 15.0011 7.43317 15.0011 8.00024C15.0011 8.5673 14.6508 8.90459 13.9503 9.57915C13.4478 10.0631 12.8436 10.5972 12.1802 11.0874C10.9196 12.0189 10.2893 12.4847 8.71013 12.6723C8.40205 12.7088 7.60292 12.7088 7.29484 12.6723C5.71563 12.4847 5.08535 12.0189 3.82481 11.0874C3.16137 10.5972 2.5572 10.0631 2.05464 9.57915C1.35415 8.90459 1.00391 8.5673 1.00391 8.00024C1.00391 7.43317 1.35415 7.09589 2.05464 6.42133C2.5572 5.93737 3.16137 5.40331 3.82481 4.91305ZM11.2887 6.11941C11.8864 6.56111 12.4405 7.04978 12.9099 7.50179C13.0903 7.67552 13.2235 7.8041 13.3336 7.9175C13.3636 7.94842 13.3895 7.97587 13.412 8.00024C13.3895 8.0246 13.3636 8.05205 13.3336 8.08297C13.2235 8.19637 13.0903 8.32495 12.9099 8.49868C12.4405 8.95069 11.8864 9.43936 11.2887 9.88107C10.6306 10.3674 10.2673 10.6304 9.89372 10.8172C9.56524 10.9815 9.18651 11.1051 8.53322 11.1827C8.34267 11.2054 7.66231 11.2054 7.47175 11.1827C6.81847 11.1051 6.43974 10.9815 6.11126 10.8172C5.73771 10.6304 5.37437 10.3674 4.71627 9.88107C4.11854 9.43936 3.5645 8.9507 3.09512 8.49868C2.91471 8.32495 2.78144 8.19637 2.67139 8.08297C2.64138 8.05205 2.61543 8.02461 2.59296 8.00024C2.61543 7.97587 2.64138 7.94842 2.67139 7.9175C2.78144 7.8041 2.91471 7.67552 3.09512 7.50179C3.5645 7.04978 4.11854 6.56111 4.71627 6.11941C5.37437 5.63309 5.73771 5.37005 6.11126 5.18324C6.43974 5.01897 6.81847 4.89535 7.47176 4.81776C7.66231 4.79512 8.34267 4.79512 8.53322 4.81776C9.18651 4.89535 9.56523 5.01897 9.89372 5.18324C10.2673 5.37005 10.6306 5.63309 11.2887 6.11941Z"
      />
    </SvgIcon>
  );
};

export default Show;
