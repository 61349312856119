import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const VRS = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="32" height="32" rx="8" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.1146 13.6602C19.6688 13.3008 20.0355 12.6767 20.0355 11.9669C20.0355 10.8531 19.1326 9.9502 18.0189 9.9502C16.9051 9.9502 16.0022 10.8531 16.0022 11.9669C16.0022 12.2614 16.0653 12.5411 16.1788 12.7933L12.5066 15.7311C12.1695 15.478 11.7506 15.328 11.2967 15.328C10.1829 15.328 9.28003 16.2309 9.28003 17.3447C9.28003 18.4585 10.1829 19.3614 11.2967 19.3614C12.0378 19.3614 12.6855 18.9616 13.036 18.366L18.6918 19.982C18.6913 19.9992 18.6911 20.0165 18.6911 20.0338C18.6911 21.1476 19.594 22.0505 20.7078 22.0505C21.8216 22.0505 22.7244 21.1476 22.7244 20.0338C22.7244 18.9201 21.8216 18.0172 20.7078 18.0172C20.6609 18.0172 20.6144 18.0188 20.5684 18.0219L19.1146 13.6602ZM18.1579 13.9788C18.112 13.9819 18.0656 13.9835 18.0189 13.9835C17.5649 13.9835 17.146 13.8336 16.809 13.5805L13.1368 16.5182C13.2502 16.7704 13.3134 17.0502 13.3134 17.3447C13.3134 17.362 13.3131 17.3792 13.3127 17.3964L18.9686 19.0124C19.1279 18.7416 19.3487 18.5113 19.6118 18.3407L18.1579 13.9788Z"
        fill="white"
      />
    </SvgIcon>
  );
};

export default VRS;
