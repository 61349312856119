import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const OkRound = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M16.5299 8.70867C16.3529 8.51134 16.1146 8.40002 15.865 8.40002C15.6154 8.40002 15.3771 8.50881 15.2002 8.70867L11.2678 13.0701L10.0084 11.656C9.64532 11.2512 9.04853 11.2461 8.68095 11.6484C8.31107 12.0506 8.30654 12.716 8.6696 13.1309L10.5938 15.2914C10.7708 15.4887 11.0091 15.6 11.261 15.6C11.5106 15.6 11.7465 15.4912 11.9236 15.2939L16.5255 10.1937C16.8886 9.78892 16.8931 9.12356 16.5323 8.71374L16.5299 8.70867Z" />
      <path d="M23.0568 7.33384C22.4511 5.90492 21.5846 4.62195 20.4835 3.51853C19.3799 2.41751 18.0969 1.55104 16.6678 0.945469C15.1885 0.318366 13.6182 0 12 0C5.38348 0.00239352 0 5.38561 0 12.0011C0 18.6166 5.38348 24 12 24C18.6165 24 24 18.617 24 12.0011C24 10.383 23.684 8.81288 23.0569 7.33371L23.0568 7.33384ZM12 22.0157C6.47781 22.0157 1.98465 17.523 1.98465 12.0012C1.98465 6.4795 6.47781 1.98675 12 1.98675C17.5223 1.98675 22.0154 6.4795 22.0154 12.0012C22.0154 17.523 17.5223 22.0157 12 22.0157Z" />
    </SvgIcon>
  );
};

export default OkRound;
