import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const Right = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_126990_18389)">
        <path d="M6.16409 3.75196C6.16409 3.08338 6.97195 2.74855 7.44442 3.22131L9.2031 4.98106C10.6253 6.40416 11.3364 7.11571 11.3364 7.9999C11.3364 8.8841 10.6253 9.59565 9.20309 11.0187L7.44442 12.7785C6.97195 13.2513 6.16409 12.9164 6.16409 12.2478C6.16409 12.0488 6.24311 11.8579 6.38376 11.7172L8.14243 9.95743C8.88353 9.21588 9.3304 8.76423 9.61056 8.39681C9.73987 8.22723 9.79497 8.12363 9.81865 8.0668C9.83655 8.02386 9.83647 8.0087 9.83643 8.00091L9.83643 7.9999L9.83643 7.9989C9.83647 7.99111 9.83655 7.97594 9.81865 7.93301C9.79497 7.87617 9.73987 7.77258 9.61056 7.603C9.3304 7.23558 8.88353 6.78393 8.14244 6.04237L6.38376 4.28262C6.24311 4.14188 6.16409 3.951 6.16409 3.75196Z" />
      </g>
      <defs>
        <clipPath id="clip0_126990_18389">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default Right;
