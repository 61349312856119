import { SUBSCRIPTION_SERVICE_KINDS } from "constants/keys";

import { usePayGroups } from "hooks/use-pay-groups";
import { useServiceKinds } from "hooks/use-service-kinds";
import { IDictionaryDTO, IPayGroupDTO } from "interfaces";

import { PaymentCardPayGroup } from "./components/payment-card-pay-group";
import classes from "./payment-card-pay-groups.module.css";

interface IPaymentCardPayGroupsProps {
  payGroups: IPayGroupDTO[];
}

export const PaymentCardPayGroups = (
  props: IPaymentCardPayGroupsProps
): JSX.Element | null => {
  const { payGroups } = props;

  const { newPayGroupsRINEX, newPayGroupsRTKRINEX } = usePayGroups(payGroups);
  const { data: serviceKinds, isLoading: isLoadingServiceKinds } =
    useServiceKinds();

  if (payGroups?.length === 0 || isLoadingServiceKinds) {
    return null;
  }

  return (
    <ul className={classes.groups}>
      {serviceKinds?.map((serviceKind: IDictionaryDTO, index: number) => {
        let payGroup = null;

        if (serviceKind.code === SUBSCRIPTION_SERVICE_KINDS.RTK_RINEX) {
          payGroup = newPayGroupsRTKRINEX;
        } else if (serviceKind.code === SUBSCRIPTION_SERVICE_KINDS.RINEX) {
          payGroup = newPayGroupsRINEX;
        }

        return (
          <PaymentCardPayGroup
            title={serviceKind.name}
            payGroup={payGroup}
            key={index}
          />
        );
      })}
    </ul>
  );
};
