import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export const AccuracyIcon = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon
      width="96"
      height="96"
      viewBox="0 0 96 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20 23V73"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M76 23V73"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M25.2929 54.2929C24.9024 54.6834 24.9024 55.3166 25.2929 55.7071L31.6569 62.0711C32.0474 62.4616 32.6805 62.4616 33.0711 62.0711C33.4616 61.6805 33.4616 61.0474 33.0711 60.6569L27.4142 55L33.0711 49.3431C33.4616 48.9526 33.4616 48.3195 33.0711 47.9289C32.6805 47.5384 32.0474 47.5384 31.6569 47.9289L25.2929 54.2929ZM70.7071 55.7071C71.0976 55.3166 71.0976 54.6834 70.7071 54.2929L64.3431 47.9289C63.9526 47.5384 63.3195 47.5384 62.9289 47.9289C62.5384 48.3195 62.5384 48.9526 62.9289 49.3431L68.5858 55L62.9289 60.6569C62.5384 61.0474 62.5384 61.6805 62.9289 62.0711C63.3195 62.4616 63.9526 62.4616 64.3431 62.0711L70.7071 55.7071ZM26 56H70V54H26V56Z"
        fill="black"
      />
      <path
        d="M39 40.0057C39 39.4668 39.0777 39.0076 39.233 38.6281C39.3884 38.2486 39.6214 37.9374 39.9321 37.6945C40.2428 37.4516 40.6354 37.277 41.1101 37.1708C41.5934 37.0569 42.1587 37 42.8059 37C43.8761 37 44.7175 37.1518 45.3303 37.4554C45.9517 37.7514 46.3141 38.2372 46.4177 38.9127H44.696C44.5924 38.6091 44.3982 38.389 44.1134 38.2524C43.8286 38.1157 43.3928 38.0474 42.8059 38.0474C42.0896 38.0474 41.5632 38.1651 41.2266 38.4004C40.8987 38.6357 40.7347 39.038 40.7347 39.6072V40.3814C40.7347 40.9734 40.9116 41.3833 41.2654 41.611C41.6193 41.8387 42.1328 41.9526 42.8059 41.9526C43.41 41.9526 43.8545 41.8805 44.1393 41.7362C44.4241 41.5844 44.6183 41.3719 44.7218 41.0987H46.4436C46.3228 41.7362 45.9646 42.2144 45.3691 42.5332C44.7823 42.8444 43.9279 43 42.8059 43C42.1759 43 41.6236 42.9507 41.1489 42.852C40.6743 42.7533 40.2773 42.5863 39.958 42.351C39.6386 42.1158 39.397 41.8083 39.233 41.4288C39.0777 41.0493 39 40.575 39 40.0057Z"
        fill="black"
      />
      <path
        d="M49.2975 38.2182V42.8634H47.757V37.148H50.3202L52.4173 41.3947L54.5274 37.148H57V42.8634H55.3042V38.241L53.1423 42.5901H51.4594L49.2975 38.2182Z"
        fill="black"
      />
    </SvgIcon>
  );
};
