import React from "react";

import { Tooltip } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import classNames from "classnames";
import { InputLabel } from "components/input-label";
import { InputMessage } from "components/input-message";
import { MTSButtonIcon } from "components/mts-button-icon";
import { MTSIcon } from "components/mts-icon";
import { MTSSpinner } from "components/mts-spinner";
import { IDictionaryDTO } from "interfaces";

import {
  IMTSAutocompleteSearchSize,
  IMTSAutocompleteSearchValue,
} from "./interfaces";
import classes from "./mts-autocomplete-search.module.css";
import { getDropdownItemHeight } from "./utils/get-dropdown-item-height";

interface IMTSAutocompleteSearchProps {
  value: IMTSAutocompleteSearchValue;
  onChangeValue: (newValue: IMTSAutocompleteSearchValue) => void;
  size: IMTSAutocompleteSearchSize;
  placeholder: string;
  label?: string;
  hideLabel?: boolean;
  labelTooltipText?: string;
  loading?: boolean;
  errorMessage?: string | undefined;
  disabled?: boolean;
  disabledTooltipText?: string;
  options: IDictionaryDTO[];
  disabledOptionCodes?: string[];
  required?: boolean;
  dataTestId?: string | null;
}

export const MTSAutocompleteSearch = React.memo(
  (props: IMTSAutocompleteSearchProps): JSX.Element => {
    const {
      value,
      onChangeValue,
      size,
      options = [],
      placeholder,
      label = "",
      hideLabel = false,
      loading = false,
      errorMessage,
      disabled = false,
      disabledTooltipText = "",
      disabledOptionCodes = [],
      labelTooltipText = "",
      required = false,
      dataTestId = null,
    } = props;

    const autocompleteRef = React.useRef<HTMLDivElement>(null);
    const [inputValue, setInputValue] = React.useState<string>("");
    const [isDropdownListOpen, setIsDropdownListOpen] =
      React.useState<boolean>(false);
    const [isInputFocused, setIsInputFocused] = React.useState<boolean>(false);
    const [isInputHovered, setIsInputHovered] = React.useState<boolean>(false);
    const isShowClearIcon: boolean =
      !disabled && !!inputValue && (isInputFocused || isInputHovered);
    const isError = !!errorMessage && !disabled;

    const inputWrapStyles = classNames({
      [classes.inputWrap]: true,
      [classes.inputWrapEffects]: !disabled,
      [classes.inputWrapSizeS]: size === "s",
      [classes.inputWrapSizeM]: size === "m",
      [classes.inputWrapSizeL]: size === "l",
      [classes.inputWrapSizeXL]: size === "xl",
      [classes.inputWrapError]: isError,
      [classes.inputWrapDisabed]: disabled,
    });

    const inputStyles = classNames({
      [classes.input]: true,
      [classes.inputSizeS]: size === "s",
      [classes.inputSizeM]: size === "m",
      [classes.inputSizeL]: size === "l",
      [classes.inputSizeXL]: size === "xl",
      [classes.inputSizeXLHidden]:
        size === "xl" && (inputValue?.length === 0 || !isInputFocused),
      [classes.inputSizeXLFocused]:
        size === "xl" && (isInputFocused || !!inputValue),
      [classes.inputDisabled]: disabled,
    });

    const labelSizeXLStyles = classNames({
      [classes.labelSizeXL]: true,
      [classes.labelSizeXLFocused]: isInputFocused || !!inputValue,
    });

    const dropdownItemHeight: number = React.useMemo(
      () => getDropdownItemHeight(size),
      [size]
    );

    const textNoOptionStyles = classNames({
      [classes.textNoOption]: true,
      [classes.textOptionSizeS]: size === "s",
      [classes.textOptionSizeM]: size === "m",
      [classes.textOptionSizeL]: size === "l",
      [classes.textOptionSizeXl]: size === "xl",
    });
    const textOptionStyles = classNames({
      [classes.textOption]: true,
      [classes.textOptionSizeS]: size === "s",
      [classes.textOptionSizeM]: size === "m",
      [classes.textOptionSizeL]: size === "l",
      [classes.textOptionSizeXl]: size === "xl",
    });

    React.useEffect(() => {
      const autocompleteRefObject = autocompleteRef.current;

      const mouseHovered = (): void => {
        if (autocompleteRefObject) {
          autocompleteRefObject.addEventListener("mouseenter", () => {
            setIsInputHovered(true);
          });
        }
      };

      const mouseLeave = (): void => {
        if (autocompleteRefObject) {
          autocompleteRefObject.addEventListener("mouseleave", () => {
            setIsInputHovered(false);
          });
        }
      };

      mouseHovered();
      mouseLeave();

      return () => {
        autocompleteRefObject?.removeEventListener("mouseenter", mouseHovered);
        autocompleteRefObject?.removeEventListener("mouseleave", mouseLeave);
      };
    }, []);

    return (
      <div
        className={classes.blockMTSAutocompleteSearch}
        data-testid={dataTestId}
      >
        {hideLabel || size === "xl" ? null : (
          <div className={classes.inputLabel}>
            <InputLabel
              label={label}
              isError={isError}
              isRequiredField={required}
              infoText={labelTooltipText}
              disabled={disabled}
            />
          </div>
        )}
        <Autocomplete
          id={`mts-autocomplete`}
          ref={autocompleteRef}
          open={isDropdownListOpen}
          clearText=""
          closeText=""
          openText=""
          disabled={disabled}
          sx={{
            display: "inline-flex",
            width: "100%",
          }}
          value={value}
          onChange={(event: any, newValue: IMTSAutocompleteSearchValue) => {
            onChangeValue(newValue);
          }}
          inputValue={inputValue}
          onInputChange={(_, newInputValue: string) => {
            setInputValue(newInputValue);
          }}
          options={options}
          noOptionsText={
            <p className={textNoOptionStyles}>
              {loading ? <MTSSpinner /> : "Ничего не найдено"}
            </p>
          }
          getOptionLabel={(option: IDictionaryDTO): string => {
            return option?.name ? option.name : "";
          }}
          isOptionEqualToValue={(
            option: IDictionaryDTO,
            incomingValue: IDictionaryDTO
          ): boolean => {
            return option.id === incomingValue.id;
          }}
          renderOption={(renderProps, option: IDictionaryDTO, { selected }) => {
            const customRenderProps = {
              ...renderProps,
              // eslint-disable-next-line no-useless-computed-key
              ["aria-selected"]: false,
            };

            if (options.length === 0) {
              return null;
            }

            return (
              <Box
                component="li"
                {...customRenderProps}
                sx={{
                  "&.MuiAutocomplete-option": {
                    padding: "10px 12px",
                  },
                  "&.MuiAutocomplete-option.Mui-focused": {
                    backgroundColor: "var(--color-background-secondary)",
                  },
                  "&.MuiAutocomplete-option.Mui-focusVisible": {
                    backgroundColor: "var(--color-background-secondary)",
                  },
                  minHeight: `${dropdownItemHeight}px`,
                }}
                key={option.id}
              >
                <p className={textOptionStyles}>{option.name}</p>
                <div
                  className={classes.optionCheckbox}
                  style={{ visibility: selected ? "visible" : "hidden" }}
                >
                  <MTSIcon.Check
                    sx={{
                      fontSize: 24,
                      color: "var(--color-icon-primary)",
                    }}
                  />
                </div>
              </Box>
            );
          }}
          getOptionDisabled={(option: IDictionaryDTO) => {
            return disabledOptionCodes.includes(option.code);
          }}
          onOpen={() => {
            setIsDropdownListOpen(true);
          }}
          onClose={() => {
            setIsDropdownListOpen(false);
          }}
          onFocus={() => {
            setIsInputFocused(true);
          }}
          onBlur={() => {
            setIsDropdownListOpen(false);
            setIsInputFocused(false);
          }}
          renderInput={(params) => (
            <div ref={params.InputProps.ref} className={inputWrapStyles}>
              <MTSIcon.Search
                data-testid="btn_st_search"
                sx={{
                  color: "var(--color-icon-tertiary)",
                  fontSize: "24px",
                  marginLeft: "10px",
                }}
              />
              {size === "xl" ? (
                <label className={classes.inputLabelWrapSizeXL}>
                  <span className={labelSizeXLStyles}>
                    <InputLabel
                      size="XL"
                      label={label}
                      isError={isError}
                      isRequiredField={required}
                      infoText={labelTooltipText}
                      disabled={disabled}
                    />
                  </span>
                  <input
                    type="text"
                    {...params.inputProps}
                    className={inputStyles}
                    placeholder={placeholder}
                  />
                </label>
              ) : (
                <input
                  type="text"
                  {...params.inputProps}
                  className={inputStyles}
                  placeholder={placeholder}
                />
              )}

              <div className={classes.buttons}>
                {disabled && !!disabledTooltipText ? (
                  <Tooltip
                    title={disabledTooltipText}
                    arrow
                    placement="top"
                    disableInteractive
                  >
                    <div className={classes.iconWrap}>
                      <MTSIcon.InfoCircleFill
                        sx={{
                          fontSize: 24,
                          color: "var(--color-icon-secondary)",
                        }}
                      />
                    </div>
                  </Tooltip>
                ) : null}
                {!isShowClearIcon && isError && (
                  <MTSIcon.WarningCircleFill
                    sx={{
                      fontSize: 24,
                      color: "var(--color-accent-negative)",
                    }}
                  />
                )}
                {isShowClearIcon && (
                  <MTSButtonIcon
                    mode="round"
                    size="S"
                    variant="ghost"
                    onClick={(e: React.SyntheticEvent): void => {
                      e.preventDefault();
                      setInputValue("");
                      onChangeValue(null);
                    }}
                    Icon={MTSIcon.BackspaceFill}
                    iconSize={24}
                    iconColor={"var(--color-icon-secondary)"}
                  />
                )}
              </div>
            </div>
          )}
          loading={loading}
        />
        {isError && (
          <div className={classes.inputError}>
            <InputMessage text={errorMessage || ""} mode="error" />
          </div>
        )}
      </div>
    );
  }
);
