import { BACKEND_ERROR_CODES, QueryKeys } from "constants/keys";

import { useMutation, useQueryClient } from "react-query";

import { AxiosError } from "axios";
import { useNotifications } from "hooks/use-notifications";
import { IUpdateSubscriptionInfoPayload } from "interfaces";
import { updateSubscriptionInfoByProfileService } from "services/api";

export const useMutationUpdateSubscriptionInfoByProfile = () => {
  const { addSuccessMessage, addErrorMessage } = useNotifications();
  const queryClient = useQueryClient();

  return useMutation(
    (payload: IUpdateSubscriptionInfoPayload) => {
      return updateSubscriptionInfoByProfileService(payload);
    },
    {
      onSuccess: async () => {
        addSuccessMessage({ message: "Данные сохранены" });

        await queryClient.invalidateQueries([QueryKeys.SUBSCRIPTION_INFO]);
        await queryClient.invalidateQueries([
          QueryKeys.BILLING_SUBSCRIPTIONS_BY_PAYMENT,
        ]);
        await queryClient.invalidateQueries([
          QueryKeys.USER_ACCESS_DOWNLOAD_RINEX,
        ]);
      },
      onError: (error: AxiosError) => {
        let errorMessage = "Не удалось сохранить данные";
        const data = error?.response?.data;
        const backendErrorCode = data?.code;

        if (
          backendErrorCode ===
            BACKEND_ERROR_CODES.CUSTOMER_BASE_ALREADY_EXISTS ||
          backendErrorCode ===
            BACKEND_ERROR_CODES.SUBSCRIPTION_CANNOT_BE_UPDATED
        ) {
          errorMessage =
            "Невозможно сохранить данные, пользователь с данным номером телефона уже существует";
        }

        addErrorMessage({ message: errorMessage });
      },
    }
  );
};
