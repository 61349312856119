import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  btn: {
    "&.MuiButton-root": {
      display: "flex",
      flex: "0 0 auto",
      transition: "all .3s ease",

      "&:hover": {
        transform: "scale(1.02)",
      },
      "&:focus": {
        transform: "scale(1.02)",
      },
      "&:active": {
        transform: "scale(0.98)",
      },
    },
  },
  text: {
    display: "flex",
    alignItems: "center",
    position: "relative",
    padding: "0px 8px",
  },
  textSizeS: {
    fontFamily: "var(--typography-v3-p4-medium-comp-font-family)",
    fontSize: "var(--typography-v3-p4-medium-comp-font-size)",
    lineHeight: "var(--typography-v3-p4-medium-comp-line-height)",
    fontWeight: "var(--typography-v3-p4-medium-comp-font-weight)",
  },
  textSizeM: {
    fontFamily: "var(--typography-v3-p3-medium-comp-font-family)",
    fontSize: "var(--typography-v3-p3-medium-comp-font-size)",
    lineHeight: "var(--typography-v3-p3-medium-comp-line-height)",
    fontWeight: "var(--typography-v3-p3-medium-comp-font-weight)",
  },
  textSizeL: {
    fontFamily: "var(--typography-v3-p3-medium-comp-font-family)",
    fontSize: "var(--typography-v3-p3-medium-comp-font-size)",
    lineHeight: "var(--typography-v3-p3-medium-comp-line-height)",
    fontWeight: "var(--typography-v3-p3-medium-comp-font-weight)",
  },
  textSizeXL: {
    fontFamily: "var(--typography-v3-h4-comp-font-family)",
    fontSize: "var(--typography-v3-h4-comp-font-size)",
    lineHeight: "var(--typography-v3-h4-comp-line-height)",
    fontWeight: "var(--typography-v3-h4-comp-font-weight)",
  },
  textHidden: {
    visibility: "hidden",
  },
  textNoWrap: {
    whiteSpace: "nowrap",
  },
  btnSizeS: {
    "&.MuiButton-root": {
      padding: "6px 8px",
      borderRadius: "6px",
    },
  },
  btnSizeM: {
    "&.MuiButton-root": {
      padding: "10px",
      borderRadius: "8px",
    },
  },
  btnSizeL: {
    "&.MuiButton-root": {
      padding: "14px",
      borderRadius: "8px",
    },
  },
  btnSizeXL: {
    "&.MuiButton-root": {
      padding: "24px",
      borderRadius: "12px",
    },
  },

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // PRIMARY
  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  btnPrimary: {
    "&.MuiButton-root": {
      backgroundColor: "var(--color-brand)",

      "&:hover": {
        backgroundColor: "var(--color-brand)",
      },
      "&:focus": {
        backgroundColor: "var(--color-brand)",
      },
      "&:active": {
        backgroundColor: "var(--color-brand)",
      },
    },
  },

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // PRIMARY ALTERNATE
  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  btnPrimaryAlternate: {
    "&.MuiButton-root": {
      backgroundColor: "var(--color-control-secondary-active)",

      "&:hover": {
        backgroundColor: "var(--color-control-secondary-active)",
      },
      "&:focus": {
        backgroundColor: "var(--color-control-secondary-active)",
      },
      "&:active": {
        backgroundColor: "var(--color-control-secondary-active)",
      },
    },
  },

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // ALWAYS WHITE
  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  btnAlwaysWhite: {
    "&.MuiButton-root": {
      backgroundColor: "#FFFFFF",

      "&:hover": {
        backgroundColor: "#FFFFFF",
      },
      "&:focus": {
        backgroundColor: "#FFFFFF",
      },
      "&:active": {
        backgroundColor: "#FFFFFF",
      },
    },
  },

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // SECONDARY
  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  btnSecondary: {
    "&.MuiButton-root": {
      backgroundColor: "#F2F3F7",

      "&:hover": {
        backgroundColor: "#F2F3F7",
      },
      "&:focus": {
        backgroundColor: "#F2F3F7",
      },
      "&:active": {
        backgroundColor: "#F2F3F7",
      },
    },
  },

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // GHOST
  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  btnGhost: {
    "&.MuiButton-root": {
      backgroundColor: "transparent",
      "&:hover": {
        backgroundColor: "transparent",
      },
      "&:focus": {
        backgroundColor: "transparent",
      },
      "&:active": {
        backgroundColor: "transparent",
      },
    },
  },

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // NEGATIVE
  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  btnNegative: {
    "&.MuiButton-root": {
      backgroundColor: "#F2F3F7",

      "&:hover": {
        backgroundColor: "#F2F3F7",
      },
      "&:focus": {
        backgroundColor: "#F2F3F7",
      },
      "&:active": {
        backgroundColor: "#F2F3F7",
      },
    },
  },

  //
  btnDisabled: {
    "&.MuiButton-root": {
      backgroundColor: "var(--color-control-inactive)",

      "&:hover": {
        backgroundColor: "var(--color-control-inactive)",
        transform: "scale(1)",
      },
      "&:focus": {
        backgroundColor: "var(--color-control-inactive)",
        transform: "scale(1)",
      },
      "&:active": {
        backgroundColor: "var(--color-control-inactive)",
        transform: "scale(1)",
      },
    },
  },
  spinnerBlock: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));
