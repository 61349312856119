import { FIELD_NAMES, REQUIRED_FIELD_MESSAGE } from "constants/keys";

import { useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import { fieldSchema } from "utils/yup-schema";
import * as yup from "yup";

import { ISubscriptionInfoFormStruct } from "../interfaces";

interface IUseSubsciptionInfoFormProps {
  initialFormData: ISubscriptionInfoFormStruct;
  isServiceKindRTK: boolean;
}

const schema = {
  [FIELD_NAMES.SUBSCRIPTION_NAME]: yup
    .string()
    .min(2, "Не менее 2 символов")
    .max(56, "Не более 56 символов")
    .trim()
    .required(REQUIRED_FIELD_MESSAGE),
  [FIELD_NAMES.SUBSCRIPTION_DESCRIPTION]: yup
    .string()
    .trim()
    .max(255, "Не более 255 символов"),
  [FIELD_NAMES.SUBSCRIPTION_SERVICE_KIND]: yup
    .mixed()
    .required(REQUIRED_FIELD_MESSAGE),
  [FIELD_NAMES.SUBSCRIPTION_DURATION]: yup
    .mixed()
    .required(REQUIRED_FIELD_MESSAGE),
  [FIELD_NAMES.SUBSCRIPTION_PHONE]: fieldSchema.required.phone,
  [FIELD_NAMES.SUBSCRIPTION_EMAIL]: fieldSchema.required.email,
};

const schemaRINEX = yup.object({
  ...schema,
});

const schemaRTK = yup.object({
  ...schema,
  [FIELD_NAMES.SUBSCRIPTION_DEVICE_PASSWORD]:
    fieldSchema.required.devicePassword,
});

export const useSubscriptionInfoForm = (
  props: IUseSubsciptionInfoFormProps
) => {
  const { initialFormData, isServiceKindRTK } = props;

  return useForm<ISubscriptionInfoFormStruct>({
    mode: "onChange",
    defaultValues: initialFormData,
    shouldFocusError: false,
    resolver: yupResolver(isServiceKindRTK ? schemaRTK : schemaRINEX),
  });
};
