import { PATH_TARIFFS } from "constants/routes";

import React from "react";

import { useHistory } from "react-router";

import { MTSButton } from "components/mts-button";

export const ButtonBecomeClient = (): JSX.Element => {
  const history = useHistory();

  const handleRedirectBecomeClient = (): void => {
    history.push(PATH_TARIFFS);
  };

  return (
    <MTSButton
      size="M"
      fullWidth
      sx={{
        maxWidth: "271px",
        "@media (max-width: 500px)": {
          maxWidth: "97%",
        },
      }}
      onClick={handleRedirectBecomeClient}
    >
      Стать клиентом
    </MTSButton>
  );
};
