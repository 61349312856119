import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const RubleCircle = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9467 12.1568C14.492 12.4337 14.1102 12.8155 13.8333 13.2702C13.347 14.0688 13.347 15.1543 13.347 17.3252V18.3538C13.3459 18.375 13.3454 18.3963 13.3454 18.4177L13.3454 20.0112L12.9287 20.0111C12.2383 20.0111 11.6787 20.5708 11.6787 21.2611C11.6787 21.9515 12.2383 22.5111 12.9287 22.5111L13.3454 22.5112L13.3454 24.1697H12.9771C12.2868 24.1697 11.7271 24.7293 11.7271 25.4197C11.7271 26.11 12.2868 26.6697 12.9771 26.6697H13.3454L13.3454 27.9302C13.3454 28.6206 13.905 29.1802 14.5954 29.1802C15.2857 29.1802 15.8454 28.6206 15.8454 27.9302L15.8454 26.6697H22.9328C23.6231 26.6697 24.1828 26.11 24.1828 25.4197C24.1828 24.7293 23.6231 24.1697 22.9328 24.1697H15.8454L15.8454 22.5168L21.4131 22.5168C21.8742 22.5168 22.32 22.5068 22.7463 22.4893C25.7968 22.3644 28.3269 20.3782 28.3269 17.3251C28.3269 14.0386 25.5878 11.7732 22.3025 11.6828C22.0134 11.6749 21.7166 11.6705 21.4131 11.6705H19.0016C16.8308 11.6705 15.7453 11.6705 14.9467 12.1568ZM18.4703 20.0112C18.5107 20.0112 18.5507 20.0131 18.5901 20.0168L21.4131 20.0168C21.8379 20.0168 22.2495 20.0076 22.644 19.9914C24.7555 19.905 25.8269 18.6695 25.8269 17.3251C25.8269 15.6846 24.4779 14.2436 22.2337 14.1819C21.9666 14.1745 21.6927 14.1705 21.4131 14.1705H19.0016C17.868 14.1705 17.1925 14.1731 16.695 14.2202C16.3963 14.2484 16.2722 14.2851 16.2377 14.2978C16.1311 14.3646 16.0411 14.4546 15.9743 14.5611C15.9616 14.5956 15.9249 14.7197 15.8967 15.0185C15.8496 15.516 15.847 16.1916 15.847 17.3252V20.0112L18.4703 20.0112ZM15.9788 14.5505L15.9782 14.5524L15.9753 14.5584C15.9775 14.5527 15.9788 14.5503 15.9788 14.5505Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.80912 7.81581C5.621 10.0039 5.45876 12.1839 5.13426 16.5439C5.05015 17.6741 4.99918 18.84 4.99918 20.0059C4.99918 21.1717 5.05015 22.3376 5.13426 23.4678C5.45876 27.8278 5.621 30.0078 7.80912 32.1959C9.99725 34.384 12.1772 34.5463 16.5372 34.8708C17.6674 34.9549 18.8333 35.0059 19.9992 35.0059C21.1651 35.0059 22.3309 34.9549 23.4611 34.8708C27.8211 34.5463 30.0011 34.384 32.1892 32.1959C34.3774 30.0078 34.5396 27.8278 34.8641 23.4678C34.9482 22.3376 34.9992 21.1717 34.9992 20.0059C34.9992 18.84 34.9482 17.6741 34.8641 16.5439C34.5396 12.1839 34.3774 10.0039 32.1892 7.81581C30.0011 5.62768 27.8211 5.46544 23.4611 5.14095C22.3309 5.05683 21.1651 5.00586 19.9992 5.00586C18.8333 5.00586 17.6674 5.05683 16.5372 5.14095C12.1772 5.46544 9.99725 5.62769 7.80912 7.81581ZM19.9992 8.33919C18.9288 8.33919 17.8465 8.38605 16.7846 8.46509C12.2007 8.80625 11.3431 8.99583 10.1661 10.1728C8.98915 11.3498 8.79956 12.2074 8.4584 16.7913C8.37937 17.8532 8.33251 18.9355 8.33251 20.0059C8.33251 21.0762 8.37937 22.1585 8.4584 23.2204C8.79956 27.8044 8.98915 28.6619 10.1661 29.8389C11.3431 31.0159 12.2007 31.2055 16.7846 31.5466C17.8465 31.6257 18.9288 31.6725 19.9992 31.6725C21.0695 31.6725 22.1518 31.6257 23.2137 31.5466C27.7977 31.2055 28.6552 31.0159 29.8322 29.8389C31.0092 28.6619 31.1988 27.8044 31.5399 23.2204C31.619 22.1585 31.6658 21.0762 31.6658 20.0059C31.6658 18.9355 31.619 17.8532 31.5399 16.7913C31.1988 12.2074 31.0092 11.3498 29.8322 10.1728C28.6552 8.99583 27.7977 8.80625 23.2137 8.46509C22.1518 8.38605 21.0695 8.33919 19.9992 8.33919Z"
      />
    </SvgIcon>
  );
};

export default RubleCircle;
