import React from "react";

import classNames from "classnames";

import classes from "./input-message.module.css";

interface IInputMessageProps {
  text: string;
  mode: "error" | "description";
}

export const InputMessage = React.memo((props: IInputMessageProps) => {
  const { text, mode } = props;

  const textStyles = classNames({
    [classes.text]: true,
    [classes.textError]: mode === "error",
    [classes.textDescription]: mode === "description",
  });

  return <span className={textStyles}>{text}</span>;
});
