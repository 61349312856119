import { NOTIFICATION_MODE } from "constants/keys";

import React from "react";

import ReactDOM from "react-dom";

import { MTSIcon } from "components/mts-icon";
import { useContextMain } from "hooks/use-context-main";

import classes from "./notification-bar.module.css";

const useNotificationBarPortal = () => {
  const [componentDidMounted, setComponentDidMounted] =
    React.useState<boolean>(false);
  const [portalId] = React.useState<string>("app-notification-bar-element");

  React.useEffect(() => {
    const bodyElem = document.getElementsByTagName("body")[0];
    const div = document.createElement("div");

    if (bodyElem) {
      div.id = portalId;
      bodyElem.prepend(div);
      setComponentDidMounted(true);
    }

    return () => {
      setComponentDidMounted(false);
    };
  }, [portalId]);

  return { componentDidMounted, portalId };
};

export const NotificationBar = () => {
  const { notifications, setNotifications } = useContextMain();

  const { portalId, componentDidMounted } = useNotificationBarPortal();

  const [notificationIdForDeleting, setNotificationIdForDeleting] =
    React.useState<string>("");

  React.useEffect(() => {
    if (notificationIdForDeleting) {
      setNotifications((n: any) =>
        n.filter((_n: any) => _n.id !== notificationIdForDeleting)
      );
    }
  }, [notificationIdForDeleting, setNotifications]);

  React.useEffect(() => {
    if (notifications.length) {
      const notificationId = notifications[notifications.length - 1].id;

      setTimeout(() => {
        setNotificationIdForDeleting(notificationId);
      }, 5000);
    }
  }, [notifications]);

  if (componentDidMounted) {
    return ReactDOM.createPortal(
      <ul className={classes.list}>
        {notifications.map((notification) => {
          const notificationMode: string = notification.mode;
          const title: string | JSX.Element = notification?.title ?? "";
          const message: string | JSX.Element = notification.message;

          return (
            <li className={classes.listRow} key={notification.id}>
              <div className={classes.toast}>
                <div className={classes.toastIcon}>
                  {notificationMode === NOTIFICATION_MODE.SUCCESS && (
                    <MTSIcon.DoneRectangleSmooth
                      sx={{
                        fontSize: 24,
                        color: "var(--color-accent-positive)",
                      }}
                    />
                  )}
                  {notificationMode === NOTIFICATION_MODE.INFO && (
                    <MTSIcon.InfoCircleFill
                      sx={{
                        fontSize: 24,
                        color: "var(--color-constant-blueberry-light)",
                      }}
                    />
                  )}
                  {notificationMode === NOTIFICATION_MODE.WARNING && (
                    <MTSIcon.WarningRectangleSmooth
                      sx={{
                        fontSize: 24,
                        color: "var(--color-accent-warning)",
                      }}
                    />
                  )}
                  {notificationMode === NOTIFICATION_MODE.ERROR && (
                    <MTSIcon.ErrorRectangleSmooth
                      sx={{
                        fontSize: 24,
                        color: "var(--color-accent-negative)",
                      }}
                    />
                  )}
                </div>
                <div className={classes.toastContent}>
                  {title ? (
                    <>
                      <span className={classes.toastContentTitle}>{title}</span>
                      <span className={classes.toastContentText}>
                        {message}
                      </span>
                    </>
                  ) : (
                    <span className={classes.toastContentText}>{message}</span>
                  )}
                </div>
              </div>
            </li>
          );
        })}
      </ul>,
      document.getElementById(portalId) as HTMLDivElement
    );
  }

  return null;
};
