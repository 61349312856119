import { FIELD_NAMES } from "constants/keys";

import React from "react";

import { Controller, useFieldArray, useFormContext } from "react-hook-form";

import Collapse from "@mui/material/Collapse";
import Fade from "@mui/material/Fade";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import { MTSAutocomplete } from "components/mts-autocomplete";
import { MTSButton } from "components/mts-button";
import { MTSButtonIcon } from "components/mts-button-icon";
import { MTSIcon } from "components/mts-icon";
import { MTSInput } from "components/mts-input";
import {
  MAX_TOTAL_SUB_COUNT,
  FormPaymentConfigurator,
  InitialFormState,
  TOTAL_SUB_COUNT_EXCEEDS_ERROR_MEESAGE,
} from "components/payment-configurator/constants";
import { useDurations } from "hooks/use-durations";
import { useRegions } from "hooks/use-regions";
import { useServiceKinds } from "hooks/use-service-kinds";
import { IPayGroupDTO } from "interfaces";
import { mockData } from "services/mock-data";
import { getDurationsWithOutTest } from "utils/get-durations-with-out-test";
import { getExpandedRegionOptions } from "utils/get-expanded-region-options";
import { getFormattedNumberWithThousandSeparator } from "utils/get-formatted-number-with-thousand-separator";

import classes from "./payment-configurator-form.module.css";

interface IPaymentConfiguratorFormProps {
  payGroups: IPayGroupDTO[];
  errorFieldIndexOfSubCount: string | null;
}

export const PaymentConfiguratorForm = (
  props: IPaymentConfiguratorFormProps
) => {
  const { payGroups, errorFieldIndexOfSubCount } = props;
  const {
    register,
    control,
    setValue,
    getValues,
    trigger,
    formState: { errors, dirtyFields },
  } = useFormContext();
  const { fields, append, remove } = useFieldArray<any>({
    name: FormPaymentConfigurator.GROUPS,
    control,
  });

  const { data: durations, isLoading: isLoadingDurations } = useDurations();
  const { data: serviceKinds, isLoading: isLoadingServiceKinds } =
    useServiceKinds();
  const { data: regions, isLoading: isLoadingRegions } = useRegions();
  const expandedRegionOptions = React.useMemo(
    () => getExpandedRegionOptions(mockData.regions, regions),
    [regions]
  );

  const handleAddNewGroup = (): void => {
    append(new InitialFormState());
  };

  const handleDeleteGroup = (itemIndex: number) => {
    remove(itemIndex);
  };

  return (
    <>
      <form className={classes.form} autoComplete="off">
        {fields.map((fieldItem: any, index: number) => {
          const fieldPayGroupId: any = fieldItem?.payGroupId || "";
          const payGroup: IPayGroupDTO | undefined = payGroups.find(
            (payGroupItem) => payGroupItem?.payGroupId === fieldPayGroupId
          );

          const payGroupAmount: number = payGroup?.amount ?? 0;
          const isShowPayGroupAmount: boolean = !!payGroupAmount;
          const payGroupAmountFormatted: string =
            getFormattedNumberWithThousandSeparator({
              value: payGroupAmount,
              showFractionDigits: true,
            });

          const isSubscriptionCountFieldDirty: boolean =
            !!getValues()?.[FormPaymentConfigurator.GROUPS]?.[index]?.[
              FIELD_NAMES.PAYMENT_CONFIGURATOR_COUNT
            ] ?? false;

          return (
            <div
              key={fieldItem.id}
              className={classes.cardGroup}
              style={{ paddingTop: index > 0 ? "36px" : "0px" }}
            >
              <div className={classes.cardGroupHeader}>
                <h3
                  className={classes.cardGroupHeaderTitle}
                  data-testId="txt_head_group_subs"
                >
                  Группа подписок {index + 1}
                </h3>
                <Zoom in={fields.length > 1}>
                  <Tooltip
                    arrow
                    title="Удалить группу"
                    disableInteractive
                    disableHoverListener={fields.length <= 1}
                  >
                    <div>
                      <MTSButtonIcon
                        mode="round"
                        Icon={MTSIcon.Minus}
                        size="XS"
                        variant="secondary"
                        onClick={() => handleDeleteGroup(index)}
                        data-testid="btn_delete_group_subs"
                      />
                    </div>
                  </Tooltip>
                </Zoom>
              </div>
              <ul className={classes.cardGroupInputs}>
                <li className={classes.cardGroupInput}>
                  <MTSInput
                    required
                    type="number"
                    onWheel={(event: any) => event.currentTarget.blur()}
                    label="Количество подписок"
                    description={
                      isSubscriptionCountFieldDirty
                        ? ""
                        : `За один платёж можно приобрести не более ${MAX_TOTAL_SUB_COUNT} подписок`
                    }
                    placeholder="Введите"
                    {...register(
                      `${FormPaymentConfigurator.GROUPS}.${index}.${FIELD_NAMES.PAYMENT_CONFIGURATOR_COUNT}`
                    )}
                    errorMessage={
                      errors?.[FormPaymentConfigurator.GROUPS]?.[index]?.[
                        FIELD_NAMES.PAYMENT_CONFIGURATOR_COUNT
                      ]?.["message"] ||
                      (errorFieldIndexOfSubCount === String(index)
                        ? TOTAL_SUB_COUNT_EXCEEDS_ERROR_MEESAGE
                        : "")
                    }
                    onClear={() => {
                      const fieldId = `${FormPaymentConfigurator.GROUPS}.${index}.${FIELD_NAMES.PAYMENT_CONFIGURATOR_COUNT}`;
                      setValue(fieldId, "");
                      trigger(fieldId);
                    }}
                    isDirty={
                      dirtyFields?.[FormPaymentConfigurator.GROUPS]?.[index]?.[
                        FIELD_NAMES.PAYMENT_CONFIGURATOR_COUNT
                      ] ?? false
                    }
                    dataTestId="fld_count_subs"
                  />
                </li>
                <li className={classes.cardGroupInput}>
                  <Controller
                    control={control}
                    name={`${FormPaymentConfigurator.GROUPS}.${index}.${FIELD_NAMES.PAYMENT_CONFIGURATOR_SERVICE_KIND}`}
                    render={(renderProps) => {
                      const { field, fieldState } = renderProps;
                      const { value, onChange } = field;
                      const errorMessage: string =
                        fieldState?.error?.message || "";

                      return (
                        <MTSAutocomplete
                          dataTestId="fld_autocomplete_serviceKind"
                          required
                          size="m"
                          label="Тип доступа"
                          placeholder="Выберите"
                          value={value}
                          onChangeValue={onChange}
                          options={serviceKinds || []}
                          loading={isLoadingServiceKinds}
                          errorMessage={errorMessage}
                        />
                      );
                    }}
                  />
                </li>
                <li className={classes.cardGroupInput}>
                  <Controller
                    control={control}
                    name={`${FormPaymentConfigurator.GROUPS}.${index}.${FIELD_NAMES.PAYMENT_CONFIGURATOR_DURATION}`}
                    render={(renderProps) => {
                      const { field, fieldState } = renderProps;
                      const { value, onChange } = field;
                      const errorMessage: string =
                        fieldState?.error?.message || "";

                      return (
                        <MTSAutocomplete
                          dataTestId="fld_autocomplete_duration"
                          required
                          size="m"
                          label="Срок действия"
                          placeholder="Выберите"
                          value={value}
                          onChangeValue={onChange}
                          options={getDurationsWithOutTest(durations)}
                          loading={isLoadingDurations}
                          errorMessage={errorMessage}
                        />
                      );
                    }}
                  />
                </li>
                <li className={classes.cardGroupInput}>
                  <Controller
                    control={control}
                    name={`${FormPaymentConfigurator.GROUPS}.${index}.${FIELD_NAMES.PAYMENT_CONFIGURATOR_REGION}`}
                    render={(renderProps) => {
                      const { field, fieldState } = renderProps;
                      const { value, onChange } = field;
                      const errorMessage: string =
                        fieldState?.error?.message || "";

                      return (
                        <MTSAutocomplete
                          dataTestId="fld_autocomplete_region"
                          required
                          disabled
                          disabledTooltipText="На выбранном тарифе нет ограничений по регионам. 
                          Вы можете использовать любые станции сети,
                          независимо от региона"
                          size="m"
                          label="Регион"
                          placeholder="Выберите"
                          value={value}
                          onChangeValue={onChange}
                          options={expandedRegionOptions}
                          loading={isLoadingRegions}
                          errorMessage={errorMessage}
                        />
                      );
                    }}
                  />
                </li>
              </ul>
              <Collapse in={isShowPayGroupAmount}>
                <Fade
                  in={isShowPayGroupAmount}
                  style={{ transformOrigin: "0 0 0" }}
                  {...(isShowPayGroupAmount ? { timeout: 1500 } : {})}
                >
                  <div className={classes.cardDiscount}>
                    <p
                      className={classes.cardDiscountNumber}
                      data-testid="txt_price_group_sub_without_discount"
                    >
                      {payGroupAmountFormatted} ₽
                    </p>
                    <p
                      className={classes.cardDiscountDesc}
                      data-testid="txt_hint_price_group_sub_without_discount"
                    >
                      стоимость группы подписок без скидки
                    </p>
                  </div>
                </Fade>
              </Collapse>
            </div>
          );
        })}
      </form>

      <div className={classes.addButton}>
        <MTSButton
          variant="ghost"
          size="M"
          onClick={handleAddNewGroup}
          IconStart={MTSIcon.Plus}
          data-testid="btn_add_group_subs"
        >
          Добавить группу подписок
        </MTSButton>
      </div>
    </>
  );
};
