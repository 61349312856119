import React from "react";

import { useInView } from "react-intersection-observer";

import { BlockContent } from "components/block-content";
import { MTSIcon } from "components/mts-icon";
import { SliderNav } from "components/slider-nav";
import {
  GAE_ACTION_GROUP,
  GAE_BUTTON_LOCATION,
  GAE_EVENT,
  GAE_EVENT_ACTION,
  GAE_EVENT_CATEGORY,
  GAE_EVENT_CONTENT,
  GAE_EVENT_LABEL,
  useGoogleAnalyticsEvents,
} from "hooks/google-analytics";
import { useAppTheme } from "hooks/use-app-theme";
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { SwiperClass } from "swiper/react";

import "swiper/modules/navigation/navigation.min.css";

import "swiper/swiper.css";
import controlAgroImg from "./img/control-agro-img.jpg";
import controlCropsImg from "./img/control-crops-img.jpg";
import cropsImg from "./img/crops-img.jpg";
import techniqueImg from "./img/technique-img.jpg";
import classes from "./slider.module.css";

export const Slider = (): JSX.Element => {
  const slides = [
    {
      title: (
        <>
          Подготовка
          <br />к посевным работам
        </>
      ),
      texts: [
        <>Создание цифровых карт полей и&nbsp;цифровых моделей рельефа</>,
        <>Анализ рельефа, выделение водооттоков&nbsp;и бессточных зон</>,
        "Актуализация контуров полей перед посевной",
        "Проверка на нецелевое использование угодий",
        <>
          Оценка состояния мелиоративных систем и&nbsp;гидротехнических{" "}
          <br className={classes.break} />
          сооружений
        </>,
      ],
      img: cropsImg,
    },
    {
      title: (
        <>
          Контроль
          <br />
          посевных работ
        </>
      ),
      texts: [
        "Снижение рисков по перерасходу семян",
        <>
          Повышение сбора урожая в результате ухода двойной{" "}
          <br className={classes.break} />
          густоты на перекрытиях
        </>,
      ],
      img: controlCropsImg,
    },
    {
      title: (
        <>
          Контроль и&nbsp;оценка <br className={classes.break} />
          агро&shy;технических мероприятий
        </>
      ),
      texts: [
        "Снижение рисков по перерасходу удобрений, средств защиты растений",
        <>Исключение «вытаптывания» посевов при&nbsp;опрыскивании</>,
        <>
          Создание предписаний на диффе&shy;ренцированное внесение{" "}
          <br className={classes.break} />
          средств защиты растений и удобрений
        </>,
        "Контроль на перекрытиях при гербицидной обработке посевов",
        "Оценка ущербов от агроклиматических аномалий",
        <>Контроль всхожести пропашных культур и&nbsp;аналитика урожайности</>,
      ],
      img: controlAgroImg,
    },
    {
      title: <>Техника</>,
      texts: [
        "Снижение простоев техники",
        "Контроль ГСМ",
        <>Контроль износа техники и&nbsp;комплектующих</>,
      ],
      img: techniqueImg,
    },
  ];

  const theme = useAppTheme();
  const [slideProgress, setSlideProgress] = React.useState<number>(0);
  const { sendGAE } = useGoogleAnalyticsEvents();
  const { ref: agroUpperSlideRef, inView: inViewAgroUpperSlider } = useInView({
    initialInView: false,
    threshold: 0.5,
    triggerOnce: true,
  });
  const onAutoplayTimeLeft = (
    s: SwiperClass,
    time: number,
    progress: number
  ) => {
    setSlideProgress(progress);
  };

  const handleSendMetrics = (direction: string) => {
    let _eventLabel = GAE_EVENT_LABEL.vpered;
    if (direction === "prev") {
      _eventLabel = GAE_EVENT_LABEL.nazad;
    }
    sendGAE({
      id: 55,
      event: GAE_EVENT.vntZeml,
      eventCategory: GAE_EVENT_CATEGORY.tochnoe_zemledelie,
      eventAction: GAE_EVENT_ACTION.element_click,
      eventLabel: _eventLabel,
      eventValue: null,
      eventContent: GAE_EVENT_CONTENT.up,
      buttonLocation: GAE_BUTTON_LOCATION.screen,
      filterName: null,
      actionGroup: GAE_ACTION_GROUP.interactions,
      productName: null,
    });
  };

  React.useEffect(() => {
    if (inViewAgroUpperSlider) {
      sendGAE({
        id: 64,
        event: GAE_EVENT.vntZeml,
        eventCategory: GAE_EVENT_CATEGORY.tochnoe_zemledelie,
        eventAction: GAE_EVENT_ACTION.block_show,
        eventLabel: GAE_EVENT_LABEL.upper_slider,
        eventValue: null,
        eventContent: null,
        buttonLocation: null,
        filterName: null,
        actionGroup: GAE_ACTION_GROUP.non_interactions,
        productName: null,
      });
    }
  }, [inViewAgroUpperSlider, sendGAE]);

  return (
    <div ref={agroUpperSlideRef} className={classes.blockSlider}>
      <Swiper
        spaceBetween={0}
        centeredSlides={true}
        loop={true}
        autoplay={{
          delay: 15000,
          disableOnInteraction: false,
        }}
        modules={[Autoplay]}
        onAutoplayTimeLeft={onAutoplayTimeLeft}
        className={classes.swiper}
      >
        {slides.map((slideItem, index: number) => {
          return (
            <SwiperSlide
              key={index}
              className={classes.swiperSlide}
              style={{ backgroundImage: `url(${slideItem.img})` }}
            >
              <BlockContent
                sx={{
                  position: "relative",
                  height: "100%",
                }}
              >
                <div className={classes.swiperSlideContent}>
                  <h2 className={classes.swiperSlideContentTitle}>
                    {slideItem.title}
                  </h2>

                  <ul className={classes.contentList}>
                    {slideItem.texts.map(
                      (text: string | JSX.Element, _index: number) => (
                        <li className={classes.contentListItem} key={_index}>
                          <MTSIcon.OkRound
                            sx={{
                              fontSize: "24px",
                              color: "var(--color-constant-greyscale-0)",
                              marginTop: "2px",
                              [theme.breakpoints.down("desktopS_960")]: {
                                fontSize: "20px",
                              },
                            }}
                          />

                          <p className={classes.contentListItemText}>{text}</p>
                        </li>
                      )
                    )}
                  </ul>
                </div>
              </BlockContent>
            </SwiperSlide>
          );
        })}
        <SliderNav
          sliderProgress={slideProgress}
          onClickNext={(next) => handleSendMetrics(next)}
          onClickPrev={(prev) => handleSendMetrics(prev)}
        />
      </Swiper>
    </div>
  );
};
