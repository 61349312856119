import { LS_USER_FROM_LANDING } from "constants/keys";
import {
  PATH_MAP,
  PATH_PERSONAL_AREA,
  PATH_SUBSCRIPTIONS,
  PATH_TARIFFS,
} from "constants/routes";

import React from "react";

import { useHistory } from "react-router-dom";

import { BlockContent } from "components/block-content";
import { BlockWide } from "components/block-wide";
import { MTSButton } from "components/mts-button";
import {
  GAE_ACTION_GROUP,
  GAE_BUTTON_LOCATION,
  GAE_EVENT,
  GAE_EVENT_ACTION,
  GAE_EVENT_CATEGORY,
  GAE_EVENT_LABEL,
  GA_SCREEN_NAME,
  useGoogleAnalyticsEvents,
} from "hooks/google-analytics";
import { useContextMain } from "hooks/use-context-main";
import { useProfileData } from "hooks/use-profile-data";
import { isAdminHasAnySubscriptions } from "utils/is-admin-has-any-subscriptions";

import { PulseButton } from "./components/pulse-button";
import classes from "./main.module.css";

export const Main = (): JSX.Element => {
  const history = useHistory();
  const { isAuthorized, isLoadingAuthData } = useContextMain();
  const { isPotentialRole, isCustomerAdminRole } = useProfileData();
  const { sendGAE } = useGoogleAnalyticsEvents();

  const [isRedirecting, setIsRedirecting] = React.useState<boolean>(false);

  const handleRedirectToPersonalArea = async (): Promise<void> => {
    setIsRedirecting(true);
    let redirectURL: string = isAuthorized
      ? isPotentialRole
        ? PATH_TARIFFS
        : PATH_PERSONAL_AREA
      : PATH_TARIFFS;

    if (isAuthorized) {
      sendGAE({
        id: 31,
        event: GAE_EVENT.vntMain,
        eventCategory: GAE_EVENT_CATEGORY.glavnaya,
        eventAction: GAE_EVENT_ACTION.button_click,
        eventLabel: GAE_EVENT_LABEL.pereiti_v_lichnyi_kabinet,
        eventValue: null,
        screenName: GA_SCREEN_NAME.main,
        eventContent: null,
        buttonLocation: GAE_BUTTON_LOCATION.screen,
        filterName: null,
        actionGroup: GAE_ACTION_GROUP.interactions,
        productName: null,
      });

      if (isCustomerAdminRole) {
        const isHasAnySubscriptions: boolean =
          await isAdminHasAnySubscriptions();

        if (isHasAnySubscriptions) {
          redirectURL = PATH_SUBSCRIPTIONS;
        }
      }
    } else {
      sendGAE({
        id: 13,
        event: GAE_EVENT.vntMain,
        eventCategory: GAE_EVENT_CATEGORY.glavnaya,
        eventAction: GAE_EVENT_ACTION.button_click,
        eventLabel: GAE_EVENT_LABEL.stat_klientom,
        eventValue: null,
        screenName: GA_SCREEN_NAME.main,
        eventContent: null,
        buttonLocation: GAE_BUTTON_LOCATION.screen,
        filterName: null,
        actionGroup: GAE_ACTION_GROUP.interactions,
        productName: null,
      });
      sendGAE({
        id: 14,
        event: GAE_EVENT.vntLogin,
        eventCategory: GAE_EVENT_CATEGORY.avtorizaciya,
        eventAction: GAE_EVENT_ACTION.button_click,
        eventLabel: GAE_EVENT_LABEL.voiti,
        eventValue: null,
        screenName: GA_SCREEN_NAME.main,
        eventContent: "Stat klientom",
        buttonLocation: GAE_BUTTON_LOCATION.screen,
        filterName: null,
        actionGroup: GAE_ACTION_GROUP.interactions,
        productName: null,
      });
    }

    setIsRedirecting(false);

    // NOTE: При входи проверять если у пользователя подписки, если есть то отправлять на страницу с подписками
    if (redirectURL === PATH_TARIFFS) {
      localStorage.setItem(LS_USER_FROM_LANDING, "1");
    }

    history.push(redirectURL);
  };

  const handleRedirectToLandingMap = (): void => {
    sendGAE({
      id: 30,
      event: GAE_EVENT.vntMain,
      eventCategory: GAE_EVENT_CATEGORY.glavnaya,
      eventAction: GAE_EVENT_ACTION.button_click,
      eventLabel: GAE_EVENT_LABEL.karta_pokrytiya,
      eventValue: null,
      eventContent: null,
      buttonLocation: GAE_BUTTON_LOCATION.screen,
      filterName: null,
      actionGroup: GAE_ACTION_GROUP.interactions,
      productName: null,
    });

    history.push(PATH_MAP);
  };

  return (
    <BlockWide>
      <BlockContent sx={{ padding: 0 }}>
        <div className={classes.container}>
          <div className={classes.intro}>
            <h1 className={classes.introTitle}>
              ВЫСОКОТОЧНОЕ
              <br />
              ГЕОПОЗИЦИО&shy;НИРОВАНИЕ
            </h1>

            <p className={classes.introText}>
              Определяйте местоположение объектов
              <br />с точностью до 2 сантиметров
            </p>

            <div className={classes.introBtn}>
              <MTSButton
                fullWidth
                size="L"
                onClick={handleRedirectToPersonalArea}
                loading={isLoadingAuthData || isRedirecting}
              >
                {isAuthorized ? "Перейти в личный кабинет" : "Стать клиентом"}
              </MTSButton>
            </div>

            {!isAuthorized && (
              <p className={classes.introDesc}>
                Услуга доступна только юрлицам
              </p>
            )}
          </div>

          <div className={classes.btnMap}>
            <PulseButton onClick={handleRedirectToLandingMap} />
          </div>

          <div className={classes.backgroundMap} />
        </div>
      </BlockContent>
    </BlockWide>
  );
};
