import { IS_DEVELOPMENT_MODE } from "constants/env";
import { HTTPStatusCode, QueryKeys } from "constants/keys";

import { useQuery } from "react-query";

import { AxiosError } from "axios";
import { useContextMain } from "hooks/use-context-main";
import { useContextProfileWidget } from "hooks/use-context-profile-widget";
import { IValidateWEBSSOAuthResponse } from "interfaces";
import { getProfile, getValidateWEBSSOAuth } from "services/api";
import { AuthService } from "services/auth";

export const useSynchronizeAuth = (): void => {
  const { isLoadingAuthData, isAuthorized, clearAuthData } = useContextMain();
  const { reloadProfileWidget } = useContextProfileWidget();

  useQuery(QueryKeys.WIDGET_AUTH_DATA, () => getValidateWEBSSOAuth(), {
    enabled: !IS_DEVELOPMENT_MODE && !isLoadingAuthData,
    refetchOnWindowFocus: true,
    retry: 0,
    onSuccess: async (data: IValidateWEBSSOAuthResponse) => {
      const isSessionCookieValid: boolean = data.isSessionCookieValid
        ? true
        : false;

      if (isSessionCookieValid) {
        const webssoAuthPhone: string = data?.session_cookie?.uid || "";

        await getProfile()
          .then((resp) => {
            if (resp.status === HTTPStatusCode.OK_200) {
              const indentityAuthPhone = resp.data?.sub || "";

              const isNeedAuthLoginCase1 =
                !indentityAuthPhone && webssoAuthPhone;
              const isNeedAuthLoginCase2 =
                indentityAuthPhone &&
                webssoAuthPhone &&
                indentityAuthPhone !== webssoAuthPhone;
              const isNeedAuthLoginCase3 = webssoAuthPhone && !isAuthorized;

              if (
                isNeedAuthLoginCase1 ||
                isNeedAuthLoginCase2 ||
                isNeedAuthLoginCase3
              ) {
                AuthService.login();
              }
            }
          })
          .catch((error) => {
            if (
              error.response &&
              error.response.status === HTTPStatusCode.UNAUTHORIZED_401
            ) {
              AuthService.login();
            }
          });
      } else {
        AuthService.logout();
      }
    },
    onError: (error: AxiosError) => {
      reloadProfileWidget();
      clearAuthData();
    },
  });
};
