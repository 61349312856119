import { IMTSTab, MTSTabs } from "components/mts-tabs";

interface ITableSubscriptionsTabsProps {
  selectedTab: number | boolean;
  tabs: IMTSTab[];
  onChange: (event: React.SyntheticEvent, newTabNumber: number) => void;
}

export const TableSubscriptionsTabs = (
  props: ITableSubscriptionsTabsProps
): JSX.Element | null => {
  const { selectedTab = false, tabs, onChange } = props;

  if (tabs?.length) {
    return (
      <MTSTabs
        size="s"
        selectedTab={selectedTab}
        tabs={tabs}
        onChange={onChange}
      />
    );
  }

  return null;
};
