import { FIELD_NAMES } from "constants/keys";

import React from "react";

import { useHistory } from "react-router-dom";

import { useForm } from "react-hook-form";
import { useLocation } from "react-router";

import { yupResolver } from "@hookform/resolvers/yup";
import { useDurations } from "hooks/use-durations";
import { useServiceKinds } from "hooks/use-service-kinds";
import { IDictionaryDTO, ITableSubscriptionsFilters } from "interfaces";
import * as yup from "yup";

class InitialFilterState implements ITableSubscriptionsFilters {
  [FIELD_NAMES.TABLE_SUBSCRIPTIONS_FILTER_SEARCH] = "";
  [FIELD_NAMES.SUBSCRIPTION_SERVICE_KIND] = null;
  [FIELD_NAMES.SUBSCRIPTION_DURATION] = null;
}

const schema = yup.object({
  [FIELD_NAMES.TABLE_SUBSCRIPTIONS_FILTER_SEARCH]: yup.string(),
  [FIELD_NAMES.SUBSCRIPTION_SERVICE_KIND]: yup
    .object()
    .shape({
      id: yup.number(),
      code: yup.string(),
      name: yup.string(),
      description: yup.string(),
    })
    .nullable(),
  [FIELD_NAMES.SUBSCRIPTION_DURATION]: yup
    .object()
    .shape({
      id: yup.number(),
      code: yup.string(),
      name: yup.string(),
      description: yup.string(),
    })
    .nullable(),
});

export const useTableSubscriptionsFilters = () => {
  const location = useLocation();
  const history = useHistory();
  const [tableFilters, setTableFilters] =
    React.useState<ITableSubscriptionsFilters>(new InitialFilterState());
  const [isSearchFilled, setIsSearchFilled] = React.useState<boolean>(false);

  const { data: durations, isLoading: isLoadingDurations } = useDurations();
  const { data: serviceKinds, isLoading: isLoadingServiceKinds } =
    useServiceKinds();

  const { register, watch, setValue, control, resetField } =
    useForm<ITableSubscriptionsFilters>({
      defaultValues: new InitialFilterState(),
      resolver: yupResolver(schema),
    });

  const clearSearchInput = (): void => {
    resetField(FIELD_NAMES.TABLE_SUBSCRIPTIONS_FILTER_SEARCH);
  };

  React.useEffect(() => {
    const subscription = watch((value) => {
      const locationSearchPararms = new URLSearchParams(location.search);

      const search: string | undefined =
        value[FIELD_NAMES.TABLE_SUBSCRIPTIONS_FILTER_SEARCH];
      const duration = value[FIELD_NAMES.SUBSCRIPTION_DURATION] as
        | IDictionaryDTO
        | null
        | undefined;
      const serviceKind = value[FIELD_NAMES.SUBSCRIPTION_SERVICE_KIND] as
        | IDictionaryDTO
        | null
        | undefined;

      if (search) {
        setIsSearchFilled(true);
        locationSearchPararms.set(
          FIELD_NAMES.TABLE_SUBSCRIPTIONS_FILTER_SEARCH,
          search
        );
      } else {
        setIsSearchFilled(false);
        locationSearchPararms.delete(
          FIELD_NAMES.TABLE_SUBSCRIPTIONS_FILTER_SEARCH
        );
      }

      if (serviceKind?.code) {
        locationSearchPararms.set(
          FIELD_NAMES.SUBSCRIPTION_SERVICE_KIND,
          serviceKind?.code
        );
      } else {
        locationSearchPararms.delete(FIELD_NAMES.SUBSCRIPTION_SERVICE_KIND);
      }

      if (duration?.code) {
        locationSearchPararms.set(
          FIELD_NAMES.SUBSCRIPTION_DURATION,
          duration?.code
        );
      } else {
        locationSearchPararms.delete(FIELD_NAMES.SUBSCRIPTION_DURATION);
      }

      history.push({
        search: locationSearchPararms.toString(),
      });
    });

    return () => {
      subscription.unsubscribe();
      setIsSearchFilled(false);
    };
  }, [history, location.search, watch]);

  React.useEffect(() => {
    if (!isLoadingDurations && !isLoadingServiceKinds) {
      const locationSearchPararms = new URLSearchParams(location.search);

      const searchParam: string | null = locationSearchPararms.get(
        FIELD_NAMES.TABLE_SUBSCRIPTIONS_FILTER_SEARCH
      );
      const durationCodeParam: string | null = locationSearchPararms.get(
        FIELD_NAMES.SUBSCRIPTION_DURATION
      );
      const serviceKindCodeParam: string | null = locationSearchPararms.get(
        FIELD_NAMES.SUBSCRIPTION_SERVICE_KIND
      );
      const duration: IDictionaryDTO | undefined = durations?.find(
        (_duration) => _duration.code === durationCodeParam
      );
      const serviceKind: IDictionaryDTO | undefined = serviceKinds?.find(
        (_serviceKind) => _serviceKind.code === serviceKindCodeParam
      );

      setValue(
        FIELD_NAMES.TABLE_SUBSCRIPTIONS_FILTER_SEARCH,
        searchParam ? searchParam : ""
      );
      setValue(FIELD_NAMES.SUBSCRIPTION_DURATION, duration ? duration : null);
      setValue(
        FIELD_NAMES.SUBSCRIPTION_SERVICE_KIND,
        serviceKind ? serviceKind : null
      );

      setTableFilters({
        search: searchParam ? searchParam : "",
        duration: duration ? duration : null,
        serviceKind: serviceKind ? serviceKind : null,
      });
    }

    return () => {
      setTableFilters({
        search: "",
        duration: null,
        serviceKind: null,
      });
    };
  }, [
    durations,
    isLoadingDurations,
    isLoadingServiceKinds,
    location.search,
    serviceKinds,
    setValue,
  ]);

  return {
    tableFilters,
    isSearchFilled,
    clearSearchInput,
    register,
    control,
  };
};
