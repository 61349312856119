import { QueryKeys } from "constants/keys";

import { useMutation, useQueryClient } from "react-query";

import { AxiosError } from "axios";
import { useNotifications } from "hooks/use-notifications";
import { ICustomerAdminUpdateDTOPayload } from "interfaces";
import { updateCustomerAdminInfo } from "services/api";

export const useMutationUpdateCustomerAdminInfo = () => {
  const { addErrorMessage, addSuccessMessage } = useNotifications();
  const queryClient = useQueryClient();

  return useMutation(
    (payload: ICustomerAdminUpdateDTOPayload) => {
      return updateCustomerAdminInfo(payload);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(QueryKeys.CUSTOMER_ADMIN_DATA);
        addSuccessMessage({ message: "Информация успешно обновлена" });
      },
      onError: (error: AxiosError) => {
        addErrorMessage({ message: "Не удалось обновить информацию" });
      },
    }
  );
};
