import React from "react";

interface IContextProfileWidget {
  profileWidget: any;
  setProfileWidget: React.Dispatch<React.SetStateAction<any>>;
  reloadProfileWidget: () => void;
}

export const ContextProfileWidget = React.createContext<IContextProfileWidget>({
  profileWidget: null,
  setProfileWidget: () => null,
  reloadProfileWidget: () => undefined,
});
