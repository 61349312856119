import { NOTIFICATION_MODE } from "constants/keys";

import { INotificationStruct, INotificationPayload } from "interfaces/index";
import { v4 as uuidv4 } from "uuid";

import { useContextMain } from "./use-context-main";

export const useNotifications = () => {
  const { setNotifications } = useContextMain();

  const addNewNotification = (newNotification: INotificationStruct): void => {
    setNotifications((prevNotifications: INotificationStruct[]) => {
      const notificationsWithSameMessage: INotificationStruct[] =
        prevNotifications.filter(
          (notif) => notif.message === newNotification.message
        );

      if (notificationsWithSameMessage?.length) {
        return [...prevNotifications];
      } else {
        return [...prevNotifications, newNotification];
      }
    });
  };

  const addSuccessMessage = ({ title, message }: INotificationPayload) => {
    const newNotification: INotificationStruct = {
      id: uuidv4(),
      mode: NOTIFICATION_MODE.SUCCESS,
      title,
      message,
    };

    addNewNotification(newNotification);
  };

  const addInfoMessage = ({ title, message }: INotificationPayload) => {
    const newNotification: INotificationStruct = {
      id: uuidv4(),
      mode: NOTIFICATION_MODE.INFO,
      title,
      message,
    };

    addNewNotification(newNotification);
  };

  const addWarningMessage = ({ title, message }: INotificationPayload) => {
    const newNotification: INotificationStruct = {
      id: uuidv4(),
      mode: NOTIFICATION_MODE.WARNING,
      title,
      message,
    };

    addNewNotification(newNotification);
  };

  const addErrorMessage = ({ title, message }: INotificationPayload) => {
    const newNotification: INotificationStruct = {
      id: uuidv4(),
      mode: NOTIFICATION_MODE.ERROR,
      title,
      message,
    };

    addNewNotification(newNotification);
  };

  const addMessage = ({ title, message }: INotificationPayload) => {
    const newNotification: INotificationStruct = {
      id: uuidv4(),
      mode: NOTIFICATION_MODE.MESSAGE,
      title,
      message,
    };

    addNewNotification(newNotification);
  };

  return {
    addSuccessMessage,
    addErrorMessage,
    addInfoMessage,
    addWarningMessage,
    addMessage,
  };
};
