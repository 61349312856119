import { HTTPStatusCode, QueryKeys } from "constants/keys";
import { DICTIONARY_SLATE_TIME } from "constants/server";

import { useQuery } from "react-query";

import { AxiosError } from "axios";
import { useNotifications } from "hooks/use-notifications";
import { getDiscounts } from "services/api";

export const useDiscounts = () => {
  const { addErrorMessage } = useNotifications();

  return useQuery(QueryKeys.DISCOUNTS, () => getDiscounts(), {
    staleTime: DICTIONARY_SLATE_TIME,
    retry: 0,
    onError: (error: AxiosError) => {
      const status = error.response?.status;

      if (status !== HTTPStatusCode.UNAUTHORIZED_401) {
        addErrorMessage({
          message: "Не удалось получить справочник со скидками",
        });
      }
    },
  });
};
