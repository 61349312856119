import { PATH_AGRONOMY, PATH_MAIN } from "constants/routes";

import React from "react";

import { BlockContent } from "components/block-content";
import { BlockWide } from "components/block-wide";
import { Breadcrumbs } from "components/breadcrumbs";
import { DialogContactUs } from "components/dialog-contact-us";
import { MTSButton } from "components/mts-button";
import {
  GAE_ACTION_GROUP,
  GAE_BUTTON_LOCATION,
  GAE_EVENT,
  GAE_EVENT_ACTION,
  GAE_EVENT_CATEGORY,
  GAE_EVENT_LABEL,
  useGoogleAnalyticsEvents,
} from "hooks/google-analytics";
import { useTabNames } from "hooks/tabs/use-tab-names";

import agroImg from "./img/agro-img.jpg";
import classes from "./intro.module.css";

const links = [
  {
    href: "#",
    label: "Точное позиционирование",
  },
  {
    href: "#",
    label: "ГИС",
  },
  {
    href: "#",
    label: "IoT",
  },
  {
    href: "#",
    label: "Консалтинг",
  },
  {
    href: "#",
    label: "Услуги",
  },
  {
    href: "#",
    label: "Сервисная поддержка",
  },
];

export const Intro = React.memo((): JSX.Element => {
  const { tabNames } = useTabNames();
  const { sendGAE } = useGoogleAnalyticsEvents();

  const breadcrumbLinks = React.useMemo(
    () => [
      {
        name: tabNames.main,
        href: PATH_MAIN,
      },
      { name: tabNames.agro, href: PATH_AGRONOMY },
    ],
    [tabNames.agro, tabNames.main]
  );

  const [isOpenDialogContactUs, setIsOpenDialogContactUs] =
    React.useState<boolean>(false);

  const handleOpenDialog = (): void => {
    setIsOpenDialogContactUs(true);
  };

  const handleCloseDialog = (): void => {
    setIsOpenDialogContactUs(false);
  };

  const handleSendMetrics = (): void => {
    sendGAE({
      id: 54,
      event: GAE_EVENT.vntZeml,
      eventCategory: GAE_EVENT_CATEGORY.tochnoe_zemledelie,
      eventAction: GAE_EVENT_ACTION.button_click,
      eventLabel: GAE_EVENT_LABEL.zakazat_demonstraciu,
      eventValue: null,
      eventContent: null,
      buttonLocation: GAE_BUTTON_LOCATION.screen,
      filterName: null,
      actionGroup: GAE_ACTION_GROUP.interactions,
      productName: null,
    });
  };

  return (
    <>
      <div className={classes.blockIntro}>
        <BlockWide>
          <BlockContent>
            <Breadcrumbs links={breadcrumbLinks} />
          </BlockContent>
        </BlockWide>

        <BlockWide
          sx={{
            marginTop: "16px",
            height: "100%",
            backgroundImage: `url(${agroImg})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        >
          <BlockContent>
            <div className={classes.info}>
              <h2 className={classes.infoTitle}>
                Точное
                <br />
                земледелие
              </h2>

              <div className={classes.infoContent}>
                <p className={classes.infoContentTitle}>
                  Повышайте эффективность земледелия&nbsp;
                  <br className={classes.infoContentBreak} />с помощью&nbsp;
                  <span className={classes.infoContentSpan}>
                    точных координат
                  </span>
                </p>

                <div className={classes.infoContentLinks}>
                  {/* TODO: Временно вместо ссылок используем div блоки */}
                  {links.map((linkItem, index) => (
                    <div key={index} className={classes.infoContentLink}>
                      <p className={classes.infoContentLinkText}>
                        {linkItem.label}
                      </p>
                    </div>
                  ))}
                </div>
              </div>

              <MTSButton
                variant="primary"
                size="L"
                onClick={() => {
                  handleOpenDialog();
                  handleSendMetrics();
                }}
                sx={{
                  maxWidth: "292px",
                  "@media (max-width: 500px)": {
                    maxWidth: "100%",
                  },
                }}
              >
                Заказать демонстрацию
              </MTSButton>
            </div>
          </BlockContent>
        </BlockWide>
      </div>
      <DialogContactUs
        isOpen={isOpenDialogContactUs}
        handleCloseDialog={handleCloseDialog}
        feedbackType="DEMONSTRATION"
        title="Заказать демонстрацию"
      />
    </>
  );
});
