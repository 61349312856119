import { IconWrapper } from "components/icon-wrapper";
import { MTSIcon } from "components/mts-icon";
import { Plug } from "components/plug";
import { TransitionFade } from "components/transition-fade";

import classes from "./table-empty.module.css";

type ITableEmptyIcon = "subs" | "rinex-file-requests";

type ITableEmptyProps = {
  pageSize: number;
  text: string | JSX.Element;
  children?: React.ReactNode;
  icon: ITableEmptyIcon;
};

export const TableEmpty = (props: ITableEmptyProps): JSX.Element => {
  const { pageSize, text, icon, children = null } = props;

  return (
    <TransitionFade isShow={true}>
      <div
        className={classes.block}
        style={{ height: `calc(${pageSize + 1} * var(--table-row-height))` }}
      >
        {icon === "subs" && (
          <Plug
            icon={
              <IconWrapper
                size={64}
                icon={
                  <MTSIcon.Mail
                    sx={{
                      fontSize: "24px",
                      color: "var(--color-constant-blackberry-light)",
                    }}
                  />
                }
              />
            }
            label={text}
          />
        )}
        {icon === "rinex-file-requests" && (
          <Plug
            icon={
              <IconWrapper
                size={64}
                icon={
                  <MTSIcon.AddFile
                    sx={{
                      fontSize: "24px",
                      color: "var(--color-constant-blackberry-light)",
                    }}
                  />
                }
              />
            }
            label={text}
          />
        )}

        {children ? <div className={classes.content}>{children}</div> : <></>}
      </div>
    </TransitionFade>
  );
};
