import { FIELD_NAMES, PHONE_MASK } from "constants/keys";
import {
  PATH_AGRONOMY,
  PATH_GEODESY_CADASTRE,
  PATH_MAIN,
} from "constants/routes";

import React from "react";

import { useLocation } from "react-router-dom";

import { Controller } from "react-hook-form";
import InputMask from "react-input-mask";

import { useMediaQuery } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { BlockContent } from "components/block-content";
import { MTSAutocomplete } from "components/mts-autocomplete";
import { MTSButton } from "components/mts-button";
import { MTSButtonIcon } from "components/mts-button-icon";
import { MTSIcon } from "components/mts-icon";
import { MTSInput } from "components/mts-input";
import {
  GAE_ACTION_GROUP,
  GAE_BUTTON_LOCATION,
  GAE_EVENT,
  GAE_EVENT_ACTION,
  GAE_EVENT_CATEGORY,
  GAE_EVENT_LABEL,
  useGoogleAnalyticsEvents,
} from "hooks/google-analytics";
import { useAppTheme } from "hooks/use-app-theme";
import { useFormContactUs, IFormContactUs } from "hooks/use-form-contact-us";
import { useMutationContactUs } from "hooks/use-mutation-contact-us";
import { useRegions } from "hooks/use-regions";
import { IFeedbackDTO } from "interfaces";
import { getPhoneNumberWithoutMask } from "utils/get-phone-number-without-mask";

import classes from "./dialog-contact-us.module.css";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type TFeedBackType =
  | "EQUIPMENT"
  | "DEMONSTRATION"
  | "CALL_REQUEST"
  | "PARTNER"
  | "TEST_SUBSCRIPTION_REQUEST";

interface IDialogContactUsProps {
  isOpen: boolean;
  feedbackType: TFeedBackType;
  title: string | JSX.Element;
  handleCloseDialog: () => void;
}

export const DialogContactUs = (props: IDialogContactUsProps): JSX.Element => {
  const { isOpen, title, feedbackType, handleCloseDialog } = props;
  const theme = useAppTheme();
  const { data: regions, isLoading: isLoadingRegions } = useRegions();
  const location = useLocation();
  const pathname = location.pathname;
  const { sendGAE } = useGoogleAnalyticsEvents();

  const isMinWidthTablet_768 = useMediaQuery(
    theme.breakpoints.up("tablet_768")
  );
  const isMinWidthDesktop_960 = useMediaQuery(
    theme.breakpoints.up("desktopS_960")
  );

  const mutationContactUs = useMutationContactUs();

  const {
    register,
    handleSubmit,
    control,
    trigger,
    setValue,
    reset,
    formState: { errors, dirtyFields },
  } = useFormContactUs();

  const onSubmit = (formData: IFormContactUs): void => {
    const payload: IFeedbackDTO = {
      fio: formData?.fio || "",
      phoneNumber: getPhoneNumberWithoutMask(formData?.phoneNumber),
      email: formData?.email || "",
      companyName: formData?.companyName || "",
      region: formData?.region?.code || "",
      feedbackType: feedbackType,
    };

    mutationContactUs.mutate(payload, {
      onSettled: () => {
        handleCloseDialog();
      },
      onSuccess: () => {
        if (pathname === PATH_MAIN && feedbackType === "PARTNER") {
          sendGAE({
            id: 85,
            event: GAE_EVENT.vntMain,
            eventCategory: GAE_EVENT_CATEGORY.glavnaya,
            eventAction: GAE_EVENT_ACTION.popup_show,
            eventLabel: GAE_EVENT_LABEL.dannye_otpravleny,
            eventValue: null,
            eventContent: null,
            buttonLocation: GAE_BUTTON_LOCATION.screen,
            filterName: null,
            actionGroup: GAE_ACTION_GROUP.non_interactions,
            productName: null,
          });
        } else if (
          pathname === PATH_GEODESY_CADASTRE &&
          feedbackType === "CALL_REQUEST"
        ) {
          sendGAE({
            id: 86,
            event: GAE_EVENT.vntGeod,
            eventCategory: GAE_EVENT_CATEGORY.geodeziya_i_kadastr,
            eventAction: GAE_EVENT_ACTION.popup_show,
            eventLabel: GAE_EVENT_LABEL.dannye_otpravleny,
            eventValue: null,
            eventContent: null,
            buttonLocation: GAE_BUTTON_LOCATION.screen,
            filterName: null,
            actionGroup: GAE_ACTION_GROUP.non_interactions,
            productName: null,
          });
        } else if (
          pathname === PATH_AGRONOMY &&
          feedbackType === "CALL_REQUEST"
        ) {
          sendGAE({
            id: 87,
            event: GAE_EVENT.vntZeml,
            eventCategory: GAE_EVENT_CATEGORY.tochnoe_zemledelie,
            eventAction: GAE_EVENT_ACTION.popup_show,
            eventLabel: GAE_EVENT_LABEL.dannye_otpravleny,
            eventValue: null,
            eventContent: null,
            buttonLocation: GAE_BUTTON_LOCATION.screen,
            filterName: null,
            actionGroup: GAE_ACTION_GROUP.non_interactions,
            productName: null,
          });
        }
      },
    });
  };

  React.useEffect(() => {
    if (!isOpen) {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <Dialog
      fullScreen
      open={isOpen}
      onClose={handleCloseDialog}
      TransitionComponent={Transition}
    >
      <BlockContent sx={{ alignSelf: "center", padding: "0px 0px" }}>
        <div className={classes.dialog}>
          <div className={classes.dialogHeader}>
            <div className={classes.dialogHeaderInfo}>
              <h2 className={classes.dialogHeaderInfoTitle}>{title}</h2>
              <p className={classes.dialogHeaderInfoText}>
                Заполните форму и мы скоро с вами свяжемся
              </p>
            </div>

            <MTSButtonIcon
              mode="round"
              Icon={MTSIcon.Close}
              iconSize={isMinWidthDesktop_960 ? 44 : 32}
              iconColor={"var(--color-icon-primary)"}
              onClick={handleCloseDialog}
              variant="ghost"
              size={isMinWidthDesktop_960 ? "M" : "S"}
              sx={{
                [theme.breakpoints.between("tablet_768", "desktopS_960")]: {
                  paddingTop: "2px",
                },
                [theme.breakpoints.down("tablet_768")]: {
                  position: "absolute",
                  top: "20px",
                  right: "20px",
                },
              }}
            />
          </div>

          <div className={classes.dialogContent}>
            <form className={classes.dialogContentForm}>
              <MTSInput
                required
                label="ФИО"
                placeholder="Введите"
                {...register(FIELD_NAMES.CONTACT_US_FIO)}
                errorMessage={errors?.[FIELD_NAMES.CONTACT_US_FIO]?.message}
                onClear={() => {
                  setValue(FIELD_NAMES.CONTACT_US_FIO, "");
                  trigger(FIELD_NAMES.CONTACT_US_FIO);
                }}
                isDirty={dirtyFields?.[FIELD_NAMES.CONTACT_US_FIO] ?? false}
                size={isMinWidthTablet_768 ? "M" : "S"}
              />

              <MTSInput
                required
                label="Название компании"
                placeholder="Введите"
                {...register(FIELD_NAMES.CONTACT_US_COMPANY_NAME)}
                errorMessage={
                  errors?.[FIELD_NAMES.CONTACT_US_COMPANY_NAME]?.message
                }
                onClear={() => {
                  setValue(FIELD_NAMES.CONTACT_US_COMPANY_NAME, "");
                  trigger(FIELD_NAMES.CONTACT_US_COMPANY_NAME);
                }}
                isDirty={
                  dirtyFields?.[FIELD_NAMES.CONTACT_US_COMPANY_NAME] ?? false
                }
                size={isMinWidthTablet_768 ? "M" : "S"}
              />

              <InputMask
                mask={PHONE_MASK}
                {...register(FIELD_NAMES.CONTACT_US_PHONE)}
              >
                {(inputProps: any) => (
                  <MTSInput
                    required
                    type="tel"
                    label="Телефон"
                    placeholder="+7"
                    {...inputProps}
                    errorMessage={
                      errors?.[FIELD_NAMES.CONTACT_US_PHONE]?.message
                    }
                    onClear={() => {
                      setValue(FIELD_NAMES.CONTACT_US_PHONE, "");
                      trigger(FIELD_NAMES.CONTACT_US_PHONE);
                    }}
                    isDirty={
                      dirtyFields?.[FIELD_NAMES.CONTACT_US_PHONE] ?? false
                    }
                    size={isMinWidthTablet_768 ? "M" : "S"}
                  />
                )}
              </InputMask>

              <MTSInput
                required
                label="Email"
                placeholder="user@company.ru"
                {...register(FIELD_NAMES.CONTACT_US_EMAIL)}
                errorMessage={errors?.[FIELD_NAMES.CONTACT_US_EMAIL]?.message}
                onClear={() => {
                  setValue(FIELD_NAMES.CONTACT_US_EMAIL, "");
                  trigger(FIELD_NAMES.CONTACT_US_EMAIL);
                }}
                isDirty={dirtyFields?.[FIELD_NAMES.CONTACT_US_EMAIL] ?? false}
                size={isMinWidthTablet_768 ? "M" : "S"}
              />

              <Controller
                control={control}
                name={FIELD_NAMES.CONTACT_US_REGION}
                render={(renderProps) => {
                  const { field, fieldState } = renderProps;
                  const { onChange, value } = field;
                  const errorMessage: string = fieldState?.error?.message || "";

                  return (
                    <MTSAutocomplete
                      required
                      size={isMinWidthTablet_768 ? "m" : "s"}
                      label="Регион"
                      placeholder="Выберите"
                      value={value}
                      onChangeValue={onChange}
                      options={regions || []}
                      loading={isLoadingRegions}
                      errorMessage={errorMessage}
                    />
                  );
                }}
              />

              <p className={classes.dialogContentFormText}>
                Отправляя данные, вы принимаете{" "}
                <a
                  href="https://profile.mts.ru/documents/contracts/offers/politika-obrabotka-personalnih-dannih-v-pao-mts"
                  target="_blank"
                  rel="noreferrer"
                >
                  условия доступа и&nbsp;политику обработки ПДн в ПАО "МТС"
                </a>
              </p>
            </form>

            <div className={classes.dialogFooter}>
              <MTSButton
                size="M"
                variant="primary"
                onClick={handleSubmit(onSubmit)}
                sx={{
                  width: "100%",
                  maxWidth: "324px",
                  [theme.breakpoints.down("desktopS_960")]: {
                    maxWidth: "268px",
                  },
                  [theme.breakpoints.down("tablet_768")]: {
                    maxWidth: "320px",
                  },
                }}
              >
                Отправить
              </MTSButton>
            </div>
          </div>
        </div>
      </BlockContent>
    </Dialog>
  );
};
