import React from "react";

import classNames from "classnames";

import { IMTSTab, IMTSTabSize } from "./interface";
import classes from "./mts-tabs.module.css";

interface MTSTabsProps {
  size: IMTSTabSize;
  selectedTab: number | boolean;
  tabs: IMTSTab[];
  onChange: (event: React.SyntheticEvent, newTabNumber: number) => void;
  setButtonsPosition?: React.Dispatch<React.SetStateAction<DOMRect[]>>;
  onClick?: (event: React.SyntheticEvent, tabName: string) => void;
  onMouseEnter?: (event: React.SyntheticEvent, tabName: string) => void;
}

export const MTSTabs = (props: MTSTabsProps): JSX.Element | null => {
  const {
    size,
    selectedTab = false,
    tabs = [],
    onChange,
    setButtonsPosition,
    onClick,
    onMouseEnter,
  } = props;

  const buttonsRef = React.useRef<any>(
    Array.from({ length: tabs.length }, (a: any) => React.createRef())
  );

  const tabsStyles = classNames({
    [classes.blockMTSTabs]: true,
    [classes.blockMTSTabsSizeS]: size === "s",
    [classes.blockMTSTabsSizeM]: size === "m",
    [classes.blockMTSTabsSizeL]: size === "l",
  });

  const tabLabelStyles = classNames({
    [classes.tabLabel]: true,
    [classes.tabLabelSizeS]: size === "s",
    [classes.tabLabelSizeM]: size === "m",
    [classes.tabLabelSizeL]: size === "l",
  });

  React.useEffect(() => {
    const calcButtonsPosition = (): void => {
      if (setButtonsPosition) {
        const positions: DOMRect[] = [];

        tabs.forEach((tab, index: number) => {
          if (buttonsRef?.current[index]) {
            const btnPosition: DOMRect =
              buttonsRef.current[index].current.getBoundingClientRect();
            positions.push(btnPosition);
          }
        });

        setButtonsPosition(positions);
      }
    };

    calcButtonsPosition();

    window.addEventListener("resize", calcButtonsPosition);

    return () => {
      window.removeEventListener("resize", calcButtonsPosition);
    };
  }, [setButtonsPosition, tabs]);

  if (tabs.length === 0) {
    return null;
  }

  const handleSendMetrics = (event: React.SyntheticEvent, tabName: string) => {
    if (onClick) {
      onClick(event, tabName);
    } else if (onMouseEnter) {
      onMouseEnter(event, tabName);
    }
  };

  return (
    <nav className={tabsStyles}>
      {tabs.map((tab: IMTSTab, index: number) => {
        const isShowTabCount: boolean =
          typeof tab?.count === "number" ? true : false;
        const tabCount: number =
          typeof tab?.count === "number" ? tab.count : -1;

        const tabStyles = classNames({
          [classes.tab]: true,
          [classes.tabSizeS]: size === "s",
          [classes.tabSizeM]: size === "m",
          [classes.tabSizeL]: size === "l",
          [classes.tabSelected]: selectedTab === index,
        });

        return (
          <button
            key={tab.id}
            data-testid={tab?.dataTestId || ""}
            className={tabStyles}
            onClick={(event) => {
              handleSendMetrics(event, tab.name);
              if (
                selectedTab === false ||
                selectedTab !== index ||
                tab?.type === "folder"
              ) {
                onChange(event, index);
              }
            }}
            onMouseEnter={(event) => handleSendMetrics(event, tab.name)}
            ref={buttonsRef.current[index]}
          >
            <span className={tabLabelStyles}>{tab.name}</span>
            {isShowTabCount && (
              <span className={classes.tabCount}>
                {tabCount >= 1000 ? "999+" : tabCount}
              </span>
            )}
          </button>
        );
      })}
    </nav>
  );
};
