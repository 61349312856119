import { PRODUCT_NAME } from "constants/common";

import { Helmet } from "react-helmet";

interface IAppHeletProps {
  title?: string;
}

export const AppHelmet = (props: IAppHeletProps): JSX.Element => {
  const { title } = props;

  return (
    <Helmet>
      <title>{title ? `${title} | ${PRODUCT_NAME}` : PRODUCT_NAME}</title>
    </Helmet>
  );
};
