import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const Definition = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="134"
      height="128"
      viewBox="0 0 134 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M115.474 58.1351C114.587 56.3172 112.71 54.9555 109.834 54.1551C106.96 53.3551 103.203 53.1537 98.7926 53.5747C89.9792 54.416 78.7745 57.7208 67.2951 63.3218C55.8157 68.9229 46.3152 75.7205 40.2282 82.1494C37.182 85.3667 35.0289 88.4516 33.8904 91.2096C32.7512 93.9691 32.6695 96.2864 33.5565 98.1043C34.4435 99.9222 36.3203 101.284 39.1963 102.084C42.0709 102.884 45.8274 103.086 50.238 102.665C59.0513 101.823 70.256 98.5186 81.7354 92.9176C93.2148 87.3165 102.715 80.5189 108.802 74.09C111.849 70.8727 114.002 67.7878 115.14 65.0298C116.279 62.2703 116.361 59.953 115.474 58.1351Z"
        stroke="black"
        strokeWidth="2"
      />
      <path
        d="M94.0635 68.2804C93.7947 67.7264 93.1383 67.22 91.8573 66.9329C90.5891 66.6486 88.8724 66.6226 86.7964 66.8878C82.6552 67.4167 77.3127 69.0741 71.7758 71.7609C66.2389 74.4478 61.6311 77.619 58.653 80.5448C57.1601 82.0115 56.1182 83.3761 55.5567 84.5483C54.9896 85.7322 54.9813 86.5612 55.2501 87.1152C55.5189 87.6692 56.1753 88.1756 57.4563 88.4627C58.7245 88.747 60.4412 88.773 62.5172 88.5078C66.6584 87.9789 72.0008 86.3215 77.5378 83.6347C83.0747 80.9478 87.6825 77.7766 90.6606 74.8508C92.1535 73.3841 93.1954 72.0195 93.7569 70.8473C94.324 69.6634 94.3323 68.8344 94.0635 68.2804Z"
        stroke="black"
        strokeWidth="2"
      />
      <path
        d="M21.08 60.0081L6.64453 61.9963C6.64453 61.9963 6.69091 61.3453 6.84753 60.9664C6.80543 60.6371 7.70908 59.8523 7.70908 59.8523L32 43.5L42.5 42.5L21.08 60.0081Z"
        fill="#D7D7D7"
      />
      <path
        d="M66.7251 80.6661L74.4995 79.5L20.511 60.0078C20.511 60.0078 9.2631 61.5333 6.62899 61.8701C6.62573 61.8705 6.65047 62.0095 6.67487 62.2004C6.80171 63.1926 7.13099 63.1505 8.90424 63.9323C10.465 64.6204 52.346 77.319 62.3625 80.3529C63.7807 80.7825 65.2597 80.886 66.7251 80.6661Z"
        fill="#E7E7E7"
      />
      <path
        d="M42.5 42.5L31.9999 43.5C31.9999 43.5 37.8891 17.496 39.4118 16.2973C40.9345 15.0987 49.5 12.5 49.5 12.5L42.5 42.5Z"
        fill="#E7E7E7"
      />
      <g filter="url(#filter0_f_6269_464951)">
        <path
          d="M9.09402 63.1494L20.8467 60.9349L20.5099 58.3006L8.75727 60.5152C8.75727 60.5152 7.51784 61.0083 7.64412 61.9961C7.77041 62.984 9.09402 63.1494 9.09402 63.1494Z"
          fill="url(#paint0_linear_6269_464951)"
        />
      </g>
      <path
        d="M42.5 42.5L20.7871 58.0395C19.4644 58.9861 19.7647 61.0316 21.3036 61.5582L73.5105 79.4205C75.9483 80.2546 78.2341 77.8302 77.2581 75.4457L51.9097 13.5122C51.1576 11.6747 48.4769 11.9374 48.0959 13.8859L42.5 42.5Z"
        fill="url(#paint1_radial_6269_464951)"
      />
      <defs>
        <filter
          id="filter0_f_6269_464951"
          x="5.63672"
          y="56.3007"
          width="17.2109"
          height="8.84875"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="1"
            result="effect1_foregroundBlur_6269_464951"
          />
        </filter>
        <linearGradient
          id="paint0_linear_6269_464951"
          x1="15.2491"
          y1="60.3546"
          x2="15.4595"
          y2="62.001"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CCCCCD" />
          <stop offset="1" stopColor="#F2F2F2" />
        </linearGradient>
        <radialGradient
          id="paint1_radial_6269_464951"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(45.4054 45.2049) rotate(-97.2651) scale(49.5632 36.1361)"
        >
          <stop offset="0.257841" stopColor="white" />
          <stop offset="1" stopColor="#EAEAEA" />
        </radialGradient>
      </defs>
    </SvgIcon>
  );
};

export default Definition;
