import React from "react";

import { useInView } from "react-intersection-observer";

import { BlockContent } from "components/block-content";
import { BlockWide } from "components/block-wide";
import { MTSIcon } from "components/mts-icon";
import {
  GAE_ACTION_GROUP,
  GAE_EVENT,
  GAE_EVENT_ACTION,
  GAE_EVENT_CATEGORY,
  GAE_EVENT_LABEL,
  useGoogleAnalyticsEvents,
} from "hooks/google-analytics";

import classes from "./about.module.css";

interface IApplicationInfo {
  id: number;
  icon: JSX.Element;
  step: string;
  title: string | JSX.Element;
  description: JSX.Element[];
}

export const About = (): JSX.Element => {
  const { sendGAE } = useGoogleAnalyticsEvents();
  const { ref: blockAboutRef, inView: inViewBlockAbout } = useInView({
    initialInView: false,
    threshold: 0.5,
    triggerOnce: true,
  });
  const [applicationItems] = React.useState<IApplicationInfo[]>([
    {
      id: 1,
      icon: <MTSIcon.Satellite sx={{ fontSize: 128, color: "transparent" }} />,
      step: "Шаг 1",
      title: <>Определяем местоположение по&nbsp;спутникам</>,
      description: [
        <>
          Атмосферные факторы влияют на точность сигнала спутника, координаты
          объекта определяются с точностью&nbsp;
          <span style={{ whiteSpace: "nowrap" }}>5-15 м</span>
        </>,
      ],
    },
    {
      id: 2,
      icon: <MTSIcon.Network sx={{ fontSize: 128, color: "transparent" }} />,
      step: "Шаг 2",
      title: "Референсная сеть формирует поправки",
      description: [
        <>
          Референсная сеть – cеть специальных станций с точными координатами,
          которые накапливают спутниковые данные о местоположении объектов и
          формируют корректирующие поправки для ваших устройств. Рассчитанные
          поправки хранятся на сервере LocationPro.
        </>,
      ],
    },
    {
      id: 3,
      icon: <MTSIcon.Definition sx={{ fontSize: 128, color: "transparent" }} />,
      step: "Шаг 3",
      title: "Улучшаем точность координат до 2 см",
      description: [
        <>
          Используя наши поправки и данные от спутника, вы получаете точность
          измерений <span style={{ whiteSpace: "nowrap" }}>2-5 см</span>&nbsp;
          в&nbsp;реальном времени
        </>,
      ],
    },
  ]);

  React.useEffect(() => {
    if (inViewBlockAbout) {
      sendGAE({
        id: 41,
        event: GAE_EVENT.vntMain,
        eventCategory: GAE_EVENT_CATEGORY.glavnaya,
        eventAction: GAE_EVENT_ACTION.block_show,
        eventLabel: GAE_EVENT_LABEL.chto_takoe_locationpro,
        eventValue: null,
        eventContent: null,
        buttonLocation: null,
        filterName: null,
        actionGroup: GAE_ACTION_GROUP.non_interactions,
        productName: null,
      });
    }
  }, [inViewBlockAbout, sendGAE]);

  return (
    <div ref={blockAboutRef} className={classes.blockAbout}>
      <BlockWide>
        <BlockContent>
          <div className={classes.info}>
            <h2 className={classes.infoTitle}>Принцип работы сервиса</h2>
            <p className={classes.infoDesc}>
              Сеть референсных станций МТС на надёжной инфраструктуре
              телеком-оператора.
              <br />
              Решение позволяет определять координаты объекта с высокой
              точностью.
            </p>
          </div>
        </BlockContent>
      </BlockWide>

      <BlockWide>
        <BlockContent>
          <div className={classes.cards}>
            {applicationItems.map(
              ({ id, icon, step, title, description }: IApplicationInfo) => (
                <div className={classes.card} key={id}>
                  <div className={classes.cardHeader}>
                    <div className={classes.headerIcon}>{icon}</div>
                    <div className={classes.headerInfo}>
                      <div className={classes.headerInfoStep}>
                        <p className={classes.headerInfoStepText}>{step}</p>
                      </div>
                      <h3 className={classes.headerInfoTitle}>{title}</h3>
                    </div>
                  </div>
                  <div className={classes.cardContent}>
                    {description.map(
                      (
                        descriptionText: string | JSX.Element,
                        index: number
                      ) => (
                        <p className={classes.cardContentText} key={index}>
                          {descriptionText}
                        </p>
                      )
                    )}
                  </div>
                </div>
              )
            )}
          </div>
        </BlockContent>
      </BlockWide>
    </div>
  );
};
