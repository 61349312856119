import { IS_DEVELOPMENT_MODE } from "constants/env";
import {
  HTTPStatusCode,
  LS_USER_FROM_LANDING,
  PROFILE_ROLE,
} from "constants/keys";
import {
  PATH_ADD_SUBSCRIPTIONS,
  PATH_PERSONAL_AREA,
  PATH_TARIFFS,
  PATH_ERROR,
  PATH_SUBSCRIPTIONS,
} from "constants/routes";

import React from "react";

import { useLocation, useHistory } from "react-router-dom";

import { useContextMain } from "hooks/use-context-main";
import { AuthService } from "services/auth";
import { isAdminHasAnySubscriptions } from "utils/is-admin-has-any-subscriptions";

export const useCheckAuthorization = (): void => {
  const location = useLocation();
  const history = useHistory();
  const { isAuthorized, setIsAuthorized, setProfile, setIsLoadingAuthData } =
    useContextMain();
  const isFromLanding: boolean =
    localStorage.getItem(LS_USER_FROM_LANDING) === "1";

  React.useEffect(() => {
    const checkAuth = async () => {
      let currentProfileRole: string = "";

      try {
        setIsLoadingAuthData(true);

        const responseRefresh = await AuthService.refresh();

        if (responseRefresh.status === HTTPStatusCode.OK_200) {
          const responseProfile = await AuthService.getProfile();

          if (responseProfile.status === HTTPStatusCode.OK_200) {
            if (IS_DEVELOPMENT_MODE) {
              console.log("PROFILE DATA", responseProfile.data);
            }
            setProfile(responseProfile.data);
            currentProfileRole = responseProfile?.data?.role || "";
          } else {
            setProfile(null);
          }

          setIsAuthorized(true);
        }
      } catch (error: any) {
        if (isAuthorized) {
          setIsAuthorized(false);
        }

        if (error.response === undefined) {
          history.push(PATH_ERROR);
        }
      } finally {
        if (currentProfileRole === PROFILE_ROLE.CUSTOMER_BASE) {
          if (
            location.pathname === PATH_TARIFFS ||
            location.pathname === PATH_ADD_SUBSCRIPTIONS
          ) {
            history.push(PATH_PERSONAL_AREA);
          }
        }

        // NOTE: При входи проверять если у пользователя подписки, если есть то отправлять на страницу с подписками
        if (
          currentProfileRole === PROFILE_ROLE.CUSTOMER_ADMIN &&
          location.pathname === PATH_TARIFFS
        ) {
          const isHasAnySubscriptions: boolean =
            await isAdminHasAnySubscriptions();

          if (isHasAnySubscriptions && isFromLanding) {
            history.push(PATH_SUBSCRIPTIONS);
            localStorage.removeItem(LS_USER_FROM_LANDING);
          }
        }

        setIsLoadingAuthData(false);
      }
    };

    checkAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
