import { IDiscountDTO } from "interfaces";

export const getDiscountPercentByCount = (
  selectedSubscriptionCount: number,
  discounts: IDiscountDTO[]
): number => {
  let discountPercent: number = 0;

  if (discounts?.length) {
    for (let discount of discounts) {
      const { percent, min, max } = discount;

      if (
        selectedSubscriptionCount >= min &&
        selectedSubscriptionCount <= max
      ) {
        discountPercent = percent;
        break;
      }
    }
  }

  return discountPercent;
};
