import React from "react";

import { BlockWide } from "components/block-wide";

import { Slider } from "./components/slider";
import classes from "./gadgets.module.css";

export const Gadgets = React.memo((): JSX.Element => {
  return (
    <div className={classes.blockGadgets}>
      <BlockWide>
        <Slider />
      </BlockWide>
    </div>
  );
});
