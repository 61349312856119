import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const ArrowsMin = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M39.965 4.56072C39.8935 4.38688 39.724 4.27347 39.5361 4.27347H36.6205C36.6195 4.03759 36.5293 3.80237 36.3491 3.62271C36.1747 3.44887 35.9483 3.36099 35.7206 3.3542L35.7209 0.462979C35.7209 0.27622 35.6086 0.107227 35.4356 0.0354949C35.2645 -0.0359134 35.0636 0.00221436 34.9309 0.133722L31.7847 3.24373C31.6968 3.33098 31.6466 3.44988 31.6469 3.57396L31.6544 7.00284L30.2181 8.43783C27.0075 5.55661 22.766 3.79953 18.1209 3.79953C8.12882 3.79953 0 11.9194 0 21.8998C0 31.8801 8.12915 40 18.1209 40C28.1127 40 36.2418 31.8801 36.2418 21.8998C36.2418 17.2246 34.4578 12.958 31.5348 9.74278L32.9704 8.30879L36.3997 8.30136C36.5216 8.30136 36.6384 8.25321 36.7248 8.16759L39.8619 5.06634C39.9962 4.93484 40.0366 4.73483 39.9651 4.56099L39.965 4.56072ZM32.9959 21.9C32.9959 30.0929 26.3231 36.7577 18.1212 36.7577C9.91924 36.7577 3.2465 30.0925 3.2465 21.9C3.2465 13.7074 9.91932 7.04224 18.1212 7.04224C21.872 7.04224 25.3 8.43876 27.9195 10.7352L25.1272 13.5246C23.2286 11.9372 20.785 10.9798 18.1214 10.9798C12.0933 10.9798 7.1886 15.8789 7.1886 21.9001C7.1886 27.9214 12.0933 32.8205 18.1214 32.8205C24.1496 32.8205 29.0543 27.9214 29.0543 21.9001C29.0543 19.205 28.0696 16.7367 26.4438 14.83L29.2361 12.0405C31.5727 14.6658 32.9963 18.1189 32.9963 21.9004L32.9959 21.9ZM26.2713 21.9C26.2713 26.389 22.615 30.0412 18.1208 30.0412C13.6266 30.0412 9.97025 26.389 9.97025 21.9C9.97025 17.4109 13.6266 13.7587 18.1208 13.7587C20.017 13.7587 21.7596 14.4147 23.1458 15.5032L20.3224 18.3243C19.6809 17.9288 18.9285 17.6962 18.1204 17.6962C15.7998 17.6962 13.9119 19.5819 13.9119 21.8999C13.9119 24.2179 15.7998 26.1036 18.1204 26.1036C20.4411 26.1036 22.3289 24.2179 22.3289 21.8999C22.3289 21.0588 22.0776 20.2759 21.65 19.618L24.4669 16.8034C25.5935 18.2003 26.2713 19.9716 26.2713 21.8999L26.2713 21.9Z" />
    </SvgIcon>
  );
};

export default ArrowsMin;
