import {
  FIELD_NAMES,
  PHONE_MASK,
  PROMPT_MESSAGE_UNSAVED_DATA,
  SUBSCRIPTION_DURATIONS,
  SUBSCRIPTION_SERVICE_KINDS,
  SUBSCRIPTION_STATUS_FILLED,
} from "constants/keys";
import { PATH_PAYMENTS, PATH_SUBSCRIPTION_TAB_RENEW } from "constants/routes";

import React from "react";

import { useLocation } from "react-router-dom";

import { Controller, useWatch } from "react-hook-form";
import InputMask from "react-input-mask";
import { Prompt, useHistory } from "react-router";

import { DeleteSubscriptionMessage } from "components/delete-subscription-message";
import { DialogConfirm, IDialogConfirmProps } from "components/dialog-confirm";
import { MTSAutocomplete } from "components/mts-autocomplete";
import { MTSButton } from "components/mts-button";
import { MTSButtonIcon } from "components/mts-button-icon";
import { MTSIcon } from "components/mts-icon";
import { MTSInput } from "components/mts-input";
import { MTSLink } from "components/mts-link";
import {
  MessageUnpaidPaymentIfRenew,
  NotificationMessage,
} from "components/notification-message";
import { ISubscriptionInfoLocationState } from "components/pages/subsription-info-page";
import { RenewSubscriptionMessage } from "components/renew-subscription-message";
import { useDurations } from "hooks/use-durations";
import { useMutationDeleteSubscription } from "hooks/use-mutation-delele-subscription";
import { useMutationPaymentActivation } from "hooks/use-mutation-payment-activation";
import { useMutationProlongationForSubscriptions } from "hooks/use-mutation-prolongation-for-subscriptions";
import { useMutationUpdateSubscriptionInfoByBilling } from "hooks/use-mutation-update-subscription-info-by-billing";
import { useMutationUpdateSubscriptionInfoByProfile } from "hooks/use-mutation-update-subscription-info-by-profile";
import { useServiceKinds } from "hooks/use-service-kinds";
import {
  IUpdateSubscriptionInfoPayload,
  ISubscriptionDTO,
  ISubscriptionResponseDTO,
  IUpdateSubscriptionInfoByBillingServicePayload,
  IDeviceRequestDTO,
} from "interfaces";
import { getPhoneNumberWithoutMask } from "utils/get-phone-number-without-mask";
import { getStatusRenewDialog } from "utils/get-status-renew-dialog";
import { isSubscriptionReadyToRenew } from "utils/is-subscription-ready-to-renew.ts";
import { SubscriptionStatusCheckerByCode } from "utils/subscription-status-checker-by-code";

import { useSubscriptionInfoForm } from "../../hooks/use-subscription-info-form";
import { ISubscriptionInfoFormStruct } from "../../interfaces";
import { SubscriptionInfoHeader } from "../subscription-info-header";
import { SubscriptionInfoStepper } from "../subscription-info-stepper";

import classes from "./subscription-info-form.module.css";

interface ISubscriptionInfoFormProps {
  subscriptionId: string;
  profileSubscriptionData: ISubscriptionResponseDTO | undefined;
  billingSubscriptionData: ISubscriptionDTO | undefined;
  initialFormData: ISubscriptionInfoFormStruct;
  subscriptionCountCanRenew: number;
  isCanCreatePayment: boolean | undefined;
}

export const SubscriptionInfoForm = (props: ISubscriptionInfoFormProps) => {
  const {
    profileSubscriptionData,
    billingSubscriptionData,
    subscriptionId,
    initialFormData,
    subscriptionCountCanRenew,
    isCanCreatePayment,
  } = props;

  const history = useHistory();
  const location = useLocation();
  const locationState = location.state as ISubscriptionInfoLocationState;

  const [dialogConfirm, setDialogConfirm] =
    React.useState<IDialogConfirmProps | null>(null);
  const [initialPhone, setInitialPhone] = React.useState<string>(
    initialFormData.phone ?? ""
  );
  const [isPhoneChanged, setIsPhoneChanged] = React.useState<boolean>(false);

  const subscriptionDurationCode: string =
    billingSubscriptionData?.duration ?? "";
  const isTestSubscription: boolean =
    subscriptionDurationCode === SUBSCRIPTION_DURATIONS.TWO_WEEKS;
  const isServiceKindRTK: boolean =
    billingSubscriptionData?.serviceKind ===
    SUBSCRIPTION_SERVICE_KINDS.RTK_RINEX;
  const expiredOnDate: string = billingSubscriptionData?.expiredOn ?? "";
  const subscriptionStatusCode: string = billingSubscriptionData?.status ?? "";
  const isSubscriptionInPrologation: boolean =
    !!billingSubscriptionData?.inProlongation;

  const isPaidSub: boolean = SubscriptionStatusCheckerByCode.isPaid(
    subscriptionStatusCode
  );
  const isExpiredSub: boolean = SubscriptionStatusCheckerByCode.isExpired(
    subscriptionStatusCode
  );

  const isReadyActivate: boolean =
    profileSubscriptionData?.editStatus === SUBSCRIPTION_STATUS_FILLED.FILLED;

  const isReadyToRenew: boolean = isSubscriptionReadyToRenew({
    statusCode: subscriptionStatusCode,
    expiredDate: expiredOnDate,
    durationCode: subscriptionDurationCode,
    inProlongation: isSubscriptionInPrologation,
  });

  const {
    register,
    handleSubmit,
    reset,
    watch,
    control,
    trigger,
    setValue,
    setError,
    formState: { isDirty, errors, dirtyFields },
  } = useSubscriptionInfoForm({
    initialFormData: initialFormData,
    isServiceKindRTK: isServiceKindRTK,
  });
  watch();
  const currentPhone: string = useWatch({
    control,
    name: FIELD_NAMES.SUBSCRIPTION_PHONE,
  });

  const { data: durations, isLoading: isLoadingDurations } = useDurations();
  const { data: serviceKinds, isLoading: isLoadingServiceKinds } =
    useServiceKinds();
  const mutationDeleteSubscription = useMutationDeleteSubscription();
  const mutationUpdateSubscriptionInfoByProfile =
    useMutationUpdateSubscriptionInfoByProfile();
  const mutationUpdateSubscriptionInfoByBilling =
    useMutationUpdateSubscriptionInfoByBilling();
  const mutationPaymentActivation = useMutationPaymentActivation();
  const mutationDoProlongationSubscription =
    useMutationProlongationForSubscriptions();

  React.useEffect(() => {
    if (!isPhoneChanged && initialPhone && currentPhone) {
      const initialPhoneWithoutMask: string =
        getPhoneNumberWithoutMask(initialPhone);
      const currentPhoneWithoutMask: string =
        getPhoneNumberWithoutMask(currentPhone);

      if (
        initialPhoneWithoutMask &&
        currentPhoneWithoutMask &&
        initialPhoneWithoutMask !== currentPhoneWithoutMask
      ) {
        const closeDialog = (): void => {
          setDialogConfirm((prevDialogProps) => ({
            ...prevDialogProps,
            isOpen: false,
          }));
        };

        const handleCloseDialog = (): void => {
          setValue(FIELD_NAMES.SUBSCRIPTION_PHONE, initialPhone);
          closeDialog();
        };

        const handleCancelDialog = (): void => {
          setValue(FIELD_NAMES.SUBSCRIPTION_PHONE, initialPhone);
          closeDialog();
        };

        const handleConfirmDialog = (): void => {
          setIsPhoneChanged(true);
          setInitialPhone(currentPhone);

          if (isServiceKindRTK) {
            setValue(FIELD_NAMES.SUBSCRIPTION_DEVICE_PASSWORD, "");
            setError(
              FIELD_NAMES.SUBSCRIPTION_DEVICE_PASSWORD,
              {
                type: "focus",
                message: "Измените пароль устройства",
              },
              { shouldFocus: true }
            );
          }

          closeDialog();
        };

        const dialogConfirmProps: IDialogConfirmProps = {
          isOpen: true,
          title: "Изменение номера телефона",
          content: (
            <p className={classes.dialogText}>
              Доступ по номеру&nbsp;
              <span style={{ whiteSpace: "nowrap" }}>{initialPhone}</span>
              &nbsp; будет прекращён.
              <br />
              Вы точно хотите изменить пользователя в данной подписке?
            </p>
          ),
          confirmLabel: "Изменить",
          handleCancel: () => handleCancelDialog(),
          handleClose: () => handleCloseDialog(),
          handleConfirm: () => handleConfirmDialog(),
        };

        setDialogConfirm(dialogConfirmProps);
      }
    }
  }, [
    currentPhone,
    initialPhone,
    isPhoneChanged,
    isServiceKindRTK,
    setError,
    setValue,
  ]);

  // NOTE: Данный эффект нужен для <InputMask...
  React.useEffect(() => {
    reset(initialFormData);
    setInitialPhone(initialFormData?.phone ?? "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSaveSubscriptionInfo = async (
    data: ISubscriptionInfoFormStruct
  ) => {
    let isErrorOnSave: boolean = false;

    // NOTE: SAVE DATA PROFILE SERVICE
    const payloadForProfileService: IUpdateSubscriptionInfoPayload = {
      id: subscriptionId,
      name: data.name,
      email: data.email,
      subscribed: {
        login: getPhoneNumberWithoutMask(data.phone),
      },
      description: data.description,
    };

    if (isServiceKindRTK) {
      const devicePayload: IDeviceRequestDTO = {
        password: data[FIELD_NAMES.SUBSCRIPTION_DEVICE_PASSWORD],
      };

      payloadForProfileService.device = devicePayload;
    }

    await mutationUpdateSubscriptionInfoByProfile.mutateAsync(
      payloadForProfileService,
      {
        onSuccess: () => {
          setIsPhoneChanged(false);
          setInitialPhone(data.phone);
        },
        onError: () => {
          isErrorOnSave = true;
        },
      }
    );

    // NOTE: SAVE DATA IN BILLING SERVICE
    const prevDuration: string = initialFormData?.duration?.code ?? "";
    const newDuration: string = data?.duration?.code ?? "";

    if (prevDuration && newDuration && prevDuration !== newDuration) {
      const payloadForBillingService: IUpdateSubscriptionInfoByBillingServicePayload =
        {
          id: subscriptionId,
          duration: newDuration,
        };

      await mutationUpdateSubscriptionInfoByBilling.mutateAsync(
        payloadForBillingService,
        {
          onError: () => {
            isErrorOnSave = true;
          },
        }
      );
    }

    if (!isErrorOnSave) {
      reset(data);
    }
  };

  const handleRedirectToSubscriptionTab = (): void => {
    history.goBack();
  };

  const handleActivateSubscription = (): void => {
    mutationPaymentActivation.mutate(subscriptionId);
  };

  const handleRenewSubscription = (id: string): void => {
    const paymentId = new URLSearchParams(locationState?.from?.search).get(
      "payment"
    );
    const subscriptionCountCanRenewByPayment: number =
      locationState?.from?.subscriptionCountCanRenew;

    const doProlongation = (): void => {
      mutationDoProlongationSubscription.mutate([Number(id)]);
    };

    if (getStatusRenewDialog().isDontShowDialog) {
      doProlongation();
    } else {
      if (
        paymentId
          ? subscriptionCountCanRenewByPayment > 5
          : subscriptionCountCanRenew > 5
      ) {
        const closeDialog = (): void => {
          setDialogConfirm((prevDialogProps) => ({
            ...prevDialogProps,
            isOpen: false,
          }));
        };

        const handleCancelDialog = (): void => {
          closeDialog();
          doProlongation();
        };

        const handleConfirmDialog = (): void => {
          closeDialog();

          if (paymentId) {
            history.push(
              `${locationState.from.pathname}${locationState.from.search}`
            );
          } else {
            history.push(PATH_SUBSCRIPTION_TAB_RENEW);
          }
        };

        const dialogProps: IDialogConfirmProps = {
          isOpen: true,
          title: "Скидка за продление подписок",
          content: <RenewSubscriptionMessage />,
          confirmLabel: "Продлить несколько",
          cancelLabel: "Продлить одну",
          handleCancel: () => handleCancelDialog(),
          handleClose: () => closeDialog(),
          handleConfirm: () => handleConfirmDialog(),
        };

        setDialogConfirm(dialogProps);
      } else {
        doProlongation();
      }
    }
  };

  const handleDeleteSubscription = (id: string): void => {
    const closeDialog = (): void => {
      setDialogConfirm((prevDialogProps) => ({
        ...prevDialogProps,
        isOpen: false,
      }));
    };

    const handleConfirmDialog = (): void => {
      mutationDeleteSubscription.mutate(id, {
        onSuccess: () => {
          handleRedirectToSubscriptionTab();
          closeDialog();
        },
      });
    };

    const dialogProps: IDialogConfirmProps = {
      isOpen: true,
      title: "Удаление подписки",
      content: <DeleteSubscriptionMessage />,
      confirmLabel: "Удалить",
      handleCancel: () => closeDialog(),
      handleClose: () => closeDialog(),
      handleConfirm: () => handleConfirmDialog(),
    };

    setDialogConfirm(dialogProps);
  };

  return (
    <>
      <div className={classes.container}>
        <div className={classes.mainBlock}>
          <SubscriptionInfoHeader
            name={profileSubscriptionData?.name ?? ""}
            statusCode={subscriptionStatusCode}
            createdDate={profileSubscriptionData?.created ?? ""}
            expiredOnDate={billingSubscriptionData?.expiredOn ?? ""}
          />

          {isSubscriptionInPrologation && (
            <div className={classes.mainBlockMessage}>
              <NotificationMessage
                show={true}
                text={
                  <>
                    Подписка в процессе продления. Ждём от вас оплаты{" "}
                    <MTSLink to={PATH_PAYMENTS}>счёта.</MTSLink>
                  </>
                }
              />
            </div>
          )}

          {!isSubscriptionInPrologation &&
            isReadyToRenew &&
            isCanCreatePayment === false && (
              <div className={classes.mainBlockMessage}>
                <NotificationMessage
                  show={true}
                  text={<MessageUnpaidPaymentIfRenew isSubscriptionInfoPage />}
                />
              </div>
            )}

          <div className={classes.mainBlockContent}>
            <div className={classes.mainBlockContentForm}>
              <form className={classes.form}>
                <div className={classes.formBlock}>
                  <div className={classes.formBlockInputs}>
                    <div className={classes.formBlockInput}>
                      <MTSInput
                        required
                        label="Название подписки"
                        placeholder="Введите"
                        description="От 2 до 56 символов"
                        {...register(FIELD_NAMES.SUBSCRIPTION_NAME)}
                        errorMessage={
                          errors?.[FIELD_NAMES.SUBSCRIPTION_NAME]?.message
                        }
                        disabled={isSubscriptionInPrologation}
                        onClear={() => {
                          setValue(FIELD_NAMES.SUBSCRIPTION_NAME, "");
                          trigger(FIELD_NAMES.SUBSCRIPTION_NAME);
                        }}
                        isDirty={
                          dirtyFields?.[FIELD_NAMES.SUBSCRIPTION_NAME] ?? false
                        }
                      />
                    </div>

                    <div className={classes.formBlockInput}>
                      <Controller
                        control={control}
                        name={FIELD_NAMES.SUBSCRIPTION_SERVICE_KIND}
                        render={(renderProps) => {
                          const { field, fieldState } = renderProps;
                          const { onChange, value } = field;
                          const errorMessage: string =
                            fieldState?.error?.message || "";

                          return (
                            <MTSAutocomplete
                              required
                              disabled
                              size="m"
                              label="Тип доступа"
                              placeholder="Выберите"
                              value={value}
                              onChangeValue={onChange}
                              options={serviceKinds || []}
                              loading={isLoadingServiceKinds}
                              errorMessage={errorMessage}
                            />
                          );
                        }}
                      />
                    </div>

                    <div className={classes.formBlockInput}>
                      <Controller
                        control={control}
                        name={FIELD_NAMES.SUBSCRIPTION_DURATION}
                        render={(renderProps) => {
                          const { field, fieldState } = renderProps;
                          const { onChange, value } = field;
                          const errorMessage: string =
                            fieldState?.error?.message || "";

                          return (
                            <MTSAutocomplete
                              required
                              disabled={
                                !isExpiredSub ||
                                isSubscriptionInPrologation ||
                                (isExpiredSub && isTestSubscription)
                              }
                              size="m"
                              label="Срок действия"
                              placeholder="Выберите"
                              value={value}
                              onChangeValue={onChange}
                              options={durations || []}
                              loading={isLoadingDurations}
                              errorMessage={errorMessage}
                              disabledOptionCodes={[
                                SUBSCRIPTION_DURATIONS.TWO_WEEKS,
                              ]}
                            />
                          );
                        }}
                      />
                    </div>

                    <div className={classes.formBlockInput}>
                      <MTSInput
                        label="Комментарий"
                        placeholder="Например, ФИО владельца подписки"
                        description="Не более 255 символов"
                        {...register(FIELD_NAMES.SUBSCRIPTION_DESCRIPTION)}
                        errorMessage={
                          (
                            errors?.[
                              FIELD_NAMES.SUBSCRIPTION_DESCRIPTION
                            ] as any
                          )?.message
                        }
                        disabled={isSubscriptionInPrologation}
                        onClear={() => {
                          setValue(FIELD_NAMES.SUBSCRIPTION_DESCRIPTION, "");
                          trigger(FIELD_NAMES.SUBSCRIPTION_DESCRIPTION);
                        }}
                        isDirty={
                          dirtyFields?.[FIELD_NAMES.SUBSCRIPTION_DESCRIPTION] ??
                          false
                        }
                      />
                    </div>
                  </div>
                </div>

                <div className={classes.formBlock}>
                  <h4 className={classes.formBlockTitle}>Пользователь</h4>
                  <div className={classes.formBlockInputs}>
                    <div className={classes.formBlockInput}>
                      <InputMask
                        mask={PHONE_MASK}
                        {...register(FIELD_NAMES.SUBSCRIPTION_PHONE)}
                        disabled={isSubscriptionInPrologation}
                      >
                        {(inputProps: any) => (
                          <MTSInput
                            required
                            type="tel"
                            label="Телефон"
                            placeholder="+7"
                            {...inputProps}
                            errorMessage={
                              errors?.[FIELD_NAMES.SUBSCRIPTION_PHONE]?.message
                            }
                            disabled={isSubscriptionInPrologation}
                            onClear={() => {
                              setValue(FIELD_NAMES.SUBSCRIPTION_PHONE, "");
                              trigger(FIELD_NAMES.SUBSCRIPTION_PHONE);
                            }}
                            isDirty={
                              dirtyFields?.[FIELD_NAMES.SUBSCRIPTION_PHONE] ??
                              false
                            }
                          />
                        )}
                      </InputMask>
                    </div>
                    <div className={classes.formBlockInput}>
                      <MTSInput
                        required
                        label="Email"
                        placeholder="user@company.ru"
                        infoText="На email придёт информация по подписке"
                        {...register(FIELD_NAMES.SUBSCRIPTION_EMAIL)}
                        errorMessage={
                          errors?.[FIELD_NAMES.SUBSCRIPTION_EMAIL]?.message
                        }
                        disabled={isSubscriptionInPrologation}
                        onClear={() => {
                          setValue(FIELD_NAMES.SUBSCRIPTION_EMAIL, "");
                          trigger(FIELD_NAMES.SUBSCRIPTION_EMAIL);
                        }}
                        isDirty={
                          dirtyFields?.[FIELD_NAMES.SUBSCRIPTION_EMAIL] ?? false
                        }
                      />
                    </div>
                  </div>
                </div>

                {isServiceKindRTK && (
                  <div className={classes.formBlock}>
                    <h4 className={classes.formBlockTitle}>Устройство</h4>
                    <div className={classes.formBlockInputs}>
                      <div className={classes.formBlockInput}>
                        <MTSInput
                          required
                          label="Логин"
                          placeholder="Введите"
                          {...register(FIELD_NAMES.SUBSCRIPTION_DEVICE_LOGIN)}
                          disabled
                        />
                      </div>
                      <div className={classes.formBlockInput}>
                        <MTSInput
                          required
                          type="password"
                          label="Пароль"
                          placeholder="Введите"
                          description="5 символов: строчные буквы a-z и цифры"
                          {...register(
                            FIELD_NAMES.SUBSCRIPTION_DEVICE_PASSWORD
                          )}
                          errorMessage={
                            errors?.[FIELD_NAMES.SUBSCRIPTION_DEVICE_PASSWORD]
                              ?.message
                          }
                          disabled={isSubscriptionInPrologation}
                          isDirty={
                            dirtyFields?.[
                              FIELD_NAMES.SUBSCRIPTION_DEVICE_PASSWORD
                            ] ?? false
                          }
                        />
                      </div>
                    </div>
                  </div>
                )}
              </form>

              {!isExpiredSub && (
                <div className={classes.stepperBlock}>
                  <SubscriptionInfoStepper
                    subcriptionStatus={subscriptionStatusCode || ""}
                    subscriptionEditStatus={
                      profileSubscriptionData?.editStatus || ""
                    }
                  />
                </div>
              )}

              {isExpiredSub && (
                <div className={classes.buttonDeleteBlock}>
                  <MTSButtonIcon
                    variant="outline"
                    size="S"
                    Icon={MTSIcon.DeleteOutline}
                    iconSize={24}
                    onClick={() => {
                      handleDeleteSubscription(subscriptionId);
                    }}
                    disabled={isSubscriptionInPrologation}
                  />
                </div>
              )}
            </div>

            {isPaidSub && (
              <div className={classes.buttonWrapper}>
                <MTSButton
                  variant="primary"
                  size="M"
                  onClick={handleActivateSubscription}
                  disabled={!isReadyActivate}
                  loading={mutationPaymentActivation.isLoading}
                >
                  Активировать подписку
                </MTSButton>
              </div>
            )}

            {isReadyToRenew && (
              <div className={classes.buttonWrapper}>
                <MTSButton
                  variant="primary"
                  size="M"
                  onClick={() => handleRenewSubscription(subscriptionId)}
                  loading={mutationDoProlongationSubscription.isLoading}
                  disabled={
                    isSubscriptionInPrologation || isCanCreatePayment === false
                  }
                >
                  Продлить подписку
                </MTSButton>
              </div>
            )}
          </div>
        </div>
        <div
          className={classes.footerBlock}
          style={{
            visibility: isDirty ? "visible" : "hidden",
          }}
        >
          <MTSButton
            variant="secondary"
            size="M"
            sx={{ width: 192 }}
            onClick={() => handleRedirectToSubscriptionTab()}
          >
            Отмена
          </MTSButton>

          <MTSButton
            variant="primary"
            size="M"
            sx={{ width: 192 }}
            onClick={handleSubmit(handleSaveSubscriptionInfo)}
            loading={
              mutationUpdateSubscriptionInfoByProfile.isLoading ||
              mutationUpdateSubscriptionInfoByBilling.isLoading
            }
          >
            Сохранить
          </MTSButton>
        </div>
      </div>

      <DialogConfirm {...dialogConfirm} />
      <Prompt when={isDirty} message={PROMPT_MESSAGE_UNSAVED_DATA} />
    </>
  );
};
