import { IBaseStationEchoCommonDTO } from "interfaces";
import ReactDOMServer from "react-dom/server";
import { getStationCoordinates } from "utils/get-station-coordinates";

import round24IconOffLine from "../../icons/round-24-offline.svg";
import round24Icon from "../../icons/round-24.svg";

import "../../index.css";
import classes from "./card-base-common-station.module.css";

interface ICardBaseCommonStationProps {
  properties: IBaseStationEchoCommonDTO;
}

export const CardBaseCommonStation = (props: ICardBaseCommonStationProps) => {
  const { properties } = props;

  const stationMountpoint = properties?.mountPoint ?? "";
  const isStationOnline: boolean = properties?.conditionNetwork === "1";
  const formattedAddress: string = properties?.address?.formatted_address ?? "";
  const receiverName: string = properties?.receiveType ?? "";
  const stationCoordinates: string = getStationCoordinates({
    lat: properties?.coordinateLatitude,
    lng: properties?.coordinateLongitude,
  });
  const antenna: string = properties?.antenna ?? "";
  const address: string = properties?.address?.area ?? "";

  return ReactDOMServer.renderToString(
    <>
      <div className={"mts-map-custom-popup-header"}>
        <p className={classes.headerTitle} data-testid="txt_area_base_common">
          {address}
        </p>
      </div>

      <div className={"mts-map-custom-popup-info"}>
        <div className={"mts-map-custom-popup-info__status"}>
          <img
            alt="Иконка не загрузилась"
            src={isStationOnline ? round24Icon : round24IconOffLine}
          />
          <p
            className={classes.infoStatusText}
            data-testid="txt_status_base_common"
          >
            {isStationOnline ? "В сети" : "Не в сети"}
          </p>
        </div>

        <div className={"mts-map-custom-popup-info__location"}>
          <p
            className={classes.infoLocationText}
            data-testid="txt_area_address_base_common"
          >
            {formattedAddress}
          </p>
        </div>

        <div className="mts-map-custom-popup-info__list">
          <div
            className={"mts-map-custom-popup-info__list__item"}
            data-testid="txt_coord_base_common"
          >
            <p className={classes.listItemLabel}>Координаты:</p>
            <p className={classes.listItemText}>{stationCoordinates}</p>
          </div>
          <div
            className={"mts-map-custom-popup-info__list__item"}
            data-testid="txt_st_id_base_common"
          >
            <p className={classes.listItemLabel}>ID станции:</p>
            <p className={classes.listItemText}>{stationMountpoint}</p>
          </div>
          <div
            className={"mts-map-custom-popup-info__list__item"}
            data-testid="txt_receiver_type_base_common"
          >
            <p className={classes.listItemLabel}>Тип приёмника:</p>
            <p className={classes.listItemText}>{receiverName}</p>
          </div>
          <div
            className={"mts-map-custom-popup-info__list__item"}
            data-testid="txt_antenna_base_common"
          >
            <p className={classes.listItemLabel}>Антенна:</p>
            <p className={classes.listItemText}>{antenna}</p>
          </div>
        </div>
      </div>
    </>
  );
};
