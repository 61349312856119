import { FIELD_NAMES } from "constants/keys";

import { Controller, useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import { MTSAutocomplete } from "components/mts-autocomplete";
import { MTSButton } from "components/mts-button";
import { MTSInput } from "components/mts-input";
import { useMutationUpdateCustomerAdminInfo } from "hooks/use-mutation-update-customer-admin-info";
import { useProfileData } from "hooks/use-profile-data";
import { useRegions } from "hooks/use-regions";
import { IDictionaryDTO } from "interfaces";
import { fieldSchema } from "utils/yup-schema";
import * as yup from "yup";

import classes from "./company-info-form.module.css";

export interface ICompanyInfoFormStruct {
  [FIELD_NAMES.COMPANY_INFO_REGION]: IDictionaryDTO | null;
  [FIELD_NAMES.COMPANY_INFO_COMPANY_NAME]: string;
  [FIELD_NAMES.COMPANY_INFO_FIO]: string;
  [FIELD_NAMES.COMPANY_INFO_EMAIL]: string;
}

const schema = yup.object({
  [FIELD_NAMES.COMPANY_INFO_REGION]: fieldSchema.notRequired.region,
  [FIELD_NAMES.COMPANY_INFO_COMPANY_NAME]: fieldSchema.notRequired.companyName,
  [FIELD_NAMES.COMPANY_INFO_FIO]: fieldSchema.required.fio,
  [FIELD_NAMES.COMPANY_INFO_EMAIL]: fieldSchema.required.email,
});

interface ICompanyInfoFormProps {
  initialFormData: ICompanyInfoFormStruct;
}

export const CompanyInfoForm = (props: ICompanyInfoFormProps): JSX.Element => {
  const { initialFormData } = props;
  const { isCustomerAdminRole } = useProfileData();

  const { data: regions, isLoading: isLoadingRegions } = useRegions();
  const mutationUpdateCustomerAdminInfo = useMutationUpdateCustomerAdminInfo();

  const {
    register,
    handleSubmit,
    trigger,
    setValue,
    control,
    reset,
    formState: { isDirty, errors, dirtyFields },
  } = useForm<ICompanyInfoFormStruct>({
    mode: "onChange",
    defaultValues: initialFormData,
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: ICompanyInfoFormStruct) => {
    const { fio, email } = data;

    mutationUpdateCustomerAdminInfo.mutate(
      {
        email,
        fio,
      },
      {
        onSuccess: () => {
          reset({
            ...initialFormData,
            email,
            fio,
          });
        },
      }
    );
  };

  return (
    <>
      <form className={classes.blockForm}>
        <div className={classes.blockFormItem}>
          <Controller
            control={control}
            name={FIELD_NAMES.COMPANY_INFO_REGION}
            render={(renderProps) => {
              const { field, fieldState } = renderProps;
              const { onChange, value } = field;
              const errorMessage: string = fieldState?.error?.message || "";

              return (
                <MTSAutocomplete
                  required
                  disabled
                  size="m"
                  label="Регион"
                  placeholder="Выберите"
                  value={value}
                  onChangeValue={onChange}
                  options={regions || []}
                  loading={isLoadingRegions}
                  errorMessage={errorMessage}
                />
              );
            }}
          />
        </div>
        <div className={classes.blockFormItem}>
          <MTSInput
            required
            label="Наименование компании"
            placeholder="Введите"
            {...register(FIELD_NAMES.COMPANY_INFO_COMPANY_NAME)}
            errorMessage={
              errors?.[FIELD_NAMES.COMPANY_INFO_COMPANY_NAME]?.message
            }
            onClear={() => {
              setValue(FIELD_NAMES.COMPANY_INFO_COMPANY_NAME, "");
              trigger(FIELD_NAMES.COMPANY_INFO_COMPANY_NAME);
            }}
            disabled
          />
        </div>
        <div className={classes.blockFormItem}>
          <MTSInput
            required
            label="Контактное лицо"
            placeholder="Фамилия Имя Отчество"
            description="Кириллица, не более 56 символов"
            {...register(FIELD_NAMES.COMPANY_INFO_FIO)}
            errorMessage={errors?.[FIELD_NAMES.COMPANY_INFO_FIO]?.message}
            disabled={!isCustomerAdminRole}
            onClear={() => {
              setValue(FIELD_NAMES.COMPANY_INFO_FIO, "");
              trigger(FIELD_NAMES.COMPANY_INFO_FIO);
            }}
            isDirty={dirtyFields?.[FIELD_NAMES.COMPANY_INFO_FIO] ?? false}
          />
        </div>
        <div className={classes.blockFormItem}>
          <MTSInput
            required
            label="Email контактного лица"
            placeholder="Введите"
            infoText={
              isCustomerAdminRole
                ? "На этот адрес вам будут приходить уведомления по основным событиям в системе и платёжные документы для оплаты услуги"
                : ""
            }
            {...register(FIELD_NAMES.COMPANY_INFO_EMAIL)}
            errorMessage={errors?.[FIELD_NAMES.COMPANY_INFO_EMAIL]?.message}
            disabled={!isCustomerAdminRole}
            onClear={() => {
              setValue(FIELD_NAMES.COMPANY_INFO_EMAIL, "");
              trigger(FIELD_NAMES.COMPANY_INFO_EMAIL);
            }}
            isDirty={dirtyFields?.[FIELD_NAMES.COMPANY_INFO_EMAIL] ?? false}
          />
        </div>
      </form>

      {isDirty && (
        <div className={classes.blockAction}>
          <MTSButton
            variant="primary"
            onClick={handleSubmit(onSubmit)}
            loading={mutationUpdateCustomerAdminInfo.isLoading}
          >
            Сохранить
          </MTSButton>
        </div>
      )}
    </>
  );
};
