import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export const EyeHideIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_114893_7887)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.79277 5.20711C5.40225 4.81658 5.40225 4.18342 5.79277 3.79289C6.1833 3.40237 6.81646 3.40237 7.20698 3.79289L9.18236 5.76827C9.18215 5.76833 9.18257 5.7682 9.18236 5.76827L11.9135 8.4986C11.9138 8.4986 11.9132 8.49861 11.9135 8.4986L15.5013 12.0872C15.5013 12.0875 15.5013 12.087 15.5013 12.0872L19.026 15.6111C19.0262 15.611 19.0259 15.6113 19.026 15.6111L21.207 17.7929C21.5975 18.1834 21.5975 18.8166 21.207 19.2071C20.8165 19.5976 20.1833 19.5976 19.7928 19.2071L17.4256 16.84C16.1972 17.7309 15.364 18.2138 13.5231 18.5291C12.8646 18.6419 11.1431 18.6419 10.4846 18.5291C8.37575 18.1679 7.58926 17.5867 6.01627 16.4243C5.11108 15.7554 4.28286 15.0296 3.58399 14.363C2.5306 13.3583 2.00391 12.856 2.00391 11.9997C2.00391 11.1434 2.5306 10.6411 3.58399 9.63636C4.28286 8.96978 5.11108 8.24398 6.01627 7.57507C6.48454 7.22903 6.88311 6.9345 7.26761 6.68195L5.79277 5.20711ZM9.83347 9.24781C9.01984 9.88916 8.4975 10.8836 8.4975 12C8.4975 13.9343 10.0656 15.5024 11.9999 15.5024C13.1164 15.5024 14.1108 14.9801 14.7521 14.1665L13.3141 12.7284C13.0577 13.19 12.5653 13.5023 11.9999 13.5023C11.1702 13.5023 10.4977 12.8297 10.4977 12C10.4977 11.4346 10.81 10.9422 11.2715 10.6858L9.83347 9.24781Z"
        />
        <path d="M20.5172 14.2738L11.6328 5.38944C12.3382 5.37518 13.1249 5.40214 13.5231 5.47032C15.6319 5.83148 16.4184 6.41268 17.9914 7.57507C18.8966 8.24398 19.7248 8.96978 20.4237 9.63636C21.477 10.6411 22.0037 11.1434 22.0037 11.9997C22.0037 12.8302 21.5082 13.3278 20.5172 14.2738Z" />
      </g>
      <defs>
        <clipPath id="clip0_114893_7887">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
