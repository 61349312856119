import {
  PATH_AGRONOMY,
  PATH_GEODESY_CADASTRE,
  PATH_MAIN,
} from "constants/routes";

import { Switch, Route } from "react-router-dom";

import { ErrorPage, ErrorPageVariant } from "components/pages/error-page";

import { PageAgronomy } from "../page-agronomy";
import { PageGeodesyCadastre } from "../page-geodesy-cadastre";

export const Industry = (): JSX.Element => {
  return (
    <Switch>
      <Route exact path={PATH_AGRONOMY}>
        <PageAgronomy />
      </Route>
      <Route exact path={PATH_GEODESY_CADASTRE}>
        <PageGeodesyCadastre />
      </Route>
      <Route path="*">
        <ErrorPage
          variants={ErrorPageVariant.PAGE_NOT_FOUND}
          buttonLabel="Перейти на главную страницу"
          redirectURL={PATH_MAIN}
        />
      </Route>
    </Switch>
  );
};
