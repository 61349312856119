export const SearchParamsKeys = {
  pagination: {
    pageNumber: "pn",
    pageSize: "ps",
  },
  rinexFileRequests: {
    search: "srch",
    stationId: "stationId",
  },
};
