import { ITabNames, _tabNames } from "constants/tabs";

import React from "react";

import { IDialogOkProps } from "components/dialog-ok/dialog-ok";
import { INotificationStruct, IProfileStruct } from "interfaces";

interface IContextMain {
  isAuthorized: boolean;
  setIsAuthorized: React.Dispatch<React.SetStateAction<boolean>>;
  isLoadingAuthData: boolean;
  setIsLoadingAuthData: React.Dispatch<React.SetStateAction<boolean>>;
  profile: IProfileStruct | null;
  setProfile: React.Dispatch<React.SetStateAction<IProfileStruct | null>>;
  notifications: INotificationStruct[];
  setNotifications: React.Dispatch<React.SetStateAction<INotificationStruct[]>>;
  timeIntervalCheckProfile: number;
  setTimeIntervalCheckProfile: React.Dispatch<React.SetStateAction<number>>;
  testSubscriptionStatus: string;
  setTestSubscriptionStatus: React.Dispatch<React.SetStateAction<string>>;
  dialogOk: IDialogOkProps | null;
  setDialogOk: React.Dispatch<React.SetStateAction<IDialogOkProps | null>>;
  tabNames: ITabNames;
  setTabNames: React.Dispatch<React.SetStateAction<ITabNames>>;
  clearAuthData: () => void;
}

export const ContextMain = React.createContext<IContextMain>({
  isAuthorized: false,
  setIsAuthorized: () => {},
  isLoadingAuthData: false,
  setIsLoadingAuthData: () => {},
  profile: null,
  setProfile: () => {},
  notifications: [],
  setNotifications: () => {},
  timeIntervalCheckProfile: 0,
  setTimeIntervalCheckProfile: () => {},
  testSubscriptionStatus: "",
  setTestSubscriptionStatus: () => {},
  dialogOk: null,
  setDialogOk: () => null,
  tabNames: _tabNames,
  setTabNames: () => _tabNames,
  clearAuthData: () => undefined,
});
