import React from "react";

import { ISubscriptionCounts } from "interfaces";

interface IContextSubscriptions {
  subscriptionCounts: ISubscriptionCounts;
  setSubscriptionCounts: React.Dispatch<
    React.SetStateAction<ISubscriptionCounts>
  >;
}

export const ContextSubscriptions = React.createContext<IContextSubscriptions>({
  subscriptionCounts: {
    all: 0,
    activated: 0,
    paid: 0,
    renew: 0,
    expired: 0,
    canRenew: 0,
  },
  setSubscriptionCounts: () => {},
});
