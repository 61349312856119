import { PAYMENT_ACTION_TYPE } from "constants/keys";
import { PATH_PAYMENTS, PATH_SUBSCRIPTIONS } from "constants/routes";

import React from "react";

import { useParams } from "react-router-dom";

import Collapse from "@mui/material/Collapse";
import { AppHelmet } from "components/app-helmet";
import { Breadcrumbs } from "components/breadcrumbs";
import { ContainerContent } from "components/container-content";
import { FooterOfPersonalArea } from "components/footer-of-personal-area";
import { Loader } from "components/loader";
import { MTSLink } from "components/mts-link";
import { NotificationMessage } from "components/notification-message";
import { PageTitle } from "components/page-title";
import { PaymentCard } from "components/payment-card";
import { TemplateServiceNotAvailable } from "components/template-service-not-available";
import {
  GAP_EVENT_TYPE,
  GA_SCREEN_NAME,
  useGoogleAnalyticsPages,
} from "hooks/google-analytics";
import { useTabNames } from "hooks/tabs/use-tab-names";
import { useCheckAbilityToCreatePayments } from "hooks/use-check-ability-to-create-payments";
import { usePaymentData } from "hooks/use-payment-data";

import classes from "./payment-info-page.module.css";

const PAGE_TITLE = "Платёж";

interface IMatchParams {
  id: string;
}

export const PaymentInfoPage = (): JSX.Element => {
  const { id } = useParams<IMatchParams>();

  useGoogleAnalyticsPages({
    screenName: GA_SCREEN_NAME.payment_info,
    event: GAP_EVENT_TYPE.SCRN,
  });

  const { tabNames } = useTabNames();

  const {
    data: paymentData,
    isLoading: isLoadingPaymentData,
    isError: isErrorPaymentData,
  } = usePaymentData(id, { useRefetchInterval: true });
  const {
    data: abilityToCreatePayments,
    isLoading: isLoadingAbilityToCreatePayments,
    isError: isErrorAbilityToCreatePayments,
  } = useCheckAbilityToCreatePayments({});

  const isError: boolean = isErrorAbilityToCreatePayments || isErrorPaymentData;
  const paymentActionType: string = paymentData?.actionType ?? "";

  const [isShowNotification, setIsShowNotification] =
    React.useState<boolean>(false);

  const breadcrumbLinks = React.useMemo(
    () => [
      {
        name: tabNames.subscriptions,
        href: PATH_SUBSCRIPTIONS,
      },
      { name: PAGE_TITLE, href: "" },
    ],
    [tabNames.subscriptions]
  );

  React.useEffect(() => {
    if (!isLoadingAbilityToCreatePayments && abilityToCreatePayments) {
      setIsShowNotification(!abilityToCreatePayments.canCreatePayment);
    }
  }, [abilityToCreatePayments, isLoadingAbilityToCreatePayments]);

  if (isLoadingPaymentData || isLoadingAbilityToCreatePayments) {
    return <Loader />;
  }

  return (
    <>
      <AppHelmet title={`${PAGE_TITLE} - Личный кабинет`} />

      {isError ? (
        <ContainerContent>
          <Breadcrumbs links={breadcrumbLinks} />
          <PageTitle text={PAGE_TITLE} sx={{ marginTop: "24px" }} />
          <TemplateServiceNotAvailable />
        </ContainerContent>
      ) : (
        <ContainerContent>
          <div className={classes.container}>
            <Breadcrumbs links={breadcrumbLinks} />

            <PageTitle text={PAGE_TITLE} sx={{ marginTop: "24px" }} />

            <Collapse in={isShowNotification}>
              <div
                style={{
                  marginTop: "24px",
                }}
              >
                <NotificationMessage
                  show={isShowNotification}
                  handleClose={() => setIsShowNotification(false)}
                  text={
                    <>
                      Пока вы не оплатитите
                      {paymentActionType === PAYMENT_ACTION_TYPE.MODIFY
                        ? " "
                        : " первый "}
                      <MTSLink to={PATH_PAYMENTS}>счёт</MTSLink>, вы не сможете
                      создавать другие платежи
                    </>
                  }
                />
              </div>
            </Collapse>

            <div className={classes.content}>
              {paymentData && (
                <PaymentCard data={paymentData} isPaymentInfoPage={true} />
              )}
            </div>
          </div>
        </ContainerContent>
      )}

      <FooterOfPersonalArea />
    </>
  );
};
