import React from "react";

import { useInView } from "react-intersection-observer";

import { SelectChangeEvent, useMediaQuery } from "@mui/material";
import { BlockContent } from "components/block-content";
import { BlockWide } from "components/block-wide";
import { IMTSTab, MTSTabs } from "components/mts-tabs";
import { TabsMobile } from "components/tabs-mobile";
import {
  GAE_ACTION_GROUP,
  GAE_BUTTON_LOCATION,
  GAE_EVENT,
  GAE_EVENT_ACTION,
  GAE_EVENT_CATEGORY,
  GAE_EVENT_LABEL,
  useGoogleAnalyticsEvents,
} from "hooks/google-analytics";
import { useAppTheme } from "hooks/use-app-theme";

import classes from "./casses.module.css";
import companyImg from "./img/company-img.jpg";
import complexImg from "./img/complex-img.jpg";
import holdingsImg from "./img/holdings-img.jpg";

const enum TAB_NAMES {
  AGRI_COMPLEX = "agriComplex",
  AGRI_COMPANY = "agriCompany",
  AGRI_HOLDING = "agriHolding",
}

const tabs: IMTSTab[] = [
  {
    id: TAB_NAMES.AGRI_COMPLEX,
    name: "Сельскохозяйственный комплекс",
    type: "link",
    href: "",
    access: ["all"],
  },
  {
    id: TAB_NAMES.AGRI_COMPANY,
    name: "Сельскохозяйственная компания",
    type: "link",
    href: "",
    access: ["all"],
  },
  {
    id: TAB_NAMES.AGRI_HOLDING,
    name: "Сельскохозяйственный холдинг",
    type: "link",
    href: "",
    access: ["all"],
  },
];

export const Cases = React.memo((): JSX.Element => {
  const theme = useAppTheme();
  const isMinWidthDesktop_960 = useMediaQuery(
    theme.breakpoints.up("desktopS_960")
  );

  const { sendGAE } = useGoogleAnalyticsEvents();

  const { ref: agroBlockCasesRef, inView: inViewAgroBlockCases } = useInView({
    initialInView: false,
    threshold: 0.5,
    triggerOnce: true,
  });

  const [selectedTab, setSelectedTab] = React.useState<number | boolean>(0);
  const [selectedTabId, setSelectedTabId] = React.useState<string>(tabs[0].id);

  const handleChangeTab = (
    event: React.SyntheticEvent,
    newTabNumber: number
  ): void => {
    const tabId: string = tabs[newTabNumber].id;
    setSelectedTabId(tabId);
    setSelectedTab(newTabNumber);
  };

  const handleSendMetrics = (e: React.SyntheticEvent, tabName: string) => {
    if (e.type === "click") {
      let eventLabel = GAE_EVENT_LABEL.selskohozyaistvennyi_kompleks;
      if (tabName === tabs[1].name) {
        eventLabel = GAE_EVENT_LABEL.selskohozyaistvennaya_kompaniya;
      } else if (tabName === tabs[2].name) {
        eventLabel = GAE_EVENT_LABEL.selskohozyaistvennyi_holding;
      }

      sendGAE({
        id: 60,
        event: GAE_EVENT.vntZeml,
        eventCategory: GAE_EVENT_CATEGORY.tochnoe_zemledelie,
        eventAction: GAE_EVENT_ACTION.tab_click,
        eventLabel: eventLabel,
        eventValue: null,
        eventContent: null,
        buttonLocation: GAE_BUTTON_LOCATION.screen,
        filterName: null,
        actionGroup: GAE_ACTION_GROUP.interactions,
        productName: null,
      });
    }
  };

  React.useEffect(() => {
    if (inViewAgroBlockCases) {
      sendGAE({
        id: 67,
        event: GAE_EVENT.vntZeml,
        eventCategory: GAE_EVENT_CATEGORY.tochnoe_zemledelie,
        eventAction: GAE_EVENT_ACTION.block_show,
        eventLabel: GAE_EVENT_LABEL.keisy,
        eventValue: null,
        eventContent: null,
        buttonLocation: null,
        filterName: null,
        actionGroup: GAE_ACTION_GROUP.non_interactions,
        productName: null,
      });
    }
  }, [inViewAgroBlockCases, sendGAE]);

  const [selectedMobileTab, setSelectedMobileTab] = React.useState<string>(
    tabs[0].name
  );

  const handleChangeMobileTab = (event: SelectChangeEvent) => {
    setSelectedMobileTab(event.target.value);
  };

  return (
    <div className={classes.blockCases} ref={agroBlockCasesRef}>
      <BlockWide>
        <BlockContent>
          <h2 className={classes.title}>Кейсы</h2>

          {isMinWidthDesktop_960 ? (
            <div className={classes.tabs}>
              <MTSTabs
                size="m"
                selectedTab={selectedTab}
                tabs={tabs}
                onChange={handleChangeTab}
                onClick={(e, tabName: string) => handleSendMetrics(e, tabName)}
              />
            </div>
          ) : (
            <div className={classes.tabsMobile}>
              <TabsMobile
                tabs={tabs}
                selectedMobileTab={selectedMobileTab}
                handleChangeMobileTab={handleChangeMobileTab}
                onClick={(e, tabName: string) => handleSendMetrics(e, tabName)}
              />
            </div>
          )}

          <div className={classes.contentWrapper}>
            {(isMinWidthDesktop_960
              ? selectedTabId === TAB_NAMES.AGRI_COMPLEX
              : selectedMobileTab === tabs[0].name) && (
              <>
                <div className={classes.contentInfo}>
                  <div className={classes.contentInfoItem}>
                    <h2 className={classes.contentInfoItemTitle}>Задача</h2>

                    <div className={classes.contentInfoItemList}>
                      <p className={classes.contentInfoItemListText}>
                        Обеспечить точное позиционирование техники.
                      </p>
                      <p className={classes.contentInfoItemListText}>
                        Улучшить подготовку, контроль и оценку
                        агротехни&shy;ческих мероприятий.
                      </p>
                    </div>
                  </div>

                  <div className={classes.contentInfoItem}>
                    <h2 className={classes.contentInfoItemTitle}>Решение</h2>

                    <div className={classes.contentInfoItemList}>
                      <p className={classes.contentInfoItemListText}>
                        Разработка геоинформационных систем и создание цифровых
                        карт полей и моделей рельефа для анализа.
                      </p>
                      <p className={classes.contentInfoItemListText}>
                        Оценка состояния мелиоративных систем,
                        гидротехни&shy;ческих сооружений и ущербов от
                        агроклиматических аномалий.
                      </p>
                      <p className={classes.contentInfoItemListText}>
                        Контроль всхожести пропашных культур, обработки посевов,
                        аналитика урожайности.
                      </p>
                    </div>
                  </div>

                  <div className={classes.contentInfoItem}>
                    <h2 className={classes.contentInfoItemTitle}>Результат</h2>

                    <div className={classes.contentInfoItemList}>
                      <p className={classes.contentInfoItemListText}>
                        Увеличение производительности в <strong>1,5–1,8</strong>
                        &nbsp; раз.
                      </p>
                      <p className={classes.contentInfoItemListText}>
                        Рост рентабельности земледелия на&nbsp;
                        <strong>10–25%</strong>, <br />
                        работоспособность 365 дней в году 24/7.
                      </p>
                      <p className={classes.contentInfoItemListText}>
                        Снижение нагрузки на оператора и повышение точности
                        работ, так как механизатор сосредоточен на контроле
                        выполняемых работ.
                      </p>
                      <p className={classes.contentInfoItemListText}>
                        Снижение рисков по перерасходу семян, удобрений, средств
                        защиты растений.
                      </p>
                      <p className={classes.contentInfoItemListText}>
                        Повышение сбора урожая в результате ухода двойной
                        густоты на перекрытиях.
                      </p>
                      <p className={classes.contentInfoItemListText}>
                        Исключение «вытаптывания» посевов при опрыски&shy;вании.
                      </p>
                    </div>
                  </div>
                </div>
                <div className={classes.contentImg}>
                  <img src={complexImg} alt="agriComplexImg" />
                </div>
              </>
            )}

            {(isMinWidthDesktop_960
              ? selectedTabId === TAB_NAMES.AGRI_COMPANY
              : selectedMobileTab === tabs[1].name) && (
              <>
                <div className={classes.contentInfo}>
                  <div className={classes.contentInfoItem}>
                    <h2 className={classes.contentInfoItemTitle}>Задача</h2>

                    <div className={classes.contentInfoItemList}>
                      <p className={classes.contentInfoItemListText}>
                        Найти альтернативного поставщика услуг после ухода с
                        рынка зарубежной компании.
                      </p>
                      <p className={classes.contentInfoItemListText}>
                        Улучшить качество посевных работ. Из-за ухода компании
                        более <strong>90%</strong> техники не ездит с точностью
                        до <strong>15 см</strong>. Это влияет на посевные и
                        уборочные работы и снижает прибыль.
                      </p>
                    </div>
                  </div>

                  <div className={classes.contentInfoItem}>
                    <h2 className={classes.contentInfoItemTitle}>Решение</h2>

                    <div className={classes.contentInfoItemList}>
                      <p className={classes.contentInfoItemListText}>
                        Пилотная постройка референцной станции в Белгородской
                        области.
                      </p>
                      <p className={classes.contentInfoItemListText}>
                        Система параллельного вождения для сельскохозяйственной
                        техники: подруливающие устройства, антенна-приёмник,
                        навигационный планшет.
                      </p>
                    </div>
                  </div>

                  <div className={classes.contentInfoItem}>
                    <h2 className={classes.contentInfoItemTitle}>Результат</h2>

                    <div className={classes.contentInfoItemList}>
                      <p className={classes.contentInfoItemListText}>
                        Оборудование передвигается по сельскохозяйственным полям
                        с точностью{" "}
                        <strong style={{ whiteSpace: "nowrap" }}>2–5 см</strong>
                      </p>
                      <p className={classes.contentInfoItemListText}>
                        Отсутствие пропусков и перекрытий при обработке полей
                      </p>
                      <p className={classes.contentInfoItemListText}>
                        Увеличение производительности в{" "}
                        <strong style={{ whiteSpace: "nowrap" }}>
                          1,5–1,8
                        </strong>{" "}
                        раз
                      </p>
                      <p className={classes.contentInfoItemListText}>
                        Сокращение перерасхода семян на&nbsp;
                        <strong style={{ whiteSpace: "nowrap" }}>10–15%</strong>
                        , удобрений на&nbsp;
                        <strong style={{ whiteSpace: "nowrap" }}>10–30%</strong>
                        , средств защиты растений на&nbsp;
                        <strong style={{ whiteSpace: "nowrap" }}>15–60%</strong>
                        , ГСМ на&nbsp;
                        <strong style={{ whiteSpace: "nowrap" }}>5–10%</strong>
                      </p>
                      <p className={classes.contentInfoItemListText}>
                        Снижение износа техники на <strong>10%</strong>
                      </p>
                      <p className={classes.contentInfoItemListText}>
                        Уменьшение недобора урожая, «вытаптывания» посевов при
                        опрыскивании, потерь при гербицидной обработке на&nbsp;
                        <strong>20%</strong>
                      </p>
                      <p className={classes.contentInfoItemListText}>
                        Уменьшение простоев техники на <strong>15–20%</strong>
                      </p>
                    </div>
                  </div>
                </div>
                <div className={classes.contentImg}>
                  <img src={companyImg} alt="agriCompanyImg" />
                </div>
              </>
            )}

            {(isMinWidthDesktop_960
              ? selectedTabId === TAB_NAMES.AGRI_HOLDING
              : selectedMobileTab === tabs[2].name) && (
              <>
                <div className={classes.contentInfo}>
                  <div className={classes.contentInfoItem}>
                    <h2 className={classes.contentInfoItemTitle}>Задача</h2>

                    <div className={classes.contentInfoItemList}>
                      <p className={classes.contentInfoItemListText}>
                        Точно определять координаты дрона и уменьшать перерасход
                        средств опыления. При опылении полей дроны используют
                        обычный GPS, поэтому появляется погрешность и дрон
                        начинает лететь зигзагом, увеличивая расход средств
                      </p>
                    </div>
                  </div>

                  <div className={classes.contentInfoItem}>
                    <h2 className={classes.contentInfoItemTitle}>Решение</h2>

                    <div className={classes.contentInfoItemList}>
                      <p className={classes.contentInfoItemListText}>
                        Подключение БПЛА к сети Location Pro.
                      </p>
                      <p className={classes.contentInfoItemListText}>
                        Получение поправок RTK для выстраивания чётких линий
                        полёта.
                      </p>
                    </div>
                  </div>

                  <div className={classes.contentInfoItem}>
                    <h2 className={classes.contentInfoItemTitle}>Результат</h2>

                    <div className={classes.contentInfoItemList}>
                      <p className={classes.contentInfoItemListText}>
                        Стабильный полёт дрона над полями.
                      </p>
                      <p className={classes.contentInfoItemListText}>
                        Исключение перерасхода средств защиты.
                      </p>
                    </div>
                  </div>
                </div>
                <div className={classes.contentImg}>
                  <img src={holdingsImg} alt="agriHoldingsImg" />
                </div>
              </>
            )}
          </div>
        </BlockContent>
      </BlockWide>
    </div>
  );
});
