import { FIELD_NAMES } from "constants/keys";

import React from "react";

import { useForm } from "react-hook-form";

import { MTSIcon } from "components/mts-icon";
import { MTSSwitch } from "components/mts-switch";

import { MenuOptionType } from "../../constants";

import classes from "./menu-option-plan.module.css";

interface IMenuOptionPlanProps {
  menuType: MenuOptionType;
  disabled?: boolean;
  isShow: boolean;
  setIsShow: React.Dispatch<React.SetStateAction<boolean>>;
  dataTestIdIcon?: string;
  dataTestIdLabel?: string;
  dataTestIdSwitch?: string;
}

export const MenuOptionPlan = React.memo(
  (props: IMenuOptionPlanProps): JSX.Element => {
    const {
      disabled = false,
      isShow,
      setIsShow,
      menuType,
      dataTestIdIcon = "",
      dataTestIdLabel = "",
      dataTestIdSwitch = "",
    } = props;

    const { control, watch, setValue } = useForm({
      defaultValues: {
        [FIELD_NAMES.MAP_MENU_PLAN]: isShow,
      },
    });

    React.useEffect(() => {
      setValue(FIELD_NAMES.MAP_MENU_PLAN, isShow);
    }, [isShow, setValue]);

    React.useEffect(() => {
      const subscription = watch((value) => {
        if (value[FIELD_NAMES.MAP_MENU_PLAN] === true) {
          setIsShow(true);
        } else {
          setIsShow(false);
        }
      });

      return () => subscription.unsubscribe();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watch]);

    return (
      <div className={classes.option}>
        <div className={classes.optionHeader}>
          <MTSIcon.Ref
            data-testid={dataTestIdIcon || ""}
            sx={{
              fontSize: 32,
              color: isShow
                ? "var(--color-constant-greyscale-400)"
                : "var(--color-constant-greyscale-200)",
            }}
          />

          <p
            data-testid={dataTestIdLabel || ""}
            className={classes.optionHeaderLabel}
          >
            {menuType}
          </p>
        </div>
        <MTSSwitch
          data-testid={dataTestIdSwitch || ""}
          size="M"
          disabled={disabled}
          control={control}
          controlFieldName={FIELD_NAMES.MAP_MENU_PLAN}
        />
      </div>
    );
  }
);
