import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const Geomir = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="178"
      height="84"
      viewBox="0 0 178 84"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M139.299 55.8905L143.649 55.3788V25L124.893 46.12V25.8532L120.543 26.3654V57L139.299 35.8375V55.8905ZM160.56 32.2113C161.501 33.2068 161.971 34.4299 161.971 35.8805C161.971 37.3882 161.458 38.6395 160.432 39.635C159.207 40.8298 157.354 41.4272 154.875 41.4272L153.379 41.3419V30.4191H155.687C157.796 30.4191 159.42 31.0165 160.56 32.2113ZM163.766 42.5791C165.59 40.7869 166.502 38.5684 166.502 35.9234C166.502 33.3632 165.647 31.1729 163.937 29.3525C161.971 27.2762 159.221 26.2378 155.687 26.2378H148.848V55.7629L153.379 55.2581V45.6085H153.977H154.618H155.345C158.907 45.6085 161.714 44.5989 163.766 42.5791ZM117.466 55.6557L113.106 56.3813L109.772 38.0772L101.094 56.7224L92.2886 38.0772L89.0827 56.3813L84.6797 55.6557L90.6214 25.2773L101.18 47.5489L111.482 25.2773L117.466 55.6557ZM47.4471 55.6355L48.6442 51.4542H35.0934V42.7928H45.3953V38.6115H35.0934V30.5475H47.5326L46.6777 26.3662H30.5625V55.6355H47.4471ZM26.8458 30.4192H16.0309V55.2513L11.5 55.763V26.2378H25.9481L26.8458 30.4192ZM78.9012 38.9304C78.046 34.1283 74.269 30.3128 69.4813 29.3903V25.4964C76.3936 26.4909 81.8737 32.0082 82.7952 38.9304H78.9012ZM69.4813 52.6075C74.2685 51.685 78.0456 47.8699 78.9012 43.0683H82.7952C81.8724 49.9896 76.3927 55.5082 69.4813 56.5028V52.6075ZM55.5781 43.0683C56.4539 47.9822 60.3887 51.8636 65.3357 52.6675V56.548C58.2645 55.6877 52.6208 50.1029 51.6836 43.0683H55.5781ZM65.3357 29.3303C60.3887 30.1342 56.4539 34.016 55.5781 38.9304H51.6836C52.6203 31.8954 58.2645 26.3114 65.3357 25.4512V29.3303Z"
        fill="white"
      />
    </SvgIcon>
  );
};

export default Geomir;
