import { PRODUCT_NAME } from "constants/common";
import { FRONTEND_URL } from "constants/env";

import React from "react";

import { Helmet } from "react-helmet";

import { LandingContactUs } from "components/landing-contact-us";
import { MTSFooter } from "components/mts-footer";
import {
  GAP_EVENT_TYPE,
  GA_SCREEN_NAME,
  useGoogleAnalyticsPages,
} from "hooks/google-analytics";

import { About } from "./components/about";
import { Advantages } from "./components/advantages";
import { Application } from "./components/application";
import { Main } from "./components/main";
import { Partners } from "./components/partners";
import { Questions } from "./components/questions";
import { Tariffs } from "./components/tariffs";

export const Landing = (): JSX.Element => {
  useGoogleAnalyticsPages({
    screenName: GA_SCREEN_NAME.main,
    event: GAP_EVENT_TYPE.SCRN,
  });

  return (
    <>
      <Helmet>
        <title>
          Высокоточное геопозиционирование - Главная страница | {PRODUCT_NAME}
        </title>
        <meta
          name="description"
          content={`Высокоточное геопозиционирование от ${PRODUCT_NAME}. Определение геопозиции объектов с точностью до 2-х сантиметров. Cеть референсных станций МТС на надёжной инфраструктуре телеком-оператора. Решение позволяет определять местоположение объекта с высокой точностью.`}
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={FRONTEND_URL} />
        <meta
          property="og:title"
          content={`Высокоточное геопозиционирование - Главная страница |  ${PRODUCT_NAME}`}
        />
        <meta
          property="og:description"
          content={`Высокоточное геопозиционирование от ${PRODUCT_NAME}. Определение геопозиции объектов с точностью до 2-х сантиметров. Cеть референсных станций МТС на надёжной инфраструктуре телеком-оператора. Решение позволяет определять местоположение объекта с высокой точностью.`}
        />
        <meta property="og:site_name" content="Главная страница" />
      </Helmet>
      <Main />
      <About />
      <Application />
      <Tariffs />
      <Advantages />
      <Questions />
      <Partners />
      <LandingContactUs
        title="Остались вопросы?"
        description="Заполните форму и мы вместе подберем подходящее решение"
      />
      <MTSFooter sx={{ marginTop: 120 }} />
    </>
  );
};
