import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const Error = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x="2" y="2" width="20" height="20" rx="10" />
      <path
        d="M15.8047 9.13807C16.0651 8.87772 16.0651 8.45561 15.8047 8.19526C15.5444 7.93491 15.1223 7.93491 14.8619 8.19526L12 11.0572L9.13807 8.19526C8.87772 7.93491 8.45561 7.93491 8.19526 8.19526C7.93491 8.45561 7.93491 8.87772 8.19526 9.13807L11.0572 12L8.19526 14.8619C7.93491 15.1223 7.93491 15.5444 8.19526 15.8047C8.45561 16.0651 8.87772 16.0651 9.13807 15.8047L12 12.9428L14.8619 15.8047C15.1223 16.0651 15.5444 16.0651 15.8047 15.8047C16.0651 15.5444 16.0651 15.1223 15.8047 14.8619L12.9428 12L15.8047 9.13807Z"
        fill="#FFFFFF"
      />
    </SvgIcon>
  );
};

export default Error;
