import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const Smile = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M13.6257 25.6096C13.425 25.3484 13.3348 25.0722 13.3333 24.8057C13.329 24.0288 14.0783 23.3343 15.0387 23.3343C15.6978 23.3343 16.2911 23.6941 16.7846 24.1311C17.2282 24.524 17.7653 24.7592 18.2952 24.8614C18.8157 24.9618 19.4093 25.0048 20 25.001C20.5302 24.9975 21.0581 24.9562 21.5288 24.8847C22.1489 24.7905 22.7265 24.5623 23.1925 24.1584C23.7007 23.7179 24.2995 23.3343 24.9721 23.3343C25.9239 23.3343 26.6741 24.0287 26.6667 24.8057C26.6642 25.0613 26.5798 25.3259 26.3936 25.5776C25.3632 26.9706 23.8022 27.9114 22.0294 28.1807C21.392 28.2775 20.6975 28.3311 20 28.3343C19.1974 28.338 18.3911 28.2751 17.6637 28.1348C16.0888 27.831 14.6249 26.9097 13.6257 25.6096Z" />
      <path d="M15.0001 14.5843C16.1506 14.5843 17.0834 15.517 17.0834 16.6676C17.0834 17.8182 16.1506 18.751 15.0001 18.751C13.8495 18.751 12.9167 17.8182 12.9167 16.6676C12.9167 15.517 13.8495 14.5843 15.0001 14.5843Z" />
      <path d="M27.0833 16.6676C27.0833 15.517 26.1506 14.5843 25 14.5843C23.8494 14.5843 22.9167 15.517 22.9167 16.6676C22.9167 17.8182 23.8494 18.751 25 18.751C26.1506 18.751 27.0833 17.8182 27.0833 16.6676Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.13509 16.5415C5.45958 12.1815 5.62183 10.0015 7.80995 7.81337C9.99807 5.62524 12.1781 5.463 16.538 5.13851C17.6683 5.05439 18.8341 5.00342 20 5.00342C21.1659 5.00342 22.3317 5.05439 23.462 5.13851C27.8219 5.463 30.0019 5.62524 32.1901 7.81337C34.3782 10.0015 34.5404 12.1815 34.8649 16.5415C34.949 17.6717 35 18.8375 35 20.0034C35 21.1693 34.949 22.3352 34.8649 23.4654C34.5404 27.8254 34.3782 30.0053 32.1901 32.1935C30.0019 34.3816 27.8219 34.5438 23.462 34.8683C22.3317 34.9524 21.1659 35.0034 20 35.0034C18.8341 35.0034 17.6683 34.9524 16.538 34.8683C12.1781 34.5438 9.99807 34.3816 7.80995 32.1935C5.62183 30.0054 5.45958 27.8254 5.13509 23.4654C5.05097 22.3352 5 21.1693 5 20.0034C5 18.8375 5.05097 17.6717 5.13509 16.5415ZM16.7854 8.46265C17.8474 8.38361 18.9296 8.33675 20 8.33675C21.0704 8.33675 22.1526 8.38361 23.2146 8.46265C27.7985 8.8038 28.656 8.99339 29.833 10.1704C31.01 11.3474 31.1996 12.2049 31.5408 16.7889C31.6198 17.8508 31.6667 18.9331 31.6667 20.0034C31.6667 21.0738 31.6198 22.1561 31.5408 23.218C31.1996 27.8019 31.01 28.6595 29.833 29.8365C28.656 31.0134 27.7985 31.203 23.2146 31.5442C22.1526 31.6232 21.0704 31.6701 20 31.6701C18.9296 31.6701 17.8474 31.6232 16.7854 31.5442C12.2015 31.203 11.344 31.0134 10.167 29.8365C8.98997 28.6595 8.80039 27.8019 8.45923 23.218C8.3802 22.1561 8.33333 21.0738 8.33333 20.0034C8.33333 18.9331 8.3802 17.8508 8.45923 16.7889C8.80039 12.2049 8.98997 11.3474 10.167 10.1704C11.344 8.99339 12.2015 8.80381 16.7854 8.46265Z"
      />
    </SvgIcon>
  );
};

export default Smile;
