export const ROUTE_MAIN = "/";
export const ROUTE_PERSONAL_AREA = "p";
export const ROUTE_TEST_PAGE = "test";
export const ROUTE_TAB_SUBSCRIPTIONS = "subs";
export const ROUTE_MAP = "map";
export const ROUTE_RINEX_REQUESTS = "rinex-requests";
export const ROUTE_ADD_SUBS = "add-subs";
export const ROUTE_PAYMENTS = "payments";
export const ROUTE_PAYMENT_EDIT = "payment-edit";
export const ROUTE_SUBSCRIPTION_INFO = "sub-info";
export const ROUTE_TARIFFS = "tariffs";
export const ROUTE_ERROR = "error";
export const ROUTE_SETTINGS = "settings";
export const ROUTE_INSTRUCTIONS = "instructions";
export const ROUTE_INSTRUCTION_DEVICE_CONNECT = "device-connect";
export const ROUTE_PUBLIC_OFFER = "offer";
export const ROUTE_COMPANY_INFO = "company-info";
export const ROUTE_SUBSCRIPTION_INFO_PERSONAL = "sub";
export const ROUTE_INSTRUCTION_CARDS = "instruction-cards";
export const ROUTE_INDUSTRY = "industry";
export const ROUTE_AGRONOMY = "agro";
export const ROUTE_GEODESY_CADASTRE = "geodesy-cadastre";

// SUBSCRIPTIONS BY STATUS
export const ROUTE_SUBSCRIPTIONS_ALL = "all";
export const ROUTE_SUBSCRIPTIONS_ACTIVATED = "activated";
export const ROUTE_SUBSCRIPTIONS_PAID = "paid";
export const ROUTE_SUBSCRIPTIONS_RENEW = "renew";
export const ROUTE_SUBSCRIPTIONS_EXPIRED = "expired";

// FULL PATHS
export const PATH_MAIN = ROUTE_MAIN;
export const PATH_PERSONAL_AREA = `/${ROUTE_PERSONAL_AREA}`;
export const PATH_TEST_PAGE = `/${ROUTE_TEST_PAGE}`;
export const PATH_ERROR = `/${ROUTE_ERROR}`;
export const PATH_MAP = `/${ROUTE_MAP}`;
export const PATH_RINEX_REQUESTS = `${PATH_PERSONAL_AREA}/${ROUTE_RINEX_REQUESTS}`;
export const PATH_ADD_RINEX_REQUEST = `${PATH_RINEX_REQUESTS}/add`;
export const PATH_SUBSCRIPTION_INFO = `${PATH_PERSONAL_AREA}/${ROUTE_SUBSCRIPTION_INFO}`;
export const PATH_PAYMENTS = `${PATH_PERSONAL_AREA}/${ROUTE_PAYMENTS}`;
export const PATH_PAYMENT_EDIT = `${PATH_PERSONAL_AREA}/${ROUTE_PAYMENT_EDIT}`;
export const PATH_ADD_SUBSCRIPTIONS = `${PATH_PERSONAL_AREA}/${ROUTE_ADD_SUBS}`;
export const PATH_TARIFFS = `${PATH_PERSONAL_AREA}/${ROUTE_TARIFFS}`;
export const PATH_PUBLIC_OFFER = `${PATH_PERSONAL_AREA}/${ROUTE_PUBLIC_OFFER}`;

// INDUSTRY
export const PATH_INDUSTRY = `/${ROUTE_INDUSTRY}`;
export const PATH_AGRONOMY = `${PATH_INDUSTRY}/${ROUTE_AGRONOMY}`;
export const PATH_GEODESY_CADASTRE = `${PATH_INDUSTRY}/${ROUTE_GEODESY_CADASTRE}`;

// SUBSCRIPTION TABS
export const PATH_SUBSCRIPTIONS = `${PATH_PERSONAL_AREA}/${ROUTE_TAB_SUBSCRIPTIONS}`;
export const PATH_SUBSCRIPTION_TAB_ALL = `${PATH_SUBSCRIPTIONS}/${ROUTE_SUBSCRIPTIONS_ALL}`;
export const PATH_SUBSCRIPTION_TAB_PAID = `${PATH_SUBSCRIPTIONS}/${ROUTE_SUBSCRIPTIONS_PAID}`;
export const PATH_SUBSCRIPTION_TAB_ACTIVATED = `${PATH_SUBSCRIPTIONS}/${ROUTE_SUBSCRIPTIONS_ACTIVATED}`;
export const PATH_SUBSCRIPTION_TAB_RENEW = `${PATH_SUBSCRIPTIONS}/${ROUTE_SUBSCRIPTIONS_RENEW}`;
export const PATH_SUBSCRIPTION_TAB_EXPIRED = `${PATH_SUBSCRIPTIONS}/${ROUTE_SUBSCRIPTIONS_EXPIRED}`;

// SETTINGS
export const PATH_SETTINGS = `${PATH_PERSONAL_AREA}/${ROUTE_SETTINGS}`;
export const PATH_COMPANY_INFO = `${PATH_SETTINGS}/${ROUTE_COMPANY_INFO}`;
export const PATH_SUBSCRIPTION_INFO_PERSONAL = `${PATH_SETTINGS}/${ROUTE_SUBSCRIPTION_INFO_PERSONAL}`;
export const PATH_INSTRUCTION_CARDS = `${PATH_SETTINGS}/${ROUTE_INSTRUCTION_CARDS}`;

// SEARCH PARAMS
export const SEARCH_PARAM_PAYMENT_IN_SUBS = "payment";
export const SEARCH_PARAM_PAY_GROUPS_IN_PAYMENT_CONFIGURATOR = "payGroups";
