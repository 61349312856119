import { PRODUCT_NAME } from "constants/common";
import { FRONTEND_URL } from "constants/env";
import { PATH_AGRONOMY } from "constants/routes";

import React from "react";

import { Helmet } from "react-helmet";

import { LandingContactUs } from "components/landing-contact-us";
import { MTSFooter } from "components/mts-footer";
import {
  GAP_EVENT_TYPE,
  GA_SCREEN_NAME,
  useGoogleAnalyticsPages,
} from "hooks/google-analytics";

import { Cases } from "./components/cases";
import { Effects } from "./components/effects";
import { Gadgets } from "./components/gadgets";
import { Intro } from "./components/intro";
import { Problems } from "./components/problems";
import { ResolveCases } from "./components/resolve-cases";
import { ResolveProblems } from "./components/resolve-problems";

export const PageAgronomy = (): JSX.Element => {
  useGoogleAnalyticsPages({
    screenName: GA_SCREEN_NAME.agronomy,
    event: GAP_EVENT_TYPE.SCRN,
  });

  return (
    <>
      <Helmet>
        <title>Точное земледелие | {PRODUCT_NAME}</title>
        <meta
          name="description"
          content="Повышение эффективности земледелия с помощью точных координат."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={FRONTEND_URL + PATH_AGRONOMY} />
        <meta
          property="og:title"
          content={`Точное земледелие | ${PRODUCT_NAME}`}
        />
        <meta
          property="og:description"
          content="Повышение эффективности земледелия с помощью точных координат."
        />
        <meta property="og:site_name" content="Точное земледелие" />
      </Helmet>
      <Intro />
      <Problems />
      <ResolveProblems />
      <ResolveCases />
      <Gadgets />
      <Cases />
      <Effects />
      <LandingContactUs />
      <MTSFooter sx={{ margin: "120px 0 0 0" }} />
    </>
  );
};
