import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export const ITIcon = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon
      width="96"
      height="96"
      viewBox="0 0 96 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M68 21H28C23.5817 21 20 24.5817 20 29V35C20 37.2091 21.7909 39 24 39H72C74.2091 39 76 37.2091 76 35V29C76 24.5817 72.4183 21 68 21Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M72 39H24C21.7909 39 20 40.7909 20 43V53C20 55.2091 21.7909 57 24 57H72C74.2091 57 76 55.2091 76 53V43C76 40.7909 74.2091 39 72 39Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M72 57H24C21.7909 57 20 58.7909 20 61V67C20 71.4183 23.5817 75 28 75H68C72.4183 75 76 71.4183 76 67V61C76 58.7909 74.2091 57 72 57Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <ellipse
        cx="31.3066"
        cy="30.0001"
        rx="2.69231"
        ry="2.64706"
        fill="black"
      />
      <ellipse
        cx="31.3066"
        cy="48.0001"
        rx="2.69231"
        ry="2.64706"
        fill="black"
      />
      <ellipse
        cx="31.3066"
        cy="66.0001"
        rx="2.69231"
        ry="2.64706"
        fill="black"
      />
    </SvgIcon>
  );
};
