import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const NextWeek = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 6.5H20C21.1 6.5 22 7.4 22 8.5V19.5C22 20.6 21.1 21.5 20 21.5H4C2.9 21.5 2 20.6 2 19.5V8.5C2 7.4 2.9 6.5 4 6.5H8V4.5C8 3.4 8.9 2.5 10 2.5H14C14.55 2.5 15.05 2.72 15.41 3.09C15.78 3.45 16 3.95 16 4.5V6.5ZM11 10L15 14L11 18L10 17L13 14L10 11L11 10ZM10 4.5H14V6.5H10V4.5ZM4 19.5H20V8.5H4V19.5Z"
      />
    </SvgIcon>
  );
};

export default NextWeek;
