import { MutationKeys } from "constants/keys";

import React from "react";

import { useIsMutating } from "react-query";

import Tooltip from "@mui/material/Tooltip";
import { MTSButtonIcon } from "components/mts-button-icon";
import { MTSIcon } from "components/mts-icon";
import { useMutationDownloadRinexFile } from "hooks/use-mutation-download-rinex-file";

type ITableButtonDownloadRinexProps = {
  rinexFileId: string;
  disabled?: boolean;
};

export const TableButtonDownloadRinex = (
  props: ITableButtonDownloadRinexProps
) => {
  const { rinexFileId, disabled = true } = props;
  const mutationDownloadRinexFile = useMutationDownloadRinexFile(rinexFileId);
  const isDownloadingRinexFile = !!useIsMutating({
    mutationKey: [MutationKeys.DOWNLOAD_RINEX_FILE, rinexFileId],
  });

  return (
    <Tooltip
      title="Скачать Rinex-файл"
      arrow
      disableHoverListener={disabled}
      disableFocusListener={disabled}
      disableTouchListener={disabled}
      disableInteractive
    >
      <div>
        <MTSButtonIcon
          Icon={MTSIcon.Download}
          variant="secondary"
          onClick={() => {
            mutationDownloadRinexFile.mutateAsync(rinexFileId);
          }}
          disabled={disabled}
          loading={
            mutationDownloadRinexFile.isLoading || isDownloadingRinexFile
          }
        />
      </div>
    </Tooltip>
  );
};
