import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const UserList = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.6665 36.6667C34.0303 36.6667 39.9998 30.6971 39.9998 23.3333C39.9998 15.9695 34.0303 10 26.6665 10C19.3027 10 13.3332 15.9695 13.3332 23.3333C13.3332 30.6971 19.3027 36.6667 26.6665 36.6667ZM26.6665 30C30.3484 30 33.3332 27.0152 33.3332 23.3333C33.3332 19.6514 30.3484 16.6667 26.6665 16.6667C22.9846 16.6667 19.9998 19.6514 19.9998 23.3333C19.9998 27.0152 22.9846 30 26.6665 30Z"
      />
      <path d="M36.6665 46.6667C38.5075 46.6667 39.9998 48.159 39.9998 50V70H46.6665V50C46.6665 44.4771 42.1894 40 36.6665 40H16.6665C11.1437 40 6.6665 44.4771 6.6665 50V70H13.3332V50C13.3332 48.159 14.8256 46.6667 16.6665 46.6667H36.6665Z" />
      <path d="M73.3332 36.6667H53.3332V43.3333H73.3332V36.6667Z" />
      <path d="M53.3332 50H73.3332V56.6667H53.3332V50Z" />
      <path d="M73.3332 23.3333H53.3332V30H73.3332V23.3333Z" />
    </SvgIcon>
  );
};

export default UserList;
