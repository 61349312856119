import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  switch: {
    "&.MuiSwitch-root": {
      width: "auto",
      height: "auto",
    },
  },
  size_S_16: {
    "&.MuiSwitch-root": {
      "& .MuiSwitch-switchBase": {
        padding: "2px",
        height: "16px",
      },

      "& .Mui-checked": {
        transform: "translateX(12px)",
      },
    },

    "& .MuiSwitch-thumb": {
      width: "12px",
      height: "12px",
    },

    "& .MuiSwitch-track": {
      borderRadius: "calc(16px / 2)",
      width: "28px",
      height: "16px",
    },
  },
  size_M_24: {
    "&.MuiSwitch-root": {
      "& .MuiSwitch-switchBase": {
        padding: "3px",
        height: "24px",
      },

      "& .Mui-checked": {
        transform: "translateX(20px)",
      },
    },

    "& .MuiSwitch-thumb": {
      width: "18px",
      height: "18px",
    },

    "& .MuiSwitch-track": {
      borderRadius: "calc(24px / 2)",
      width: "44px",
      height: "24px",
    },
  },
  size_L_32: {
    "&.MuiSwitch-root": {
      "& .MuiSwitch-switchBase": {
        padding: "4px",
        height: "32px",
      },

      "& .Mui-checked": {
        transform: "translateX(24px)",
      },
    },

    "& .MuiSwitch-thumb": {
      width: "24px",
      height: "24px",
    },

    "& .MuiSwitch-track": {
      borderRadius: "calc(32px / 2)",
      width: "56px",
      height: "32px",
    },
  },
}));
