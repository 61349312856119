import { SUBSCRIPTION_SERVICE_KINDS } from "constants/keys";

import { GAE_PRODUCT_NAME } from "hooks/google-analytics";

export const getProductNameByPayment = (serviceKindCodes: string[]): string => {
  return serviceKindCodes.reduce((result: string, serviceKindCode: string) => {
    if (serviceKindCode === SUBSCRIPTION_SERVICE_KINDS.RINEX) {
      if (result.length) {
        result += "|" + GAE_PRODUCT_NAME.tarif_rinex;
      } else {
        result += GAE_PRODUCT_NAME.tarif_rinex;
      }
    } else if (serviceKindCode === SUBSCRIPTION_SERVICE_KINDS.RTK_RINEX) {
      if (result.length) {
        result += "|" + GAE_PRODUCT_NAME.tarif_rtk_rinex;
      } else {
        result += GAE_PRODUCT_NAME.tarif_rtk_rinex;
      }
    }

    return result;
  }, "");
};
