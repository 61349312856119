import React from "react";

export const useDeviceViewport = (): void => {
  React.useEffect(() => {
    const updateDeviceViewport = (): void => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    };

    updateDeviceViewport();

    window.addEventListener("resize", updateDeviceViewport);

    return () => {
      window.removeEventListener("resize", updateDeviceViewport);
    };
  }, []);
};
