import { PATH_PUBLIC_OFFER } from "constants/routes";

import classes from "./offer.module.css";

export const Offer = (): JSX.Element => {
  return (
    <div className={classes.block}>
      <p className={classes.text}>
        Переходя в личный кабинет, вы соглашаетесь&nbsp;
        <a
          className={classes.link}
          href={PATH_PUBLIC_OFFER}
          target="_blank"
          rel="noreferrer"
        >
          с офертой
        </a>
      </p>
    </div>
  );
};
