import { BACKEND_URL } from "./env";

// BACKEND SERVICES
export const PROFILE_SERVICE_URL = BACKEND_URL + "/profile-service/api/rest/v1";
export const BILLING_SERVICE_URL = BACKEND_URL + "/billing-service/api/rest/v1";
export const RINEX_SERVICE_URL = BACKEND_URL + "/rinex-service/rest/v1";
export const IDENTITY_SERVICE_URL = BACKEND_URL + "/identity-service/rest/v1";
export const CDC_DATA_SERVICE_URL = BACKEND_URL + "/cdc-data-service/rest/v1";
export const EGIS_SERVICE_URL = BACKEND_URL + "/egis-data-service/api/rest/v1";

// OTHER
export const PRODUCT_SUPPORT_URL = "https://support.mts.ru/locationpro";
export const INSTRUCTION_HOW_TO_WORK_ADMIN_URL =
  "https://support.mts.ru/locationpro/Instruktsiya-administratora-klienta/dobavlenie-i-oplata-podpisok";
export const INSTRUCTION_HOW_TO_WORK_CUSTOMER_BASE_URL =
  "https://support.mts.ru/locationpro/Instruktsiya-polzovatelya/podklyuchenie-ustroistva";
export const INSTRUCTION_DEVICE_CONNECT_URL =
  "https://support.mts.ru/locationpro/Instruktsiya-polzovatelya/podklyuchenie-ustroistva";
export const TO_BE_MTS_USER_URL =
  "https://moskva.mts.ru/business/mobilnaya-svyaz/korporativnie-tarifi-i-opcii/umnij_business_m";
