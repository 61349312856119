import { EMPTY_CELL_VALUE } from "constants/keys";

import React from "react";

import Tooltip from "@mui/material/Tooltip";

import classes from "./table-cell-with-tooltip.module.css";

interface ITableCellWithTooltipProps {
  text: string;
  textStyles?: React.CSSProperties;
  cellWidth: number;
  dataTestId?: string | null;
}

export const TableCellWithTooltip = React.memo(
  (props: ITableCellWithTooltipProps) => {
    const { text, cellWidth = 0, dataTestId = null, textStyles } = props;
    const textElementRef = React.useRef<HTMLInputElement | null>(null);
    const [isOverflow, setIsOverflow] = React.useState<boolean>(false);

    React.useEffect(() => {
      if (textElementRef?.current && cellWidth) {
        const tableCellPaddingLeft = 12;
        const scrollWidth: number =
          textElementRef?.current?.scrollWidth + tableCellPaddingLeft || 0;

        setIsOverflow(scrollWidth > cellWidth);
      }
    }, [cellWidth, text]);

    const typography = (
      <span
        className={classes.text}
        data-testid={dataTestId || ""}
        ref={textElementRef}
        style={{
          ...textStyles,
        }}
      >
        {text ? text : EMPTY_CELL_VALUE}
      </span>
    );

    if (isOverflow) {
      return (
        <Tooltip title={text} arrow disableInteractive>
          {typography}
        </Tooltip>
      );
    }

    return <>{typography}</>;
  }
);
