import { SUBSCRIPTION_DURATIONS } from "constants/keys";

import { IDictionaryDTO } from "interfaces";

export const getDurationsWithOutTest = (
  durations: IDictionaryDTO[] | undefined
): IDictionaryDTO[] => {
  if (durations && Array.isArray(durations)) {
    return durations?.filter(
      (duration) => duration.code !== SUBSCRIPTION_DURATIONS.TWO_WEEKS
    );
  } else {
    return [];
  }
};
