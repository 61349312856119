import { PAYMENT_STATUSES } from "constants/keys";

import React from "react";

import { PaymentCardIcon } from "../payment-card-icon";

import classes from "./payment-card-status.module.css";

interface IPaymentCardStatusProps {
  paymentStatus: string;
}

export const PaymentCardStatus = React.memo(
  (props: IPaymentCardStatusProps): JSX.Element | null => {
    const { paymentStatus } = props;

    const getBadgeLabel = (status: string): string => {
      switch (status) {
        case PAYMENT_STATUSES.DRAFT:
          return "Подписки не оплачены";
        case PAYMENT_STATUSES.IN_PROCESS:
          return "Ожидаем оплаты";
        case PAYMENT_STATUSES.TO_QUEUE:
          return "В обработке";
        case PAYMENT_STATUSES.DONE:
        case PAYMENT_STATUSES.TEST:
          return "Подписки оплачены";
        case PAYMENT_STATUSES.FAILED:
          return "Не удалось запросить счёт";
        default:
          return "";
      }
    };

    return (
      <div className={classes.paymentStatus}>
        <PaymentCardIcon status={paymentStatus} />
        <p className={classes.paymentStatusLabel}>
          {getBadgeLabel(paymentStatus)}
        </p>
      </div>
    );
  }
);
