import classes from "./crossed-out-text.module.css";

interface ICrossedOutTextProps {
  children: JSX.Element;
  crossLineColor?: string;
  crossLineWidth?: number;
}

export const CrossedOutText = (props: ICrossedOutTextProps): JSX.Element => {
  const {
    children,
    crossLineColor = "var(--color-constant-greyscale-400)",
    crossLineWidth = 1,
  } = props;

  return (
    <div className={classes.blockCrossedOutText}>
      {children}
      <span
        className={classes.line}
        style={{
          borderTop: `${crossLineWidth}px solid ${crossLineColor}`,
        }}
      ></span>
    </div>
  );
};
