import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const Edit = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M4.66667 20C4.29848 20 4 19.7015 4 19.3333V16.9366C4 16.7608 4.06941 16.5922 4.19312 16.4673L13.9273 6.64386L17.3161 9.98164L7.58447 19.8026C7.45928 19.9289 7.28879 20 7.11092 20H4.66667Z" />
      <path d="M19.7356 6.34311C20.0881 6.69024 20.0881 7.25099 19.7356 7.59812L18.2831 9.02925L14.8942 5.69148L16.3468 4.26035C16.6992 3.91322 17.2686 3.91322 17.621 4.26035L19.7356 6.34311Z" />
    </SvgIcon>
  );
};

export default Edit;
