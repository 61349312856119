import { IPayGroupByDurations } from "interfaces";

import classes from "./payment-card-pay-group.module.css";

interface IPaymentCardPayGroup {
  title: string;
  payGroup: IPayGroupByDurations | null;
}

export const PaymentCardPayGroup = (
  props: IPaymentCardPayGroup
): JSX.Element | null => {
  const { title, payGroup } = props;

  const isShowPayGroup: boolean = payGroup
    ? Object.values(payGroup).reduce((sum, count) => sum + count, 0) > 0
    : false;

  if (!isShowPayGroup || !payGroup) {
    return null;
  }

  return (
    <li className={classes.group}>
      <p className={classes.groupTitle}>{title}</p>
      <ul className={classes.groupItems}>
        {payGroup.day > 0 && (
          <li className={classes.groupItem}>
            <p className={classes.groupItemDuration}>1 день</p>
            <p className={classes.groupItemCount}>{payGroup.day} шт</p>
          </li>
        )}

        {payGroup.month > 0 && (
          <li className={classes.groupItem}>
            <p className={classes.groupItemDuration}>1 мес</p>
            <p className={classes.groupItemCount}>{payGroup.month} шт</p>
          </li>
        )}

        {payGroup.quarter > 0 && (
          <li className={classes.groupItem}>
            <p className={classes.groupItemDuration}>3 мес</p>
            <p className={classes.groupItemCount}>{payGroup.quarter} шт</p>
          </li>
        )}

        {payGroup.halfYear > 0 && (
          <li className={classes.groupItem}>
            <p className={classes.groupItemDuration}>6 мес</p>
            <p className={classes.groupItemCount}>{payGroup.halfYear} шт</p>
          </li>
        )}

        {payGroup.year > 0 && (
          <li className={classes.groupItem}>
            <p className={classes.groupItemDuration}>1 год</p>
            <p className={classes.groupItemCount}>{payGroup.year} шт</p>
          </li>
        )}

        {payGroup.testPeriod > 0 && (
          <li className={classes.groupItem}>
            <p className={classes.groupItemDuration}>14 дней</p>
            <p className={classes.groupItemCount}>{payGroup.testPeriod} шт</p>
          </li>
        )}
      </ul>
    </li>
  );
};
