import { IS_DEVELOPMENT_MODE } from "constants/env";

import React from "react";

import { QueryClient, QueryClientProvider } from "react-query";

import { ReactQueryDevtools } from "react-query/devtools";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      structuralSharing: false,
      // notifyOnChangeProps: "tracked",
      // staleTime: 4 * 60 * 1000,
    },
  },
});

export const ReactQueryWrapper: React.FC = ({ children }) => {
  return (
    <QueryClientProvider client={queryClient}>
      {children}
      {IS_DEVELOPMENT_MODE && false && (
        <ReactQueryDevtools initialIsOpen={false} />
      )}
    </QueryClientProvider>
  );
};

ReactQueryWrapper.displayName = "ReactQueryWrapper";
