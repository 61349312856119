import { STAND_NAME } from "constants/env";

import React from "react";

import TagManager from "react-gtm-module";

import { useProfileData } from "hooks/use-profile-data";

import { useScreenName } from "./use-screen-name";

export enum GAP_EVENT_TYPE {
  SCRN = "scrn",
}

interface IUseGoogleAnalyticsPages {
  screenName?: string;
  event: GAP_EVENT_TYPE;
  enable?: boolean;
}

export const useGoogleAnalyticsPages = (props: IUseGoogleAnalyticsPages) => {
  const { screenName, event, enable = true } = props;
  const { profile, isLoadingAuthData, isAuthorized } = useProfileData();
  const currentScreenName: string = useScreenName();

  React.useEffect(() => {
    if (enable && !isLoadingAuthData) {
      const userId: string | null = profile?.guid ?? null;
      const userAuth: string = isAuthorized ? "1" : "0";

      const tagManagerArgs = {
        dataLayer: {
          event,
          userId,
          userAuth,
          screenName: screenName ? screenName : currentScreenName,
          eventContent: null,
          buttonLocation: null,
          filterName: null,
          actionGroup: "non_interactions",
          productName: null,
          touchPoint: "web",
          currentTariff: null,
          formOrderId: null,
        },
      };

      switch (STAND_NAME) {
        case "PROD":
          TagManager.dataLayer(tagManagerArgs);
          break;
        case "PRODLIKE":
          console.log("GAPage", tagManagerArgs.dataLayer);
          break;
        case "TEST":
        case "DEV":
          break;
        default:
          break;
      }
    }
  }, [
    profile?.guid,
    isLoadingAuthData,
    isAuthorized,
    event,
    screenName,
    enable,
    currentScreenName,
  ]);
};
