import React from "react";

import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { IMTSTab } from "components/mts-tabs";

import { TriangleDownIcon } from "./components/triangle-down-icon";

interface ITabsMobile {
  selectedMobileTab: string;
  handleChangeMobileTab: (event: SelectChangeEvent) => void;
  tabs: IMTSTab[];
  onClick?: (event: React.SyntheticEvent, tabName: string) => void;
  geodesyCadastre?: boolean;
}

export const TabsMobile = (props: ITabsMobile): JSX.Element => {
  const {
    selectedMobileTab,
    handleChangeMobileTab,
    tabs,
    onClick,
    geodesyCadastre,
  } = props;
  const fieldSetComponent = React.useRef<HTMLFieldSetElement>(null);
  const [position, setPosition] = React.useState(0);

  React.useEffect(() => {
    setPosition(
      fieldSetComponent.current
        ? fieldSetComponent.current.getBoundingClientRect().left
        : 0
    );
  }, [fieldSetComponent]);

  const handleSendMetrics = (event: React.SyntheticEvent, tabName: string) => {
    onClick && onClick(event, tabName);
  };

  return (
    <FormControl>
      <Select
        value={selectedMobileTab}
        onChange={handleChangeMobileTab}
        defaultValue={selectedMobileTab}
        endAdornment={<TriangleDownIcon />}
        ref={fieldSetComponent}
        MenuProps={{
          PaperProps: {
            sx: {
              minWidth: !geodesyCadastre
                ? "287px !important"
                : "256px !important",
              transform: "translateY(5px) !important",
              left: `${position}px !important`,
              borderRadius: "6px !important",
              boxShadow:
                "0px 12px 20px 0px rgba(0, 0, 0, 0.14), 0px 4px 24px 0px rgba(0, 0, 0, 0.12) !important",
              "& ul": {
                paddingTop: "0px",
                paddingBottom: "0px",
              },
            },
          },
        }}
        sx={{
          fontFamily: "var(--typography-v3-p3-medium-comp-font-family)",
          fontSize: "var(--typography-v3-p3-medium-comp-font-size)",
          lineHeight: "var(--typography-v3-p3-medium-comp-line-height)",
          fontWeight: "var(--typography-v3-p3-medium-comp-font-weight)",
          color: "var(--color-text-primary)",

          paddingLeft: "2px",
          "& .MuiSelect-iconOutlined": { display: "none" },
          "& .MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input": {
            paddingRight: "0px !important",
          },
          "& svg": {
            fontSize: "16px",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
            borderRadius: "0",
            borderBottom: `2px solid var(--color-brand)`,
            padding: "0",
            marginRight: "14px",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: `var(--color-brand) !important`,
          },
          "& .MuiSelect-select": {
            paddingLeft: "0",
            paddingRight: "0 !important",
          },
          width: "auto",
          height: "36px",
        }}
      >
        {tabs.map((tab: IMTSTab, index: number) => (
          <MenuItem
            sx={{
              fontFamily: "var(--typography-v3-p3-regular-comp-font-family)",
              fontSize: "var(--typography-v3-p3-regular-comp-font-size)",
              lineHeight: "var(--typography-v3-p3-regular-comp-line-height)",
              fontWeight: "var(--typography-v3-p3-regular-comp-font-weight)",
              color: "var(--color-text-primary)",
              minHeight: "44px",
              padding: "0",
              paddingLeft: "12px",
              "&.Mui-selected": {
                backgroundColor: "rgba(25, 118, 210, 0.08) !important",
              },
            }}
            value={tab.name}
            key={index}
            onClick={(event) => handleSendMetrics(event, tab.name)}
          >
            {tab.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
