import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export const MTSIconSvg = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="32" height="32" rx="8" />
      <path
        d="M20.5363 4.43066V5.71158H23.2835V10.723L23.284 10.8055H24.822V5.71158H27.5691V4.43066H20.5363Z"
        fill="white"
      />
      <path
        d="M10.6428 4.44975L8.6336 8.64011L6.62437 4.44975H4.43066V10.7858H5.96861V6.25124L7.93255 10.0822H9.33468L11.2992 6.25124V10.7858H12.8371V4.44975H10.6428Z"
        fill="white"
      />
      <path
        d="M26.0273 25.5695C25.9986 25.8035 25.8925 26.021 25.7259 26.1872C25.553 26.3453 25.3404 26.453 25.111 26.4988C24.7758 26.5655 24.4346 26.5966 24.093 26.5915C23.7292 26.598 23.3668 26.5447 23.0202 26.4337C22.736 26.341 22.491 26.1553 22.3241 25.9064C22.1564 25.657 22.0737 25.2971 22.0737 24.8394V24.1903C22.0737 23.7292 22.1581 23.3692 22.3241 23.1199C22.4906 22.8715 22.735 22.6861 23.0185 22.5932C23.3651 22.4821 23.7275 22.4288 24.0913 22.4354C24.433 22.4303 24.7742 22.4613 25.1094 22.528C25.3387 22.574 25.5513 22.6817 25.7243 22.8397C25.8908 23.0059 25.9969 23.2234 26.0256 23.4574H27.5647C27.5369 22.97 27.3518 22.505 27.0375 22.1327C26.7275 21.7903 26.3263 21.5443 25.8814 21.424C25.2979 21.2694 24.6958 21.197 24.0924 21.2089C23.355 21.2089 22.7155 21.3156 22.1916 21.5256C21.6734 21.726 21.2393 22.0989 20.9618 22.5819C20.6906 23.0497 20.5481 23.6741 20.5386 24.4396V24.5131V24.5833C20.5481 25.3487 20.6906 25.9732 20.9618 26.441C21.2393 26.924 21.6734 27.2969 22.1916 27.4972C22.716 27.7073 23.3562 27.814 24.0924 27.814C24.6958 27.8262 25.2979 27.7538 25.8814 27.5989C26.3265 27.479 26.7278 27.233 27.0375 26.8902C27.3519 26.5179 27.537 26.0529 27.5647 25.5655L26.0273 25.5695Z"
        fill="white"
      />
    </SvgIcon>
  );
};
