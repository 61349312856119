import { PATH_GEODESY_CADASTRE, PATH_MAIN } from "constants/routes";

import React from "react";

import classNames from "classnames";
import { BlockContent } from "components/block-content";
import { BlockWide } from "components/block-wide";
import { Breadcrumbs } from "components/breadcrumbs";
import { DialogContactUs } from "components/dialog-contact-us";
import { MTSButton } from "components/mts-button";
import {
  GAE_ACTION_GROUP,
  GAE_BUTTON_LOCATION,
  GAE_EVENT,
  GAE_EVENT_ACTION,
  GAE_EVENT_CATEGORY,
  GAE_EVENT_LABEL,
  useGoogleAnalyticsEvents,
} from "hooks/google-analytics";
import { useTabNames } from "hooks/tabs/use-tab-names";
import { useAppTheme } from "hooks/use-app-theme";

import geodesyCadstreImg from "./img/geodesy-cadastre-img.jpg";
import classes from "./intro.module.css";

const links = [
  {
    href: "#",
    label: "RTK-поправки",
  },
  {
    href: "#",
    label: "RINEX",
  },
  {
    href: "#",
    label: "Быстрое подключение",
  },
  {
    href: "#",
    label: "Гибкие тарифы",
  },
  {
    href: "#",
    label: "Услуги",
  },
  {
    href: "#",
    label: "Поддержка",
  },
];

export const Intro = React.memo((): JSX.Element => {
  const theme = useAppTheme();
  const { tabNames } = useTabNames();
  const { sendGAE } = useGoogleAnalyticsEvents();

  const breadcrumbLinks = React.useMemo(
    () => [
      {
        name: tabNames.main,
        href: PATH_MAIN,
      },
      { name: tabNames.geodesyCadastrе, href: PATH_GEODESY_CADASTRE },
    ],
    [tabNames.geodesyCadastrе, tabNames.main]
  );

  const [isOpenDialogContactUs, setIsOpenDialogContactUs] =
    React.useState<boolean>(false);

  const handleOpenDialog = (): void => {
    setIsOpenDialogContactUs(true);
  };

  const handleCloseDialog = (): void => {
    setIsOpenDialogContactUs(false);
  };

  const handleSendMetrics = (): void => {
    sendGAE({
      id: 74,
      event: GAE_EVENT.vntGeod,
      eventCategory: GAE_EVENT_CATEGORY.geodeziya_i_kadastr,
      eventAction: GAE_EVENT_ACTION.button_click,
      eventLabel: GAE_EVENT_LABEL.zakazat_demonstraciu,
      eventValue: null,
      eventContent: null,
      buttonLocation: GAE_BUTTON_LOCATION.screen,
      filterName: null,
      actionGroup: GAE_ACTION_GROUP.non_interactions,
      productName: null,
    });
  };

  return (
    <>
      <div className={classes.blockIntro}>
        <BlockWide>
          <BlockContent>
            <Breadcrumbs links={breadcrumbLinks} />
          </BlockContent>
        </BlockWide>

        <BlockWide
          sx={{
            marginTop: "16px",
            height: "100%",
            backgroundImage: `url(${geodesyCadstreImg})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        >
          <BlockContent>
            <div className={classes.info}>
              <h2 className={classes.infoTitle}>
                Геодезия
                <br />и кадастр
              </h2>

              <div className={classes.infoContent}>
                <p className={classes.infoContentText}>
                  Корректирующие данные для проведения исследований{" "}
                  <br className={classes.infoContent__break} />
                  и&nbsp;изысканий в&nbsp;строительстве и&nbsp;навигации
                </p>

                <div className={classes.infoContentLinks}>
                  {/* TODO: Временно вместо ссылок используем div блоки */}
                  {links.map((linkItem, index) => (
                    <div
                      key={index}
                      className={classNames(
                        classes.infoContentLink,
                        index === 0
                          ? classes.infoContentLink_1
                          : index === 1
                          ? classes.infoContentLink_2
                          : index === 2
                          ? classes.infoContentLink_3
                          : index === 3
                          ? classes.infoContentLink_4
                          : index === 4
                          ? classes.infoContentLink_5
                          : classes.infoContentLink_6
                      )}
                    >
                      <h4 className={classes.infoContentLinkText}>
                        {linkItem.label}
                      </h4>
                    </div>
                  ))}
                </div>
              </div>

              <MTSButton
                variant="primary"
                size="L"
                onClick={() => {
                  handleOpenDialog();
                  handleSendMetrics();
                }}
                sx={{
                  maxWidth: "292px",
                  [theme.breakpoints.down("tablet_768")]: {
                    marginTop: "24px",
                  },
                  "@media (max-width: 500px)": {
                    maxWidth: "100%",
                  },
                }}
              >
                Заказать демонстрацию
              </MTSButton>
            </div>
          </BlockContent>
        </BlockWide>
      </div>
      <DialogContactUs
        isOpen={isOpenDialogContactUs}
        handleCloseDialog={handleCloseDialog}
        feedbackType="DEMONSTRATION"
        title="Заказать демонстрацию"
      />
    </>
  );
});
