import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const Information = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x="2" y="2" width="20" height="20" rx="10" />
      <g clipPath="url(#clip0_3843_332392)">
        <path
          d="M11.9001 8.46699C11.403 8.46699 11.0001 8.06405 11.0001 7.56699C11.0001 7.06994 11.403 6.66699 11.9001 6.66699C12.3971 6.66699 12.8001 7.06994 12.8001 7.56699C12.8001 8.06405 12.3971 8.46699 11.9001 8.46699Z"
          fill="white"
        />
        <path
          d="M12 17C11.5858 17 11.25 16.6642 11.25 16.25V11.5H10C9.58579 11.5 9.25 11.1642 9.25 10.75C9.25 10.3358 9.58579 10 10 10L12 10C12.4142 10 12.75 10.3358 12.75 10.75V16.25C12.75 16.6642 12.4142 17 12 17Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_3843_332392">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(4 4)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default Information;
