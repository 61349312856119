import { PRODUCT_NAME } from "constants/common";
import { FRONTEND_URL } from "constants/env";
import { PATH_GEODESY_CADASTRE } from "constants/routes";

import React from "react";

import { Helmet } from "react-helmet";

import { LandingContactUs } from "components/landing-contact-us";
import { MTSFooter } from "components/mts-footer";
import {
  GAP_EVENT_TYPE,
  GA_SCREEN_NAME,
  useGoogleAnalyticsPages,
} from "hooks/google-analytics";

import { Cases } from "./components/cases";
import { CasesSlide } from "./components/cases-slide";
import { Intro } from "./components/intro";
import { Problems } from "./components/problems";
import { ResolveCases } from "./components/resolve-cases";

export const PageGeodesyCadastre = (): JSX.Element => {
  useGoogleAnalyticsPages({
    screenName: GA_SCREEN_NAME.geodesy_cadastre,
    event: GAP_EVENT_TYPE.SCRN,
  });

  return (
    <>
      <Helmet>
        <title>Геодезия и кадастр | {PRODUCT_NAME}</title>
        <meta
          name="description"
          content="Корректирующие данные для проведения исследований и изысканий в строительстве и навигации."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content={FRONTEND_URL + PATH_GEODESY_CADASTRE}
        />
        <meta
          property="og:title"
          content={`Геодезия и кадастр | ${PRODUCT_NAME}`}
        />
        <meta
          property="og:description"
          content="Корректирующие данные для проведения исследований и изысканий в строительстве и навигации."
        />
        <meta property="og:site_name" content="Геодезия и кадастр" />
      </Helmet>
      <Intro />
      <Problems />
      <CasesSlide />
      <ResolveCases />
      <Cases />
      <LandingContactUs />
      <MTSFooter sx={{ margin: "120px 0 0 0" }} />
    </>
  );
};
