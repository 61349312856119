import React from "react";

import classes from "./table.module.css";

export const Table = (): JSX.Element => {
  return (
    <div className={classes.blockTable}>
      <div className={classes.tableRow}>
        <p className={classes.tableRowHeadCell}></p>
        <p className={classes.tableRowHeadCell}>1 год</p>
        <p className={classes.tableRowHeadCell}>3 года</p>
      </div>

      <div className={classes.tableRow}>
        <p className={classes.tableRowCellLabel}>
          Количество техники&nbsp;
          <span style={{ whiteSpace: "nowrap" }}>по нормативу</span>
        </p>
        <p className={classes.tableRowCell}>
          9&nbsp;<span className={classes.tableRowCellRegular}>ед</span>
        </p>
        <p className={classes.tableRowCell}></p>
      </div>

      <div className={classes.tableRow}>
        <p className={classes.tableRowCellLabel}>Затраты на LP</p>
        <p className={classes.tableRowCell}>
          4,41&nbsp;
          <span className={classes.tableRowCellRegular}>млн ₽</span>
        </p>
        <p className={classes.tableRowCell}>
          5,13&nbsp;
          <span className={classes.tableRowCellRegular}>млн ₽</span>
        </p>
      </div>

      <div className={classes.tableRow}>
        <p className={classes.tableRowCellLabel}>Эффект Экономия</p>
        <p className={classes.tableRowCell}>
          6,3&nbsp;
          <span className={classes.tableRowCellRegular}>млн ₽</span>
        </p>
        <p className={classes.tableRowCell}>
          18,9&nbsp;
          <span className={classes.tableRowCellRegular}>млн ₽</span>
        </p>
      </div>

      <div className={classes.tableRow}>
        <p className={classes.tableRowCellLabel}>ROI</p>
        <p className={classes.tableRowCell}>45%</p>
        <p className={classes.tableRowCell}>268%</p>
      </div>
    </div>
  );
};
