import { HTTPStatusCode, LS_GAE_SUCCESS_AUTH, QueryKeys } from "constants/keys";
import { REFETCH_INTERVAL_TO_CHECK_PROFILE_DATA } from "constants/server";

import { useQuery } from "react-query";

import {
  GAE_ACTION_GROUP,
  GAE_BUTTON_LOCATION,
  GAE_EVENT,
  GAE_EVENT_ACTION,
  GAE_EVENT_CATEGORY,
  GAE_EVENT_LABEL,
  useGoogleAnalyticsEvents,
} from "hooks/google-analytics";
import { useContextMain } from "hooks/use-context-main";
import { IDataGAESuccessAuth, IProfileStruct } from "interfaces";
import { DateTime } from "luxon";
import { getProfile } from "services/api";
import { AuthService } from "services/auth";

export const useCheckProfile = () => {
  const {
    isAuthorized,
    profile,
    setProfile,
    timeIntervalCheckProfile,
    setTimeIntervalCheckProfile,
  } = useContextMain();
  const { sendGAE } = useGoogleAnalyticsEvents();

  const sendGAESuccessAuth = (guid: string): void => {
    const dateGAESend: string = DateTime.now().toISO();
    const dataGAESuccessAuth: IDataGAESuccessAuth = {
      guid: guid,
      date: dateGAESend,
    };

    sendGAE({
      id: 20,
      event: GAE_EVENT.vntLogin,
      eventCategory: GAE_EVENT_CATEGORY.avtorizaciya,
      eventAction: GAE_EVENT_ACTION.confirmed,
      eventLabel: GAE_EVENT_LABEL.uspeshnaya_avtorizaciya,
      eventValue: null,
      eventContent: null,
      buttonLocation: GAE_BUTTON_LOCATION.screen,
      filterName: null,
      actionGroup: GAE_ACTION_GROUP.conversions,
      productName: null,
    });

    localStorage.setItem(
      LS_GAE_SUCCESS_AUTH,
      JSON.stringify(dataGAESuccessAuth)
    );
  };

  return useQuery(QueryKeys.PROFILE, () => getProfile(), {
    enabled: isAuthorized,
    refetchInterval: timeIntervalCheckProfile,
    refetchOnWindowFocus: true,
    retry: 0,
    onSuccess: async (response) => {
      const profileData: IProfileStruct | undefined = response?.data;
      const profileGUID: string = profileData?.guid ?? "";

      if (profileData?.role !== profile?.role) {
        try {
          const responseValidate = await AuthService.refresh();

          if (responseValidate.status === HTTPStatusCode.OK_200) {
            setProfile(profileData);
            setTimeIntervalCheckProfile(REFETCH_INTERVAL_TO_CHECK_PROFILE_DATA);
          }
        } catch (error) {
          console.error("Ошибка обновления токена при смене роли пользователя");
        }
      } else if (profileGUID) {
        const googleEventData = localStorage.getItem(LS_GAE_SUCCESS_AUTH);

        if (googleEventData) {
          const dataGAESuccessAuth: IDataGAESuccessAuth =
            JSON.parse(googleEventData);
          const dateGAE: string = dataGAESuccessAuth?.date ?? "";
          const isTimeToSendGAESuccessAuth: boolean =
            DateTime.now().diff(DateTime.fromISO(dateGAE)).toMillis() >=
            14400000;
          const guidGAE: string = dataGAESuccessAuth?.guid ?? "";

          if (guidGAE === profileGUID) {
            if (
              typeof isTimeToSendGAESuccessAuth === "boolean" &&
              isTimeToSendGAESuccessAuth
            ) {
              sendGAESuccessAuth(profileGUID);
            }
          } else {
            sendGAESuccessAuth(profileGUID);
          }
        } else {
          sendGAESuccessAuth(profileGUID);
        }
      }
    },
  });
};
