import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const Network = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="128"
      height="128"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="61.9046" cy="21.5142" r="3.50223" fill="black" />
      <circle cx="20.7561" cy="73.172" r="3.50223" fill="black" />
      <circle cx="26.0061" cy="39.9009" r="3.50223" fill="black" />
      <circle cx="49.6507" cy="95.0611" r="3.50223" fill="black" />
      <circle cx="95.178" cy="90.6833" r="3.50223" fill="black" />
      <circle cx="109.186" cy="58.2876" r="3.50223" fill="black" />
      <path
        d="M21.6328 72.7344L84.0234 59.6829"
        stroke="black"
        strokeWidth="2"
      />
      <path
        d="M27.3242 41.2144L85.0234 58.6829"
        stroke="black"
        strokeWidth="2"
      />
      <path
        d="M85.5234 58.6829L109.023 58.6829"
        stroke="black"
        strokeWidth="2"
      />
      <path d="M85.0234 57.6829L62 22.5" stroke="black" strokeWidth="2" />
      <path
        d="M94.5234 89.6829L87.0234 60.6829"
        stroke="black"
        strokeWidth="2"
      />
      <path
        d="M95.5703 90.7582L110.342 58.2877"
        stroke="black"
        strokeWidth="2"
      />
      <path
        d="M83.5234 60.6829L51.2628 94.7429"
        stroke="black"
        strokeWidth="2"
      />
      <path
        d="M20.7539 73.4547L50.5229 95.9366"
        stroke="black"
        strokeWidth="2"
      />
      <path
        d="M25.5703 40.3387L20.7547 72.7344"
        stroke="black"
        strokeWidth="2"
      />
      <path
        d="M95.1758 91.1211L50.0846 95.9367"
        stroke="black"
        strokeWidth="2"
      />
      <path
        d="M109.625 58.2877L62.3449 21.0765"
        stroke="black"
        strokeWidth="2"
      />
      <path
        d="M61.9062 21.0764L26.0073 40.1164"
        stroke="black"
        strokeWidth="2"
      />
      <path
        d="M62.3438 22.8276L50.0859 95.0611"
        stroke="black"
        strokeWidth="0.5"
        strokeDasharray="1.5 3"
      />
      <path
        d="M63.2227 22.3899L94.3049 89.37"
        stroke="black"
        strokeWidth="0.5"
        strokeDasharray="1.5 3"
      />
      <circle cx="26.0223" cy="40.1818" r="4.9989" fill="#E2E5EB" />
      <circle
        cx="26.0223"
        cy="40.1818"
        r="4.9989"
        fill="url(#paint0_radial_5973_406987)"
      />
      <circle
        cx="26.0223"
        cy="40.1818"
        r="4.9989"
        fill="url(#paint1_radial_5973_406987)"
      />
      <ellipse
        cx="22.5933"
        cy="38.7611"
        rx="0.36009"
        ry="0.40359"
        transform="rotate(14.7317 22.5933 38.7611)"
        fill="url(#paint2_radial_5973_406987)"
      />
      <ellipse
        cx="24.8001"
        cy="36.7625"
        rx="0.83297"
        ry="0.872532"
        transform="rotate(44.0756 24.8001 36.7625)"
        fill="url(#paint3_radial_5973_406987)"
      />
      <ellipse
        cx="28.0931"
        cy="37.7245"
        rx="0.157124"
        ry="0.192463"
        transform="rotate(-16.9301 28.0931 37.7245)"
        fill="url(#paint4_linear_5973_406987)"
      />
      <ellipse
        cx="21.9834"
        cy="41.2092"
        rx="0.122515"
        ry="0.145134"
        transform="rotate(2.45497 21.9834 41.2092)"
        fill="url(#paint5_linear_5973_406987)"
      />
      <ellipse
        cx="29.9605"
        cy="38.7125"
        rx="0.283331"
        ry="0.375366"
        transform="rotate(-19.8724 29.9605 38.7125)"
        fill="url(#paint6_linear_5973_406987)"
      />
      <g filter="url(#filter0_f_5973_406987)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.0014 44.958C28.6214 44.958 30.7454 42.834 30.7454 40.214C30.7454 39.2107 30.434 38.2802 29.9025 37.5138C30.496 38.3024 30.8477 39.2832 30.8477 40.3461C30.8477 42.9501 28.7367 45.0611 26.1327 45.0611C25.0594 45.0611 24.0698 44.7024 23.2773 44.0985C24.0482 44.6401 24.9877 44.958 26.0014 44.958Z"
          fill="white"
        />
      </g>
      <circle
        cx="26.0234"
        cy="40.1829"
        r="5"
        fill="url(#paint7_radial_5973_406987)"
      />
      <circle cx="85.0223" cy="59.1818" r="4.9989" fill="#E2E5EB" />
      <circle
        cx="85.0223"
        cy="59.1818"
        r="4.9989"
        fill="url(#paint8_radial_5973_406987)"
      />
      <circle
        cx="85.0223"
        cy="59.1818"
        r="4.9989"
        fill="url(#paint9_radial_5973_406987)"
      />
      <ellipse
        cx="81.5933"
        cy="57.7611"
        rx="0.36009"
        ry="0.40359"
        transform="rotate(14.7317 81.5933 57.7611)"
        fill="url(#paint10_radial_5973_406987)"
      />
      <ellipse
        cx="83.8001"
        cy="55.7625"
        rx="0.83297"
        ry="0.872532"
        transform="rotate(44.0756 83.8001 55.7625)"
        fill="url(#paint11_radial_5973_406987)"
      />
      <ellipse
        cx="87.0931"
        cy="56.7245"
        rx="0.157124"
        ry="0.192463"
        transform="rotate(-16.9301 87.0931 56.7245)"
        fill="url(#paint12_linear_5973_406987)"
      />
      <ellipse
        cx="80.9834"
        cy="60.2092"
        rx="0.122515"
        ry="0.145134"
        transform="rotate(2.45497 80.9834 60.2092)"
        fill="url(#paint13_linear_5973_406987)"
      />
      <ellipse
        cx="88.9605"
        cy="57.7125"
        rx="0.283331"
        ry="0.375366"
        transform="rotate(-19.8724 88.9605 57.7125)"
        fill="url(#paint14_linear_5973_406987)"
      />
      <g filter="url(#filter1_f_5973_406987)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M85.0014 63.958C87.6214 63.958 89.7454 61.834 89.7454 59.214C89.7454 58.2107 89.434 57.2802 88.9025 56.5138C89.496 57.3024 89.8477 58.2832 89.8477 59.3461C89.8477 61.9501 87.7367 64.0611 85.1327 64.0611C84.0594 64.0611 83.0698 63.7024 82.2773 63.0985C83.0482 63.6401 83.9877 63.958 85.0014 63.958Z"
          fill="white"
        />
      </g>
      <circle
        cx="85.0234"
        cy="59.1829"
        r="5"
        fill="url(#paint15_radial_5973_406987)"
      />
      <circle cx="50.1587" cy="95.6814" r="6.49857" fill="#E2E5EB" />
      <circle
        cx="50.1587"
        cy="95.6814"
        r="6.49857"
        fill="url(#paint16_radial_5973_406987)"
      />
      <circle
        cx="50.1587"
        cy="95.6814"
        r="6.49857"
        fill="url(#paint17_radial_5973_406987)"
      />
      <ellipse
        cx="45.6982"
        cy="93.8346"
        rx="0.468117"
        ry="0.524667"
        transform="rotate(14.7317 45.6982 93.8346)"
        fill="url(#paint18_radial_5973_406987)"
      />
      <ellipse
        cx="48.571"
        cy="91.2364"
        rx="1.08286"
        ry="1.13429"
        transform="rotate(44.0756 48.571 91.2364)"
        fill="url(#paint19_radial_5973_406987)"
      />
      <ellipse
        cx="52.8503"
        cy="92.487"
        rx="0.204261"
        ry="0.250201"
        transform="rotate(-16.9301 52.8503 92.487)"
        fill="url(#paint20_linear_5973_406987)"
      />
      <ellipse
        cx="44.9089"
        cy="97.0171"
        rx="0.15927"
        ry="0.188674"
        transform="rotate(2.45497 44.9089 97.0171)"
        fill="url(#paint21_linear_5973_406987)"
      />
      <ellipse
        cx="55.2779"
        cy="93.7715"
        rx="0.368331"
        ry="0.487976"
        transform="rotate(-19.8724 55.2779 93.7715)"
        fill="url(#paint22_linear_5973_406987)"
      />
      <g filter="url(#filter2_f_5973_406987)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M50.1311 101.89C53.5372 101.89 56.2983 99.1293 56.2983 95.7232C56.2983 94.419 55.8935 93.2093 55.2026 92.213C55.9741 93.2382 56.4313 94.5132 56.4313 95.895C56.4313 99.2802 53.6871 102.024 50.3019 102.024C48.9065 102.024 47.6201 101.558 46.5898 100.773C47.592 101.477 48.8133 101.89 50.1311 101.89Z"
          fill="white"
        />
      </g>
      <circle
        cx="50.1602"
        cy="95.6829"
        r="6.5"
        fill="url(#paint23_radial_5973_406987)"
      />
      <defs>
        <filter
          id="filter0_f_5973_406987"
          x="11.2773"
          y="25.5138"
          width="31.5703"
          height="31.5472"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="6"
            result="effect1_foregroundBlur_5973_406987"
          />
        </filter>
        <filter
          id="filter1_f_5973_406987"
          x="70.2773"
          y="44.5138"
          width="31.5703"
          height="31.5472"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="6"
            result="effect1_foregroundBlur_5973_406987"
          />
        </filter>
        <filter
          id="filter2_f_5973_406987"
          x="34.5898"
          y="80.213"
          width="33.8398"
          height="33.8115"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="6"
            result="effect1_foregroundBlur_5973_406987"
          />
        </filter>
        <radialGradient
          id="paint0_radial_5973_406987"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(28.1165 42.4087) rotate(-130.642) scale(7.75005)"
        >
          <stop stopColor="#E9ECEE" />
          <stop offset="1" stopColor="white" stopOpacity="0.58" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_5973_406987"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(24.7376 39.2362) rotate(-134.576) scale(4.92444)"
        >
          <stop offset="0.489583" stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint2_radial_5973_406987"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(22.0238 38.3686) rotate(32.4641) scale(1.32711 1.42839)"
        >
          <stop stopColor="#E0E1E7" />
          <stop offset="0.7148" stopColor="white" />
          <stop offset="0.838427" stopColor="#F1F2F5" />
        </radialGradient>
        <radialGradient
          id="paint3_radial_5973_406987"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(20.8894 35.4536) rotate(16.8304) scale(12.5241 13.0483)"
        >
          <stop offset="0.162182" stopColor="#DEDFE2" />
          <stop offset="0.376793" stopColor="white" />
          <stop offset="0.434564" stopColor="#EEEFF2" />
        </radialGradient>
        <linearGradient
          id="paint4_linear_5973_406987"
          x1="27.8908"
          y1="37.5696"
          x2="28.9964"
          y2="38.1725"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0520833" stopColor="#E0E2E7" />
          <stop offset="0.352388" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_5973_406987"
          x1="21.9024"
          y1="41.166"
          x2="22.1158"
          y2="41.245"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E0E1E7" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_5973_406987"
          x1="29.7733"
          y1="38.6008"
          x2="30.2789"
          y2="38.7682"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E0E1E7" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <radialGradient
          id="paint7_radial_5973_406987"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(26.0234 40.1829) rotate(90) scale(5.1684)"
        >
          <stop offset="0.880208" stopColor="#F2F3F7" stopOpacity="0" />
          <stop offset="1" stopColor="white" />
        </radialGradient>
        <radialGradient
          id="paint8_radial_5973_406987"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(87.1165 61.4087) rotate(-130.642) scale(7.75005)"
        >
          <stop stopColor="#E9ECEE" />
          <stop offset="1" stopColor="white" stopOpacity="0.58" />
        </radialGradient>
        <radialGradient
          id="paint9_radial_5973_406987"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(83.7376 58.2362) rotate(-134.576) scale(4.92444)"
        >
          <stop offset="0.489583" stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint10_radial_5973_406987"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(81.0238 57.3686) rotate(32.4641) scale(1.32711 1.42839)"
        >
          <stop stopColor="#E0E1E7" />
          <stop offset="0.7148" stopColor="white" />
          <stop offset="0.838427" stopColor="#F1F2F5" />
        </radialGradient>
        <radialGradient
          id="paint11_radial_5973_406987"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(79.8894 54.4536) rotate(16.8304) scale(12.5241 13.0483)"
        >
          <stop offset="0.162182" stopColor="#DEDFE2" />
          <stop offset="0.376793" stopColor="white" />
          <stop offset="0.434564" stopColor="#EEEFF2" />
        </radialGradient>
        <linearGradient
          id="paint12_linear_5973_406987"
          x1="86.8908"
          y1="56.5696"
          x2="87.9964"
          y2="57.1725"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0520833" stopColor="#E0E2E7" />
          <stop offset="0.352388" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_5973_406987"
          x1="80.9024"
          y1="60.166"
          x2="81.1158"
          y2="60.245"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E0E1E7" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_5973_406987"
          x1="88.7733"
          y1="57.6008"
          x2="89.2789"
          y2="57.7682"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E0E1E7" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <radialGradient
          id="paint15_radial_5973_406987"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(85.0234 59.1829) rotate(90) scale(5.1684)"
        >
          <stop offset="0.880208" stopColor="#F2F3F7" stopOpacity="0" />
          <stop offset="1" stopColor="white" />
        </radialGradient>
        <radialGradient
          id="paint16_radial_5973_406987"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(52.8811 98.5764) rotate(-130.642) scale(10.0751)"
        >
          <stop stopColor="#E9ECEE" />
          <stop offset="1" stopColor="white" stopOpacity="0.58" />
        </radialGradient>
        <radialGradient
          id="paint17_radial_5973_406987"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(48.4886 94.4522) rotate(-134.576) scale(6.40177)"
        >
          <stop offset="0.489583" stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint18_radial_5973_406987"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(44.958 93.3244) rotate(32.4641) scale(1.72524 1.85691)"
        >
          <stop stopColor="#E0E1E7" />
          <stop offset="0.7148" stopColor="white" />
          <stop offset="0.838427" stopColor="#F1F2F5" />
        </radialGradient>
        <radialGradient
          id="paint19_radial_5973_406987"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(43.487 89.5347) rotate(16.8304) scale(16.2814 16.9627)"
        >
          <stop offset="0.162182" stopColor="#DEDFE2" />
          <stop offset="0.376793" stopColor="white" />
          <stop offset="0.434564" stopColor="#EEEFF2" />
        </radialGradient>
        <linearGradient
          id="paint20_linear_5973_406987"
          x1="52.5874"
          y1="92.2855"
          x2="54.0246"
          y2="93.0693"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0520833" stopColor="#E0E2E7" />
          <stop offset="0.352388" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint21_linear_5973_406987"
          x1="44.8036"
          y1="96.961"
          x2="45.081"
          y2="97.0636"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E0E1E7" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint22_linear_5973_406987"
          x1="55.0346"
          y1="93.6263"
          x2="55.6918"
          y2="93.8439"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E0E1E7" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <radialGradient
          id="paint23_radial_5973_406987"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(50.1602 95.6829) rotate(90) scale(6.71893)"
        >
          <stop offset="0.880208" stopColor="#F2F3F7" stopOpacity="0" />
          <stop offset="1" stopColor="white" />
        </radialGradient>
      </defs>
    </SvgIcon>
  );
};

export default Network;
