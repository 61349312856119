import React from "react";

import classes from "./block-wide.module.css";

interface IBlockWideProps {
  children: React.ReactNode;
  sx?: React.CSSProperties;
}

export const BlockWide = (props: IBlockWideProps): JSX.Element => {
  const { children, sx } = props;

  return (
    <div className={classes.blockWide} style={sx}>
      {children}
    </div>
  );
};
