import Fade from "@mui/material/Fade";

interface ITransitionFadeProps {
  isShow: boolean;
  delay?: number;
  children: any;
}

export const TransitionFade = (props: ITransitionFadeProps): JSX.Element => {
  const { isShow, delay = 700, children } = props;

  return (
    <Fade in={isShow} timeout={delay}>
      <div>{children}</div>
    </Fade>
  );
};
