import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const Shield = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.16876 6.18765C12.184 4.83137 16.3422 3.33335 20 3.33335C23.6578 3.33335 27.8161 4.83141 30.8313 6.18771C32.7833 7.06575 33.7593 7.50477 34.4315 8.60556C35.1038 9.70635 35.0446 10.8209 34.9262 13.0499C34.5895 19.3898 33.3443 25.6175 29.346 30.7486C27.3675 33.2876 23.2239 36.6667 20 36.6667C16.776 36.6667 12.6324 33.2876 10.6539 30.7485C6.65573 25.6175 5.4106 19.3899 5.07383 13.0501C4.95541 10.8209 4.89621 9.70636 5.56844 8.60555C6.24068 7.50473 7.2167 7.0657 9.16876 6.18765ZM10.5424 9.22621C13.4717 7.90858 17.0871 6.66657 20 6.66657C22.9129 6.66657 26.5283 7.90862 29.4576 9.22626C30.5101 9.69968 31.0022 9.92858 31.3385 10.1386C31.518 10.2965 31.5839 10.3919 31.6439 10.6643C31.6774 11.0919 31.6528 11.6771 31.5893 12.8736C31.2666 18.9507 30.0883 24.3646 26.7077 28.703C25.9325 29.6979 24.6528 30.9441 23.2405 31.9175C21.7357 32.9546 20.5867 33.3335 20 33.3335C19.4133 33.3335 18.2642 32.9546 16.7595 31.9175C15.3471 30.9441 14.0675 29.6978 13.2923 28.703C9.91173 24.3645 8.73357 18.9508 8.41075 12.8737C8.34719 11.6771 8.3226 11.092 8.3561 10.6643C8.41712 10.4118 8.48597 10.2982 8.66145 10.1385C8.99785 9.92854 9.48993 9.69963 10.5424 9.22621Z"
      />
    </SvgIcon>
  );
};

export default Shield;
