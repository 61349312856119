import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export const HarvestIcon = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon
      width="96"
      height="96"
      viewBox="0 0 96 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8 37.7569V42.1964"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.7012 35.5555V42.0483"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.3535 35.5555V42.0483"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.0518 37.7569V42.1964"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.04097 51.9425C7.44603 60.6072 13.4661 63.2762 17.3078 63.6188C17.8016 63.6629 18.2251 63.2929 18.2762 62.7998C19.2035 53.846 12.9773 51.3071 8.98357 51.0907C8.48765 51.0638 8.07499 51.447 8.04097 51.9425Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.04097 41.584C7.44603 50.2486 13.4661 52.9176 17.3078 53.2603C17.8016 53.3043 18.2251 52.9344 18.2762 52.4412C19.2035 43.4875 12.9773 40.9485 8.98357 40.7322C8.48765 40.7053 8.07499 41.0885 8.04097 41.584Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.9737 51.9475C29.5686 60.6122 23.5486 63.2812 19.7069 63.6238C19.2131 63.6679 18.7895 63.2979 18.7384 62.8048C17.8111 53.851 24.0374 51.3121 28.0311 51.0957C28.527 51.0688 28.9397 51.452 28.9737 51.9475Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.0108 41.584C29.6057 50.2486 23.5857 52.9176 19.744 53.2603C19.2502 53.3043 18.8266 52.9344 18.7755 52.4412C17.8482 43.4875 24.0745 40.9485 28.0682 40.7322C28.5641 40.7053 28.9768 41.0885 29.0108 41.584Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.5264 68.7407V47.3757"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M66.9473 37.7569V42.1964"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M73.6484 35.5555V42.0483"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M81.2998 35.5555V42.0483"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M87.999 37.7569V42.1964"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M66.9882 51.9424C66.3933 60.6071 72.4133 63.2761 76.255 63.6187C76.7489 63.6628 77.1724 63.2928 77.2235 62.7997C78.1508 53.8459 71.9245 51.307 67.9308 51.0906C67.4349 51.0638 67.0223 51.4469 66.9882 51.9424Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M66.9882 41.584C66.3933 50.2486 72.4133 52.9176 76.255 53.2603C76.7489 53.3043 77.1724 52.9344 77.2235 52.4412C78.1508 43.4875 71.9245 40.9485 67.9308 40.7322C67.4349 40.7053 67.0223 41.0885 66.9882 41.584Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M87.92 51.9475C88.5149 60.6122 82.4949 63.2812 78.6532 63.6238C78.1593 63.6679 77.7358 63.2979 77.6847 62.8048C76.7574 53.851 82.9837 51.3121 86.9774 51.0957C87.4733 51.0688 87.8859 51.452 87.92 51.9475Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M87.9581 41.584C88.553 50.2486 82.5329 52.9176 78.6913 53.2603C78.1974 53.3043 77.7739 52.9344 77.7228 52.4412C76.7955 43.4875 83.0218 40.9485 87.0155 40.7322C87.5114 40.7053 87.924 41.0885 87.9581 41.584Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M77.4746 68.7407V47.3757"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.3822 55.7675C33.4933 66.4407 41.6813 69.5856 46.6843 69.9027C47.1743 69.9337 47.5944 69.568 47.6535 69.0807C48.9905 58.059 40.5308 55.0785 35.3275 54.9108C34.8339 54.8949 34.4232 55.2754 34.3822 55.7675Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.3822 43.3969C33.4933 54.0702 41.6813 57.215 46.6843 57.5321C47.1743 57.5632 47.5944 57.1975 47.6535 56.7101C48.9905 45.6885 40.5308 42.7079 35.3275 42.5402C34.8339 42.5243 34.4232 42.9048 34.3822 43.3969Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.3822 31.0263C33.4933 41.6995 41.6813 44.8444 46.6843 45.1615C47.1743 45.1925 47.5944 44.8268 47.6535 44.3395C48.9905 33.3178 40.5308 30.3373 35.3275 30.1696C34.8339 30.1537 34.4232 30.5341 34.3822 31.0263Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M61.568 55.7733C62.4569 66.4465 54.2689 69.5914 49.2659 69.9085C48.7759 69.9395 48.3558 69.5738 48.2967 69.0865C46.9597 58.0648 55.4194 55.0843 60.6227 54.9166C61.1163 54.9007 61.527 55.2812 61.568 55.7733Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M61.6168 43.3969C62.5057 54.0702 54.3178 57.215 49.3147 57.5321C48.8247 57.5632 48.4046 57.1975 48.3455 56.7101C47.0085 45.6885 55.4682 42.7079 60.6716 42.5402C61.1651 42.5243 61.5758 42.9048 61.6168 43.3969Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M61.6168 31.0263C62.5057 41.6995 54.3178 44.8444 49.3147 45.1615C48.8247 45.1925 48.4046 44.8268 48.3455 44.3395C47.0085 33.3178 55.4682 30.3373 60.6716 30.1696C61.1651 30.1537 61.5758 30.5341 61.6168 31.0263Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M47.999 76V38.0925"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M47.9963 20.0001C42.3626 22.5847 37.1785 28.6154 47.9958 35.5077"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M47.9969 20C53.6305 22.5846 58.8146 28.6154 47.9974 35.5076"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
