import React from "react";

import { ISubscriptionCounts } from "interfaces";

import { ContextSubscriptions } from "./context-subscriptions";

export const ContextSubscriptionsProvider: React.FC = ({ children }) => {
  const [subscriptionCounts, setSubscriptionCounts] =
    React.useState<ISubscriptionCounts>({
      all: 0,
      activated: 0,
      paid: 0,
      renew: 0,
      expired: 0,
      canRenew: 0,
    });

  return (
    <ContextSubscriptions.Provider
      value={{
        subscriptionCounts,
        setSubscriptionCounts,
      }}
    >
      {children}
    </ContextSubscriptions.Provider>
  );
};

ContextSubscriptionsProvider.displayName = "ContextSubscriptionsProvider";
