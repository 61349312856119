import { PATH_PAYMENTS, PATH_PERSONAL_AREA } from "constants/routes";

import { Switch, Route } from "react-router-dom";

import { ErrorPage, ErrorPageVariant } from "components/pages/error-page";
import { PaymentInfoPage } from "components/pages/payment-info-page";
import { PaymentsPage } from "components/pages/payments-page";

export const TabPayments = () => {
  return (
    <Switch>
      <Route exact path={PATH_PAYMENTS}>
        <PaymentsPage />
      </Route>
      <Route exact path={`${PATH_PAYMENTS}/:id`}>
        <PaymentInfoPage />
      </Route>
      <Route path="*">
        <ErrorPage
          variants={ErrorPageVariant.PAGE_NOT_FOUND}
          buttonLabel="Перейти в личный кабинет"
          redirectURL={PATH_PERSONAL_AREA}
        />
      </Route>
    </Switch>
  );
};
