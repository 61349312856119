import { IconWrapper } from "components/icon-wrapper";
import { MTSIcon } from "components/mts-icon";
import { Plug } from "components/plug";
import { TransitionFade } from "components/transition-fade";

import classes from "./table-not-found.module.css";

export const TableNotFound = (): JSX.Element => {
  return (
    <div className={classes.block}>
      <TransitionFade isShow={true}>
        <Plug
          icon={
            <IconWrapper
              size={64}
              icon={
                <MTSIcon.Mail
                  sx={{
                    fontSize: "24px",
                    color: "var(--color-constant-blackberry-light)",
                  }}
                />
              }
            />
          }
          label="Не найдено ни одной подписки"
        />
      </TransitionFade>
    </div>
  );
};
