import { HTTPStatusCode, MutationKeys } from "constants/keys";

import { useMutation } from "react-query";

import FileSaver from "file-saver";
import { useNotifications } from "hooks/use-notifications";
import { downloadRinexFile } from "services/api";

export const useMutationDownloadRinexFile = (rinexFileId: string) => {
  const { addSuccessMessage, addErrorMessage } = useNotifications();

  const sendErrorNotificationMessage = (): void => {
    addErrorMessage({
      message: "Что-то пошло не так. Повторите попытку позже",
    });
  };

  return useMutation(
    (_rinexFileId: string) => downloadRinexFile(_rinexFileId),
    {
      mutationKey: [MutationKeys.DOWNLOAD_RINEX_FILE, rinexFileId],
      onSuccess: (response) => {
        const fileName =
          response.headers["content-disposition"]
            .split("filename=")[1]
            .split(";")[0] || `RINEX-${rinexFileId}.zip`;

        if (response.status === HTTPStatusCode.OK_200) {
          addSuccessMessage({
            title: "Скачивание файла завершено",
            message: fileName,
          });
          const data = response.data;
          FileSaver(data, fileName);
        } else {
          sendErrorNotificationMessage();
        }
      },
      onError: () => {
        sendErrorNotificationMessage();
      },
    }
  );
};
