import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const Date = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.3333 5.77778H18.2222C19.2041 5.77778 20 6.57372 20 7.55556V18.2222C20 19.2041 19.2041 20 18.2222 20H5.77778C4.79594 20 4 19.2041 4 18.2222V7.55556C4 6.57372 4.79594 5.77778 5.77778 5.77778H6.66667V4.88889C6.66667 4.39797 7.06464 4 7.55556 4C8.04648 4 8.44444 4.39797 8.44444 4.88889V5.77778H15.5556V4.88889C15.5556 4.39797 15.9535 4 16.4444 4C16.9354 4 17.3333 4.39797 17.3333 4.88889V5.77778ZM5.77776 7.55556V18.2222H18.2222V7.55556H5.77776ZM7.99997 10.2222H8.88886C9.13432 10.2222 9.3333 10.4212 9.3333 10.6666V11.5555C9.3333 11.801 9.13432 12 8.88886 12H7.99997C7.75451 12 7.55552 11.801 7.55552 11.5555V10.6666C7.55552 10.4212 7.75451 10.2222 7.99997 10.2222ZM7.99997 13.7778H8.88886C9.13432 13.7778 9.3333 13.9768 9.3333 14.2222V15.1111C9.3333 15.3566 9.13432 15.5556 8.88886 15.5556H7.99997C7.75451 15.5556 7.55552 15.3566 7.55552 15.1111V14.2222C7.55552 13.9768 7.75451 13.7778 7.99997 13.7778ZM11.5556 10.2222H12.4445C12.69 10.2222 12.889 10.4212 12.889 10.6666V11.5555C12.889 11.801 12.69 12 12.4445 12H11.5556C11.3102 12 11.1112 11.801 11.1112 11.5555V10.6666C11.1112 10.4212 11.3102 10.2222 11.5556 10.2222ZM11.5556 13.7778H12.4445C12.69 13.7778 12.889 13.9768 12.889 14.2222V15.1111C12.889 15.3566 12.69 15.5556 12.4445 15.5556H11.5556C11.3102 15.5556 11.1112 15.3566 11.1112 15.1111V14.2222C11.1112 13.9768 11.3102 13.7778 11.5556 13.7778ZM15.1112 13.7778H16C16.2455 13.7778 16.4445 13.9768 16.4445 14.2222V15.1111C16.4445 15.3566 16.2455 15.5556 16 15.5556H15.1112C14.8657 15.5556 14.6667 15.3566 14.6667 15.1111V14.2222C14.6667 13.9768 14.8657 13.7778 15.1112 13.7778ZM15.1112 10.2222H16C16.2455 10.2222 16.4445 10.4212 16.4445 10.6666V11.5555C16.4445 11.801 16.2455 12 16 12H15.1112C14.8657 12 14.6667 11.801 14.6667 11.5555V10.6666C14.6667 10.4212 14.8657 10.2222 15.1112 10.2222Z"
      />
    </SvgIcon>
  );
};

export default Date;
