import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const Question = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12ZM12.4986 14.5089H10.6816C10.5906 12.8598 11.0412 12.1934 11.9425 11.6325C12.5753 11.2442 12.9876 10.8319 12.9876 10.2278C12.9876 9.56147 12.465 9.13001 11.8178 9.13001C11.1898 9.13001 10.6097 9.54709 10.581 10.3285H8.625C8.66815 8.4109 10.0872 7.50003 11.8274 7.50003C13.7306 7.50003 15.0826 8.47802 15.0826 10.1559C15.0826 11.1962 14.5744 11.9201 13.6923 12.4619C12.8054 13.0084 12.4986 13.3727 12.4986 14.5089ZM12.7766 16.4265C12.7718 17.0642 12.2397 17.5771 11.6261 17.5771C10.9933 17.5771 10.4707 17.0642 10.4755 16.4265C10.4707 15.7985 10.9933 15.2856 11.6261 15.2856C12.2397 15.2856 12.7718 15.7985 12.7766 16.4265Z"
      />
    </SvgIcon>
  );
};

export default Question;
