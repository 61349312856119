import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const Ref = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="32" height="32" rx="8" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3333 8.88C11.3697 8.88 9.77778 10.4719 9.77778 12.4356V14.0049H11.5556L11.5556 20.4356H9C8.44772 20.4356 8 20.8833 8 21.4356V22.1022C8 22.6545 8.44772 23.1022 9 23.1022H23C23.5523 23.1022 24 22.6545 24 22.1022V21.4356C24 20.8833 23.5523 20.4356 23 20.4356H20.4444V18.6578C20.4444 17.6759 19.6485 16.88 18.6667 16.88C17.6848 16.88 16.8889 17.6759 16.8889 18.6578V20.4356H15.1111L15.1111 14.0049H16.8889V12.4356C16.8889 10.4719 15.297 8.88 13.3333 8.88Z"
        fill="white"
      />
      <path
        d="M8 12.4355C8 11.3941 8.44771 10.4513 9.17155 9.76889L9.68581 10.2537C9.09358 10.8121 8.72727 11.5835 8.72727 12.4355C8.72727 13.2876 9.09357 14.0589 9.68581 14.6173L9.17155 15.1022C8.4477 14.4197 8 13.4769 8 12.4355Z"
        fill="white"
      />
      <path
        d="M18.6667 12.4355C18.6667 11.3941 18.219 10.4513 17.4951 9.76889L16.9809 10.2537C17.5731 10.8121 17.9394 11.5835 17.9394 12.4355C17.9394 13.2876 17.5731 14.0589 16.9809 14.6173L17.4951 15.1022C18.219 14.4197 18.6667 13.4769 18.6667 12.4355Z"
        fill="white"
      />
    </SvgIcon>
  );
};

export default Ref;
